import React,{FC, useState,useEffect,forwardRef, useImperativeHandle, useRef,useContext} from 'react'
import {getContacts, getContactStatus, updateContactStatus, getSortContact, deleteContact, getContactByRole,getContactLazyLoad, saveContactNotes, getReassignToList, saveReassignTo} from './core/_requests'
import Moment from 'moment';
import { Offcanvas, Toast } from 'bootstrap';
import { ContactDrawer } from './contactDrawers'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import {useAuth} from '../../../app/modules/auth'
import { ContactDetails } from './contactDetails';
import { ContactToolbar } from './contactToolBar';
import { ContactList } from './contactList';
import { ContactKanban } from './contactKanban';
import * as Yup from 'yup'
import {useFormik} from 'formik'
import InfiniteScroll from "react-infinite-scroll-component";
 import {Filterby} from '../dashboard/contact/contactFunnel'
import {useLocation} from 'react-router-dom';


const initialValues = {
    reply: '',
    reassign_to: '',
    note: '',
}

const ContactPageClone = (props) => {

    const {
        sortByOnChange,
      } = props


      const {state} = useLocation()

       const filtercon = useContext(Filterby)
       console.log('wwe',filtercon);

    
    const [contact, setContact]   = useState([]);
    const [contacts, setContacts] = useState([]);
    const [reassign, setReassign] = useState([]);
    const [contactStatus, setContactStatus] = useState([]);
    const [contactCheckList, setContactCheckList] = useState([]);
    const {currentUser, logout} = useAuth();
    const [detailLoaded, setDetailLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [leadClicked, setLeadClicked] = useState(false);
    const [taskClicked, setTaskClicked] = useState(false);
    const [statusChanged, setStatusChanged] = useState(false);
    const [detailData, setDetailData] = useState('');
    const [currentContactName, setCurrentContactName] = useState({});
    const [detailTab, setDetailTab] = useState('');
    const [currentContactId, setCurrentContactId] = useState('');
    const [reassigned, setReassigned] = useState('');
    const [contactId, setContactId] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [contactsLength, setContactsLength] = useState('');
    const [detailClick, setDetailClick] = useState(false);
    const [noteSaved, setNoteSaved] = useState(false);
    const [loading, setLoading] = useState(false)
    const [filtered, setFiltered] = useState(false)
    const [hasMore, setHasMore] = useState(true);

    const funnelChartfilter = async () =>{
    //     var userId = currentUser?.id;
    //     var roleId = currentUser?.designation;
    //     var status = state?.e?.contact_status_id;
    //     var day = state?.day;
    //     var from = state?.from;
    //     var to = state?.to;
    //  const contactRes = await getContactFilterbyDay(userId,roleId,status,day,from,to)
    //  console.log("filterby",contactRes);

    //  if(state){
    //     setIsLoading(false);
    //     setLoading(false);
    //     setContacts(contactRes)
    //  }

    }

    const contactList =  async () => {
        setIsLoading(true);
        const characterResponse = await getContacts()
        console.log('all contacts');
        console.log(characterResponse);
        setContact(characterResponse);
        setIsLoading(false);
    }
    var userId = currentUser?.id;
    var roleId = currentUser?.designation;
    const contactStatusList =  async () => {
        const contactStatusResponse = await getContactStatus()
        setContactStatus(contactStatusResponse);
    }

    const notesFormSchema = Yup.object().shape({
        reply: Yup.string(),
    })

    const formikNotes = useFormik({
        initialValues,
        validationSchema: notesFormSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          console.log('on presssed');
          setLoading(true)
          try {
    
            var userId = currentUser?.id;
            var roleId = currentUser?.designation;

            var notesBody = {
                "reply": values.reply,
                "contact_id": currentContactId,
                "status_id": selectedStatus,
                "user_id": userId,
                "parent_id": 0
            };
                           
            const updateContactAddressData = await saveContactNotes(notesBody)
    
            console.log('updateContactAddressData');
            console.log(updateContactAddressData);
            if(updateContactAddressData != null){
                setLoading(false);
                var toastEl = document.getElementById('myToastUpdate');
                const bsToast = new Toast(toastEl);
                bsToast.show();
                resetForm();
                setNoteSaved(true);
                var userId = currentUser?.id;
                const body = {
                    "created_by": userId,
                    "status": selectedStatus
                }

                const updateTask = await updateContactStatus(currentContactId, body);

                if(updateTask != null){
                    var toastEl = document.getElementById('myToastStatus');
                    const bsToast = new Toast(toastEl);
                    bsToast.show();
                    // contactListByRole();
                    setNoteSaved(false);
                }
            //   const notesResponse = await getContactNotes(contactId)
            //   setContactNotes(notesResponse);
            }
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
        },
    })

    const openModal = (leadId, tabType) => {
        setDetailData(leadId);
        setDetailTab(tabType);
        setDetailClick(true);
        document.body.className += ' detail_opened';
        var offCanvasEl = document.getElementById('kt_expand'+leadId);
        offCanvasEl?.classList.remove('invisible');
        const bsOffcanvas = new Offcanvas(offCanvasEl);
        bsOffcanvas.show();
    }

    // console.log('sortByOnChange');
    // console.log(sortByOnChange);

    const deleteModel = async (leadData) => {
        setIsLoading(true);
        console.log(leadData);
        await deleteContact(leadData);
        setIsLoading(false);
        var toastEl = document.getElementById('myToastDeleteStatus');
        const bsToast = new Toast(toastEl);
        bsToast.show();
        // contactListByRole();
    } 

    const contactListByRole = async () => {
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const contactRes = await getContactByRole(userId, roleId);
        setContactsLength(contactRes.length);
    }

    const contactListLazyLoad = async () => {
        // setIsLoading(true);
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const contactRes = await getContactLazyLoad(userId, roleId, contacts.length);
       if(!state) setContacts((post) => [...post, ...contactRes]);
        setIsLoading(false); 
    }

    const openLeadForm = (id, name, source, source_name, property_id, property_name, assign_to, assign_to_name) => {
        var data = {'id': id, 'name': name, 'source': source, 'source_name': source_name, 'property_id': property_id, 'property_name': property_name, 'assign_to': assign_to, 'assign_to_name': assign_to_name}
        setCurrentContactName(data);
        setLeadClicked(true);
        document.getElementById('kt_lead_toggle')?.click();
    }

    const openTaskForm = (id, name, property_id, property_name, assign_to, assign_to_name) => {
        var data = {'id': id, 'name': name, 'property_id': property_id, 'property_name': property_name, 'assign_to': assign_to, 'assign_to_name': assign_to_name}
        setCurrentContactName(data);
        setTaskClicked(true);
        document.getElementById('kt_task_toggle')?.click();
    }

    useEffect(() => {
        setIsLoading(true);
        reassignList();
        contactStatusList();
        contactListByRole();
        contactListLazyLoad();
        funnelChartfilter();
    }, []);
    //table view
    const data = [];
    for (var i = 0; i < contacts.length; i++) {
    let object = {
            id: contacts[i].id,
            name: contacts[i].first_name+" "+contacts[i].last_name,
            email:contacts[i].email,
            phone:contacts[i].mobile,
            contactType:contacts[i].contact_type_name,
            company:contacts[i].company_name,
            designation:contacts[i].designation,
            town:contacts[i].address_2,
            province:contacts[i].national_id,
            city:contacts[i].locality,
            zipCode:contacts[i].zip_code,
            nationality:contacts[i].nationality,
            country:contacts[i].country,
            dob:Moment(contacts[i].dob).format('YYYY-MM-DD'),
            language:contacts[i].language,
            source:contacts[i].source_name,
            status:contacts[i].status_name,
            lastNote:contacts[i].remarks,
            assignedTo:contacts[i].assign_to_name,
            createdAt:Moment(contacts[i].created_at).format('YYYY-MM-DD'),
            updatedAt:Moment(contacts[i].updated_at).format('YYYY-MM-DD'),
            sortable: true,
        }
        data.push(object)
    }
        
    const [toggle, setToggle] = useState('grid');

    const handleHideData = (e) => {
        setToggle(e.target.value);
    };

    const sortByChange = async(e) => {
        setContact(e);
        // console.log(e);
        const sortContacts = await getSortContact(userId, roleId, e, contacts.length);
        
        if(sortContacts != null && !state){
            var toastEl = document.getElementById('myToastSort');
            const bsToast = new Toast(toastEl);
            bsToast.show();
          setContacts(sortContacts);
        }
    };

    const handleStatus = async (e, id, name, source, source_name, property_id, property_name, assign_to, assign_to_name) => {
        console.log(e.target.value);
        console.log(id);
        setCurrentContactId(id);
        setStatusChanged(true);
        setSelectedStatus(e.target.value);
        if (e.target.value == 2) {
            var data = {'id': id, 'name': name, 'source': source, 'source_name': source_name, 'property_id': property_id, 'property_name': property_name, 'assign_to': assign_to, 'assign_to_name': assign_to_name}
            setCurrentContactName(data);
            setLeadClicked(true);
            document.getElementById('kt_lead_toggle')?.click();
        } else if(e.target.value == 6) {            
            
                document.getElementById('reassignModelPopup')?.click();
                
        } else {
            // contact_status_pop
            var dropSubmit = document.getElementById('contact_status_pop'+id);
            dropSubmit.click();
            if(noteSaved){
                var userId = currentUser?.id;
                const body = {
                    "created_by": userId,
                    "status": e.target.value
                }

                const updateTask = await updateContactStatus(id, body);

                if(updateTask != null){
                    var toastEl = document.getElementById('myToastStatus');
                    const bsToast = new Toast(toastEl);
                    bsToast.show();
                    // contactListByRole();
                    setNoteSaved(false);
                }
            }
        } 
    }

    const contactOnSelect = (e, id) => {
        console.log('sdsdsdsdsdsd');
        console.log(e.target.checked);
        const newArr = [...contactCheckList];
        if(e.target.checked != false){
            setContactCheckList(contactCheckList => [...contactCheckList, id]);
        } else {
            newArr.splice(newArr.findIndex(item => item === id), 1);
            setContactCheckList(newArr);
        }
        console.log(contactCheckList);
    }

    const cancelStatusChange = () => {
        // contactStatusList();
        // contactListByRole();        
    }

    const reassignList = async () => {
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const response = await getReassignToList(userId, roleId)
        
        let list = [];
        for(let i=0;i<response.length;i++) {            
            if(response[i].mytl == "YES") {
                formik.setFieldValue('reassign_to', response[i].id ?? '')
            }
        }
        setReassign(response)
    }

    // const reassignToModel = async () => {

    //     var userId = currentUser?.id;
    //     var note = (document.getElementById('reassignNotestoSave') as HTMLInputElement).value;
    
    //     var body = {
    //         "module_id" :  currentContactId,
    //         "module_name" : 1,
    //         "reassign_to" : reassigned,
    //         "reassign_by" : userId,
    //         "note" : note,
    //     }
    
    //     const response = await saveReassignTo(body)
    //     if(response != null) {
    //         var toastEl = document.getElementById('myToastUpdate');
    //         const bsToast = new Toast(toastEl!);
    //         bsToast.show();   
    //         var userId = currentUser?.id;
    //             const body = {
    //                 "created_by": userId,
    //                 "status": selectedStatus
    //             }

    //             const updateTask = await updateContactStatus(currentContactId, body);

    //             if(updateTask != null){
    //                 var toastEl = document.getElementById('myToastStatus');
    //                 const bsToast = new Toast(toastEl!);
    //                 bsToast.show(); 
    //                 setNoteSaved(false);
                    
    //             }         
    //         document.getElementById('contactReload')?.click();
    //     }
    // }

    const taskSaveSchema = Yup.object().shape({
        reassign_to: Yup.string().required('Reassign_to is required'),
        note: Yup.string().required('Note is required'),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: taskSaveSchema ,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
        //   console.log('lead form body');
        //   console.log(values);
          try {
            var userId = currentUser?.id;
            var body = {
                "module_id" :  currentContactId,
                "module_name" : 1,
                "reassign_to" : values.reassign_to,
                "reassign_by" : userId,
                "note" : values.note,
            }
        
            const response = await saveReassignTo(body)
            if(response != null) {
                var toastEl = document.getElementById('myToastUpdate');
                const bsToast = new Toast(toastEl);
                bsToast.show(); 
                resetForm();  
                var userId = currentUser?.id;
                    const body = {
                        "created_by": userId,
                        "status": selectedStatus
                    }
    
                    const updateTask = await updateContactStatus(currentContactId, body);
    
                    if(updateTask != null){
                        var toastEl = document.getElementById('myToastStatus');
                        const bsToast = new Toast(toastEl);
                        bsToast.show(); 
                        setNoteSaved(false);
                        
                    }         
                document.getElementById('contactReload')?.click();
                document.getElementById('reassign_Close')?.click();
            }
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
    }})

    const cancelReassign = () => {
        document.getElementById('contactReload')?.click();
        document.getElementById('reassign_Close')?.click();
    }

    // const getMorePost = async () => {
    //     const res = await fetch(
    //       `https://jsonplaceholder.typicode.com/todos?_start=${posts.length}&_limit=10`
    //     );
    //     const newPosts = await res.json();
    //     setPosts((post) => [...post, ...newPosts]);
    //   };


    return (
        <div>
            <ContactToolbar sortByOnChange={sortByChange} layoutOnChange={handleHideData} selectedContacts={contactCheckList} setContactList={setContacts}/>
            <ContactDrawer setToggle={setFiltered} setContactList={setContacts} clickContactName={currentContactName} leadClick={leadClicked} taskClick={taskClicked}/>
            {/* <button className='d-none' onClick={contactListByRole} id="contactReload">reload</button> */}
           {/* <div className="d-flex justify-content-end">
                <select className="me-4 mb-4 btn_secondary btn btn-sm" name="views" id="views" onChange={handleHideData}>
                    <option>Grid View</option>
                    <option>List View</option>
                </select>
           </div> */}
           {isLoading ? 
            <div className='w-100 h-100'>
                <div className='d-flex justify-content-center flex-column align-items-center h_80vh'>
                    <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
                    <div className="spinner-border taskloader" role="status">                                    
                        <span className="sr-only">Loading...</span>
                    </div>
                </div> 
            </div> :
            <div>
                {/* grid view */}
                {filtered &&
                <div className={toggle == 'grid' ? "contact_page d-block" : 'd-none'}>
                    <div className="row">
                    {/* <LazyLoad height={100}> */}
                        <div className="card-group"> 
                         
                        {contacts.map((contactUser, i) => {
                            return(
                            <div className="col-sm-6 col-xxl-3 col-xl-4 mb-3" key={contactUser.id}>
                                <div className="card h-100 mb-5 mb-xl-8 mx-2 bs_1">
                                    <div className='card-body px-3 pt-3 pb-0'>
                                        <div className="d-flex align-items-center justify-content-between mb-5">
                                            <div className="d-flex align-items-center">
                                                {/* modal */}
                                                <div>
                                                {
                                                    detailClick && detailData == contactUser.id && 
                                                    <>
                                                    <div
                                                        id={'kt_expand'+contactUser.id}
                                                        className='expand_area detail_page_view offcanvas offcanvas-end justify-content-end w-100 bg-transparent d-flex'
                                                        // data-kt-drawer='true'
                                                        // data-kt-drawer-name='filter'
                                                        // data-kt-drawer-activate='true'
                                                        // data-kt-drawer-overlay='true'
                                                        // data-kt-drawer-width="{default:'96%', 'md': '75%'}"
                                                        // data-kt-drawer-direction='end'
                                                        // data-kt-drawer-toggle={'#kt_contact_toggle'+contactUser.id}
                                                        // data-kt-drawer-close='#kt_contact_close'
                                                    >
                                                        <ContactDetails contactId={contactUser.id} setContactList={setContact} tabInfo={detailTab} setDetailClicked={setDetailClick}/>
                                                    </div>
                                                    </>
                                                }
                                                {/* <div className="card bg_primary minimize_card d-none">
                                                    <div className="card-body d-flex justify-content-between">
                                                        <div>
                                                            <h5 className='text-white mb-0'>Contact Details</h5>
                                                        </div>
                                                        <a href="#" className="mx-3">
                                                            <i className="fas fa-window-minimize text-white"></i>
                                                        </a>
                                                        <a href="#" className="mx-3">
                                                            <i className="fas fa-times text-white"></i>
                                                        </a>
                                                    </div>
                                                </div> */}
                                                </div>
                                            {/* modal */}
                                                <form action="">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" onClick={(e) => contactOnSelect(e, contactUser.id)} id={"contact"+contactUser.id}/>
                                                        <label className="form-check-label id_label" htmlFor={"contact"+contactUser.id}>
                                                            {contactUser.id}
                                                        </label>
                                                    </div>
                                                </form>
                                                <div className="ms-3 ml-2 d-flex align-items-center">
                                                    <img src={toAbsoluteUrl('/media/custom/user.svg')} alt="" className="icon me-2" />
                                                    <p className="mb-0 contact_name pb-1">{contactUser.first_name ?? ''+" "+contactUser.last_name ?? ''}</p>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <a href='#' onClick={() => openModal(contactUser.id, 'overview')}><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></a>
                                                {/* <a href="#" id={'kt_contact_toggle'+contactUser.id} ><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></a> */}
                                                {/* <a href="#"><img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/></a> */}
                                                <div className="btn-group">
                                                    <a className="" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                        <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/>
                                                    </a>
                                                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                                        <li><a className="dropdown-item" onClick={() => openModal(contactUser.id, 'overview')}>Edit</a></li>
                                                        {roleId == 1  ? 
                                                            <li><a className="dropdown-item" data-bs-toggle='modal'
                                                            data-bs-target={'#delete_contact_popup'+contactUser.id}>Delete</a></li>
                                                            : <></>
                                                        }
                                                        <li><button className="dropdown-item" onClick={() => openLeadForm(contactUser.id, contactUser.first_name+" "+contactUser.last_name, contactUser.source, contactUser.source_name, contactUser.property_id, contactUser.property_name, contactUser.assign_to, contactUser.assign_to_name)}>Convert to Lead</button></li>
                                                        <li><button className="dropdown-item" onClick={() => openTaskForm(contactUser.id, contactUser.first_name+" "+contactUser.last_name, contactUser.property_id, contactUser.property_name, contactUser.assign_to, contactUser.assign_to_name)}>Add Task</button></li>
                                                    </ul>
                                                </div>
                                                <div className='modal fade' id={'delete_contact_popup'+contactUser.id} aria-hidden='true'>
                                                    <div className='modal-dialog modal-dialog-centered'>
                                                        <div className='modal-content'>
                                                            <div className='modal-header'>
                                                                <h3>Confirmation</h3>
                                                                <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                                                </div>
                                                            </div>
                                                            <div className='modal-body py-lg-10 px-lg-10'>
                                                                <p>Are you sure want to delete?</p>
                                                                <div className='d-flex align-items-center justify-content-end'>
                                                                    <button className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal'>
                                                                        No
                                                                    </button>
                                                                    <button className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal' onClick={(e) => deleteModel(contactUser.id)}>
                                                                        Yes
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center mb-4">
                                            <div className="flex-shrink-0">
                                                <img onError={e => { e.currentTarget.src = toAbsoluteUrl('/media/custom/buyer.svg') }} src={process.env.REACT_APP_API_BASE_URL+'uploads/contacts/profile_image/'+contactUser.id+'/'+contactUser.profile_image} className="user_img" alt='' />
                                                {/* <img src={toAbsoluteUrl('/media/custom/buyer.svg')} className="user_img" alt=''/> */}
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <a href={"mailto:" + contactUser.email} className="d-flex align-items-center mb-3 text-dark">
                                                    <img src={toAbsoluteUrl('/media/custom/envelope.svg')} alt="" className="icon me-1"/>
                                                    <p className="mb-0 fixed_text">{contactUser.email}</p>
                                                </a>
                                                <div className="d-xxl-flex justify-content-between">
                                                    <a href={"tel:" + contactUser.mobile} className="d-flex align-items-center mb-2 text-dark">
                                                        <p className="mb-0 d-flex flex-wrap">
                                                            <span><img src={toAbsoluteUrl('/media/custom/phone.svg')} alt="" className="icon me-1" /></span>
                                                                {contactUser.mobile}
                                                        </p>
                                                    </a>
                                                    <a href={"https://api.whatsapp.com/send?phone="+ contactUser.mobile} target="new" className="d-flex align-items-center mb-2 text-dark">
                                                        <p className="mb-0 d-flex flex-wrap">
                                                            <span><img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} alt="" className="icon me-1" /></span>
                                                                {contactUser.mobile}
                                                        </p>
                                                    </a>
                                                </div>   
                                            </div>
                                        </div>
                                        <div className='mb-3'>
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="row">
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                    <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Project</small>
                                                                        <p className="mb-0 fw-500">{contactUser.property_name}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                <img src={toAbsoluteUrl('/media/custom/google_ads.svg')} alt="" className="me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Source</small>
                                                                        <p className="mb-0 fw-500">{contactUser.source_name}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                    <img src={toAbsoluteUrl('/media/custom/assign.svg')} alt="" className="me-2"/>
                                                                    <div className="d-flex flex-column" title={contactUser.assign_to_name ?? '-'}>
                                                                        <small className="text_light">Assigned To</small>
                                                                        <p className="mb-0 fw-500">{contactUser.assign_to_name ?? '-'}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                    <img src={toAbsoluteUrl('/media/custom/calendar.svg')} alt="" className="me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Created Date</small>
                                                                        {/* <p className="mb-0 fw-500">{Moment(contactUser.created_at).format('DD-MMMM-YYYY')}</p> */}
                                                                        <p className="mb-0 fw-500">--{contactUser.created_at}</p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>  
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer border-0 p-1">
                                            <div className="row">
                                                <div className="col-7 col-xl-8 icons_bar d-flex flex-wrap">
                                                    <a href="#" onClick={() => openModal(contactUser.id, 'notes')} className="btn btn-sm icon_primary rounded-circle position-relative"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Notes">
                                                        <img src={toAbsoluteUrl('/media/custom/notes.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            0
                                                        </span>
                                                    </a>
                                                    <a href="#" onClick={() => openModal(contactUser.id, 'files')} className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="File">
                                                        <img src={toAbsoluteUrl('/media/custom/file.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            0
                                                        </span>
                                                    </a>
                                                    <a href="#" onClick={() => openModal(contactUser.id, 'message')} className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Message">
                                                        <img src={toAbsoluteUrl('/media/custom/message.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            0
                                                        </span>
                                                    </a>
                                                    <a href="#" onClick={() => openModal(contactUser.id, 'sec_contact')} className="btn btn-sm icon_primary rounded-circle position-relative"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="sec Contact">
                                                        <img src={toAbsoluteUrl('/media/custom/sec_contact.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            {contactUser.sec_count}
                                                        </span>
                                                    </a>
                                                    <a href="#" onClick={() => openModal(contactUser.id, 'lead')} className="btn btn-sm icon_primary rounded-circle position-relative"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Lead">
                                                        <img src={toAbsoluteUrl('/media/custom/lead.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            {contactUser.lead_count}
                                                        </span>
                                                    </a>
                                                    <a href="#" onClick={() => openModal(contactUser.id, 'task')} className="btn btn-sm icon_primary rounded-circle position-relative"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Task">
                                                        <img src={toAbsoluteUrl('/media/custom/task.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            {contactUser.task_count}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="col-5 col-xl-4 d-flex align-items-center justify-content-end">
                                                    <select className="form-select toggle_white toggle_white btn_primary rounded-pill btn-sm cursor-pointer status_btn" aria-label="Default select example" onChange={event => handleStatus(event, contactUser.id, contactUser.first_name+" "+contactUser.last_name, contactUser.source, contactUser.source_name, contactUser.property_id, contactUser.property_name, contactUser.assign_to, contactUser.assign_to_name)}>
                                                        <option disabled selected>Status</option>
                                                        {contactStatus.map((contactStatusValue,i) =>{
                                                            return (
                                                                <option value={contactStatusValue.id} selected={contactStatusValue.id == contactUser.status} key={i}>{contactStatusValue.name}</option> 
                                                        )})}
                                                    </select>
                                                </div>
                                                <a className="d-none" data-bs-toggle='modal'
                                                            data-bs-target={'#reassignToNote'} id='reassignModelPopup'>Delete</a>
                                            <div className='modal fade' id={'reassignToNote'} aria-hidden='true'>
                                                    <div className='modal-dialog modal-dialog-centered'>
                                                        <div className='modal-content'>
                                                            <form noValidate onSubmit={formik.handleSubmit}>
                                                            <div className='modal-header'>
                                                                <h3>Confirmation</h3>
                                                                <div className='btn btn-sm btn-icon btn-active-color-primary' id='reassign_Close' data-bs-dismiss='modal'>
                                                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                                                </div>
                                                            </div>
                                                            <div className='modal-body py-lg-10 px-lg-10'>
                                                            <div className="">
                                                                <label htmlFor="basic-url" className="form-label required">Reassign To</label>
                                                                <div className="input-group mb-3 input_prepend py-1">
                                                                    <select {...formik.getFieldProps('reassign_to')} className="btn btn-sm w-100 text-start form-select">
                                                                        <option value="">Select</option>
                                                                        {reassign.map((reass, i) => {
                                                                            return (<>
                                                                            <option selected={i == 0 ? true: false} value={reass.id} key={i}>{reass.first_name}</option>
                                                                            </>
                                                                        )})}                                       
                                                                    </select>
                                                                </div> 
                                                                {formik.touched.reassign_to && formik.errors.reassign_to && (
                                                                <div className='fv-plugins-message-container'>
                                                                    <div className='fv-help-block'>
                                                                    <span role='alert' className='text-danger'>{formik.errors.reassign_to}</span>
                                                                    </div>
                                                                </div>
                                                                )}
                                                            </div>
                                                            <div>
                                                                <label htmlFor="basic-url" className="form-label required">Note</label>
                                                                <div>
                                                                    <textarea {...formik.getFieldProps('note')} className='form-control main_bg border-0 p-2 resize-none min-h-25px br_10' id='reassignNotestoSave'
                                                                    rows={7}
                                                                    placeholder='Reason'/>
                                                                </div>
                                                                {formik.touched.note && formik.errors.note && (
                                                                <div className='fv-plugins-message-container'>
                                                                    <div className='fv-help-block'>
                                                                    <span role='alert' className='text-danger'>{formik.errors.note}</span>
                                                                    </div>
                                                                </div>
                                                                )}
                                                            </div>
                                                            </div>
                                                            {/* <div className='modal-footer'>
                                                                <button type='submit' className='btn btn-sm btn_primary' disabled={formik.isSubmitting} >Reassign</button>
                                                            </div> */}
                                                            <div className='card-footer py-5 text-end' id='kt_contact_footer'>
                                                                <button
                                                                type='submit'
                                                                id='kt_sign_up_submit' data-bs-dismiss='modal'
                                                                className='btn btn-sm btn_primary text-primary'
                                                                disabled={formik.isSubmitting}
                                                                >
                                                                {!loading && <span className='indicator-label'>Re-Assign
                                                                {/* <KTSVG
                                                                path='/media/custom/save_white.svg'
                                                                className='svg-icon-3 svg-icon-primary ms-2'
                                                                /> */}
                                                                </span>}
                                                                {loading && (
                                                                    <span className='indicator-progress' style={{display: 'block'}}>
                                                                    Please wait...{' '}
                                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                    </span>
                                                                )}
                                                                </button>
                                                                
                                                            </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <a className="d-none" href="#" data-bs-toggle='modal' id={'contact_status_pop'+contactUser.id}
                                                data-bs-target={'#contact_status_change'+contactUser.id}></a>
                                                <div className='modal fade' id={'contact_status_change'+contactUser.id} aria-hidden='true'>
                                                    <div className='modal-dialog modal-dialog-centered'>
                                                        <div className='modal-content'>
                                                            <div className='modal-header'>
                                                                <h3>Confirmation</h3>
                                                                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={(e) => cancelStatusChange()} data-bs-dismiss='modal'>
                                                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                                                </div>
                                                            </div>
                                                            <div className='modal-body py-lg-10 px-lg-10'>
                                                                <form noValidate onSubmit={formikNotes.handleSubmit}>
                                                                    <textarea
                                                                        className='form-control main_bg border-0 p-2 resize-none min-h-25px br_10'
                                                                        data-kt-autosize='true'
                                                                        {...formikNotes.getFieldProps('reply')} 
                                                                        rows={7}
                                                                        placeholder='Reason'
                                                                    ></textarea>
                                                                    <div className='d-flex align-items-center justify-content-end'>
                                                                        <button className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal' type='button' onClick={(e) => cancelStatusChange()}>
                                                                            No
                                                                        </button>
                                                                        <button disabled={formikNotes.isSubmitting} className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal' type='submit'>
                                                                            Yes
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )})}
                        
                        </div>
                        {/* </LazyLoad> */}
                    </div>
                </div>}
                {/* list view */}
                
            </div>
            }
            {toggle == 'list' &&
                <div className='h-100'>
                    <div className="card table_card p-3 h-100">  
                        <ContactList contactList={data}/>
                    </div>
                </div>
                }
                {/* kanban view */}
                {toggle == 'pipe' &&
                    <div className='pipline'>
                        <ContactKanban contactList={contact}/>
                    </div>
                }
            {filtered == false &&
                <InfiniteScroll
                    dataLength={contacts.length}
                    next={contactListLazyLoad}
                    hasMore={hasMore}
                    loader={<>
                    {contactsLength != contacts.length &&
                    <div className='w-100 h-100'>
                        <div className='d-flex justify-content-center flex-column align-items-center h_80vh'>
                            <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
                            <div className="spinner-border taskloader" role="status">                                    
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div> 
                    </div>}
                    </>}
                    endMessage={<h4>Nothing more to show</h4>}
                >
                <div className={toggle == 'grid' ? "contact_page d-block" : 'd-none'}>
                    <div className="row">
                    {/* <LazyLoad height={100}> */}
                        <div className="card-group"> 
                         
                        {contacts.map((contactUser, i) => {
                            return(
                            <div className="col-sm-6 col-xxl-3 col-xl-4 mb-3" key={contactUser.id}>
                                <div className="card h-100 mb-5 mb-xl-8 mx-2 bs_1">
                                    <div className='card-body px-3 pt-3 pb-0'>
                                        <div className="d-flex align-items-center justify-content-between mb-5">
                                            <div className="d-flex align-items-center">
                                                {/* modal */}
                                                <div>
                                                {
                                                    detailClick && detailData == contactUser.id && 
                                                    <>
                                                    <div
                                                        id={'kt_expand'+contactUser.id}
                                                        className='expand_area detail_page_view offcanvas offcanvas-end justify-content-end w-100 bg-transparent d-flex'
                                                        // data-kt-drawer='true'
                                                        // data-kt-drawer-name='filter'
                                                        // data-kt-drawer-activate='true'
                                                        // data-kt-drawer-overlay='true'
                                                        // data-kt-drawer-width="{default:'96%', 'md': '75%'}"
                                                        // data-kt-drawer-direction='end'
                                                        // data-kt-drawer-toggle={'#kt_contact_toggle'+contactUser.id}
                                                        // data-kt-drawer-close='#kt_contact_close'
                                                    >
                                                        <ContactDetails contactId={contactUser.id} setContactList={setContact} tabInfo={detailTab} setDetailClicked={setDetailClick}/>
                                                    </div>
                                                    </>
                                                }
                                                {/* <div className="card bg_primary minimize_card d-none">
                                                    <div className="card-body d-flex justify-content-between">
                                                        <div>
                                                            <h5 className='text-white mb-0'>Contact Details</h5>
                                                        </div>
                                                        <a href="#" className="mx-3">
                                                            <i className="fas fa-window-minimize text-white"></i>
                                                        </a>
                                                        <a href="#" className="mx-3">
                                                            <i className="fas fa-times text-white"></i>
                                                        </a>
                                                    </div>
                                                </div> */}
                                                </div>
                                            {/* modal */}
                                                <form action="">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" onClick={(e) => contactOnSelect(e, contactUser.id)} id={"contact"+contactUser.id}/>
                                                        <label className="form-check-label id_label" htmlFor={"contact"+contactUser.id}>
                                                            {contactUser.id}
                                                        </label>
                                                    </div>
                                                </form>
                                                <div className="ms-3 ml-2 d-flex align-items-center">
                                                    <img src={toAbsoluteUrl('/media/custom/user.svg')} alt="" className="icon me-2" />
                                                    <p className="mb-0 contact_name pb-1">{contactUser.first_name ?? ''+" "+contactUser.last_name ?? ''}</p>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <a href='#' onClick={() => openModal(contactUser.id, 'overview')}><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></a>
                                                {/* <a href="#" id={'kt_contact_toggle'+contactUser.id} ><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></a> */}
                                                {/* <a href="#"><img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/></a> */}
                                                <div className="btn-group">
                                                    <a className="" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                        <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/>
                                                    </a>
                                                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                                        <li><a className="dropdown-item" onClick={() => openModal(contactUser.id, 'overview')}>Edit</a></li>
                                                        {roleId == 1  ? 
                                                            <li><a className="dropdown-item" data-bs-toggle='modal'
                                                            data-bs-target={'#delete_contact_popup'+contactUser.id}>Delete</a></li>
                                                            : <></>
                                                        }
                                                        <li><button className="dropdown-item" onClick={() => openLeadForm(contactUser.id, contactUser.first_name+" "+contactUser.last_name, contactUser.source, contactUser.source_name, contactUser.property_id, contactUser.property_name, contactUser.assign_to, contactUser.assign_to_name)}>Convert to Lead</button></li>
                                                        <li><button className="dropdown-item" onClick={() => openTaskForm(contactUser.id, contactUser.first_name+" "+contactUser.last_name, contactUser.property_id, contactUser.property_name, contactUser.assign_to, contactUser.assign_to_name)}>Add Task</button></li>
                                                    </ul>
                                                </div>
                                                <div className='modal fade' id={'delete_contact_popup'+contactUser.id} aria-hidden='true'>
                                                    <div className='modal-dialog modal-dialog-centered'>
                                                        <div className='modal-content'>
                                                            <div className='modal-header'>
                                                                <h3>Confirmation</h3>
                                                                <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                                                </div>
                                                            </div>
                                                            <div className='modal-body py-lg-10 px-lg-10'>
                                                                <p>Are you sure want to delete?</p>
                                                                <div className='d-flex align-items-center justify-content-end'>
                                                                    <button className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal'>
                                                                        No
                                                                    </button>
                                                                    <button className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal' onClick={(e) => deleteModel(contactUser.id)}>
                                                                        Yes
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center mb-4">
                                            <div className="flex-shrink-0">
                                                <img onError={e => { e.currentTarget.src = toAbsoluteUrl('/media/custom/buyer.svg') }} src={process.env.REACT_APP_API_BASE_URL+'uploads/contacts/profile_image/'+contactUser.id+'/'+contactUser.profile_image} className="user_img" alt='' />
                                                {/* <img src={toAbsoluteUrl('/media/custom/buyer.svg')} className="user_img" alt=''/> */}
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <a href={"mailto:" + contactUser.email} className="d-flex align-items-center mb-3 text-dark">
                                                    <img src={toAbsoluteUrl('/media/custom/envelope.svg')} alt="" className="icon me-1"/>
                                                    <p className="mb-0 fixed_text">{contactUser.email}</p>
                                                </a>
                                                <div className="d-xxl-flex justify-content-between">
                                                    <a href={"tel:" + contactUser.mobile} className="d-flex align-items-center mb-2 text-dark">
                                                        <p className="mb-0 d-flex flex-wrap">
                                                            <span><img src={toAbsoluteUrl('/media/custom/phone.svg')} alt="" className="icon me-1" /></span>
                                                                {contactUser.mobile}
                                                        </p>
                                                    </a>
                                                    <a href={"https://api.whatsapp.com/send?phone="+ contactUser.mobile} target="new" className="d-flex align-items-center mb-2 text-dark">
                                                        <p className="mb-0 d-flex flex-wrap">
                                                            <span><img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} alt="" className="icon me-1" /></span>
                                                                {contactUser.mobile}
                                                        </p>
                                                    </a>
                                                </div>   
                                            </div>
                                        </div>
                                        <div className='mb-3'>
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="row">
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                    <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Project Name</small>
                                                                        <p className="mb-0 fw-500">{contactUser.property_name}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                <img src={toAbsoluteUrl('/media/custom/google_ads.svg')} alt="" className="me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Source</small>
                                                                        <p className="mb-0 fw-500">{contactUser.source_name}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                    <img src={toAbsoluteUrl('/media/custom/assign.svg')} alt="" className="me-2"/>
                                                                    <div className="d-flex flex-column" title={contactUser.assign_to_name ?? '-'}>
                                                                        <small className="text_light">Assigned To</small>
                                                                        <p className="mb-0 fw-500">{contactUser.assign_to_name ?? '-'}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                    <img src={toAbsoluteUrl('/media/custom/calendar.svg')} alt="" className="me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Created Date</small>
                                                                        {/* <p className="mb-0 fw-500">{Moment(contactUser.created_at).format('DD-MMMM-YYYY')}</p> */}
                                                                        
                                                                        <p className="mb-0 fw-500">{ Moment.parseZone(contactUser.created_at).format('YYYY-MM-DD')}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>  
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer border-0 p-1">
                                            <div className="row">
                                                <div className="col-7 col-xl-8 icons_bar d-flex flex-wrap">
                                                    <a href="#" onClick={() => openModal(contactUser.id, 'notes')} className="btn btn-sm icon_primary rounded-circle position-relative"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Notes">
                                                        <img src={toAbsoluteUrl('/media/custom/notes.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            0
                                                        </span>
                                                    </a>
                                                    <a href="#" onClick={() => openModal(contactUser.id, 'files')} className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="File">
                                                        <img src={toAbsoluteUrl('/media/custom/file.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            0
                                                        </span>
                                                    </a>
                                                    <a href="#" onClick={() => openModal(contactUser.id, 'message')} className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Message">
                                                        <img src={toAbsoluteUrl('/media/custom/message.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            0
                                                        </span>
                                                    </a>
                                                    <a href="#" onClick={() => openModal(contactUser.id, 'sec_contact')} className="btn btn-sm icon_primary rounded-circle position-relative"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="sec Contact">
                                                        <img src={toAbsoluteUrl('/media/custom/sec_contact.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            {contactUser.sec_count}
                                                        </span>
                                                    </a>
                                                    <a href="#" onClick={() => openModal(contactUser.id, 'lead')} className="btn btn-sm icon_primary rounded-circle position-relative"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Lead">
                                                        <img src={toAbsoluteUrl('/media/custom/lead.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            {contactUser.lead_count}
                                                        </span>
                                                    </a>
                                                    <a href="#" onClick={() => openModal(contactUser.id, 'task')} className="btn btn-sm icon_primary rounded-circle position-relative"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Task">
                                                        <img src={toAbsoluteUrl('/media/custom/task.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            {contactUser.task_count}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="col-5 col-xl-4 d-flex align-items-center justify-content-end">
                                                    <select className="form-select toggle_white toggle_white btn_primary rounded-pill btn-sm cursor-pointer status_btn" aria-label="Default select example" onChange={event => handleStatus(event, contactUser.id, contactUser.first_name+" "+contactUser.last_name, contactUser.source, contactUser.source_name, contactUser.property_id, contactUser.property_name, contactUser.assign_to, contactUser.assign_to_name)}>
                                                        <option disabled selected>Status</option>
                                                        {contactStatus.map((contactStatusValue,i) =>{
                                                            return (
                                                                <option value={contactStatusValue.id} selected={contactStatusValue.id == contactUser.status} key={i}>{contactStatusValue.name}</option> 
                                                        )})}
                                                    </select>
                                                </div>
                                                <a className="d-none" data-bs-toggle='modal'
                                                            data-bs-target={'#reassignToNote'} id='reassignModelPopup'>Delete</a>
                                            <div className='modal fade' id={'reassignToNote'} aria-hidden='true'>
                                                    <div className='modal-dialog modal-dialog-centered'>
                                                        <div className='modal-content'>
                                                            <form noValidate onSubmit={formik.handleSubmit}>
                                                            <div className='modal-header'>
                                                                <h3>Confirmation</h3>
                                                                <div className='btn btn-sm btn-icon btn-active-color-primary' id='reassign_Close' data-bs-dismiss='modal'>
                                                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                                                </div>
                                                            </div>
                                                            <div className='modal-body py-lg-10 px-lg-10'>
                                                            <div className="">
                                                                <label htmlFor="basic-url" className="form-label required">Reassign To</label>
                                                                <div className="input-group mb-3 input_prepend py-1">
                                                                    <select {...formik.getFieldProps('reassign_to')} className="btn btn-sm w-100 text-start form-select">
                                                                        <option selected value="">Select</option>
                                                                        {reassign.map((reass, i) => {
                                                                            return (<>
                                                                            <option value={reass.id} key={i}>{reass.first_name}</option>
                                                                            </>
                                                                        )})}                                       
                                                                    </select>
                                                                </div> 
                                                                {formik.touched.reassign_to && formik.errors.reassign_to && (
                                                                <div className='fv-plugins-message-container'>
                                                                    <div className='fv-help-block'>
                                                                    <span role='alert' className='text-danger'>{formik.errors.reassign_to}</span>
                                                                    </div>
                                                                </div>
                                                                )}
                                                            </div>
                                                            <div>
                                                                <label htmlFor="basic-url" className="form-label required">Note</label>
                                                                <div>
                                                                    <textarea {...formik.getFieldProps('note')} className='form-control main_bg border-0 p-2 resize-none min-h-25px br_10' id='reassignNotestoSave'
                                                                    rows={7}
                                                                    placeholder='Reason'/>
                                                                </div>
                                                                {formik.touched.note && formik.errors.note && (
                                                                <div className='fv-plugins-message-container'>
                                                                    <div className='fv-help-block'>
                                                                    <span role='alert' className='text-danger'>{formik.errors.note}</span>
                                                                    </div>
                                                                </div>
                                                                )}
                                                            </div>
                                                            </div>
                                                            {/* <div className='modal-footer'>
                                                                <button type='submit' className='btn btn-sm btn_primary' disabled={formik.isSubmitting} >Reassign</button>
                                                            </div> */}
                                                            <div className='card-footer py-5 text-end' id='kt_contact_footer'>
                                                                <button
                                                                type='submit'
                                                                id='kt_sign_up_submit' data-bs-dismiss='modal'
                                                                className='btn btn-sm btn_primary text-primary'
                                                                disabled={formik.isSubmitting}
                                                                >
                                                                {!loading && <span className='indicator-label'>Re-Assign
                                                                {/* <KTSVG
                                                                path='/media/custom/save_white.svg'
                                                                className='svg-icon-3 svg-icon-primary ms-2'
                                                                /> */}
                                                                </span>}
                                                                {loading && (
                                                                    <span className='indicator-progress' style={{display:'block'}}>
                                                                    Please wait...{' '}
                                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                    </span>
                                                                )}
                                                                </button>
                                                                
                                                            </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <a className="d-none" href="#" data-bs-toggle='modal' id={'contact_status_pop'+contactUser.id}
                                                data-bs-target={'#contact_status_change'+contactUser.id}></a>
                                                <div className='modal fade' id={'contact_status_change'+contactUser.id} aria-hidden='true'>
                                                    <div className='modal-dialog modal-dialog-centered'>
                                                        <div className='modal-content'>
                                                            <div className='modal-header'>
                                                                <h3>Confirmation</h3>
                                                                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={(e) => cancelStatusChange()} data-bs-dismiss='modal'>
                                                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                                                </div>
                                                            </div>
                                                            <div className='modal-body py-lg-10 px-lg-10'>
                                                                <form noValidate onSubmit={formikNotes.handleSubmit}>
                                                                    <textarea
                                                                        className='form-control main_bg border-0 p-2 resize-none min-h-25px br_10'
                                                                        data-kt-autosize='true'
                                                                        {...formikNotes.getFieldProps('reply')} 
                                                                        rows={7}
                                                                        placeholder='Reason'
                                                                    ></textarea>
                                                                    <div className='d-flex align-items-center justify-content-end'>
                                                                        <button className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal' type='button' onClick={(e) => cancelStatusChange()}>
                                                                            No
                                                                        </button>
                                                                        <button disabled={formikNotes.isSubmitting} className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal' type='submit'>
                                                                            Yes
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )})}
                        
                        </div>
                        {/* </LazyLoad> */}
                    </div>
                </div>
                </InfiniteScroll> }
             <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToast">
                <div className="toast-header">
                        <strong className="me-auto">Success</strong>
                        <button aria-label="Close" className="btn-close" 
                                data-bs-dismiss="toast" type="button">
                        </button>
                </div>
                <div className="toast-body">
                    Contact Updated Successfully!
                </div>
            </div>
             <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastUpdate">
                <div className="toast-header">
                        <strong className="me-auto">Success</strong>
                        <button aria-label="Close" className="btn-close" 
                                data-bs-dismiss="toast" type="button">
                        </button>
                </div>
                <div className="toast-body">
                    Contact Updated Successfully!
                </div>
            </div>
             <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastStatus">
                <div className="toast-header">
                        <strong className="me-auto">Success</strong>
                        <button aria-label="Close" className="btn-close" 
                                data-bs-dismiss="toast" type="button">
                        </button>
                </div>
                <div className="toast-body">
                    Contact Status Updated Successfully!
                </div>
            </div>
             <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastDeleteStatus">
                <div className="toast-header">
                        <strong className="me-auto">Success</strong>
                        <button aria-label="Close" className="btn-close" 
                                data-bs-dismiss="toast" type="button">
                        </button>
                </div>
                <div className="toast-body">
                    Contact Status Deleted Successfully!
                </div>
            </div>
             <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastSort">
                <div className="toast-header">
                        <strong className="me-auto">Success</strong>
                        <button aria-label="Close" className="btn-close" 
                                data-bs-dismiss="toast" type="button">
                        </button>
                </div>
                <div className="toast-body">
                    Contact Sorted!
                </div>
            </div>
            <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastAdd">
                <div className="toast-header">
                        <strong className="me-auto">Success</strong>
                        <button aria-label="Close" className="btn-close" 
                                data-bs-dismiss="toast" type="button">
                        </button>
                </div>
                <div className="toast-body">
                    Contact Created Successfully!
                </div>
            </div>
            <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastUpload">
                <div className="toast-header">
                        <strong className="me-auto">Success</strong>
                        <button aria-label="Close" className="btn-close" 
                                data-bs-dismiss="toast" type="button">
                        </button>
                </div>
                <div className="toast-body">
                    Contact Imported Successfully!
                </div>
            </div>
            <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastFiltered">
                <div className="toast-header">
                        <strong className="me-auto">Success</strong>
                        <button aria-label="Close" className="btn-close" 
                                data-bs-dismiss="toast" type="button">
                        </button>
                </div>
                <div className="toast-body">
                    Contact Filtered Successfully!
                </div>
            </div>
            <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastUnFiltered">
                <div className="toast-header">
                        <strong className="me-auto">Success</strong>
                        <button aria-label="Close" className="btn-close" 
                                data-bs-dismiss="toast" type="button">
                        </button>
                </div>
                <div className="toast-body">
                    Contact Filtered Reset Successfully!
                </div>
            </div>
        </div>
    )
}

  export { ContactPageClone }

  