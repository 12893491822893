import bootstrap from 'bootstrap';
import React,{FC} from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { ContactSettingSave } from '../ContactSettings/contactSettingsSave';
import App from './drag';

import { LeadSettingsDrawer } from './leadSetting_Drawer'
import { LeadToolbar } from './leadToolbar'



const LeadSettings: FC = () => {
    
    return(
        <>
        <LeadSettingsDrawer/>
        <LeadToolbar/>
        {/* <App/> */}
               <div>
                {/* grid view */}
                <div >
                    <div className="row">
                        <div className="card-group">   
                        
                            <div className="col-sm-6 col-xxl-3 col-xl-4 mb-3">
                                <div className="card h-100 mb-5 mb-xl-8 mx-2 bs_1">
                                    <div className='card-body px-3 pt-3 pb-0'>
                                        <div className="d-flex align-items-center justify-content-between mb-5">
                                            <div className="d-flex align-items-center">
                                                {/* modal */}
                                                <div
                                                    id='kt_addcontactsettings'
                                                    className='detail_page_view offcanvas offcanvas-end justify-content-end bg-transparent d-none w-100 p-4'
                                                    // data-kt-drawer='true'
                                                    // data-kt-drawer-name='LeadSettings'
                                                    // data-kt-drawer-activate='true'
                                                    // data-kt-drawer-overlay='true'
                                                    // data-kt-drawer-width="{default:'100%', 'md': '700px'}"
                                                    // data-kt-drawer-direction='end'
                                                    // data-kt-drawer-toggle='#kt_addleadsettings_toggle'
                                                    // data-kt-drawer-close='#kt_addleadsettings_close'
                                                >
                                                    {/* <ContactSettingSave/> */}
                                                </div> 
                                            {/* modal */}
                                                <form action="">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                                                        <label className="form-check-label id_label" htmlFor="exampleCheck1">
                                                            68794
                                                        </label>
                                                    </div>
                                                </form>
                                                <div className="ms-3 ml-2 d-flex align-items-center">
                                                    <img src={toAbsoluteUrl('/media/custom/user.svg')} alt="" className="leadicon me-2" />
                                                    <p className="mb-0 contact_name">Nirmal Kumar</p>
                                                </div>
                                            </div>
                                           
                                        </div>
                                        
                                        <div className='mb-3'>
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="row">
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                    <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="leadicon me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Contact Type</small>
                                                                        <p className="mb-0 fw-500">Office</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                <img src={toAbsoluteUrl('/media/custom/google_ads.svg')} alt="" className="leadicon me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Source</small>
                                                                        <p className="mb-0 fw-500">Onsite</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                    <img src={toAbsoluteUrl('/media/custom/assign.svg')} alt="" className="leadicon me-2"/>
                                                                    <div className="d-flex flex-column" title="user">
                                                                        <small className="text_light">Assigned To</small>
                                                                        <p className="mb-0 fw-500">user</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                    <img src={toAbsoluteUrl('/media/custom/calendar.svg')} alt="" className="leadicon me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Created Date</small>
                                                                        <p className="mb-0 fw-500">12/12/2022</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>  
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            
                
            </div>
            </>
    )
}

export {LeadSettings}