import React,{FC, useState, useEffect} from 'react'
import {useAuth} from '../../../app/modules/auth'
import MaterialTable from 'material-table'
import { forwardRef } from 'react';
import { getLeadsByRole, getLeadStatus, saveLeadNotes, updateLeadStatus } from './core/_requests'
import Moment from 'moment';
import * as Yup from 'yup'
import {useFormik} from 'formik'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const initialValues = {
  subject: '',
  message: '',
  reply: '',
  to: '',
}

const LeadList = (props) => {

    const {
        setLeadList, leadList
       } = props

    const [lead, setLead] = useState([]);
    const [leadStatus, setLeadStatus] = useState([]);
    const {currentUser, logout} = useAuth();
    const [pageHeight, setPageHeight] = useState('');
    const [lostStatus, setLostStatus] = useState(null);
    const [leadId, setLeadId] = useState(null);

    const columns = [
      { title: 'Id', field: 'id'},
      { title: 'Client Name', field: 'contact_name' },
      { title: 'Contact Number', field: 'contact_mobile' },
      { title: 'Email', field: 'contact_email' },
      { title: 'Project', field: 'property_name_of_building' },
      { title: 'Assigned To', field: 'assign_to_name' },
      { title: 'Source', field: 'lead_source_name' },
      { title: 'Property Type', field: 'property_type_name' },
      { title: 'Location', field: 'requirement_location_name' },
      { title: 'Bedroom Min', field: 'no_of_bedrooms_min' },
      { title: 'Bedroom Max', field: 'no_of_bedrooms_max' },
      { title: 'Builtup Area Min', field: 'built_up_area_min' },
      { title: 'Builtup Area Max', field: 'built_up_area_max' },
      { title: 'Budget Min', field: 'budget_min' },
      { title: 'Budget Max', field: 'budget_max' },
      { title: 'Posession', field: 'posession_name' },
      { title: 'Lead Priority', field: 'lead_priority_name' },
      { title: 'Created Date', render: rowData => Moment(rowData.created_at).format('DD-MMMM-YYYY') },
      { title: 'Last Note', field: 'createdAt' },
      { title: 'Last Called Date', field: 'updatedAt' },
      { title: 'Next Calling Date', field: 'updatedAt' },
      { title: 'Status', render: rowData => <div className="">
      <select className="form-select toggle_white toggle_white btn_primary rounded-pill btn-sm cursor-pointer status_btn" aria-label="Default select example" onChange={(e) => leadTypeChange(e, rowData.id)}>
          {leadStatus.map((statusVal,i) =>{
              return (
                  <option value={statusVal.id} selected={statusVal.id == rowData.status} key={i}>{statusVal.name}</option> 
          )})}
      </select>
  </div> },
  ];

    const leadListByRole =  async () => {
      var userId = currentUser?.id;
      var roleId = currentUser?.designation;
      const characterResponse = await getLeadsByRole(userId, roleId)
      setLead(characterResponse);
    }

    const leadStatusList = async () => {
      const statusResponse = await getLeadStatus();
      setLeadStatus(statusResponse);
  } 

  const cancelStatusChange = () => {

  }

  const leadTypeChange = async (e, id) => {
    var userId = currentUser?.id;
    setLeadId(id)
    // console.log('status value')
    // console.log(e.target.value)
              
        document.getElementById('lead_status_pop_list')?.click();
        setLostStatus(e.target.value);
        
}

const notesFormSchema = Yup.object().shape({
  reply: Yup.string(),
})

const formikNotes = useFormik({
  initialValues,
  validationSchema: notesFormSchema,
  onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
    console.log('on presssed');
    console.log(leadId);
  //   setIsLoading(true)
    try {

      var userId = currentUser?.id;
      var roleId = currentUser?.designation;

      var notesBody = {
          "reply": values.reply,
          "lead_id": leadId,
          "user_id": userId,
          "parent_id": 0
      };
                     
      const leadNotesData = await saveLeadNotes(notesBody)

      console.log('updateContactAddressData');
      console.log(leadNotesData);
      if(leadNotesData != null){
          // setIsLoading(false);
          resetForm();
      //   var toastEl = document.getElementById('myToastUpdate');
      //   const bsToast = new Toast(toastEl!);
      //   bsToast.show();                
      }

      const body = {
          "created_by": userId,
          "status": lostStatus
      }
      const updateTask = await updateLeadStatus(leadId, body);
      if(updateTask != null){
          // var toastEl = document.getElementById('myToastStatus');
          // const bsToast = new Toast(toastEl!);
          // bsToast.show();
          leadListByRole();
      }

    } catch (error) {
      console.error(error)
      setStatus('The registration details is incorrect')
      setSubmitting(false)
      // setIsLoading(false)
    }
  },
})

    const setHeight = () => {
      let heigh ;
      if(window.innerHeight > 720) {
        heigh = '650px'
      } else {
        heigh = '550px'
      }
      setPageHeight(heigh)
    } 

    useEffect(() => {
      setHeight()
    }, [window.innerHeight]);

    useEffect(() => {
      leadListByRole();
      leadStatusList();
    }, []);

    return (
      <div style={{ maxWidth: '100%' }} className="position-relative" >
        <div>
        <a className="d-none" href="#" data-bs-toggle='modal' id='lead_status_pop_list'
          data-bs-target={'#contact_status_change_list'+leadId}></a>
          <div className='modal fade' id={'contact_status_change_list'+leadId} aria-hidden='true'>
              <div className='modal-dialog modal-dialog-centered'>
                  <div className='modal-content'>
                      <div className='modal-header'>
                          <h3>Confirmation</h3>
                          <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' onClick={(e) => leadListByRole()}>
                          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                          </div>
                      </div>
                      <div className='modal-body py-lg-10 px-lg-10'>
                          <form noValidate onSubmit={formikNotes.handleSubmit}>
                              <textarea
                                  className='form-control main_bg border-0 p-2 resize-none min-h-25px br_10'
                                  data-kt-autosize='true'
                                  {...formikNotes.getFieldProps('reply')} 
                                  rows={7}
                                  placeholder='Reason'
                              ></textarea>
                              <div className='d-flex align-items-center justify-content-end'>
                                  <button className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal' type='button' onClick={(e) => leadListByRole()}>
                                      No
                                  </button>
                                  <button disabled={formikNotes.isSubmitting} className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal' type='submit'>
                                      Yes
                                  </button>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
        </div>
        <MaterialTable className="p-3"
          icons={tableIcons}
          columns={columns}
          data={lead}
          title=""
          // actions={[
          //   {
          //     icon: 'Save',
          //     tooltip: 'Save User',
          //     onClick: (event, rowData) => {} 
          //   },
          //   rowData => ({
          //     icon: 'Delete',
          //     tooltip: 'Delete User',
          //     onClick: (event, rowData) => {},
          //   }),
          // ]}
          options={{
            actionsColumnIndex: -1,
            maxBodyHeight: pageHeight,
            exportButton: true,
            columnsButton: true,
            headerStyle: {
                position: "sticky",
                backgroundColor: '#ececec',
                color: '#000',
                whiteSpace: 'nowrap'
            },
            rowStyle: {
                backgroundColor: '#fff',
            }
          }}
        />
      </div>
    )
}

export {LeadList}

