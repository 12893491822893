import React,{FC, useState,useEffect} from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Offcanvas, Toast } from 'bootstrap';
import { getOrganozationCompany, saveOrganozationCompany, updateOrganozationCompany } from './core/_requests'

const initialValues = {
    "upload_logo": "",
    "company_name": "",
    "registered_address": "",
    "locality": "",
    "city": "",
    "state": "",
    "phone_number": "",
    "gst_number": "",
    "cin_number": "",
    "tan_number": "",
    "pan_number": "",
    "upload": "",
    "branch_details": "",
    "address": "",
    "locality_2": "",
    "city_2": "",
    "state_2": "",
    "currency": "",
    "website": "",
    "facebook_url": "",
    "linkindin_url": "",
    "twitter_url": "",
    "instagram_url": "",
    "youtube_url": ""
}

const OrgSettings: FC = () => {

    const teamsSaveSchema = Yup.object().shape({
        company_name: Yup.string().required('Company Name is required'),
        registered_address: Yup.string().required('Address is required'),       
        locality: Yup.string(),
        city: Yup.string(),       
        state: Yup.string(),       
        phone_number: Yup.string().required('Contact Number is required'),       
        gst_number: Yup.string(),       
        cin_number: Yup.string(),       
        tan_number: Yup.string(),       
        pan_number: Yup.string(),       
        branch_details: Yup.string(),       
        address: Yup.string(),       
        locality_2: Yup.string(),       
        city_2: Yup.string(),       
        state_2: Yup.string(),       
        currency: Yup.string(),       
        website: Yup.string(),       
        facebook_url: Yup.string(),       
        twitter_url: Yup.string(),       
        instagram_url: Yup.string(),       
        youtube_url: Yup.string(),       
    })

    const [toggle, setToggle] = useState(false);
    const [loading, setLoading] = useState(false); 
    const [leadStatus, setLeadStatus] = useState<any[]>([]);
    const [imageFile, setImageFile] = useState<any>(null);
    const [imgPre, setImgPre] = useState(false);
    const [imagePreview, setImagePreview] = useState<any>(null);

    const formik = useFormik({
        initialValues,
        validationSchema: teamsSaveSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
          try {
              
            // const body = {
            //     "upload_logo": "good",
            //     "company_name": values.company_name,
            //     "registered_address": values.registered_address,
            //     "locality": values.locality,
            //     "city": values.city,
            //     "state": values.state,
            //     "phone_number": values.phone_number,
            //     "gst_number": values.gst_number,
            //     "cin_number": values.cin_number,
            //     "tan_number": values.tan_number,
            //     "pan_number": values.pan_number,
            //     "upload": "",
            //     "branch_details": values.branch_details,
            //     "address": values.address,
            //     "locality_2": values.locality_2,
            //     "city_2": values.city_2,
            //     "state_2": values.state_2,
            //     "currency": values.currency,
            //     "website": values.website,
            //     "facebook_url": values.facebook_url,
            //     "linkindin_url": values.linkindin_url,
            //     "twitter_url": values.twitter_url,
            //     "instagram_url": values.instagram_url,
            //     "youtube_url": values.youtube_url
            // }

            let formData = new FormData();    //formdata object

                formData.append('upload_logo', imageFile!);  
                formData.append('company_name', values.company_name);
                formData.append('registered_address',  values.registered_address);  
                formData.append('locality', values.locality);
                formData.append('city', values.city);  
                formData.append('state', values.state);
                formData.append('phone_number', values.phone_number);  
                formData.append('gst_number', values.gst_number);
                formData.append('cin_number', values.cin_number);  
                formData.append('tan_number', values.tan_number);
                formData.append('pan_number', values.pan_number);  
                formData.append('upload', imageFile!);
                formData.append('branch_details', values.branch_details);  
                formData.append('address', values.address);
                formData.append('locality_2', values.locality_2);  
                formData.append('city_2', values.city_2);
                formData.append('state_2', values.state_2);  
                formData.append('currency', values.currency);
                formData.append('website', values.website);  
                formData.append('facebook_url', values.facebook_url);
                formData.append('linkindin_url', values.linkindin_url);  
                formData.append('twitter_url', values.twitter_url);
                formData.append('instagram_url', values.instagram_url);  
                formData.append('youtube_url', values.youtube_url);

                const config = {     
                    headers: {'content-type': 'multipart/form-data'}
                }
                
            // console.log('lead form body');
            // console.log(body);
            const saveTemplateMailData = await updateOrganozationCompany(1, formData);
           
            // document.getElementById('kt_contact')?.classList.remove('drawer-on');
            if(saveTemplateMailData != null){
                setLoading(false);
                document.getElementById('kt_team_close')?.click();
                document.getElementById('logo_reload')?.click();
                var toastEl = document.getElementById('myToastAdd');
                const bsToast = new Toast(toastEl!);
                bsToast.show();
                // const characterResponse = await getTasks()
                // setTasks(characterResponse)
                // resetForm();
                
            }
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
    }})

    // const handleImagePreview = (e:any) => {
    //     let image_as_base64:any = URL.createObjectURL(e.target.files[0]);
    //     let image_as_files:any = e.target.files[0];
    // //     const canvas = e.target.files[0];
    // //     const canvasterm = canvas.toDataURL();
    // //     const base64 = useImageToBase64(image_as_files);
    // //    console.log('image_as_files_encoded')
    // //    console.log(base64)
    //     setImagePreview(image_as_base64);
    //     setImageFile(image_as_files);
    //     setImagePreview(image_as_base64);
    //     setImgPre(true);
    // }

    const handleImagePreview = (e:any) => {
        let image_as_base64:any = URL.createObjectURL(e.target.files[0]);
        let image_as_files:any = e.target.files[0];
        setImagePreview(image_as_base64);
        setImageFile(image_as_files);
        setImagePreview(image_as_base64);
        setImgPre(true);              
    }

    const imgRemove = () => {
        setImageFile(null);
        setImagePreview(null);
        setImgPre(false);
    }

    const loadOrg = async () => {
        const response = await getOrganozationCompany(1);
        if(response != null) {
            console.log("fvhljhgkjfdgbdjf", response);
            // formik.setFieldValue('upload_logo', response.);
            formik.setFieldValue('company_name', response.company_name);
            formik.setFieldValue('registered_address', response.address);
            formik.setFieldValue('locality', response.locality);
            formik.setFieldValue('city', response.city);
            formik.setFieldValue('state', response.state);
            formik.setFieldValue('phone_number', response.phone_number);
            formik.setFieldValue('gst_number', response.gst_number);
            formik.setFieldValue('cin_number', response.cin_number);
            formik.setFieldValue('tan_number', response.tan_number);
            formik.setFieldValue('pan_number', response.pan_number);
            // formik.setFieldValue('upload', response.);
            formik.setFieldValue('branch_details', response.branch_details);
            formik.setFieldValue('address', response.address);
            formik.setFieldValue('locality_2', response.locality_2);
            formik.setFieldValue('city_2', response.city_2);
            formik.setFieldValue('state_2', response.state_2);
            formik.setFieldValue('currency', response.currency);
            formik.setFieldValue('website', response.currency);
            formik.setFieldValue('facebook_url', response.facebook_url);
            formik.setFieldValue('linkindin_url', response.linkindin_url);
            formik.setFieldValue('twitter_url', response.twitter_url);
            formik.setFieldValue('instagram_url', response.instagram_url);
            formik.setFieldValue('youtube_url', response.youtube_url);
        }
    }

    useEffect(() => {
        loadOrg();        
    }, []);

    return(
        <div className="org_settings bg_white p-3 h-100">            
            <ul className="nav nav-pills mb-5" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="pills-org-tab" data-bs-toggle="pill" data-bs-target="#pills-org" type="button" role="tab" aria-controls="pills-org" aria-selected="true">Company Details</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-personal-tab" data-bs-toggle="pill" data-bs-target="#pills-personal" type="button" role="tab" aria-controls="pills-personal" aria-selected="false">Technical Details</button>
                </li>
            </ul>
            
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-org" role="tabpanel" aria-labelledby="pills-org-tab">
                    <form noValidate onSubmit={formik.handleSubmit} >
                        <div className="row">
                            <div className="col-lg-12 col-xxl-12">
                                <div className="card bs_1">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-3 col-xxl-2">
                                            {imgPre &&
                                            <><div className='position-relative'><img src={imagePreview} alt="image preview" height={100} width={100} />
                                            <a onClick={(e) => imgRemove()} className="icon position-absolute px-1 top-0 end-0 rounded bg-gray border-0"><span className="svg-icon svg-icon-muted"><svg width="" height="30" viewBox="3 3 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect><rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)" fill="currentColor"></rect><rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)" fill="currentColor"></rect></svg></span></a></div></>}
                                                <span className="btn btn-file d-flex flex-md-row p-2 p-md-4 w-75 my-3">
                                                    <i className="fa fa-upload my-2" aria-hidden="true"></i>Upload Logo
                                                    <input type="file" onChange={handleImagePreview}/>
                                                </span>
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='fs-10px text-danger'>Note: use jpg, jpeg, png only!</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-9 col-xl-8 col-xxl-7">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Name of Company</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="Enter Company name" {...formik.getFieldProps('company_name')}/> 
                                                            </div>
                                                            {formik.touched.company_name && formik.errors.company_name && (
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>
                                                                    <span role='alert' className='text-danger'>{formik.errors.company_name}</span>
                                                                </div>
                                                            </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Registered Address</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="Address" {...formik.getFieldProps('registered_address')}/> 
                                                            </div>
                                                            {formik.touched.registered_address && formik.errors.registered_address && (
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>
                                                                    <span role='alert' className='text-danger'>{formik.errors.registered_address}</span>
                                                                </div>
                                                            </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Locality</label>
                                                            <select className="form-select btn-sm text-start" {...formik.getFieldProps('locality')}>
                                                                <option value="">Select</option>
                                                                <option value="1">Devanhalli</option>
                                                                <option value="2">HSR Layout</option>
                                                            </select>  
                                                        </div>
                                                        {formik.touched.locality && formik.errors.locality && (
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>
                                                                    <span role='alert' className='text-danger'>{formik.errors.locality}</span>
                                                                </div>
                                                            </div>
                                                            )}
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">City</label>
                                                            <select className="form-select btn-sm text-start" {...formik.getFieldProps('city')}>
                                                                <option value="">Select</option>
                                                                <option value="1">Bangalore</option>
                                                                <option value="1">Chennai</option>
                                                            </select>  
                                                        </div>
                                                        {formik.touched.city && formik.errors.city && (
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>
                                                                    <span role='alert' className='text-danger'>{formik.errors.city}</span>
                                                                </div>
                                                            </div>
                                                            )}
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">State</label>
                                                            <select className="form-select btn-sm text-start" {...formik.getFieldProps('state')}>
                                                                <option value="">Select</option>
                                                                <option value="1">Karnataka</option>
                                                                <option value="2">TamilNadu</option>
                                                            </select>  
                                                        </div>
                                                        {formik.touched.state && formik.errors.state && (
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>
                                                                    <span role='alert' className='text-danger'>{formik.errors.state}</span>
                                                                </div>
                                                            </div>
                                                            )}
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Phone Number</label>
                                                            <div className="input-group">
                                                                <input type="number" min="0" className="form-control" placeholder="Enter Phone Number" {...formik.getFieldProps('phone_number')}/> 
                                                            </div>
                                                            {formik.touched.phone_number && formik.errors.phone_number && (
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>
                                                                    <span role='alert' className='text-danger'>{formik.errors.phone_number}</span>
                                                                </div>
                                                            </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xxl-9 col-xl-11 col-12 mt-4">
                                                <div className="row">
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">GST Number</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="GST Number" {...formik.getFieldProps('gst_number')}/> 
                                                            </div>
                                                            {formik.touched.gst_number && formik.errors.gst_number && (
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>
                                                                    <span role='alert' className='text-danger'>{formik.errors.gst_number}</span>
                                                                </div>
                                                            </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">CIN Number</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="CIN Number" {...formik.getFieldProps('cin_number')}/> 
                                                            </div>
                                                            {formik.touched.cin_number && formik.errors.cin_number && (
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>
                                                                    <span role='alert' className='text-danger'>{formik.errors.cin_number}</span>
                                                                </div>
                                                            </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">TAN Number</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="TAN Number" {...formik.getFieldProps('tan_number')}/> 
                                                            </div>
                                                            {formik.touched.tan_number && formik.errors.tan_number && (
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>
                                                                    <span role='alert' className='text-danger'>{formik.errors.tan_number}</span>
                                                                </div>
                                                            </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">PAN Number</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="PAN Number" {...formik.getFieldProps('pan_number')}/> 
                                                            </div>
                                                            {formik.touched.pan_number && formik.errors.pan_number && (
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>
                                                                    <span role='alert' className='text-danger'>{formik.errors.pan_number}</span>
                                                                </div>
                                                            </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Upload Watermark Image</label>
                                                            <span className="btn btn-file d-flex flex-md-row p-2 p-md-4">
                                                                <i className="fa fa-upload my-2" aria-hidden="true"></i>Upload
                                                                <input type="file"/>
                                                            </span>
                                                        </div>
                                                    </div> */}
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label className="form-label">Branch Details</label>
                                                            <div className="input-group mb-3">
                                                                <input type="text" className="form-control" placeholder="Branch Details" {...formik.getFieldProps('branch_details')}/>                                                                
                                                            </div>
                                                            {formik.touched.branch_details && formik.errors.branch_details && (
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>
                                                                    <span role='alert' className='text-danger'>{formik.errors.branch_details}</span>
                                                                </div>
                                                            </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Address</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="Address" {...formik.getFieldProps('address')}/> 
                                                            </div>
                                                            {formik.touched.address && formik.errors.address && (
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>
                                                                    <span role='alert' className='text-danger'>{formik.errors.address}</span>
                                                                </div>
                                                            </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Locality</label>
                                                            <select className="form-select btn-sm text-start" {...formik.getFieldProps('locality_2')}>
                                                                <option value="">Select</option>
                                                                <option value="1">Devanhalli</option>
                                                                <option value="2">HSR Layout</option>
                                                            </select>  
                                                        </div>
                                                        {formik.touched.locality_2 && formik.errors.locality_2 && (
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>
                                                                    <span role='alert' className='text-danger'>{formik.errors.locality_2}</span>
                                                                </div>
                                                            </div>
                                                            )}
                                                    </div>
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">City</label>
                                                            <select className="form-select btn-sm text-start" {...formik.getFieldProps('city_2')}>
                                                                <option value="">Select</option>
                                                                <option value="1">Bangalore</option>
                                                                <option value="2">Chennai</option>
                                                            </select>  
                                                        </div>
                                                        {formik.touched.city_2 && formik.errors.city_2 && (
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>
                                                                    <span role='alert' className='text-danger'>{formik.errors.city_2}</span>
                                                                </div>
                                                            </div>
                                                            )}
                                                    </div>
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">State</label>
                                                            <select className="form-select btn-sm text-start" {...formik.getFieldProps('state_2')}>
                                                                <option value="">Select</option>
                                                                <option value="1">Karnataka</option>
                                                                <option value="2">TamilNadu</option>
                                                            </select>  
                                                        </div>
                                                        {formik.touched.state_2 && formik.errors.state_2 && (
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>
                                                                    <span role='alert' className='text-danger'>{formik.errors.state_2}</span>
                                                                </div>
                                                            </div>
                                                            )}
                                                    </div>
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Currency</label>
                                                            <select className="form-select btn-sm text-start" {...formik.getFieldProps('currency')}>
                                                                <option value="">Select</option>
                                                                <option value="1">$</option>
                                                                <option value="2">₹</option>
                                                            </select>  
                                                        </div>
                                                        {formik.touched.currency && formik.errors.currency && (
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>
                                                                    <span role='alert' className='text-danger'>{formik.errors.currency}</span>
                                                                </div>
                                                            </div>
                                                            )}
                                                    </div>
                                                    <div className="col-6 col-xl-4 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Website</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="URL" {...formik.getFieldProps('website')}/> 
                                                            </div>
                                                            {formik.touched.website && formik.errors.website && (
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>
                                                                    <span role='alert' className='text-danger'>{formik.errors.website}</span>
                                                                </div>
                                                            </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group my-4">
                                                            <h4>Social Media Links</h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Facebook</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="URL" {...formik.getFieldProps('facebook_url')}/> 
                                                            </div>
                                                            {formik.touched.facebook_url && formik.errors.facebook_url && (
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>
                                                                    <span role='alert' className='text-danger'>{formik.errors.facebook_url}</span>
                                                                </div>
                                                            </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Linkedin</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="URL" {...formik.getFieldProps('linkindin_url')}/> 
                                                            </div>
                                                            {formik.touched.linkindin_url && formik.errors.linkindin_url && (
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>
                                                                    <span role='alert' className='text-danger'>{formik.errors.linkindin_url}</span>
                                                                </div>
                                                            </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Twitter</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="URL" {...formik.getFieldProps('twitter_url')}/> 
                                                            </div>
                                                            {formik.touched.twitter_url && formik.errors.twitter_url && (
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>
                                                                    <span role='alert' className='text-danger'>{formik.errors.twitter_url}</span>
                                                                </div>
                                                            </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Instagram</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="URL" {...formik.getFieldProps('instagram_url')}/> 
                                                            </div>
                                                            {formik.touched.instagram_url && formik.errors.instagram_url && (
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>
                                                                    <span role='alert' className='text-danger'>{formik.errors.instagram_url}</span>
                                                                </div>
                                                            </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Youtube</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="URL" {...formik.getFieldProps('youtube_url')}/> 
                                                            </div>
                                                            {formik.touched.youtube_url && formik.errors.youtube_url && (
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>
                                                                    <span role='alert' className='text-danger'>{formik.errors.youtube_url}</span>
                                                                </div>
                                                            </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-footer py-5 text-center' id='kt_task_footer'>
                                            <button
                                            type='submit'
                                            id='kt_add_teams_submit'
                                            className='btn btn_primary text-primary'
                                            disabled={formik.isSubmitting}
                                            >
                                            {!loading && <span className='indicator-label'>Save
                                            <KTSVG
                                            path='/media/custom/save_white.svg'
                                            className='svg-icon-3 svg-icon-primary ms-2'
                                            />
                                            </span>}
                                            {loading && (
                                                <span className='indicator-progress' style={{display: 'block'}}>
                                                Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </form>                                        
                </div>
                <div className="tab-pane fade" id="pills-personal" role="tabpanel" aria-labelledby="pills-personal-tab">
                    <form noValidate onSubmit={formik.handleSubmit} >
                        <div className="row">
                            <div className="col-lg-12 col-xxl-12">
                            <div className="d-flex flex-column flex-center">   
                                    <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
                                    <h2>Under Construction</h2>
                                </div>
                                <div className="card bs_1">                                
                                    <div className="card-body d-none">
                                        <div className="row">
                                            <div className="col-12 col-md-9 col-xl-8 col-xxl-7">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Email Server</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="Email Server"/> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">DLT</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="DLT"/> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </form>                   
                </div>
            </div>
            <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastAdd">
                <div className="toast-header">
                        <strong className="me-auto">Success</strong>
                        <button aria-label="Close" className="btn-close" 
                                data-bs-dismiss="toast" type="button">
                        </button>
                </div>
                <div className="toast-body">
                    Organization Created Successfully!
                </div>
            </div>            
            <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastUpdate">
                <div className="toast-header">
                        <strong className="me-auto">Success</strong>
                        <button aria-label="Close" className="btn-close" 
                                data-bs-dismiss="toast" type="button">
                        </button>
                </div>
                <div className="toast-body">
                    Organization Updated Successfully!
                </div>
            </div>            
        </div>
    )
}
export {OrgSettings}