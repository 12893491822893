import React,{FC, useState, useEffect} from 'react'
import {getLeadDetail, getContacts, getCity, getLog, getAssignTo, getPoperty, getAmenity, getFurnishStatus, getVasthu, 
    getPosesStatus, getAgeOfProperty, getLookingFor, getSegment, getPropertyType, getLeadSource, getReqLocations, 
    getLeadGroup, getLeadStatus, updateLead, updateLeadReq, saveLeadNotes, getLeadNotes, uploadMultipleFileLead, 
    getLeadsByRole, getMultiImage, deleteMultipleImagesLeads, deleteLeadNotes, updateLeadNotes, getTaskLead, 
    getSecondaryContactLead, getDuplicateLead, getSalesManagerList} from './core/_requests';
    import { getProjects,getProperties } from '../property/core/_requests';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import {useAuth} from '../../../app/modules/auth'
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import { Toast, Offcanvas } from 'bootstrap';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { useDropzone } from 'react-dropzone'
import Moment from 'moment';

import { DataGrid,
     GridColDef,
    // GridColumnHeaderParams,
    GridRenderCellParams,
    // GridValueGetterParams
     } from '@mui/x-data-grid';
import { getPriority, getPropertyFacing } from '../settings/masters/core/_requests'
import { getAllTemplatesMail } from '../settings/templates/core/_requests';
import { getAssignToList } from '../contact/core/_requests';

const initialValues = {
    contact_id: '',
    lead_source: '',
    lead_priority: '',
    lead_group: '',
    segment: '',
    status: '',
    sales_manager: '',
    project_facing: '',
    assign_to: [],
    possession_status: [],
    age_of_property: '',
    vasthu_compliant:'',
    furnishing:[],
    looking_for: '',
    property_type: '',
    city: '',
    fee_oppurtunity: '',
    property_id: '',
    requirement_location: '',
    budget_min: '',
    budget_max: '',
    no_of_bedrooms_min: '',
    no_of_bedrooms_max: '',
    no_of_bathrooms_min: '',
    no_of_bathrooms_max: '',
    built_up_area_min: '',
    built_up_area_max: '',
    plot_area_min: '',
    plot_area_max: '',   
    car_park_min:'',
    car_park_max:'',
    timeline_for_closure_min:'',
    timeline_for_closure_max:'',
    amenities:[],
    reply:'',
    title: "",
    subject: "",
    share_with: "",
    module_id: "",
    body: "",
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
};

const thumbsContainer = {
    display: 'flex',
    // flexDirection: 'row',
    // flexWrap: 'wrap',
    marginTop: 16
  };

const thumb = {
    display: 'inline-flex',
    borderRadius: 5,
    // border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    // boxSizing: 'border-box'
  };
  
  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };
  
  const img = {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  };

  const thumbButton = {
    // position: 'absolute',
    right: 10,
    bottom: 10,
};

function getStyles(name: string, aminityName: string[], theme: Theme) {
    return {
        fontWeight:
        aminityName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
    };
}


const secContactColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70,headerClassName: 'dg_header' },
    { field: 'first_name', headerName: 'Name', width: 130,headerClassName: 'dg_header' },
    { field: 'company_name', headerName: 'Company', width: 130,headerClassName: 'dg_header' },
    { field: 'email', headerName: 'Mail', type: 'number', width: 130,headerClassName: 'dg_header'},
    { field: 'mobile', headerName: 'Phone', width: 130,headerClassName: 'dg_header' },
    { field: 'remarks', headerName: 'Remarks', width: 130,headerClassName: 'dg_header' },
  ];

  const duplicatecolumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70,headerClassName: 'dg_header' },
    { field: 'first_name', headerName: 'Name', width: 130,headerClassName: 'dg_header' },
    { field: 'company_name', headerName: 'Company', width: 130,headerClassName: 'dg_header' },
    { field: 'email', headerName: 'Mail', type: 'number', width: 130,headerClassName: 'dg_header'},
    { field: 'mobile', headerName: 'Phone', width: 130,headerClassName: 'dg_header' },
    { field: 'remarks', headerName: 'Remarks', width: 130,headerClassName: 'dg_header' },
  ];


const Contactrows = [
  { id: '85213',
    firstName: 'Mr.Raj',
    Email:'raj2002@gmail.com',
    PhoneNumber: '8965745689',
    designation:'Asst.Manager',
    createdBy:'Kumar',
    createdOn: '10 Dec 2022'
  },
];

const logContactcolumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70,headerClassName: 'dg_header' },
    { field: 'user_id', headerName: 'User ID', width: 100,headerClassName: 'dg_header' },
    { field: 'user_name', headerName: 'User Name', width: 200,headerClassName: 'dg_header' },
    { field: 'module_name_ref', headerName: 'Module Name', width: 150,headerClassName: 'dg_header' },
    { field: 'note', headerName: 'Note', width: 500,headerClassName: 'dg_header' },
    { field: 'created_at', headerName: 'Created At', width: 200,headerClassName: 'dg_header' },
];

const logContactTaskcolumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70,headerClassName: 'dg_header' },
    { field: 'task_type', headerName: 'Task Type', width: 120,headerClassName: 'dg_header' },
    { field: 'priority', headerName: 'priority', width: 120,headerClassName: 'dg_header' },
    { field: 'task_time', headerName: 'Task Time', width: 120,headerClassName: 'dg_header' },
    { field: 'finish_time', headerName: 'Finish Time', width: 120,headerClassName: 'dg_header' },
    { field: 'project', headerName: 'Project', width: 120,headerClassName: 'dg_header' },
    { field: 'contact', headerName: 'Contact', width: 120,headerClassName: 'dg_header' },
    { field: 'agenda', headerName: 'Agenda', width: 120,headerClassName: 'dg_header' },
    { field: 'assign_to_name', headerName: 'Assign To', width: 200,headerClassName: 'dg_header' },
    { field: 'task_note', headerName: 'Task Note', width: 120,headerClassName: 'dg_header' },
    { field: 'task_status', headerName: 'Task Status', width: 120,headerClassName: 'dg_header' },
    { field: 'contact_email', headerName: 'Contact Email', width: 120,headerClassName: 'dg_header' },
    { field: 'contact_mobile', headerName: 'Contact Mobile', width: 120,headerClassName: 'dg_header' },
];


const leadcolumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 ,headerClassName: 'dg_header'},
    { field: 'firstName', headerName: 'First name', width: 130, headerClassName: 'dg_header',
        renderCell: (params: GridRenderCellParams<Date>) => (
        <strong>
          <img src={toAbsoluteUrl('/media/custom/user.svg')} alt="" className="table_icon me-2" />
          
        </strong>
      ),
    },
    { field: 'Email', headerName: 'Email', width: 130, headerClassName: 'dg_header' },
    { field: 'PhoneNumber', headerName: 'Phone Number', type: 'number', width: 130, headerClassName: 'dg_header'},
    { field: 'Project', headerName: 'Project', width: 130, headerClassName: 'dg_header'},
    { field: 'Configuration', headerName: 'Configuration', width: 130, headerClassName: 'dg_header'},
    { field: 'ProjectType', headerName: 'Project Type', width: 130, headerClassName: 'dg_header'},
    { field: 'Source', headerName: 'Source', width: 130, headerClassName: 'dg_header'},
    { field: 'CreatedOn', headerName: 'Created on', width: 130, headerClassName: 'dg_header'},
    { field: 'AssignTo', headerName: 'Assign To', width: 130, headerClassName: 'dg_header'},
    { field: 'Budget', headerName: 'Budget', width: 130, headerClassName: 'dg_header'},
    { field:'Action', renderCell: () => (
        <select className="form-select toggle_white btn_primary rounded-pill btn-sm cursor-pointer status_btn" aria-label="Default select example">
            <option selected>Status</option>
            <option value="1">Pending</option>
            <option value="2">Completed</option>
        </select>
      ),
    }
  ];

const leadrows = [
  { id: '85213',
    firstName: 'Mr.Raj',
    Email:'raj2002@gmail.com',
    PhoneNumber: '8965745689',
    Project:'Sobha forest Edge',
    Configuration:'2BHK',
    ProjectType:'Villa',
    Source:'Facebook',
    CreatedOn: '10 Dec 2022',
    AssignTo: 'Ram Kumar',   
    Budget: 'INR 3c To 4c',   
  },
  { id: '65236',
    firstName: 'Mr.Ram',
    Email:'raj2002@gmail.com',
    PhoneNumber: '8965745689',
    Project:'Sobha forest Edge',
    Configuration:'2BHK',
    ProjectType:'Villa',
    Source:'Facebook',
    CreatedOn: '10 Dec 2022',
    AssignTo: 'Ram Kumar',   
    Budget: 'INR 3c To 4c',   
  },
];


type Props = {
    leadId?: any,
    setLeadList?: any,
    tabInfo?: any,
    setDetailClicked?: any
}

const LeadDetails: FC<Props> = (props) => {

    const {
        leadId, setLeadList, tabInfo, setDetailClicked
      } = props

    const leadUpdateSchema = Yup.object().shape({
        contact_id: Yup.string()
            .required('Contact name is required'),
        looking_for: Yup.string(),
        property_type: Yup.string(),
        city: Yup.string(),
        lead_source: Yup.string(),
        lead_priority: Yup.string(),
        sales_manager: Yup.string(),
        lead_group: Yup.string(),
        segment: Yup.string(),
        fee_oppurtunity: Yup.string(),
        property_id: Yup.string(),
        status: Yup.string(),
        assign_to: Yup.array(),
    })
    const notesFormSchema = Yup.object().shape({
        reply: Yup.string(),        
      })
    const leadUpdateSchema2 = Yup.object().shape({
        requirement_location: Yup.string(),
        budget_min: Yup.string(),
        budget_max: Yup.string(),
        project_facing: Yup.string(),
        no_of_bedrooms_min: Yup.string(),
        no_of_bedrooms_max: Yup.string(),
        no_of_bathrooms_min: Yup.string(),
        no_of_bathrooms_max: Yup.string(),
        built_up_area_min: Yup.string(),
        built_up_area_max: Yup.string(),
        plot_area_min: Yup.string(),
        plot_area_max: Yup.string(),
        age_of_property: Yup.string(),
        vasthu_compliant: Yup.string(),
        car_park_min: Yup.string(),
        car_park_max: Yup.string(),
        timeline_for_closure_min: Yup.string(),
        timeline_for_closure_max: Yup.string(),
    })

    const theme = useTheme(); 

    const [isExpand, setIsExpand] = useState(true);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [leadDetail, setLeadDetail] = useState<{[key: string]: any}>({});
    const [contacts, setContacts] = useState<any[]>([]);
    const [lookingFor, setLookingFor] = useState<any[]>([]);
    const [segment, setSegment] = useState<any[]>([]);
    const [propertyType, setPropertyType] = useState<any[]>([]);
    const [leadSource, setLeadSource] = useState<any[]>([]);
    const [leadGroup, setLeadGroup] = useState<any[]>([]);
    const [leadStatus, setLeadStatus] = useState<any[]>([]);
    const [opportunity, setOpportunity] = useState<any>(null);
    const [property, setProperty] = useState<any>(null);
    const [bedroomsMin, setBedroomsMin] = useState<any>(null);
    const [bedroomsMax, setBedroomsMax] = useState<any>(null);
    const [bathroomsMin, setBathroomsMin] = useState<any>(null);
    const [bathroomsMax, setBathroomsMax] = useState<any>(null);
    const [plotAreaMin, setPlotAreaMin] = useState<any>(null);
    const [plotAreaMax, setPlotAreaMax] = useState<any>(null);
    const [builtAreaMin, setBuiltAreaMin] = useState<any>(null);
    const [builtAreaMax, setBuiltAreaMax] = useState<any>(null);
    const [carParkMin, setCarParkMin] = useState<any>(null);
    const [carParkMax, setCarParkMax] = useState<any>(null);
    const [budgetMin, setBudgetMin] = useState<any>(null);
    const [budgetMax, setBudgetMax] = useState<any>(null);
    const [timeCloseMin, setTimeCloseMin] = useState<any>(null);
    const [timeCloseMax, setTimeCloseMax] = useState<any>(null);
    const [reqLocation, setReqLocation] = useState<any[]>([]);
    const [logList, setLogList] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [aminityName, setAminityName] = React.useState<string[]>([]);
    const [aminityId, setAminityId] = React.useState<string[]>([]);
    const [assignToName, setAssignToName] = React.useState<string[]>([]);
    const [assignToId, setAssignToId] = React.useState<string[]>([]);
    const [rlName, setRLName] = React.useState<string[]>([]);
    const [rlId, setRLId] = React.useState<string[]>([]);
    const [furnishName, setFurnishName] = React.useState<string[]>([]);
    const [furnishId, setFurnishId] = React.useState<string[]>([]);
    const [posessionName, setPosName] = React.useState<string[]>([]);
    const [projects, setProjects] = useState<any[]>([]);
    const [posId, setPosId] = React.useState<string[]>([]);
    const [cities, setCities] = useState<any[]>([]);
    const [vasthu, setVasthu] = useState<any[]>([]);
    const [ageOfProp, setAgeOfProp] = useState<any[]>([]);
    const [assignTo, setAssignTo] = useState<any[]>([]);
    const [amenities, setAmenities] = useState<any[]>([]);
    const [furnishStatus, setFurnishStatus] = useState<any[]>([]);
    const [posesStatus, setPosesStatus] = useState<any[]>([]);
    const [LeadNoteList, setLeadNoteList] = useState<any[]>([]);
    const [LeadNotes, setLeadNotes] = useState<any[]>([]);
    const [projectFacing, setProjectFacing] = useState<any[]>([]);
    const [filesVal, setFilesVal] = useState<any[]>([]);
    const [files, setFiles] = useState<any[]>([]);
    const [taskLead, setTaskLead] = useState<any[]>([]);
    const [priority, setPriority] = useState<any[]>([]);
    const [duplicateLead, setDuplicateLead] = useState<any[]>([]);
    const [salesManagerList, setSalesManagerList] = useState<any[]>([]);
    const [secondaryContactLead, setSecondaryContactLead] = useState<any[]>([]);
    const {currentUser, logout} = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [secSelected, setSecSelected] = useState(true);
    const [isFormError, setIsFormError] = useState(false);    
    const [parentId, setParentId] = useState<String>('');
    const [isFilesError, setIsFilesError] = useState(false);
    const [imgFullView, setImgFullView] = useState(false);
    const [imgSelect, setImgSelect] = useState('');
    const [noteEditVal, setNoteEditVal] = useState<any>('');
    const [requirementLocationName, setRequirementLocationName] = useState<any[]>([]);
    const [requirementLocationId, setRequirementLocationId] = useState<any[]>([]);


    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            // 'image/*': ['.jpeg', '.jpg'],         
            // 'document/*': ['.pdf']
        },
        onDrop: acceptedFiles => {
        console.log(acceptedFiles);
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        // setFormFiles(acceptedFiles);

        var test: any[] = [];
        acceptedFiles.map(file => {
            console.log(file.lastModified);
            let data = {
                "lastModified": file.lastModified,
                // "lastModifiedDate": file.lastModifiedDate,
                "name": file.name,
                "size": file.size,
                "type": file.type,
                // "webkitRelativePath": acceptedFiles.webkitRelativePath
            }            
            console.log(data);
            test.push(data);
            // setFilesData(data);
        });
        console.log('files =======');
        console.log(files);
        }
    });   

    console.log('LeadNoteList')
    console.log('currentUser')
    console.log(LeadNoteList)
    console.log(currentUser)
    
    const saveFiles = async () => {
        if(files.length > 0){
            // setLoading(true);
            setIsFilesError(false);
            try {

                var formData = new FormData();

                // formData.append('uploadfiles', JSON.stringify(files));
                
                for (var i = 0; i < files.length; i++) {
                    // console.log('filessssss')
                    // console.log(files[i]);
                    formData.append('uploadfiles', files[i]);
                }
               
                const headers = {
                    headers: {
                        "Content-type": "multipart/form-data",
                    },                    
                }
                               
                const saveContactFiles = await uploadMultipleFileLead(leadId, formData, headers)
        
                console.log('saveContactFiles');
                console.log(saveContactFiles);
                if(saveContactFiles != null){
                  setLoading(false);
                  var toastEl = document.getElementById('myToastUpdate');
                  const bsToast = new Toast(toastEl!);
                  bsToast.show();
                  setFiles([]);
                  leadFilesList(leadId);
                //   const notesResponse = await getContactNotes(leadId)
                //   setContactNotes(notesResponse);
                }
        
              } catch (error) {
                console.error(error)
                setLoading(false)
              }   
        }
    
        else {
            setIsFilesError(true);
        }
    }

    const replyDelete = async (id:any, parentId:any) => {
        var userId = currentUser?.id;
        console.log(id);
        console.log(userId);
        const deleteNotes = await deleteLeadNotes(id, userId, parentId);
        console.log(deleteNotes);
        if(deleteNotes != null){
            var toastEl = document.getElementById('myToastUpdate');
            const bsToast = new Toast(toastEl!);
            bsToast.show();
            leadNoteList();
            // setTaskNoteList(taskId);
        }
    }

    const replyEdit = async (id:any, val:any) => {
        setParentId(id);
        // let replyVal = (document.getElementById('child_reply'+id) as HTMLInputElement)!.value;        
        setNoteEditVal(val);    
    }

    const cancelEdit = async (id:any) => {
        setParentId('');
    }

    const editOnSubmit = async (id:any) => {
        setParentId(id);
        // setLoading(true);
        let editVal = (document.getElementById('edit_field'+id) as HTMLInputElement)!.value;
    
        if(editVal != ''){
            setIsFormError(false);
            try {
                var userId = currentUser?.id;
              
                var notesBody = {
                    "reply": editVal,
                    "user_id": userId
                };
                               
                const editNotesData = await updateLeadNotes(id, notesBody)
        
                console.log('editNotesData');
                console.log(editNotesData);
                if(editNotesData != null){
                  setLoading(false);
                  (document.getElementById('edit_field'+id) as HTMLInputElement).value = '';
                  setNoteEditVal('');
                  var toastEl = document.getElementById('myToastUpdate');
                  const bsToast = new Toast(toastEl!);
                  bsToast.show();
                  leadNoteList();
                //   const notesResponse = await getTaskNotes(taskId)
                //   setTaskNoteList(notesResponse);
                }
        
              } catch (error) {
                console.error(error)
                setLoading(false)
              }   
        }
    
        else {
            setIsFormError(true);
        }
    
    }

    const FetchLeadDetails =  async (leadId : number) => {
        // leadId : number
        setIsLoading(true);
        const fetchDetails = await getLeadDetail(leadId)
        console.log('lead Details');
        console.log(fetchDetails);
        setLeadDetail(fetchDetails);

        formik.setFieldValue('contact_id', fetchDetails.contact_id ?? '');
        formik.setFieldValue('looking_for', fetchDetails.looking_for ?? '');
        formik.setFieldValue('property_type', fetchDetails.property_type ?? '');
        formik.setFieldValue('city', fetchDetails.city ?? '');
        formik.setFieldValue('lead_source', fetchDetails.lead_source ?? '');
        formik.setFieldValue('lead_priority', fetchDetails.lead_priority ?? '');
        formik.setFieldValue('sales_manager', fetchDetails.sales_manager ?? '');
        formik.setFieldValue('lead_group', fetchDetails.lead_group ?? '');
        formik.setFieldValue('segment', fetchDetails.segment ?? '');
        // formik.setFieldValue('assign_to', fetchDetails.assign_to != null ? fetchDetails.assign_to: []);
        formik.setFieldValue('status', fetchDetails.status ?? '');
        formik.setFieldValue('fee_oppurtunity', fetchDetails.fee_oppurtunity ?? '');
        formik.setFieldValue('property_id', fetchDetails.property_id ?? '');

        formik2.setFieldValue('budget_min', fetchDetails.budget_min ?? '');
        formik2.setFieldValue('budget_max', fetchDetails.budget_max ?? '');
        formik2.setFieldValue('project_facing', fetchDetails.property_facing ?? '');
        formik2.setFieldValue('no_of_bedrooms_min', fetchDetails.no_of_bedrooms_min ?? '');
        formik2.setFieldValue('no_of_bedrooms_max', fetchDetails.no_of_bedrooms_max ?? '');
        formik2.setFieldValue('no_of_bathrooms_min', fetchDetails.no_of_bathrooms_min ?? '');
        formik2.setFieldValue('no_of_bathrooms_max', fetchDetails.no_of_bathrooms_max ?? '');
        formik2.setFieldValue('built_up_area_min', fetchDetails.built_up_area_min ?? '');
        formik2.setFieldValue('built_up_area_max', fetchDetails.built_up_area_max ?? '');
        formik2.setFieldValue('plot_area_min', fetchDetails.plot_area_min ?? '');
        formik2.setFieldValue('plot_area_max', fetchDetails.plot_area_max ?? '');
        // formik2.setFieldValue('requirement_location', fetchDetails.requirement_location ?? '');
        formik2.setFieldValue('age_of_property', fetchDetails.age_of_property_id ?? '');
        formik2.setFieldValue('vasthu_compliant', fetchDetails.vasthu_compliant_id ?? '');
        formik2.setFieldValue('car_park_min', fetchDetails.car_park_min ?? '');
        formik2.setFieldValue('car_park_max', fetchDetails.car_park_max ?? '');
        formik2.setFieldValue('timeline_for_closure_min', fetchDetails.timeline_for_closure_min ?? '');
        formik2.setFieldValue('timeline_for_closure_max', fetchDetails.timeline_for_closure_max ?? '');
        
        setOpportunity(fetchDetails.fee_oppurtunity);
        setProperty(fetchDetails.property_id);
        setBedroomsMin(fetchDetails.no_of_bedrooms_min);
        setBedroomsMax(fetchDetails.no_of_bedrooms_max);
        setBathroomsMin(fetchDetails.no_of_bathrooms_min);
        setBathroomsMax(fetchDetails.no_of_bathrooms_max);
        setPlotAreaMin(fetchDetails.plot_area_min);
        setPlotAreaMax(fetchDetails.plot_area_max);
        setBuiltAreaMin(fetchDetails.built_up_area_min);
        setBuiltAreaMax(fetchDetails.built_up_area_max);
        setCarParkMin(fetchDetails.car_park_min);
        setCarParkMax(fetchDetails.car_park_max);
        setBudgetMin(fetchDetails.budget_min);
        setBudgetMax(fetchDetails.budget_max);
        setTimeCloseMin(fetchDetails.timeline_for_closure_min);
        setTimeCloseMax(fetchDetails.timeline_for_closure_max);

        // var assignVal = [];

        var assignArray = [];
        var assignNameArray = [];
        if(fetchDetails.assign_to != null){
            assignArray = fetchDetails.assign_to.split(",").map((e:any) => {
                return parseInt(e);
            });
        }
        if(fetchDetails.assign_to_name != null){
            assignNameArray = fetchDetails.assign_to_name.split(",").map((e:any) => {
                return e;
            });
        }

        setAssignToId(assignArray);
        setAssignToName(assignNameArray);

        var requirement_locationarray = [];
        var requirement_location_namearray = [];
        if(fetchDetails.requirement_location != null){
            requirement_locationarray = fetchDetails.requirement_location.split(",").map((e:any) => {
                return parseInt(e);
            });
        }
        if(fetchDetails.requirement_location_name != null){
            requirement_location_namearray = fetchDetails.requirement_location_name.split(",").map((e:any) => {
                return e;
            });
        }

        setRequirementLocationId(requirement_locationarray);
        setRequirementLocationName(requirement_location_namearray);
        
        var amenityArray = [];
        var amenityNameArray = [];
        if(fetchDetails.amenities != null){
            amenityArray = fetchDetails.amenities.split(",").map((e:any) => {
                return parseInt(e);
            });
        }
        if(fetchDetails.amenities_name != null) {
            amenityNameArray = fetchDetails.amenities_name.split(",").map((e:any) => {
                return e;
            });
        }

        formik2.setFieldValue('amenities', amenityArray ?? '');
        setAminityId(amenityArray);
        setAminityName(amenityNameArray);


        var furnishingArray = [];
        var furnishingNameArray = [];
        if(fetchDetails.furnishing != null){
            furnishingArray = fetchDetails.furnishing.split(",").map((e:any) => {
                return parseInt(e);
            });
        }
        if(fetchDetails.furnishing_name != null){
            furnishingNameArray = fetchDetails.furnishing_name.split(",").map((e:any) => {
                return e;
            });
        }

        formik2.setFieldValue('furnishing', furnishingArray ?? '');
        setFurnishId(furnishingArray);
        setFurnishName(furnishingNameArray);
        
        
        var posessionArray = [];
        var posessionNameArray = [];
        if(fetchDetails.possession_status != null){
            posessionArray = fetchDetails.possession_status.split(",").map((e:any) => {
                return parseInt(e);
            });
        }
        if(fetchDetails.posession_name != null){
            posessionNameArray = fetchDetails.posession_name.split(",").map((e:any) => {
                return e;
            });
        }

        formik2.setFieldValue('possession_status', posessionArray ?? '');
        setPosId(posessionArray);
        setPosName(posessionNameArray);
        setIsLoading(false);
        
    }

    const projectList =  async () => {
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const projectResponse = await getProperties(userId, roleId);
        setProjects(projectResponse);
    }

    const SecondaryContactLeadList =  async () => {
        const Response = await getSecondaryContactLead(leadId);
        setSecondaryContactLead(Response);
    }

    const DuplicateLeadList =  async () => {
        const Response = await getDuplicateLead(leadId);
        setDuplicateLead(Response);
    }

    const contactsList =  async () => {   
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const contactsResponse = await getContacts(userId, roleId);
        var contactDropList = [];
    
        for(let i = 0; i < contactsResponse.length; i++) {
            var data = { "id": contactsResponse[i].id, "name": contactsResponse[i].first_name + ' '+ contactsResponse[i].last_name }
            contactDropList.push(data);
        }
    
        setContacts(contactDropList);
    }

    const lookingForList = async () => {
        // setLookingFor
        const lookingResponse = await getLookingFor();
        setLookingFor(lookingResponse);
    } 

    const segmentList = async () => {
        const segmentResponse = await getSegment();
        setSegment(segmentResponse);
    } 

    const taskLeadList = async () => {
        const taskLeadResponse = await getTaskLead(leadId);
        setTaskLead(taskLeadResponse);
    }

    const propertyTypeList = async () => {
        const propertyResponse = await getPropertyType();
        setPropertyType(propertyResponse);
    } 

    const reqLocationsList =  async () => {   
        const locResponse = await getReqLocations();
        setReqLocation(locResponse);
    }

    const salesManagerListList =  async () => {
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const Response = await getSalesManagerList(userId, roleId);
        setSalesManagerList(Response);
    }

    const leadSourceList = async () => {
        const sourceResponse = await getLeadSource();
        setLeadSource(sourceResponse);
    } 

    const leadFilesList =  async (leadId : number) => {   
        const contactFileResponse = await getMultiImage(leadId)
        setFilesVal(contactFileResponse);
        // console.log('files sdsdsd');
        // console.log(contactFileResponse);
    } 

    const leadGroupList = async () => {
        const groupResponse = await getLeadGroup();
        setLeadGroup(groupResponse);
    } 

    const leadStatusList = async () => {
        const statusResponse = await getLeadStatus();
        setLeadStatus(statusResponse);

        console.log('leads status');
        console.log(statusResponse);
    } 

    const minimaximize = () => {
        setIsExpand(current => !current);
    }
    
    const fullScreenChange = () => {
        setIsFullScreen(current => !current);
    }

    const imgViewChange = (id:any) => {
        console.log(id);
        setImgFullView(!imgFullView)
        setImgSelect(id)
    }

    const projectFacingList =  async () => {   
        const response = await getPropertyFacing();
        setProjectFacing(response);
    }

    const onDeleteFile = async (id:any) => {
        console.log(id);
        const deleteRes = await deleteMultipleImagesLeads(id);
        console.log(deleteRes);
        if(deleteRes != null){
            var toastEl = document.getElementById('myToastUpdate');
            const bsToast = new Toast(toastEl!);
            bsToast.show();
            leadFilesList(leadId);
        }
    }

    const handleChange = (event: SelectChangeEvent<typeof aminityName>) => {
        const {
          target: { value },
        } = event;
        var name = [];
        var id = [];
    
        for(let i = 0; i < value.length; i++){
          var fields = value[i].split('-');
    
          var n = fields[0];
          var d = fields[1];
    
          name.push(n);
          id.push(d);
        }
    
        console.log(name);
        console.log(id);
    
        setAminityId(id);
        setAminityName(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
    };

    const assingToChange = (event: SelectChangeEvent<typeof assignToName>) => {
        const {
            target: { value },
        } = event;

        var name = [];
        var id = [];
    
        for(let i = 0; i < value.length; i++){
          var fields = value[i].split('-');
    
          var n = fields[0];
          var d = fields[1];
    
          name.push(n);
          id.push(d);
        }
    
        console.log(name);
        console.log(id);
    
        setAssignToId(id);

        setAssignToName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const furnishingChange = (event: SelectChangeEvent<typeof furnishName>) => {
        const {
            target: { value },
        } = event;
        var name = [];
        var id = [];
    
        for(let i = 0; i < value.length; i++){
          var fields = value[i].split('-');
    
          var n = fields[0];
          var d = fields[1];
    
          name.push(n);
          id.push(d);
        }
    
        console.log(name);
        console.log(id);
    
        setFurnishId(id);
        setFurnishName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const locationHandleChange = (event: SelectChangeEvent<typeof requirementLocationName>) => {
        const {
          target: { value },
        } = event;
    
        var name = [];
        var id = [];
    
        for(let i = 0; i < value.length; i++){
          var fields = value[i].split('-');
    
          var n = fields[0];
          var d = fields[1];
    
          name.push(n);
          id.push(d);
        }
    
        console.log(name);
        console.log(id);
    
        setRequirementLocationId(id);
    
        setRequirementLocationName(
          typeof value === 'string' ? value.split(',') : value,
        );
      };

    const possessionChange = (event: SelectChangeEvent<typeof posessionName>) => {
        const {
            target: { value },
        } = event;
        var name = [];
        var id = [];
    
        for(let i = 0; i < value.length; i++){
          var fields = value[i].split('-');
    
          var n = fields[0];
          var d = fields[1];
    
          name.push(n);
          id.push(d);
        }
    
        console.log(name);
        console.log(id);
    
        setPosId(id);
        setPosName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const citiesList =  async () => {   
        const cityResponse = await getCity();
    
        setCities(cityResponse);
    }

    const vasthuList =  async () => {   
        const vasthuResponse = await getVasthu();
        setVasthu(vasthuResponse);
    }
    
    const ageOfPropList =  async () => {   
        const response = await getAgeOfProperty();
        setAgeOfProp(response);
    }

    const assignToList =  async () => {   
        var userId = currentUser?.id;
        var roleId = currentUser?.designation; 
        const assignToResponse = await getAssignToList(userId, roleId);
        setAssignTo(assignToResponse);
    }

    const amenitiesList =  async () => {   
        const amenityResponse = await getAmenity();
        if(amenityResponse != null){
            setAmenities(amenityResponse);
        }
    }

    const furnishStatusList =  async () => {   
        const furnishResponse = await getFurnishStatus();
        setFurnishStatus(furnishResponse);
    }

    const posesStatusList =  async () => {   
        const posessionResponse = await getPosesStatus();
        setPosesStatus(posessionResponse);
    }

    const leadNoteList =  async () => {   
        const leadNoteResponse = await getLeadNotes(leadId);
        setLeadNoteList(leadNoteResponse);
    }

    const fetchLog = async (leadId : number) => {
        const fetchLogList = await getLog(leadId);
        setLogList(fetchLogList);
    }

    const PriorityList = async () => {
        const Response = await getPriority();
        setPriority(Response);
        console.log('ssefrgsergrtyerytyu');
        console.log(Response);
    }

    const formik = useFormik({
        initialValues,
        validationSchema: leadUpdateSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
        //   setLoading(true)
        //   console.log('lead form body');
        //   console.log(values);
        try {

        var userId = currentUser?.id;
        var roleId = currentUser?.designation;

        console.log('sddddddddassdsf');

        const body = {
            "contact_id": values.contact_id,
            "looking_for": values.looking_for,
            "property_type": values.property_type,
            "sales_manager": values.sales_manager,
            "city": values.city,
            "lead_source": values.lead_source,
            "lead_priority": values.lead_priority,
            "lead_group": values.lead_group,
            "segment": values.segment,
            "fee_oppurtunity": values.fee_oppurtunity,
            "property_id": values.property_id,
            "status": values.status,
            "assign_to": assignToId.length > 0 ? assignToId : [userId],
            "created_by": userId,
        }

        const updateLeadData = await updateLead(leadId ,body);

        console.log('updateLeadData');
        console.log(updateLeadData);
        // document.getElementById('kt_contact')?.classList.remove('drawer-on');
        if(updateLeadData != null){
            setLoading(false);
            setDetailClicked(false);
            document.getElementById('kt_expand_close')?.click();
            var toastEl = document.getElementById('myToastUpdate');
            const bsToast = new Toast(toastEl!);
            bsToast.show();
            const characterResponse = await getLeadsByRole(userId, roleId)
            setLeadList(characterResponse);

           
        }

        } catch (error) {
        console.error(error)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
        }
    }})

    const formikNotes = useFormik({
        initialValues,
        validationSchema: notesFormSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          console.log('on presssed');
        //   setLoading(true)
          try {
    
            var userId = currentUser?.id;

            var notesBody = {
                "reply": values.reply,
                "lead_id": leadId,
                "user_id": userId,
                "parent_id": 0
            };
                           
            const leadNotesData = await saveLeadNotes(notesBody)
    
            console.log('updateContactAddressData');
            console.log(leadNotesData);
            if(leadNotesData != null){
              setLoading(false);
              var toastEl = document.getElementById('myToastUpdate');
              const bsToast = new Toast(toastEl!);
              bsToast.show();
              resetForm();
              const leadNoteResponse = await getLeadNotes(leadId);
                setLeadNoteList(leadNoteResponse);    
            }
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
        },
      })

    const formik2 = useFormik({
        initialValues,
        validationSchema: leadUpdateSchema2,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
        //   setLoading(true)
        //   console.log('lead form body');
        //   console.log(values);
        try {

        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        console.log('sddddddddassdsf');

        const body = {
            "requirement_location": requirementLocationId,
            "budget_min": values.budget_min,
            "budget_max": values.budget_max,
            "no_of_bedrooms_min": values.no_of_bedrooms_min,
            "no_of_bedrooms_max": values.no_of_bedrooms_max,
            "no_of_bathrooms_min": values.no_of_bathrooms_min,
            "no_of_bathrooms_max": values.no_of_bathrooms_max,
            "built_up_area_min": values.built_up_area_min,
            "built_up_area_max": values.built_up_area_max,
            "plot_area_min": values.plot_area_min,
            "plot_area_max": values.plot_area_max,
            "property_facing": values.project_facing,
            "possession_status": posId,
            "age_of_property": values.age_of_property,
            "vasthu_compliant": values.vasthu_compliant,
            "furnishing": furnishId,
            "car_park_min": values.car_park_min,
            "car_park_max": values.car_park_max,
            "timeline_for_closure_min": values.timeline_for_closure_min,
            "timeline_for_closure_max": values.timeline_for_closure_max,
            "amenities": aminityId,
            "created_by": userId,
        }

        const updateLeadData = await updateLeadReq(leadId ,body);

        console.log('updateLeadData');
        console.log(updateLeadData);
        // document.getElementById('kt_contact')?.classList.remove('drawer-on');
        if(updateLeadData != null){
            setLoading(false);
            setDetailClicked(false);
            document.getElementById('kt_expand_close')?.click();
            var toastEl = document.getElementById('myToastUpdate');
            const bsToast = new Toast(toastEl!);
            bsToast.show();
            const characterResponse = await getLeadsByRole(userId, roleId)
            setLeadList(characterResponse);
            // resetForm();
            // setAminityName([]);
            // setAssignToName([]);
            // setFurnishName([]);
            // setPosName([]);
        }

        } catch (error) {
        console.error(error)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
        }
    }})

    const closeDialogue = () => {
        setDetailClicked(false);
        var offCanvasEl = document.getElementById('kt_expand'+leadId);
        offCanvasEl?.classList.add('invisible');
        const bsOffcanvas = new Offcanvas(offCanvasEl!);
        bsOffcanvas.hide();
    }

    const replyOnSubmit = async (id:any) => {
        setParentId(id);
        let replyVal = (document.getElementById('child_reply'+id) as HTMLInputElement)!.value;
        console.log('sdsdsdsd');
        console.log(replyVal);
    
        if(replyVal != ''){
            setIsFormError(false);
            try {
                var userId = currentUser?.id;
                var notesBody = {
                    "reply": replyVal,
                    "lead_id": leadId,
                    "user_id": userId,
                    "parent_id": id
                };
                               
                const saveContactNotesData = await saveLeadNotes(notesBody)
        
                console.log('saveContactNotesData');
                console.log(saveContactNotesData);
                if(saveContactNotesData != null){
                  setLoading(false);
                  (document.getElementById('child_reply'+id) as HTMLInputElement).value = ''
                  var toastEl = document.getElementById('myToastUpdate');
                  const bsToast = new Toast(toastEl!);
                  bsToast.show();
                  const notesResponse = await getLeadNotes(leadId)
                  setLeadNoteList(notesResponse);
                }
        
              } catch (error) {
                console.error(error)
                setLoading(false)
              }   
        }
    
        else {
            setIsFormError(true);
        }
    
    }
    
    const handleClick = (index:any) => {
        setFiles([
            ...files.slice(0, index),
            ...files.slice(index + 1, files.length)
          ]);
    }
  
    const thumbs = files.map((file:any, index:any) => {
        const pieces = file.path.split('.');
        const last = pieces[pieces.length - 1];
        return(
        <div style={thumb} key={file.name} className="position-relative">
            <div style={thumbInner}>
            { last != 'pdf' ?
                <img
                src={file.preview}
                className='w-100 h-100 img-thumbnail rounded fit_contain'
                onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />: <img
                src={toAbsoluteUrl('/media/svg/files/pdf.svg')}
                className='w-100 h-100 img-thumbnail rounded fit_contain'
                />
                }
                
            </div>
            <a onClick={() => handleClick(index)} className='icon position-absolute top-0 end-0 rounded bg-gray border-0'><span className="svg-icon svg-icon-muted"><svg width="" height="30" viewBox="3 3 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
            <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)" fill="currentColor"/>
            <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)" fill="currentColor"/>
            </svg>
            </span></a>
            {/* <button
                style={thumbButton}
                onClick={() =>
                    editImage(file, (output:any) => {
                        const updatedFiles = [...files];

                        // replace original image with new image
                        updatedFiles[index] = output;

                        // revoke preview URL for old image
                        if (file.preview) URL.revokeObjectURL(file.preview);

                        // set new preview URL
                        Object.assign(output, {
                            preview: URL.createObjectURL(output),
                        });

                        // update view
                        setFiles(updatedFiles);
                    })
                }
            >
                Edit
            </button> */}
        </div>
    )});

    const sendMail = () => {
        // alert('mail')
    }
    const sendWatsapp = () => {
        // alert('watsapp')
    }
    const sendSMS = () => {
        // alert('sms')
    }
    const sendCall = () => {
        // alert('call')
    }

    const mailSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        subject: Yup.string().required('Subject is required'),       
        share_with: Yup.string().required('Share with is required'),
        module_id: Yup.string().required('Module is required'),       
        body: Yup.string().required('Body is required'),       
    })

    const formikMail = useFormik({
        initialValues,
        validationSchema: mailSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
        //   setLoading(true)
          try {
              
            const body = {
                "title" : values.title,
                "subject" : values.subject,
                "share_with" : values.share_with,
                "module_id" :values.module_id,
                "body" : values.body,
            }
                
            console.log('lead form body');
            console.log(body);
            
                // const saveTemplatMailData = await saveTemplateMail(body);
            
                // if(saveTemplatMailData != null){
                //     setLoading(false);
                //     var toastEl = document.getElementById('myToastAdd');
                //     const bsToast = new Toast(toastEl!);
                //     bsToast.show();
                //     resetForm();
                //     AllTemplatesMailList();
                // }
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
    }})

    const [search, setSearch] = useState<any>("");
    const [filtered, setFiltered] = useState<any[]>([]);
    const [allTemplatesMail, setAllTemplatesMail] = useState<any[]>([]);


    const AllTemplatesMailList =  async () => {
        const AllTemplatesMailResponse = await getAllTemplatesMail()
        setAllTemplatesMail(AllTemplatesMailResponse);
    }

    const MailById = async (id:any) => {
        // setSelectedId(id);
        // const TemplatesMailResponse = await getTemplateMail(id)
        // setTemplatesMail(TemplatesMailResponse);
        // setDataBinded(true);
        // formik.setFieldValue('title', TemplatesMailResponse.title ?? '');
        // formik.setFieldValue('subject', TemplatesMailResponse.subject ?? '');
        // formik.setFieldValue('share_with', TemplatesMailResponse.share_with ?? '');
        // formik.setFieldValue('module_id', TemplatesMailResponse.module_id ?? '');
        // formik.setFieldValue('body', TemplatesMailResponse.body ?? '');
    }

    function filterItem(item: any, search: string) {
        if (search.startsWith("@")) {
          const bucket = search.substring(1).split(":");
          const searchBy = bucket[0];
          const searchFor = bucket[1];
          const searchByType = getType(item[searchBy]);
      
          // When we want to limit the search by provided property types
          // i.e. filterBy: ["tags", "flavors", "ip", ...];
          // if (item.filterBy && item.filterBy.length) {
          //     if (!item.filterBy.includes(searchBy)) return false;
          // }
      
          if (!searchFor) return false;
          console.log("**** searchBy: ", searchBy);
          console.log("**** searchFor: ", searchFor);
          console.log("**** item: ", item);
          // When search is @status:error
          if (searchByType == "string") {
            // When search is @status:!error
            if (searchFor.startsWith("!")) {
              const bucket = searchFor.split("!");
              const searchForVal = bucket[1];
              return !item[searchBy].includes(searchForVal);
            }
            return item[searchBy].includes(searchFor);
          }
      
          // When search is @flavors:small..
          if (searchByType == "array") {
            // When search is @flavors:!small
            if (searchFor.startsWith("!")) {
              const bucket = searchFor.split("!");
              const searchForVal = bucket[1];
              return item[searchBy].find((val: string | any[]) => !val.includes(searchForVal));
            }
            return item[searchBy].find((val: string | any[]) => val.includes(searchFor));
          }
      
          // When search is @tags:org=sales
          if (searchByType == "object") {
            // When search is @tags:!sales
            if (searchFor.startsWith("!")) {
              const bucket = searchFor.split("!");
              const val = bucket[1] || "";
              return !item[searchBy][val];
            }
            // When search is @tags:org!=sales
            if (searchFor.includes("!=")) {
              const bucket2 = searchFor.split("!=");
              const key = bucket2[0];
              const val = bucket2[1] || "";
              return item[searchBy][key] && !item[searchBy][key].includes(val);
            }
            const bucket2 = searchFor.split("=");
            const key = bucket2[0];
            const val = bucket2[1] || "";
            return item[searchBy][key] && item[searchBy][key].includes(val);
          }
        } else {
          // When search is !computer.....
          if (search.startsWith("!")) {
            const bucket = search.split("!");
            const searchFor = bucket[1];
            return !item.title.includes(searchFor);
          }
          // When search is computer.....
          return item.title.includes(search);
        }
      }
      
      const getType = (value:any) => {
        if (Array.isArray(value)) {
          return "array";
        } else if (typeof value == "string") {
          return "string";
        } else if (value == null) {
          return "null";
        } else if (typeof value == "boolean") {
          return "boolean";
        } else if (Number(value) == value) {
          return "number";
        } else if (typeof value == "object") {
          return "object";
        }
        return "string";
      };

    useEffect(() => {
        AllTemplatesMailList();
        var filteredData: any[] = [];
        if (search.length > 0) {
            allTemplatesMail.forEach((item) => {
            if (filterItem(item, search)) {
            filteredData.push({ ...item });
            }
        });
        } else {
            filteredData = [];
        }
        setFiltered(filteredData);        
    }, [search]);

    useEffect(() => {
        FetchLeadDetails(leadId);
        contactsList();
        lookingForList();
        projectList();
        segmentList();
        propertyTypeList();
        leadSourceList();
        leadGroupList();
        fetchLog(leadId);
        leadStatusList();
        reqLocationsList();
        citiesList();
        vasthuList();
        ageOfPropList();
        assignToList();
        amenitiesList();
        furnishStatusList();
        posesStatusList();
        leadNoteList();
        projectFacingList();
        DuplicateLeadList();
        salesManagerListList();
        SecondaryContactLeadList();
        leadFilesList(leadId);
        taskLeadList();
        PriorityList();
    }, [leadId]);

    return(
        <div className={isExpand ? isFullScreen ? "w-100 contact_details_page full_screen" : "w-75 contact_details_page full_screen m-5": "contact_details_page small_screen d-flex align-items-end justify-content-end m-5"}>
            {
            isLoading ? 
            <div className="card main_bg h-100">
                <div className='w-100 h-100'>
                    <div className='d-flex justify-content-center flex-column align-items-center vh-100'>
                        <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
                        <div className="spinner-border taskloader" style={{width:"3rem", height: "3rem"}} role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div> 
                </div> 
            </div> :
            isExpand ?
            <div className="card main_bg h-100">
                <div className="card-header d-flex align-items-center">
                    <div className="row w-100">
                        <div className="col-sm-12 col-12 d-flex align-items-center justify-content-end">
                            <div className='card-toolbar'>
                                <button className="btn mx-3 expand_btn" onClick={fullScreenChange}>
                                    <img src={isFullScreen ? toAbsoluteUrl('/media/custom/overview-icons/comp_white.svg') : toAbsoluteUrl('/media/custom/overview-icons/expand_white.svg')} className="svg_icon" alt='' />
                                </button>
                                <button className="btn mx-3 minimize_btn" onClick={minimaximize}>
                                    <img src={toAbsoluteUrl('/media/custom/overview-icons/minimize_white.svg')} className="svg_icon" alt='' />
                                </button>
                                <button
                                    type='button'
                                    className='btn  me-n5 mx-3 close_btn'
                                    data-bs-dismiss="offcanvas"
                                    id='kt_expand_close'
                                    onClick={closeDialogue}
                                    aria-label="Close"
                                    >
                                        <img src={toAbsoluteUrl('/media/custom/overview-icons/cancel_white.svg')} className="svg_icon" alt='' />
                                </button>
                            </div>
                        </div>
                        {/* <div className="col-sm-9 col-12 d-flex align-items-center justify-content-end">
                            <div className="input-group search_btn me-3">
                                <input type="text" className="form-control" placeholder="Search"/>
                                <div className="input-group-append">
                                    <button className="btn bg_primary" type="button">
                                        <img src={toAbsoluteUrl('/media/custom/search_white.svg')} className="svg_icon" alt='' />
                                    </button>
                                </div>
                            </div>
                            <a className="me-4 btn btn-sm me-4 btn_primary" id='kt_lead_toggle'>Add+</a>
                            <select className="form-select toggle_white me-4 btn btn-sm btn_primary" name="action" id="action">
                                <option value="action">Action</option>
                                <option value="add">Add</option>
                                <option value="inport">Import</option>
                                <option value="edit">Edit</option>
                            </select>
                        </div> */}
                    </div>
                    {/* <h3>Contact Full screen</h3> */}
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="card-group">
                            <div className="col-xxl-6 col-12 mb-3">
                                <div className="card bs_1 name_card h-100 mx-2">
                                    <div className="card-body p-3">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0">
                                                <img src={toAbsoluteUrl('/media/avatars/300-23.jpg')} className="user_img" alt='' />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="d-flex">
                                                            
                                                            <h4 className="mb-0 ms-2">{leadDetail.contact_name}</h4>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <a href={"mailto:"+ leadDetail.email} className="btn_soft_primary"><i className="fas fa-envelope"></i></a>
                                                        <a href={"tel:"+ leadDetail.mobile} className="btn_soft_primary"><i className="fas fa-phone-alt"></i></a>
                                                        <a href="#" className="btn_soft_primary"><i className="fas fa-clipboard-list"></i></a>
                                                        <a href={"https://api.whatsapp.com/send?phone=" + leadDetail.mobile} target="new" className="btn_soft_primary">
                                                            <img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} className="svg_icon" alt='' />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-6 col-12 mb-3">
                                <div className="card bs_1 h-100 mx-2 info_card">
                                    <div className="card-body p-3">
                                        <div className='row w-100 p-3'>
                                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 p-2">
                                                <small className="mb-0">Email</small>
                                                <p className="mb-0">{leadDetail.email}</p>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 p-2">
                                                <small className="mb-0">Phone Number</small>
                                                <p className="mb-0">{leadDetail.mobile}</p>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 p-2">
                                                <small className="mb-0">Contact Category</small>
                                                <p className="mb-0">{leadDetail.contact_category_name}</p>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 p-2">
                                                <small className="mb-0">Created By</small>
                                                <p className="mb-0">{leadDetail.created_by_name}</p>
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab_container bg_white br_10 bs_1 mx-2">
                        <div className="row mt-4">
                            <div className="col-12">
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'overview' ? "nav-link active" : "nav-link"} id="overview-tab" data-bs-toggle="pill" data-bs-target={"#overview"+leadId} type="button" role="tab" aria-controls={"overview"+leadId} aria-selected="true">Overview</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'notes' ? "nav-link active" : "nav-link"} id="notes-tab" data-bs-toggle="pill" data-bs-target={"#notes"+leadId} type="button" role="tab" aria-controls="notes" aria-selected="false">Notes</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'files' ? "nav-link active" : "nav-link"} id="files-tab" data-bs-toggle="pill" data-bs-target={"#files"+leadId} type="button" role="tab" aria-controls="files" aria-selected="false">Files</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'message' ? "nav-link active" : "nav-link"} id="message-tab" data-bs-toggle="pill" data-bs-target={"#message"+leadId} type="button" role="tab" aria-controls="message" aria-selected="false">Messages</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'req' ? "nav-link active" : "nav-link"} id="message-tab" data-bs-toggle="pill" data-bs-target={"#requirements"+leadId} type="button" role="tab" aria-controls="message" aria-selected="false">Requirements</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'sec_contact' ? "nav-link active" : "nav-link"} id="contact-tab" data-bs-toggle="pill" data-bs-target={"#contact"+leadId} type="button" role="tab" aria-controls="contact" aria-selected="false">Sec.contact</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'lead' ? "nav-link active" : "nav-link"} id="lead-tab" data-bs-toggle="pill" data-bs-target={"#lead"+leadId} type="button" role="tab" aria-controls="lead" aria-selected="false">Lead</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'duplicate' ? "nav-link active" : "nav-link"} id="duplicate-tab" data-bs-toggle="pill" data-bs-target={"#duplicate"+leadId} type="button" role="tab" aria-controls="duplicate" aria-selected="false">Duplicate</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'task' ? "nav-link active" : "nav-link"} id="tasks-tab" data-bs-toggle="pill" data-bs-target={"#tasks"+leadId} type="button" role="tab" aria-controls="tasks" aria-selected="false">Tasks</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'timeline' ? "nav-link active" : "nav-link"} id="timeline-tab" data-bs-toggle="pill" data-bs-target={"#timeline"+leadId} type="button" role="tab" aria-controls="timeline" aria-selected="false">Active timeline</button>
                                    </li>
                                </ul>
                                <div className="tab-content pt-5" id="pills-tabContent">
                                    <div className={tabInfo == 'overview' ? "tab-pane fade show active": "tab-pane fade"} id={"overview"+leadId} role="tabpanel" aria-labelledby="overview-tab">
                                        <form noValidate onSubmit={formik.handleSubmit}>
                                            <div className="row">
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Contact Person</label>
                                                    <div className="input-group mb-3 input_prepend py-1">
                                                        <select className="btn btn-sm w-100 text-start form-select" {...formik.getFieldProps('contact_id')}>
                                                            <option value=''>Contact Person</option>
                                                            {contacts.map((contactsVal,i) =>{
                                                                return (
                                                                    <option value={contactsVal.id} selected={contactsVal.id == leadDetail.contact_id} key={i}>{contactsVal.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div> 
                                                    {formik.touched.contact_id && formik.errors.contact_id && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert' className='text-danger'>{formik.errors.contact_id}</span>
                                                        </div>
                                                    </div>
                                                    )}
                                                </div>
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Looking for</label>
                                                    <div className="input-group mb-3 input_prepend py-1">
                                                        <select className={clsx(
                                                            'btn btn-sm w-100 text-start form-select',
                                                            {
                                                                'is-invalid': formik.touched.looking_for && formik.errors.looking_for,
                                                            },
                                                            {
                                                                'is-valid': formik.touched.looking_for && !formik.errors.looking_for,
                                                            }
                                                            )} {...formik.getFieldProps('looking_for')}>
                                                                <option value=''>Looking For</option>
                                                            {lookingFor.map((lookingForVal,i) =>{
                                                                return (
                                                                    <option value={lookingForVal.id} selected={lookingForVal.id == leadDetail.looking_for} key={i}>{lookingForVal.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div> 
                                                    {formik.touched.looking_for && formik.errors.looking_for && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert' className='text-danger'>{formik.errors.looking_for}</span>
                                                        </div>
                                                    </div>
                                                    )}
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Segment</label>
                                                    <div className="input-group mb-3 input_prepend py-1">
                                                        <select className="btn btn-sm w-100 text-start form-select" {...formik.getFieldProps('segment')}>
                                                        <option value=''>Segment</option>
                                                            {segment.map((segmentVal,i) =>{
                                                                return (
                                                                    <option value={segmentVal.id} selected={segmentVal.id == leadDetail.segment} key={i}>{segmentVal.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div> 
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Lead Priority</label>
                                                    <div className="input-group mb-3 input_prepend py-1">
                                                    <select 
                                                        {...formik.getFieldProps('lead_priority')}
                                                        className="btn btn-sm w-100 text-start form-select">
                                                            <option value=''>select</option>                                            
                                                            {priority.map((priorityVal,i) =>{
                                                                return (
                                                                    <option value={priorityVal.id} selected={priorityVal.id == leadDetail.lead_priority} key={i}>{priorityVal.name}</option> 
                                                            )})}                                            
                                                        </select>
                                                    </div> 
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Sales Manager</label>
                                                    <div className="input-group mb-3 input_prepend py-1">
                                                        <select className="btn btn-sm w-100 text-start form-select" {...formik.getFieldProps('sales_manager')}>
                                                        <option value=''>Select</option>
                                                            {salesManagerList.map((manager,i) =>{
                                                                return (
                                                                    <option value={manager.id} selected={manager.id == leadDetail.contact_id} key={i}>{manager.first_name +' '+ manager.last_name}</option>  
                                                            )})}
                                                        </select>
                                                    </div> 
                                                </div>
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Property Type</label>
                                                    <div className="input-group mb-3 input_prepend py-1">
                                                        <select className="btn btn-sm w-100 text-start form-select" {...formik.getFieldProps('property_type')}>
                                                        <option value=''>Property Type</option>
                                                            {propertyType.map((propertyVal,i) =>{
                                                                return (
                                                                    <option value={propertyVal.id} selected={propertyVal.id == leadDetail.property_type} key={i}>{propertyVal.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div> 
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Lead Source</label>
                                                    <div className="input-group mb-3 input_prepend py-1">
                                                        <select className="btn btn-sm w-100 text-start form-select" {...formik.getFieldProps('lead_source')}>
                                                        <option value=''>Lead Source</option>
                                                            {leadSource.map((sourceVal,i) =>{
                                                                return (
                                                                    <option value={sourceVal.id} selected={sourceVal.id == leadDetail.lead_source} key={i}>{sourceVal.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div> 
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Lead Group</label>
                                                    <div className="input-group mb-3 input_prepend py-1">
                                                        <select className="btn btn-sm w-100 text-start form-select" {...formik.getFieldProps('lead_group')}>
                                                        <option value=''>Lead Group</option>
                                                            {leadGroup.map((groupVal,i) =>{
                                                                return (
                                                                    <option value={groupVal.id} selected={groupVal.id == leadDetail.lead_group} key={i}>{groupVal.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div> 
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Opportunity Value</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <input type="text" className="form-control" {...formik.getFieldProps('fee_oppurtunity')} onChange={(e) => {
                                                            setOpportunity(e.target.value)
                                                            formik.setFieldValue('fee_oppurtunity', e.target.value ?? '')
                                                        }} value={opportunity} placeholder=""/>
                                                    </div>
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Project</label>
                                                    <div className="input-group mb-3 input_prepend py-1">
                                                        <select className="btn btn-sm w-100 text-start form-select" {...formik.getFieldProps('property_id')}>
                                                        <option value=''>Select</option>
                                                            {projects.map((projectsVal,i) =>{
                                                                return (<>
                                                                    {projectsVal.name_of_building != null &&
                                                                    <option value={projectsVal.id} selected={projectsVal.id == leadDetail.property_id} key={i}>{projectsVal.name_of_building}</option> }</>
                                                            )})}
                                                        </select>
                                                    </div> 
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Status</label>
                                                    <div className="input-group mb-3 input_prepend py-1">
                                                        <select className="btn btn-sm w-100 text-start form-select" {...formik.getFieldProps('status')}>
                                                        <option value=''>Status</option>
                                                            {leadStatus.map((statusVal,i) =>{
                                                                return (
                                                                    <option value={statusVal.id} selected={statusVal.id == leadDetail.status} key={i}>{statusVal.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div> 
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3 edit_page_form">
                                                    <label htmlFor="basic-url" className="form-label">Assign To</label>
                                                    <FormControl sx={{ m: 0, width: "100%", mt: 0 }}>
                                                        <Select
                                                            multiple
                                                            displayEmpty
                                                            value={assignToName}
                                                            onChange={assingToChange}
                                                            input={<OutlinedInput />}
                                                            renderValue={(selected) => {
                                                                // selected = assignToName;
                                                                var name = [];
                                                                var id = [];

                                                                for(let i = 0; i < selected.length; i++){
                                                                    var fields = selected[i].split('-');

                                                                    var n = fields[0];
                                                                    var d = fields[1];

                                                                    name.push(n);
                                                                    id.push(d);
                                                                }
                                                                if (selected.length === 0) {
                                                                return <p>Assign To</p>;
                                                                }

                                                                return name.join(', ');
                                                            }}
                                                            className='multi_select_field form-control'
                                                            MenuProps={MenuProps}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                            >
                                                            <MenuItem  value="as1">
                                                                <em>Assign To</em>
                                                            </MenuItem>
                                                            {assignTo.map((assignVal) => (
                                                                <MenuItem
                                                                key={assignVal.user_id}
                                                                value={assignVal.employee_name+'-'+assignVal.user_id}
                                                                style={getStyles(assignVal.employee_name, assignToName, theme)}
                                                                >
                                                                {assignVal.employee_name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">City</label>
                                                    <div className="input-group mb-3 input_prepend py-1">
                                                        <select 
                                                        {...formik.getFieldProps('city')} 
                                                        className="btn btn-sm w-100 text-start form-select">
                                                            <option value="">Select location</option>
                                                            {cities.map((cityVal,i) =>{
                                                                return (
                                                                    <option value={cityVal.id} selected={cityVal.id == leadDetail.city} key={i}>{cityVal.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div> 
                                                </div>
                                                <div className="col-12 d-flex justify-content-center mb-4">
                                                <button
                                                    type='submit'
                                                    id='kt_sign_up_submit'
                                                    className='btn btn_primary text-primary'
                                                    disabled={formik.isSubmitting}
                                                    >
                                                    {!loading && <span className='indicator-label'>Save
                                                    <KTSVG
                                                    path='/media/custom/save_white.svg'
                                                    className='svg-icon-3 svg-icon-primary ms-2'
                                                    />
                                                    </span>}
                                                    {loading && (
                                                        <span className='indicator-progress' style={{display: 'block'}}>
                                                        Please wait...{' '}
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    )}
                                                </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className={tabInfo == 'notes' ? "tab-pane fade show active": "tab-pane fade"} id={"notes"+leadId} role="tabpanel" aria-labelledby="notes-tab">
                                    <div className="card mb-5 mb-xl-8">
                                            <div className='card-body pb-0'>
                                                <div className='main_bg px-lg-5 px-4 pt-4 pb-1 mb-6'>
                                                    <div className='d-flex align-items-center mb-3'>
                                                    
                                                        {/* begin::User */}
                                                        <div className='d-flex align-items-center flex-grow-1'>
                                                            {/* begin::Info */}
                                                            <div className='d-flex flex-column'>
                                                                <h3 className='text-gray-800 text-hover-primary fs-6 fw-bolder'>
                                                                    Add Note
                                                                </h3>
                                                            </div>
                                                            {/* end::Info */}
                                                        </div>
                                                        {/* end::User */}
                                                    </div>
                                                    <form noValidate onSubmit={formikNotes.handleSubmit} className='position-relative mb-6 pb-4 border-bottom border-secondary'>
                                                        <input {...formikNotes.getFieldProps('reply')}
                                                            className='form-control border-0 p-0 pe-10 resize-none min-h-25px'
                                                            data-kt-autosize='true'                                                        
                                                            placeholder='Reply..'
                                                        />

                                                        <div className='position-absolute top-0 end-0 me-n5'>
                                                            {/* <span className='btn btn-icon btn-sm btn-active-color-primary pe-0 me-2'>
                                                            <KTSVG
                                                                path='/media/icons/duotune/communication/com012.svg'
                                                                className='svg-icon-3 mb-3'
                                                            />
                                                            </span> */}

                                                            <button type='submit' disabled={formikNotes.isSubmitting} className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                <KTSVG path='/media/icons/duotune/general/gen016.svg' className='svg-icon-2 mb-3' />
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className='notes_list mt-4 card-body'>
                                                    <h4 className='mb-3'>Notes List</h4>
                                                    <hr/>
                                                    {LeadNoteList.map((leadNote, i) => {
                                                        return (
                                                    <div className='mb-5' key={leadNote.id}>
                                                        {leadNote.reply1 == 'NO'
                                                        ? <div className='note_question'>
                                                            {/* begin::Header */}
                                                            <div className='d-flex align-items-center mb-3'>
                                                                {/* begin::User */}
                                                                <div className='d-flex align-items-center flex-grow-1'>
                                                                    {/* begin::Avatar */}
                                                                    <div className='symbol symbol-45px me-5'>
                                                                    <img onError={e => { e.currentTarget.src = toAbsoluteUrl('/media/avatars/300-6.jpg') }} src={leadNote.user_profile_image != '' ? leadNote.user_profile_image ?? toAbsoluteUrl('/media/avatars/300-6.jpg') : toAbsoluteUrl('/media/avatars/300-6.jpg')} alt='' />
                                                                    </div>
                                                                    {/* end::Avatar */}

                                                                    {/* begin::Info */}
                                                                    <div className='d-flex flex-column'>
                                                                    <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bolder'>
                                                                        {leadNote.user_name ?? 'user'}
                                                                    </a>
                                                                    <span className='text-gray-400 fw-bold'>{Moment(leadNote.created_at).format("DD-MMMM-YYYY HH:mm")}</span>
                                                                    </div>
                                                                    {/* end::Info */}
                                                                </div>
                                                                {/* end::User */}
                                                            </div>
                                                            {/* end::Header */}

                                                            {/* begin::Post */}
                                                            <div className='mb-7 pb-5 border-bottom border-secondary d-flex justify-content-between'>
                                                                {/* begin::Text */}
                                                                { noteEditVal != '' && parentId == leadNote.id ?
                                                                            <div className='text-gray-800 position-relative w-75'>
                                                                                <input
                                                                                    className='form-control border-0 p-0 pe-10 resize-none min-h-25px'
                                                                                    data-kt-autosize='true'
                                                                                    // rows={1}
                                                                                    placeholder='Reply..'
                                                                                    id={'edit_field'+leadNote.id}
                                                                                    defaultValue={noteEditVal}
                                                                                    // onChange={(e) => childReplyChange(e)}
                                                                                ></input>
                                                                                {/* <div className='position-absolute top-0 end-0 me-n5'>     
                                                                                    <button type='button' onClick={() => editOnSubmit(contactNote.id)} className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                    <KTSVG path='/media/icons/duotune/general/gen016.svg' className='svg-icon-2 mb-3' />
                                                                                    </button>
                                                                                </div> */}
                                                                            </div>
                                                                            : 
                                                                            <div className='text-gray-800'>
                                                                             {leadNote.reply}
                                                                            </div>
                                                                            }
                                                                            { currentUser?.id == leadNote.user_id ?
                                                                            <span>
                                                                                { noteEditVal != '' && parentId == leadNote.id ?
                                                                                <><button type='button' onClick={() => cancelEdit(leadNote.id)} className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-icon-2 mb-3"><path opacity="0.3" d="M6.7 19.4L5.3 18C4.9 17.6 4.9 17 5.3 16.6L16.6 5.3C17 4.9 17.6 4.9 18 5.3L19.4 6.7C19.8 7.1 19.8 7.7 19.4 8.1L8.1 19.4C7.8 19.8 7.1 19.8 6.7 19.4Z" fill="black"></path><path opacity="0.3" d="M19.5 18L18.1 19.4C17.7 19.8 17.1 19.8 16.7 19.4L5.40001 8.1C5.00001 7.7 5.00001 7.1 5.40001 6.7L6.80001 5.3C7.20001 4.9 7.80001 4.9 8.20001 5.3L19.5 16.6C19.9 16.9 19.9 17.6 19.5 18Z" fill="black"></path></svg>
                                                                                        </button>
                                                                                        <button type='button' onClick={() => editOnSubmit(leadNote.id)} className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                                <KTSVG path='/media/icons/duotune/general/gen016.svg' className='svg-icon-2 mb-3' />
                                                                                            </button></>:
                                                                                <button type='button' onClick={() => replyEdit(leadNote.id, leadNote.reply)} className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                <KTSVG path="/media/icons/duotune/art/art005.svg" className="svg-icon-2 mb-3"/>
                                                                                </button>}
                                                                                {currentUser?.designation == 1 && <button type='button'
                                                                                data-bs-toggle='modal'
                                                                                data-bs-target={'#delete_note_popup'+leadNote.id} 
                                                                                className='btn btn-icon btn-sm btn-active-color-danger ps-0'>
                                                                                <KTSVG path="/media/icons/duotune/general/gen027.svg" className="svg-icon-2 mb-3" />
                                                                                </button> }
                                                                            </span> : currentUser?.designation == 1 ?
                                                                            <span>
                                                                                <button type='button' data-bs-toggle='modal'
                                                                                data-bs-target={'#delete_note_popup'+leadNote.id}  className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                <KTSVG path="/media/icons/duotune/general/gen027.svg" className="svg-icon-2 mb-3" />
                                                                                </button>
                                                                            </span> : <></>
                                                                            }
                                                                            {/* confirm modal */}
                                                                            <div className='modal fade' id={'delete_note_popup'+leadNote.id} aria-hidden='true'>
                                                                                <div className='modal-dialog modal-dialog-centered'>
                                                                                    <div className='modal-content'>
                                                                                        <div className='modal-header'>
                                                                                            <h3>Confirmation</h3>
                                                                                            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='modal-body py-lg-10 px-lg-10'>
                                                                                            <p>Are you sure want to delete?</p>
                                                                                            <div className='d-flex align-items-center justify-content-end'>
                                                                                                <button className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal'>
                                                                                                    No
                                                                                                </button>
                                                                                                <button className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal' onClick={(e) => replyDelete(leadNote.id, leadNote.parent_id)}>
                                                                                                    Yes
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                {/* end::Text */}
                                                            </div>
                                                            {/* end::Post */}
                                                        </div> :
                                                        <div className='mb-7 ps-10 note_answer'>
                                                            <div className='d-flex mb-5'>
                                                                <div className='symbol symbol-45px me-5'>
                                                                    <img src={leadNote.user_profile_image != '' ? leadNote.user_profile_image ?? toAbsoluteUrl('/media/avatars/300-6.jpg') : toAbsoluteUrl('/media/avatars/300-6.jpg')} alt='' />
                                                                </div>

                                                                <div className='d-flex flex-column flex-row-fluid'>
                                                                <div className='d-flex align-items-center flex-wrap mb-1'>
                                                                    <a href='#' className='text-gray-800 text-hover-primary fw-bolder me-2'>
                                                                    {leadNote.user_name ?? 'User'}
                                                                    </a>

                                                                    <span className='text-gray-400 fw-bold fs-7'>{Moment(leadNote.created_at).format("DD-MMMM-YYYY HH:mm")}</span>
                                                                </div>
                                                                
                                                                <div className=' d-flex justify-content-between'>                                            
                                                                            { noteEditVal != '' && parentId == leadNote.id ?
                                                                            <div className='text-gray-800 position-relative w-75'>
                                                                                <input
                                                                                    className='form-control border-0 p-0 pe-10 resize-none min-h-25px'
                                                                                    data-kt-autosize='true'
                                                                                    // rows={1}
                                                                                    placeholder='Reply..'
                                                                                    id={'edit_field'+leadNote.id}
                                                                                    defaultValue={noteEditVal}
                                                                                    // onChange={(e) => childReplyChange(e)}
                                                                                ></input>
                                                                                {/* <div className='position-absolute top-0 end-0 me-n5'>     
                                                                                    <button type='button' onClick={() => editOnSubmit(contactNote.id)} className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                    <KTSVG path='/media/icons/duotune/general/gen016.svg' className='svg-icon-2 mb-3' />
                                                                                    </button>
                                                                                </div> */}
                                                                            </div>
                                                                            : 
                                                                            <div className='text-gray-800'>
                                                                             {leadNote.reply}
                                                                            </div>
                                                                            } 
                                                                                <span>
                                                                                { currentUser?.id == leadNote.user_id ?
                                                                            <span>
                                                                                { noteEditVal != '' && parentId == leadNote.id ?
                                                                                <><button type='button' onClick={() => cancelEdit(leadNote.id)} className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-icon-2 mb-3"><path opacity="0.3" d="M6.7 19.4L5.3 18C4.9 17.6 4.9 17 5.3 16.6L16.6 5.3C17 4.9 17.6 4.9 18 5.3L19.4 6.7C19.8 7.1 19.8 7.7 19.4 8.1L8.1 19.4C7.8 19.8 7.1 19.8 6.7 19.4Z" fill="black"></path><path opacity="0.3" d="M19.5 18L18.1 19.4C17.7 19.8 17.1 19.8 16.7 19.4L5.40001 8.1C5.00001 7.7 5.00001 7.1 5.40001 6.7L6.80001 5.3C7.20001 4.9 7.80001 4.9 8.20001 5.3L19.5 16.6C19.9 16.9 19.9 17.6 19.5 18Z" fill="black"></path></svg>
                                                                                        </button>
                                                                                        <button type='button' onClick={() => editOnSubmit(leadNote.id)} className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                                <KTSVG path='/media/icons/duotune/general/gen016.svg' className='svg-icon-2 mb-3' />
                                                                                            </button></>:
                                                                                <button type='button' onClick={() => replyEdit(leadNote.id, leadNote.reply)} className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                <KTSVG path="/media/icons/duotune/art/art005.svg" className="svg-icon-2 mb-3"/>
                                                                                </button>}
                                                                                {currentUser?.designation == 1 && <button type='button'
                                                                                data-bs-toggle='modal'
                                                                                data-bs-target={'#delete_note_popup2'+leadNote.id} 
                                                                                className='btn btn-icon btn-sm btn-active-color-danger ps-0'>
                                                                                <KTSVG path="/media/icons/duotune/general/gen027.svg" className="svg-icon-2 mb-3" />
                                                                                </button> }
                                                                            </span> : currentUser?.designation == 1 ?
                                                                            <span>
                                                                                <button type='button' data-bs-toggle='modal'
                                                                                data-bs-target={'#delete_note_popup2'+leadNote.id}  className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                <KTSVG path="/media/icons/duotune/general/gen027.svg" className="svg-icon-2 mb-3" />
                                                                                </button>
                                                                            </span> : <></>
                                                                            }
                                                                                </span>
                                                                            </div>
                                                                    
                                                                        </div>
                                                                             {/* confirm modal */}
                                                                <div className='modal fade' id={'delete_note_popup2'+leadNote.id} aria-hidden='true'>
                                                                                <div className='modal-dialog modal-dialog-centered'>
                                                                                    <div className='modal-content'>
                                                                                        <div className='modal-header'>
                                                                                            <h3>Confirmation</h3>
                                                                                            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='modal-body py-lg-10 px-lg-10'>
                                                                                            <p>Are you sure want to delete?</p>
                                                                                            <div className='d-flex align-items-center justify-content-end'>
                                                                                                <button className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal'>
                                                                                                    No
                                                                                                </button>
                                                                                                <button className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal' onClick={(e) => replyDelete(leadNote.id, leadNote.parent_id)}>
                                                                                                    Yes
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div> 
                                                            </div>
                                                        </div>
                                                        }
                                                       
                                                       {leadNote.reply1 == 'NO' && 
                                                        <>
                                                        {/* begin::Reply input */}
                                                        <form className='position-relative mb-6'>
                                                            <input
                                                                className='form-control border-0 p-0 pe-10 resize-none min-h-25px'
                                                                data-kt-autosize='true'
                                                                placeholder='Reply..'
                                                                id={'child_reply'+leadNote.id}
                                                            />

                                                            <div className='position-absolute top-0 end-0 me-n5'>

                                                                <button type='submit' onClick={() => replyOnSubmit(leadNote.id)}  className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                    <KTSVG path='/media/icons/duotune/general/gen016.svg' className='svg-icon-2 mb-3' />
                                                                </button>
                                                            </div>
                                                        </form>
                                                        {/* edit::Reply input */}
                                                        </>
                                                       }
                                                        <div className='separator mb-4'></div>
                                                    </div>
                                                        )
                                                    })}
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={tabInfo == 'files' ? "tab-pane fade show active": "tab-pane fade"} id={"files"+leadId} role="tabpanel" aria-labelledby="files-tab">
                                    <div {...getRootProps({className: 'dropzone'})}>
                                            <div className='myDIV'>
                                                {/* <div className='d-flex align-items-center justify-content-center h-100 vh-25'>
                                                    <input {...getInputProps()} />
                                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                                </div> */}
                                                <div className="d-flex align-items-center justify-content-center w-100 h-100 vh-25">
                                                    <span className="btn btn-file w-100 h-100">
                                                        {/* <i className="fa fa-upload my-2" aria-hidden="true"></i> */}
                                                        <KTSVG
                                                        path='/media/icons/duotune/files/fil022.svg'
                                                        className='svg-icon-1 text_primary ms-2'
                                                        />
                                                        <p className='text_primary'>Upload Files Here</p>
                                                        <small className='text-dark'>* Note: jpg, jpeg, pdf only acceptable</small>
                                                        <input {...getInputProps()}/>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <aside style={thumbsContainer}>
                                            {thumbs}
                                        </aside>
                                        <div className='p-5 text-end'>
                                            <button
                                                type='button'
                                                id='kt_sign_up_submit'
                                                className='btn btn_primary text-primary'
                                                // disabled={formik.isSubmitting}
                                                onClick={saveFiles}
                                                >
                                                {!loading && <span className='indicator-label'>Save
                                                <KTSVG
                                                path='/media/custom/save_white.svg'
                                                className='svg-icon-3 svg-icon-primary ms-2'
                                                />
                                                </span>}
                                                {loading && (
                                                    <span className='indicator-progress' style={{display: 'block'}}>
                                                    Please wait...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                        {/* <div className='separator mb-4'></div> */}
                                        {filesVal.length > 0 &&<>
                                        <div className='main_bg p-4 mb-9 rounded'>
                                            
                                            <h4>Files</h4>
                                            <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
                                            {filesVal.map((file, i) => {
                                                const pieces = file.fileoriginalname.split('.');
                                                const last = pieces[pieces.length - 1];
                                                console.log('sdsdsdsdsdsd');
                                                console.log(imgFullView);
                                                console.log(process.env.REACT_APP_API_BASE_URL+'uploads/lead/files/'+file.lead_id+'/'+file.file);
                                                return ( 
                                                    <>
                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                                                        <div className="card h-100">
                                                            <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                                                            <a href="#" data-bs-toggle='modal'
                                                            data-bs-target={'#delete_confirm_popup'+file.id} className="btn delete_btn btn-icon btn-bg-light btn-active-color-danger btn-sm"><span className="svg-icon svg-icon-4"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"></path><path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"></path><path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"></path></svg></span></a>
                                                                <a href="#" className="text-gray-800 text-hover-primary d-flex flex-column">
                                                                    {last != 'pdf' ? 
                                                                    <a className={imgFullView && imgSelect == file.id ? "img_full_view" : "symbol symbol-75px"} onClick={() => imgViewChange(file.id)}>
                                                                        <img onError={e => { e.currentTarget.src = toAbsoluteUrl('/media/svg/files/doc.svg') }} src={process.env.REACT_APP_API_BASE_URL+'uploads/lead/files/'+file.lead_id+'/'+file.file} alt=""/>
                                                                        <div className="fs-5 fw-bolder text-dark mb-2">{file.fileoriginalname}</div>
                                                                        <div className="fs-7 fw-bold text-gray-400 mt-auto">{Moment(file.created_at).format("DD-MMMM-YYYY")}</div>
                                                                    </a>
                                                                    :
                                                                    <a className="symbol symbol-75px" href={process.env.REACT_APP_API_BASE_URL+'uploads/lead/files/'+file.lead_id+'/'+file.file} download target="_blank">
                                                                        <img src={toAbsoluteUrl("/media/svg/files/pdf.svg")} alt=""/>
                                                                        <div className="fs-5 fw-bolder text-dark mb-2">{file.fileoriginalname}</div>
                                                                        <div className="fs-7 fw-bold text-gray-400 mt-auto">{Moment(file.created_at).format("DD-MMMM-YYYY ")}</div>
                                                                    </a>
                                                                    }
                                                                    
                                                                </a>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* confirm modal */}
                                                    <div className='modal fade' id={'delete_confirm_popup'+file.id} aria-hidden='true'>
                                                        <div className='modal-dialog modal-dialog-centered'>
                                                            <div className='modal-content'>
                                                                <div className='modal-header'>
                                                                    <h3>Confirmation</h3>
                                                                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                                                    </div>
                                                                </div>
                                                                <div className='modal-body py-lg-10 px-lg-10'>
                                                                    <p>Are you sure want to delete?</p>
                                                                    <div className='d-flex align-items-center justify-content-end'>
                                                                        <button className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal'>
                                                                            No
                                                                        </button>
                                                                        <button className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal' onClick={(e) => onDeleteFile(file.id)}>
                                                                            Yes
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </>
                                                    )
                                                })}
                                            </div>
                                    </div></>}
                                    </div>
                                    <div className={tabInfo == 'req' ? "tab-pane fade show active": "tab-pane fade"} id={"requirements"+leadId} role="tabpanel" aria-labelledby="files-tab">
                                        <form noValidate onSubmit={formik2.handleSubmit}>
                                            <div className="row">
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Bedrooms</label>
                                                    <div className='row mx-0'>
                                                        <div className="col-md-6 col-12 mb-3">
                                                            <div className="input-group mb-3 input_prepend">
                                                                <input type="text" className="form-control" {...formik2.getFieldProps('no_of_bedrooms_min')} onChange={(e) => {
                                                            setBedroomsMin(e.target.value)
                                                            formik2.setFieldValue('no_of_bedrooms_min', e.target.value ?? '')
                                                        }} value={bedroomsMin} placeholder="Min"/>
                                                            </div>
                                                        </div> 
                                                        <div className="col-md-6 col-12 mb-3">
                                                            <div className="input-group mb-3 input_prepend">
                                                                <input type="text" className="form-control" {...formik2.getFieldProps('no_of_bedrooms_max')} onChange={(e) => {
                                                            setBedroomsMax(e.target.value)
                                                            formik2.setFieldValue('no_of_bedrooms_max', e.target.value ?? '')
                                                        }} value={bedroomsMax} placeholder="Max"/>
                                                            </div>
                                                        </div> 
                                                    </div> 
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Bathrooms</label>
                                                    <div className='row mx-0'>
                                                        <div className="col-md-6 col-12 mb-3">
                                                            <div className="input-group mb-3 input_prepend">
                                                                <input type="text" className="form-control" {...formik2.getFieldProps('no_of_bathrooms_min')} onChange={(e) => {
                                                            setBathroomsMin(e.target.value)
                                                            formik2.setFieldValue('no_of_bathrooms_min', e.target.value ?? '')
                                                        }} value={bathroomsMin} placeholder="Min"/>
                                                            </div>
                                                        </div> 
                                                        <div className="col-md-6 col-12 mb-3">
                                                            <div className="input-group mb-3 input_prepend">
                                                                <input type="text" className="form-control" {...formik2.getFieldProps('no_of_bathrooms_max')} onChange={(e) => {
                                                            setBathroomsMax(e.target.value)
                                                            formik2.setFieldValue('no_of_bathrooms_max', e.target.value ?? '')
                                                        }} value={bathroomsMax} placeholder="Max"/>
                                                            </div>
                                                        </div> 
                                                    </div> 
                                                </div>  
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Project Facing</label>
                                                    <div className="input-group mb-3 input_prepend py-1">
                                                        <select className="btn btn-sm w-100 text-start form-select" {...formik.getFieldProps('project_facing')}>
                                                        <option value=''>Select</option>
                                                            {projectFacing.map((Facing,i) =>{
                                                                return (
                                                                    <option value={Facing.id} selected={Facing.id == leadDetail.project_facing} key={i}>{Facing.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div> 
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Budget Range</label>
                                                    <div className='row mx-0'>
                                                        <div className="col-md-6 col-12 mb-3">
                                                            <div className="input-group mb-3 input_prepend">
                                                                <input type="text" {...formik2.getFieldProps('budget_min')} onChange={(e) => {
                                                            setBudgetMin(e.target.value)
                                                            formik2.setFieldValue('budget_min', e.target.value ?? '')
                                                        }} value={budgetMin} className="form-control" placeholder="Min"/>
                                                            </div>
                                                        </div> 
                                                        <div className="col-md-6 col-12 mb-3">
                                                            <div className="input-group mb-3 input_prepend">
                                                                <input type="text" {...formik2.getFieldProps('budget_max')} onChange={(e) => {
                                                            setBudgetMax(e.target.value)
                                                            formik2.setFieldValue('budget_max', e.target.value ?? '')
                                                        }} value={budgetMax} className="form-control" placeholder="Max"/>
                                                            </div>
                                                        </div> 
                                                    </div> 
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Built Area Range</label>
                                                    <div className='row mx-0'>
                                                        <div className="col-md-6 col-12 mb-3">
                                                            <div className="input-group mb-3 input_prepend">
                                                                <input type="text" className="form-control" {...formik2.getFieldProps('built_up_area_min')} onChange={(e) => {
                                                            setBuiltAreaMin(e.target.value)
                                                            formik2.setFieldValue('built_up_area_min', e.target.value ?? '')
                                                        }} value={builtAreaMin} placeholder="Min"/>
                                                            </div>
                                                        </div> 
                                                        <div className="col-md-6 col-12 mb-3">
                                                            <div className="input-group mb-3 input_prepend">
                                                                <input type="text" className="form-control" {...formik2.getFieldProps('built_up_area_max')} onChange={(e) => {
                                                            setBuiltAreaMax(e.target.value)
                                                            formik2.setFieldValue('built_up_area_max', e.target.value ?? '')
                                                        }} value={builtAreaMax} placeholder="Max"/>
                                                            </div>
                                                        </div> 
                                                    </div> 
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Plot Area Range</label>
                                                    <div className='row mx-0'>
                                                        <div className="col-md-6 col-12 mb-3">
                                                            <div className="input-group mb-3 input_prepend">
                                                                <input type="text" {...formik2.getFieldProps('plot_area_min')} onChange={(e) => {
                                                            setPlotAreaMin(e.target.value)
                                                            formik2.setFieldValue('plot_area_min', e.target.value ?? '')
                                                        }} value={plotAreaMin} className="form-control" placeholder="Min"/>
                                                            </div>
                                                        </div> 
                                                        <div className="col-md-6 col-12 mb-3">
                                                            <div className="input-group mb-3 input_prepend">
                                                                <input type="text" {...formik2.getFieldProps('plot_area_max')} onChange={(e) => {
                                                            setPlotAreaMax(e.target.value)
                                                            formik2.setFieldValue('plot_area_max', e.target.value ?? '')
                                                        }} value={plotAreaMax} className="form-control" placeholder="Max"/>
                                                            </div>
                                                        </div> 
                                                    </div> 
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">No. of Car Park</label>
                                                    <div className='row mx-0'>
                                                        <div className="col-md-6 col-12 mb-3">
                                                            <div className="input-group mb-3 input_prepend">
                                                                <input type="text" {...formik2.getFieldProps('car_park_min')} onChange={(e) => {
                                                            setCarParkMin(e.target.value)
                                                            formik2.setFieldValue('car_park_min', e.target.value ?? '')
                                                        }} value={carParkMin} className="form-control" placeholder="Min"/>
                                                            </div>
                                                        </div> 
                                                        <div className="col-md-6 col-12 mb-3">
                                                            <div className="input-group mb-3 input_prepend">
                                                                <input type="text" {...formik2.getFieldProps('car_park_max')} onChange={(e) => {
                                                                    setCarParkMax(e.target.value)
                                                                    formik2.setFieldValue('car_park_max', e.target.value ?? '')
                                                                }} value={carParkMax} className="form-control" placeholder="Max"/>
                                                            </div>
                                                        </div> 
                                                    </div> 
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Timeline for closure</label>
                                                    <div className='row mx-0'>
                                                        <div className="col-md-6 col-12 mb-3">
                                                            <div className="input-group mb-3 input_prepend">
                                                                <input type="text" {...formik2.getFieldProps('timeline_for_closure_min')} onChange={(e) => {
                                                                    setTimeCloseMin(e.target.value)
                                                                    formik2.setFieldValue('timeline_for_closure_min', e.target.value ?? '')
                                                                }} value={timeCloseMin} className="form-control" placeholder="Min"/>
                                                            </div>
                                                        </div> 
                                                        <div className="col-md-6 col-12 mb-3">
                                                            <div className="input-group mb-3 input_prepend">
                                                                <input type="text" {...formik2.getFieldProps('timeline_for_closure_max')} onChange={(e) => {
                                                                    setTimeCloseMax(e.target.value)
                                                                    formik2.setFieldValue('timeline_for_closure_max', e.target.value ?? '')
                                                                }} value={timeCloseMax} className="form-control" placeholder="Max"/>
                                                            </div>
                                                        </div> 
                                                    </div> 
                                                </div> 
                                                {/* <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Requirement Location</label>
                                                    <div className="input-group mb-3 input_prepend py-1">
                                                        <select 
                                                        {...formik2.getFieldProps('requirement_location')} 
                                                        className="btn btn-sm w-100 text-start form-select">
                                                            <option value="">Select Requirement Location</option>
                                                            {reqLocation.map((reqLocationVal,i) =>{
                                                                return (
                                                                    <option value={reqLocationVal.id} selected={reqLocationVal.id == leadDetail.requirement_location} key={i}>{reqLocationVal.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div> 
                                                </div>  */}
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3 edit_page_form">
                                                    <label htmlFor="basic-url" className="form-label">Requirement location</label>
                                                    <FormControl sx={{ m: 0, width: '100%', mt: 0 }}>
                                                        <Select
                                                            className='input_prepend'
                                                            multiple
                                                            displayEmpty
                                                            value={requirementLocationName}
                                                            onChange={locationHandleChange}
                                                            input={<OutlinedInput className='input_prepend' />}
                                                            renderValue={(selected) => {
                                                                var name = [];
                                                                var id = [];

                                                                for(let i = 0; i < selected.length; i++){
                                                                    var fields = selected[i].split('-');

                                                                    var n = fields[0];
                                                                    var d = fields[1];

                                                                    name.push(n);
                                                                    id.push(d);
                                                                }

                                                                if (selected.length === 0) {
                                                                    return <p>Requirement location</p>;
                                                                }

                                                                return name.join(', ');
                                                            }}
                                                            MenuProps={MenuProps}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                            >
                                                            <MenuItem disabled value="">
                                                                <em>Requirement location</em>
                                                            </MenuItem>
                                                            {reqLocation.map((reqLocationVal) => (
                                                                <MenuItem
                                                                key={reqLocationVal.id}
                                                                value={reqLocationVal.name +'-'+ reqLocationVal.id}
                                                                style={getStyles(reqLocationVal.name, requirementLocationName, theme)}
                                                                >
                                                                {reqLocationVal.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>                                                
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Age of Property</label>
                                                    <div className="input-group mb-3 input_prepend py-1">
                                                        <select 
                                                        {...formik2.getFieldProps('age_of_property')} 
                                                        className="btn btn-sm w-100 text-start form-select">
                                                            <option value="">Select Age of property</option>
                                                            {ageOfProp.map((ageOfPropVal,i) =>{
                                                                return (
                                                                    <option value={ageOfPropVal.id} selected={ageOfPropVal.id == leadDetail.age_of_property_id} key={i}>{ageOfPropVal.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div> 
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Vasthu/ Feng Sui Compliant</label>
                                                    <div className="input-group mb-3 input_prepend py-1">
                                                        <select 
                                                        {...formik2.getFieldProps('vasthu_compliant')} 
                                                        className="btn btn-sm w-100 text-start form-select">
                                                            <option value="" >Select Vasthu</option>
                                                            {vasthu.map((vasthuVal,i) =>{
                                                                return (
                                                                    <option value={vasthuVal.id} selected={vasthuVal.id == leadDetail.vasthu_compliant_id} key={i}>{vasthuVal.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div> 
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3 edit_page_form">
                                                    <label htmlFor="basic-url" className="form-label">Amenities</label>
                                                    <FormControl sx={{ m: 0, width: '100%', mt: 0 }}>
                                                        <Select
                                                            className='input_prepend'
                                                            multiple
                                                            displayEmpty
                                                            value={aminityName}
                                                            onChange={handleChange}
                                                            input={<OutlinedInput className='input_prepend' />}
                                                            renderValue={(selected) => {
                                                                // selected = aminityName;
                                                                var name = [];
                                                                var id = [];

                                                                for(let i = 0; i < selected.length; i++){
                                                                    var fields = selected[i].split('-');

                                                                    var n = fields[0];
                                                                    var d = fields[1];

                                                                    name.push(n);
                                                                    id.push(d);
                                                                }
                                                                if (selected.length === 0) {
                                                                return <p>Amenities</p>;
                                                                }

                                                                return name.join(', ');
                                                            }}
                                                            MenuProps={MenuProps}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                            >
                                                            <MenuItem disabled value="">
                                                                <em>Amenities</em>
                                                            </MenuItem>
                                                            {amenities.length > 0 ? 
                                                            amenities.map((amenitiesVal) => (
                                                                <MenuItem
                                                                key={amenitiesVal.id}
                                                                value={amenitiesVal.name+'-'+amenitiesVal.id}
                                                                style={getStyles(amenitiesVal.name, aminityName, theme)}
                                                                >
                                                                {amenitiesVal.name}
                                                                </MenuItem>
                                                            )) :
                                                                <MenuItem disabled value="">
                                                                    <em>Amenities</em>
                                                                </MenuItem>
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3 edit_page_form">
                                                    <label htmlFor="basic-url" className="form-label">Furnishing Status</label>
                                                    <FormControl sx={{ m: 0, width: "100%", mt: 0 }}>
                                                        <Select
                                                            multiple
                                                            displayEmpty
                                                            value={furnishName}
                                                            onChange={furnishingChange}
                                                            input={<OutlinedInput />}
                                                            renderValue={(selected) => {
                                                                selected = furnishName;
                                                                var name = [];
                                                                var id = [];

                                                                for(let i = 0; i < selected.length; i++){
                                                                    var fields = selected[i].split('-');

                                                                    var n = fields[0];
                                                                    var d = fields[1];

                                                                    name.push(n);
                                                                    id.push(d);
                                                                }
                                                                if (selected.length === 0) {
                                                                return <p>Furnishing</p>;
                                                                }

                                                                return name.join(', ');
                                                            }}
                                                            className='multi_select_field'
                                                            MenuProps={MenuProps}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                            >
                                                            <MenuItem disabled value="">
                                                                <em>Furnishing</em>
                                                            </MenuItem>
                                                            {furnishStatus.map((furnishStatusVal) => (
                                                                <MenuItem
                                                                key={furnishStatusVal.id}
                                                                value={furnishStatusVal.name+'-'+furnishStatusVal.id}
                                                                style={getStyles(furnishStatusVal.name, furnishName, theme)}
                                                                >
                                                                {furnishStatusVal.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3 edit_page_form">
                                                    <label htmlFor="basic-url" className="form-label">Posession Status</label>
                                                    <FormControl sx={{ m: 0, width: "100%", mt: 0 }}>
                                                        <Select
                                                            multiple
                                                            displayEmpty
                                                            value={posessionName}
                                                            onChange={possessionChange}
                                                            input={<OutlinedInput />}
                                                            renderValue={(selected) => {
                                                                selected = posessionName;
                                                                var name = [];
                                                                var id = [];

                                                                for(let i = 0; i < selected.length; i++){
                                                                    var fields = selected[i].split('-');

                                                                    var n = fields[0];
                                                                    var d = fields[1];

                                                                    name.push(n);
                                                                    id.push(d);
                                                                }
                                                                if (selected.length === 0) {
                                                                return <p>Posession</p>;
                                                                }

                                                                return name.join(', ');
                                                            }}
                                                            className='multi_select_field'
                                                            MenuProps={MenuProps}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                            >
                                                            <MenuItem disabled value="">
                                                                <em>Posession</em>
                                                            </MenuItem>
                                                            {posesStatus.map((posesStatusVal) => (
                                                                <MenuItem
                                                                key={posesStatusVal.id}
                                                                value={posesStatusVal.name+'-'+posesStatusVal.id}
                                                                style={getStyles(posesStatusVal.name, posessionName, theme)}
                                                                >
                                                                {posesStatusVal.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div> 
                                                
                                                <div className="col-12 d-flex justify-content-center mb-4">
                                                <button
                                                    type='submit'
                                                    id='kt_sign_up_submit'
                                                    className='btn btn_primary text-primary'
                                                    disabled={formik2.isSubmitting}
                                                    >
                                                    {!loading && <span className='indicator-label'>Save
                                                    <KTSVG
                                                    path='/media/custom/save_white.svg'
                                                    className='svg-icon-3 svg-icon-primary ms-2'
                                                    />
                                                    </span>}
                                                    {loading && (
                                                        <span className='indicator-progress' style={{display: 'block'}}>
                                                        Please wait...{' '}
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    )}
                                                </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className={tabInfo == 'message' ? "tab-pane fade show active": "tab-pane fade"} id={"message"+leadId} role="tabpanel" aria-labelledby="message-tab">                                        
                                    <ul className="nav nav-pills mb-3 message_tabs" id={"pills-tab"+leadId} role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id={"pills-mail-tab"+leadId} data-bs-toggle="pill" data-bs-target={"#pills-mail"+leadId} type="button" role="tab" aria-controls="pills-mail" aria-selected="true">Email</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id={"pills-whatsapp-tab"+leadId} data-bs-toggle="pill" data-bs-target={"#pills-whatsapp"+leadId} type="button" role="tab" aria-controls="pills-whatsapp" aria-selected="false">Whatsapp</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id={"pills-sms-tab"+leadId} data-bs-toggle="pill" data-bs-target={"#pills-sms"+leadId} type="button" role="tab" aria-controls="pills-sms" aria-selected="false">Sms</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id={"pills-calls-tab"+leadId} data-bs-toggle="pill" data-bs-target={"#pills-calls"+leadId} type="button" role="tab" aria-controls="pills-calls" aria-selected="false">Calls</button>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="pills-tabContent position-relative">
                                            <div className="tab-pane fade show active" id={"pills-mail"+leadId} role="tabpanel" aria-labelledby="pills-mail-tab">
                                                <div className="mt-4">
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                {/* <div className="col-lg-1  d-flex align-items-center justify-content-lg-center my-2">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-lg-3 my-2">
                                                                    <span className="mail_icon"><i className="fas fa-envelope"></i></span>
                                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                        <p>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                    <div className="w-100 d-flex flex-wrap mt-2">
                                                                        <a href="#" className="d-flex mail_format me-2 mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.wrd</p>
                                                                        </a> 
                                                                        <a href="#" className="d-flex mail_format mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.pdf</p>
                                                                        </a> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-bs-toggle='modal' data-bs-target={'#mail_template_popup'} onClick={sendMail}>                                                
                                                <KTSVG path="/media/icons/duotune/general/gen041.svg" className="svg-icon-muted rounded-circle svg-icon-4hx position-absolute bottom-0 end-0 mb-9 me-9 text_primary" />
                                                </div>
                                                <div className='modal fade' id={'mail_template_popup'} aria-hidden='true'>
                                                    <div className='modal-dialog modal-dialog-centered'>
                                                        <div className='modal-content list_height'>
                                                            <div className='modal-header rbc-today py-0'>
                                                            <div className='card-header pt-2 d-flex align-items-center justify-content-center' id='kt_chat_contacts_header'>
                                                                <h3>Mail List</h3>
                                                            </div>
                                                                <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                <img src={toAbsoluteUrl('/media/custom/overview-icons/cancel_white.svg')} className="svg_icon text-dark" alt='' />
                                                                </div>
                                                            </div>
                                                            <div className='modal-body'>                                         
                                                            <div className="input-group form_search">
                                                                    <input type="text" className="form-control" name="search" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)}/>
                                                                    <div className="input-group-append">
                                                                        <button className="btn btn-secondary" type="button">
                                                                        <img src={toAbsoluteUrl('/media/custom/header-icons/search.svg')} className="svg_icon" alt='' />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <ul className="nav mb-1 d-block list_template">
                                                                {!search
                                                                    ? allTemplatesMail.map((item) => (
                                                                        // <li key={`${item.title}-${item.title}`}>{item.title}</li>
                                                                        <li className="nav-item w-100" key={item.title}>
                                                                        <div onClick={() => MailById(item.id)} className='btn' data-bs-toggle='modal' data-bs-target={'#mail_content_popup'}>
                                                                            <div className='d-flex align-items-center'>
                                                                                <div className='symbol symbol-35px symbol-circle'>
                                                                                    <span className='symbol-label bg_soft text_primary fs-6 fw-bolder'>
                                                                                    {item.title[0]}
                                                                                    </span>
                                                                                </div>

                                                                                <div className='ms-5'>
                                                                                    <p className='fs-5 fw-bolder text-gray-900 mb-0'>
                                                                                    {item.title} 
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    ))
                                                                    : filtered.map((item) => (<li className="nav-item w-100" key={item.title}>
                                                                    <div onClick={() => MailById(item.id)} className='btn' data-bs-toggle='modal' data-bs-target={'#mail_content_popup'}>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div className='symbol symbol-35px symbol-circle'>
                                                                                <span className='symbol-label bg_soft text_primary fs-6 fw-bolder'>
                                                                                {item.title[0]}
                                                                                </span>
                                                                            </div>

                                                                            <div className='ms-5'>
                                                                                <p className='p-3 fs-5 fw-bolder text-gray-900 mb-0'>
                                                                                {item.title} 
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='modal fade shadow-lg' id={'mail_content_popup'} aria-hidden='true'>
                                                        <div className='modal-dialog modal-lg modal-dialog-centered'>
                                                            <div className='modal-content'>
                                                                <div className='modal-header rbc-today'>
                                                                    <h3>Send Mail</h3>
                                                                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                    <img src={toAbsoluteUrl('/media/custom/overview-icons/cancel_white.svg')} className="svg_icon text-dark" alt='' />
                                                                    </div>
                                                                </div>
                                                                <div className='modal-body py-lg-10 px-lg-10'>
                                                                <form noValidate onSubmit={formikMail.handleSubmit} >
                                                                    <div className="row">
                                                                        <div className="col-md-6">                                            
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Title</label>
                                                                                <div className="input-group">
                                                                                    <input type="text" className="form-control" placeholder="title" {...formikMail.getFieldProps('title')}/> 
                                                                                </div>
                                                                                {formikMail.touched.title && formikMail.errors.title && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.title}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">                                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Subject</label>
                                                                                <div className="input-group">
                                                                                    <input type="text" className="form-control" placeholder="subject" {...formikMail.getFieldProps('subject')}/> 
                                                                                </div>
                                                                                {formikMail.touched.subject && formikMail.errors.subject && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.subject}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-6">                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Share with</label>
                                                                                <select className="form-select text-start bg-secondary bg-opacity-25 form-control" {...formikMail.getFieldProps('share_with')}>
                                                                                    <option value="default">Select</option>
                                                                                    <option value={1}>Option 1</option>
                                                                                    <option value={2}>Option 2</option>
                                                                                </select>  
                                                                            </div> 
                                                                            {formikMail.touched.share_with && formikMail.errors.share_with && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.share_with}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}                                                   
                                                                        </div>
                                                                        <div className="col-xl-6">                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Module</label>
                                                                                <select className="form-select form-control text-start bg-secondary bg-opacity-25" {...formikMail.getFieldProps('module_id')}>
                                                                                    <option value="default">Select</option>
                                                                                    <option value={1}>Lead</option>
                                                                                    <option value={2}>Contact</option>
                                                                                    <option value={3}>Task</option>
                                                                                    <option value={4}>Project</option>
                                                                                </select>  
                                                                            </div>   
                                                                            {formikMail.touched.module_id && formikMail.errors.module_id && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.module_id}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}                                                 
                                                                        </div>
                                                                        <div className="col">                                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Body</label>
                                                                                <div className="input-group">
                                                                                    <textarea style={{height: '200px'}} className="form-control" placeholder="Encryption" {...formikMail.getFieldProps('body')}/> 
                                                                                </div>
                                                                                {formikMail.touched.body && formikMail.errors.body && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.body}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                            </div>
                                                                        </div>                                        
                                                                    </div>
                                                                    <div className='card-footer py-3 text-center' id='kt_task_footer'>
                                                                        <button type='button' className='btn btn-secondary me-3' data-bs-dismiss='modal'>Cancel</button>
                                                                        <button
                                                                        type='submit'
                                                                        id='kt_add_teams_submit'
                                                                        className='btn btn_primary text-primary'
                                                                        disabled={formikMail.isSubmitting}
                                                                        >
                                                                        {!loading && <span className='indicator-label'>Send
                                                                        {/* <KTSVG
                                                                        path='/media/custom/save_white.svg'
                                                                        className='svg-icon-3 svg-icon-primary ms-2'
                                                                        /> */}
                                                                        </span>}
                                                                        {loading && (
                                                                            <span className='indicator-progress' style={{display: 'block'}}>
                                                                            Please wait...{' '}
                                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                            </span>
                                                                        )}
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                            <div className="tab-pane fade" id={"pills-whatsapp"+leadId} role="tabpanel" aria-labelledby="pills-whatsapp-tab">
                                                <div className="mt-4">
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                {/* <div className="col-lg-1 align-items-center d-flex justify-content-lg-center my-2">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-lg-3 my-2">
                                                                    <span className="icon_bg"><img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} className="svg_icon" alt=''/></span>
                                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                        <p>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                <div className="col-lg-3 my-2">
                                                                    <span className="icon_bg"><img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} className="svg_icon" alt=''/></span>
                                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                        <p>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-bs-toggle='modal' data-bs-target={'#watsapp_template_popup'} onClick={sendWatsapp}>
                                                <KTSVG path="/media/icons/duotune/general/gen041.svg" className="svg-icon-muted rounded-circle svg-icon-4hx position-absolute bottom-0 end-0 mb-9 me-9 text_primary" />
                                                </div>
                                                <div className='modal fade' id={'watsapp_template_popup'} aria-hidden='true'>
                                                    <div className='modal-dialog modal-dialog-centered'>
                                                        <div className='modal-content list_height'>
                                                            <div className='modal-header rbc-today py-0'>
                                                            <div className='card-header pt-2 d-flex align-items-center justify-content-center' id='kt_chat_contacts_header'>
                                                                <h3>Mail List</h3>
                                                            </div>
                                                                <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                <img src={toAbsoluteUrl('/media/custom/overview-icons/cancel_white.svg')} className="svg_icon text-dark" alt='' />
                                                                </div>
                                                            </div>
                                                            <div className='modal-body'>                                         
                                                            <div className="input-group form_search">
                                                                    <input type="text" className="form-control" name="search" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)}/>
                                                                    <div className="input-group-append">
                                                                        <button className="btn btn-secondary" type="button">
                                                                        <img src={toAbsoluteUrl('/media/custom/header-icons/search.svg')} className="svg_icon" alt='' />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <ul className="nav mb-1 d-block list_template">
                                                                {!search
                                                                    ? allTemplatesMail.map((item) => (
                                                                        // <li key={`${item.title}-${item.title}`}>{item.title}</li>
                                                                        <li className="nav-item w-100" key={item.title}>
                                                                        <div onClick={() => MailById(item.id)} className='btn' data-bs-toggle='modal' data-bs-target={'#watsapp_content_popup'}>
                                                                            <div className='d-flex align-items-center'>
                                                                                <div className='symbol symbol-35px symbol-circle'>
                                                                                    <span className='symbol-label bg_soft text_primary fs-6 fw-bolder'>
                                                                                    {item.title[0]}
                                                                                    </span>
                                                                                </div>

                                                                                <div className='ms-5'>
                                                                                    <p className='fs-5 fw-bolder text-gray-900 mb-0'>
                                                                                    {item.title} 
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    ))
                                                                    : filtered.map((item) => (<li className="nav-item w-100" key={item.title}>
                                                                    <div onClick={() => MailById(item.id)} className='btn' data-bs-toggle='modal' data-bs-target={'#watsapp_content_popup'}>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div className='symbol symbol-35px symbol-circle'>
                                                                                <span className='symbol-label bg_soft text_primary fs-6 fw-bolder'>
                                                                                {item.title[0]}
                                                                                </span>
                                                                            </div>

                                                                            <div className='ms-5'>
                                                                                <p className='p-3 fs-5 fw-bolder text-gray-900 mb-0'>
                                                                                {item.title} 
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='modal fade shadow-lg' id={'watsapp_content_popup'} aria-hidden='true'>
                                                        <div className='modal-dialog modal-lg modal-dialog-centered'>
                                                            <div className='modal-content'>
                                                                <div className='modal-header rbc-today'>
                                                                    <h3>Send Mail</h3>
                                                                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                    <img src={toAbsoluteUrl('/media/custom/overview-icons/cancel_white.svg')} className="svg_icon text-dark" alt='' />
                                                                    </div>
                                                                </div>
                                                                <div className='modal-body py-lg-10 px-lg-10'>
                                                                <form noValidate onSubmit={formikMail.handleSubmit} >
                                                                    <div className="row">
                                                                        <div className="col-md-6">                                            
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Title</label>
                                                                                <div className="input-group">
                                                                                    <input type="text" className="form-control" placeholder="title" {...formikMail.getFieldProps('title')}/> 
                                                                                </div>
                                                                                {formikMail.touched.title && formikMail.errors.title && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.title}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">                                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Subject</label>
                                                                                <div className="input-group">
                                                                                    <input type="text" className="form-control" placeholder="subject" {...formikMail.getFieldProps('subject')}/> 
                                                                                </div>
                                                                                {formikMail.touched.subject && formikMail.errors.subject && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.subject}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-6">                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Share with</label>
                                                                                <select className="form-select text-start bg-secondary bg-opacity-25 form-control" {...formikMail.getFieldProps('share_with')}>
                                                                                    <option value="default">Select</option>
                                                                                    <option value={1}>Option 1</option>
                                                                                    <option value={2}>Option 2</option>
                                                                                </select>  
                                                                            </div> 
                                                                            {formikMail.touched.share_with && formikMail.errors.share_with && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.share_with}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}                                                   
                                                                        </div>
                                                                        <div className="col-xl-6">                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Module</label>
                                                                                <select className="form-select form-control text-start bg-secondary bg-opacity-25" {...formikMail.getFieldProps('module_id')}>
                                                                                    <option value="default">Select</option>
                                                                                    <option value={1}>Lead</option>
                                                                                    <option value={2}>Contact</option>
                                                                                    <option value={3}>Task</option>
                                                                                    <option value={4}>Project</option>
                                                                                </select>  
                                                                            </div>   
                                                                            {formikMail.touched.module_id && formikMail.errors.module_id && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.module_id}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}                                                 
                                                                        </div>
                                                                        <div className="col">                                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Body</label>
                                                                                <div className="input-group">
                                                                                    <textarea style={{height: '200px'}} className="form-control" placeholder="Encryption" {...formikMail.getFieldProps('body')}/> 
                                                                                </div>
                                                                                {formikMail.touched.body && formikMail.errors.body && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.body}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                            </div>
                                                                        </div>                                        
                                                                    </div>
                                                                    <div className='card-footer py-3 text-center' id='kt_task_footer'>
                                                                        <button type='button' className='btn btn-secondary me-3' data-bs-dismiss='modal'>Cancel</button>
                                                                        <button
                                                                        type='submit'
                                                                        id='kt_add_teams_submit'
                                                                        className='btn btn_primary text-primary'
                                                                        disabled={formikMail.isSubmitting}
                                                                        >
                                                                        {!loading && <span className='indicator-label'>Send
                                                                        {/* <KTSVG
                                                                        path='/media/custom/save_white.svg'
                                                                        className='svg-icon-3 svg-icon-primary ms-2'
                                                                        /> */}
                                                                        </span>}
                                                                        {loading && (
                                                                            <span className='indicator-progress' style={{display: 'block'}}>
                                                                            Please wait...{' '}
                                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                            </span>
                                                                        )}
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                            <div className="tab-pane fade" id={"pills-sms"+leadId} role="tabpanel" aria-labelledby="pills-sms-tab">
                                                <div className="mt-4">
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                <div className="col-lg-3 my-2">
                                                                    <span className="icon_bg bg_primary_light"><img src={toAbsoluteUrl('/media/icons/duotune/communication/com007.svg')} className="svg_icon text-primary" alt=''/></span>
                                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                        <p>8934301210</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-bs-toggle='modal' data-bs-target={'#sms_template_popup'} onClick={sendSMS}>
                                                <KTSVG path="/media/icons/duotune/general/gen041.svg" className="svg-icon-muted rounded-circle svg-icon-4hx position-absolute bottom-0 end-0 mb-9 me-9 text_primary" />
                                                </div>
                                                <div className='modal fade' id={'sms_template_popup'} aria-hidden='true'>
                                                    <div className='modal-dialog modal-dialog-centered'>
                                                        <div className='modal-content list_height'>
                                                            <div className='modal-header rbc-today py-0'>
                                                            <div className='card-header pt-2 d-flex align-items-center justify-content-center' id='kt_chat_contacts_header'>
                                                                <h3>Mail List</h3>
                                                            </div>
                                                                <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                <img src={toAbsoluteUrl('/media/custom/overview-icons/cancel_white.svg')} className="svg_icon text-dark" alt='' />
                                                                </div>
                                                            </div>
                                                            <div className='modal-body'>                                         
                                                            <div className="input-group form_search">
                                                                    <input type="text" className="form-control" name="search" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)}/>
                                                                    <div className="input-group-append">
                                                                        <button className="btn btn-secondary" type="button">
                                                                        <img src={toAbsoluteUrl('/media/custom/header-icons/search.svg')} className="svg_icon" alt='' />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <ul className="nav mb-1 d-block list_template">
                                                                {!search
                                                                    ? allTemplatesMail.map((item) => (
                                                                        // <li key={`${item.title}-${item.title}`}>{item.title}</li>
                                                                        <li className="nav-item w-100" key={item.title}>
                                                                        <div onClick={() => MailById(item.id)} className='btn' data-bs-toggle='modal' data-bs-target={'#sms_content_popup'}>
                                                                            <div className='d-flex align-items-center'>
                                                                                <div className='symbol symbol-35px symbol-circle'>
                                                                                    <span className='symbol-label bg_soft text_primary fs-6 fw-bolder'>
                                                                                    {item.title[0]}
                                                                                    </span>
                                                                                </div>

                                                                                <div className='ms-5'>
                                                                                    <p className='fs-5 fw-bolder text-gray-900 mb-0'>
                                                                                    {item.title} 
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    ))
                                                                    : filtered.map((item) => (<li className="nav-item w-100" key={item.title}>
                                                                    <div onClick={() => MailById(item.id)} className='btn' data-bs-toggle='modal' data-bs-target={'#sms_content_popup'}>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div className='symbol symbol-35px symbol-circle'>
                                                                                <span className='symbol-label bg_soft text_primary fs-6 fw-bolder'>
                                                                                {item.title[0]}
                                                                                </span>
                                                                            </div>

                                                                            <div className='ms-5'>
                                                                                <p className='p-3 fs-5 fw-bolder text-gray-900 mb-0'>
                                                                                {item.title} 
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='modal fade shadow-lg' id={'sms_content_popup'} aria-hidden='true'>
                                                        <div className='modal-dialog modal-lg modal-dialog-centered'>
                                                            <div className='modal-content'>
                                                                <div className='modal-header rbc-today'>
                                                                    <h3>Send Mail</h3>
                                                                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                    <img src={toAbsoluteUrl('/media/custom/overview-icons/cancel_white.svg')} className="svg_icon text-dark" alt='' />
                                                                    </div>
                                                                </div>
                                                                <div className='modal-body py-lg-10 px-lg-10'>
                                                                <form noValidate onSubmit={formikMail.handleSubmit} >
                                                                    <div className="row">
                                                                        <div className="col-md-6">                                            
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Title</label>
                                                                                <div className="input-group">
                                                                                    <input type="text" className="form-control" placeholder="title" {...formikMail.getFieldProps('title')}/> 
                                                                                </div>
                                                                                {formikMail.touched.title && formikMail.errors.title && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.title}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">                                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Subject</label>
                                                                                <div className="input-group">
                                                                                    <input type="text" className="form-control" placeholder="subject" {...formikMail.getFieldProps('subject')}/> 
                                                                                </div>
                                                                                {formikMail.touched.subject && formikMail.errors.subject && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.subject}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-6">                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Share with</label>
                                                                                <select className="form-select text-start bg-secondary bg-opacity-25 form-control" {...formikMail.getFieldProps('share_with')}>
                                                                                    <option value="default">Select</option>
                                                                                    <option value={1}>Option 1</option>
                                                                                    <option value={2}>Option 2</option>
                                                                                </select>  
                                                                            </div> 
                                                                            {formikMail.touched.share_with && formikMail.errors.share_with && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.share_with}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}                                                   
                                                                        </div>
                                                                        <div className="col-xl-6">                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Module</label>
                                                                                <select className="form-select form-control text-start bg-secondary bg-opacity-25" {...formikMail.getFieldProps('module_id')}>
                                                                                    <option value="default">Select</option>
                                                                                    <option value={1}>Lead</option>
                                                                                    <option value={2}>Contact</option>
                                                                                    <option value={3}>Task</option>
                                                                                    <option value={4}>Project</option>
                                                                                </select>  
                                                                            </div>   
                                                                            {formikMail.touched.module_id && formikMail.errors.module_id && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.module_id}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}                                                 
                                                                        </div>
                                                                        <div className="col">                                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Body</label>
                                                                                <div className="input-group">
                                                                                    <textarea style={{height: '200px'}} className="form-control" placeholder="Encryption" {...formikMail.getFieldProps('body')}/> 
                                                                                </div>
                                                                                {formikMail.touched.body && formikMail.errors.body && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.body}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                            </div>
                                                                        </div>                                        
                                                                    </div>
                                                                    <div className='card-footer py-3 text-center' id='kt_task_footer'>
                                                                        <button type='button' className='btn btn-secondary me-3' data-bs-dismiss='modal'>Cancel</button>
                                                                        <button
                                                                        type='submit'
                                                                        id='kt_add_teams_submit'
                                                                        className='btn btn_primary text-primary'
                                                                        disabled={formikMail.isSubmitting}
                                                                        >
                                                                        {!loading && <span className='indicator-label'>Send
                                                                        {/* <KTSVG
                                                                        path='/media/custom/save_white.svg'
                                                                        className='svg-icon-3 svg-icon-primary ms-2'
                                                                        /> */}
                                                                        </span>}
                                                                        {loading && (
                                                                            <span className='indicator-progress' style={{display: 'block'}}>
                                                                            Please wait...{' '}
                                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                            </span>
                                                                        )}
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                            <div className="tab-pane fade" id={"pills-calls"+leadId} role="tabpanel" aria-labelledby="pills-calls-tab">
                                                <div className="mt-4">
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                <div className="col-lg-3 my-2">
                                                                    <span className="icon_bg bg_warning_light"><img src={toAbsoluteUrl('/media/icons/duotune/communication/com005.svg')} className="svg_icon text-danger" alt=''/></span>
                                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                        <p>8934301210</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div onClick={sendCall}>
                                                <KTSVG path="/media/icons/duotune/general/gen041.svg" className="svg-icon-muted rounded-circle svg-icon-4hx position-absolute bottom-0 end-0 mb-9 me-9 text_primary" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={tabInfo == 'contact' ? "tab-pane fade show active": "tab-pane fade"} id={"contact"+leadId} role="tabpanel" aria-labelledby="contact-tab">
                                        <div style={{ height: 500, width: '100%', paddingBottom: '50px',}}>
                                        {secondaryContactLead.length > 0
                                            ?
                                            <DataGrid
                                                rows={secondaryContactLead}
                                                columns={secContactColumns}
                                                pageSize={5}
                                                rowsPerPageOptions={[5]}
                                                checkboxSelection
                                                sx={{
                                                    fontSize:14,
                                                    fontWeight:500,
                                                }}
                                            />
                                            : <div className="text-center w-100">
                                                <span className="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor"></path><rect x="7" y="17" width="6" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="12" width="10" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="7" width="6" height="2" rx="1" fill="currentColor"></rect><path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor"></path></svg></span>
                                                <p className='mt-3'>No Secondary Contacts Available</p>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    <div className={tabInfo == 'lead' ? "tab-pane fade show active": "tab-pane fade"} id={"lead"+leadId} role="tabpanel" aria-labelledby="lead-tab">
                                        <div style={{ height: 500, width: '100%', paddingBottom: '50px',}}>
                                        {leadrows.length > 0
                                            ?
                                            <DataGrid
                                                rows={leadrows}
                                                columns={leadcolumns}
                                                pageSize={5}
                                                rowsPerPageOptions={[5]}
                                                checkboxSelection
                                                sx={{
                                                    fontSize:14,
                                                    fontWeight:500,
                                                }}
                                            />
                                            : <div className="text-center w-100">
                                                <span className="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor"></path><rect x="7" y="17" width="6" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="12" width="10" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="7" width="6" height="2" rx="1" fill="currentColor"></rect><path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor"></path></svg></span>
                                                <p className='mt-3'>No Lead Available</p>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    <div className={tabInfo == 'duplicate' ? "tab-pane fade show active": "tab-pane fade"} id={"duplicate"+leadId} role="tabpanel" aria-labelledby="duplicate-tab">
                                    <div className='mb-9' style={{ height: 500, width: '100%',}}>
                                    <div style={{ height: 500, width: '100%', paddingBottom: '50px',}}>
                                        {duplicateLead.length > 0
                                            ?
                                            <DataGrid
                                                rows={duplicateLead}
                                                columns={duplicatecolumns}
                                                pageSize={5}
                                                rowsPerPageOptions={[5]}
                                                checkboxSelection
                                                sx={{
                                                    fontSize:14,
                                                    fontWeight:500,
                                                }}
                                            />
                                            : <div className="text-center w-100">
                                                <span className="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor"></path><rect x="7" y="17" width="6" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="12" width="10" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="7" width="6" height="2" rx="1" fill="currentColor"></rect><path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor"></path></svg></span>
                                                <p className='mt-3'>No Secondary Contacts Available</p>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    </div>
                                    <div className={tabInfo == 'task' ? "tab-pane fade show active": "tab-pane fade"} id={"tasks"+leadId} role="tabpanel" aria-labelledby="tasks-tab">
                                    <div className='mb-9' style={{ height: 500, width: '100%',}}>
                                        {taskLead.length > 0
                                            ?
                                        <DataGrid
                                                rows={taskLead}
                                                columns={logContactTaskcolumns}
                                                pageSize={5}
                                                rowsPerPageOptions={[5]}
                                                checkboxSelection
                                                sx={{
                                                    fontSize:14,
                                                    fontWeight:500,
                                                }}
                                            />
                                            : <div className="text-center w-100">
                                                <span className="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor"></path><rect x="7" y="17" width="6" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="12" width="10" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="7" width="6" height="2" rx="1" fill="currentColor"></rect><path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor"></path></svg></span>
                                                <p className='mt-3'>No Tasks Available</p>
                                            </div>
                                            }
                                            </div>
                                    </div>
                                    <div className={tabInfo == 'timeline' ? "tab-pane fade show active": "tab-pane fade"} id={"timeline"+leadId} role="tabpanel" aria-labelledby="timeline-tab">
                                        <div className='mb-9' style={{ height: 500, width: '100%',}}>
                                            {logList.length > 0
                                            ?
                                            <DataGrid
                                                rows={logList}
                                                columns={logContactcolumns}
                                                pageSize={5}
                                                rowsPerPageOptions={[5]}
                                                checkboxSelection
                                                sx={{
                                                    fontSize:14,
                                                    fontWeight:500,
                                                }}
                                            />
                                            : <div className="text-center w-100">
                                                <span className="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor"></path><rect x="7" y="17" width="6" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="12" width="10" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="7" width="6" height="2" rx="1" fill="currentColor"></rect><path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor"></path></svg></span>
                                                <p className='mt-3'>No Timeline Contacts Available</p>
                                            </div>
                                            }
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> :
            <div className="card bg_primary">
                <div className="card-body d-flex justify-content-between">
                    <div>
                        <h5 className='text-white'>Lead Details</h5>
                    </div>
                    <button onClick={minimaximize} className="mx-3 btn p-0">
                        <i className="fas fa-window-maximize text-white"></i>
                    </button>
                    <button type='button' data-bs-dismiss="offcanvas" id='kt_expand_close' onClick={closeDialogue} className="mx-3 btn p-0">
                        <i className="fas fa-times text-white"></i>
                    </button>
                </div>
            </div>
            }
        </div>
    )

}
export {LeadDetails}