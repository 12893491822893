import React,{FC} from 'react';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { DashboardBarChart } from '../dashboard/barChart';


const HrLead: FC = () => {
    return(
        <section className='report_lead'>
            <div className="row">
                <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-4 pb-5">
                    <div className="card h-100 bg-light type_card">
                        <div className="card-header">
                            <div className="input-group form_search me-3">
                                <input type="text" className="form-control" placeholder="Search"/>
                                <div className="input-group-append">
                                    <button className="btn btn-secondary" type="button">
                                    <img src={toAbsoluteUrl('/media/custom/header-icons/search.svg')} className="svg_icon" alt='' />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body px-4">
                            <ul className="nav nav-pills masters_tab d-block px-3 border-0" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className='text-dark nav-link active' id="userdemo-tab" data-bs-toggle="pill" data-bs-target="#userdemo" type="button" role="tab" aria-controls="userdemo" aria-selected="true">
                                    {/* <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' /> */}
                                    User Demographic Report
                                    </a>                                     
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className='text-dark nav-link' id="userlocation-tab" data-bs-toggle="pill" data-bs-target="#userlocation" type="button" role="tab" aria-controls="userlocation" aria-selected="true">
                                    {/* <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' /> */}
                                    User Location
                                    </a>                                     
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className='text-dark nav-link' id="attendance-tab" data-bs-toggle="pill" data-bs-target="#attendance" type="button" role="tab" aria-controls="attendance" aria-selected="true">
                                    {/* <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' /> */}
                                    Attendance Report
                                    </a>                                     
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className='text-dark nav-link' id="leavetype-tab" data-bs-toggle="pill" data-bs-target="#leavetype" type="button" role="tab" aria-controls="leavetype" aria-selected="true">
                                    {/* <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' /> */}
                                    Leave Type Report
                                    </a>                                     
                                </li>                                                                
                            </ul>                            
                        </div>
                    </div>
                </div>
                {/* <div className="col-xxl-9 col-xl-8 col-lg-8 col-md-8 col-sm-8">                    
                    <div className="card-group">
                    <div className="tab-content w-100" id="pills-tabContent">
                        <div  className="tab-pane fade show active" id="leadreport" role="tabpanel" aria-labelledby="leadreport-tab">
                            <div className="row">
                                <div className="col-xxl-6 col-xl-8 col-lg-10 col-md-12 col-sm-12 mb-4">                                
                                    <div className="card h-100 bs_1 mx-3">
                                        <div className="card-header">
                                            <h4>Lead Report</h4>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <div className="d-flex me-3">
                                                    <i className="fa fa-star me-2 text-warning" aria-hidden="true"></i>
                                                    <i className="fa fa-download me-2" aria-hidden="true"></i>
                                                </div>
                                                <select className="form-select dash_btn me-2 mb-1">
                                                    <option selected>Teams</option>
                                                    <option value="1">Brigade Sales Team</option>
                                                    <option value="1">Godrej Sales Team</option>
                                                    <option value="1">Shriram Sales Team</option>
                                                </select>                                                
                                            </div>
                                        </div>
                                        <div className="card-body p-3">
                                        <DashboardBarChart/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                                                  
                        <div  className="tab-pane fade" id="leadregistration" role="tabpanel" aria-labelledby="leadregistration-tab">
                            <div className="row">                          
                                <div className="col-xxl-6 col-xl-8 col-lg-10 col-md-12 col-sm-12 mb-4">
                                    <div className="card h-100 bs_1 mx-3">
                                        <div className="card-header">
                                            <h4>City Wise Lead Report</h4>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <div className="d-flex me-3">
                                                    <i className="fa fa-star me-2 text-warning" aria-hidden="true"></i>
                                                    <i className="fa fa-download me-2" aria-hidden="true"></i>
                                                </div>
                                                <select className="form-select dash_btn me-2 mb-1">
                                                    <option selected>Teams</option>
                                                    <option value="1">Brigade Sales Team</option>
                                                    <option value="1">Godrej Sales Team</option>
                                                    <option value="1">Shriram Sales Team</option>
                                                </select>                                                
                                            </div>
                                        </div>
                                        <div className="card-body p-3">
                                        <DashboardBarChart/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                    </div>                        
                    </div>
                </div> */}
            </div>
        </section>
    )
}
export{HrLead}