import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import "react-big-calendar/lib/css/react-big-calendar.css";
import { saveAttendance } from './core/_requests';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useAuth } from '../../../modules/auth';
import { getAttendanceStatus, getLeaveType } from '../masters/core/_requests';
import { getAttendance, updateAttendance, getTeamMembers, getTeamsList, getUsersByRole, deleteAttendance } from './core/_requests';
import MaterialTable from 'material-table'
import { forwardRef } from 'react';
import { format } from 'date-fns';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const columns = [
  { title: 'Id', field: 'id'},
  { title: 'Name', field: 'title', render: rowData => {
    var fields = rowData.title.split('-');
      return fields[0];
  } },
  { title: 'Date', field: 'start', render: rowData => moment(rowData.start).format('DD-MMMM-YYYY')},
  { title: 'In Time', field: 'start', render: rowData => moment(rowData.start).format('HH:mm A')},
  { title: 'Out Time', field: 'end', render: rowData => moment(rowData.start).format('HH:mm A')},
  { title: 'Status', field: 'status_name' },
  { title: 'Reason', field: 'leave_type_name' },  
];

// type Props = {
//     teamDetails?: any
// }

const initialValues = {
    user_id: "",
    status: "",
    leave_type: "",
    start_time: "",
    end_time: "",
} 

moment.locale("en");
const localizer = momentLocalizer(moment);

export default function ReactBigCalendar  (props) {

    const {
        teamDetails
      } = props


    const [eventsData, setEventsData] = useState([]);
    const [users, setUsers] = useState([]);
    const [attendance, setAttendance] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]);
    const [attendanceStatus, setAttendanceStatus] = useState([]);
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const {currentUser, logout} = useAuth();
    const usersId = currentUser?.id;
    const roleId = currentUser?.designation;
    const [id, setId] = useState('');
    const [title, setTitle] = useState('');
    const [userId, setUserId] = useState('');
    const [listChange, setListChange] = useState('1');
    const [loading, setLoading] = useState(false);
    const [editClicked, setEditClicked] = useState(false);
    const [teams, setTeams] = useState([]);
    const [teamId, setTeamId] = useState('');
    const [teamName, setTeamName] = useState('');
    const [memberId, setMemberId] = useState(usersId);
    const [teamClicked, setTeamClicked] = useState(false);
    const [search, setSearch] = useState("");
    const [toggle, setToggle] = useState("1");
    const [filtered, setFiltered] = useState([]);
    const [filteredTeam, setFilteredTeam] = useState([]);
    const [att, setAtt] = useState('');
    const [leaveType, setLeaveType] = useState([]);
    const [clickedTeam, setClickedTeam] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [type, setType] = useState(false);
    const [memberClicked, setMemberClicked] = useState(false);


  const attendanceSchema1 = Yup.object().shape({
    user_id: Yup.string().required('user id is required'),       
    status: Yup.string().required('status is required'),       
    start_time: Yup.string().required('start Time is required'),       
    end_time: Yup.string(),       
    leave_type: Yup.string(),       
})
  const attendanceSchema2 = Yup.object().shape({
    user_id: Yup.string().required('user id is required'),       
    status: Yup.string().required('status is required'),       
    leave_type: Yup.string().required('Type is required'),       
})

const handleUsers = async (userid, name) => {
    setUserId(userid);
    setAttendance([]);
    setMemberId(userid)     
    setTeamName(name); 
    setClickedTeam(true);
    const atResponse = await getAttendance(usersId, roleId, userid, 0)
    if(atResponse != null) {
      setAttendance(atResponse);
    }  

}

const handleTeam = async (teamId, TeamName) => {
  setMemberId('');
  setAttendance([]);
  setTeamName(TeamName);
  setTeamId(teamId);
  setClickedTeam(true);
  const Response = await getTeamMembers(teamId)
  if(Response != null) {
    setTeamMembers(Response);
  }
  
  const atResponse = await getAttendance(usersId, roleId, 0, teamId)
    if(atResponse != null) {
      setAttendance(atResponse);
    } 
}

const teamsList =  async () => {
    var usersId = currentUser?.id;
    var roleId = currentUser?.designation;
    const Response = await getTeamsList(usersId, roleId)
    setTeams(Response);
    console.log('Response', Response)
}

const attendanceStatusList =  async () => {
    const Response = await getAttendanceStatus()
    setAttendanceStatus(Response);
}

const usersList =  async () => {
    var usersId = currentUser?.id;
    var roleId = currentUser?.designation;
    const Response = await getUsersByRole(usersId, roleId)
    setUsers(Response);
    
}

const LeaveTypeList =  async () => {
  const LeaveTypeResponse = await getLeaveType()
  setLeaveType(LeaveTypeResponse);
}

  
  const formik = useFormik({
    initialValues,
    validationSchema: att == 2 ? attendanceSchema2 : attendanceSchema1,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      try {        
        if(!editClicked) {

            var usersId = currentUser?.id;
            var roleId = currentUser?.designation;

            var st = moment(start).format('DD-MMMM-YYYY')+' '+values.start_time;
            var en = moment(values.end_time).format('DD-MMMM-YYYY HH:mm') == "Invalid date" ? '' : moment(values.end_time).format('DD-MMMM-YYYY HH:mm');

            const body = {
                "user_id": values.user_id,
                "start": st,
                "end": en,
                "status": values.status,
                "leave_type": values.leave_type,
                "created_by": usersId                          
            }

        const attendanceData = await saveAttendance(body);
       
        if(attendanceData != null){
            setLoading(false);
            setEditClicked(false);
            setClicked(false);
            setType(false);
            setAtt('');
            if (attendanceData == 'you cannot save attendance for a person twice in a day') {
            alert(attendanceData)
            }
            resetForm();
            document.getElementById('calender_model321')?.click();
            if(listChange == '1') {
              const atResponse = await getAttendance(usersId, roleId, 0, teamId)
              setAttendance(atResponse);
            } else {
              const atResponse = await getAttendance(usersId, roleId, memberId, 0)
              setAttendance(atResponse);
            }
                                    
        } else {
            resetForm();
            
        }
    } else {

      var st = moment(start).format('DD-MMMM-YYYY')+' '+values.start_time;
      var en = moment(values.end_time).format('DD-MMMM-YYYY HH:mm') == "Invalid date" ? moment(start).format('DD-MMMM-YYYY') : moment(values.end_time).format('DD-MMMM-YYYY HH:mm');

        const body2 = {
            "user_id": values.user_id,
            "start": st,
            "end": en,
            "status": values.status,                         
            "leave_type": values.leave_type,  
            "created_by": usersId                        
        }
        const attendanceData = await updateAttendance(id, body2);
       
        if(attendanceData != null){
            setLoading(false);
            resetForm();
            setAtt('');
            document.getElementById('calender_model321')?.click();
            if(listChange == '1') {
              const atResponse = await getAttendance(usersId, roleId, 0, teamId)
              setAttendance(atResponse);
            } else {
              const atResponse = await getAttendance(usersId, roleId, memberId, 0)
              setAttendance(atResponse);
            }
            
            
        } else {
            resetForm();
           
        }
    }

      } catch (error) {
        console.error(error)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)        
      }
}})
  

  const handleSelect = (start) => {
    alert(start)
    if(clickedTeam) {
    document.getElementById('calender_model123')?.click();
    formik.setFieldValue('user_id', userId ?? '');
    setStart(start)
    setEnd(start)
    } else {
      alert('Please select a team or user first!!!')
    }      
  }    
  
const handleEvent = (status, title, user_id, id, start_time, leave_type_id, e) => {
    setTitle(title);
    setId(id);
    console.log('id', id)
    console.log(start_time)
    console.log(moment('2022-12-02T22:33:00.000Z').format('HH:mm'))
    console.log(moment('2022-12-06T17:23:00.000Z').format('HH:mm'))
    console.log(moment(start_time).format('HH:mm'))
    setUserId(user_id);
    setEditClicked(true);
    setStart(start_time);
    document.getElementById('calender_model123')?.click();
    formik.setFieldValue('user_id', user_id ?? '')
    formik.setFieldValue('status', status ?? '')  
    if(status == 2 ) {
      setType(true)
      formik.setFieldValue('leave_type', leave_type_id ?? '')
      formik.setFieldValue('start_time', '')
      formik.setFieldValue('end_time', '')
    } else {
      setType(false)
      formik.setFieldValue('leave_type', '')
      formik.setFieldValue('start_time', moment(start_time).format('HH:mm') ?? '')
      formik.setFieldValue('end_time', e == "0000-00-00 00:00:00" ? '' : moment(e).format('HH:mm'))
    }
  }

const cancelEdit = () => {
    formik.setFieldValue('user_id', '')
    formik.setFieldValue('status', '') 
    formik.setFieldValue('leave_type', '')
    formik.setFieldValue('start_time', '')
    formik.setFieldValue('end_time', '') 
    formik.resetForm() 
    setEditClicked(false);
    setType(false);
    setAtt('');
}

const deleteAtt = async (id) => {
  const attendanceData = await deleteAttendance(id);
       
    if(attendanceData != null){
        formik.resetForm();
        setAtt('');
        document.getElementById('calender_model321')?.click();
        if(listChange == '1') {
          const atResponse = await getAttendance(usersId, roleId, 0, teamId)
          setAttendance(atResponse);
        } else {
          const atResponse = await getAttendance(usersId, roleId, memberId, 0)
          setAttendance(atResponse);
        }
    }
  }

  const ListChange = (e) => {
    setClickedTeam(false)
    setListChange(e);
    setAttendance([]);
    setAtt('')
    setMemberId(usersId);
    setTeamId('')
  }
 
function filterItem(item, search) {
    if (search.startsWith("@")) {
      const bucket = search.toLowerCase().substring(1).split(":");
      const searchBy = bucket[0];
      const searchFor = bucket[1];
      const searchByType = getType(item[searchBy]);
  
      if (!searchFor) return false;
      console.log("**** searchBy: ", searchBy);
      console.log("**** searchFor: ", searchFor);
      console.log("**** item: ", item);
      if (searchByType == "string") {
        if (searchFor.startsWith("!")) {
          const bucket = searchFor.split("!");
          const searchForVal = bucket[1];
          return !item[searchBy].includes(searchForVal);
        }
        return item[searchBy].includes(searchFor);
      }
  
      if (searchByType == "array") {
        if (searchFor.startsWith("!")) {
          const bucket = searchFor.split("!");
          const searchForVal = bucket[1];
          return item[searchBy].find((val) => !val.includes(searchForVal));
        }
        return item[searchBy].find((val) => val.includes(searchFor));
      }
  
      if (searchByType == "object") {
        if (searchFor.startsWith("!")) {
          const bucket = searchFor.split("!");
          const val = bucket[1] || "";
          return !item[searchBy][val];
        }
        if (searchFor.includes("!=")) {
          const bucket2 = searchFor.split("!=");
          const key = bucket2[0];
          const val = bucket2[1] || "";
          return item[searchBy][key] && !item[searchBy][key].includes(val);
        }
        const bucket2 = searchFor.split("=");
        const key = bucket2[0];
        const val = bucket2[1] || "";
        return item[searchBy][key] && item[searchBy][key].includes(val);
      }
    } else {
      if (search.startsWith("!")) {
        const bucket = search.split("!");
        const searchFor = bucket[1];
        if(listChange == '1') {
        return !item.team_name_lower.includes(searchFor);
        } else {
        return !item.employee_name_lower.includes(searchFor);
      }
      }
      if(listChange == '1') {
      return item.team_name_lower.includes(search);
       } else {
      return item.employee_name_lower.includes(search);
       }
    }
  }
  
  const getType = (value) => {
    if (Array.isArray(value)) {
      return "array";
    } else if (typeof value == "string") {
      return "string";
    } else if (value == null) {
      return "null";
    } else if (typeof value == "boolean") {
      return "boolean";
    } else if (Number(value) == value) {
      return "number";
    } else if (typeof value == "object") {
      return "object";
    }
    return "string";
  };

  const absentType = (data) => {
    formik.setFieldValue('status', data ?? '');
    setAtt(data);
  } 

  useEffect(() => {
    teamsList();
    attendanceStatusList();
    LeaveTypeList();
    usersList();

    let filteredData = [];
    if (search.length) {
      if(listChange == '1') {
        teams.forEach((item) => {
          if (filterItem(item, search.toLowerCase())) {
            filteredData.push({ ...item });
          }
        });
      } else {
        users.forEach((item) => {
          if (filterItem(item, search.toLowerCase())) {
            filteredData.push({ ...item });
          }
        });
      }
      
    }
    if(listChange == '1') {
    setFilteredTeam(filteredData);
    } else {
    setFiltered(filteredData);
    }
}, [search]);

  return (
    <div className="App">   
        <button className='btn btn-sm btn-secondary mt-3 me-3 d-none' data-bs-toggle='modal' id="calender_model123" data-bs-target='#calander_popup123456789'>ADD</button>                
        <div className="row">
          <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-2">            
            <div className="card h-100 bg-light h-1000px">
              <div className="card-header">
              <div className="pt-3 w-100">
                <select className="form-select btn btn-sm btn_secondary" onChange={(e) => ListChange(e.target.value)}>
                  <option value="1">Teams</option>
                  <option value="2">Users</option>
                </select>
              </div>
                <div className="input-group form_search my-6">
                  <input type="text" className="form-control" name="search" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)}/>
                  <div className="input-group-append">
                    <button className="btn btn-secondary" type="button">
                      <img src={toAbsoluteUrl('/media/custom/header-icons/search.svg')} className="svg_icon" alt='' />
                    </button>
                  </div>
                </div>  
              </div>
              {listChange == 1 &&
              <div className="card-body p-0 pt-5">
                  <ul>
                  {!search
                      ? teams.map((userData, i) => {
                        return(                            
                        <li className="list-group p-4" key={i}>
                          <div className={ teamId ==  userData.team_leader ? "text_primary" : "" }>
                            <a type="button" onClick={(e) => handleTeam(userData.team_leader, userData.team_leader_name)}>
                                <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                                {userData.team_leader_name}
                            </a>
                            </div>
                            <ul className={teamId ==  userData.team_leader ? "d-block" : "d-none"}>
                              {teamMembers.map((memb, i) => (
                              <li>
                                <div className={ memberId ==  memb.emp_id ? "text_primary" : "" }>
                                <a type="button" onClick={(e) => handleUsers(memb.emp_id, memb.employee_name)}>{memb.employee_name}</a>
                                </div>
                              </li>
                              ))}
                            </ul>                                  
                        </li>
                        )}) 
                      : filteredTeam.length ? filteredTeam.map((item) => (<li  className="list-group p-4">
                      <div className={ teamId ==  item.team_leader ? "text_primary" : "" }>
                              <a type="button" onClick={(e) => handleTeam(item.team_leader, item.team_leader_name)}>
                                <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                                {item.team_leader_name}
                              </a>
                            </div> 
                        <ul className={teamId ==  item.team_leader ? "d-block" : "d-none"}>
                          {teamMembers.map((memb, i) => (
                          <li>
                            <div className={ memberId ==  memb.emp_id ? "text_primary" : "" }>
                            <a type="button" onClick={(e) => handleUsers(memb.emp_id, memb.employee_name)}>{memb.employee_name}</a>
                            </div>
                          </li>
                          ))}
                        </ul>
                  </li>)) : <p>No Records!!!</p> }
                  </ul>
              </div>}
              {listChange == 2 &&
              <div className="card-body p-0 pt-5">
                  <ul>
                  {!search
                      ? users.map((item, i) => (
                          <li key={i} className="list-group p-4">
                            <div className={ memberId ==  item.user_id ? "text_primary" : "" }>
                              <a type="button" onClick={(e) => handleUsers(item.user_id, item.employee_name)}>
                                <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                                {item.employee_name}
                              </a>
                            </div> 
                      </li>
                          ))
                      : filtered.length ? filtered.map((item) => (<li  className="list-group p-4">
                      <div className={ memberId ==  item.user_id ? "text_primary" : "" }>
                              <a type="button" onClick={(e) => handleUsers(item.user_id, item.employee_name)}>
                                <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                                {item.employee_name}
                              </a>
                            </div> 
                  </li>)) : <p>No records!!!</p>}
                  </ul>
              </div>}
            </div>
          </div>
          <div className='modal fade' id={'calander_popup123456789'} aria-hidden='true'>
                  <div className='modal-dialog modal-dialog-centered'>
                      <div className='modal-content'>
                          <div className='modal-header'>
                            <div>
                              <h3>{editClicked ? "Update Attendance" : "Add Attendance" }</h3>
                              <h5 className="text-gray-400">{moment(start).format("DD-MM-YYYY")}</h5> 
                            </div>                             
                              <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={cancelEdit} id="calender_model321" data-bs-dismiss='modal'>
                                  <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                              </div>
                          </div>
                          <div className='modal-body py-lg-10 px-lg-10'>
                          <form noValidate onSubmit={formik.handleSubmit}>
                          {listChange == 1 &&
                          <div className="row">
                            <div className="col-md-6 col-12 mb-3">
                              <label htmlFor="basic-url" className="form-label required">User Name</label>
                              <div className="input-group mb-3">
                                  <select className="form-control w-100" {...formik.getFieldProps('user_id')}>
                                      <option disabled value=''>Select</option>
                                      {teamMembers.map((Branchobj, i) => {
                                          return (
                                              <option selected={Branchobj.emp_id == userId} value={Branchobj.emp_id} key={i}>{Branchobj.employee_name}</option>
                                          );
                                      })}
                                  </select>
                              </div>
                              {formik.touched.user_id && formik.errors.user_id && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert' className='text-danger'>{formik.errors.user_id}</span>
                                    </div>
                                </div>
                              )}
                            </div> 
                            <div className="col-md-6 col-12 mb-3">
                              <label htmlFor="basic-url" className="form-label required">Status</label>
                              <div className="input-group mb-3">
                                <select className="form-control w-100" {...formik.getFieldProps('status')} onChange={(e) => absentType(e.target.value)}>
                                  <option disabled value=''>Select</option>
                                  {attendanceStatus.map((obj, i) => {
                                    return (
                                      <option selected={obj.id == userId} value={obj.id} key={i}>{obj.name}</option>
                                    );
                                  })}
                                </select>
                              </div>
                              {formik.touched.status && formik.errors.status && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert' className='text-danger'>{formik.errors.status}</span>
                                    </div>
                                </div>
                              )}
                            </div>
                            {att == 2 ?
                            <div className="col-12 mb-3">
                                <label htmlFor="basic-url" className="form-label required">Leave Type</label>
                                <div className="input-group mb-3">
                                    <select className="form-control w-100" {...formik.getFieldProps('leave_type')}>
                                        <option value=''>Select</option>
                                        {leaveType.map((obj, i) => {
                                            return (
                                                <option selected={obj.id == userId} value={obj.id} key={i}>{obj.name}</option>
                                            );
                                        })}
                                    </select>
                                </div>
                                {formik.touched.leave_type && formik.errors.leave_type && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert' className='text-danger'>{formik.errors.leave_type}</span>
                                            </div>
                                        </div>
                                    )}
                            </div>
                            :<>
                            <div className="col-md-6 col-12 mb-3">
                              <label htmlFor="basic-url" className="form-label required">In Time</label>
                                <div className="input-group mb-3">
                                  <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" {...formik.getFieldProps('start_time')} className="form-control" />
                                </div>
                                {formik.touched.start_time && formik.errors.start_time && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert' className='text-danger'>{formik.errors.start_time}</span>
                                    </div>
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 col-12 mb-3">
                              <label htmlFor="basic-url" className="form-label">Out time</label>
                                <div className="input-group mb-3">
                                  <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" {...formik.getFieldProps('end_time')} className="form-control" />
                                </div>
                            </div>
                            </> }   
                          </div>}
                          {listChange == 2 &&                                
                          <div className="row">
                            <div className="col-md-6 col-12 mb-3">
                              <label htmlFor="basic-url" className="form-label">User Name</label>
                              <div className="input-group mb-3">
                                  <select disabled className="form-control w-100" {...formik.getFieldProps('user_id')}>
                                      <option disabled value=''>Select</option>
                                      {users.map((user, i) => {
                                          return (
                                              <option selected={user.user_id == userId} value={user.user_id} key={i}>{user.employee_name}</option>
                                          );
                                      })}
                                  </select>
                              </div>
                              {formik.touched.user_id && formik.errors.user_id && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert' className='text-danger'>{formik.errors.user_id}</span>
                                    </div>
                                </div>
                              )}
                            </div> 
                            <div className="col-md-6 col-12 mb-3">
                              <label htmlFor="basic-url" className="form-label">Status</label>
                              <div className="input-group mb-3">
                                <select className="form-control w-100" {...formik.getFieldProps('status')} onChange={(e) => absentType(e.target.value)}>
                                  <option disabled value=''>Select</option>
                                  {attendanceStatus.map((obj, i) => {
                                    return (
                                      <option selected={obj.id == userId} value={obj.id} key={i}>{obj.name}</option>
                                    );
                                  })}
                                </select>
                              </div>
                              {formik.touched.status && formik.errors.status && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert' className='text-danger'>{formik.errors.status}</span>
                                    </div>
                                </div>
                              )}
                            </div>
                            {att == 2 ?
                            <div className="col-12 mb-3">
                                <label htmlFor="basic-url" className="form-label">Leave Type</label>
                                <div className="input-group mb-3">
                                    <select className="form-control w-100" {...formik.getFieldProps('leave_type')}>
                                        <option value=''>Select</option>
                                        {leaveType.map((obj, i) => {
                                            return (
                                                <option selected={obj.id == userId} value={obj.id} key={i}>{obj.name}</option>
                                            );
                                        })}
                                    </select>
                                </div>
                                {formik.touched.leave_type && formik.errors.leave_type && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert' className='text-danger'>{formik.errors.leave_type}</span>
                                            </div>
                                        </div>
                                    )}
                            </div>
                            :<>
                            <div className="col-md-6 col-12 mb-3">
                              <label htmlFor="basic-url" className="form-label required">In Time</label>
                                <div className="input-group mb-3">
                                  <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" {...formik.getFieldProps('start_time')} className="form-control" />
                                </div>
                                {formik.touched.start_time && formik.errors.start_time && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert' className='text-danger'>{formik.errors.start_time}</span>
                                    </div>
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 col-12 mb-3">
                              <label htmlFor="basic-url" className="form-label">Out time</label>
                                <div className="input-group mb-3">
                                  <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" {...formik.getFieldProps('end_time')} className="form-control" />
                                </div>
                            </div>
                            </>}    
                          </div>}                                
                                <div className='card-footer py-5 text-center' id='kt_attence_footer'>
                                    <button
                                        type='submit'
                                        id='kt_add_attenance_submit'
                                        className='btn btn_primary text-primary'
                                        disabled={formik.isSubmitting}
                                    >
                                        {!loading && <span className='indicator-label'>{editClicked ? "Update" : "Save" }
                                            <KTSVG
                                                path='/media/custom/save_white.svg'
                                                className='svg-icon-3 svg-icon-primary ms-2' />
                                        </span>}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                    {editClicked &&
                                    <button type="button" className="btn btn_secondary text-danger ms-3" onClick={() => deleteAtt(id)}>Delete</button>}
                                </div> 
                            </form>                                                            
                        </div>
                    </div>
                </div>
          </div>
            {toggle == 1 &&<>
            {loading ? 
              <div className='w-100 h-100'>
                  <div className='d-flex justify-content-center flex-column align-items-center h_80vh'>
                      <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
                      <div className="spinner-border taskloader" role="status">                                    
                          <span className="sr-only">Loading...</span>
                      </div>
                  </div> 
              </div> :
            <div className="col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-10">
              <div className="d-flex justify-content-between">
                <h2>{teamName}</h2>
                {listChange == 2 &&
                <div className="card bs_1 p-3">
                  <div className="row">
                  <span className="col">
                    <p className="mb-0 text-center">Present</p>
                    <p className="mb-0 text-center">{attendance.present_count}</p>
                  </span>
                  <span className="col">
                    <p className="mb-0 text-center">Absent</p>
                    <p className="mb-0 text-center">{attendance.absent_count}</p>
                  </span>
                  <span className="col">
                    <p className="mb-0 text-center">Half_Day</p>
                    <p className="mb-0 text-center">{attendance.halfday_count}</p>
                  </span>
                  </div>                  
                </div>}
                <select className="btn_secondary h-100" onChange={(e) => setToggle(e.target.value)}>
                  <option value="1">Calender</option>
                  <option value="2">List</option>
                </select>
              </div>
      <Calendar
        views={["month"]}
        selectable
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={attendance.attendance}
        style={{ height: "100vh" }}
        onSelectEvent={(event) => handleEvent(event.status, event.title, event.user_id, event.id, event.start, event.leave_type_id, event.end )}
        onSelectSlot={(e) => handleSelect(e.start)}
        startAccessor="start"
      />
      <p className="required text_primary"><small>Note: P - Present, A - Absent, H - Half Day</small></p>
      </div>}</>}
      {toggle == 2 &&<>
      {loading ? 
      <div className='w-100 h-100'>
      <div className='d-flex justify-content-center flex-column align-items-center h_80vh'>
          <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
          <div className="spinner-border taskloader" role="status">                                    
              <span className="sr-only">Loading...</span>
          </div>
      </div> 
  </div> :
      <div className="col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-10">
        <div className="d-flex justify-content-between">
            <h2>{teamName}</h2>
            {listChange == 2 &&
            <div className="card bs_1 p-3">
              <div className="row">
              <span className="col">
                <p className="mb-0 text-center">Present</p>
                <p className="mb-0 text-center">{attendance.present_count}</p>
              </span>
              <span className="col">
                <p className="mb-0 text-center">Absent</p>
                <p className="mb-0 text-center">{attendance.absent_count}</p>
              </span>
              <span className="col">
                <p className="mb-0 text-center">Half_Day</p>
                <p className="mb-0 text-center">{attendance.halfday_count}</p>
              </span>
              </div>                  
            </div>}
            <select className="btn_secondary h-100" onChange={(e) => setToggle(e.target.value)}>
              <option value="1">Calender</option>
              <option selected value="2">List</option>
            </select>
          </div>
      {attendance != [] &&      
      <div className="mt-5" style={{ maxWidth: '100%' }} >
        
        <MaterialTable className="p-3"
        icons={tableIcons}
          columns={columns}
          data={attendance.attendance}
          title="Attendance"
          options={{
            actionsColumnIndex: -1,
            exportButton: true,
            headerStyle: {
                backgroundColor: '#ececec',
                color: '#000'
            },
            rowStyle: {
                backgroundColor: '#fff',
            }
          }}
        />
      </div>
          }
      </div>}</>}
      </div>
    </div>
  );
}
