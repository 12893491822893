import axios, {AxiosResponse}  from 'axios'

const API_URL = process.env.REACT_APP_API_URL


// user api's
export const GET_ALL_ORGANIZATION_COMPANY_URL = `${API_URL}/get_all_organization_company`
export const GET_ORGANIZATION_COMPANY_URL = `${API_URL}/get_organization_company`
export const SAVE_ORGANIZATION_COMPANY_URL = `${API_URL}/save_organization_company`
export const UPDATE_ORGANIZATION_COMPANY_URL = `${API_URL}/put_organization_company`
export const DELETE_ORGANIZATION_COMPANY_URL = `${API_URL}/delete_organization_company`


export function getAllOrganozationCompany() {
    return axios.get(GET_ALL_ORGANIZATION_COMPANY_URL)
    .then((response => response.data))
}

export function getOrganozationCompany(id:any) {
    return axios.get(GET_ORGANIZATION_COMPANY_URL+'/'+id)
    .then((response => response.data))
}

export function saveOrganozationCompany(body:any, header:any) {
    return axios.post(SAVE_ORGANIZATION_COMPANY_URL, body, header)
    .then((response => response.data))
}

export function updateOrganozationCompany(id:any ,body:any) {
    return axios.put(UPDATE_ORGANIZATION_COMPANY_URL+'/'+id, body)
    .then((response => response.data))
}

export function deleteOrganozationCompany(id:any) {
    return axios.put(DELETE_ORGANIZATION_COMPANY_URL+'/'+id)
    .then((response => response.data))
}