import React,{FC, useState,useEffect} from 'react'
import {Link} from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import {useAuth} from '../../../app/modules/auth'
import { saveTask, getAssignTo, getContacts, getTasksByRole, getTaskType, getTaskStatus, getTaskPriority, getProjects } from './core/_requests'
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import { Toast } from 'bootstrap';
import FormControl from '@mui/material/FormControl';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { getAssignToList, getContactDetail } from '../contact/core/_requests'

const initialValues = {
    task_type: "",
    priority: "",
    task_time: "",  
    finish_time: "",  
    project: "0",
    contact: "",
    // agenda: "",
    assign_to: [],
    task_note: "",
    task_status:"2"
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, aminityName: string[], theme: Theme) {
    return {
        fontWeight:
        aminityName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
    };
}

const mystyle = {
    padding: "0px",
    height: "34px",
    maxHeight: "200px",
    overflowY: "scroll",
    // display: "block"
};

type Props = {
    setTasks?: any,
    contactName?: any,
    checkTaskClick?: any,
}

const TaskForm:  FC<Props> = (props) => {

    const {setTasks, contactName, checkTaskClick} = props

    const [contactDetail, setContactDetail] = useState<{[key: string]: any}>({});

    const leadFromContact = async () => {
        const fetchDetails = await getContactDetail(contactName['id'])
        setContactDetail(fetchDetails)

        formik.setFieldValue('project', fetchDetails.property_id ?? '')

        var assignArray = [];
        var assignNameArray = [];
        if(fetchDetails.assign_to != null){
            assignArray = fetchDetails.assign_to.split(",").map((e:any) => {
                return parseInt(e);
            });
        }
        if(fetchDetails.assign_to_name != null){
            assignNameArray = fetchDetails.assign_to_name.split(",").map((e:any) => {
                return e;
            });
        }

        setAssignToId(assignArray);
        setAssignToName(assignNameArray);
    }

    useEffect(() => {
        contactName?.id && leadFromContact();
    },[checkTaskClick == true]);

    const taskSaveSchema = Yup.object().shape({
        task_type: Yup.string().required('Task type is required'),
        priority: Yup.string().required('Priority is required'),
        task_time: Yup.string().required('Task time is required'),
        finish_time: Yup.string(),
        project: Yup.string(),
        contact: Yup.string(),
        // agenda: Yup.string(),
        assign_to: Yup.array(),
        task_note: Yup.string().required('Task Description is required'),
        task_status: Yup.string(),
    })

    const [loading, setLoading] = useState(false);
    const [taskTime, setTaskTime] = React.useState<any>('');
    const [finishTime, setFinishTime] = React.useState<any>('');
    const [assignToName, setAssignToName] = React.useState<string[]>([]);
    const [assignToId, setAssignToId] = React.useState<any[]>([]);
    const [assignTo, setAssignTo] = useState<any[]>([]);
    const [taskType, setTaskType] = useState<any[]>([]);
    const [taskPriority, setTaskPriority] = useState<any[]>([]);
    const [taskProjects, setTaskProjects] = useState<any[]>([]);
    const [projectDopList, setProjectDopList] = useState<any[]>([]);
    const [taskStatus, setTaskStatus] = useState<any[]>([]);
    const [contacts, setContacts] = useState<any[]>([]);
    const [asTo, setAsTo] = useState<any[]>([]);
    const {currentUser, logout} = useAuth();

    const theme = useTheme(); 

    const assingToChange = (event: SelectChangeEvent<typeof assignToName>) => {
        const {
          target: { value },
        } = event;

        var userId = currentUser?.id;
        var roleId = currentUser?.designation;  

        var name = [];
        var id = [];
        if (value.length > 0) {    
        for(let i = 0; i < value.length; i++){
          var fields = value[i].split('-');
    
          var n = fields[0];
          var d = fields[1];
    
          name.push(n);
          id.push(d);
        }}
        else {
            id.push(userId)
        }
    
        console.log(name);
        console.log(id);
    
        setAssignToId(id);

        setAssignToName(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
    };

    var userId = currentUser?.id;
    var roleId = currentUser?.designation;
    console.log('rgfsfdghgthrth');
    console.log(userId);

    const formik = useFormik({
        initialValues,
        validationSchema: taskSaveSchema ,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
        //   console.log('lead form body');
        //   console.log(values);
          try {
    
            console.log('sddddddddassdsf');
            
            var body;

            if(checkTaskClick && contactName != null){
                var assignedTo = contactName['assign_to'].split(',');
                body = {
                    "task_type": values.task_type,
                    "priority": values.priority,
                    "task_time": taskTime,
                    "finish_time": finishTime,
                    "project": values.project,
                    "contact": contactName['id'],
                    // "agenda": values.agenda,
                    "assign_to": assignedTo,
                    "created_by": userId,
                    "task_note": values.task_note,
                    "task_status":values.task_status,
                }
            } else {
                body = {
                    "task_type": values.task_type,
                    "priority": values.priority,
                    "task_time": taskTime,
                    "finish_time": finishTime,
                    "project": values.project,
                    "contact": values.contact,
                    // "agenda": values.agenda,
                    "assign_to": assignToId.length > 0 ? assignToId: [userId],
                    "created_by": userId,
                    "task_note": values.task_note,
                    "task_status":values.task_status,
                }
            }
            const saveTaskData = await saveTask(body);
    
            console.log('saveTaskData');
            console.log(saveTaskData);
            // document.getElementById('kt_contact')?.classList.remove('drawer-on');
            if(saveTaskData != null){
                setLoading(false);
                document.getElementById('kt_task_close')?.click();
                document.getElementById('taskReload')?.click();
                var toastEl = document.getElementById('myToastAdd');
                const bsToast = new Toast(toastEl!);
                bsToast.show();                
                resetForm();
                formik.setFieldValue('project', '0');
                formik.setFieldValue('task_time', taskTime ?? '');
                formik.setFieldValue('finish_time', finishTime ?? '');
                setAssignToName([]);
                // setTaskTime(null);
                // setFinishTime(null);
            }
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
    }})

    const handleOnSearch = (string:any, results:any) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        console.log(string, results)
    }

    const handleOnHover = (result:any) => {
        // the item hovered
        console.log(result)
    }

    const handleOnSelect = (item:any) => {
        // the item selected
        console.log(item)
    }

    const handleOnFocus = () => {
        console.log('Focused')
    }

    const formatResult = (item:any) => {
        return (
            <>
            {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
            <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
            </>
        )
    }

    const assignToList =  async () => {   
        var userId = currentUser?.id;
        var roleId = currentUser?.designation; 
        const assignToResponse = await getAssignToList(userId, roleId);
        setAssignTo(assignToResponse);
    }

    const taskTypeList =  async () => {   
        const taskTypeResponse = await getTaskType();
        setTaskType(taskTypeResponse);
    }

    const taskStatusList =  async () => {   
        const taskStatusResponse = await getTaskStatus();
        setTaskStatus(taskStatusResponse);
        formik.setFieldValue('task_status', 2);
    }

    const taskPriorityList =  async () => {   
        const taskPriorityResponse = await getTaskPriority();
        setTaskPriority(taskPriorityResponse);
    }

    const taskProjectList =  async () => {   
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const taskProjectResponse = await getProjects(userId, roleId);
        console.log('taskProjectResponse');
        console.log(taskProjectResponse);
        setTaskProjects(taskProjectResponse);
        
        var propertyDropList = [];

        for(let i = 0; i < taskProjectResponse.length; i++) {
            var data = { "id": taskProjectResponse[i]?.id, "name": taskProjectResponse[i]?.name_of_building }
            propertyDropList.push(data);
        }

        setProjectDopList(propertyDropList);
    }

    const contactsList =  async () => {   
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const contactsResponse = await getContacts(userId, roleId);
    
        console.log('leads contactsResponse');
        console.log(contactsResponse);
    
        var contactDropList = [];
    
        for(let i = 0; i < contactsResponse.length; i++) {
            var data = { "id": contactsResponse[i].id, "name": contactsResponse[i].first_name + ' '+ contactsResponse[i].last_name }
            contactDropList.push(data);
        }
    
        console.log(contactDropList);
        setContacts(contactDropList);
    }

    const editing = async () => {
        formik.setFieldValue('project', contactName['property_id'] ?? '');
        const fetchDetails = await getContactDetail(contactName['id'])
        var assignArray = [];
        var assignNameArray = [];
        if(fetchDetails.assign_to != null){
            assignArray = fetchDetails.assign_to.split(",").map((e:any) => {
                return parseInt(e);
            });
        }
        if(fetchDetails.assign_to_name != null){
            assignNameArray = fetchDetails.assign_to_name.split(",").map((e:any) => {
                return e;
            });
        }
    
        setAssignToId(assignArray);
        setAssignToName(assignNameArray);
        
    }

    const propertyDropSelect = (id:any, type:any) => {
        formik.setFieldValue('project', id ?? '');
    }

    const formatResultProperty = (item:any) => {
        return (
            <>
            <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
            </>
        )
    }
    
    useEffect(() => {
        contactName?.id && editing();
    }, [contactName]);

    useEffect(() => {
        contactsList();
        assignToList();
        taskTypeList();
        taskStatusList();
        taskPriorityList();
        taskProjectList();
        formik.setFieldValue('task_time', taskTime ?? '');
        formik.setFieldValue('finish_time', finishTime ?? '');
    }, []);

    return(
        <div className='card shadow-none rounded-0 w-100'>
            <div className='card-header w-100' id='kt_task_header'>
                <h3 className='card-title fw-bolder text-dark'>Add Task</h3>

                <div className='card-toolbar'>
                    <button
                    type='button'
                    className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                    id='kt_task_close'
                    onClick={() => formik.resetForm()}
                    >
                        <img src={toAbsoluteUrl('/media/custom/cancel_black.svg')} className="svg_icon" alt='' />
                        {/* <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' /> */}
                    </button>
                </div>
            </div>
            <form noValidate onSubmit={formik.handleSubmit} >
            <div className='card-body position-relative' id='kt_task_body'>
                <div className="accordion" id="accordionExample"> 
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Task Details
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className="row">
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label required">Task Type</label>
                                        <div className="input-group mb-3">
                                            <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('task_type')}>
                                            <option value=''>Select</option>
                                                {taskType.map((task,i) =>{
                                                    return (
                                                        <option selected={i == 0 ? true: false} value={task.id} key={i}>{task.name}</option> 
                                                )})}
                                            </select>
                                        </div>
                                        {formik.touched.task_type && formik.errors.task_type && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert' className='text-danger'>{formik.errors.task_type}</span>
                                            </div>
                                        </div>
                                        )}
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label required">Priority</label>
                                        <div className="input-group mb-3">
                                            <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('priority')}>
                                            <option value=''>Select</option>
                                                {taskPriority.map((taskPrior,i) =>{
                                                    return (
                                                        <option selected={i == 0 ? true: false} value={taskPrior.id} key={i}>{taskPrior.name}</option> 
                                                )})}
                                            </select>
                                        </div>
                                        {formik.touched.priority && formik.errors.priority && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert' className='text-danger'>{formik.errors.priority}</span>
                                            </div>
                                        </div>
                                        )}
                                    </div>
                                    <div className="col-md-6 col-12 mb-3 date_time_white">
                                        <label htmlFor="basic-url" className="form-label required">Task Time</label>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DateTimePicker
                                            renderInput={(props) => <TextField {...formik.getFieldProps('task_time')} className='w-100' {...props} /> }
                                            // label="DateTimePicker"
                                            value={taskTime}
                                            onChange={(newValue) => {
                                                setTaskTime(newValue);
                                                formik.setFieldValue('task_time', newValue ?? '');
                                            }}
                                            />
                                        </LocalizationProvider>
                                        {formik.touched.task_time && formik.errors.task_time && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert' className='text-danger'>{formik.errors.task_time}</span>
                                            </div>
                                        </div>
                                        )}
                                    </div>
                                    <div className="col-md-6 col-12 mb-3 date_time_white">
                                        <label htmlFor="basic-url" className="form-label">Finish Time</label>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DateTimePicker
                                            renderInput={(props) => 
                                                <TextField {...formik.getFieldProps('finish_time')} className='w-100' {...props} />
                                             } 
                                            // label="DateTimePicker"
                                            value={finishTime}
                                            onChange={(newValue) => {
                                                formik.setFieldValue('finish_time', finishTime ?? '');
                                                setFinishTime(newValue);
                                            }}
                                            />
                                        </LocalizationProvider>
                                        {formik.touched.finish_time && formik.errors.finish_time && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert' className='text-danger'>{formik.errors.finish_time}</span>
                                            </div>
                                        </div>
                                        )}
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Project</label>
{/*                                     
                                        <div className="input-group mb-3">
                                            <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('project')}>
                                            <option value=''>Select</option>
                                                {taskProjects.map((taskProject,i) =>{
                                                    return (
                                                        <option selected={i == 0 ? true: false} value={taskProject.id} key={i}>{taskProject.name_of_building}</option> 
                                                )})}
                                            </select>
                                        </div> */}
                                        <div className="input-group mb-3">
                                            <div className='autocomplete_field w-100'>
                                                <ReactSearchAutocomplete
                                                    items={projectDopList}
                                                    {...formik.getFieldProps('project')}
                                                    onSearch={handleOnSearch}
                                                    onHover={handleOnHover}
                                                    onSelect={(val) => propertyDropSelect(val.id, val.name_of_building)}
                                                    onFocus={handleOnFocus}
                                                    placeholder="Choose Property..."
                                                    styling={mystyle}
                                                    autoFocus
                                                    formatResult={formatResultProperty}
                                                />                                        
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Select Contact</label>
                                        {checkTaskClick && contactName != null ?
                                    <div className="input-group mb-3">
                                        <input type="text" value={contactName['name']} disabled name="contact_id" className='form-control form-control-lg form-control-solid' placeholder="Contact Person" aria-label="Search a contact"/>
                                    </div>
                                    :
                                        <div className="input-group mb-3">
                                            <div className='autocomplete_field w-100'>
                                                <ReactSearchAutocomplete
                                                    items={contacts}
                                                    {...formik.getFieldProps('contact')}
                                                    onSearch={handleOnSearch}
                                                    onHover={handleOnHover}
                                                    // onSelect={handleOnSelect}
                                                    onSelect={(val) => formik.setFieldValue('contact', val.id ?? '')}
                                                    onFocus={handleOnFocus}
                                                    placeholder="choose contact"
                                                    styling={mystyle}
                                                    autoFocus
                                                    formatResult={formatResult}
                                                />
                                            </div>
                                            {/* <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('contact')}>
                                                <option value="0">David</option>
                                                <option value="1">Nirmal</option>
                                            </select> */}
                                        </div>}
                                    </div>
                                    {/* <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Agenda</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <input type="text" className="form-control" {...formik.getFieldProps('agenda')} placeholder="Enter Agenda"/>
                                        </div>
                                    </div> */}
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Assign To</label>
                                    
                                        <FormControl sx={{ m: 0, width: 300, mt: 0 }}>
                                            <Select
                                                multiple
                                                displayEmpty
                                                value={assignToName}
                                                onChange={assingToChange}
                                                input={<OutlinedInput />}
                                                renderValue={(selected) => {
                                                    var name = [];
                                                    var id = [];

                                                    for(let i = 0; i < selected.length; i++){
                                                        var fields = selected[i].split('-');

                                                        var n = fields[0];
                                                        var d = fields[1];

                                                        name.push(n);
                                                        id.push(d);
                                                    }

                                                    // setAssignToId(id);
                                                    
                                                    if (selected.length === 0) {
                                                    return <p>Assign To</p>;
                                                    }

                                                    return name.join(', ');
                                                }}
                                                className='multi_select_field'
                                                MenuProps={MenuProps}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                <MenuItem disabled value="">
                                                    <em>Assign To</em>
                                                </MenuItem>
                                                {assignTo.map((assignVal) => (
                                                    <MenuItem
                                                    key={assignVal.user_id}
                                                    value={assignVal.employee_name+'-'+assignVal.user_id}
                                                    style={getStyles(assignVal.employee_name, assignToName, theme)}
                                                    >
                                                    {assignVal.employee_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        {/* <div className="input-group mb-3">
                                            <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('assign_to')}>
                                                <option value="0">David</option>
                                                <option value="1">Murugan</option>
                                            </select>
                                        </div> */}
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Task Status</label>
                                        <div className="input-group mb-3">
                                            <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('task_status')}>
                                            <option value=''>Select</option>
                                                {taskStatus.map((taskState,i) =>{
                                                    return (
                                                        <option selected={i == 0 ? true: false} value={taskState.id} key={i}>{taskState.name}</option> 
                                                )})}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-12 mb-3">
                                        <div>
                                            <label htmlFor="basic-url" className="form-label required">Task Description</label>
                                            <textarea
                                            className='form-control border-0 p-2 resize-none min-h-25px br_10' {...formik.getFieldProps('task_note')}
                                            data-kt-autosize='true'
                                            rows={5}
                                            placeholder='Enter your Notes'
                                            ></textarea>
                                        </div>
                                        {formik.touched.task_note && formik.errors.task_note && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert' className='text-danger'>{formik.errors.task_note}</span>
                                            </div>
                                        </div>
                                        )}
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card-footer py-5 text-center' id='kt_task_footer'>
                <button
                  type='submit'
                  id='kt_sign_up_submit'
                  className='btn btn_primary text-primary'
                  disabled={formik.isSubmitting}
                >
                  {!loading && <span className='indicator-label'>Submit
                  <KTSVG
                  path='/media/custom/save_white.svg'
                  className='svg-icon-3 svg-icon-primary ms-2'
                />
                  </span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
            </div>
            </form>
        </div>
    )
}


export {TaskForm}