import React,{FC, useEffect, useState} from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { Toast } from 'bootstrap';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { useAuth } from '../../modules/auth';
import Moment from 'moment';
import { getAssignTo, getFilteredTasks, getTaskFilter, getTaskFilters, getTasksByRole, getTaskStatus, getTaskType, saveTaskFilter } from './core/_requests';
import { getPriority } from '../settings/masters/core/_requests';
import FormControl from '@mui/material/FormControl';
import { getProperties,getProjects } from '../property/core/_requests';
import { getAssignToList } from '../contact/core/_requests';

const initialValues = {
    task_type: '',
    priority: '',
    property: '',
    created_date: '',
    assign_to: [],
    task_status: '',   
    filter_name: '',   
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, aminityName: string[], theme: Theme) {
    return {
        fontWeight:
        aminityName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
    };
}

const mystyle = {
    padding: "0px",
    height: "34px",
    maxHeight: "200px",
    overflowY: "scroll",
    // display: "block"
};

type Props = {
    setTasks?: any
}


const TaskFilter:  FC<Props> = (props) => {

    const {
        setTasks
    } = props        

    const theme = useTheme(); 
    const {currentUser, logout} = useAuth();
    const [assignToId, setAssignToId] = useState<any[]>([]); 
    const [save, setSave] = useState(false); 
    const [assignToName, setAssignToName] = useState<any[]>([]); 
    const [taskType, setTaskType] = useState<any[]>([]); 
    const [priority, setPriority] = useState<any[]>([]); 
    const [taskStatus, setTaskStatus] = useState<any[]>([]); 
    const [assignTo, setAssignTo] = useState<any[]>([]); 
    const [taskFilters, setTaskFilters] = useState<any[]>([]); 
    const [filterDetail, setFilterDetail] = useState<any[]>([]); 
    const [properties, setProperties] = useState<any[]>([]); 
    var userId = currentUser?.id;
    var roleId = currentUser?.designation;

    const taskFilterSchema = Yup.object().shape({
        task_type: Yup.string(),
        priority: Yup.string(),
        property: Yup.string(),
        created_date: Yup.string(),
        task_status: Yup.string(),        
        filter_name: Yup.string(),        
    })

    const assingToChange = (event: SelectChangeEvent<typeof assignToName>) => {
        const {
          target: { value },
        } = event;
        var name = [];
        var id = [];

        for(let i = 0; i < value.length; i++){
        var fields = value[i].split('-');

        var n = fields[0];
        var d = fields[1];

        name.push(n);
        id.push(d);
        }

        console.log(name);
        console.log(id);

        setAssignToId(id);

        setAssignToName(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
    };

    const formik = useFormik({
        initialValues,
        validationSchema: taskFilterSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
        //   setLoading(true)
        //   console.log('lead form body');
        //   console.log(values);
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        try {
            

        console.log('sddddddddassdsf');

        const body = {
            "task_type" : values.task_type,
            "assign_to" : assignToId,
            "priority": values.priority,
            "property": values.property,
            "created_date": values.created_date,
            "task_status": values.task_status, 
            "filter_name": values.filter_name, 
            "created_by": userId, 
        }

        const headers = {
            headers: {
                "Content-type": "application/json",
            },                    
          }

          if(save) {

            const filterLeadData = await saveTaskFilter(body);

                if(filterLeadData != null){
                    document.getElementById('kt_task_filter_close')?.click();
                    var toastEl = document.getElementById('myToastAdd');
                    const bsToast = new Toast(toastEl!);
                    bsToast.show();
                    resetForm();
                    taskFiltersList()
                    setAssignToName([])
                }
            } 
            else {
                const filterPropertyData = await getFilteredTasks(userId, roleId, body, headers);

                console.log('updateLeadData');
                console.log(filterPropertyData);
                // document.getElementById('kt_contact')?.classList.remove('drawer-on');
                if(filterPropertyData != null) {
                    document.getElementById('kt_task_filter_close')?.click();
                    var toastEl = document.getElementById('myToastAdd');
                    const bsToast = new Toast(toastEl!);
                    bsToast.show();
                    console.log('filterPropertyData');
                    console.log(filterPropertyData);                    
                    resetForm();
                    setAssignToName([])                    
                    //     var userId = currentUser?.id;
                    //     var roleId = currentUser?.designation;
                    //     const characterResponse = await getTasksByRole(userId, roleId)
                    setTasks(filterPropertyData);
                }
            }
        } catch (error) {
        console.error(error)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        }
    }})

    const TaskTypeList =  async () => {
        const TaskTypeResponse = await getTaskType()
        setTaskType(TaskTypeResponse);
    }
    const PriorityList =  async () => {
        const PriorityResponse = await getPriority()
        setPriority(PriorityResponse);
    }
    const TaskStatusList =  async () => {
        const TaskStatusResponse = await getTaskStatus()
        setTaskStatus(TaskStatusResponse);
    }
    const assignToList =  async () => {   
        var userId = currentUser?.id;
        var roleId = currentUser?.designation; 
        const assignToResponse = await getAssignToList(userId, roleId);
        setAssignTo(assignToResponse);
    }
    const taskFiltersList =  async () => { 
        var userId = currentUser?.id;  
        const assignToResponse = await getTaskFilters(userId);
        setTaskFilters(assignToResponse);
    }

    const filterTap =  async (Id : any) => {
        // contactId : number
        // setLoading(true);
        const fetchDetails = await getTaskFilter(Id)
        console.log('fetchDetails');    
        console.log(fetchDetails);
        setFilterDetail(fetchDetails)          
        formik.setFieldValue('task_type', fetchDetails.task_type ?? '')
        formik.setFieldValue('priority', fetchDetails.priority ?? '')
        formik.setFieldValue('property', fetchDetails.property ?? '')
        formik.setFieldValue('created_date', Moment(fetchDetails.created_date).format("YYYY-MM-DD") ?? '')
        formik.setFieldValue('task_status', fetchDetails.task_status ?? '')
        // setLoading(false);

        var assignArray = [];
        var assignNameArray = [];
        if(fetchDetails.assign_to != null){
            assignArray = fetchDetails.assign_to.split(",").map((e:any) => {
                return e;
            });
        }
        if(fetchDetails.assign_to_name != null){
            assignNameArray = fetchDetails.assign_to_name.split(",").map((e:any) => {
                return e;
            });
        }

        setAssignToId(assignArray)
        setAssignToName(assignNameArray)
        
    }

    const resetFilter = async () => {
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const characterResponse = await getTasksByRole(userId, roleId)
        setTasks(characterResponse);

        console.log('sdasdsd')
        formik.resetForm();
        setAssignToName([])
        document.getElementById('kt_task_filter_close')?.click();
        document.getElementById('taskReload')?.click();
        var toastEl = document.getElementById('myToastAdd');
        const bsToast = new Toast(toastEl!);
        bsToast.show();
        
    }

    const propertyList =  async () => {   
        const Response = await getProperties(userId, roleId)
        setProperties(Response);
    }

    useEffect(() => {
        TaskTypeList();
        PriorityList();        
        TaskStatusList();        
        assignToList();        
        taskFiltersList();        
        propertyList();        
    }, []);

    return(
        <div className='card shadow-none rounded-0 w-100'>
                <div className='card-header w-100' id='kt_filter_header'>
                    <h3 className='card-title fw-bolder text-dark'>Task Filter</h3>

                    <div className='card-toolbar'>
                        <button
                        type='button'
                        className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                        id='kt_task_filter_close'
                        >
                            <img src={toAbsoluteUrl('/media/custom/cancel_black.svg')} className="svg_icon" alt='' />
                        {/* <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' /> */}
                        </button>
                    </div>
                </div>
                <div className='card-body position-relative' id='kt_filter_body'>
                <form noValidate onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Task Type</label>
                            <div className="input-group mb-3">
                                <select className="btn w-100 text-start form-select bg-gray-100 rounded" {...formik.getFieldProps('task_type')}>
                                    <option>Select</option>
                                    {taskType.map((data,i) =>{
                                        return (
                                            <option value={data.id} key={i}>{data.name}</option> 
                                    )})}
                                </select>      
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Priority</label>
                            <div className="input-group mb-3">
                                <select className="btn w-100 text-start form-select bg-gray-100 rounded" {...formik.getFieldProps('priority')}>
                                    <option>Select</option>
                                    {priority.map((data,i) =>{
                                        return (
                                            <option value={data.id} key={i}>{data.name}</option> 
                                    )})}
                                </select>      
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Created Date</label>
                            <div className="input-group mb-3">
                                <input type="date" className="input-group btn w-100 text-start input_prepend bg-gray-100 rounded" placeholder="date" {...formik.getFieldProps('created_date')}/> 
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Property</label>
                            <div className="input-group mb-3">
                            <select className="btn w-100 text-start form-select bg-gray-100 rounded" 
                            {...formik.getFieldProps('property')}
                            >
                                {properties.map((value,i)=> {
                                  return (
                                    <option selected={i == 0 ? true: false} value={value.id} key={i}>{value.name_of_building}</option>
                                  )
                                })}   
                            </select>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3 edit_page_form">
                            <label htmlFor="basic-url" className="form-label">Assign To</label>
                            <FormControl sx={{ m: 0, width: '100%', mt: 0 }}>
                                <Select
                                    multiple
                                    displayEmpty
                                    value={assignToName}
                                    onChange={assingToChange}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => {
                                        var name = [];
                                        var id = [];

                                        for(let i = 0; i < selected.length; i++){
                                            var fields = selected[i].split('-');

                                            var n = fields[0];
                                            var d = fields[1];

                                            name.push(n);
                                            id.push(d);
                                        }

                                        if (selected.length === 0) {
                                        return <p>Assign To</p>;
                                        }

                                        return name.join(', ');
                                    }}
                                    className='multi_select_field form-control btn w-100 text-start form-select bg-gray-100 rounded'
                                    MenuProps={MenuProps}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                    <MenuItem disabled value="">
                                        <em>Assign To</em>
                                    </MenuItem>
                                    {assignTo.map((assignVal) => (
                                        <MenuItem
                                        key={assignVal.user_id}
                                        value={assignVal.employee_name+'-'+assignVal.user_id}
                                        style={getStyles(assignVal.employee_name, assignToName, theme)}
                                        >
                                        {assignVal.employee_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Status</label>
                            <div className="input-group mb-3">
                                <select className="btn w-100 text-start form-select bg-gray-100 rounded" {...formik.getFieldProps('task_status')}>
                                    <option>Select</option>
                                    {taskStatus.map((data,i) =>{
                                        return (
                                            <option value={data.id} key={i}>{data.name}</option> 
                                    )})}
                                </select>      
                            </div>
                        </div>
                    </div>
                <div className='card-footer py-5 text-center filter_footer d-flex flex-column flex-md-row align-items-center justify-content-between'>
                    <div className="mb-3 mb-md-0">
                        <div className="form-check d-flex">
                            <input className="form-check-input" type="checkbox" value="" id="filterCheck"/>
                            <label className="form-check-label mx-3" htmlFor="filterCheck">
                                Include Archived Records
                            </label>
                        </div>
                    </div>
                    <div className="mb-3 mb-md-0">
                        <button type='button' className='btn btn-sm btn_soft_primary save_btn p-3 pr-0 mx-3 br_10' data-bs-toggle='modal' data-bs-target={'#task_filter_save_popup'} title="Save">
                            <KTSVG
                                path='/media/custom/save_orange.svg'
                                className='svg-icon-3 svg-icon-primary me-0'
                            />
                            {/* <i className="far fa-save mx-auto"></i> */}
                        </button>
                        <button type='button' className='btn btn-sm reset_btn mx-3 br_10' onClick={resetFilter}>
                            <i className="fas fa-undo"></i>
                            Reset
                        </button>
                        <button className='btn btn-sm btn_primary mx-3 br_10' onClick={(e) => setSave(false)}>
                            <KTSVG path='/media/custom/search_white.svg' className='svg-icon-5 svg-icon-gray-500 me-1'
                            />
                            {/* <i className="fas fa-search text-white"></i> */}
                            Search
                        </button>
                    </div>
                    <div className='modal fade' id={'task_filter_save_popup'} aria-hidden='true'>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className='modal-content'>
                                <div className='modal-header'>
                                    <h3>Want to Save Filter?</h3>
                                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                    </div>
                                </div>
                                <div className='modal-body py-lg-10 px-lg-10'>
                                    <div className="mb-3 text-start">
                                        <label htmlFor="basic-url" className="form-label">Filter Name</label>
                                        <div className="input-group mb-3">
                                            <input type="text" className="input-group btn w-100 text-start input_prepend bg-gray-100 rounded" placeholder="" {...formik.getFieldProps('filter_name')}/> 
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-end'>
                                        <button type='button' className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal'>
                                            No
                                        </button>
                                        <button type='submit' className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal'   onClick={(e) => setSave(true)}>
                                            Yes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            {taskFilters.length > 0
            ? <div className='row'>
                <div className='col-12'>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Saved Filters
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <div className='row'>
                                {taskFilters.map((Data, i) => {
                                return(
                                    <div className='col-xxl-4'>
                                        <div onClick={(e) => filterTap(Data.id)} className="d-flex cursor_pointer align-items-center bg_soft rounded py-5 px-4 mb-7">
                                            <div className="flex-grow-1">
                                                <a href="#" className="fw-bold text-gray-800 text-hover-primary fs-6">{Data.filter_name}</a>
                                                <span className="text-muted fw-semibold d-block">{Moment(Data.created_at).format("DD-MM-YYYY HH:mm")}</span>
                                            </div>
                                        </div>
                                    </div>
                                )})}
                            </div>
                        </div>
                    </div>
                </div>
                    
                </div>
            </div> : <></>
            }
            </div>
        </div>
    )
}


export {TaskFilter}