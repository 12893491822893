import React, {useState, useEffect} from "react";
import Draggable from "react-smooth-draggable";


export default function App(names) {

  // console.log("names");
  // console.log(names.names);
  // if(names.names.length)

  const [masterList, setMasterList] = useState(["qeqwe",
  "jjshh",
  "piaif",
  "asjdhgj"]);

  
  function reorder(subject, before, after) {
    console.log(`move ${subject} between ${before} and ${after}`); 
  //   console.log("names");
  // console.log(names); 
  //   setMasterList(names[0]) 
  
    if (before !== undefined) {
      setMasterList((masterList) => {
        const draft = [...masterList];

        const index = draft.indexOf(subject);
        draft.splice(index, 1);

        const beforeIndex = draft.indexOf(before);
        draft.splice(beforeIndex + 1, 0, subject);

        return draft;
      });
    } else if (after !== undefined) {
      setMasterList((masterList) => {
        const draft = [...masterList];

        const index = draft.indexOf(subject);
        draft.splice(index, 1);

        const afterIndex = draft.indexOf(after);
        console.log({ afterIndex, index });
        draft.splice(Math.max(0, afterIndex - 1), 0, subject);

        return draft;
      });
    } 
    console.log(masterList)
  }
  
 useEffect(() => {
    
}, []);

  return (
    <div className="App">
      <div className="flex gap-2">
        <List
          className="bg-blue-100"
          reorder={reorder}
          list={masterList}
          name="all"
        />
       
      </div>
    </div>
  );
}

const List = ({ reorder, className, name, list }) => {
  function onDragEnd({ list, dragIndex, dropIndex }) {
    reorder(list[dropIndex], list[dropIndex - 1], list[dropIndex + 1]);
  }

  return (
    <div className={`flex flex-col gap-1 w-full lead_drag ${className}`}>
      
      <Draggable onDragEnd={onDragEnd} list={list} cols={1} height={48}>
        {(segment) => <Segment>{segment}</Segment>}
      </Draggable>
    </div>
  );
};

const Segment = ({ children }) => {
  const colors = [
    "gray",
    "zinc",
    "neutral",
    "stone",
    "red",
    "orange",
    "amber",
    "yellow",
    "lime",
    "green",
    "emerald",
    "teal",
    "cyan",
    "sky",
    "blue",
    "indigo",
    "violet",
    "purple",
    "fuchsia",
    "pink",
    "rose"
  ];

  const id = children
    .split("")
    .reduce((total, char) => (total += char.charCodeAt(0)), 0);

  const color = colors[id % colors.length];

  return (
    <div
      className={`px-4 py-2 rounded shadow scale-100 bg-${color}-500 text-dark font-bold w-100 mx-2`}
    >
      {children}
    </div>
  );
};
