import React,{FC, useEffect, useState} from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { right } from '@popperjs/core';
import { getTeamsList, getUsersByRole } from '../../settings/userManagement/core/_requests';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { useAuth } from '../../../modules/auth';
import { getCountSourceProperty } from '../core/requests';
import { getProjects } from '../../property/core/_requests';

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
    plugins: {
        legend: {
            display: true,
            position: right,
            labels: {
                color: '#000',
                fontSize:'100',
            },
        }
    }
  };

const initialValues = {
    start_date: "",
    end_date: "",
}

const PropertyDoughnut: FC = () => {

    const {currentUser, logout} = useAuth();
    const userId = currentUser?.id;
    const roleId = currentUser?.designation;
    const usersName = currentUser?.first_name;
    const [search, setSearch] = useState("");
    const [propertyPie, setPropertyPie] = useState(1);
    const [userName, setUserName] = useState('');
    const [title, setTitle] = useState('');
    const [reqId, setReqId] = useState(userId);
    const [role, setRole] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState<any[]>([]);
    const [filtered, setFiltered] = useState<any[]>([]); 
    const [projects, setProjects] = useState<any[]>([]); 
    const [users, setUsers] = useState<any[]>([]);
    const [teams, setTeams] = useState<any[]>([]);
    const [filteredTeam, setFilteredTeam] = useState<any[]>([]);
    const [teamId, setTeamId] = useState(0);
    const [list, setList] = useState(1);
    const [dataProperty, setDataProperty] = useState<any>({
      labels: [],
      datasets: [
        {
          label: '# of Votes',
          data: [],
          backgroundColor: [
            '#e27e0c',
            '#ff6700',
            '#208ae6',
            '#03a128',
            '#f37485',
            '#0c4688',
          ],
          borderColor: [
              '#fff',
              '#fff',
              '#fff',
              '#fff',
              '#fff',
              '#fff',
          ],
          borderWidth: 5,
        },
      ],
    });

  
    const CountSourceList =  async (body:any, reqId:any) => {         
      const CountSourceResponse = await getCountSourceProperty(userId, roleId, reqId, body)
      setCount(CountSourceResponse); 
      setTitle('Source'); 
    }
    
    const usersList =  async () => {
        const userId = currentUser?.id;
        const roleId = currentUser?.designation;
      const Response = await getUsersByRole(userId, roleId)
      setUsers(Response);
      console.log('userlist', Response);
    }

    const projectList =  async () => {
      var userId = currentUser?.id;
      var roleId = currentUser?.designation;
      const projectResponse = await getProjects(userId, roleId);
      setProjects(projectResponse); 
      console.log('fdhwiuegferoiugeroiutger', projectResponse)
    }
      // var propertyDropList = [];

      // for(let i = 0; i < projectResponse.length; i++) {
      //     var data = { "id": projectResponse[i].id, "name": projectResponse[i].name_of_building }
      //     propertyDropList.push(data);
      // }

      // setProjectDopList(propertyDropList);
  

    const rangeSelectPie = (val:any) => {
      setPropertyPie(val);
      if(val == 5) {
      document.getElementById('propertyPieCustomDateRange')?.click();
      }
      var body = {
        'start_date': fromDate,
        'end_date': toDate,
        'filter': val,
      }
      CountSourceList(body, reqId)
    
    }

      const taskSaveSchema = Yup.object().shape({
        start_date: Yup.string(),
        end_date: Yup.string(),
    })

      const formik = useFormik({
        initialValues,
        validationSchema: taskSaveSchema ,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
          try {
            setFromDate(values.start_date)
            setToDate(values.end_date)

            var body = {
              'start_date': values.start_date,
              'end_date': values.end_date,
              'filter': propertyPie,
            }

            CountSourceList(body, reqId)

            resetForm();
            setLoading(false)
            document.getElementById('propertyPieModelClose')?.click();
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
    }})

    useEffect(() => {
      const graphXProperty = count.map((obj) => Object.values(obj)[0]);
    const graphYProperty = count.map((obj) => Object.values(obj)[1]);

    const lableProperty: any[] = [];
    for(var p=0;p<graphXProperty.length;p++) {
    var val15 = graphXProperty[p];
    var val16 = graphYProperty[p];
    var value = val16+'-'+val15;
    lableProperty.push(value);
    }

    const dataProperty = {
        labels: lableProperty,
        datasets: [
          {
            label: '# of Votes',
            data: graphXProperty,
            backgroundColor: [
              '#e27e0c',
              '#ff6700',
              '#208ae6',
              '#03a128',
              '#f37485',
              '#0c4688',
            ],
            borderColor: [
                '#fff',
                '#fff',
                '#fff',
                '#fff',
                '#fff',
                '#fff',
            ],
            borderWidth: 5,
          },
        ],
      };
      setDataProperty(dataProperty)
    }, [count]);    

      useEffect(() => {
        projectList();        
        var body = {
          'start_date': fromDate,
          'end_date': toDate,
          'filter': propertyPie,
        }
        CountSourceList(body, reqId);        
        usersList();        
      }, []);

      function filterItem(item:any, search:any) {
        if (search.startsWith("@")) {
          const bucket = search.toLowerCase().substring(1).split(":");
          const searchBy = bucket[0];
          const searchFor = bucket[1];
          const searchByType = getType(item[searchBy]);
      
          if (!searchFor) return false;
          console.log("**** searchBy: ", searchBy);
          console.log("**** searchFor: ", searchFor);
          console.log("**** item: ", item);
          if (searchByType == "string") {
            if (searchFor.startsWith("!")) {
              const bucket = searchFor.split("!");
              const searchForVal = bucket[1];
              return !item[searchBy].includes(searchForVal);
            }
            return item[searchBy].includes(searchFor);
          }
      
          if (searchByType == "array") {
            if (searchFor.startsWith("!")) {
              const bucket = searchFor.split("!");
              const searchForVal = bucket[1];
              return item[searchBy].find((val:any) => !val.includes(searchForVal));
            }
            return item[searchBy].find((val:any) => val.includes(searchFor));
          }
      
          if (searchByType == "object") {
            if (searchFor.startsWith("!")) {
              const bucket = searchFor.split("!");
              const val = bucket[1] || "";
              return !item[searchBy][val];
            }
            if (searchFor.includes("!=")) {
              const bucket2 = searchFor.split("!=");
              const key = bucket2[0];
              const val = bucket2[1] || "";
              return item[searchBy][key] && !item[searchBy][key].includes(val);
            }
            const bucket2 = searchFor.split("=");
            const key = bucket2[0];
            const val = bucket2[1] || "";
            return item[searchBy][key] && item[searchBy][key].includes(val);
          }
        } else {
          if (search.startsWith("!")) {
            const bucket = search.split("!");
            const searchFor = bucket[1];
            return !item.name_of_building.toLowerCase().includes(searchFor);
          }
          return item.name_of_building.toLowerCase().includes(search);
        }
      }
      
      const getType = (value:any) => {
        if (Array.isArray(value)) {
          return "array";
        } else if (typeof value == "string") {
          return "string";
        } else if (value == null) {
          return "null";
        } else if (typeof value == "boolean") {
          return "boolean";
        } else if (Number(value) == value) {
          return "number";
        } else if (typeof value == "object") {
          return "object";
        }
        return "string";
      }; 
    
      useEffect(() => {
        teamsList();
        usersList();        
    
        let filteredData:any = [];
        if (search.length) {
            projects.forEach((item) => {
              if (filterItem(item, search.toLowerCase())) {
                filteredData.push({ ...item });
              }
            });
          
        }
        if(list == 1) {
        setFilteredTeam(filteredData);
        } else {
        setFiltered(filteredData);
        }
    }, [search]);

      const ListChange = (e:any) => {
        setList(e);
        setTeamId(0);
      }

      const teamsList =  async () => {
        var usersId = currentUser?.id;
        var roleId = currentUser?.designation;
        const Response = await getTeamsList(usersId, roleId)
        setTeams(Response);
        console.log('Response', Response)
    }

    const handleTeam = async (id:any, name:any) => {
      setTeamId(id);
      setReqId(id);
      setUserName(name);
      var body = {
        'start_date': fromDate,
        'end_date': toDate,
        'filter': propertyPie,
      }
      CountSourceList(body, id);
      }

    return(
        <>
        <div className="card mx-sm-1 mx-xl-2 bs_1 br_15">
        <div className='modal fade' id='propertyPieCustomDate' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>
                    <form noValidate onSubmit={formik.handleSubmit}>
                    <div className='modal-header py-2'>
                        <h3>Select Custom Range</h3>
                        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' id='propertyPieModelClose'>
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                        </div>
                    </div>
                    <div className='modal-body py-3 px-lg-10 pb-2'>
                        <div className='row'>                                
                        <div className="col-6 mb-3">
                            <label htmlFor="basic-url" className="form-label">Start Date</label>
                            <div className="input-group mb-3">
                                <input type="date" className="form-control" {...formik.getFieldProps('start_date')}/> 
                            </div>
                        </div>
                        <div className="col-6 mb-3">
                            <label htmlFor="basic-url" className="form-label">End Date</label>
                            <div className="input-group mb-3">
                                <input type="date" className="form-control" {...formik.getFieldProps('end_date')}/> 
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className='model-footer'>
                        <div className='d-flex align-items-center justify-content-end pb-3 pe-6 pt-0'>
                        <button
                        type='submit'
                        id='kt_sign_up_submit'
                        className='btn btn-sm btn_primary text-primary'
                        disabled={formik.isSubmitting}
                        >
                        {!loading && <span className='indicator-label'>Filter
                        
                        </span>}
                        {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                        </button>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
        </div>
            <div className="card-heade border-0 d-flex align-items-center justify-content-end pt-5 px-5">
                {/* <h3 className='card-title align-items-start flex-column'>
                    {title}
                </h3> */}
                <div className='d-flex justify-content-end align-items-center flex-wrap'>
                {roleId != 3 && <>
                <input className="form-select dash_btn m-2 fs-9" type="button" value={userName} id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false"/>
                <ul className="dropdown-menu db_dw_menu" aria-labelledby="defaultDropdown">
                {/* <select className="form-select btn btn-sm btn_secondary" onChange={(e) => ListChange(e.target.value)}>
                    <option value="1">Teams</option>
                    <option value="2">Users</option>
                  </select> */}
                    <div className="input-group form_search my-3 dropdown-item">
                      <input type="text" className="form-control" name="search" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)}/>
                      <div className="input-group-append">
                        <button className="btn btn-secondary" type="button">
                        <img src={toAbsoluteUrl('/media/custom/header-icons/search.svg')} className="svg_icon" alt='' />
                        </button>
                      </div>
                    </div>
                    {!search
                      ? projects.map((userData, i) => {
                        return(                            
                        <li className="list-group p-4" key={i}>
                          <div className="">
                            <a type="button" onClick={(e) => handleTeam(userData.id, userData.name_of_building)}>
                                <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                                {userData.name_of_building}
                            </a>
                          </div>                                  
                        </li>
                        )}) 
                      : filteredTeam.length ? filteredTeam.map((item) => (<li  className="list-group p-4">
                      <div className="">
                        <a type="button" onClick={(e) => handleTeam(item.id, item.name_of_building)}>
                          <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                          {item.name_of_building}
                        </a>
                      </div>
                  </li>)) : <p>No Records!!!</p> }
                </ul></>}
                <select className="form-select dash_btn m-2 fs-9" onChange={(e) => rangeSelectPie(e.target.value)}>
                    <option selected value="1">Today</option>
                    <option value="2">Yesterday</option>
                    <option value="3">Last Week</option>
                    <option value="4">Last Month</option>
                    <option value="6">This Month</option>
                    <option value="7">This Year</option>
                    <option value="5">Custom Date</option>
                </select>
                <button type='button' data-bs-toggle='modal' data-bs-target='#propertyPieCustomDate' className='d-none' id='propertyPieCustomDateRange'>Custom Date</button>
                
                </div>
                
            </div>
            <div className="card-body">
            <>
            <Doughnut className='d-block source_graph' options={options} data={dataProperty} />            
            </>
            </div>
        </div>
        </>
    )
}
export {PropertyDoughnut}