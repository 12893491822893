import React,{FC, useEffect, useState} from 'react';
import { Bar } from 'react-chartjs-2';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { DashboardBarChart } from '../dashboard/barChart';
import { getCountStatusTask, getCountTaskType } from './core/_request';


export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
      //   text: 'Chart.js Bar Chart',
      },
    },
    
  };


const TaskLead: FC = () => {

    const [countTaskType, setCountTaskType] = useState<any[]>([]);
    const [countStatusTask, setCountStatusTask] = useState<any[]>([]);
        
    const CountTaskTypeList =  async () => {    
        const CountTaskTypeResponse = await getCountTaskType()
        setCountTaskType(CountTaskTypeResponse);   
    }

    const CountStatusTaskList =  async () => {    
        const CountStatusTaskResponse = await getCountStatusTask()
        setCountStatusTask(CountStatusTaskResponse);   
    }
    
    const countTaskTypeLabels = countTaskType.map((obj) => Object.values(obj)[1]);
    const countTaskTypebar = countTaskType.map((obj) => Object.values(obj)[0]);
    
    const databarcountCity = {
    labels: countTaskTypeLabels,
    datasets: [{
        label: 'Citywise Lead',
        backgroundColor: '#3bb6f1',
        borderColor: 'rgb(255, 99, 132)',
        data: countTaskTypebar,
    }]
    };

    const CountStatusTaskLabels = countStatusTask.map((obj) => Object.values(obj)[1]);
    const CountStatusTaskbar = countStatusTask.map((obj) => Object.values(obj)[0]);
    
    const databarCountPropertyType = {
    labels: CountStatusTaskLabels,
    datasets: [{
        label: 'Count Property Type',
        backgroundColor: '#3bb6f1',
        borderColor: 'rgb(255, 99, 132)',
        data: CountStatusTaskbar,
    }]
    };

    useEffect(() => {
        CountTaskTypeList();
        CountStatusTaskList();
    }, []);

    return(
        <section className='report_lead'>
            <div className="row">
                <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-4 pb-5">
                    <div className="card h-100 bg-light type_card">
                        <div className="card-header">
                            <div className="input-group form_search me-3">
                                <input type="text" className="form-control" placeholder="Search"/>
                                <div className="input-group-append">
                                    <button className="btn btn-secondary" type="button">
                                    <img src={toAbsoluteUrl('/media/custom/header-icons/search.svg')} className="svg_icon" alt='' />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body px-4">
                            <ul className="nav nav-pills masters_tab d-block px-3 border-0" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className='text-dark nav-link active' id="taskstatus-tab" data-bs-toggle="pill" data-bs-target="#taskstatus" type="button" role="tab" aria-controls="taskstatus" aria-selected="true">
                                    {/* <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' /> */}
                                    Task Status Report
                                    </a>                                     
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className='text-dark nav-link' id="tasktype-tab" data-bs-toggle="pill" data-bs-target="#tasktype" type="button" role="tab" aria-controls="tasktype" aria-selected="true">
                                    {/* <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' /> */}
                                    Task Type Report
                                    </a>                                     
                                </li>                                                                                                
                            </ul>                            
                        </div>
                    </div>
                </div>
                <div className="col-xxl-9 col-xl-8 col-lg-8 col-md-8 col-sm-8">                    
                    <div className="card-group">
                    <div className="tab-content w-100" id="pills-tabContent">
                        <div  className="tab-pane fade show active" id="taskstatus" role="tabpanel" aria-labelledby="taskstatus-tab">
                            <div className="row">
                                <div className="col-xxl-6 col-xl-8 col-lg-10 col-md-12 col-sm-12 mb-4">                                
                                    <div className="card h-100 bs_1 mx-3">
                                        <div className="card-header">
                                            <h4>Lead Report</h4>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <div className="d-flex me-3">
                                                    <i className="fa fa-star me-2 text-warning" aria-hidden="true"></i>
                                                    <i className="fa fa-download me-2" aria-hidden="true"></i>
                                                </div>
                                                <select className="form-select dash_btn me-2 mb-1">
                                                    <option selected>Teams</option>
                                                    <option value="1">Brigade Sales Team</option>
                                                    <option value="1">Godrej Sales Team</option>
                                                    <option value="1">Shriram Sales Team</option>
                                                </select>                                                
                                            </div>
                                        </div>
                                        <div className="card-body p-3">
                                        <Bar options={options} data={databarcountCity} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                                                  
                        <div  className="tab-pane fade" id="tasktype" role="tabpanel" aria-labelledby="tasktype-tab">
                            <div className="row">                          
                                <div className="col-xxl-6 col-xl-8 col-lg-10 col-md-12 col-sm-12 mb-4">
                                    <div className="card h-100 bs_1 mx-3">
                                        <div className="card-header">
                                            <h4>City Wise Lead Report</h4>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <div className="d-flex me-3">
                                                    <i className="fa fa-star me-2 text-warning" aria-hidden="true"></i>
                                                    <i className="fa fa-download me-2" aria-hidden="true"></i>
                                                </div>
                                                <select className="form-select dash_btn me-2 mb-1">
                                                    <option selected>Teams</option>
                                                    <option value="1">Brigade Sales Team</option>
                                                    <option value="1">Godrej Sales Team</option>
                                                    <option value="1">Shriram Sales Team</option>
                                                </select>                                                
                                            </div>
                                        </div>
                                        <div className="card-body p-3">
                                        <Bar options={options} data={databarCountPropertyType} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                    </div>                        
                    </div>
                </div>
            </div>
        </section>
    )
}
export{TaskLead}