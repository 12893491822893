import React,{FC, useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {getCity, getAssignTo, getAmenity, getFurnishStatus, getPosesStatus, getLookingFor, getSegment, getPropertyType, getLeadSource, getVasthu, getAgeOfProperty, getLeadGroup, getLeadStatus, getFilteredLead, saveLeadFilter, getLeadsByRole, getLeadFilter, getLeadFilters} from './core/_requests'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { Toast } from 'bootstrap';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { useAuth } from '../../modules/auth';
import Moment from 'moment';
import { getProperties,getProjects } from '../property/core/_requests';
import { getPriority } from '../settings/masters/core/_requests';
import { getAssignToList } from '../contact/core/_requests';

const initialValues = {
    looking_for: '',
    requirement_location: '',
    lead_source: '',
    lead_group: '',
    fee_oppurtunity: '',
    status: '',
    property_type: '',
    property: '',
    priority: '',
    assign_to: [],
    budget_min: '',
    budget_max: '',
    no_of_bedrooms_min: '',
    no_of_bedrooms_max: '',
    no_of_bathrooms_min: '',
    no_of_bathrooms_max: '',
    built_up_area_min: '',
    built_up_area_max: '',
    plot_area_min: '',
    plot_area_max: '',
    possession_status: [],
    age_of_property: '',
    vasthu_compliant:'',
    furnishing:[],
    car_park_min:'',
    car_park_max:'',
    created_date:'',
    filter_name:'',
    timeline_for_closure_min:'',
    timeline_for_closure_max:'',
    amenities:[],
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, aminityName: string[], theme: Theme) {
    return {
        fontWeight:
        aminityName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
    };
}

const mystyle = {
    padding: "0px",
    height: "34px",
    maxHeight: "200px",
    overflowY: "scroll",
    // display: "block"
};

type Props = {
    setLeads?: any
}

const LeadFilter:  FC<Props> = (props) => {

    const {
        setLeads
        } = props

        

    const theme = useTheme(); 

    const leadFilterSchema = Yup.object().shape({
        looking_for: Yup.string(),
        requirement_location: Yup.string(),
        lead_source: Yup.string(),
        lead_group: Yup.string(),
        fee_oppurtunity: Yup.string(),
        status: Yup.string(),
        property_type: Yup.string(),
        property: Yup.string(),
        priority: Yup.string(),
        budget_min: Yup.string(),
        budget_max: Yup.string(),
        no_of_bedrooms_min: Yup.string(),
        no_of_bedrooms_max: Yup.string(),
        no_of_bathrooms_min: Yup.string(),
        no_of_bathrooms_max: Yup.string(),
        built_up_area_min: Yup.string(),
        built_up_area_max: Yup.string(),
        plot_area_min: Yup.string(),
        plot_area_max: Yup.string(),
        age_of_property: Yup.string(),
        vasthu_compliant: Yup.string(),
        car_park_min: Yup.string(),
        car_park_max: Yup.string(),
        timeline_for_closure_min: Yup.string(),
        timeline_for_closure_max: Yup.string(),
        created_date: Yup.string(),
        filter_name: Yup.string(),
    })

    const [lookingFor, setLookingFor] = useState<any[]>([]);
    const [cities, setCities] = useState<any[]>([]);
    const [segment, setSegment] = useState<any[]>([]);
    const [propertyType, setPropertyType] = useState<any[]>([]);
    const [leadSource, setLeadSource] = useState<any[]>([]);
    const [leadGroup, setLeadGroup] = useState<any[]>([]);
    const [leadStatus, setLeadStatus] = useState<any[]>([]);
    const [vasthu, setVasthu] = useState<any[]>([]);
    const [ageOfProp, setAgeOfProp] = useState<any[]>([]);    
    const [save, setSave] = useState(false);
    const [loading, setLoading] = useState(false);
    const [amenitiesId, setAmenitiesId] = React.useState<string[]>([]);
    const [aminityName, setAmenitiesName] = React.useState<string[]>([]);
    const [assignToId, setAssignToId] = React.useState<string[]>([]);
    const [assignToName, setAssignToName] = React.useState<string[]>([]);
    const [furnishId, setFurnishId] = React.useState<string[]>([]);
    const [furnishName, setFurnishName] = React.useState<string[]>([]);
    const [posId, setPosId] = React.useState<string[]>([]);
    const [posName, setPosName] = React.useState<string[]>([]);
    const [assignTo, setAssignTo] = useState<any[]>([]);
    const [amenities, setAmenities] = useState<any[]>([]);
    const [furnishStatus, setFurnishStatus] = useState<any[]>([]);
    const [posesStatus, setPosesStatus] = useState<any[]>([]);
    const [leadFilter, setLeadFilter] = useState<any[]>([]);
    const [filterDetail, setFilterDetail] = useState<any[]>([]);
    const [property, setProperty] = useState<any[]>([]);
    const [priority, setPriority] = useState<any[]>([]);
    const {currentUser, logout} = useAuth();

    var roleId = currentUser?.designation;
    var userId = currentUser?.id;


    const lookingForList = async () => {
        // setLookingFor
        const lookingResponse = await getLookingFor();
        setLookingFor(lookingResponse);
    } 

    const citiesList =  async () => {   
        const cityResponse = await getCity();
        setCities(cityResponse);
    }

    const ageOfPropList =  async () => {   
        const response = await getAgeOfProperty();
        setAgeOfProp(response);
    }

    const segmentList = async () => {
        const segmentResponse = await getSegment();
        setSegment(segmentResponse);
    } 

    const propertyTypeList = async () => {
        const propertyResponse = await getPropertyType();
        setPropertyType(propertyResponse);
    } 

    const leadSourceList = async () => {
        const sourceResponse = await getLeadSource();
        setLeadSource(sourceResponse);
    } 

    const leadGroupList = async () => {
        const groupResponse = await getLeadGroup();
        setLeadGroup(groupResponse);
    } 

    const leadStatusList = async () => {
        const statusResponse = await getLeadStatus();
        setLeadStatus(statusResponse);

        console.log('leads status');
        console.log(statusResponse);
    } 

    const assignToList =  async () => {   
        var userId = currentUser?.id;
        var roleId = currentUser?.designation; 
        const assignToResponse = await getAssignToList(userId, roleId);
        setAssignTo(assignToResponse);
    }

    const amenitiesList =  async () => {   
        const amenityResponse = await getAmenity();
        setAmenities(amenityResponse);
    }

    const furnishStatusList =  async () => {   
        const furnishResponse = await getFurnishStatus();
        setFurnishStatus(furnishResponse);
    }

    const posesStatusList =  async () => {   
        const posessionResponse = await getPosesStatus();
        setPosesStatus(posessionResponse);
    }

    const vasthuList =  async () => {   
        const vasthuResponse = await getVasthu();
        setVasthu(vasthuResponse);
    }

    const LeadFilterList =  async () => {  
        var userId = currentUser?.id;
        var roleId = currentUser?.designation; 
        const Response = await getLeadFilters(userId);
        setLeadFilter(Response);
        console.log('Response')
        console.log(Response)
    }
    // const LeadFilterList =  async () => {  
    //     var userId = currentUser?.id;
    //     var roleId = currentUser?.designation; 
    //     const Response = await getLeadFilter(userId);
    //     setLeadFilter(Response);
    // }

    const filterTap =  async (Id : any) => {
        // contactId : number
        setLoading(true);
        const fetchDetails = await getLeadFilter(Id)
        console.log('fetchDetails');    
        console.log(fetchDetails);
        setFilterDetail(fetchDetails)          
        formik.setFieldValue('looking_for', fetchDetails.looking_for  ?? '')
        formik.setFieldValue('lead_source', fetchDetails.lead_source  ?? '')
        formik.setFieldValue('lead_group', fetchDetails.lead_group  ?? '')
        formik.setFieldValue('status', fetchDetails.status  ?? '')
        formik.setFieldValue('property_type', fetchDetails.property_type  ?? '')
        formik.setFieldValue('property', fetchDetails.property  ?? '')
        formik.setFieldValue('priority', fetchDetails.priority  ?? '')
        formik.setFieldValue('requirement_location', fetchDetails.requirement_location  ?? '')
        formik.setFieldValue('budget_min', fetchDetails.budget_min  ?? '')
        formik.setFieldValue('budget_max', fetchDetails.budget_max  ?? '')
        formik.setFieldValue('no_of_bedrooms_min', fetchDetails.no_of_bedrooms_min  ?? '')
        formik.setFieldValue('no_of_bedrooms_max', fetchDetails.no_of_bedrooms_max  ?? '')
        formik.setFieldValue('no_of_bathrooms_min', fetchDetails.no_of_bathrooms_min  ?? '')
        formik.setFieldValue('no_of_bathrooms_max', fetchDetails.no_of_bathrooms_max  ?? '')
        formik.setFieldValue('built_up_area_min', fetchDetails.built_up_area_min  ?? '')
        formik.setFieldValue('built_up_area_max', fetchDetails.built_up_area_max  ?? '')
        formik.setFieldValue('plot_area_min', fetchDetails.plot_area_min  ?? '')
        formik.setFieldValue('plot_area_max', fetchDetails.plot_area_max  ?? '')
        formik.setFieldValue('age_of_property', fetchDetails.age_of_property  ?? '')
        formik.setFieldValue('vasthu_compliant', fetchDetails.vasthu_compliant  ?? '')
        formik.setFieldValue('car_park_min', fetchDetails.car_park_min  ?? '')
        formik.setFieldValue('car_park_max', fetchDetails.car_park_max  ?? '')
        formik.setFieldValue('timeline_for_closure_min', fetchDetails.timeline_for_closure_min  ?? '')
        formik.setFieldValue('timeline_for_closure_max', fetchDetails.timeline_for_closure_max  ?? '')
        formik.setFieldValue('created_date', Moment(fetchDetails.created_date  ?? '').format("YYYY-MM-DD"))
        formik.setFieldValue('fee_oppurtunity', fetchDetails.fee_oppurtunity  ?? '')
        

        var assignArray = [];
        var assignNameArray = [];
        if(fetchDetails.assign_to != null){
            assignArray = fetchDetails.assign_to.split(",").map((e:any) => {
                return e;
            });
        }
        if(fetchDetails.assign_to_name != null){
            assignNameArray = fetchDetails.assign_to_name.split(",").map((e:any) => {
                return e;
            });
        }

        setAssignToId(assignArray)
        setAssignToName(assignNameArray)

        var possArray = [];
        var possNameArray = [];
        if(fetchDetails.possession_status != null){
            possArray = fetchDetails.possession_status.split(",").map((e:any) => {
                return e;
            });
        }
        if(fetchDetails.posession_name != null){
            possNameArray = fetchDetails.posession_name.split(",").map((e:any) => {
                return e;
            });
        }

        setPosId(possArray)
        setPosName(possNameArray)

        var furnishArray = [];
        var furnishNameArray = [];
        if(fetchDetails.assign_to != null){
            furnishArray = fetchDetails.assign_to.split(",").map((e:any) => {
                return e;
            });
        }
        if(fetchDetails.furnishing_name != null){
            furnishNameArray = fetchDetails.furnishing_name.split(",").map((e:any) => {
                return e;
            });
        }

        setFurnishId(furnishArray)
        setFurnishName(furnishNameArray)

        var amenitiesArray = [];
        var amenitiesNameArray = [];
        if(fetchDetails.assign_to != null){
            amenitiesArray = fetchDetails.assign_to.split(",").map((e:any) => {
                return e;
            });
        }
        if(fetchDetails.amenities_name != null){
            amenitiesNameArray = fetchDetails.amenities_name.split(",").map((e:any) => {
                return e;
            });
        }

        setAmenitiesId(amenitiesArray)
        setAmenitiesName(amenitiesNameArray)
        setLoading(false);
    }

    const handleChange = (event: SelectChangeEvent<typeof aminityName>) => {
        const {
          target: { value },
        } = event;

        var name = [];
        var id = [];

        for(let i = 0; i < value.length; i++){
        var fields = value[i].split('-');

        var n = fields[0];
        var d = fields[1];

        name.push(n);
        id.push(d);
        }

        console.log(name);
        console.log(id);

        setAmenitiesId(id);

        setAmenitiesName(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
    };

    const assingToChange = (event: SelectChangeEvent<typeof assignToName>) => {
        const {
          target: { value },
        } = event;
        var name = [];
        var id = [];

        for(let i = 0; i < value.length; i++){
        var fields = value[i].split('-');

        var n = fields[0];
        var d = fields[1];

        name.push(n);
        id.push(d);
        }

        console.log(name);
        console.log(id);

        setAssignToId(id);

        setAssignToName(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
    };
    
      const furnishingChange = (event: SelectChangeEvent<typeof furnishName>) => {
        const {
          target: { value },
        } = event;

        var name = [];
        var id = [];

        for(let i = 0; i < value.length; i++){
        var fields = value[i].split('-');

        var n = fields[0];
        var d = fields[1];

        name.push(n);
        id.push(d);
        }

        console.log(name);
        console.log(id);

        setFurnishId(id);

        setFurnishName(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
    };
    
      const possessionChange = (event: SelectChangeEvent<typeof posName>) => {
        const {
          target: { value },
        } = event;

        var name = [];
        var id = [];
    
        for(let i = 0; i < value.length; i++){
          var fields = value[i].split('-');
    
          var n = fields[0];
          var d = fields[1];
    
          name.push(n);
          id.push(d);
        }
    
        console.log(name);
        console.log(id);
    
        setPosId(id);

        setPosName(
          typeof value === 'string' ? value.split(',') : value,
        );
    };

    const formik = useFormik({
        initialValues,
        validationSchema: leadFilterSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        try {
            

        console.log('sddddddddassdsf');

        const body = {
            "looking_for": values.looking_for,
            "lead_source": values.lead_source,
            "lead_group": values.lead_group,
            "fee_oppurtunity": values.fee_oppurtunity,
            "status": values.status,
            "assign_to": assignToId,
            "requirement_location": values.requirement_location,
            "budget_min": values.budget_min,
            "budget_max": values.budget_max,
            "no_of_bedrooms_min": values.no_of_bedrooms_min,
            "no_of_bedrooms_max": values.no_of_bedrooms_max,
            "no_of_bathrooms_min": values.no_of_bathrooms_min,
            "no_of_bathrooms_max": values.no_of_bathrooms_max,
            "built_up_area_min": values.built_up_area_min,
            "built_up_area_max": values.built_up_area_max,
            "plot_area_min": values.plot_area_min,
            "plot_area_max": values.plot_area_max,
            "possession_status": posId,
            "age_of_property": values.age_of_property,
            "vasthu_compliant": values.vasthu_compliant,
            "property": values.property,
            "priority": values.priority,
            "property_type": values.property_type,
            "furnishing": furnishId,
            "car_park_min": values.car_park_min,
            "car_park_max": values.car_park_max,
            "timeline_for_closure_min": values.timeline_for_closure_min,
            "timeline_for_closure_max": values.timeline_for_closure_max,
            "amenities": amenitiesId,
            "created_date" : values.created_date,
            "filter_name" : values.filter_name,
            "created_by" : userId,
        }

        const headers = {
            headers: {
                "Content-type": "application/json",
            },                    
          }

          if(save) {

        const filterLeadData = await saveLeadFilter(body);

        console.log('updateLeadData');
        if(filterLeadData != null){
            setLoading(false);
            document.getElementById('kt_filter_close')?.click();
            var toastEl = document.getElementById('myToastAdd');
            const bsToast = new Toast(toastEl!);
            bsToast.show();
            LeadFilterList();
            resetForm();
            setAmenitiesName([]);
            setAmenitiesName([]);
            setAssignToName([]);
            setFurnishName([]);
            setPosName([]);
        }
    } else {
        const filterLeadData = await getFilteredLead(userId, roleId, body, headers);

        if(filterLeadData != null){
            setLoading(false);
            document.getElementById('kt_filter_close')?.click();
            var toastEl = document.getElementById('myToastAdd');
            const bsToast = new Toast(toastEl!);
            bsToast.show();
            setLeads(filterLeadData)
            resetForm();
            setAmenitiesName([]);
            setAssignToName([]);
            setFurnishName([]);
            setPosName([]);
        }
    }
        } catch (error) {
        console.error(error)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
        }
    }})

    const resetFilter = async () => {
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;

        console.log('sdasdsd')
        formik.resetForm();
        setAmenitiesName([]);
        setAssignToName([]);
        setFurnishName([]);
        setPosName([]);
        document.getElementById('kt_filter_close')?.click();
        var toastEl = document.getElementById('myToastAdd');
        const bsToast = new Toast(toastEl!);
        bsToast.show();
        const Response = await getLeadsByRole(userId, roleId);
        setLeads(Response)        
    }

    const propertyList =  async () => {   
        const Response = await getProperties(userId, roleId)
        setProperty(Response);
    }

    const PriorityList = async () => {
        const Response = await getPriority();
        setPriority(Response);
    }

    useEffect(() => {
        lookingForList();
        PriorityList();
        propertyList();
        segmentList();
        propertyTypeList();
        leadSourceList();
        leadGroupList();
        leadStatusList();
        citiesList();
        ageOfPropList();
        vasthuList();
        assignToList();
        amenitiesList();
        furnishStatusList();
        posesStatusList();
        LeadFilterList();
    }, []);

    return(
        <div className='card shadow-none rounded-0 w-100'>
            
                <div className='card-header w-100 d-flex align-items-center justify-content-between' id='kt_filter_header'>
                    <h3 className='card-title fw-bolder text-dark'>Lead Filter</h3>
                    <div className='card-toolbar'>
                        <button
                        type='button'
                        className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                        id='kt_filter_close'
                        >
                            <img src={toAbsoluteUrl('/media/custom/cancel_black.svg')} className="svg_icon" alt='' />
                        </button>
                    </div>
                </div>
                <div className='card-body position-relative' id='kt_filter_body'>                    
                    <form noValidate onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Looking For</label>
                            <div className="input-group mb-3">
                                <select 
                                    {...formik.getFieldProps('looking_for')}
                                    className={clsx(
                                    'btn btn-sm w-100 text-start form-select',
                                    {
                                        'is-invalid': formik.touched.looking_for && formik.errors.looking_for,
                                    },
                                    {
                                        'is-valid': formik.touched.looking_for && !formik.errors.looking_for,
                                    }
                                    )}
                                    name="looking_for"
                                    >
                                        <option value=''>Looking For</option>
                                    {lookingFor.map((lookingForVal,i) =>{
                                        return (
                                            <option value={lookingForVal.id} key={i}>{lookingForVal.name}</option> 
                                    )})}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Location</label>
                            <div className="input-group mb-3">
                                <select className="btn btn-sm w-100 text-start form-select" {...formik.getFieldProps('requirement_location')} >
                                <option value=''>Location</option>
                                    {cities.map((cityVal,i) =>{
                                        return (
                                            <option value={cityVal.id} key={i}>{cityVal.name}</option> 
                                    )})}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Lead Source</label>
                            <div className="input-group mb-3">
                                <select className="btn btn-sm w-100 text-start form-select" {...formik.getFieldProps('lead_source')}>
                                <option value=''>Lead Source</option>
                                    {leadSource.map((sourceVal,i) =>{
                                        return (
                                            <option value={sourceVal.id} selected={i == 0 ? true: false} key={i}>{sourceVal.name}</option> 
                                    )})}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Lead Group</label>
                            <div className="input-group mb-3">
                                <select className="btn btn-sm w-100 text-start form-select" {...formik.getFieldProps('lead_group')}>
                                <option value=''>Lead Group</option>
                                    {leadGroup.map((groupVal,i) =>{
                                        return (
                                            <option value={groupVal.id} selected={i == 0 ? true: false} key={i}>{groupVal.name}</option> 
                                    )})}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label className="form-label">Opportunity Value</label>
                            <div className="input-group mb-3 input_prepend">
                                <select className="px-2 py-2 btn_secondary bg_secondary btn btn-sm prepend">
                                    <option selected value="0">₹</option>
                                    <option value="1">$</option>
                                </select>
                                <input type="text" {...formik.getFieldProps('fee_oppurtunity')} name="fee_oppurtunity" className="form-control" placeholder="0"/>
                            </div>
                        </div> 
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Status</label>
                            <div className="input-group mb-3">
                            <select 
                            {...formik.getFieldProps('status')}
                            className="btn btn-sm w-100 text-start form-select">
                                <option value=''>Status</option>
                                {leadStatus.map((statusVal,i) =>{
                                    return (
                                        <option value={statusVal.id} selected={i == 0 ? true: false} key={i}>{statusVal.name}</option> 
                                )})}
                            </select>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3 edit_page_form">
                            <label htmlFor="basic-url" className="form-label">Assign To</label>
                            <FormControl sx={{ m: 0, width: '100%', mt: 0 }}>
                                <Select
                                    multiple
                                    displayEmpty
                                    value={assignToName}
                                    onChange={assingToChange}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => {
                                        var name = [];
                                        var id = [];

                                        for(let i = 0; i < selected.length; i++){
                                            var fields = selected[i].split('-');

                                            var n = fields[0];
                                            var d = fields[1];

                                            name.push(n);
                                            id.push(d);
                                        }

                                        if (selected.length === 0) {
                                        return <p>Assign To</p>;
                                        }

                                        return name.join(', ');
                                    }}
                                    className='multi_select_field form-control'
                                    MenuProps={MenuProps}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                    <MenuItem disabled value="">
                                        <em>Assign To</em>
                                    </MenuItem>
                                    {assignTo.map((assignVal) => (
                                        <MenuItem
                                        key={assignVal.user_id}
                                        value={assignVal.employee_name+'-'+assignVal.user_id}
                                        style={getStyles(assignVal.employee_name, assignToName, theme)}
                                        >
                                        {assignVal.employee_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div> 
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Bedroom</label>
                            <div className="row">
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="text" className="form-control" {...formik.getFieldProps('no_of_bedrooms_min')} placeholder="Min"/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="text" className="form-control" {...formik.getFieldProps('no_of_bedrooms_max')} placeholder="Max"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Bathroom</label>
                            <div className="row">
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="text" className="form-control" {...formik.getFieldProps('no_of_bathrooms_min')} placeholder="Min"/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="text" className="form-control" {...formik.getFieldProps('no_of_bathrooms_max')} placeholder="Max"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Price Range</label>
                            <div className="row">
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="text" className="form-control" {...formik.getFieldProps('budget_min')} placeholder="Min"/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="text" className="form-control" {...formik.getFieldProps('budget_max')} placeholder="Max"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Builtup Area Range</label>
                            <div className="row">
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="text" className="form-control" {...formik.getFieldProps('built_up_area_min')} placeholder="Min"/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="text" className="form-control" {...formik.getFieldProps('built_up_area_max')} placeholder="Max"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Plot Area Range</label>
                            <div className="row">
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="text" className="form-control" {...formik.getFieldProps('plot_area_min')} placeholder="Min"/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="text" className="form-control" {...formik.getFieldProps('plot_area_max')} placeholder="Max"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-6 mb-3">
                            <label htmlFor="basic-url" className="form-label">No. of Car Park</label>
                            <div className="row">
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="number" min="0" {...formik.getFieldProps('car_park_min')} name="car_park_min" className="form-control" placeholder="Min"/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="number" min="0" {...formik.getFieldProps('car_park_max')} name="car_park_max" className="form-control" placeholder="Max"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label className="form-label">Property Type</label>
                            <div className="input-group mb-3">
                                <select 
                                {...formik.getFieldProps('property_type')}
                                className="btn btn-sm w-100 text-start form-select">
                                    <option value=''>Property Type</option>
                                    {propertyType.map((propertyVal,i) =>{
                                        return (
                                            <option value={propertyVal.id} selected={i == 0 ? true: false} key={i}>{propertyVal.name}</option> 
                                    )})}
                                </select>
                            </div>  
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label className="form-label">Lead Priority</label>
                            <div className="input-group mb-3">
                                <select 
                                {...formik.getFieldProps('priority')}
                                className="btn btn-sm w-100 text-start form-select">
                                    <option value=''>select</option>                                            
                                    {priority.map((priorityVal,i) =>{
                                        return (
                                            <option value={priorityVal.id} selected={i == 0 ? true: false} key={i}>{priorityVal.name}</option> 
                                    )})}                                            
                                </select>
                            </div>  
                        </div>
                        <div className="col-md-6 col-6 mb-3">
                            <label htmlFor="basic-url" className="form-label">Timeline for closure</label>
                            <div className="row">
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="number" min="0" {...formik.getFieldProps('timeline_for_closure_min')} name="timeline_for_closure_min" className="form-control" placeholder="Min"/>
                                        <span className="input-group-text">Months</span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="number" min="0" {...formik.getFieldProps('timeline_for_closure_max')} name="timeline_for_closure_max" className="form-control" placeholder="Max"/>
                                        <span className="input-group-text">Months</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label className="form-label">Age of Property</label>
                            <div className="input-group mb-3">
                                <select 
                                {...formik.getFieldProps('age_of_property')} 
                                className="btn btn-sm w-100 text-start form-select">
                                    <option value=''>Age of Property</option>
                                    {ageOfProp.map((ageOfPropVal,i) =>{
                                        return (
                                            <option value={ageOfPropVal.id} selected={i == 0 ? true: false} key={i}>{ageOfPropVal.name}</option> 
                                    )})}
                                </select>
                            </div> 
                        </div>
                            <div className="col-md-6 col-12 mb-3">
                                <label htmlFor="basic-url" className="form-label">Property</label>
                                <div className="input-group mb-3">
                                <select className="form-select btn btn-sm w-100" 
                                {...formik.getFieldProps('property')}
                                >
                                    <option value=''>Property</option>
                                    {property.map((value,i)=> {
                                    return (
                                        <>
                                    {value.name_of_building && 
                                    <option selected={i == 0 ? true: false} value={value.id} key={i}>{value.name_of_building}</option>}
                                    </>
                                    )
                                    })}   
                                </select>
                                </div>
                            </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label className="form-label">Vasthu / Feng Sui Compliant</label>
                            <div className="input-group mb-3">
                                <select 
                                {...formik.getFieldProps('vasthu_compliant')} 
                                className="btn btn-sm w-100 text-start form-select">
                                    <option value=''>Vasthu Complient</option>
                                    {vasthu.map((vasthuVal,i) =>{
                                        return (
                                            <option value={vasthuVal.id} selected={i == 0 ? true: false} key={i}>{vasthuVal.name}</option> 
                                    )})}
                                </select>
                            </div> 
                        </div>
                        <div className="col-md-6 col-12 mb-3 edit_page_form">
                            <label htmlFor="basic-url" className="form-label">Amenities</label>
                            <FormControl sx={{ m: 0, width: '100%', mt: 0 }}>
                                <Select
                                    multiple
                                    displayEmpty
                                    value={aminityName}
                                    onChange={handleChange}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => {
                                        var name = [];
                                        var id = [];

                                        for(let i = 0; i < selected.length; i++){
                                            var fields = selected[i].split('-');

                                            var n = fields[0];
                                            var d = fields[1];

                                            name.push(n);
                                            id.push(d);
                                        }

                                        if (selected.length === 0) {
                                            return <p>Amenities</p>;
                                        }

                                        return name.join(', ');
                                    }}
                                    MenuProps={MenuProps}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                    <MenuItem disabled value="">
                                        <em>Amenities</em>
                                    </MenuItem>
                                    {amenities.map((amenity) => (
                                        <MenuItem
                                        key={amenity.id}
                                        value={amenity.name +'-'+ amenity.id}
                                        style={getStyles(amenity.name, aminityName, theme)}
                                        >
                                        {amenity.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-6 col-12 mb-3 edit_page_form">
                            <label className="form-label">Furnishing Status</label>
                            <FormControl sx={{ m: 0, width: '100%', mt: 0 }}>
                                <Select
                                    multiple
                                    displayEmpty
                                    value={furnishName}
                                    onChange={furnishingChange}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => {
                                        var name = [];
                                        var id = [];

                                        for(let i = 0; i < selected.length; i++){
                                            var fields = selected[i].split('-');

                                            var n = fields[0];
                                            var d = fields[1];

                                            name.push(n);
                                            id.push(d);
                                        }

                                        if (selected.length === 0) {
                                        return <p>Furnishing</p>;
                                        }

                                        return name.join(', ');
                                    }}
                                    className='multi_select_field'
                                    MenuProps={MenuProps}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                    <MenuItem disabled value="">
                                        <em>Furnishing</em>
                                    </MenuItem>
                                    {furnishStatus.map((furnish) => (
                                        <MenuItem
                                        key={furnish.id}
                                        value={furnish.name+'-'+furnish.id}
                                        style={getStyles(furnish.name, furnishName, theme)}
                                        >
                                        {furnish.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            
                        </div> 
                        <div className="col-md-6 col-12 mb-3 edit_page_form">
                            <label className="form-label">Posession Status</label>
                            <FormControl sx={{ m: 0, width: '100%', mt: 0 }}>
                                <Select
                                    multiple
                                    displayEmpty
                                    value={posName}
                                    onChange={possessionChange}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => {
                                        var name = [];
                                        var id = [];

                                        for(let i = 0; i < selected.length; i++){
                                            var fields = selected[i].split('-');

                                            var n = fields[0];
                                            var d = fields[1];

                                            name.push(n);
                                            id.push(d);
                                        }

                                        if (selected.length === 0) {
                                        return <p>Posession</p>;
                                        }

                                        return name.join(', ');
                                    }}
                                    className='multi_select_field'
                                    MenuProps={MenuProps}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                    <MenuItem disabled value="">
                                        <em>Posession</em>
                                    </MenuItem>
                                    {posesStatus != null && posesStatus.map((posession) => (
                                    <MenuItem
                                    key={posession.id}
                                    value={posession.name+ '-' +posession.id}
                                    style={getStyles(posession.name, posName, theme)}
                                    >
                                    {posession.name}
                                    </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Created Date</label>
                            <div className="input-group first mb-3 input_prepend">
                                <input type="date" className="form-control" {...formik.getFieldProps('created_date')} placeholder="Min"/>
                            </div>                                
                        </div>
                        </div>
                <div className='card-footer py-5 text-center filter_footer d-flex flex-column flex-md-row align-items-center justify-content-between'>
                    <div className="mb-3 mb-md-0">
                        <div className="form-check d-flex">
                            <input className="form-check-input" type="checkbox" value="" id="filterCheck"/>
                            <label className="form-check-label mx-3" htmlFor="filterCheck">
                                Include Archived Records
                            </label>
                        </div>
                    </div>
                    <div className="mb-3 mb-md-0">
                        <button data-bs-toggle='modal' data-bs-target={'#lead_filter_save_popup'} className='btn btn-sm btn_soft_primary save_btn p-3 pr-0 mx-3 br_10' type='button' title="Save">
                            <KTSVG
                                path='/media/custom/save_orange.svg'
                                className='svg-icon-3 svg-icon-primary me-0'
                            />
                        </button>
                        <button type='button' className='btn btn-sm reset_btn mx-3 br_10' onClick={resetFilter}>
                            <i className="fas fa-undo"></i>
                            Reset
                        </button>
                        <button className='btn btn-sm btn_primary mx-3 br_10' onClick={(e) => setSave(false)}>
                            <KTSVG path='/media/custom/search_white.svg' className='svg-icon-5 svg-icon-gray-500 me-1'/>
                            Search
                        </button>
                    </div>
                    <div className='modal fade' id={'lead_filter_save_popup'} aria-hidden='true'>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className='modal-content'>
                                <div className='modal-header'>
                                    <h3>Want to Save Filter?</h3>
                                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                    </div>
                                </div>
                                <div className='modal-body py-lg-10 px-lg-10'>
                                    <div className="mb-3 text-start">
                                        <label htmlFor="basic-url" className="form-label">Filter Name</label>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" {...formik.getFieldProps('filter_name')} placeholder=""/> 
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-end'>
                                        <button type='button' className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal'>
                                            No
                                        </button>
                                        <button type='submit' className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal'  onClick={(e) => setSave(true)}>
                                            Yes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </form>
                {leadFilter.length > 0
            ? <div className='row'>
                <div className='col-12'>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Saved Filters
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <div className='row'>
                                {leadFilter.map((Data, i) => {
                                return(
                                    <div className='col-xxl-4'>
                                        <div onClick={(e) => filterTap(Data.id)} className="d-flex cursor_pointer align-items-center bg_soft rounded py-5 px-4 mb-7">
                                            <div className="flex-grow-1">
                                                <a href="#" className="fw-bold text-gray-800 text-hover-primary fs-6">{Data.filter_name}</a>
                                                <span className="text-muted fw-semibold d-block">{Moment(Data.created_at).format("DD-MM-YYYY HH:mm")}</span>
                                            </div>
                                        </div>
                                    </div>)})}
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div> : <></>}            
        </div>            
    </div>
    )
}


export {LeadFilter}