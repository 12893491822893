import React,{FC} from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const Sms: FC = () => {
    return(
    <div className="tab-pane" id="pills-personal" role="tabpanel" aria-labelledby="pills-personal-tab">
        <form action="">
            <div className="row">
                <div className="col-lg-12 col-xxl-12">
                    <div className="card bs_1">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-md-4">                                            
                                            <div className="form-group mb-4">
                                                <label htmlFor="basic-url" className="form-label">Text Local</label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" placeholder="API Key"/> 
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">                                        
                                            <div className="form-group mb-4">
                                                <label htmlFor="basic-url" className="form-label">Twillo</label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" placeholder="API Key"/> 
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-4">
                                                <label htmlFor="basic-url" className="form-label">Watsapp</label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" placeholder="API Key"/> 
                                                </div>
                                            </div>
                                        </div>                                        
                                    </div>
                                    <div className='text-end mt-4'>
                                        <button className='btn btn_primary rounded'>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    )
}

export {Sms}