import React,{FC, useEffect, useState} from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { getTeams, deleteTeam, getTeamsList } from './core/_requests';
import { EditTeam } from './editTeam';
import { Offcanvas, Toast } from 'bootstrap';
import { useAuth } from '../../../modules/auth';

const TeamsList: FC = () => {

    const [teams, setTeams] = useState<any[]>([]);
    const [detailData, setDetailData] = useState('');
    const {currentUser, logout} = useAuth(); 
    const [detailClicked, setDetailClick] = useState(false);
    // const memb:any;

    const teamsList =  async () => {
        var usersId = currentUser?.id;
        var roleId = currentUser?.designation;
        const Response = await getTeamsList(usersId, roleId)
        setTeams(Response);
    }

    const openModal = (Id:any) => {
        setDetailClick(true);
        setDetailData(Id);
        document.body.className += ' detail_opened';
        var offCanvasEl = document.getElementById('kt_expand'+Id);
        offCanvasEl?.classList.remove('invisible');
        const bsOffcanvas = new Offcanvas(offCanvasEl!);
        bsOffcanvas.show();
    }

    const onDelete = async (id:any) => {
        const Response = await deleteTeam(id)
        if(Response != null) {
            var toastEl = document.getElementById('myToastUpdate');
            const bsToast = new Toast(toastEl!);
            bsToast.show();
            const Response = await getTeams()
            setTeams(Response);
        }        
    }

    useEffect(() => {
        teamsList();
    }, []);

    return(
        <>
        <button className='d-none' id='teamListReload' onClick={teamsList}></button>
        <div className="row">
            <div className="card-group">
            {teams.map((teamData, i) => {
            return(
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-6 p-2" key={i}>
                    <div className="card h-100 user_card bs_2">
                        <div className="card-header px-0 d-flex justify-content-between align-items-center">
                        
                        {
                                detailClicked && detailData == teamData.id &&
                            <div
                                id={'kt_teamEdit_toggle'+teamData.id}
                                className='expand_area detail_page_view offcanvas offcanvas-end justify-content-end w-100 bg-transparent d-flex p-6' data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample"
                            >
                                <EditTeam currentTeamId={teamData.id} setDetailClicked={setDetailClick}/>
                            </div>
                        } 
                            <div>
                                <p className="mb-0"><span>ID:</span> {teamData.id}</p>
                            </div>
                            <div className="d-flex">
                                {/* <button className="btn btn-sm p-1"
                                onClick={() => openModal(teamData.id)}
                                >
                                    <img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></button> */}
                                <div className="btn-group">
                                    {/* <button className="btn p-1 btn-sm" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                        <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/>
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                        <li><button className="dropdown-item" onClick={() => openModal(teamData.id)}>Edit</button></li>
                                                                                            
                                        <li><button data-bs-toggle='modal' data-bs-target={'#delete_confirm_popup123123'+teamData.id} className="dropdown-item">Delete</button>
                                        </li>
                                    </ul> */}
                                    <div className='modal fade' id={'delete_confirm_popup123123'+teamData.id} aria-hidden='true'>
                                        <div className='modal-dialog modal-dialog-centered'>
                                            <div className='modal-content'>
                                                <div className='modal-header'>
                                                    <h3>Confirmation</h3>
                                                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                                    </div>
                                                </div>
                                                <div className='modal-body py-lg-10 px-lg-10'>
                                                    <p>Are you sure want to delete?</p>
                                                    <div className='d-flex align-items-center justify-content-end'>
                                                        <button className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal'>
                                                            No
                                                        </button>
                                                        <button className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal' 
                                                        onClick={(e) => onDelete(teamData.id)}
                                                        >
                                                            Yes
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="d-flex flex-center">
                            <div className='symbol symbol-75px symbol-circle'>
                                <span className='symbol-label bg_soft text_primary fs-1 fw-bolder'>
                                {teamData.team_leader_name.split(" ")[0][0]}{teamData.team_leader_name.split(" ")[1] == undefined ? '' : teamData.team_leader_name.split(" ")[1][0]}{teamData.team_leader_name.split(" ")[2] == undefined ? '' : teamData.team_leader_name.split(" ")[2][0]}
                                </span>
                            </div>
                            </div>
                            <div className="text-center mt-4">
                                <div className="d-flex justify-content-center mb-2 text-center">
                                    {/* <img src={toAbsoluteUrl('/media/custom/user.svg')} alt="" className="icon me-2" /> */}
                                    <p className="mb-0 contact_name">{teamData.team_leader_name}</p>
                                </div>
                            </div>
                            <div>
                                <div className="row">
                                    <div className="col-12 h-150px scroll-y">
                                        {/* <div className="d-flex align-items-start single_item mb-3">
                                            <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="svg_icon me-2"/>     
                                            <div className="d-flex flex-column">
                                                <p className="mb-0 fw-500">{teamData.tl_name}</p>
                                            </div>
                                        </div> */}
                                        {teamData.assign_to_name &&
                                         teamData.assign_to_name.split(',').map((members: { [x: string]: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }, i: any) => {
                                            return(
                                        <div className="d-flex align-items-start single_item mb-3 " key={i}>
                                            <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="svg_icon me-2"/>
                                            <div className="d-flex flex-column">
                                                <p className="mb-0 fw-500">{members ?? ''}</p>
                                            </div>
                                        </div>)} )}                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )})}
                
            </div>
            <div aria-atomic="true" aria-live="assertive" className="toast bg_primary p-0 text-white position-fixed end-0 bottom-0" id="myToastAdd">
                <div className="toast-header">
                    <strong className="me-auto">Success</strong>
                    <button aria-label="Close" className="btn-close btn-close-color-white" 
                                data-bs-dismiss="toast" type="button">
                    </button> 
                </div>
                <div className="toast-body">
                    <div>Team Created Successfully!</div>
                </div>
            </div>
            <div aria-atomic="true" aria-live="assertive" className="toast bg_primary p-0 text-white position-fixed end-0 bottom-0" id="myToastUpdate">
                <div className="toast-header">
                    <strong className="me-auto">Success</strong>
                    <button aria-label="Close" className="btn-close btn-close-color-white" 
                                data-bs-dismiss="toast" type="button">
                    </button> 
                </div>
                <div className="toast-body">
                    <div>Team Updated Successfully!</div>
                </div>
            </div>
        </div>
        </>
    )
}
export {TeamsList}