import React,{FC, useState,useEffect} from 'react'
import {getTasks, getTaskStatus, getTaskPriority, getTasksByRole, updateTaskStatus, updateTaskPriority, deleteTask, saveTaskNotes, getTaskSortBy} from './core/_requests'
import Moment from 'moment';
import { Offcanvas, Toast } from 'bootstrap';
import { TaskDrawer } from './taskDrawers';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import {useAuth} from '../../../app/modules/auth'
import { TaskDetails } from './taskDetails';
// full calendar
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import * as Yup from 'yup'
import {useFormik} from 'formik'
import events from "./data/events";
import { TaskToolbar } from './taskToolbar';
import moment from 'moment';

const initialValues = {
    reply: '',
}

const TaskPage: FC = () => {
    
    const [tasks, setTasks] = useState<any[]>([]);
    const [taskPriority, setTaskPriority] = useState<any[]>([]);
    const [taskStatus, setTaskStatus] = useState<any[]>([]);
    const [taskStatusCalendar, setTaskStatusCalendar] = useState<any[]>([]);
    const [toggle, setToggle] = useState('grid');
    const {currentUser, logout} = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [detailData, setDetailData] = useState('');
    const [detailTab, setDetailTab] = useState('');
    const [taskId, setTaskId] = useState('');
    const [taskStatusChange, setTaskStatusChange] = useState('');
    const [detailClick, setDetailClick] = useState(false);
    const [taskCheckList, setTaskCheckList] = useState<any[]>([]);

    var roleId = currentUser?.designation;
    
    const handleHideData = (e:any) => {
        setToggle(e.target.value);
    };
    
    const taskList =  async () => {
        setIsLoading(true);
        const taskResponse = await getTasks()
        
        // console.log('all tasks');
        // console.log(taskResponse);

        setTasks(taskResponse);
        setIsLoading(false);

        var taskData = [];

        for(let i = 0; i < taskResponse.length; i++){
            const data = {title: taskResponse[i]['task_status_name'], date: taskResponse[i]['task_time']};

            taskData.push(data);
        }
        setTaskStatusCalendar(taskData);
        console.log(taskData);
    }

    const taskStatusList =  async () => {  
        setIsLoading(true); 
        const taskStatusResponse = await getTaskStatus();
        setTaskStatus(taskStatusResponse);
        setIsLoading(false);
    }

    const taskPriorityList =  async () => { 
        setIsLoading(true);  
        const taskPriorityResponse = await getTaskPriority();
        setTaskPriority(taskPriorityResponse);
        setIsLoading(false);
        
    }

    const taskTypeChange = async (e:any, id:any) => {
        setTaskId(id);
        document.getElementById('task_status_pop')?.click();
        setTaskStatusChange(e.target.value);
    }

    const taskPriorityChange = async (e:any, id:any) => {
        // console.log(e.target.value);
        // console.log(id);

        var userId = currentUser?.id;

        const body = {
            "created_by": userId,
            "priority": e.target.value
        }

        const updateTask = await updateTaskPriority(id, body);
        if(updateTask != null){
            var toastEl = document.getElementById('myToastPriority');
            const bsToast = new Toast(toastEl!);
            bsToast.show();
            taskListByRole();
            
        }
        
        // console.log(updateTask)
    }

    const openModal = (taskId:any, tabType:any) => {
        setDetailData(taskId);
        setDetailTab(tabType);
        setDetailClick(true);
        document.body.className += ' detail_opened';
        var offCanvasEl = document.getElementById('kt_expand'+taskId);
        offCanvasEl?.classList.remove('invisible');
        const bsOffcanvas = new Offcanvas(offCanvasEl!);
        bsOffcanvas.show();
    }

    const deleteModel = async (leadData:any) => {
        setIsLoading(false);
        console.log(leadData);
        await deleteTask(leadData);
        setIsLoading(false);
        taskListByRole();
        var toastEl = document.getElementById('myToastDeleteStatus');
            const bsToast = new Toast(toastEl!);
            bsToast.show();
    }

    const taskListByRole =  async () => {   
        setIsLoading(true);
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const characterResponse = await getTasksByRole(userId, roleId)
        setTasks(characterResponse);
        var taskData = [];

        for(let i = 0; i < characterResponse.length; i++){
            let msg = characterResponse[i].task_note + " " + "Assign to" + " " + characterResponse[i].assign_to_name
            const data = {title: msg , date: moment(characterResponse[i].task_time).format('YYYY-MM-DD'), start: characterResponse[i].task_time, end: characterResponse[i].finish_time};

            taskData.push(data);
        }
        setTaskStatusCalendar(taskData);
        console.log("calendertaskData", taskData)
        setIsLoading(false);
    }

    function renderEventContent(eventInfo:any) {
        console.log("eventInfo", eventInfo)
        return (
          <div className='w-100'>
            <span className='text-wrap shrink'>{eventInfo.event.title}</span>
          </div>
        )
      }

    const notesFormSchema = Yup.object().shape({
        reply: Yup.string(),
    })

    const formikNotes = useFormik({
        initialValues,
        validationSchema: notesFormSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
        //   console.log('on presssed');
        //   console.log(leadId);
        //   setIsLoading(true)
          try {
            // console.log('propertyId')
            // console.log(propertyId)
            var userId = currentUser?.id;
            var roleId = currentUser?.designation;

            var notesBody = {
                "reply": values.reply,
                "task_id": taskId,
                "user_id": userId,
                "parent_id": 0
            };
                           
            const leadNotesData = await saveTaskNotes(notesBody)
    
            // console.log('updateContactAddressData');
            // console.log(leadNotesData);
            if(leadNotesData != null){
                // setIsLoading(false);
                resetForm();
            //   var toastEl = document.getElementById('myToastUpdate');
            //   const bsToast = new Toast(toastEl!);
            //   bsToast.show();
                taskListByRole();                
            }

            const body = {
                "task_status": taskStatusChange,
                "created_by": userId,
            }
    
            const updateTask = await updateTaskStatus(taskId, body);
            console.log(updateTask);
    
            if(updateTask != null){
                // var toastEl = document.getElementById('myToastStatus');
                // const bsToast = new Toast(toastEl!);
                // bsToast.show();
                taskListByRole();
            }
            
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setIsLoading(false)
          }
        },
    })

    const cancelStatusChange = () => {
        taskListByRole();
    }

    useEffect(() => {
        // taskList();
        taskListByRole();
        taskStatusList();
        taskPriorityList();
    }, []);

    const taskOnSelect = (e:any, id:any) => {
        console.log('sdsdsdsdsdsd');
        console.log(e.target.checked);
        const newArr = [...taskCheckList];
        if(e.target.checked != false){
            setTaskCheckList(taskCheckList => [...taskCheckList, id]);
        } else {
            newArr.splice(newArr.findIndex(item => item === id), 1);
            setTaskCheckList(newArr);
        }
        console.log(taskCheckList);
    }

    const sortbyChangeTask = async (e:any) => {
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const Response = await getTaskSortBy(userId, roleId, e)
        setTasks(Response);
    }

    return(
        <div>
            
            <TaskToolbar sortByOnChange={sortbyChangeTask} layoutOnChange={handleHideData} selectedTasks={taskCheckList} setTaskList={setTasks}/>
            <TaskDrawer setTaskList={setTasks}/>
            <button id='taskReload' className='d-none' onClick={taskListByRole}></button>
            {isLoading ? 
            <div className='w-100 h-100'>
            <div className='d-flex justify-content-center flex-column align-items-center h_80vh'>
                <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
                <div className="spinner-border taskloader" role="status">                                    
                    <span className="sr-only">Loading...</span>
                </div>
            </div> 
        </div>:
            <div className={toggle == 'grid' ? "taskpage d-block": 'd-none'}>
                <div className="row">
                    <div className="card-group">
                    {tasks.map((taskData, i) => {
                                return(
                                    
                        <div className="col-sm-6 col-xxl-3 col-xl-4 mt-10 mb-3" key={i}>
                            
                            <div className="card h-100 mb-xl-8 mx-2 task_card bs_1">
                                <div className="card_status bg_secondary">
                                    <p className="mb-0">{taskData.task_type ?? 'No Task Type'}</p>
                                </div>
                                <div className='card-body px-3 pt-3 pb-0'>
                               
                                <div>
                                    <div className="d-flex align-items-center justify-content-between mb-5">   
                                        <div className="d-flex align-items-center">
                                            {/* modal */}
                                            <div>   
                                                {
                                                    detailClick && detailData == taskData.id && 
                                                    <>
                                                        <div
                                                            id={'kt_expand'+taskData.id}
                                                            className='expand_area detail_page_view offcanvas offcanvas-end justify-content-end w-100 bg-transparent d-flex'
                                                            // data-kt-drawer='true'
                                                            // data-kt-drawer-name='filter'
                                                            // data-kt-drawer-activate='true'
                                                            // data-kt-drawer-overlay='true'
                                                            // data-kt-drawer-width="{default:'96%', 'md': '75%'}"
                                                            // data-kt-drawer-direction='end'
                                                            // data-kt-drawer-toggle={'#kt_expand_toggle'+taskData.id}
                                                            // data-kt-drawer-close='#kt_expand_close'
                                                        >
                                                            <TaskDetails taskId={taskData.id} setTaskList={setTasks} tabInfo={detailTab} setDetailClicked={setDetailClick}/>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            <form action="">
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" onClick={(e) => taskOnSelect(e, taskData.id)} id={"contact"+taskData.id}/>
                                                    <label className="form-check-label id_label" htmlFor={"contact"+taskData.id}>
                                                        {taskData.id}
                                                    </label>
                                                </div>
                                            </form>
                                            <div className="ms-3 ml-2 d-flex align-items-center">
                                                <img src={toAbsoluteUrl('/media/custom/user.svg')} alt="" className="icon me-2" />
                                                <p className="mb-0 contact_name pb-1">{taskData.contact_name}</p>
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <a href='#' onClick={() => openModal(taskData.id, 'overview')}><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></a>
                                            <div className="btn-group">
                                                <a className="" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                    <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/>
                                                </a>
                                                <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                                    <li><a className="dropdown-item" href="#" onClick={() => openModal(taskData.id, 'overview')}>Edit</a></li>
                                                    { roleId == 1 ? <li><a className="dropdown-item" href="#" data-bs-toggle='modal'
                                                        data-bs-target={'#delete_confirm_popup418'+taskData.id}>Delete</a></li>: <></>}
                                                </ul>
                                            </div>
                                            <div className='modal fade' id={'delete_confirm_popup418'+taskData.id} aria-hidden='true'>
                                                <div className='modal-dialog modal-dialog-centered'>
                                                    <div className='modal-content'>
                                                        <div className='modal-header'>
                                                            <h3>Confirmation</h3>
                                                            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                                            </div>
                                                        </div>
                                                        <div className='modal-body py-lg-10 px-lg-10'>
                                                            <p>Are you sure want to delete?</p>
                                                            <div className='d-flex align-items-center justify-content-end'>
                                                                <button className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal'>
                                                                    No
                                                                </button>
                                                                <button className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal' onClick={(e) => deleteModel(taskData.id)}>
                                                                    Yes
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center mb-4">
                                        <div className="flex-shrink-0">
                                            <img onError={e => { e.currentTarget.src = toAbsoluteUrl('/media/custom/buyer.svg') }} src={taskData.contact_profile_image ? process.env.REACT_APP_API_BASE_URL+'uploads/contacts/profile_image/'+taskData.contact_id+'/'+taskData.contact_profile_image : ''} className="user_img" alt='' />
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <a href={"mailto:"+ taskData.contact_email} className="d-flex align-items-center mb-3 text-dark">
                                                <img src={toAbsoluteUrl('/media/custom/envelope.svg')} alt="" className="icon me-1"/>
                                                <p className="mb-0 fixed_text">{taskData.contact_email}</p>
                                            </a>
                                            <div className="d-xxl-flex justify-content-between">
                                                <a href={"tel:" + taskData.contact_mobile} className="d-flex align-items-center mb-2 text-dark">
                                                    <p className="mb-0 d-flex flex-wrap">
                                                        <span><img src={toAbsoluteUrl('/media/custom/phone.svg')} alt="" className="icon me-1" /></span>
                                                        {taskData.contact_mobile}
                                                    </p>
                                                </a>
                                                <a href={"https://api.whatsapp.com/send?phone="+ taskData.contact_mobile} target="new" className="d-flex align-items-center mb-2 text-dark">
                                                    <p className="mb-0 d-flex flex-wrap">
                                                        <span><img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} alt="" className="icon me-1" /></span>
                                                        {taskData.contact_mobile}
                                                    </p>
                                                </a>
                                            </div>
                                            {/* <div className="d-xxl-flex">
                                                <a href={"tel:"+ taskData.contact_mobile} className="d-flex align-items-center mb-2 text-dark">
                                                    <p className="mb-0 d-flex flex-wrap">
                                                        <span><img src={toAbsoluteUrl('/media/custom/phone.svg')} alt="" className="icon me-1" /></span>
                                                        {taskData.contact_mobile}
                                                    </p>
                                                </a>
                                            </div>    */}
                                        </div>
                                    </div>
                                    <div className='mb-3'>
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="row">
                                                    <div className="col-sm-6 col-6 mb-3">
                                                        <div className="task_content_single" title={taskData.contact_type ?? '--'}>
                                                            <div className="d-flex align-items-start single_item">
                                                                <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="me-2"/>
                                                                <div className="d-flex flex-column">
                                                                    <small className="text_light">Contact Type</small>
                                                                    <p className="mb-0 fw-500">{taskData.contact_type ?? '--'}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="task_content_single">
                                                            <div className="d-flex align-items-center mb-4">
                                                                <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="icon me-2"/>
                                                                <p className="ml-2 mb-0 fixed_text">Buyer</p>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    <div className="col-sm-6 col-6 mb-3" title={taskData.property_name ?? '--'}>
                                                        <div className="task_content_single">
                                                            <div className="d-flex align-items-start single_item">
                                                                <img src={toAbsoluteUrl('/media/custom/project.svg')} alt="" className="me-2"/>
                                                                <div className="d-flex flex-column">
                                                                    <small className="text_light">Project</small>
                                                                    <p className="mb-0 fw-500">{taskData.property_name ?? '--'}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-6 mb-3" title={Moment(taskData.task_time).format('DD-MMMM-YYYY, HH:mm')}>
                                                        <div className="task_content_single">
                                                            <div className="d-flex align-items-start single_item">
                                                                <img src={toAbsoluteUrl('/media/custom/fromdate.svg')} alt="" className="me-2"/>
                                                                <div className="d-flex flex-column">
                                                                    <small className="text_light">From Date</small>
                                                                    <p className="mb-0 fw-500">{Moment(taskData.task_time).format('DD-MM-YYYY, HH:mm')}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-sm-6 col-6 mb-3" title={Moment(taskData.finish_time).format('DD-MMMM-YYYY, HH:mm')}>
                                                        <div className="task_content_single">
                                                            <div className="d-flex align-items-start single_item">
                                                                <img src={toAbsoluteUrl('/media/custom/fromdate.svg')} alt="" className="me-2"/>
                                                                <div className="d-flex flex-column">
                                                                    <small className="text_light">To Date</small>
                                                                    <p className="mb-0 fw-500">{Moment(taskData.finish_time).format('DD-MM-YYYY, HH:mm')}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-6 mb-3" title={taskData.contact_name ?? '--'}>
                                                        <div className="task_content_single">
                                                            <div className="d-flex align-items-start single_item">
                                                                <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="me-2"/>
                                                                <div className="d-flex flex-column">
                                                                    <small className="text_light">{taskData.contact_type ?? 'Contact Person'}</small>
                                                                    <p className="mb-0 fw-500">{taskData.contact_name ?? '--'}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div className="col-sm-6 col-6 mb-3" title={taskData.assign_to_name ?? '--'}>
                                                        <div className="task_content_single">
                                                            <div className="d-flex align-items-start single_item">
                                                                <img src={toAbsoluteUrl('/media/custom/lead/assign_9.svg')} alt="" className="me-2"/>
                                                                <div className="d-flex flex-column">
                                                                    <small className="text_light">Assign To</small>
                                                                    <p className="mb-0 fw-500">{taskData.assign_to_name ?? '--'}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mb-3" title={taskData.task_note ?? '--'}>
                                                        <div className="task_content_single">
                                                            <div className="d-flex align-items-start single_item">
                                                                <img src={toAbsoluteUrl('/media/custom/fromdate.svg')} alt="" className="me-2"/>
                                                                <div className="d-flex flex-column">
                                                                    <small className="text_light">Task Description</small>
                                                                    <p className="mb-0 fw-500">{taskData.task_note ?? '--'}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer border-0 p-1">
                                        <div className="row">
                                            <div className="col-7 col-xl-8 icons_bar d-flex align-items-end flex-wrap">
                                                <a href="#" onClick={() => openModal(taskData.id, 'notes')} className="btn btn-sm icon_primary rounded-circle position-relative mb-2" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Notes">
                                                    <img src={toAbsoluteUrl('/media/custom/notes.svg')} className="svg_icon" alt='' />
                                                    <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                        0
                                                    </span>
                                                </a>
                                                <a href="#" onClick={() => openModal(taskData.id, 'files')} className="btn btn-sm icon_primary rounded-circle position-relative mb-2" data-bs-toggle="tooltip" data-bs-placement="bottom" title="File">
                                                    <img src={toAbsoluteUrl('/media/custom/file.svg')} className="svg_icon" alt='' />
                                                    <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                        0
                                                    </span>
                                                </a>
                                                <select className="form-select form-select-sm priority_select ms-2 mb-2" aria-label=".form-select-sm example" onChange={(e) => taskPriorityChange(e, taskData.id)}>
                                                    {taskPriority.map((taskPrior,i) =>{
                                                        return (
                                                            <option value={taskPrior.id} selected={taskPrior.id == taskData.priority} key={i}>{taskPrior.name}</option> 
                                                    )})}
                                                </select>
                                            </div>
                                            <div className="col-5 col-xl-4 d-flex align-items-center justify-content-end">
                                                <select className="form-select toggle_white toggle_white btn_primary rounded-pill btn-sm cursor-pointer status_btn" aria-label="Default select example" onChange={(e) => taskTypeChange(e, taskData.id)}>
                                                    {taskStatus.map((taskState,i) =>{
                                                        return (
                                                            <option value={taskState.id} selected={taskState.id == taskData.task_status} key={i}>{taskState.name}</option> 
                                                    )})}
                                                </select>
                                            </div>
                                            <a className="d-none" href="#" data-bs-toggle='modal' id='task_status_pop'
                                                data-bs-target={'#contact_status_change'+taskData.id}></a>
                                                <div className='modal fade' id={'contact_status_change'+taskData.id} aria-hidden='true'>
                                                    <div className='modal-dialog modal-dialog-centered'>
                                                        <div className='modal-content'>
                                                            <div className='modal-header'>
                                                                <h3>Confirmation</h3>
                                                                <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                                                </div>
                                                            </div>
                                                            <div className='modal-body py-lg-10 px-lg-10'>
                                                                <form noValidate onSubmit={formikNotes.handleSubmit}>
                                                                    <textarea
                                                                        className='form-control main_bg border-0 p-2 resize-none min-h-25px br_10'
                                                                        data-kt-autosize='true'
                                                                        {...formikNotes.getFieldProps('reply')} 
                                                                        rows={7}
                                                                        placeholder='Reason'
                                                                    ></textarea>
                                                                    <div className='d-flex align-items-center justify-content-end'>
                                                                        <button className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal' type='button' onClick={(e) => cancelStatusChange()}>
                                                                            No
                                                                        </button>
                                                                        <button disabled={formikNotes.isSubmitting} className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal' type='submit'>
                                                                            Yes
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                    </div> 
                                </div>
                            </div>   
                        </div>
                    )})}
                    </div>
                </div>
            </div>
}
            {/* kanban view */}
            <div className={toggle == 'pipe' ? "taskpage d-block": 'd-none'}>
                pipeline
                {/* <Board data={data} /> */}
            </div>
            
            {/* calendar view */}
            {toggle == 'calendar' && (
            <div className='taskCalenderView'>
                <FullCalendar
                initialView="dayGridMonth"
                headerToolbar= {{
                    start: "prev,next,today",
                    center: "title",
                    end: "dayGridMonth,timeGridWeek,timeGridDay"
                }}
                plugins={[dayGridPlugin, timeGridPlugin]}
                events={taskStatusCalendar}
                eventContent={renderEventContent}
                />
            </div>
            )}

            <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToast">
                <div className="toast-header">
                    <strong className="me-auto">Success</strong>
                    <button aria-label="Close" className="btn-close btn-close-color-white" 
                                data-bs-dismiss="toast" type="button">
                    </button>
                </div>
                <div className="toast-body">
                    <div>Task Updated Successfully!</div>
                </div>
            </div>
            <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastStatus">
                <div className="toast-header">
                    <strong className="me-auto">Success</strong>
                    <button aria-label="Close" className="btn-close" 
                            data-bs-dismiss="toast" type="button">
                    </button>
                </div>
                <div className="toast-body">
                    <div>Task Status Updated Successfully!</div>
                </div>
            </div>
            <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastPriority">
                <div className="toast-header">
                    <strong className="me-auto">Success</strong>
                    <button aria-label="Close" className="btn-close" 
                            data-bs-dismiss="toast" type="button">
                    </button>
                </div>
                <div className="toast-body">
                    <div>Task Priority Updated Successfully!</div>
                </div>
            </div>
            <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastDeleteStatus">
                <div className="toast-header">
                    <strong className="me-auto">Success</strong>
                    <button aria-label="Close" className="btn-close" 
                            data-bs-dismiss="toast" type="button">
                    </button>
                </div>
                <div className="toast-body">
                    <div>Task Deleted Successfully!</div>
                </div>
            </div>
            <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastAdd">
                <div className="toast-header">
                    <strong className="me-auto">Success</strong>
                    <button aria-label="Close" className="btn-close btn-close-color-white" 
                                data-bs-dismiss="toast" type="button">
                    </button> 
                </div>
                <div className="toast-body">
                    <div>Task Created Successfully!</div>
                </div>
            </div>
            <div aria-atomic="true" aria-live="assertive" className="toast bg_primary text-white position-absolute end-0 bottom-0 m-3" id="myToastUpload">
                <div className="toast-header">
                    <strong className="me-auto">Success</strong>
                    <button aria-label="Close" className="btn-close btn-close-color-white" 
                                data-bs-dismiss="toast" type="button">
                    </button> 
                </div>
                <div className="toast-body">
                    <div>Task Imported Successfully!</div>
                </div>
            </div>
            <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastUpdate">
                <div className="toast-header">
                        <strong className="me-auto">Success</strong>
                        <button aria-label="Close" className="btn-close" 
                                data-bs-dismiss="toast" type="button">
                        </button>
                </div>
                <div className="toast-body">
                    Task Updated Successfully!
                </div>
            </div>
        </div>
    )
}

export {TaskPage}
