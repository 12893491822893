import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Board, { moveCard } from "@lourenci/react-kanban";
import "@lourenci/react-kanban/dist/styles.css";
import { getTeams } from './core/_requests';
// Use your own styles to override the default styles
// import "./styles.css";

let board = {
  columns: [
    {
      id: 1,
      title: "Backlog",
      backgroundColor: "#fff",
      cards: [
        {
          id: 1,
          title: "Card title 1",
          description: "Card content"
        },
        {
          id: 2,
          title: "Card title 2",
          description: "Card content"
        },
        {
          id: 3,
          title: "Card title 3",
          
        }
      ]
    },
    {
      id: 2,
      title: "Doing",
      cards: [
        {
          id: 9,
          title: "Card title 9",
          description: "Card content"
        }
      ]
    },
    {
      id: 3,
      title: "Q&A",
      cards: [
        {
          id: 10,
          title: "Card title 10",
          description: "Card content"
        },
        {
          id: 11,
          title: "Card title 11",
          description: "Card content"
        }
      ]
    },
    {
      id: 4,
      title: "Production",
      cards: [
        {
          id: 12,
          title: "Card title 12",
          description: "Card content"
        },
        {
          id: 13,
          title: "Card title 13",
          description: "Card content"
        }
      ]
    }
  ]
};

var newBoard = {};


export default function UncontrolledBoard() {

    const [teamsList, setTeamsList] = useState([]);
    const [teamsObj, setTeamsObj] = useState({});
    const [teamData, setTeamData] = useState([]);

    const TeamsList =  async () => {
        const teamsResponse = await getTeams()
        setTeamsList(teamsResponse);
        var teamArray = [];
        var teamAr = [];
        for(let i= 0; i < teamsResponse.length; i++) {
            let data = {
                id: 4,
                title: "Production",
                cards: [
                  {
                    id: 12,
                    title: "Card title 12",
                    description: "Card content"
                  },
                  {
                    id: 13,
                    title: "Card title 13",
                    description: "Card content"
                  }
                ]
              }

              teamAr.push(data);

            teamArray[i] = teamsResponse[i].assign_to.split(",").map((e) => {
                return parseInt(e);
            });
        }
        console.log('sdsdsdsdsdsdsds----------------------');
        setTimeout(
            function() {
                setTeamData(teamAr);
                setTimeout(
                    function() {
                        setTeamsObj({columns: teamData})
                        board = {columns: teamData};
                        console.log(teamData)
                    }, 2000)
            }, 2000);
        
        console.log(teamArray);
    }

    
    
    // for(let i= 0; i < teamsList.length; i++) {
    //     const teamidarray = teamsList.map((obj) => Object.values(obj)[4]);
    //     const teamsid = teamidarray[i].split(",")
    //     console.log('values');
    //     console.log(teamsid);
    // }
    
    
    

    useEffect(() => {
        TeamsList();
    }, []);

  return (
    <Board
      allowRemoveLane
      allowRenameColumn
      allowRemoveCard
      
      initialBoard={board}
      allowAddCard={{ on: "top" }}
      onNewCardConfirm={(draftCard) => ({
        id: new Date().getTime(),
        ...draftCard
      })}
      
    />
  );
}


