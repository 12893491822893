import React,{FC, useEffect, useState} from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import DataTable from 'react-data-table-component'; 
import { TreeChart } from './treechart';
import { FullCalendarComponent } from './fullCalendar';
import { TimeSheet } from './timesheet';
import { RoleProfile } from './roleProfile';
import { deleteUser, getUsers } from './core/_requests';
import { EditUser } from './EditUser';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { UserManagementDrawer } from './userManagement_drawer';
import { UserToolbar } from './userToolbar';
import App from '../LeadSettings/drag';
import UncontrolledBoard from './kanban';
import ReactBigCalendar from './bigCal';
import { useAuth } from '../../../modules/auth';
import { Offcanvas } from 'bootstrap';
import { UserPerformance } from './performance';
import { TeamsList } from './teamsList';



const leadcolumns: GridColDef[] = [
    { field: 'employee_id', headerName: 'ID', width: 70,headerClassName: 'dg_header' },
    { field: 'first_name', headerName: 'First name', width: 150,headerClassName: 'dg_header' },
    { field: 'last_name', headerName: 'Last name', width: 150,headerClassName: 'dg_header' },
    { field: 'designation_name', headerName: 'Designation', width: 180,headerClassName: 'dg_header' },
    { field: 'department_name', headerName: 'Department', type: 'number', width: 150,headerClassName: 'dg_header'},
    { field: 'branch_name', headerName: 'Branch', width: 240,headerClassName: 'dg_header' },
    { field: 'p_phone_number', headerName: 'Personal Phone Number', width: 130,headerClassName: 'dg_header' },
    { field: 'email', headerName: 'Email', width: 180,headerClassName: 'dg_header' },
    { field: 'o_phone_number', headerName: 'Official Phone Number', width: 180,headerClassName: 'dg_header' },
    { field: 'o_email', headerName: 'Official Email', width: 180,headerClassName: 'dg_header' },
    { field: 'emergency_contact_no', headerName: 'Emergency Contact Number', width: 180,headerClassName: 'dg_header' },
    { field: 'aadhar_number', headerName: 'Aadhar', width: 180,headerClassName: 'dg_header' },
    { field: 'pan_number', headerName: 'PAN', width: 180,headerClassName: 'dg_header' },
    { field: 'dob', headerName: 'Date of Birth', width: 180,headerClassName: 'dg_header' },
    { field: 'marital_status', headerName: 'Marital Status', width: 180,headerClassName: 'dg_header' },
    { field: 'anniversary_date', headerName: 'Anniversary Date', width: 180,headerClassName: 'dg_header' },
    { field: 'date_of_joining', headerName: 'Date of Joining', width: 180,headerClassName: 'dg_header' },
    { field: 'created_at', headerName: 'Created At', width: 180,headerClassName: 'dg_header' },    
    { field: 'gender', headerName: 'Gender', width: 180,headerClassName: 'dg_header' },    
    { field: 'blood_group', headerName: 'Blood Group', width: 180,headerClassName: 'dg_header' },    
    { field: 'permenent_address', headerName: 'Permenent Address', width: 180,headerClassName: 'dg_header' },    
    { field: 'correspondence_address', headerName: 'Correspondence Address', width: 180,headerClassName: 'dg_header' },    
    { field: 'fathers_name', headerName: 'Fathers Name', width: 180,headerClassName: 'dg_header' },    
    { field: 'spouse_name', headerName: 'Spouse Name', width: 180,headerClassName: 'dg_header' },    
    { field: 'medical_condition', headerName: 'Medical Condition', width: 180,headerClassName: 'dg_header' },    
    { field: 'base_salary', headerName: 'Base Salary', width: 180,headerClassName: 'dg_header' },    
    { field: 'pf', headerName: 'PF', width: 180,headerClassName: 'dg_header' },    
    { field: 'hra', headerName: 'HRA', width: 180,headerClassName: 'dg_header' },    
    { field: 'mediclaim', headerName: 'Mediclaim', width: 180,headerClassName: 'dg_header' },    
    { field: 'conveyance', headerName: 'Conveyance', width: 180,headerClassName: 'dg_header' },    
    { field: 'misc', headerName: 'MISC', width: 180,headerClassName: 'dg_header' },    
    { field: 'total_ctc', headerName: 'Total CTC', width: 180,headerClassName: 'dg_header' },    
    { field: 'bank_name', headerName: 'Bank Name', width: 180,headerClassName: 'dg_header' },    
    { field: 'acc_number', headerName: 'Account Number', width: 180,headerClassName: 'dg_header' },    
    { field: 'ifsc_code', headerName: 'IFSC Code', width: 180,headerClassName: 'dg_header' },    
    { field: 'branch_name', headerName: 'Branch Name', width: 180,headerClassName: 'dg_header' },    
    { field: 'years_of_experience', headerName: 'Years of Experience', width: 180,headerClassName: 'dg_header' },    
    { field: 'last_company', headerName: 'Last Company', width: 180,headerClassName: 'dg_header' },    
    { field: 'monthly_ctc', headerName: 'Monthly CTC', width: 180,headerClassName: 'dg_header' },    
    { field: 'monthly_take_home', headerName: 'Monthly Take Home', width: 180,headerClassName: 'dg_header' },    
    { field: 'incentive', headerName: 'Incentive', width: 180,headerClassName: 'dg_header' },    
    { field: 'city', headerName: 'City', width: 180,headerClassName: 'dg_header' },    
  ];
  

const UserManagement: FC = () => {
    
    const [toggle, setToggle] = useState(false);

    const handleHideData = () => {
        setToggle(!toggle);
    };
    const [userList, setUserList] = useState<any[]>([]);
    const [editClicked, setEditClicked] = useState(false);
    const [detailClick, setDetailClick] = useState(false);
    const [editId, setEditId] = useState('');
    const [detailData, setDetailData] = useState('');
    const [detailTab, setDetailTab] = useState('');
    const {currentUser, logout} = useAuth();

    const roleId = currentUser?.designation;

    const CityList =  async () => {
        const userResponse = await getUsers()

        console.log('all property');
        console.log(userResponse);
        
        setUserList(userResponse);
    }
    // console.log(City);
    const onDelete = async (id:any) => {
        console.log(id);
        await deleteUser(id);
        CityList();
    }

    const openModal = (Id:any, tabType:any) => {
        setDetailData(Id);
        setDetailTab(tabType);
        setDetailClick(true);
        document.body.className += ' detail_opened';
        var offCanvasEl = document.getElementById('kt_expand'+Id);
        offCanvasEl?.classList.remove('invisible');
        const bsOffcanvas = new Offcanvas(offCanvasEl!);
        bsOffcanvas.show();
    }
    
    useEffect(() => {
        CityList();
    }, []);
    const handleStatus = (event:any, value:any, contactId:any) => {
        // alert(value)
        // alert(contactId)
    }

    return(
        <>
        <UserToolbar />        
        <UserManagementDrawer setUserList={setUserList}/>        
        <div className="user_manage_page bg_white h-100 p-4">
            <div className='w-100 overflow-hidden'>
                <ul className="nav nav-pills mb-5" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="pills-org-tab" data-bs-toggle="pill" data-bs-target="#pills-org" type="button" role="tab" aria-controls="pills-org" aria-selected="true">Manage Users</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="user-charts-tab" data-bs-toggle="pill" data-bs-target="#user-charts" type="button" role="tab" aria-controls="user-charts" aria-selected="false">User Charts</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="manage-teams-tab" data-bs-toggle="pill" data-bs-target="#manage-teams" type="button" role="tab" aria-controls="manage-teams" aria-selected="false">Manage Teams</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="attendance-tab" data-bs-toggle="pill" data-bs-target="#attendance" type="button" role="tab" aria-controls="attendance" aria-selected="false">Attendance</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="performance-tab" data-bs-toggle="pill" data-bs-target="#performance" type="button" role="tab" aria-controls="performance" aria-selected="false">Performance</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="time-sheets-tab" data-bs-toggle="pill" data-bs-target="#time-sheets" type="button" role="tab" aria-controls="time-sheets" aria-selected="false">Time Sheets</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="role-profile-tab" data-bs-toggle="pill" data-bs-target="#role-profile" type="button" role="tab" aria-controls="time-sheets" aria-selected="false">Role & Profile</button>
                    </li>
                </ul>
            </div>
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-org" role="tabpanel" aria-labelledby="pills-org-tab">
                    <div className="d-flex justify-content-end mb-2">
                        <select className="me-4 mb-2 btn_secondary btn btn-sm p-2" name="views" id="views" onChange={handleHideData}>
                            <option>Grid View</option>
                            <option>List View</option>
                        </select>
                    </div>
                        {!toggle && (
                            <div className="row">
                                <div className="card-group">
                                {userList.map((userData, i) => {
                                return(
                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-6 mb-4" key={i}>
                                        <div className="card user_card bs_2 h-100 mx-2">
                                            <div className="card-header px-0 d-flex justify-content-between align-items-center">
                                            
                                            {
                                                    detailClick && detailData == userData.id &&
                                                <div
                                                    id={'kt_useredit_toggle'+userData.id}
                                                    className='expand_area detail_page_view offcanvas offcanvas-end justify-content-end w-100 bg-transparent d-flex' data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample"
                                                    // data-kt-drawer='true'
                                                    // data-kt-drawer-name='kt_useredit'
                                                    // data-kt-drawer-activate='true'
                                                    // data-kt-drawer-overlay='true'
                                                    // data-kt-drawer-width="{default:'96%', 'md': '75%'}"
                                                    // data-kt-drawer-direction='end'
                                                    // data-kt-drawer-toggle={'#kt_useredit_toggle'+userData.id}
                                                    // data-kt-drawer-close='#kt_useredit_close'
                                                >
                                                    <EditUser currentUserId={userData.id} setUser={setUserList} setDetailClicked={setDetailClick}/>
                                                </div>
                                            }
                                                <div>
                                                    <p className="mb-0"><span>ID:</span> {userData.employee_id}</p>
                                                </div>
                                                <div className="d-flex">
                                                    <button className="btn btn-sm p-1" onClick={() => openModal(userData.id, 'overview')}><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></button>
                                                    <div className="btn-group">
                                                        <button className="btn p-1 btn-sm" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                            <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/>
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                                            <li><button className="dropdown-item" onClick={() => openModal(userData.id, 'overview')}>Edit</button></li>
                                                            {/* <li><button className="dropdown-item">Create Proposal</button></li>
                                                            <li><button className="dropdown-item">Requirment Form</button></li>
                                                            <li><button className="dropdown-item">Register Contact</button></li> */}
                                                            {roleId == 1 &&
                                                            <li><button data-bs-toggle='modal' data-bs-target={'#delete_confirm_popup'+userData.id} className="dropdown-item">Delete</button>
                                                            </li>}
                                                        </ul>
                                                        <div className='modal fade' id={'delete_confirm_popup'+userData.id} aria-hidden='true'>
                                                                <div className='modal-dialog modal-dialog-centered'>
                                                                    <div className='modal-content'>
                                                                        <div className='modal-header'>
                                                                            <h3>Confirmation</h3>
                                                                            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                                                            </div>
                                                                        </div>
                                                                        <div className='modal-body py-lg-10 px-lg-10'>
                                                                            <p>Are you sure want to delete?</p>
                                                                            <div className='d-flex align-items-center justify-content-end'>
                                                                                <button className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal'>
                                                                                    No
                                                                                </button>
                                                                                <button className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal' onClick={(e) => onDelete(userData.id)}>
                                                                                    Yes
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="d-flex flex-center">
                                                    {/* <img src={toAbsoluteUrl('/media/avatars/blank.png')} className="user_img" alt='' /> */}
                                                    <img onError={e => { e.currentTarget.src = toAbsoluteUrl('/media/avatars/blank.png') }} src={userData.profile_image ? process.env.REACT_APP_API_BASE_URL+'uploads/users/profile_image/'+userData.id+'/'+userData.profile_image : ''} className="user_img" alt='' />
                                                    {/* <img src={toAbsoluteUrl(userData.profile_image)} className="user_img" alt='' /> */}
                                                </div>
                                                <div className="text-center my-4">
                                                    <div className="d-flex justify-content-center mb-2 text-center">
                                                        <p className="mb-0 contact_name">{userData.first_name} {userData.last_name}</p>
                                                    </div>
                                                    <div>
                                                        <p className='mb-0 text_light'>{userData.email}</p>
                                                    </div>
                                                    <div>
                                                        <p className='mb-0 text_light'>{userData.p_phone_number}</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row">
                                                                <div className="col-sm-6 col-6 mb-3" title={userData.designation_name}>
                                                                    <div className="task_content_single">
                                                                        <div className="d-flex align-items-start single_item">
                                                                            <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="me-2"/>
                                                                            <div className="d-flex flex-column">
                                                                                <small className="text_light">Designation</small>
                                                                                <p className="mb-0 fw-500">{userData.designation_name}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-6 mb-3" title={userData.department_name}>
                                                                    <div className="task_content_single">
                                                                        <div className="d-flex align-items-start single_item">
                                                                        <img src={toAbsoluteUrl('/media/custom/google_ads.svg')} alt="" className="me-2"/>
                                                                            <div className="d-flex flex-column">
                                                                                <small className="text_light">Department</small>
                                                                                <p className="mb-0 fw-500">{userData.department_name}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-sm-6 col-6 mb-3" title={userData.p_phone_number}>
                                                                    <div className="task_content_single">
                                                                        <div className="d-flex align-items-start single_item">
                                                                            <img src={toAbsoluteUrl('/media/custom/phone.svg')} alt="" className="me-2"/>
                                                                            <div className="d-flex flex-column">
                                                                                <small className="text_light">Phone</small>
                                                                                <p className="mb-0 fw-500">{userData.p_phone_number}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-6 mb-3" title={userData.email}>
                                                                    <div className="task_content_single overflow-hidden">
                                                                        <div className="d-flex align-items-start single_item">
                                                                            <img src={toAbsoluteUrl('/media/custom/envelope.svg')} alt=""  className="me-2"/>
                                                                            <div className="d-flex flex-column">
                                                                                <small className="text_light">Email</small>
                                                                                <p className="mb-0 fw-500">{userData.email}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )})}
                                    
                                </div>
                                <div aria-atomic="true" aria-live="assertive" className="toast bg_primary p-0 text-white position-fixed end-0 bottom-0" id="myToastAdd">
                                    <div className="toast-header">
                                        <strong className="me-auto">Success</strong>
                                        <button aria-label="Close" className="btn-close btn-close-color-white" 
                                                    data-bs-dismiss="toast" type="button">
                                        </button> 
                                    </div>
                                    <div className="toast-body">
                                        <div>User Created Successfully!</div>
                                    </div>
                                </div>
                                <div aria-atomic="true" aria-live="assertive" className="toast bg_primary p-0 text-white position-fixed end-0 bottom-0" id="myToastUpdate">
                                    <div className="toast-header">
                                        <strong className="me-auto">Success</strong>
                                        <button aria-label="Close" className="btn-close btn-close-color-white" 
                                                    data-bs-dismiss="toast" type="button">
                                        </button> 
                                    </div>
                                    <div className="toast-body">
                                        <div>User Updated Successfully!</div>
                                    </div>
                                </div>
                            </div>
                            )}
                         {toggle && (
                                <>
                        <div style={{ height: 630, width: '100%',}}>

                            {userList.length > 0 
                            ? <DataGrid
                                rows={userList}
                                columns={leadcolumns}
                                pageSize={10}
                                rowsPerPageOptions={[10]}
                                checkboxSelection
                                sx={{
                                    fontSize:14,
                                    fontWeight:500,
                                }}
                            />
                            : <div className="text-center w-100">
                                <span className="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor"></path><rect x="7" y="17" width="6" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="12" width="10" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="7" width="6" height="2" rx="1" fill="currentColor"></rect><path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor"></path></svg></span>
                                <p className='mt-3'>No Lead Contacts Available</p>
                                </div>
                            }
                        </div>
                        </>
                         )}
                </div>
                <div className="tab-pane fade" id="user-charts" role="tabpanel" aria-labelledby="user-charts-tab">
                    <div className="d-flex flex-column flex-center mb-9">   
                        <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
                        <h2>Under Construction</h2>
                    </div>
                    <div className='d-none'>
                    <TreeChart/>
                    </div>
                </div>
                <div className="tab-pane fade" id="manage-teams" role="tabpanel" aria-labelledby="manage-teams-tab">
                {/* <div className="d-flex flex-column flex-center mb-9">   
                    <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
                    <h2>Under Construction</h2>
                </div> */}
                <TeamsList/>
                <div className='d-none'>
                <UncontrolledBoard/>
                </div>                    
                </div>
                <div className="tab-pane fade" id="attendance" role="tabpanel" aria-labelledby="attendance-tab">
                    {/* <div className="d-flex flex-column flex-center mb-9">   
                        <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
                        <h2>Under Construction</h2>
                    </div> */}                    
                    <div className=''>
                    <ReactBigCalendar /> 
                   </div>
                </div>
                <div className="tab-pane fade" id="performance" role="tabpanel" aria-labelledby="performance-tab">
                    <div className=''>
                        <UserPerformance/>
                    </div>  
                </div>
                <div className="tab-pane fade" id="time-sheets" role="tabpanel" aria-labelledby="time-sheets-tab">
                    <div className="d-flex flex-column flex-center mb-9">   
                        <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
                        <h2>Under Construction</h2>
                    </div>
                    <div className='d-none'>
                    <TimeSheet/>
                    </div>
                </div>
                <div className="tab-pane fade" id="role-profile" role="tabpanel" aria-labelledby="role-profile-tab">
                    {/* <div className="d-flex flex-column flex-center mb-9">   
                        <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
                        <h2>Under Construction</h2>
                    </div> */}
                    <div className=''>
                    <RoleProfile/>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export {UserManagement}