import React,{FC, useEffect, useState} from 'react'
import ReactDOM from 'react-dom'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {useAuth} from '../../../app/modules/auth'
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import { getAllUsers, getTeamLeader, getTrnsaction, getTrnsactionTLDrop, saveTrnsactions, updateTrnsactions, updateTrnsactionsBD, updateTrnsactionsID } from './core/_requests';
import { getLeadSource } from '../lead/core/_requests';
// import { getProjects } from '../task/core/_requests';
import {getProjects,getProperties} from '../property/core/_requests';
import { getCity, getUnitType } from '../property/core/_requests';
import { Offcanvas, Toast } from 'bootstrap';
import Moment from 'moment';
import { getTeamsList } from '../settings/userManagement/core/_requests';
import { getDeveloperNameList } from '../contact/core/_requests';


const initialValues = {
    lead_creation_date: '',
    booking_date: '',
    city: '',
    lead_source: '',
    team_leader: '',
    closed_by: '',
    shared_with: '',
    developer_name: '',
    project_name: '',
    client_name: '',
    contact_number: '',
    email_id: '',
    discount_value: '',
    block_no: '',
    unit_no: '',
    floor_no: '',
    bhk_type: '',
    sizes: '',
    basic_price: '',
    frc: '',
    plc: '',
    car_parking_cost: '',
    agreement_value: '',
    pan: '',
    dob: '',
    doa: '',
    correspondence_address: '',
    Brokerage_percentage: '',
    brokerage_value: '',
    discount_amount: '',
    revenue: '',
    aop_per: '',
    discount_paid_status: '',
    tds_value: '',
    s_gst_per: '',
    c_gst_per: '',
    i_gst_per: '',
    gst_amount: '',
    gross_brokerage_value: '',
    tds_per_ded_builder: '',
    tds_amount: '',
    after_tds_brokerage: '',
    actual_receivable_amount: '',
    incentive_per: '',
    incentive_without_tds: '',
    tds_per: '',
    net_incentive_earned: '',
    invoice_status: '',
    raised: '',
    pending: '',
    payment_status: '',
    amount_received: '',
    receiving_date: '',
    pending_brokerage: '',
    s_gst_2: '',
    c_gst_3: '',
    i_gst_4: '',
    gst_amount2: '',
    gross_brokerage_value2: '',
    tds_reducted_by_builder3: '',
    tds_amount2: '',
    after_tds_brokearge5: '',
    pending_receivable_amount: '',
    created_by: '',
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, aminityName: string[], theme: Theme) {
    return {
        fontWeight:
        aminityName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
    };
}

const mystyle = {
    padding: "0px",
    height: "34px",
    maxHeight: "200px",
    overflowY: "scroll",
    // display: "block"
};

type Props = {
    transactionId?: any,
    setDetailClicked: any,
}

const TransactionDetails: FC<Props> = (props) => {

    const {
        transactionId, setDetailClicked
    } = props

    const [isExpand, setIsExpand] = useState(true);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [leadSource, setLeadSource] = useState<any[]>([]);
    const [projects, setProjects] = useState<any[]>([]);
    const [unitType, setUnitType] = useState<any[]>([]);
    const [teamLeader, setTeamLeader] = useState<any[]>([]);
    const [users, setUsers] = useState<any[]>([]);
    const [city, setCity] = useState<any[]>([]);
    const {currentUser, logout} = useAuth();
    const [teamLeaderId, setTeamLeaderId] = useState<any[]>([]);
    const [developerNameList, setDeveloperNameList] = useState<any[]>([]);
    const [teamLeaderName, setTeamLeaderName] = useState<any[]>([]);
    const [closedById, setClosedById] = useState<any[]>([]);
    const [closedByName, setClosedByName] = useState<any[]>([]);
    const [sharedWithId, setSharedWithId] = useState<any[]>([]);
    const [sharedWithName, setSharedWithName] = useState<any[]>([]);
    const [taskType, setTaskType] = useState<any[]>([]);
    const [taskPriority, setTaskPriority] = useState<any[]>([]);
    const [trnsaction, setTrnsaction] = useState<any[]>([]);    
    const [loading, setLoading] = useState(false);

    const theme = useTheme(); 
    console.log('transactionId', transactionId)

    const transactionSaveSchema = Yup.object().shape({
        date: Yup.string(),
        lead_creation_date: Yup.string(),
        booking_date: Yup.string(),
        city: Yup.string(),
        lead_source: Yup.string(),
        team_leader: Yup.string(),
        closed_by: Yup.string(),
        shared_with: Yup.string(),
        developer_name: Yup.string(),
        project_name: Yup.string(),
        client_name: Yup.string(),
        contact_number: Yup.string(),
        email_id: Yup.string(),
        discount_value: Yup.string(),
        block_no: Yup.string(),
        unit_no: Yup.string(),
        floor_no: Yup.string(),
        bhk_type: Yup.string(),
        sizes: Yup.string(),
        basic_price: Yup.string(),
        frc: Yup.string(),
        plc: Yup.string(),
        car_parking_cost: Yup.string(),
        agreement_value: Yup.string(),
        pan: Yup.string(),
        dob: Yup.string(),
        doa: Yup.string(),
        correspondence_address: Yup.string(),
        Brokerage_percentage: Yup.string(),
        Brokerage_value: Yup.string(),
        discount_amount: Yup.string(),
        revenue: Yup.string(),
        aop_per: Yup.string(),
        discount_paid_status: Yup.string(),
        tds_value: Yup.string(),
        s_gst_per: Yup.string(),
        c_gst_per: Yup.string(),
        i_gst_per: Yup.string(),
        gst_amount: Yup.string(),
        gross_brokerage_value: Yup.string(),
        tds_per_ded_builder: Yup.string(),
        tds_amount: Yup.string(),
        after_tds_brokerage: Yup.string(),
        actual_receivable_amount: Yup.string(),
        incentive_per: Yup.string(),
        incentive_without_tds: Yup.string(),
        tds_per: Yup.string(),
        net_incentive_earned: Yup.string(),
        invoice_status: Yup.string(),
        raised: Yup.string(),
        pending: Yup.string(),
        payment_status: Yup.string(),
        amount_received: Yup.string(),
        receiving_date: Yup.string(),
        pending_brokerage: Yup.string(),
        s_gst_2: Yup.string(),
        c_gst_3: Yup.string(),
        i_gst_4: Yup.string(),
        gst_amount2: Yup.string(),
        gross_brokerage_value2: Yup.string(),
        tds_reducted_by_builder3: Yup.string(),
        tds_amount2: Yup.string(),
        after_tds_brokearge5: Yup.string(),
        pending_receivable_amount: Yup.string(),
    })

    console.log('transactionId', transactionId)

    const formik = useFormik({
        initialValues,
        validationSchema: transactionSaveSchema ,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
        //   console.log('lead form body');
        //   console.log(values);
        console.log('sddddddddassdsf');
          try {      

            var userId = currentUser?.id;
            var roleId = currentUser?.designation;
            
            var overview_body = {
                "shared_with":sharedWithId,
                "booking_date":values.booking_date == "Invalid date" ? '' : values.booking_date,
                "city":values.city,
                "lead_source":values.lead_source,
                "team_leader":teamLeaderId,
                "closed_by":closedById,
                "developer_name":values.developer_name,
                "project_name":values.project_name,
                "client_name":values.client_name,
                "contact_number":values.contact_number,
                "email_id":values.email_id,
                "discount_value":values.discount_value,
                "block_no":values.block_no,
                "unit_no":values.unit_no,
                "floor_no":values.floor_no,
                "bhk_type":values.bhk_type,
                "sizes":values.sizes,
                "basic_price":values.basic_price,
                "frc":values.frc,
                "plc":values.plc,
                "car_parking_cost":values.car_parking_cost,
                "agreement_value":values.agreement_value,
                "pan":values.pan,
                "dob":values.dob == "Invalid date" ? '' : values.dob,
                "doa":values.doa == "Invalid date" ? '' : values.doa,
                "correspondence_address":values.correspondence_address,
                "Brokerage_percentage":values.Brokerage_percentage,
                "Brokerage_value":values.brokerage_value,
                "discount_amount":values.discount_amount,
                "revenue":values.revenue,
                "aop_per":values.aop_per,
                "discount_paid_status":values.discount_paid_status,
                "tds_value":values.tds_value,
                "created_by":userId
                }
          
            const saveTaskData = await updateTrnsactions(transactionId, overview_body);
    
            console.log('saveTaskData');
            console.log(saveTaskData);
            // document.getElementById('kt_contact')?.classList.remove('drawer-on');
            if(saveTaskData != null){
                setLoading(false);
                document.getElementById('kt_transaction_details_close')?.click();
                document.getElementById('transaction_refresh_btn')?.click();
                var toastEl = document.getElementById('myToastUpdateTransaction');
                const bsToast = new Toast(toastEl!);
                bsToast.show();
                // resetForm();
            }
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
    }})

    const formikbd = useFormik({
        initialValues,
        validationSchema: transactionSaveSchema ,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
        //   console.log('lead form body');
        //   console.log(values);
        console.log('sddddddddassdsf');
          try {      

            var userId = currentUser?.id;
            var roleId = currentUser?.designation;
            
            var bd_body = {
                "s_gst_per": values.s_gst_per,
                "c_gst_per": values.c_gst_per,
                "i_gst_per": values.i_gst_per,
                "gst_amount": values.gst_amount,
                "gross_brokerage_value": values.gross_brokerage_value,
                "tds_per_ded_builder": values.tds_per_ded_builder,
                "tds_amount": values.tds_amount,
                "after_tds_brokerage": values.after_tds_brokerage,
                "actual_receivable_amount": values.actual_receivable_amount,
                "incentive_per": values.incentive_per,
                "incentive_without_tds": values.incentive_without_tds,
                "tds_per": values.tds_per,
                "net_incentive_earned": values.net_incentive_earned,
                "invoice_status": values.invoice_status,
                "created_by":userId
                }
          
            const saveTaskData = await updateTrnsactionsBD(transactionId, bd_body);
    
            console.log('saveTaskData');
            console.log(saveTaskData);
            // document.getElementById('kt_contact')?.classList.remove('drawer-on');
            if(saveTaskData != null){
                setLoading(false);
                document.getElementById('kt_transaction_details_close')?.click();
                document.getElementById('transaction_refresh_btn')?.click();
                var toastEl = document.getElementById('myToastUpdateTransaction');
                const bsToast = new Toast(toastEl!);
                bsToast.show();
                // resetForm();
            }
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
    }})

    const formikid = useFormik({
        initialValues,
        validationSchema: transactionSaveSchema ,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
        //   console.log('lead form body');
        //   console.log(values);
        console.log('sddddddddassdsf');
          try {      

            var userId = currentUser?.id;
            var roleId = currentUser?.designation;
            
            var id_body = {
                "raised": values.raised,
                "pending": values.pending,
                "payment_status": values.payment_status,
                "amount_received": values.amount_received,
                "receiving_date": values.receiving_date == "Invalid date" ? '' : values.receiving_date,
                "pending_brokerage": values.pending_brokerage,
                "s_gst_2": values.s_gst_2,
                "c_gst_3": values.c_gst_3,
                "i_gst_4": values.i_gst_4,
                "gst_amount2": values.gst_amount2,
                "gross_brokerage_value2": values.gross_brokerage_value2,
                "tds_reducted_by_builder3": values.tds_reducted_by_builder3,
                "tds_amount2": values.tds_amount2,
                "after_tds_brokearge5": values.after_tds_brokearge5,
                "pending_receivable_amount": values.pending_receivable_amount,
                "created_by":userId
                }
          
            const saveTaskData = await updateTrnsactionsID(transactionId, id_body);
    
            console.log('saveTaskData');
            console.log(saveTaskData);
            // document.getElementById('kt_contact')?.classList.remove('drawer-on');
            if(saveTaskData != null){
                setLoading(false);
                document.getElementById('kt_transaction_details_close')?.click();
                document.getElementById('transaction_refresh_btn')?.click();
                var toastEl = document.getElementById('myToastUpdateTransaction');
                const bsToast = new Toast(toastEl!);
                bsToast.show();
                // resetForm();
            }
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
    }})

    const LeadSourceList =  async () => {
        const LeadSourceResponse = await getLeadSource()        
        setLeadSource(LeadSourceResponse);
    }

    const projectList =  async () => {
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const projectResponse = await getProperties(userId, roleId);
        setProjects(projectResponse);
    }

    const UnitTypeList =  async () => {
        const UnitTypeResponse = await getUnitType()        
        setUnitType(UnitTypeResponse);
    }  
    
    const CityList =  async () => {
        const CityResponse = await getCity()
        setCity(CityResponse);
    }

    const transactionDetail =  async () => {
        setLoading(true)
        const Response = await getTrnsaction(transactionId)
        setTrnsaction(Response);
        console.log('getTrnsaction', Response)


            // formik.setFieldValue('shared_with', Response.shared_with);
            formik.setFieldValue('booking_date', Moment(Response.booking_date ?? '').format('YYYY-MM-DD'));
            formik.setFieldValue('city', Response.city ?? '');
            formik.setFieldValue('lead_source', Response.lead_source ?? '');
            // formik.setFieldValue('team_leader', Response.team_leader ?? '');
            // formik.setFieldValue('closed_by', Response.closed_by ?? '');
            formik.setFieldValue('developer_name', Response.developer_name ?? '');
            formik.setFieldValue('project_name', Response.project_name ?? '');
            formik.setFieldValue('client_name', Response.client_name ?? '');
            formik.setFieldValue('contact_number', Response.contact_number ?? '');
            formik.setFieldValue('email_id', Response.email_id ?? '');
            formik.setFieldValue('discount_value', Response.discount_value ?? '');
            formik.setFieldValue('block_no', Response.block_no ?? '');
            formik.setFieldValue('unit_no', Response.unit_no ?? '');
            formik.setFieldValue('floor_no', Response.floor_no ?? '');
            formik.setFieldValue('bhk_type', Response.bhk_type ?? '');
            formik.setFieldValue('sizes', Response.sizes ?? '');
            formik.setFieldValue('basic_price', Response.basic_price ?? '');
            formik.setFieldValue('frc', Response.frc ?? '');
            formik.setFieldValue('plc', Response.plc ?? '');
            formik.setFieldValue('car_parking_cost', Response.car_parking_cost ?? '');
            formik.setFieldValue('agreement_value', Response.agreement_value ?? '');
            formik.setFieldValue('pan', Response.pan ?? '');
            formik.setFieldValue('dob', Moment(Response.dob ?? '').format('YYYY-MM-DD'));
            formik.setFieldValue('doa', Moment(Response.doa ?? '').format('YYYY-MM-DD'));
            formik.setFieldValue('correspondence_address', Response.correspondence_address ?? '');
            formik.setFieldValue('Brokerage_percentage', Response.Brokerage_percentage ?? '');
            formik.setFieldValue('brokerage_value', Response.Brokerage_value ?? '');
            formik.setFieldValue('discount_amount', Response.discount_amount ?? '');
            formik.setFieldValue('revenue', Response.revenue ?? '');
            formik.setFieldValue('aop_per', Response.aop_per ?? '');
            formik.setFieldValue('discount_paid_status', Response.discount_paid_status ?? '');
            formik.setFieldValue('tds_value', Response.tds_value ?? '');

            var sharedWithArray = [];
            var sharedWithNameArray = [];
            if(Response.shared_with != null){
                sharedWithArray = Response.shared_with.split(",").map((e:any) => {
                    return parseInt(e);
                });
            }
            if(Response.shared_with_name != null){
                sharedWithNameArray = Response.shared_with_name.split(",").map((e:any) => {
                    return e;
                });
            }

            setSharedWithId(sharedWithArray);
            setSharedWithName(sharedWithNameArray);


            var teamLeaderArray = [];
            var teamLeaderNameArray = [];
            if(Response.team_leader != null){
                teamLeaderArray = Response.team_leader.split(",").map((e:any) => {
                    return parseInt(e);
                });
            }
            if(Response.team_leader_name != null){
                teamLeaderNameArray = Response.team_leader_name.split(",").map((e:any) => {
                    return e;
                });
            }

            setTeamLeaderId(teamLeaderArray);
            setTeamLeaderName(teamLeaderNameArray);


            var closedByArray = [];
            var closedByNameArray = [];
            if(Response.closed_by != null){
                closedByArray = Response.closed_by.split(",").map((e:any) => {
                    return parseInt(e);
                });
            }
            if(Response.closed_by_name != null){
                closedByNameArray = Response.closed_by_name.split(",").map((e:any) => {
                    return e;
                });
            }

            setClosedById(closedByArray);
            setClosedByName(closedByNameArray);


            formikbd.setFieldValue('s_gst_per', Response.s_gst_per ?? '');
            formikbd.setFieldValue('c_gst_per', Response.c_gst_per ?? '');
            formikbd.setFieldValue('i_gst_per', Response.i_gst_per ?? '');
            formikbd.setFieldValue('gst_amount', Response.gst_amount ?? '');
            formikbd.setFieldValue('gross_brokerage_value', Response.gross_brokerage_value ?? '');
            formikbd.setFieldValue('tds_per_ded_builder', Response.tds_per_ded_builder ?? '');
            formikbd.setFieldValue('tds_amount', Response.tds_amount ?? '');
            formikbd.setFieldValue('after_tds_brokerage', Response.after_tds_brokerage ?? '');
            formikbd.setFieldValue('actual_receivable_amount', Response.actual_receivable_amount ?? '');
            formikbd.setFieldValue('incentive_per', Response.incentive_per ?? '');
            formikbd.setFieldValue('incentive_without_tds', Response.incentive_without_tds ?? '');
            formikbd.setFieldValue('tds_per', Response.tds_per ?? '');
            formikbd.setFieldValue('net_incentive_earned', Response.net_incentive_earned ?? '');
            formikbd.setFieldValue('invoice_status', Response.invoice_status ?? '');


            formikid.setFieldValue('raised', Response.raised ?? '');
            formikid.setFieldValue('pending', Response.pending ?? '');
            formikid.setFieldValue('payment_status', Response.payment_status ?? '');
            formikid.setFieldValue('amount_received', Response.amount_received ?? '');
            formikid.setFieldValue('receiving_date', Moment(Response.receiving_date ?? '').format('YYYY-MM-DD'));
            formikid.setFieldValue('pending_brokerage', Response.pending_brokerage ?? '');
            formikid.setFieldValue('s_gst_2', Response.s_gst_2 ?? '');
            formikid.setFieldValue('c_gst_3', Response.c_gst_3 ?? '');
            formikid.setFieldValue('i_gst_4', Response.i_gst_4 ?? '');
            formikid.setFieldValue('gst_amount2', Response.gst_amount2 ?? '');
            formikid.setFieldValue('gross_brokerage_value2', Response.gross_brokerage_value2 ?? '');
            formikid.setFieldValue('tds_reducted_by_builder3', Response.tds_reducted_by_builder3 ?? '');
            formikid.setFieldValue('tds_amount2', Response.tds_amount2 ?? '');
            formikid.setFieldValue('after_tds_brokearge5', Response.after_tds_brokearge5 ?? '');
            formikid.setFieldValue('pending_receivable_amount', Response.pending_receivable_amount ?? '');


            setLoading(false)
    }

    const handleBrokerage = (brokerage:any) => {
        formik.setFieldValue('Brokerage_percentage', brokerage ?? '');
        let agreementValue:any = (document.getElementById('agreement_value') as HTMLInputElement).value;
        let brokerageValue:any = agreementValue * brokerage;
        formik.setFieldValue('brokerage_value', brokerageValue ?? '');
    }

    const handleDiscount = (discount:any) => {
        formik.setFieldValue('discount_amount', discount ?? '');
        let brokerageValue:any = (document.getElementById('brokerage_value') as HTMLInputElement).value;
        let revenue:any = brokerageValue - discount;
        formik.setFieldValue('revenue', revenue ?? '');
    }

    const teamLeaderChange = (event: SelectChangeEvent<typeof teamLeaderName>) => {
        const {
          target: { value },
        } = event;

        var name = [];
        var id = [];
    
        for(let i = 0; i < value.length; i++){
          var fields = value[i].split('-');
    
          var n = fields[0];
          var d = fields[1];
    
          name.push(n);
          id.push(d);
        }
    
        console.log(name);
        console.log(id);
    
        setTeamLeaderId(id);

        setTeamLeaderName(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
    };
    const sharedWithChange = (event: SelectChangeEvent<typeof sharedWithName>) => {
        const {
          target: { value },
        } = event;

        var name = [];
        var id = [];
    
        for(let i = 0; i < value.length; i++){
          var fields = value[i].split('-');
    
          var n = fields[0];
          var d = fields[1];
    
          name.push(n);
          id.push(d);
        }
    
        console.log(name);
        console.log(id);
    
        setSharedWithId(id);

        setSharedWithName(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
    };
    const closedByChange = (event: SelectChangeEvent<typeof closedByName>) => {
        const {
          target: { value },
        } = event;

        var name = [];
        var id = [];
    
        for(let i = 0; i < value.length; i++){
          var fields = value[i].split('-');
    
          var n = fields[0];
          var d = fields[1];
    
          name.push(n);
          id.push(d);
        }
    
        console.log(name);
        console.log(id);
    
        setClosedById(id);

        setClosedByName(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
    };

    const closeDialogue = () => {
        setDetailClicked(false);
        var offCanvasEl = document.getElementById('kt_expand'+transactionId);
        offCanvasEl?.classList.add('invisible');
        const bsOffcanvas = new Offcanvas(offCanvasEl!);
        bsOffcanvas.hide();
    }

    const TeamLeaderList =  async () => {
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const Response = await getTrnsactionTLDrop(userId, roleId)
        setTeamLeader(Response);
    }

    const usersList =  async () => {
        const Response = await getAllUsers()
        setUsers(Response);
    }

    const minimaximize = () => {
        setIsExpand(current => !current);
    }
    
    const fullScreenChange = () => {
        setIsFullScreen(current => !current);
    }    

 const developerNames =  async () => {  
    var userId = currentUser?.id;
    var roleId = currentUser?.designation;   
    const contactDropResponse = await getDeveloperNameList(userId, roleId)
    setDeveloperNameList(contactDropResponse);
}

const sum = (a:any, b:any) => {
    let c = parseInt(a) + parseInt(b);
    return c;
}

    useEffect(() => {
        LeadSourceList();
        developerNames();
        TeamLeaderList();
        usersList();
        projectList();
        UnitTypeList();
        CityList();
        transactionId && transactionDetail();
    }, [transactionId]);

    return(
        <div className="w-lg-75 w-md-100 contact_details_page full_screen m-6">
            {
            loading ? 
            <div className="card main_bg h-100">
                <div className='w-100 h-100'>
                    <div className='d-flex justify-content-center flex-column align-items-center vh-100'>
                        <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
                        <div className="spinner-border taskloader" style={{width:"3rem", height: "3rem"}} role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div> 
                </div> 
            </div> : isExpand ?
            <div className="card main_bg h-100">
                <div className="card-header d-flex align-items-center">
                    <div className="w-100">
                        <div className="d-flex justify-content-end">
                            <div className='card-toolbar'>
                                <button className="btn mx-3 expand_btn" onClick={fullScreenChange}>
                                    <img src={isFullScreen ? toAbsoluteUrl('/media/custom/overview-icons/comp_white.svg') : toAbsoluteUrl('/media/custom/overview-icons/expand_white.svg')} className="svg_icon" alt='' />
                                </button>
                                <button className="btn mx-3 minimize_btn" onClick={minimaximize}>
                                    <img src={toAbsoluteUrl('/media/custom/overview-icons/minimize_white.svg')} className="svg_icon" alt='' />
                                </button>
                                <button
                                    type='button'
                                    className='btn mx-3 close_btn'
                                    id='kt_transaction_details_close'
                                    onClick={closeDialogue}
                                    >
                                        <img src={toAbsoluteUrl('/media/custom/overview-icons/cancel_white.svg')} className="svg_icon" alt='' />
                                </button>
                            </div>
                        </div>
                        {/* <div className="col-sm-9 col-12 d-flex align-items-center justify-content-end">
                            <div className="input-group search_btn me-3">
                                <input type="text" className="form-control" placeholder="Search"/>
                                <div className="input-group-setFieldValue">
                                    <button className="btn bg_primary" type="button">
                                        <img src={toAbsoluteUrl('/media/custom/search_white.svg')} className="svg_icon" alt='' />
                                    </button>
                                </div>
                            </div>
                            <a className="me-4 btn btn-sm me-4 btn_primary" id='kt_contact_toggle'>Add+</a>
                            <select className="form-select toggle_white me-4 btn btn-sm btn_primary" name="action" id="action">
                                <option value="action">Action</option>
                                <option value="add">Add</option>
                                <option value="inport">Import</option>
                                <option value="edit">Edit</option>
                            </select>
                        </div> */}
                    </div>
                    {/* <h3>Contact Full screen</h3> */}
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="card-group">
                            <div className="col-xxl-6 col-12 mb-3">
                                <div className="card bs_1 name_card h-100 mx-2">
                                    <div className="card-body p-3">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0">
                                                <img src={toAbsoluteUrl('/media/avatars/300-23.jpg')} className="user_img" alt='' />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="d-flex">
                                                            <p className="mb-0">789658</p>
                                                            <h4 className="mb-0 ms-2">Mr.Raj</h4>
                                                        </div>
                                                        <p className="mb-0">Brigade</p>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <a href="#" className="btn_soft_primary"><i className="fas fa-envelope"></i></a>
                                                        <a href="#" className="btn_soft_primary"><i className="fas fa-phone-alt"></i></a>
                                                        <a href="#" className="btn_soft_primary"><i className="fas fa-clipboard-list"></i></a>
                                                        <a href="#" className="btn_soft_primary">
                                                            <img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} className="svg_icon" alt='' />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-6 col-12 mb-3">
                                <div className="card bs_1 h-100 mx-2 info_card">
                                    <div className="card-body p-3">
                                        <div className='row w-100 p-3'>
                                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 p-2">
                                                <small className="mb-0">Email</small>
                                                <p className="mb-0">raj2002@gmail.com</p>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 p-2">
                                                <small className="mb-0">Phone Number</small>
                                                <p className="mb-0">9856895689</p>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 p-2">
                                                <small className="mb-0">Contact Category</small>
                                                <p className="mb-0">Buyer</p>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 p-2">
                                                <small className="mb-0">Created By</small>
                                                <p className="mb-0">Kumar</p>
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab_container bg_white br_10 bs_1">
                        <div className="row mt-4">
                            <div className="col-12">
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="overview-tab" data-bs-toggle="pill" data-bs-target="#overview" type="button" role="tab" aria-controls="overview" aria-selected="true">Overview</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="notes-tab" data-bs-toggle="pill" data-bs-target="#notes" type="button" role="tab" aria-controls="notes" aria-selected="false">Notes</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="files-tab" data-bs-toggle="pill" data-bs-target="#files" type="button" role="tab" aria-controls="files" aria-selected="false">Files</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="message-tab" data-bs-toggle="pill" data-bs-target="#message" type="button" role="tab" aria-controls="message" aria-selected="false">Messages</button>
                                    </li>
                                    {/* <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="brokerage_details-tab" data-bs-toggle="pill" data-bs-target="#brokerage_details" type="button" role="tab" aria-controls="brokerage_details" aria-selected="false">Brokerage Details</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="invoice_details-tab" data-bs-toggle="pill" data-bs-target="#invoice_details" type="button" role="tab" aria-controls="invoice_details" aria-selected="false">Invoicing Details</button>
                                    </li> */}
                                    {/* <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="duplicate-tab" data-bs-toggle="pill" data-bs-target="#duplicate" type="button" role="tab" aria-controls="duplicate" aria-selected="false">Duplicate</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="tasks-tab" data-bs-toggle="pill" data-bs-target="#tasks" type="button" role="tab" aria-controls="tasks" aria-selected="false">Tasks</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="timeline-tab" data-bs-toggle="pill" data-bs-target="#timeline" type="button" role="tab" aria-controls="timeline" aria-selected="false">Active timeline</button>
                                    </li> */}
                                </ul>
                                <div className="tab-content pt-5" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                                        <form noValidate onSubmit={formik.handleSubmit}>
                                            <div className="row">
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Booking Date</label>
                                                    <div className="input-group mb-3">
                                                        <input type="date" {...formik.getFieldProps('booking_date')} className="form-control"/> 
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">City</label>
                                                    <div className="input-group mb-3">
                                                        <select disabled className="btn btn-sm w-100 text-start form-select h-42px" {...formik.getFieldProps('city')}>
                                                        <option value=''>Select</option>
                                                            {city.map((taskProject,i) =>{
                                                                return (
                                                                    <option selected={i == 0 ? true: false} value={taskProject.id} key={i}>{taskProject.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Lead Source</label>
                                                    <div className="input-group mb-3">
                                                        <select disabled className="btn btn-sm w-100 text-start form-select h-42px" {...formik.getFieldProps('lead_source')}>
                                                        <option value=''>Select</option>
                                                            {leadSource.map((task,i) =>{
                                                                return (
                                                                    <option selected={i == 0 ? true: false} value={task.id} key={i}>{task.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3 edit_page_form">
                                                    <label htmlFor="basic-url" className="form-label">Team Leader</label>
                                                    <FormControl sx={{ m: 0, width: '100%', mt: 0 }}>
                                                        <Select
                                                            className='input_prepend'
                                                            multiple
                                                            displayEmpty
                                                            value={teamLeaderName}
                                                            onChange={teamLeaderChange}
                                                            input={<OutlinedInput className='input_prepend' />}
                                                            renderValue={(selected) => {
                                                                var name = [];
                                                                var id = [];

                                                                for (let i = 0; i < selected.length; i++) {
                                                                    var fields = selected[i].split('-');

                                                                    var n = fields[0];
                                                                    var d = fields[1];

                                                                    name.push(n);
                                                                    id.push(d);
                                                                }

                                                                // setAssignToId(id);
                                                                if (selected.length === 0) {
                                                                    return <p>Team Leader</p>;
                                                                }

                                                                return name.join(', ');
                                                            } }
                                                            MenuProps={MenuProps}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                        >
                                                            <MenuItem disabled value="">
                                                                <em>Team Leader</em>
                                                            </MenuItem>
                                                            {teamLeader.map((assignVal) => (
                                                                <MenuItem
                                                                    key={assignVal.team_leader}
                                                                    value={assignVal.team_leader_name + '-' + assignVal.team_leader}
                                                                    style={getStyles(assignVal.team_leader_name, teamLeaderName, theme)}
                                                                >
                                                                    {assignVal.team_leader_name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3 edit_page_form">
                                                    <label htmlFor="basic-url" className="form-label">Shared With</label>
                                                    <FormControl sx={{ m: 0, width: '100%', mt: 0 }}>
                                                        <Select
                                                            multiple
                                                            displayEmpty
                                                            value={sharedWithName}
                                                            onChange={sharedWithChange}
                                                            input={<OutlinedInput />}
                                                            renderValue={(selected) => {
                                                                var name = [];
                                                                var id = [];

                                                                for (let i = 0; i < selected.length; i++) {
                                                                    var fields = selected[i].split('-');

                                                                    var n = fields[0];
                                                                    var d = fields[1];

                                                                    name.push(n);
                                                                    id.push(d);
                                                                }

                                                                // setAssignToId(id);
                                                                if (selected.length === 0) {
                                                                    return <p>Shared With</p>;
                                                                }

                                                                return name.join(', ');
                                                            } }
                                                            className='multi_select_field'
                                                            MenuProps={MenuProps}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                        >
                                                            <MenuItem disabled value="">
                                                                <em>Shared With</em>
                                                            </MenuItem>
                                                            {users.map((assignVal) => (
                                                                <MenuItem
                                                                    className=''
                                                                    key={assignVal.user_id}
                                                                    value={assignVal.employee_name + '-' + assignVal.user_id}
                                                                    style={getStyles(assignVal.employee_name, sharedWithName, theme)}
                                                                >
                                                                    {assignVal.employee_name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    {/* <FormControl sx={{ m: 0, width: "100%", mt: 0 }}>
                                                        <Select
                                                            multiple
                                                            displayEmpty
                                                            value={sharedWithName}
                                                            onChange={sharedWithChange}
                                                            input={<OutlinedInput />}
                                                            renderValue={(selected) => {
                                                                // selected = assignToName;
                                                                var name = [];
                                                                var id = [];

                                                                for(let i = 0; i < selected.length; i++){
                                                                    var fields = selected[i].split('-');

                                                                    var n = fields[0];
                                                                    var d = fields[1];

                                                                    name.push(n);
                                                                    id.push(d);
                                                                }
                                                                if (selected.length === 0) {
                                                                return <p>Assign To</p>;
                                                                }

                                                                return name.join(', ');
                                                            }}
                                                            className='multi_select_field form-control'
                                                            MenuProps={MenuProps}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                            >
                                                            <MenuItem  value="as1">
                                                                <em>Assign To</em>
                                                            </MenuItem>
                                                            {users.map((user) => (
                                                                <MenuItem
                                                                key={user.employee_name+ '-' +user.user_id}
                                                                value={user.employee_name+ '-' +user.id}
                                                                style={getStyles(user.employee_name, sharedWithName, theme)}
                                                                >
                                                                {user.employee_name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl> */}
                                                </div>
                                                {/* <div className="col-md-6 col-12 mb-3 edit_page_form">
                                                    <label htmlFor="basic-url" className="form-label">Closed by</label>
                                                    <FormControl sx={{ m: 0, width: '100%', mt: 0 }}>
                                                        <Select
                                                            multiple
                                                            displayEmpty
                                                            value={closedByName}
                                                            onChange={closedByChange}
                                                            input={<OutlinedInput />}
                                                            renderValue={(selected) => {
                                                                var name = [];
                                                                var id = [];

                                                                for (let i = 0; i < selected.length; i++) {
                                                                    var fields = selected[i].split('-');

                                                                    var n = fields[0];
                                                                    var d = fields[1];

                                                                    name.push(n);
                                                                    id.push(d);
                                                                }

                                                                // setAssignToId(id);
                                                                if (selected.length === 0) {
                                                                    return <p>Closed by</p>;
                                                                }

                                                                return name.join(', ');
                                                            } }
                                                            className='multi_select_field'
                                                            MenuProps={MenuProps}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                        >
                                                            <MenuItem disabled value="">
                                                                <em>Closed by</em>
                                                            </MenuItem>
                                                            {users.map((assignVal) => (
                                                                <MenuItem
                                                                    className=''
                                                                    key={assignVal.user_id}
                                                                    value={assignVal.employee_name + '-' + assignVal.user_id}
                                                                    style={getStyles(assignVal.employee_name, closedByName, theme)}
                                                                >
                                                                    {assignVal.employee_name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div> */}
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Developer Name</label>
                                                    <div className="input-group mb-3">
                                                        <select disabled className="btn btn-sm w-100 text-start form-select h-42px" {...formik.getFieldProps('developer_name')}>
                                                        <option value=''>Select</option>
                                                            {developerNameList.map((task,i) =>{
                                                                return (
                                                                    <option selected={i == 0 ? true: false} value={task.id} key={i}>{task.developer_fullname}</option> 
                                                            )})}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Project Name</label>
                                                    <div className="input-group mb-3">
                                                        <select disabled className="btn btn-sm w-100 text-start form-select h-42px" {...formik.getFieldProps('project_name')}>
                                                        <option value=''>Select</option>
                                                            {projects.map((task,i) =>{
                                                                return (
                                                                    <option selected={i == 0 ? true: false} value={task.id} key={i}>{task.name_of_building}</option> 
                                                            )})}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Client Name</label>
                                                    <div className="input-group mb-3">
                                                        <select disabled className="btn btn-sm w-100 text-start form-select h-42px" {...formik.getFieldProps('client_name')}>
                                                        <option value=''>Select</option>
                                                            {taskType.map((task,i) =>{
                                                                return (
                                                                    <option selected={i == 0 ? true: false} value={task.id} key={i}>{task.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Contact Number</label>
                                                    <div className="input-group mb-3">
                                                        <input disabled type="number" {...formik.getFieldProps('contact_number')} className="form-control"/> 
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Email</label>
                                                    <div className="input-group mb-3">
                                                        <input disabled type="email" {...formik.getFieldProps('email_id')} className="form-control"/> 
                                                    </div>
                                                </div>                                                
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Tower/Block (No. /Name)</label>
                                                    <div className="input-group mb-3">
                                                        <input type="text" {...formik.getFieldProps('block_no')} className="form-control"/> 
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Unit Number</label>
                                                    <div className="input-group mb-3">
                                                        <input type="text" {...formik.getFieldProps('unit_no')} className="form-control"/> 
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Floor Number</label>
                                                    <div className="input-group mb-3">
                                                        <input type="text" {...formik.getFieldProps('floor_no')} className="form-control"/> 
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">BHK Type</label>
                                                    <div className="input-group mb-3">
                                                        <input type="text" {...formik.getFieldProps('bhk_type')} className="form-control"/> 
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">BHK Type</label>
                                                    <div className="input-group mb-3">
                                                        <select className="btn btn-sm w-100 text-start form-select h-42px" {...formik.getFieldProps('bhk_type')}>
                                                        <option value=''>Select</option>
                                                            {unitType.map((taskPrior,i) =>{
                                                                return (
                                                                    <option selected={i == 0 ? true: false} value={taskPrior.id} key={i}>{taskPrior.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div>
                                                </div> */}
                                                {/* <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Unit Size</label>
                                                    <div className="input-group mb-3">
                                                        <select className="btn btn-sm w-100 text-start form-select h-42px" {...formik.getFieldProps('sizes')}>
                                                        <option value=''>Select</option>
                                                            {taskPriority.map((taskPrior,i) =>{
                                                                return (
                                                                    <option selected={i == 0 ? true: false} value={taskPrior.id} key={i}>{taskPrior.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div>
                                                </div> */}
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Unit Size</label>
                                                    <div className="input-group mb-3">
                                                        <input type="text" {...formik.getFieldProps('sizes')} className="form-control"/> 
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Basic Price</label>
                                                    <div className="input-group mb-3">
                                                        <input type="text" {...formik.getFieldProps('basic_price')} className="form-control"/> 
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">FRC</label>
                                                    <div className="input-group mb-3">
                                                        <input type="text" {...formik.getFieldProps('frc')} className="form-control"/> 
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">PLC</label>
                                                    <div className="input-group mb-3">
                                                        <input type="text" {...formik.getFieldProps('plc')} className="form-control"/> 
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Car Parking Cost</label>
                                                    <div className="input-group mb-3">
                                                        <input type="text" {...formik.getFieldProps('car_parking_cost')} className="form-control"/> 
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">PAN Number</label>
                                                    <div className="input-group mb-3">
                                                        <input type="text" {...formik.getFieldProps('pan')} className="form-control"/> 
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Date of Birth</label>
                                                    <div className="input-group mb-3">
                                                        <input disabled type="date" {...formik.getFieldProps('dob')} className="form-control"/> 
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Anniversary Date</label>
                                                    <div className="input-group mb-3">
                                                        <input disabled type="date" {...formik.getFieldProps('doa')} className="form-control"/> 
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Agreement Value</label>
                                                    <div className="input-group mb-3">
                                                        <input type="number" {...formik.getFieldProps('agreement_value')} className="form-control" id="agreement_value" onChange={(e:any) => {
                                                            // setAgreementValue(e.target.value); 
                                                            formik.setFieldValue('agreement_value', e.target.value ?? '')
                                                            let brokeragePer:any = (document.getElementById('Brokerage_percentage') as HTMLInputElement)?.value;
                                                            let aopPer:any = (document.getElementById('aop_per') as HTMLInputElement)?.value;
                                                            let discPer:any = (document.getElementById('discount_value') as HTMLInputElement)?.value;
                                                            let brokerageValue:any = e.target.value * sum(brokeragePer, aopPer) / 100;
                                                            formik.setFieldValue('Brokerage_value', brokerageValue ?? ''); 
                                                            let discountAmount:any = brokerageValue * discPer / 100;
                                                            formik.setFieldValue('discount_amount', discountAmount ?? '');  
                                                            formik.setFieldValue('tds_value', discountAmount * 5 / 100);  
                                                            let revenue:any = brokerageValue - discountAmount - (discountAmount * 5 / 100);
                                                            formik.setFieldValue('revenue', revenue ?? '');                                                        
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Brokerage %</label>
                                                    <div className="input-group mb-3">
                                                        <input type="number" {...formik.getFieldProps('Brokerage_percentage')} onChange={(e:any) => {
                                                            formik.setFieldValue('Brokerage_percentage', e.target.value ?? '');
                                                            let agreementValue:any = (document.getElementById('agreement_value') as HTMLInputElement)?.value;
                                                            let aopPer:any = (document.getElementById('aop_per') as HTMLInputElement)?.value;
                                                            let discPer:any = (document.getElementById('discount_value') as HTMLInputElement)?.value;
                                                            let brokerageValue:any = agreementValue * sum(e.target.value, aopPer) / 100;
                                                            formik.setFieldValue('Brokerage_value', brokerageValue ?? '');
                                                            let discountAmount:any = brokerageValue * discPer / 100;
                                                            formik.setFieldValue('discount_amount', discountAmount ?? ''); 
                                                            formik.setFieldValue('tds_value', discountAmount * 5 / 100);
                                                            let revenue:any = brokerageValue - discountAmount - (discountAmount * 5 / 100);
                                                            formik.setFieldValue('revenue', revenue ?? '');
                                                        }} className="form-control" id='Brokerage_percentage'/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">AOP %</label>
                                                    <div className="input-group mb-3">
                                                        <input type="number" {...formik.getFieldProps('aop_per')} className="form-control" id='aop_per' onChange={(e:any) => {
                                                            // setAgreementValue(e.target.value); 
                                                            formik.setFieldValue('aop_per', e.target.value ?? '')
                                                            let brokeragePer:any = (document.getElementById('Brokerage_percentage') as HTMLInputElement)?.value;
                                                            let agreementval:any = (document.getElementById('agreement_value') as HTMLInputElement)?.value;
                                                            let discPer:any = (document.getElementById('discount_value') as HTMLInputElement)?.value;
                                                            let brokerageValue:any = agreementval * sum(brokeragePer, e.target.value) / 100;
                                                            formik.setFieldValue('Brokerage_value', brokerageValue ?? '');  
                                                            let discountAmount:any = brokerageValue * discPer / 100;
                                                            formik.setFieldValue('discount_amount', discountAmount ?? ''); 
                                                            formik.setFieldValue('tds_value', discountAmount * 5 / 100); 
                                                            let revenue:any = brokerageValue - discountAmount - (discountAmount * 5 / 100);
                                                            formik.setFieldValue('revenue', revenue ?? '');                                                        
                                                        }}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Brokerage Value</label>
                                                    <div className="input-group mb-3">
                                                        <input type="number" {...formik.getFieldProps('Brokerage_value')} className="form-control" id="brokerage_value" onChange={(e:any) => {
                                                            formik.setFieldValue('Brokerage_value', e.target.value ?? '')
                                                            let discPer:any = (document.getElementById('discount_value') as HTMLInputElement)?.value;
                                                            let discountAmount:any = e.target.value * discPer / 100;
                                                            formik.setFieldValue('discount_amount', discountAmount ?? '');
                                                            formik.setFieldValue('tds_value', discountAmount * 5 / 100);
                                                            let revenue:any = e.target.value - discountAmount - (discountAmount * 5 / 100);
                                                            formik.setFieldValue('revenue', revenue ?? '');
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Discount % <small>(if any)</small></label>
                                                    <div className="input-group mb-3">
                                                        <input type="number" {...formik.getFieldProps('discount_value')} className="form-control" id='discount_value' onChange={(e:any) => {
                                                            formik.setFieldValue('discount_value', e.target.value ?? '')
                                                            let brokeragePer:any = (document.getElementById('Brokerage_percentage') as HTMLInputElement)?.value;
                                                            let agreementval:any = (document.getElementById('agreement_value') as HTMLInputElement)?.value;
                                                            let aopPer:any = (document.getElementById('aop_per') as HTMLInputElement)?.value;
                                                            let brokerageValue:any = agreementval * sum(brokeragePer, aopPer) / 100;
                                                            formik.setFieldValue('Brokerage_value', brokerageValue ?? ''); 
                                                            let discountAmount:any = brokerageValue * e.target.value / 100;
                                                            formik.setFieldValue('discount_amount', discountAmount ?? '');
                                                            formik.setFieldValue('tds_value', discountAmount * 5 / 100);
                                                            let revenue:any = brokerageValue - discountAmount - (discountAmount * 5 / 100);
                                                            formik.setFieldValue('revenue', revenue ?? '');
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Discount Amount</label>
                                                    <div className="input-group mb-3">
                                                        <input type="number" {...formik.getFieldProps('discount_amount')} className="form-control" id='discount_amount' onChange={(e:any) => {
                                                            formik.setFieldValue('discount_amount', e.target.value ?? '');
                                                            let brokerageValue:any = (document.getElementById('brokerage_value') as HTMLInputElement).value;
                                                            let revenue:any = brokerageValue - e.target.value - (e.target.value * 5 / 100);
                                                            formik.setFieldValue('revenue', revenue ?? '');
                                                            formik.setFieldValue('tds_value', e.target.value * 5 / 100);
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">TDS Value @ 5%</label>
                                                    <div className="input-group mb-3">
                                                        <input disabled type="number" {...formik.getFieldProps('tds_value')} className="form-control" id='tds_value' />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Discount Paid</label>
                                                    <div className="input-group mb-3">
                                                        {/* <input type="text" {...formik.getFieldProps('discount_amount')} className="form-control" onChange={(e) => handleDiscount(e.target.value)} /> */}
                                                        <select className="btn btn-sm w-100 text-start form-select h-42px" {...formik.getFieldProps('discount_paid_status')}>
                                                            <option value=''>Select</option>
                                                            <option value='1'>Yes</option>
                                                            <option value='0'>No</option>                                                 
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Revenue</label>
                                                    <div className="input-group mb-3">
                                                        <input type="number" {...formik.getFieldProps('revenue')} className="form-control" />
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className='d-flex justify-content-center mb-6'>           
                                                <button
                                                type='submit'
                                                id='kt_sign_up_submit'
                                                className='btn btn_primary text-primary'
                                                disabled={formik.isSubmitting}
                                                >
                                                {!loading && <span className='indicator-label'>Submit
                                                <KTSVG
                                                path='/media/custom/save_white.svg'
                                                className='svg-icon-3 svg-icon-primary ms-2'
                                                />
                                                </span>}
                                                {loading && (
                                                    <span className='indicator-progress' style={{display: 'block'}}>
                                                    Please wait...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="tab-pane fade" id="notes" role="tabpanel" aria-labelledby="notes-tab">
                                    <div className="d-flex flex-column flex-center mb-9">   
                                        <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
                                        <h2>Under Construction</h2>
                                    </div>
                                    </div>
                                    <div className="tab-pane fade" id="files" role="tabpanel" aria-labelledby="files-tab">
                                    <div className="d-flex flex-column flex-center mb-9">   
                                        <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
                                        <h2>Under Construction</h2>
                                    </div>
                                    </div>
                                    <div className="tab-pane fade" id="message" role="tabpanel" aria-labelledby="message-tab">
                                        {/* <h3>Messages</h3> */}
                                        <div className="d-flex flex-column flex-center mb-9">   
                                        <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
                                        <h2>Under Construction</h2>
                                    </div>
                                        <ul className="nav nav-pills mb-3 message_tabs" id="pills-tab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id="pills-mail-tab" data-bs-toggle="pill" data-bs-target="#pills-mail" type="button" role="tab" aria-controls="pills-mail" aria-selected="true">Email</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="pills-whatsapp-tab" data-bs-toggle="pill" data-bs-target="#pills-whatsapp" type="button" role="tab" aria-controls="pills-whatsapp" aria-selected="false">Whatsapp</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="pills-sms-tab" data-bs-toggle="pill" data-bs-target="#pills-sms" type="button" role="tab" aria-controls="pills-sms" aria-selected="false">Sms</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="pills-calls-tab" data-bs-toggle="pill" data-bs-target="#pills-calls" type="button" role="tab" aria-controls="pills-calls" aria-selected="false">Calls</button>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="pills-tabContent">
                                            <div className="tab-pane fade show active" id="pills-mail" role="tabpanel" aria-labelledby="pills-mail-tab">
                                                <div className="mt-4">
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                <div className="col-lg-1  d-flex justify-content-lg-center my-2">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 my-2">
                                                                    <span className="mail_icon"><i className="fas fa-envelope"></i></span>
                                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                        <p>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                    <div className="w-100 d-flex flex-wrap mt-2">
                                                                        <a href="" className="d-flex mail_format me-2 mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.wrd</p>
                                                                        </a> 
                                                                        <a href="" className="d-flex mail_format mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.pdf</p>
                                                                        </a> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="pills-whatsapp" role="tabpanel" aria-labelledby="pills-whatsapp-tab">
                                                <div className="mt-4">
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                <div className="col-lg-1  d-flex justify-content-lg-center my-2">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 my-2">
                                                                    <span className="icon_bg"><img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} className="svg_icon" alt=''/></span>
                                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                        <p>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                <div className="col-lg-1  d-flex justify-content-lg-center my-2">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 my-2">
                                                                    <span className="icon_bg"><img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} className="svg_icon" alt=''/></span>
                                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                        <p>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="pills-sms" role="tabpanel" aria-labelledby="pills-sms-tab">SMS</div>
                                            <div className="tab-pane fade" id="pills-calls" role="tabpanel" aria-labelledby="pills-calls-tab">Calls</div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="brokerage_details" role="tabpanel" aria-labelledby="brokerage_details-tab">
                                    <form noValidate onSubmit={formikbd.handleSubmit}>
                                        <div className='row'>
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">S GST %</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" {...formikbd.getFieldProps('s_gst_per')} className="form-control"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">C GST %</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" {...formikbd.getFieldProps('c_gst_per')} className="form-control"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">I GST %</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" {...formikbd.getFieldProps('i_gst_per')} className="form-control"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">GST Amount</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" {...formikbd.getFieldProps('gst_amount')} className="form-control"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Gross Brokerage Value</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" {...formikbd.getFieldProps('gross_brokerage_value')} className="form-control"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">TDS % Deducted by Builder</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" {...formikbd.getFieldProps('tds_per_ded_builder')} className="form-control"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">TDS Amount</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" {...formikbd.getFieldProps('tds_amount')} className="form-control"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">After TDS Brokearge</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" {...formikbd.getFieldProps('after_tds_brokerage')} className="form-control"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Actual Receivable Amount</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" {...formikbd.getFieldProps('actual_receivable_amount')} className="form-control"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Incentive%</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" {...formikbd.getFieldProps('incentive_per')} className="form-control"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Incentive Without TDS</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" {...formikbd.getFieldProps('incentive_without_tds')} className="form-control"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">TDS%</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" {...formikbd.getFieldProps('tds_per')} className="form-control"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Net Incentive Earned</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" {...formikbd.getFieldProps('net_incentive_earned')} className="form-control"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Invoice Status</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" {...formikbd.getFieldProps('invoice_status')} className="form-control"/> 
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-center mb-6'>           
                                            <button
                                            type='submit'
                                            id='kt_sign_up_submit'
                                            className='btn btn_primary text-primary'
                                            disabled={formikbd.isSubmitting}
                                            >
                                            {!loading && <span className='indicator-label'>Submit
                                            <KTSVG
                                            path='/media/custom/save_white.svg'
                                            className='svg-icon-3 svg-icon-primary ms-2'
                                            />
                                            </span>}
                                            {loading && (
                                                <span className='indicator-progress' style={{display: 'block'}}>
                                                Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                            </button>
                                        </div>
                                    </form>
                                    </div>
                                    <div className="tab-pane fade" id="invoice_details" role="tabpanel" aria-labelledby="invoice_details-tab">
                                    <form noValidate onSubmit={formikid.handleSubmit}>
                                        <div className='row'>                                    
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Raised %</label>
                                                <div className="input-group mb-3">
                                                    <input type="number" min="0" {...formikid.getFieldProps('raised')} className="form-control"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Pending %</label>
                                                <div className="input-group mb-3">
                                                    <input type="number" min="0" {...formikid.getFieldProps('pending')} className="form-control"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Payment Status</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" {...formikid.getFieldProps('payment_status')} className="form-control"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Amount Received</label>
                                                <div className="input-group mb-3">
                                                    <input type="number" min="0" {...formikid.getFieldProps('amount_received')} className="form-control"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Receiving Date</label>
                                                <div className="input-group mb-3">
                                                    <input type="date" {...formikid.getFieldProps('receiving_date')} className="form-control"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Pending Brokerage</label>
                                                <div className="input-group mb-3">
                                                    <input type="number" min="0" {...formikid.getFieldProps('pending_brokerage')} className="form-control"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">S GST %</label>
                                                <div className="input-group mb-3">
                                                    <input type="number" min="0" {...formikid.getFieldProps('s_gst_2')} className="form-control"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">C GST %</label>
                                                <div className="input-group mb-3">
                                                    <input type="number" min="0" {...formikid.getFieldProps('c_gst_3')} className="form-control"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">I GST %</label>
                                                <div className="input-group mb-3">
                                                    <input type="number" min="0" {...formikid.getFieldProps('i_gst_4')} className="form-control"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">GST Amount</label>
                                                <div className="input-group mb-3">
                                                    <input type="number" min="0" {...formikid.getFieldProps('gst_amount2')} className="form-control"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Gross Brokerage Value</label>
                                                <div className="input-group mb-3">
                                                    <input type="number" min="0" {...formikid.getFieldProps('gross_brokerage_value2')} className="form-control"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">TDS % Deducted by Builder</label>
                                                <div className="input-group mb-3">
                                                    <input type="number" min="0" {...formikid.getFieldProps('tds_reducted_by_builder3')} className="form-control"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">TDS Amount</label>
                                                <div className="input-group mb-3">
                                                    <input type="number" min="0" {...formikid.getFieldProps('tds_amount2')} className="form-control"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">After TDS Brokearge</label>
                                                <div className="input-group mb-3">
                                                    <input type="number" min="0" {...formikid.getFieldProps('after_tds_brokearge5')} className="form-control"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Pending Receivable Amount</label>
                                                <div className="input-group mb-3">
                                                    <input type="number" min="0" {...formikid.getFieldProps('pending_receivable_amount')} className="form-control"/> 
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-center mb-6'>           
                                            <button
                                            type='submit'
                                            id='kt_sign_up_submit'
                                            className='btn btn_primary text-primary'
                                            disabled={formikid.isSubmitting}
                                            >
                                            {!loading && <span className='indicator-label'>Submit
                                            <KTSVG
                                            path='/media/custom/save_white.svg'
                                            className='svg-icon-3 svg-icon-primary ms-2'
                                            />
                                            </span>}
                                            {loading && (
                                                <span className='indicator-progress' style={{display: 'block'}}>
                                                Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                            </button>
                                        </div>
                                    </form>
                                    </div>
                                    {/* <div className="tab-pane fade" id="duplicate" role="tabpanel" aria-labelledby="duplicate-tab">
                                    <div className="d-flex flex-column flex-center mb-9">   
                                        <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
                                        <h2>Under Construction</h2>
                                    </div>
                                    </div>
                                    <div className="tab-pane fade" id="tasks" role="tabpanel" aria-labelledby="tasks-tab">
                                    <div className="d-flex flex-column flex-center mb-9">   
                                        <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
                                        <h2>Under Construction</h2>
                                    </div>
                                    </div>
                                    <div className="tab-pane fade" id="timeline" role="tabpanel" aria-labelledby="timeline-tab">
                                    <div className="d-flex flex-column flex-center mb-9">   
                                        <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
                                        <h2>Under Construction</h2>
                                    </div>
                                    </div>   */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            :
            <div className="card bg_primary">
                <div className="card-body d-flex justify-content-between">
                    <div>
                        <h5 className='text-white'>Transaction Details</h5>
                    </div>
                    <button onClick={minimaximize} className="mx-3 btn p-0">
                        <i className="fas fa-window-maximize text-white"></i>
                    </button>
                    <button type='button' data-bs-dismiss="offcanvas" id='kt_expand_close' onClick={closeDialogue} className="mx-3 btn p-0">
                        <i className="fas fa-times text-white"></i>
                    </button>
                </div>
            </div>}
        </div>
    )

}
export {TransactionDetails}