import React,{FC, useState, useEffect, useRef, useCallback} from 'react'
import ReactDOM from 'react-dom'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { getPropertyDetail, getContacts, getAvailableFor, getPropertyStatus, getPropertyType, getPropertySource, getPropertyInDepth, getAssignTo, getCity, getState, getCountries, getAmenity, getFurnishStatus, getPosesStatus, getOwnershipType, getPropertyFacing, getKeyCustody, getKitchenType, getFlooring, getVasthuComp, getCurrency, getSegment, getProjectStage, getSpecification, getSiteVisitPref, getAgeOfProperty, updateProperty, updatePropertyAddress, updatePropertyFeatures, getProperties, savePropertyNotes, getPropertyNotes, uploadMultipleFileProperty, getMulitiFileProperty, deleteMultiFileProperty, updatePropertyNotes, deletePropertyNotes, getLeadProperty, getTaskProperty, getUnitType, getLog, getFeaturesList, updatePropertyUnitType, deletePropertyUnitType, getProjects } from './core/_requests';
import {useAuth} from '../../../app/modules/auth'
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { Toast, Offcanvas } from 'bootstrap';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import DataTable from 'react-data-table-component'; 
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import Dropzone, { useDropzone } from 'react-dropzone'
import Moment from 'moment';
import { getAllTemplatesMail } from '../settings/templates/core/_requests';

import { DataGrid,
     GridColDef,
    // GridColumnHeaderParams,
    GridRenderCellParams,
    // GridValueGetterParams
     } from '@mui/x-data-grid';
import { getAssignToList } from '../contact/core/_requests'


const initialValues = {
    // basic info
    contact_id: '',
    available_for: '',
    commission: '',
    property_indepth: '',
    segment: '',
    property_type: '',
    property_source: '',
    property_status: '',
    description: '',
    // basic info
    project_stage: '',
    age_of_property: '',
    furnishing: '',
    sale_price: '',
    builtup_area_min: '',
    builtup_area_max: '',
    plot_area_min: '',
    plot_area_max: '',
    tower: '',
    // specification: [],
    uds: '',
    no_of_floors: '',
    no_of_units_min: '',
    no_of_units_max: '',
    unit_type_min: '',
    unit_type_max: '',
    ownership_type: '',
    balcony: '',
    property_facing: '',
    kitchen_type: '',
    flooring: '',
    vasthu_compliant: '',
    currently_under_loan: '',
    available_from: '',
    site_visit_preference: [],
    key_custody: '',
    no_of_car: '',
    car_park_type: '',
    water_supply: '',
    gated_community: '',
    amenities:[],
    property_highlight: '',
    rera_registered: '',
    rera_number: '',
    completion_certificate: '',
    name_of_building: '',
    address_line1: '',
    country: '',
    state: '',
    city: '',
    locality: '',
    pincode: '',
    latitude: '',
    longitude: '',
    door_number: '',
    module_number: '',
    reply:'',
    prop_unit_count:'',
    id:'',
    property_id:'',
    unit_type:'',
    total_units:'',
    price_min:'',
    price_max:'',
    local_currency:'1',
    bathrooms:'',
    created_by:'',
    title: "",
    subject: "",
    share_with: "",
    module_id: "",
    body: "",
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
    style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
    },
    },
};

const thumbsContainer = {
    display: 'flex',
    // flexDirection: 'row',
    // flexWrap: 'wrap',
    marginTop: 16
  };

const thumb = {
    display: 'inline-flex',
    borderRadius: 5,
    // border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    // boxSizing: 'border-box'
  };
  
  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };
  
  const img = {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  };

  const thumbButton = {
    // position: 'absolute',
    right: 10,
    bottom: 10,
};

function getStyles(name: string, aminityName: string[], theme: Theme) {
    return {
        fontWeight:
        aminityName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
    };
}

const mystyle = {
    padding: "0px",
    height: "34px",
    maxHeight: "200px",
    overflowY: "scroll",
    // display: "block"
};


const Contactcolumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70,headerClassName: 'dg_header' },
    { field: 'firstName', headerName: 'First name', width: 130,headerClassName: 'dg_header' },
    { field: 'Email', headerName: 'Email', width: 130,headerClassName: 'dg_header' },
    { field: 'PhoneNumber', headerName: 'Phone Number', type: 'number', width: 130,headerClassName: 'dg_header'},
    { field: 'designation', headerName: 'Designation', width: 130,headerClassName: 'dg_header' },
    { field: 'createdBy', headerName: 'Created By', width: 130,headerClassName: 'dg_header' },
    { field: 'createdOn', headerName: 'Created On', width: 130,headerClassName: 'dg_header' },
  ];

  const logContactcolumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70,headerClassName: 'dg_header' },
    { field: 'user_id', headerName: 'User ID', width: 100,headerClassName: 'dg_header' },
    { field: 'user_name', headerName: 'User Name', width: 200,headerClassName: 'dg_header' },
    { field: 'module_name_ref', headerName: 'Module Name', width: 150,headerClassName: 'dg_header' },
    { field: 'note', headerName: 'Note', width: 500,headerClassName: 'dg_header' },
    { field: 'created_at', headerName: 'Created At', width: 200,headerClassName: 'dg_header' },
];

  const leadPropertycolumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70,headerClassName: 'dg_header' },
    { field: 'task_type', headerName: 'Task Type', width: 130,headerClassName: 'dg_header' },
    { field: 'priority', headerName: 'Priority', width: 130,headerClassName: 'dg_header' },
    { field: 'task_time', headerName: 'Finish Time', type: 'number', width: 130,headerClassName: 'dg_header'},
    { field: 'finish_time', headerName: 'Designation', width: 130,headerClassName: 'dg_header' },
    { field: 'project', headerName: 'Project', width: 130,headerClassName: 'dg_header' },
    { field: 'contact', headerName: 'Contact', width: 130,headerClassName: 'dg_header' },
    { field: 'agenda', headerName: 'Agenda', width: 130,headerClassName: 'dg_header' },
    { field: 'task_note', headerName: 'Task Note', width: 130,headerClassName: 'dg_header' },
    { field: 'task_status', headerName: 'Task Status', width: 130,headerClassName: 'dg_header' },
    { field: 'created_by', headerName: 'Created By', width: 130,headerClassName: 'dg_header' },
    { field: 'assign_to_name', headerName: 'Assign To', width: 130,headerClassName: 'dg_header' },
    { field: 'contact_email', headerName: 'Contact Email', width: 130,headerClassName: 'dg_header' },
    { field: 'contact_mobile', headerName: 'Contact Mobile', width: 130,headerClassName: 'dg_header' },
  ];

  const taskPropertycolumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70,headerClassName: 'dg_header' },
    { field: 'task_type', headerName: 'Task Type', width: 130,headerClassName: 'dg_header' },
    { field: 'priority', headerName: 'Priority', width: 130,headerClassName: 'dg_header' },
    { field: 'task_time', headerName: 'Task Time', type: 'number', width: 130,headerClassName: 'dg_header'},
    { field: 'finish_time', headerName: 'Finish Time', width: 130,headerClassName: 'dg_header' },
    { field: 'project', headerName: 'Project', width: 130,headerClassName: 'dg_header' },
    { field: 'contact', headerName: 'Contact', width: 130,headerClassName: 'dg_header' },
    { field: 'agenda', headerName: 'Agenda', width: 130,headerClassName: 'dg_header' },
    { field: 'task_note', headerName: 'Task Note', width: 130,headerClassName: 'dg_header' },
    { field: 'task_status', headerName: 'Task Status', width: 130,headerClassName: 'dg_header' },
    { field: 'created_by', headerName: 'Created By', width: 130,headerClassName: 'dg_header' },
    { field: 'assign_to_name', headerName: 'Assign To', width: 130,headerClassName: 'dg_header' },
    { field: 'contact_email', headerName: 'Contact Email', width: 130,headerClassName: 'dg_header' },
    { field: 'contact_mobile', headerName: 'Contact Mobile', width: 130,headerClassName: 'dg_header' },
  ];

const Contactrows = [
  { id: '85213',
    firstName: 'Mr.Raj',
    Email: 'raj2002@gmail.com',
    PhoneNumber: '8965745689',
    designation: 'Asst.Manager',
    createdBy: 'Kumar',
    createdOn: '10 Dec 2022'
  },
];


const leadcolumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 ,headerClassName: 'dg_header'},
    { field: 'firstName', headerName: 'First name', width: 130, headerClassName: 'dg_header',
        renderCell: (params: GridRenderCellParams<Date>) => (
        <strong>
          <img src={toAbsoluteUrl('/media/custom/user.svg')} alt="" className="table_icon me-2" />
          
        </strong>
      ),
    },
    { field: 'Email', headerName: 'Email', width: 130, headerClassName: 'dg_header' },
    { field: 'PhoneNumber', headerName: 'Phone Number', type: 'number', width: 130, headerClassName: 'dg_header'},
    { field: 'Project', headerName: 'Project', width: 130, headerClassName: 'dg_header'},
    { field: 'Configuration', headerName: 'Configuration', width: 130, headerClassName: 'dg_header'},
    { field: 'ProjectType', headerName: 'Project Type', width: 130, headerClassName: 'dg_header'},
    { field: 'Source', headerName: 'Source', width: 130, headerClassName: 'dg_header'},
    { field: 'CreatedOn', headerName: 'Created on', width: 130, headerClassName: 'dg_header'},
    { field: 'AssignTo', headerName: 'Assign To', width: 130, headerClassName: 'dg_header'},
    { field: 'Budget', headerName: 'Budget', width: 130, headerClassName: 'dg_header'},
    { field: 'Action', renderCell: () => (
        <select className="form-select toggle_white btn_primary rounded-pill btn-sm cursor-pointer status_btn" aria-label="Default select example">
            <option selected>Status</option>
            <option value="1">Pending</option>
            <option value="2">Completed</option>
        </select>
      ),
    }
  ];

const leadrows = [
  { id: '85213',
    firstName: 'Mr.Raj',
    Email: 'raj2002@gmail.com',
    PhoneNumber: '8965745689',
    Project: 'Sobha forest Edge',
    Configuration: '2BHK',
    ProjectType: 'Villa',
    Source: 'Facebook',
    CreatedOn: '10 Dec 2022',
    AssignTo: 'Ram Kumar',   
    Budget: 'INR 3c To 4c',   
  },
  { id: '65236',
    firstName: 'Mr.Ram',
    Email: 'raj2002@gmail.com',
    PhoneNumber: '8965745689',
    Project: 'Sobha forest Edge',
    Configuration: '2BHK',
    ProjectType: 'Villa',
    Source: 'Facebook',
    CreatedOn: '10 Dec 2022',
    AssignTo: 'Ram Kumar',   
    Budget: 'INR 3c To 4c',   
  },
];

type Props = {
    propertyId?: any,
    setPropertyList?: any,
    tabInfo?: any,
    setDetailClicked?: any
}

const PropertyDetails: FC<Props> = (props) => {

    const {
        propertyId, setPropertyList, tabInfo, setDetailClicked
    } = props

    const [isExpand, setIsExpand] = useState(true);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [segment, setSegment] = useState<any[]>([]);
    const [aminityId, setAminityId] = useState<string[]>([]);
    const [aminityName, setAminityName] = useState<string[]>([]);   
    const [assignToName, setAssignToName] = useState<string[]>([]);
    const [assignToId, setAssignToId] = useState<any[]>([]);
    const [furnishName, setFurnishName] = useState<string[]>([]);
    const [specsName, setSpecsName] = useState<string[]>([]);
    const [siteVisitName, setSiteVisitName] = useState<string[]>([]);
    const [siteVisitId, setSiteVisitId] = useState<string[]>([]);
    const [propDetail, setPropDetail] = useState<{[key: string]: any}>({});
    const [contacts, setContacts] = useState<any[]>([]);
    const [availableFor, setAvailableFor] = useState<any[]>([]);
    const [propertyType, setPropertyType] = useState<any[]>([]);
    const [propertyStatus, setPropertyStatus] = useState<any[]>([]);
    const [propertySource, setPropertySource] = useState<any[]>([]);
    const [propertyInDepth, setPropertyInDepth] = useState<any[]>([]);
    const [propertyFacing, setPropertyFacing] = useState<any[]>([]);
    const [keyCustody, setKeyCustody] = useState<any[]>([]);
    const [kitchenType, setKitchenType] = useState<any[]>([]);
    const [ownershipType, setOwnershipType] = useState<any[]>([]);
    const [flooring, setFlooring] = useState<any[]>([]);
    const [vasthuComp, setVasthuComp] = useState<any[]>([]);
    const [cities, setCities] = useState<any[]>([]);
    const [states, setStates] = useState<any[]>([]);
    const [countries, setCountries] = useState<any[]>([]);
    const [assignTo, setAssignTo] = useState<any[]>([]);
    const [amenities, setAmenities] = useState<any[]>([]);
    const [furnishStatus, setFurnishStatus] = useState<any[]>([]);
    const [posesStatus, setPosesStatus] = useState<any[]>([]);
    const [availableName, setAvailableName] = useState<any[]>([]);
    const [availableId, setAvailableId] = useState<any[]>([]);
    const [currency, setCurrency] = useState<any[]>([]);
    const [projectStages, setProjectStages] = useState<any[]>([]);
    const [ageOfProp, setAgeOfProp] = useState<any[]>([]);
    const [specifications, setSpecifications] = useState<any[]>([]);
    const [siteVisit, setSiteVisit] = useState<any[]>([]);
    const [PropertyNoteList, setPropertyNoteList] = useState<any[]>([]);
    const [files, setFiles] = useState<any[]>([]);
    const [furnishId, setFurnishId] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isFilesError, setIsFilesError] = useState(false);
    const [parentId, setParentId] = useState<String>('');
    const [isFormError, setIsFormError] = useState(false); 
    const [filesVal, setFilesVal] = useState<any[]>([]); 
    const [leadProperty, setLeadProperty] = useState<any[]>([]); 
    const [taskProperty, setTaskProperty] = useState<any[]>([]); 
    const [logList, setLogList] = useState<any[]>([]); 
    const [featuresList, setFeaturesList] = useState<any[]>([{ document: "" }]); 
    const [specsId, setSpecsId] = useState<any[]>([]); 
    const [imgFullView, setImgFullView] = useState(false);
    const [previewClicked, setPreviewClicked] = useState<any>(null);
    const [previewClickedIndex, setPreviewClickedIndex] = useState<any>('');
    const [imgSelect, setImgSelect] = useState(''); 
    const [unitId, setUnitId] = useState(''); 
    const [noteEditVal, setNoteEditVal] = useState<any>('');
    const [planData, setPlanData] = useState<any[]>([]);
    const [unitType, setUnitType] = useState<any[]>([]);
    const profileView = useRef<HTMLInputElement>(null);
    const {currentUser, logout} = useAuth();

    var roleId = currentUser?.designation;
    var userId = currentUser?.id;

    const theme = useTheme();


    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            // 'image/*': ['.jpeg', '.jpg'],         
            // 'document/*': ['.pdf']
        },
        onDrop: acceptedFiles => {
        console.log(acceptedFiles);
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        // setFormFiles(acceptedFiles);

        var test: any[] = [];
        acceptedFiles.map(file => {
            console.log(file.lastModified);
            let data = {
                "lastModified": file.lastModified,
                // "lastModifiedDate": file.lastModifiedDate,
                "name": file.name,
                "size": file.size,
                "type": file.type,
                // "webkitRelativePath": acceptedFiles.webkitRelativePath
            }            
            console.log(data);
            test.push(data);
            // setFilesData(data);
        });
        console.log('files =======');
        console.log(files);
        }
    });   
    
    const saveFiles = async () => {
        if(files.length > 0){
            setIsFilesError(false);
            try {

                var formData = new FormData();

                // formData.append('uploadfiles', JSON.stringify(files));
                
                for (var i = 0; i < files.length; i++) {
                    console.log('filessssss')
                    console.log(files[i]);
                    formData.append('uploadfiles', files[i]);
                }
               
                const headers = {
                    headers: {
                        "Content-type": "multipart/form-data",
                    },                    
                }
                               
                const saveContactFiles = await uploadMultipleFileProperty(propertyId, formData, headers)
        
                console.log('saveContactFiles');
                console.log(saveContactFiles);
                if(saveContactFiles != null){
                  setLoading(false);
                  var toastEl = document.getElementById('myToastUpdate');
                  const bsToast = new Toast(toastEl!);
                  bsToast.show();
                  setFiles([]);
                  propertyFilesList(propertyId);
                //   const notesResponse = await getContactNotes(contactId)
                //   setContactNotes(notesResponse);
                }
        
              } catch (error) {
                console.error(error)
                setLoading(false)
              }   
        }
    
        else {
            setIsFilesError(true);
        }
    }

    const minimaximize = () => {
        setIsExpand(current => !current);
    }
    
    const fullScreenChange = () => {
        setIsFullScreen(current => !current);
    }

    const handleOnSearch = (string:any, results:any) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        console.log(string, results)
    }

    const handleOnHover = (result:any) => {
        // the item hovered
        console.log(result)
    }

    const handleOnSelect = (item:any) => {
        // the item selected
        console.log(item)
    }

    const handleOnFocus = () => {
        console.log('Focused')
    }

    const formatResult = (item:any) => {
        return (
            <>
            {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
            <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
            </>
        )
    }

    const handleChange = (event: SelectChangeEvent<typeof aminityName>) => {
        const {
            target: { value },
        } = event;

        var name = [];
        var id = [];
    
        for(let i = 0; i < value.length; i++){
          var fields = value[i].split('-');
    
          var n = fields[0];
          var d = fields[1];
    
          name.push(n);
          id.push(d);
        }
    
        console.log(name);
        console.log(id);
    
        setAminityId(id);

        setAminityName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    // const specsChange = (event: SelectChangeEvent<typeof specsName>) => {
    //     const {
    //         target: { value },
    //     } = event;
    //     setSpecsName(
    //         // On autofill we get a stringified value.
    //         typeof value === 'string' ? value.split(',') : value,
    //     );
    // };

    const specsChange = (event: SelectChangeEvent<typeof specsName>) => {
        const {
          target: { value },
        } = event;
    
        console.log('assign event');
        console.log(value);
    
        var name = [];
        var id = [];
    
        for(let i = 0; i < value.length; i++){
          var fields = value[i].split('-');
    
          var n = fields[0];
          var d = fields[1];
    
          name.push(n);
          id.push(d);
        }
    
        console.log(name);
        console.log(id);
    
        setSpecsId(id);
    
        setSpecsName(
          typeof value === 'string' ? value.split(',') : value,
        );
      };

    // const siteVisitChange = (event: SelectChangeEvent<typeof siteVisitName>) => {
    //     const {
    //         target: { value },
    //     } = event;
    //     setSiteVisitName(
    //         // On autofill we get a stringified value.
    //         typeof value === 'string' ? value.split(',') : value,
    //     );
    // };

    const siteVisitChange = (event: SelectChangeEvent<typeof siteVisitName>) => {
        const {
          target: { value },
        } = event;
    
        console.log('assign event');
        console.log(value);
    
        var name = [];
        var id = [];
    
        for(let i = 0; i < value.length; i++){
          var fields = value[i].split('-');
    
          var n = fields[0];
          var d = fields[1];
    
          name.push(n);
          id.push(d);
        }
    
        console.log(name);
        console.log(id);
    
        setSiteVisitId(id);
    
        setSiteVisitName(
          typeof value === 'string' ? value.split(',') : value,
        );
      };

    const furnishingChange = (event: SelectChangeEvent<typeof furnishName>) => {
        const {
            target: { value },
        } = event;
        setFurnishName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const availableChange = (event: SelectChangeEvent<typeof availableName>) => {
        const {
            target: { value },
        } = event;

        var name = [];
        var id = [];
    
        for(let i = 0; i < value.length; i++){
          var fields = value[i].split('-');
    
          var n = fields[0];
          var d = fields[1];
    
          name.push(n);
          id.push(d);
        }
    
        console.log(name);
        console.log(id);
    
        setAvailableId(id);

        setAvailableName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const replyOnSubmit = async (id:any) => {
        setParentId(id);
        let replyVal = (document.getElementById('child_reply'+id) as HTMLInputElement)!.value;
        console.log('sdsdsdsd');
        console.log(replyVal);
    
        if(replyVal != ''){
            setIsFormError(false);
            try {
                var userId = currentUser?.id;
                var notesBody = {
                    "reply": replyVal,
                    "property_id": propertyId,
                    "user_id": userId,
                    "parent_id": id
                };
                               
                const saveContactNotesData = await savePropertyNotes(notesBody)
        
                console.log('saveContactNotesData');
                console.log(saveContactNotesData);
                if(saveContactNotesData != null){
                  setLoading(false);
                  (document.getElementById('child_reply'+id) as HTMLInputElement).value = ''
                  var toastEl = document.getElementById('myToastUpdate');
                  const bsToast = new Toast(toastEl!);
                  bsToast.show();
                  const notesResponse = await getPropertyNotes(propertyId)
                  setPropertyNoteList(notesResponse);
                }
        
              } catch (error) {
                console.error(error)
                setLoading(false)
              }   
        }
    
        else {
            setIsFormError(true);
        }
    
    }
    
    const FetchPropsDetails =  async (propertyId : number) => {
        // contactId : number
        setIsLoading(true);
        const fetchDetails = await getPropertyDetail(propertyId)
        console.log('property infooo');
        console.log(fetchDetails[0]);
        setPropDetail(fetchDetails[0]);
        // basic info fields
        formik.setFieldValue('contact_id', fetchDetails[0].contact_id ?? '');
        formik.setFieldValue('commission', fetchDetails[0].commission ?? '');
        formik.setFieldValue('property_indepth', fetchDetails[0].property_indepth ?? '');
        formik.setFieldValue('segment', fetchDetails[0].segment ?? '');
        formik.setFieldValue('property_type', fetchDetails[0].property_type ?? '');
        formik.setFieldValue('property_source', fetchDetails[0].property_source ?? '');
        formik.setFieldValue('property_status', fetchDetails[0].property_status ?? '');
        formik.setFieldValue('description', fetchDetails[0].description ?? '');
        var availableForArray = [];
        var availableForNameArray = [];
        if(fetchDetails[0].available_for != null){
            availableForArray = fetchDetails[0].available_for.split(",").map((e:any) => {
                return parseInt(e);
            });
        }
        if(fetchDetails[0].available_for_name != null){
            availableForNameArray = fetchDetails[0].available_for_name.split(",").map((e:any) => {
                return e;
            });
        }
        setAvailableId(availableForArray);
        setAvailableName(availableForNameArray);
        // address info fields
        formikAddress.setFieldValue('name_of_building', fetchDetails[0].name_of_building ?? '');
        formikAddress.setFieldValue('address_line1', fetchDetails[0].address_line1 ?? '');
        formikAddress.setFieldValue('country', fetchDetails[0].country ?? '');
        formikAddress.setFieldValue('state', fetchDetails[0].state ?? '');
        formikAddress.setFieldValue('city', fetchDetails[0].city ?? '');
        formikAddress.setFieldValue('locality', fetchDetails[0].locality ?? '');
        formikAddress.setFieldValue('pincode', fetchDetails[0].pincode ?? '');
        formikAddress.setFieldValue('latitude', fetchDetails[0].latitude ?? '');
        formikAddress.setFieldValue('longitude', fetchDetails[0].longitude ?? '');
        formikAddress.setFieldValue('door_number', fetchDetails[0].door_number ?? '');
        formikAddress.setFieldValue('module_number', fetchDetails[0].module_number ?? '');
        // features info fields
        formikFeatures.setFieldValue('project_stage', fetchDetails[0].project_stage ?? '');
        formikFeatures.setFieldValue('age_of_property', fetchDetails[0].age_of_property ?? '');
        // if(fetchDetails[0].furnishing != null){
        // var furnishingArray = [];
        // var furnishingNameArray = [];
        // if(fetchDetails[0].furnishing != null){
        //     furnishingArray = fetchDetails[0].furnishing.split(",").map((e:any) => {
        //         return parseInt(e);
        //     });
        // }
        // if(fetchDetails[0].furnishing_name != null){
        //     furnishingNameArray = fetchDetails[0].furnishing_name.split(",").map((e:any) => {
        //         return e;
        //     });
        // }
        formikFeatures.setFieldValue('furnishing', fetchDetails[0].furnishing ?? '');
        // setFurnishId(furnishingArray);
        // setFurnishName(furnishingNameArray);
        // }
        formikFeatures.setFieldValue('sale_price', fetchDetails[0].sale_price ?? '');
        formikFeatures.setFieldValue('builtup_area_min', fetchDetails[0].builtup_area_min ?? '');
        formikFeatures.setFieldValue('builtup_area_max', fetchDetails[0].builtup_area_max ?? '');
        formikFeatures.setFieldValue('plot_area_min', fetchDetails[0].plot_area_min ?? '');
        formikFeatures.setFieldValue('plot_area_max', fetchDetails[0].plot_area_max ?? '');
        formikFeatures.setFieldValue('tower', fetchDetails[0].tower ?? '');
        // formikFeatures.setFieldValue('specification', fetchDetails[0].specification ?? '');
        formikFeatures.setFieldValue('uds', fetchDetails[0].uds ?? '');
        formikFeatures.setFieldValue('no_of_floors', fetchDetails[0].no_of_floors ?? '');
        formikFeatures.setFieldValue('no_of_units_min', fetchDetails[0].no_of_units_min ?? '');
        formikFeatures.setFieldValue('no_of_units_max', fetchDetails[0].no_of_units_max ?? '');
        formikFeatures.setFieldValue('unit_type_min', fetchDetails[0].unit_type_min ?? '');
        formikFeatures.setFieldValue('unit_type_max', fetchDetails[0].unit_type_max ?? '');
        formikFeatures.setFieldValue('balcony', fetchDetails[0].balcony ?? '');
        formikFeatures.setFieldValue('flooring', fetchDetails[0].flooring ?? '');
        formikFeatures.setFieldValue('key_custody', fetchDetails[0].key_custody ?? '');
        formikFeatures.setFieldValue('kitchen_type', fetchDetails[0].kitchen_type ?? '');
        formikFeatures.setFieldValue('ownership_type', fetchDetails[0].ownership_type ?? '');
        formikFeatures.setFieldValue('property_facing', fetchDetails[0].property_facing ?? '');
        formikFeatures.setFieldValue('available_from', fetchDetails[0].available_from ?? '');
        formikFeatures.setFieldValue('site_visit_preference', fetchDetails[0].site_visit_preference ?? '');
        formikFeatures.setFieldValue('no_of_car', fetchDetails[0].no_of_car ?? '');
        formikFeatures.setFieldValue('rera_registered', fetchDetails[0].rera_registered ?? '');
        formikFeatures.setFieldValue('car_park_type', fetchDetails[0].car_park_type ?? '');
        formikFeatures.setFieldValue('water_supply', fetchDetails[0].water_supply ?? '');
        formikFeatures.setFieldValue('gated_community', fetchDetails[0].gated_community ?? '');
        formikFeatures.setFieldValue('currently_under_loan', fetchDetails[0].currently_under_loan ?? '');
        formikFeatures.setFieldValue('vasthu_compliant', fetchDetails[0].vasthu_compliant ?? '');
        // if(fetchDetails[0].amenities != null){
        var amenitiesArray = [];
        var amenitiesNameArray = [];
        if(fetchDetails[0].amenities != null){
            amenitiesArray = fetchDetails[0].amenities.split(",").map((e:any) => {
                return parseInt(e);
            });
        }
        if(fetchDetails[0].amenities_name != null){
            amenitiesNameArray = fetchDetails[0].amenities_name.split(",").map((e:any) => {
                return e;
            });
        }
        setAminityId(amenitiesArray);
        setAminityName(amenitiesNameArray);
        // }

        var assignArray = [];
        var assignNameArray = [];
        if(fetchDetails[0].assign_to != null){
            assignArray = fetchDetails[0].assign_to.split(",").map((e:any) => {
                return parseInt(e);
            });
        }
        if(fetchDetails[0].assign_to_name != null){
            assignNameArray = fetchDetails[0].assign_to_name.split(",").map((e:any) => {
                return e;
            });
        }

        setAssignToId(assignArray);
        setAssignToName(assignNameArray);

        formikFeatures.setFieldValue('property_highlight', fetchDetails[0].property_highlight ?? '');
        formikFeatures.setFieldValue('rera_number', fetchDetails[0].rera_number ?? '');
        formikFeatures.setFieldValue('completion_certificate', fetchDetails[0].completion_certificate ?? '');
        setIsLoading(false);
    };

    const contactsList =  async () => {   
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const contactsResponse = await getContacts(userId, roleId);
    
        var contactDropList = [];
    
        for(let i = 0; i < contactsResponse.length; i++) {
            var data = { "id": contactsResponse[i].id, "name": contactsResponse[i].first_name + ' '+ contactsResponse[i].last_name }
            contactDropList.push(data);
        }
    
        setContacts(contactDropList);
    }

    const availableForList = async () => {
        const lookingResponse = await getAvailableFor();
        setAvailableFor(lookingResponse);
    } 

    const assignToList =  async () => {   
        var userId = currentUser?.id;
        var roleId = currentUser?.designation; 
        const assignToResponse = await getAssignToList(userId, roleId);
        setAssignTo(assignToResponse);
    }

    const ownershipList =  async () => {   
        const cityResponse = await getOwnershipType();
        setOwnershipType(cityResponse);
    }

    const cityList =  async () => {   
        const cityResponse = await getCity();
        setCities(cityResponse);
    }

    const stateList =  async () => {   
        const stateResponse = await getState();
        setStates(stateResponse);
    }

    const countriesList =  async () => {   
        const countryResponse = await getCountries();
        setCountries(countryResponse);
    }

    const propertyTypeList = async () => {
        const response = await getPropertyType();
        setPropertyType(response);
    } 

    const propertyStatusList = async () => {
        const response = await getPropertyStatus();
        setPropertyStatus(response);
    } 

    const propertySourceList = async () => {
        const response = await getPropertySource();
        setPropertySource(response);
    }

    const propertyInDepthList = async () => {
        const response = await getPropertyInDepth();
        setPropertyInDepth(response);
    }

    const propertyFacingList = async () => {
        const response = await getPropertyFacing();
        setPropertyFacing(response);
    } 

    const keyCustodyList = async () => {
        const response = await getKeyCustody();
        setKeyCustody(response);
    } 

    const kitchenTypeList = async () => {
        const response = await getKitchenType();
        setKitchenType(response);
    } 

    const flooringList = async () => {
        const response = await getFlooring();
        setFlooring(response);
    } 

    const vasthuCompList = async () => {
        const response = await getVasthuComp();
        setVasthuComp(response);
    } 

    const amenitiesList =  async () => {   
        const amenityResponse = await getAmenity();
        setAmenities(amenityResponse);
    }

    const furnishStatusList =  async () => {   
        const furnishResponse = await getFurnishStatus();
        setFurnishStatus(furnishResponse);
    }

    const posesStatusList =  async () => {   
        const posessionResponse = await getPosesStatus();
        setPosesStatus(posessionResponse);
    }

    const currencyList =  async () => {   
        const posessionResponse = await getCurrency();
        setCurrency(posessionResponse);
    }

    const segmentList = async () => {
        const segmentResponse = await getSegment();
        setSegment(segmentResponse);
    }

    const unitTypeList =  async () => {
        const unitResponse = await getUnitType();
        setUnitType(unitResponse);
    }

    const fetchLog = async () => {
        const fetchLogList = await getLog(propertyId);
        setLogList(fetchLogList);
    } 


    const fetchFeaturesList = async () => {
        const FeaturesListData = await getFeaturesList(propertyId);
        if(FeaturesListData != null) {
            for(let i = 0; i < FeaturesListData.length; i++) {
                formikunittype.setFieldValue('builtup_area_min'+i, FeaturesListData[i].builtup_area_min ?? '')
                formikunittype.setFieldValue('builtup_area_max'+i, FeaturesListData[i].builtup_area_max ?? '')
                formikunittype.setFieldValue('local_currency'+i, FeaturesListData[i].local_currency ?? '')
                formikunittype.setFieldValue('price_max'+i, FeaturesListData[i].price_max ?? '')
                formikunittype.setFieldValue('price_min'+i, FeaturesListData[i].price_min ?? '')
                formikunittype.setFieldValue('total_units'+i, FeaturesListData[i].total_units ?? '')
                formikunittype.setFieldValue('unit_type'+i, FeaturesListData[i].unit_type ?? '')
                // formikunittype.setFieldValue('file'+i, FeaturesListData[i].file)
                
            }
            setFeaturesList(FeaturesListData);
        } else {
            setFeaturesList([{ document: "" }]);
        }
        
    } 

    const projectStageList =  async () => {   
        const response = await getProjectStage();
        setProjectStages(response);
    }

    const leadPropertyList =  async () => {   
        const response = await getLeadProperty(propertyId);
        setLeadProperty(response);
    }

    const taskPropertyList =  async () => {   
        const response = await getTaskProperty(propertyId);
        setTaskProperty(response);
    }

    const ageOfPropList =  async () => {   
        const response = await getAgeOfProperty();
        setAgeOfProp(response);
    }

    const specsList =  async () => {   
        const response = await getSpecification();
        setSpecifications(response);
    }

    const propertyNoteList =  async () => {   
        const propertyNoteResponse = await getPropertyNotes(propertyId);
        setPropertyNoteList(propertyNoteResponse);
    }

    const siteVisitList =  async () => {   
        const response = await getSiteVisitPref();
        setSiteVisit(response);
    }

    const propertyFilesList =  async (propertyId : number) => {   
        const contactFileResponse = await getMulitiFileProperty(propertyId)
        setFilesVal(contactFileResponse);
        // console.log('files sdsdsd');
        // console.log(contactFileResponse);
    }

    const closeDialogue = () => {
        setDetailClicked(false);
        var offCanvasEl = document.getElementById('kt_expand'+propertyId);
        offCanvasEl?.classList.add('invisible');
        const bsOffcanvas = new Offcanvas(offCanvasEl!);
        bsOffcanvas.hide();
    }

    const imgViewChange = (id:any) => {
        console.log(id);
        setImgFullView(!imgFullView)
        setImgSelect(id)
    }

    const onDeleteFile = async (id:any) => {
        console.log(id);
        const deleteRes = await deleteMultiFileProperty(id);
        console.log(deleteRes);
        if(deleteRes != null){
            var toastEl = document.getElementById('myToastUpdate');
            const bsToast = new Toast(toastEl!);
            bsToast.show();
            propertyFilesList(propertyId);
        }
    }

    // const cancelEdit = async (id:any) => {
    //     setParentId('');
    // }

    const editOnSubmit = async (id:any) => {
        setParentId(id);
        let editVal = (document.getElementById('edit_field'+id) as HTMLInputElement)!.value;
        console.log('sdsdsdsd');
        console.log(editVal);
    
        if(editVal != ''){
            setIsFormError(false);
            try {
                var userId = currentUser?.id;
              
                var notesBody = {
                    "reply": editVal,
                    "user_id": userId
                };
                               
                const editNotesData = await updatePropertyNotes(id, notesBody)
        
                console.log('editNotesData');
                console.log(editNotesData);
                if(editNotesData != null){
                  setLoading(false);
                  (document.getElementById('edit_field'+id) as HTMLInputElement).value = '';
                  setNoteEditVal('');
                  var toastEl = document.getElementById('myToastUpdate');
                  const bsToast = new Toast(toastEl!);
                  bsToast.show();
                  propertyNoteList();
                //   const notesResponse = await getTaskNotes(taskId)
                //   setTaskNoteList(notesResponse);
                }
        
              } catch (error) {
                console.error(error)
                setLoading(false)
              }   
        }
    
        else {
            setIsFormError(true);
        }
    
    }

    const cancelEdit = async (id:any) => {
        setParentId('');
    }

    const replyEdit = async (id:any, val:any) => {
        setParentId(id);
        // let replyVal = (document.getElementById('child_reply'+id) as HTMLInputElement)!.value;
        
        setNoteEditVal(val);
    }

    const replyDelete = async (id:any, parentId:any) => {
        var userId = currentUser?.id;
        console.log(id);
        console.log(userId);
        const deleteNotes = await deletePropertyNotes(id, userId, parentId);
        console.log(deleteNotes);
        if(deleteNotes != null){
            var toastEl = document.getElementById('myToastUpdate');
            const bsToast = new Toast(toastEl!);
            bsToast.show();
            propertyNoteList();
            // setTaskNoteList(taskId);
        }
    }

    // const replyOnSubmit = async (id:any) => {
    //     setParentId(id);
    //     let replyVal = (document.getElementById('child_reply'+id) as HTMLInputElement)!.value;
    //     console.log('sdsdsdsd');
    //     console.log(replyVal);
    
    //     if(replyVal != ''){
    //         setIsFormError(false);
    //         try {
    //             var userId = currentUser?.id;
    //             var notesBody = {
    //                 "reply": replyVal,
    //                 "contact_id": contactId,
    //                 "user_id": userId,
    //                 "parent_id": id
    //             };
                               
    //             const saveContactNotesData = await saveContactNotes(notesBody)
        
    //             console.log('saveContactNotesData');
    //             console.log(saveContactNotesData);
    //             if(saveContactNotesData != null){
    //               setLoading(false);
    //               (document.getElementById('child_reply'+id) as HTMLInputElement).value = ''
    //               var toastEl = document.getElementById('myToastUpdate');
    //               const bsToast = new Toast(toastEl!);
    //               bsToast.show();
    //               const notesResponse = await getContactNotes(contactId)
    //               setContactNotes(notesResponse);
    //             }
        
    //           } catch (error) {
    //             console.error(error)
    //             setLoading(false)
    //           }   
    //     }
    
    //     else {
    //         setIsFormError(true);
    //     }
    
    // }

    const handleClick = (index:any) => {
        setFiles([
            ...files.slice(0, index),
            ...files.slice(index + 1, files.length)
          ]);
    }
  
    const thumbs = files.map((file:any, index:any) => {
        const pieces = file.path.split('.');
        const last = pieces[pieces.length - 1];
        return(
        <div style={thumb} key={file.name} className="position-relative">
            <div style={thumbInner}>
            { last != 'pdf' ?
                <img
                src={file.preview}
                className='w-100 h-100 img-thumbnail rounded fit_contain'
                onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />: <img
                src={toAbsoluteUrl('/media/svg/files/pdf.svg')}
                className='w-100 h-100 img-thumbnail rounded fit_contain'
                />
                }
                
            </div>
            <a onClick={() => handleClick(index)} className='icon position-absolute top-0 end-0 rounded bg-gray border-0'><span className="svg-icon svg-icon-muted"><svg width="" height="30" viewBox="3 3 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
            <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)" fill="currentColor"/>
            <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)" fill="currentColor"/>
            </svg>
            </span></a>
            {/* <button
                style={thumbButton}
                onClick={() =>
                    editImage(file, (output:any) => {
                        const updatedFiles = [...files];

                        // replace original image with new image
                        updatedFiles[index] = output;

                        // revoke preview URL for old image
                        if (file.preview) URL.revokeObjectURL(file.preview);

                        // set new preview URL
                        Object.assign(output, {
                            preview: URL.createObjectURL(output),
                        });

                        // update view
                        setFiles(updatedFiles);
                    })
                }
            >
                Edit
            </button> */}
        </div>
    )});

    
    const onPlanChange = (id:any, i:any, e:any) => {
        setUnitId(id)
        // 
        formikunittype.setFieldValue('unit_type'+i, e.target.value ?? '');
    }

    const onCurrencyChange = (id:any, i:any, e:any) => {
        setUnitId(id)
        // 
        formikunittype.setFieldValue('local_currency'+i, e.target.value ?? '');
    }

    
    const processFile = (e:any, index:any) => {
        console.log('image dropppeddd');
        var files = e.dataTransfer.files;

        console.log(e.dataTransfer);
        console.log(files);

        // const newVisibilities = [...documentFile];
        // newVisibilities[index] = !newVisibilities[index];
        // setDocumentFile(newVisibilities);
        // console.log(documentFile);

        let fields = files[0]['name'].split(".");

        let fileType =  fields![fields!.length - 1];

        var featureData = [];

        for(let i = 0; i < featuresList.length; i++){
            let fFileSec = (document.getElementById('file_'+i) as HTMLInputElement).value;
            console.log(fFileSec);

            if(index == i){
                fFileSec = files;
            }

            // let fImgSec = (document.getElementById('file_img_sec_'+i) as HTMLDivElement);
            // let fImg = (document.getElementById('file_img_'+i) as HTMLImageElement);
            let fUnitType = (document.getElementById('unit_type_'+i) as HTMLInputElement).value;
            let fTotalUnits = (document.getElementById('total_units_'+i) as HTMLInputElement).value;
            // let fBathrooms = (document.getElementById('bathrooms_'+i) as HTMLInputElement).value;
            let fPriceMin = (document.getElementById('price_min_'+i) as HTMLInputElement).value;
            let fPriceMax = (document.getElementById('price_max_'+i) as HTMLInputElement).value;
            let fBuiltMin = (document.getElementById('builtup_area_min_'+i) as HTMLInputElement).value;
            let fBuiltMax = (document.getElementById('builtup_area_max_'+i) as HTMLInputElement).value;
            // let fPlotMin = (document.getElementById('plot_area_min_'+i) as HTMLInputElement).value;
            // let fPlotMax = (document.getElementById('plot_area_max_'+i) as HTMLInputElement).value;
            let fLocalCurrency = (document.getElementById('local_currency_'+i) as HTMLInputElement).value;
            // let fFile = (document.getElementById('file_'+i) as HTMLInputElement).files;

            // if(index == i){
            //     if(fileType == 'jpg' || fileType == 'jpeg'){
            //         fImgSec.classList.remove("d-none");
            //         fImg.setAttribute("src", URL.createObjectURL(files[0]));
            //     } else if (fileType == 'pdf'){
            //         fImgSec.classList.remove("d-none");
            //         fImg.setAttribute("src", toAbsoluteUrl("/media/svg/files/pdf.svg"));
            //     }
            // }

            var data = {
                "unit_type": fUnitType,
                "total_units": fTotalUnits,
                // "bathrooms": fBathrooms,
                "price_min": fPriceMin,
                "price_max": fPriceMax,
                "builtup_area_min": fBuiltMin,
                "builtup_area_max": fBuiltMax,
                // "plot_area_min": fPlotMin,
                // "plot_area_max": fPlotMax,
                "local_currency": fLocalCurrency,
                // "file": files[0],
            }

            featureData.push(data);
        }

        setPlanData(featureData);
    
        let image_as_base64:any = URL.createObjectURL(e.target.files[0])
        let image_as_files:any = e.target.files[0];

        // if(currentFeature == index){
        //     console.log('ffffffffffff');
        //     console.log(image_as_base64);
        // }

    }

    const allowDrop = (e:any, index:any) => {
        console.log('image droppp');
        e.preventDefault();
        console.log(e.target.files);
    }
    

    const handleFilePreview = (e:any, index:any) => {
        let image_as_base64:any = URL.createObjectURL(e.target.files[index]);
        setPreviewClicked(image_as_base64);
        setPreviewClickedIndex(index);
        console.log('image_as_base64');
        console.log(index);
        
        // const newVisibilities = [...documentFile];
        // newVisibilities[index] = !newVisibilities[index];
        // setDocumentFile(newVisibilities);
        // console.log(documentFile);

        let fields = profileView.current?.value.split(".");

        let fileType =  fields![fields!.length - 1];

        var featureData = [];

        for(let i = 0; i <= featuresList.length; i++){

            // let fImgSec = (document.getElementById('file_img_sec_'+i) as HTMLDivElement);
            // let fImg = (document.getElementById('file_img_'+i) as HTMLImageElement);
            let fUnitType = (document.getElementById('unit_type_'+i) as HTMLInputElement).value;
            let fTotalUnits = (document.getElementById('total_units_'+i) as HTMLInputElement).value;
            // let fBathrooms = (document.getElementById('bathrooms_'+i) as HTMLInputElement).value;
            let fPriceMin = (document.getElementById('price_min_'+i) as HTMLInputElement).value;
            let fPriceMax = (document.getElementById('price_max_'+i) as HTMLInputElement).value;
            let fBuiltMin = (document.getElementById('builtup_area_min_'+i) as HTMLInputElement).value;
            let fBuiltMax = (document.getElementById('builtup_area_max_'+i) as HTMLInputElement).value;
            // let fPlotMin = (document.getElementById('plot_area_min_'+i) as HTMLInputElement).value;
            // let fPlotMax = (document.getElementById('plot_area_max_'+i) as HTMLInputElement).value;
            let fLocalCurrency = (document.getElementById('local_currency_'+i) as HTMLInputElement).value;
            // let fFile = (document.getElementById('file_'+i) as HTMLInputElement).files;

            // if(index == i){
            //     if(fileType == 'jpg' || fileType == 'jpeg'){
            //         fImgSec.classList.remove("d-none");
            //         fImg.setAttribute("src", URL.createObjectURL(e.target.files[0]));
            //     } else if (fileType == 'pdf'){
            //         fImgSec.classList.remove("d-none");
            //         fImg.setAttribute("src", toAbsoluteUrl("/media/svg/files/pdf.svg"));
            //     }
            // }

            var data = {
                "unit_type": fUnitType,
                "total_units": fTotalUnits,
                // "bathrooms": fBathrooms,
                "price_min": fPriceMin,
                "price_max": fPriceMax,
                "builtup_area_min": fBuiltMin,
                "builtup_area_max": fBuiltMax,
                // "plot_area_min": fPlotMin,
                // "plot_area_max": fPlotMax,
                "local_currency": fLocalCurrency,
                // "file": fFile![0],
            }

            featureData.push(data);
        }

        setPlanData(featureData);
      
        // setImagePreview1(image_as_base64);
        // setImageFile1(image_as_files);
    }

    const handleDocumentAdd = () => {
        setFeaturesList([...featuresList, { document: "" }]);
        console.log(featuresList);
        
    };

    const handleDocumentRemove = async (list_id:any, index:any) => {
        if(list_id != undefined) {
            const deletedList = await deletePropertyUnitType(list_id);
            if(deletedList != null) {
                fetchFeaturesList();
            }
        } else {
            const list = [...featuresList];
            list.splice(index, 1); 
            if(featuresList.length == 1) {
                setFeaturesList([{ document: "" }])
            } else {
                setFeaturesList(list)
            }                           
        }       
    };

    const assingToChange = (event: SelectChangeEvent<typeof assignToName>) => {
        const {
          target: { value },
        } = event;
    
        console.log('assign event');
        console.log(value);
    
        var name = [];
        var id = [];
    
        for(let i = 0; i < value.length; i++){
          var fields = value[i].split('-');
    
          var n = fields[0];
          var d = fields[1];
    
          name.push(n);
          id.push(d);
        }
    
        console.log(name);
        console.log(id);
    
        setAssignToId(id);
    
        setAssignToName(
          typeof value === 'string' ? value.split(',') : value,
        );
      };

    useEffect(() => {
        FetchPropsDetails(propertyId);
        contactsList();
        availableForList();
        segmentList();
        unitTypeList();
        ownershipList();
        cityList();
        stateList();
        countriesList();
        projectStageList();
        ageOfPropList();
        specsList();
        siteVisitList();
        propertyTypeList();
        keyCustodyList();
        propertyInDepthList();
        assignToList();
        flooringList();
        vasthuCompList();
        amenitiesList();
        furnishStatusList();
        posesStatusList();
        propertyStatusList();
        propertySourceList();
        propertyFacingList();
        kitchenTypeList();
        currencyList();
        fetchLog();
        fetchFeaturesList();
        leadPropertyList();
        taskPropertyList();
        propertyNoteList();
        propertyFilesList(propertyId);
    }, [propertyId]);

    const propertyUpdateSchema = Yup.object().shape({
        contact_id: Yup.string()
          .required('Contact name is required'),
        available_for: Yup.array(),
        // .required('Availble for is required'),
        commission: Yup.string(),
        property_indepth: Yup.string().required('Property In-depth is required'),
        segment: Yup.string(),
        property_type: Yup.string(),
        property_source: Yup.string(),
        property_status: Yup.string(),
        description: Yup.string(),
    })

    const propertyUpdateUnitTypeSchema = Yup.object().shape({
        unit_type: Yup.string(),
        builtup_area_min: Yup.string(),
        // .required('Availble for is required'),
        total_units: Yup.string(),
        local_currency: Yup.string(),
        price_min: Yup.string(),
        price_max: Yup.string(),
        builtup_area_max: Yup.string(),
    })

    const propertyAddressUpdateSchema = Yup.object().shape({
        name_of_building: Yup.string(),
        address_line1: Yup.string(),
        country: Yup.string(),
        state: Yup.string(),
        city: Yup.string(),
        locality: Yup.string(),
        pincode: Yup.string(),
        latitude: Yup.string(),
        longitude: Yup.string(),
        door_number: Yup.string(),
        module_number: Yup.string(),
    })

    const notesFormSchema = Yup.object().shape({
        reply: Yup.string(),        
      })

    const propertyFeaturesUpdateSchema = Yup.object().shape({
        project_stage: Yup.string(),
        age_of_property: Yup.string(),
        furnishing: Yup.string(),
        builtup_area_min: Yup.string(),
        builtup_area_max: Yup.string(),
        plot_area_min: Yup.string(),
        plot_area_max: Yup.string(),
        // specification: Yup.array(),
        tower: Yup.string(),
        uds: Yup.string(),
        no_of_floors: Yup.string(),
        no_of_units_min: Yup.string(),
        no_of_units_max: Yup.string(),
        unit_type_min: Yup.string(),
        unit_type_max: Yup.string(),
        sale_price: Yup.string(),
        ownership_type: Yup.string(),
        balcony: Yup.string(),
        property_facing: Yup.string(),
        kitchen_type: Yup.string(),
        flooring: Yup.string(),
        vasthu_compliant: Yup.string(),
        currently_under_loan: Yup.string(),
        available_from: Yup.string(),
        // site_visit_preference: Yup.array(),
        key_custody: Yup.string(),
        no_of_car: Yup.string(),
        car_park_type: Yup.string(),
        water_supply: Yup.string(),
        gated_community: Yup.string(),
        amenities: Yup.array(),
        property_highlight: Yup.string(),
        rera_registered: Yup.string(),
        rera_number: Yup.string(),
        completion_certificate: Yup.string(),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: propertyUpdateSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
          console.log('lead form body');
          console.log(values);
        try {

        var userId = currentUser?.id;

        console.log('sddddddddassdsf');

        const body = {
            "contact_id": values.contact_id,
            "available_for": availableId,
            "assign_to": assignToId,
            "commission": values.commission,
            "property_indepth": values.property_indepth,
            "segment": values.segment,
            "property_type": values.property_type,
            "property_source": values.property_source,
            "property_status": values.property_status,
            "description": values.description,
            "created_by": userId
        }

        const updatePropData = await updateProperty(propertyId ,body);

        console.log('updatePropData');
        console.log(updatePropData);
        // document.getElementById('kt_contact')?.classList.remove('drawer-on');
        if(updatePropData != null){
            setLoading(false);
            setDetailClicked(false);
            document.getElementById('kt_expand_close')?.click();
            var toastEl = document.getElementById('myToastUpdate');
            const bsToast = new Toast(toastEl!);
            bsToast.show();
            const propertyResponse = await getProperties(userId, roleId);
            setPropertyList(propertyResponse);
            // resetForm();
            // setAminityName([]);
            // setAssignToName([]);
            // setFurnishName([]);
            // setPosName([]);
        }

        } catch (error) {
        console.error(error)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
        }
    }})

    const formikunittype = useFormik({
        initialValues,
        validationSchema: propertyUpdateUnitTypeSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
        try {

            const unitList: any = values;

            console.log('values', unitList['unit_type'+0])

            var userId = currentUser?.id;
        
            var formData = new FormData();
        
        
        for(let i = 0; i < featuresList.length; i++){
            formData.append('id', featuresList[i].id != undefined ? featuresList[i].id : '');
            formData.append('unit_type', unitList['unit_type'+i] != undefined ? unitList['unit_type'+i] : 1);
            formData.append('builtup_area_min', unitList['builtup_area_min'+i]);
            formData.append('builtup_area_max', unitList['builtup_area_max'+i]);
            formData.append('total_units', unitList['total_units'+i]);
            formData.append('local_currency', unitList['local_currency'+i] != undefined ? unitList['local_currency'+i] : 1);
            formData.append('price_min', unitList['price_min'+i]);
            formData.append('price_max', unitList['price_max'+i]);
        }
        formData.append('prop_unit_count', featuresList.length.toString());
        formData.append('property_id', propertyId.toString());
        formData.append('created_by', userId!.toString());
        

       console.log('unitlist', formData)
       const updatePropData = await updatePropertyUnitType(formData);

        if(updatePropData != null){
            setDetailClicked(false);
            document.getElementById('kt_expand_close')?.click();
            document.getElementById('propertyReloadBtn')?.click();
            var toastEl = document.getElementById('myToastUpdate');
            const bsToast = new Toast(toastEl!);
            bsToast.show();
        }

        } catch (error) {
        console.error(error)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        // setLoading(false)
        }
    }})

    const convertBase64 = (file:any) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
    
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
    
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
      };

    const handleUnitType = async () => {
        // alert();
        var userId = currentUser?.id;
        var featureData: React.SetStateAction<any[]> = [];
        for(var i = 0; i < featuresList.length; i++){
            // alert(featuresList.length);
            let fUnitType = (document.getElementById('unit_type_'+i) as HTMLInputElement).value;
            let fTotalUnits = (document.getElementById('total_units_'+i) as HTMLInputElement).value;
            // let fBathrooms = (document.getElementById('bathrooms_'+i) as HTMLInputElement).value;
            let fPriceMin = (document.getElementById('price_min_'+i) as HTMLInputElement).value;
            let fPriceMax = (document.getElementById('price_max_'+i) as HTMLInputElement).value;
            let fBuiltMin = (document.getElementById('builtup_area_min_'+i) as HTMLInputElement).value;
            let fBuiltMax = (document.getElementById('builtup_area_max_'+i) as HTMLInputElement).value;
            // let fPlotMin = (document.getElementById('plot_area_min_'+i) as HTMLInputElement).value;
            // let fPlotMax = (document.getElementById('plot_area_max_'+i) as HTMLInputElement).value;
            let fLocalCurrency = (document.getElementById('local_currency_'+i) as HTMLInputElement).value;
            let fFile = (document.getElementById('file_'+i) as HTMLInputElement).files;
            console.log('fFile', fFile)
            let base64 = fFile![0] != undefined || fFile![0] != null ? await convertBase64(fFile![0]) : '';
                // let myfile:any;
                // let reader = new FileReader();
                // reader.readAsDataURL(fFile);
                // reader.onload = function () {
                //     myfile = reader.result;
                // };        
                // return myfile;
            

            var data = {
                "id": featuresList[i].id,
                "unit_type": fUnitType,
                "total_units": fTotalUnits,
                // "property_id": propertyId,
                "price_min": fPriceMin,
                "price_max": fPriceMax,
                "builtup_area_min": fBuiltMin,
                "builtup_area_max": fBuiltMax,
                "created_by": userId,
                "prop_unit_count": featuresList.length,
                "local_currency": fLocalCurrency,
                "file": base64,
            }
            featureData.push(data);
        }

        var id = [];
        var featureImagesArray = [];
        var builtupMinArray = [];
        var builtupMaxArray = [];
        var plotMinArray = [];
        var plotMaxArray = [];
        var salePriceMinArray = [];
        var salePriceMaxArray = [];
        var totalUnitsArray = [];
        var localCurrencyArray = [];
        var unitTypeArray = [];

        for(let i = 0; i < featureData.length; i++){
           console.log(featureData[i]);
           id.push(featureData[i]['id']);
           builtupMinArray.push(featureData[i]['builtup_area_min']);
           builtupMaxArray.push(featureData[i]['builtup_area_max']);
        //    plotMinArray.push(featureData[i]['property_id']);
        //    plotMaxArray.push(featureData[i]['property_id']);
           salePriceMinArray.push(featureData[i]['price_min']);
           salePriceMaxArray.push(featureData[i]['price_max']);
           totalUnitsArray.push(featureData[i]['total_units']);
           unitTypeArray.push(featureData[i]['unit_type']);
           localCurrencyArray.push(featureData[i]['local_currency']);
           featureImagesArray.push(featureData[i]['file']);
        }

        console.log(featureImagesArray);

        
        var formData = new FormData();
        
        
        for(let i = 0; i < featureData.length; i++){
            formData.append('id', id[i] != undefined ? id[i] : '');
            formData.append('builtup_area_min', builtupMinArray[i]);
            formData.append('builtup_area_max', builtupMaxArray[i]);
            // formData.append('plot_area_min', plotMinArray[i]);
            // formData.append('plot_area_max', plotMaxArray[i]);
            formData.append('price_min', salePriceMinArray[i]);
            formData.append('price_max', salePriceMaxArray[i]);
            formData.append('total_units', totalUnitsArray[i]);
            formData.append('unit_type', unitTypeArray[i]);
            formData.append('local_currency', localCurrencyArray[i]);
            formData.append('feature_image', featureImagesArray[i]);
        }
        formData.append('prop_unit_count', featureData.length.toString());
        formData.append('property_id', propertyId.toString());
        formData.append('created_by', userId!.toString());
        setPlanData(featureData);
        console.log('planData')
        console.log(formData)

        
   
        const updatePropData = await updatePropertyUnitType(formData);

        console.log('updatePropData');
        console.log(updatePropData);
        // document.getElementById('kt_contact')?.classList.remove('drawer-on');
        if(updatePropData != null){
            // setLoading(false);
            setDetailClicked(false);
            document.getElementById('kt_expand_close')?.click();
            var toastEl = document.getElementById('myToastUpdate');
            const bsToast = new Toast(toastEl!);
            bsToast.show();
        }

    }

    const formikAddress = useFormik({
        initialValues,
        validationSchema: propertyAddressUpdateSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
        //   console.log('lead form body');
        //   console.log(values);
        try {

        var userId = currentUser?.id;

        console.log('sddddddddassdsf');

        const body = {
            "name_of_building": values.name_of_building,
            "address_line1": values.address_line1,
            "country": values.country,
            "state": values.state,
            "city": values.city,
            "locality": values.locality,
            "pincode": values.pincode,
            "latitude": values.latitude,
            "longitude": values.longitude,
            "door_number": values.door_number,
            "module_number": values.module_number,
            "created_by": userId
        }

        const updatePropAddressData = await updatePropertyAddress(propertyId ,body);

        console.log('updatePropAddressData');
        console.log(updatePropAddressData);
        // document.getElementById('kt_contact')?.classList.remove('drawer-on');
        if(updatePropAddressData != null){
            setLoading(false);
            setDetailClicked(false);
            document.getElementById('kt_expand_close')?.click();
            var toastEl = document.getElementById('myToastUpdate');
            const bsToast = new Toast(toastEl!);
            bsToast.show();
            const propertyResponse = await getProperties(userId, roleId);
            setPropertyList(propertyResponse);
            // resetForm();
            // setAminityName([]);
            // setAssignToName([]);
            // setFurnishName([]);
            // setPosName([]);
        }

        } catch (error) {
        console.error(error)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
        }
    }})

    const formikNotes = useFormik({
        initialValues,
        validationSchema: notesFormSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          console.log('on presssed');
          setLoading(true)
          try {
    
            var userId = currentUser?.id;

            var notesBody = {
                "reply": values.reply,
                "property_id": propertyId,
                "user_id": userId,
                "parent_id": 0 
            };
                           
            const leadNotesData = await savePropertyNotes(notesBody)
    
            console.log('updateContactAddressData');
            console.log(leadNotesData);
            if(leadNotesData != null){
              setLoading(false);
              var toastEl = document.getElementById('myToastUpdate');
              const bsToast = new Toast(toastEl!);
              bsToast.show();
              resetForm();
              const propertyNoteResponse = await getPropertyNotes(propertyId);
                setPropertyNoteList(propertyNoteResponse);
            }
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
        },
      })
    
    const formikFeatures = useFormik({
        initialValues,
        validationSchema: propertyFeaturesUpdateSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
        //   console.log('lead form body');
        //   console.log(values);
        try {

        var userId = currentUser?.id;
        // var availableFor = availableName.join(',');
        // var furnishData = furnishName.join(',');
        // var aminityData = aminityName.join(',');
        var specsData = specsId.join(',');
        var siteVisitData = siteVisitId.join(',');
        
        console.log('sddddddddassdsf');

        const body = {
            "project_stage": values.project_stage,
            "age_of_property": values.age_of_property,
            "furnishing": values.furnishing,
            "sale_price": values.sale_price,
            "builtup_area_min": values.builtup_area_min,
            "builtup_area_max": values.builtup_area_max,
            "plot_area_min": values.plot_area_min,
            "plot_area_max": values.plot_area_max,
            "tower": values.tower,
            // "specification": specsData,
            "uds": values.uds,
            "no_of_floors": values.no_of_floors,
            "no_of_units_min": values.no_of_units_min,
            "no_of_units_max": values.no_of_units_max,
            "unit_type_min": values.unit_type_min,
            "unit_type_max": values.unit_type_max,
            "ownership_type": values.ownership_type,
            "balcony": values.balcony,
            "property_facing": values.property_facing,
            "kitchen_type": values.kitchen_type,
            "flooring": values.flooring,
            "vasthu_compliant": values.vasthu_compliant,
            "currently_under_loan": values.currently_under_loan,
            "available_from": values.available_from,
            "site_visit_preference": siteVisitData,
            "key_custody": values.key_custody,
            "no_of_car": values.no_of_car,
            "car_park_type": values.car_park_type,
            "water_supply": values.water_supply,
            "gated_community": values.gated_community,
            "amenities": aminityId,
            "property_highlight": values.property_highlight,
            "rera_registered": values.rera_registered,
            "rera_number": values.rera_number,
            "completion_certificate": values.completion_certificate,
            "created_by": userId
        }

        const updatePropFeaturesData = await updatePropertyFeatures(propertyId ,body);

        console.log('updatePropFeaturesData');
        console.log(updatePropFeaturesData);
        // document.getElementById('kt_contact')?.classList.remove('drawer-on');
        if(updatePropFeaturesData != null){
            setLoading(false);
            setDetailClicked(false);
            document.getElementById('kt_expand_close')?.click();
            var toastEl = document.getElementById('myToastUpdate');
            const bsToast = new Toast(toastEl!);
            bsToast.show();
            const propertyResponse = await getProjects(userId, roleId);
            setPropertyList(propertyResponse);
            // resetForm();
            // setAminityName([]);
            // setAssignToName([]);
            // setFurnishName([]);
            // setPosName([]);
        }

        } catch (error) {
        console.error(error)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
        }
    }})

    const sendMail = () => {
        // alert('mail')
    }
    const sendWatsapp = () => {
        // alert('watsapp')
    }
    const sendSMS = () => {
        // alert('sms')
    }
    const sendCall = () => {
        // alert('call')
    }

    const mailSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        subject: Yup.string().required('Subject is required'),       
        share_with: Yup.string().required('Share with is required'),
        module_id: Yup.string().required('Module is required'),       
        body: Yup.string().required('Body is required'),       
    })

    const formikMail = useFormik({
        initialValues,
        validationSchema: mailSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
          try {
              
            const body = {
                "title" : values.title,
                "subject" : values.subject,
                "share_with" : values.share_with,
                "module_id" :values.module_id,
                "body" : values.body,
            }
                
            console.log('lead form body');
            console.log(body);
            
                // const saveTemplatMailData = await saveTemplateMail(body);
            
                // if(saveTemplatMailData != null){
                //     setLoading(false);
                //     var toastEl = document.getElementById('myToastAdd');
                //     const bsToast = new Toast(toastEl!);
                //     bsToast.show();
                //     resetForm();
                //     AllTemplatesMailList();
                // }
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
    }})

    const [search, setSearch] = useState<any>("");
    const [filtered, setFiltered] = useState<any[]>([]);
    const [allTemplatesMail, setAllTemplatesMail] = useState<any[]>([]);


    const AllTemplatesMailList =  async () => {
        const AllTemplatesMailResponse = await getAllTemplatesMail()
        setAllTemplatesMail(AllTemplatesMailResponse);
    }

    const MailById = async (id:any) => {
        // setSelectedId(id);
        // const TemplatesMailResponse = await getTemplateMail(id)
        // setTemplatesMail(TemplatesMailResponse);
        // setDataBinded(true);
        // formik.setFieldValue('title', TemplatesMailResponse.title ?? '');
        // formik.setFieldValue('subject', TemplatesMailResponse.subject ?? '');
        // formik.setFieldValue('share_with', TemplatesMailResponse.share_with ?? '');
        // formik.setFieldValue('module_id', TemplatesMailResponse.module_id ?? '');
        // formik.setFieldValue('body', TemplatesMailResponse.body ?? '');
    }

    function filterItem(item: any, search: string) {
        if (search.startsWith("@")) {
          const bucket = search.substring(1).split(":");
          const searchBy = bucket[0];
          const searchFor = bucket[1];
          const searchByType = getType(item[searchBy]);
      
          // When we want to limit the search by provided property types
          // i.e. filterBy: ["tags", "flavors", "ip", ...];
          // if (item.filterBy && item.filterBy.length) {
          //     if (!item.filterBy.includes(searchBy)) return false;
          // }
      
          if (!searchFor) return false;
          console.log("**** searchBy: ", searchBy);
          console.log("**** searchFor: ", searchFor);
          console.log("**** item: ", item);
          // When search is @status:error
          if (searchByType == "string") {
            // When search is @status:!error
            if (searchFor.startsWith("!")) {
              const bucket = searchFor.split("!");
              const searchForVal = bucket[1];
              return !item[searchBy].includes(searchForVal);
            }
            return item[searchBy].includes(searchFor);
          }
      
          // When search is @flavors:small..
          if (searchByType == "array") {
            // When search is @flavors:!small
            if (searchFor.startsWith("!")) {
              const bucket = searchFor.split("!");
              const searchForVal = bucket[1];
              return item[searchBy].find((val: string | any[]) => !val.includes(searchForVal));
            }
            return item[searchBy].find((val: string | any[]) => val.includes(searchFor));
          }
      
          // When search is @tags:org=sales
          if (searchByType == "object") {
            // When search is @tags:!sales
            if (searchFor.startsWith("!")) {
              const bucket = searchFor.split("!");
              const val = bucket[1] || "";
              return !item[searchBy][val];
            }
            // When search is @tags:org!=sales
            if (searchFor.includes("!=")) {
              const bucket2 = searchFor.split("!=");
              const key = bucket2[0];
              const val = bucket2[1] || "";
              return item[searchBy][key] && !item[searchBy][key].includes(val);
            }
            const bucket2 = searchFor.split("=");
            const key = bucket2[0];
            const val = bucket2[1] || "";
            return item[searchBy][key] && item[searchBy][key].includes(val);
          }
        } else {
          // When search is !computer.....
          if (search.startsWith("!")) {
            const bucket = search.split("!");
            const searchFor = bucket[1];
            return !item.title.includes(searchFor);
          }
          // When search is computer.....
          return item.title.includes(search);
        }
      }
      
      const getType = (value:any) => {
        if (Array.isArray(value)) {
          return "array";
        } else if (typeof value == "string") {
          return "string";
        } else if (value == null) {
          return "null";
        } else if (typeof value == "boolean") {
          return "boolean";
        } else if (Number(value) == value) {
          return "number";
        } else if (typeof value == "object") {
          return "object";
        }
        return "string";
      };

    useEffect(() => {
        AllTemplatesMailList();
        var filteredData: any[] = [];
        if (search.length > 0) {
            allTemplatesMail.forEach((item) => {
            if (filterItem(item, search)) {
            filteredData.push({ ...item });
            }
        });
        } else {
            filteredData = [];
        }
        setFiltered(filteredData);        
    }, [search]);

    return(
        <div className={isExpand ? isFullScreen ? "w-100 contact_details_page full_screen" : "w-75 contact_details_page full_screen m-5": "contact_details_page small_screen d-flex align-items-end justify-content-end m-5"}>
            { isLoading ? 
            <div className="card main_bg h-100">
                <div className='w-100 h-100'>
                    <div className='d-flex justify-content-center flex-column align-items-center vh-100'>
                        <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
                        <div className="spinner-border taskloader" style={{width:"3rem", height: "3rem"}} role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div> 
                </div> 
            </div> :
            isExpand ?
            <div className="card main_bg h-100">
                <div className="card-header d-flex align-items-center">
                    <div className="row w-100">
                        <div className="col-sm-12 col-12 d-flex align-items-center justify-content-end">
                            <div className='card-toolbar'>
                                <button className="btn mx-3 expand_btn" onClick={fullScreenChange}>
                                    <img src={isFullScreen ? toAbsoluteUrl('/media/custom/overview-icons/comp_white.svg') : toAbsoluteUrl('/media/custom/overview-icons/expand_white.svg')} className="svg_icon" alt='' />
                                </button>
                                <button className="btn mx-3 minimize_btn" onClick={minimaximize}>
                                    <img src={toAbsoluteUrl('/media/custom/overview-icons/minimize_white.svg')} className="svg_icon" alt='' />
                                </button>
                                <button
                                    type='button'
                                    className='btn  me-n5 mx-3 close_btn'
                                    id='kt_expand_close'
                                    data-bs-dismiss="offcanvas"
                                    onClick={closeDialogue}
                                    >
                                        <img src={toAbsoluteUrl('/media/custom/overview-icons/cancel_white.svg')} className="svg_icon" alt='' />
                                </button>
                            </div>
                        </div>
                        {/* <div className="col-sm-9 col-12 d-flex align-items-center justify-content-end">
                            <div className="input-group search_btn me-3">
                                <input type="text" className="form-control" placeholder="Search"/>
                                <div className="input-group-append">
                                    <button className="btn bg_primary" type="button">
                                        <img src={toAbsoluteUrl('/media/custom/search_white.svg')} className="svg_icon" alt='' />
                                    </button>
                                </div>
                            </div>
                            <a className="me-4 btn btn-sm me-4 btn_primary" id='kt_contact_toggle'>Add+</a>
                            <select className="form-select toggle_white me-4 btn btn-sm btn_primary" name="action" id="action">
                                <option value="action">Action</option>
                                <option value="add">Add</option>
                                <option value="inport">Import</option>
                                <option value="edit">Edit</option>
                            </select>
                        </div> */}
                    </div>
                    {/* <h3>Contact Full screen</h3> */}
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="card-group">
                            <div className="col-xxl-6 col-12 mb-3">
                                <div className="card bs_1 name_card h-100 mx-2">
                                    <div className="card-body p-3">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0">
                                                <img src={toAbsoluteUrl('/media/avatars/300-23.jpg')} className="user_img" alt='' />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <div className="row">
                                                    <div className="col-sm-6 d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex">
                                                                <h5 className="mb-0 ms-2 mb-2">{propDetail.company_name ?? 'Company name'}</h5>
                                                            </div>
                                                            <p className="mb-0 ms-2">{propDetail.name_of_building ?? 'Project Name'}</p>
                                                        </div>
                                                        <div className="d-flex align-items-center me-2 mt-2">
                                                            <img src={toAbsoluteUrl('/media/custom/lead/assign_9.svg')} alt="" className="icon me-2"/>
                                                            <p className="ml-2 mb-0 fixed_text">{propDetail.contact_name}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 d-flex align-items-center justify-content-end">
                                                        <a href={"mailto:"+ propDetail.email} className="btn_soft_primary"><i className="fas fa-envelope"></i></a>
                                                        <a href={"tel:"+ propDetail.mobile} className="btn_soft_primary"><i className="fas fa-phone-alt"></i></a>
                                                        <a href="#" className="btn_soft_primary"><i className="fas fa-clipboard-list"></i></a>
                                                        <a href={"https://api.whatsapp.com/send?phone=" + propDetail.mobile} className="btn_soft_primary">
                                                            <img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} className="svg_icon" alt='' />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-6 col-12 mb-3">
                                <div className="card bs_1 h-100 mx-2 info_card">
                                    <div className="card-body p-3">
                                        <div className='row w-100 p-3'>
                                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 p-2">
                                                <small className="mb-0">Email</small>
                                                <p className="mb-0">{propDetail.email}</p>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 p-2">
                                                <small className="mb-0">Phone Number</small>
                                                <p className="mb-0">{propDetail.mobile}</p>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 p-2">
                                                <small className="mb-0">Contact Category</small>
                                                <p className="mb-0">{propDetail.contact_category_name}</p>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 p-2">
                                                <small className="mb-0">Created By</small>
                                                <p className="mb-0">{propDetail.created_by_name}</p>
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab_container bg_white br_10 bs_1">
                        <div className="row mt-4">
                            <div className="col-12">
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'overview' ? "nav-link active" : "nav-link"} id={"overview-tab"+propertyId} data-bs-toggle="pill" data-bs-target={"#overview"+propertyId} type="button" role="tab" aria-controls="overview" aria-selected="true">Overview</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'notes' ? "nav-link active" : "nav-link"} id={"notes-tab"+propertyId} data-bs-toggle="pill" data-bs-target={"#notes"+propertyId} type="button" role="tab" aria-controls="notes" aria-selected="false">Notes</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'files' ? "nav-link active" : "nav-link"} id={"files-tab"+propertyId} data-bs-toggle="pill" data-bs-target={"#files"+propertyId} type="button" role="tab" aria-controls="files" aria-selected="false">Files</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'message' ? "nav-link active" : "nav-link"} id={"message-tab"+propertyId} data-bs-toggle="pill" data-bs-target={"#message"+propertyId} type="button" role="tab" aria-controls="message" aria-selected="false">Messages</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id={"address-tab"+propertyId} data-bs-toggle="pill" data-bs-target={"#address"+propertyId} type="button" role="tab" aria-controls="address" aria-selected="false">Project Address</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id={"features-tab"+propertyId} data-bs-toggle="pill" data-bs-target={"#features"+propertyId} type="button" role="tab" aria-controls="features" aria-selected="false">Project Features</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id={"features-list-tab"+propertyId} data-bs-toggle="pill" data-bs-target={"#features-list"+propertyId} type="button" role="tab" aria-controls="features-list" aria-selected="false">Project Unit List</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'sec_contact' ? "nav-link active" : "nav-link"} id={"contact-tab"+propertyId} data-bs-toggle="pill" data-bs-target={"#contact"+propertyId} type="button" role="tab" aria-controls="contact" aria-selected="false">Sec.contact</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'lead' ? "nav-link active" : "nav-link"} id={"lead-tab"+propertyId} data-bs-toggle="pill" data-bs-target={"#lead"+propertyId} type="button" role="tab" aria-controls="lead" aria-selected="false">Lead</button>
                                    </li>
                                    {/* <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'duplicate' ? "nav-link active" : "nav-link"} id={"duplicate-tab"+propertyId} data-bs-toggle="pill" data-bs-target={"#duplicate"+propertyId} type="button" role="tab" aria-controls="duplicate" aria-selected="false">Duplicate</button>
                                    </li> */}
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'task' ? "nav-link active" : "nav-link"} id={"tasks-tab"+propertyId} data-bs-toggle="pill" data-bs-target={"#tasks"+propertyId} type="button" role="tab" aria-controls="tasks" aria-selected="false">Tasks</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'timeline' ? "nav-link active" : "nav-link"} id={"timeline-tab"+propertyId} data-bs-toggle="pill" data-bs-target={"#timeline"+propertyId} type="button" role="tab" aria-controls="timeline" aria-selected="false">Active timeline</button>
                                    </li>
                                </ul>
                                <div className="tab-content pt-5" id="pills-tabContent">
                                    <div className={tabInfo == 'overview' ? "tab-pane fade show active": "tab-pane fade"} id={"overview"+propertyId} role="tabpanel" aria-labelledby={"overview-tab"+propertyId}>
                                        <form noValidate onSubmit={formik.handleSubmit}>
                                            <div className="row">
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label required">Contact Person</label>
                                                    <div className="input-group mb-3 input_prepend py-1">
                                                        <select className="btn btn-sm w-100 text-start form-select" {...formik.getFieldProps('contact_id')}>
                                                        <option value=''>Select</option>
                                                            {contacts.map((contactsVal,i) =>{
                                                                return (
                                                                    <option value={contactsVal.id} selected={contactsVal.id == propDetail.contact_id} key={i}>{contactsVal.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div> 
                                                    {formik.touched.contact_id && formik.errors.contact_id && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert' className='text-danger'>{formik.errors.contact_id}</span>
                                                        </div>
                                                    </div>
                                                    )}
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3 edit_page_form">
                                                    <label htmlFor="basic-url" className="form-label">Available for</label>
                                                    <FormControl sx={{ m: 0, width: "100%", mt: 0 }}>
                                                        <Select
                                                            multiple
                                                            displayEmpty
                                                            value={availableName}
                                                            onChange={availableChange}
                                                            input={<OutlinedInput />}
                                                            renderValue={(selected) => {
                                                                var name = [];
                                                                var id = [];

                                                                for(let i = 0; i < selected.length; i++){
                                                                    var fields = selected[i].split('-');

                                                                    var n = fields[0];
                                                                    var d = fields[1];

                                                                    name.push(n);
                                                                    id.push(d);
                                                                }
                                                                if (selected.length === 0) {
                                                                return <p>Available For</p>;
                                                                }

                                                                return name.join(', ');
                                                            }}
                                                            className='multi_select_field form-control'
                                                            MenuProps={MenuProps}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                            >
                                                            <MenuItem disabled value="">
                                                                <em>Available For</em>
                                                            </MenuItem>
                                                            {availableFor.map((available) => (
                                                                <MenuItem
                                                                key={available.id}
                                                                value={available.name+ '-' +available.id}
                                                                style={getStyles(available.name, availableName, theme)}
                                                                >
                                                                {available.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3 edit_page_form">
                                                <label htmlFor="basic-url" className="form-label">Assign to</label>
                                                <FormControl sx={{ m: 0, width: '100%', mt: 0 }}>
                                                    <Select
                                                        multiple
                                                        displayEmpty
                                                        value={assignToName}
                                                        onChange={assingToChange}
                                                        input={<OutlinedInput />}
                                                        renderValue={(selected) => {
                                                            var name = [];
                                                            var id = [];

                                                            for(let i = 0; i < selected.length; i++){
                                                            var fields = selected[i].split('-');

                                                            var n = fields[0];
                                                            var d = fields[1];

                                                            name.push(n);
                                                            id.push(d);
                                                            }

                                                            // setAssignToId(id);

                                                            console.log('selected');
                                                            console.log(selected);
                                                            if (selected.length === 0) {
                                                                return <p>Assign To</p>;
                                                            }

                                                            return name.join(', ');
                                                        }}
                                                        className='multi_select_field form-control'
                                                        MenuProps={MenuProps}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        >
                                                        <MenuItem disabled value="">
                                                            <em>Assign To</em>
                                                        </MenuItem>
                                                        {assignTo.map((assignVal) => (
                                                            <MenuItem
                                                            key={assignVal.user_id}
                                                            value={assignVal.employee_name+'-'+assignVal.user_id}
                                                            style={getStyles(assignVal.employee_name, assignToName, theme)}
                                                            >
                                                            {assignVal.employee_name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    </FormControl>
                                                </div>
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Commission</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <input type="text" className="form-control" {...formik.getFieldProps('commission')} placeholder="0"/>
                                                        <span className="input-group-text">%</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label required">Project Indepth</label>
                                                    <div className="input-group mb-3 input_prepend py-1">
                                                        <select {...formik.getFieldProps('property_indepth')}
                                                            className={clsx(
                                                            'btn btn-sm w-100 text-start form-select',
                                                            {
                                                                'is-invalid': formik.touched.property_indepth && formik.errors.property_indepth,
                                                            },
                                                            {
                                                                'is-valid': formik.touched.property_indepth && !formik.errors.property_indepth,
                                                            }
                                                            )} name="property_indepth" >
                                                                <option value=''>Select</option>
                                                            {propertyInDepth.map((propertyInDepthVal,i) =>{
                                                                return (
                                                                <option value={propertyInDepthVal.id} selected={propertyInDepthVal.id == propDetail.property_indepth} key={i}>{propertyInDepthVal.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div> 
                                                    {formik.touched.property_indepth && formik.errors.property_indepth && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert' className='text-danger'>{formik.errors.property_indepth}</span>
                                                        </div>
                                                    </div>
                                                    )}
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Segment</label>
                                                    <div className="input-group mb-3 input_prepend py-1">
                                                        <select className="btn btn-sm w-100 text-start form-select" {...formik.getFieldProps('segment')}>
                                                        <option value=''>Select</option>
                                                            {segment.map((segmentVal,i) =>{
                                                                return (
                                                                    <option value={segmentVal.id} selected={segmentVal.id == propDetail.segment} key={i}>{segmentVal.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div> 
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Project Type</label>
                                                    <div className="input-group mb-3 input_prepend py-1">
                                                        <select className="btn btn-sm w-100 text-start form-select" {...formik.getFieldProps('property_type')}>
                                                        <option value=''>Select</option>
                                                            {propertyType.map((propertyVal,i) =>{
                                                                return (
                                                                    <option value={propertyVal.id} selected={propertyVal.id == propDetail.property_type} key={i}>{propertyVal.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div> 
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Project Status</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <select className="btn btn-sm w-100 text-start form-select" {...formik.getFieldProps('property_status')}>
                                                        <option value=''>Select</option>
                                                            {propertyStatus.map((propertyStatusVal,i) =>{
                                                                return (
                                                                    <option selected={propertyStatusVal.id == propDetail.property_status} value={propertyStatusVal.id} key={i}>{propertyStatusVal.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div> 
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Project Source</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <select className="btn btn-sm w-100 text-start form-select" {...formik.getFieldProps('property_source')}>
                                                        <option value=''>Select</option>
                                                            {propertySource.map((propertySourceVal,i) =>{
                                                                return (
                                                                    <option selected={propertySourceVal.id == propDetail.property_source} value={propertySourceVal.id} key={i}>{propertySourceVal.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div> 
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Description</label>
                                                    <textarea
                                                        className='form-control border-0 p-2 resize-none min-h-25px br_10'
                                                        data-kt-autosize='true'
                                                        {...formik.getFieldProps('description')} 
                                                        rows={7}
                                                        placeholder='Description'
                                                    ></textarea>
                                                </div> 
                                                <div className="col-12 d-flex justify-content-center mb-4">
                                                    <button
                                                        type='submit'
                                                        id='kt_sign_up_submit'
                                                        className='btn btn_primary text-primary'
                                                        disabled={formik.isSubmitting}
                                                        >
                                                        {!loading && <span className='indicator-label'>Save
                                                        <KTSVG
                                                        path='/media/custom/save_white.svg'
                                                        className='svg-icon-3 svg-icon-primary ms-2'
                                                        />
                                                        </span>}
                                                        {loading && (
                                                            <span className='indicator-progress' style={{display: 'block'}}>
                                                            Please wait...{' '}
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                            </span>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className={tabInfo == 'notes' ? "tab-pane fade show active": "tab-pane fade"} id={"notes"+propertyId} role="tabpanel" aria-labelledby={"notes-tab"+propertyId}>
                                    <div className="card mb-5 mb-xl-8">
                                            <div className='card-body pb-0'>
                                            <div className='main_bg px-lg-5 px-4 pt-4 pb-1 mb-5'>
                                                    <div className='d-flex align-items-center mb-3'>
                                                    
                                                        {/* begin::User */}
                                                        <div className='d-flex align-items-center flex-grow-1'>
                                                            {/* begin::Info */}
                                                            <div className='d-flex flex-column'>
                                                                <h3 className='text-gray-800 text-hover-primary fs-6 fw-bolder'>
                                                                    Add Note
                                                                </h3>
                                                            </div>
                                                            {/* end::Info */}
                                                        </div>
                                                        {/* end::User */}
                                                    </div>
                                                    <form noValidate onSubmit={formikNotes.handleSubmit} className='position-relative mb-6 pb-4 border-bottom border-secondary'>
                                                        <input {...formikNotes.getFieldProps('reply')}
                                                            className='form-control border-0 p-0 pe-10 resize-none min-h-25px'
                                                            data-kt-autosize='true'
                                                            // rows={1}
                                                            placeholder='Ask anything...'></input>

                                                        <div className='position-absolute top-0 end-0 me-n5'>
                                                            {/* <span className='btn btn-icon btn-sm btn-active-color-primary pe-0 me-2'>
                                                            <KTSVG
                                                                path='/media/icons/duotune/communication/com012.svg'
                                                                className='svg-icon-3 mb-3'
                                                            />
                                                            </span> */}

                                                            <button type='submit' disabled={formikNotes.isSubmitting} className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                <KTSVG path='/media/icons/duotune/general/gen016.svg' className='svg-icon-2 mb-3' />
                                                            </button>
                                                        </div>
                                                        {formikNotes.touched.reply && formikNotes.errors.reply && (
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>
                                                                <span role='alert' className='text-danger'>{formikNotes.errors.reply}</span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </form>
                                                </div>
                                                <div className='notes_list mt-4 card-body'>
                                                    <h4 className='mb-3'>Notes List</h4>
                                                    <hr/>
                                                    {PropertyNoteList.map((propertyNote, i) => {
                                                        return (
                                                            <div className='mb-5' key={propertyNote.id}>
                                                                {propertyNote.reply1 == 'NO'
                                                                ? <div className='note_question'>
                                                                    {/* begin::Header */}
                                                                    <div className='d-flex align-items-center mb-3'>
                                                                        {/* begin::User */}
                                                                        <div className='d-flex align-items-center flex-grow-1'>
                                                                            {/* begin::Avatar */}
                                                                            <div className='symbol symbol-45px me-5'>
                                                                            <img onError={e => { e.currentTarget.src = toAbsoluteUrl('/media/avatars/300-6.jpg') }} src={propertyNote.user_profile_image != '' ? propertyNote.user_profile_image ?? toAbsoluteUrl('/media/avatars/300-6.jpg') : toAbsoluteUrl('/media/avatars/300-6.jpg')} alt='' />
                                                                            </div>
                                                                            {/* end::Avatar */}
        
                                                                            {/* begin::Info */}
                                                                            <div className='d-flex flex-column'>
                                                                            <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bolder'>
                                                                                {propertyNote.property_name ?? 'User'}
                                                                            </a>
                                                                            <span className='text-gray-400 fw-bold'>{Moment(propertyNote.created_at).format("DD-MMMM-YYYY HH:mm")}</span>
                                                                            </div>
                                                                            {/* end::Info */}
                                                                        </div>
                                                                        {/* end::User */}
                                                                    </div>
                                                                    {/* end::Header */}
        
                                                                    {/* begin::Post */}
                                                                    <div className='mb-7 pb-5 border-bottom border-secondary d-flex justify-content-between'>
                                                                        {/* begin::Text */}
                                                                        { noteEditVal != '' && parentId == propertyNote.id ?
                                                                            <div className='text-gray-800 position-relative w-75'>
                                                                                <input
                                                                                    className='form-control border-0 p-0 pe-10 resize-none min-h-25px'
                                                                                    data-kt-autosize='true'
                                                                                    // rows={1}
                                                                                    placeholder='Reply..'
                                                                                    id={'edit_field'+propertyNote.id}
                                                                                    defaultValue={noteEditVal}
                                                                                    // onChange={(e) => childReplyChange(e)}
                                                                                ></input>
                                                                                {/* <div className='position-absolute top-0 end-0 me-n5'>     
                                                                                    <button type='button' onClick={() => editOnSubmit(contactNote.id)} className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                    <KTSVG path='/media/icons/duotune/general/gen016.svg' className='svg-icon-2 mb-3' />
                                                                                    </button>
                                                                                </div> */}
                                                                            </div>
                                                                            : 
                                                                            <div className='text-gray-800'>
                                                                             {propertyNote.reply}
                                                                            </div>
                                                                            }
                                                                            { currentUser?.id == propertyNote.user_id ?
                                                                            <span>
                                                                                { noteEditVal != '' && parentId == propertyNote.id ?
                                                                                <><button type='button' onClick={() => cancelEdit(propertyNote.id)} className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-icon-2 mb-3"><path opacity="0.3" d="M6.7 19.4L5.3 18C4.9 17.6 4.9 17 5.3 16.6L16.6 5.3C17 4.9 17.6 4.9 18 5.3L19.4 6.7C19.8 7.1 19.8 7.7 19.4 8.1L8.1 19.4C7.8 19.8 7.1 19.8 6.7 19.4Z" fill="black"></path><path opacity="0.3" d="M19.5 18L18.1 19.4C17.7 19.8 17.1 19.8 16.7 19.4L5.40001 8.1C5.00001 7.7 5.00001 7.1 5.40001 6.7L6.80001 5.3C7.20001 4.9 7.80001 4.9 8.20001 5.3L19.5 16.6C19.9 16.9 19.9 17.6 19.5 18Z" fill="black"></path></svg>
                                                                                        </button>
                                                                                        <button type='button' onClick={() => editOnSubmit(propertyNote.id)} className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                                <KTSVG path='/media/icons/duotune/general/gen016.svg' className='svg-icon-2 mb-3' />
                                                                                            </button></>:
                                                                                <button type='button' onClick={() => replyEdit(propertyNote.id, propertyNote.reply)} className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                <KTSVG path="/media/icons/duotune/art/art005.svg" className="svg-icon-2 mb-3"/>
                                                                                </button>}
                                                                                {currentUser?.designation == 1 && <button type='button'
                                                                                data-bs-toggle='modal'
                                                                                data-bs-target={'#delete_note_popup'+propertyNote.id} 
                                                                                className='btn btn-icon btn-sm btn-active-color-danger ps-0'>
                                                                                <KTSVG path="/media/icons/duotune/general/gen027.svg" className="svg-icon-2 mb-3" />
                                                                                </button> }
                                                                            </span> : currentUser?.designation == 1 ?
                                                                            <span>
                                                                                <button type='button' data-bs-toggle='modal'
                                                                                data-bs-target={'#delete_note_popup'+propertyNote.id}  className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                <KTSVG path="/media/icons/duotune/general/gen027.svg" className="svg-icon-2 mb-3" />
                                                                                </button>
                                                                            </span> : <></>
                                                                            }
                                                                            {/* confirm modal */}
                                                                            <div className='modal fade' id={'delete_note_popup'+propertyNote.id} aria-hidden='true'>
                                                                                <div className='modal-dialog modal-dialog-centered'>
                                                                                    <div className='modal-content'>
                                                                                        <div className='modal-header'>
                                                                                            <h3>Confirmation</h3>
                                                                                            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='modal-body py-lg-10 px-lg-10'>
                                                                                            <p>Are you sure want to delete?</p>
                                                                                            <div className='d-flex align-items-center justify-content-end'>
                                                                                                <button className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal'>
                                                                                                    No
                                                                                                </button>
                                                                                                <button className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal' onClick={(e) => replyDelete(propertyNote.id, propertyNote.parent_id)}>
                                                                                                    Yes
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        {/* end::Text */}
                                                                    </div>
                                                                    {/* end::Post */}
                                                                </div> :
                                                                <div className='mb-7 ps-10 note_answer'>
                                                                    <div className='d-flex mb-5'>
        
                                                                        <div className='symbol symbol-45px me-5'>
                                                                            <img src={propertyNote.user_profile_image != '' ? propertyNote.user_profile_image ?? toAbsoluteUrl('/media/avatars/300-6.jpg') : toAbsoluteUrl('/media/avatars/300-6.jpg')} alt='' />
                                                                        </div>
        
                                                                        <div className='d-flex flex-column flex-row-fluid'>
                                                                        <div className='d-flex align-items-center flex-wrap mb-1'>
                                                                            <a href='#' className='text-gray-800 text-hover-primary fw-bolder me-2'>
                                                                            {propertyNote.user_name ?? 'User'}
                                                                            </a>
        
                                                                            <span className='text-gray-400 fw-bold fs-7'>{Moment(propertyNote.created_at).format("DD-MMMM-YYYY HH:mm")}</span>
                                                                        </div>
                                                                        
                                                                        <div className=' d-flex justify-content-between'>                                            
                                                                            { noteEditVal != '' && parentId == propertyNote.id ?
                                                                            <div className='text-gray-800 position-relative w-75'>
                                                                                <input
                                                                                    className='form-control border-0 p-0 pe-10 resize-none min-h-25px'
                                                                                    data-kt-autosize='true'
                                                                                    // rows={1}
                                                                                    placeholder='Reply..'
                                                                                    id={'edit_field'+propertyNote.id}
                                                                                    defaultValue={noteEditVal}
                                                                                    // onChange={(e) => childReplyChange(e)}
                                                                                ></input>
                                                                                {/* <div className='position-absolute top-0 end-0 me-n5'>     
                                                                                    <button type='button' onClick={() => editOnSubmit(contactNote.id)} className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                    <KTSVG path='/media/icons/duotune/general/gen016.svg' className='svg-icon-2 mb-3' />
                                                                                    </button>
                                                                                </div> */}
                                                                            </div>
                                                                            : 
                                                                            <div className='text-gray-800'>
                                                                             {propertyNote.reply}
                                                                            </div>
                                                                            } 
                                                                                <span>
                                                                                { currentUser?.id == propertyNote.user_id ?
                                                                            <span>
                                                                                { noteEditVal != '' && parentId == propertyNote.id ?
                                                                                <><button type='button' onClick={() => cancelEdit(propertyNote.id)} className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-icon-2 mb-3"><path opacity="0.3" d="M6.7 19.4L5.3 18C4.9 17.6 4.9 17 5.3 16.6L16.6 5.3C17 4.9 17.6 4.9 18 5.3L19.4 6.7C19.8 7.1 19.8 7.7 19.4 8.1L8.1 19.4C7.8 19.8 7.1 19.8 6.7 19.4Z" fill="black"></path><path opacity="0.3" d="M19.5 18L18.1 19.4C17.7 19.8 17.1 19.8 16.7 19.4L5.40001 8.1C5.00001 7.7 5.00001 7.1 5.40001 6.7L6.80001 5.3C7.20001 4.9 7.80001 4.9 8.20001 5.3L19.5 16.6C19.9 16.9 19.9 17.6 19.5 18Z" fill="black"></path></svg>
                                                                                        </button>
                                                                                        <button type='button' onClick={() => editOnSubmit(propertyNote.id)} className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                                <KTSVG path='/media/icons/duotune/general/gen016.svg' className='svg-icon-2 mb-3' />
                                                                                            </button></>:
                                                                                <button type='button' onClick={() => replyEdit(propertyNote.id, propertyNote.reply)} className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                <KTSVG path="/media/icons/duotune/art/art005.svg" className="svg-icon-2 mb-3"/>
                                                                                </button>}
                                                                                {currentUser?.designation == 1 && <button type='button'
                                                                                data-bs-toggle='modal'
                                                                                data-bs-target={'#delete_note_popup2'+propertyNote.id} 
                                                                                className='btn btn-icon btn-sm btn-active-color-danger ps-0'>
                                                                                <KTSVG path="/media/icons/duotune/general/gen027.svg" className="svg-icon-2 mb-3" />
                                                                                </button> }
                                                                            </span> : currentUser?.designation == 1 ?
                                                                            <span>
                                                                                <button type='button' data-bs-toggle='modal'
                                                                                data-bs-target={'#delete_note_popup2'+propertyNote.id}  className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                <KTSVG path="/media/icons/duotune/general/gen027.svg" className="svg-icon-2 mb-3" />
                                                                                </button>
                                                                            </span> : <></>
                                                                            }
                                                                                </span>
                                                                            </div>
                                                                    
                                                                        </div>
                                                                             {/* confirm modal */}
                                                                <div className='modal fade' id={'delete_note_popup2'+propertyNote.id} aria-hidden='true'>
                                                                                <div className='modal-dialog modal-dialog-centered'>
                                                                                    <div className='modal-content'>
                                                                                        <div className='modal-header'>
                                                                                            <h3>Confirmation</h3>
                                                                                            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='modal-body py-lg-10 px-lg-10'>
                                                                                            <p>Are you sure want to delete?</p>
                                                                                            <div className='d-flex align-items-center justify-content-end'>
                                                                                                <button className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal'>
                                                                                                    No
                                                                                                </button>
                                                                                                <button className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal' onClick={(e) => replyDelete(propertyNote.id, propertyNote.parent_id)}>
                                                                                                    Yes
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div> 
                                                                    </div>
                                                                </div>
                                                                }
                                                                
        
                                                                {/* begin::Replies */}
        
                                                                {/* end::Replies */}
        
                                                                {/* begin::Reply input */}
                                                                {/* <form noValidate onSubmit={() => replyOnSubmit(contactNote.id)}> */}
                                                                {propertyNote.reply1 == 'NO' && 
                                                                <>
                                                                <div className='position-relative mb-6'>
                                                                        <input
                                                                            className='form-control border-0 p-0 pe-10 resize-none min-h-25px'
                                                                            data-kt-autosize='true'
                                                                            // rows={1}
                                                                            placeholder='Reply..'
                                                                            id={'child_reply'+propertyNote.id}
                                                                            // onChange={(e) => childReplyChange(e, contactNote.id)}
                                                                        ></input>
                                                                        
                                                                        <div className='position-absolute top-0 end-0 me-n5'>
        
                                                                            <button type='button' onClick={() => replyOnSubmit(propertyNote.id)} className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                <KTSVG path='/media/icons/duotune/general/gen016.svg' className='svg-icon-2 mb-3' />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    {isFormError && propertyNote.id == parentId && (
                                                                        <div className='fv-plugins-message-container'>
                                                                            <div className='fv-help-block'>
                                                                            <span role='alert' className='text-danger'>Reply need to fill</span>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    </>
                                                                    }
                                                                    
                                                                    <div className='separator mb-4'></div>
                                                                {/* </form> */}
                                                                {/* edit::Reply input */}
                                                            </div>
                                                                )
                                                    })}
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={tabInfo == 'files' ? "tab-pane fade show active": "tab-pane fade"} id={"files"+propertyId} role="tabpanel" aria-labelledby={"files-tab"+propertyId}>
                                    <div {...getRootProps({className: 'dropzone'})}>
                                            <div className='myDIV'>
                                                {/* <div className='d-flex align-items-center justify-content-center h-100 vh-25'>
                                                    <input {...getInputProps()} />
                                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                                </div> */}
                                                <div className="d-flex align-items-center justify-content-center w-100 h-100 vh-25">
                                                    <span className="btn btn-file w-100 h-100">
                                                        {/* <i className="fa fa-upload my-2" aria-hidden="true"></i> */}
                                                        <KTSVG
                                                        path='/media/icons/duotune/files/fil022.svg'
                                                        className='svg-icon-1 text_primary ms-2'
                                                        />
                                                        <p className='text_primary'>Upload Files Here</p>
                                                        <small className='text-dark'>* Note: jpg, jpeg, pdf only acceptable</small>
                                                        <input {...getInputProps()}/>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <aside style={thumbsContainer}>
                                            {thumbs}
                                        </aside>
                                        <div className='p-5 text-end'>
                                            <button
                                                type='button'
                                                id='kt_sign_up_submit'
                                                className='btn btn_primary text-primary'
                                                // disabled={formik.isSubmitting}
                                                onClick={saveFiles}
                                                >
                                                {!loading && <span className='indicator-label'>Save
                                                <KTSVG
                                                path='/media/custom/save_white.svg'
                                                className='svg-icon-3 svg-icon-primary ms-2'
                                                />
                                                </span>}
                                                {loading && (
                                                    <span className='indicator-progress' style={{display: 'block'}}>
                                                    Please wait...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                        {filesVal.length > 0 && <>
                                        <div className='main_bg p-4 mb-9 rounded'>
                                            
                                            <h4>Files</h4>
                                            <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
                                            {filesVal.map((file, i) => {
                                                const pieces = file.fileoriginalname.split('.');
                                                const last = pieces[pieces.length - 1];
                                                console.log('sdsdsdsdsdsd');
                                                console.log(imgFullView);
                                                console.log(process.env.REACT_APP_API_BASE_URL+'uploads/property/files/'+file.lead_id+'/'+file.file);
                                                return ( 
                                                    <>
                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                                                        <div className="card h-100">
                                                            <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                                                            <a href="#" data-bs-toggle='modal'
                                                            data-bs-target={'#delete_confirm_popup'+file.id} className="btn delete_btn btn-icon btn-bg-light btn-active-color-danger btn-sm"><span className="svg-icon svg-icon-4"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"></path><path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"></path><path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"></path></svg></span></a>
                                                                <a href="#" className="text-gray-800 text-hover-primary d-flex flex-column">
                                                                    {last != 'pdf' ? 
                                                                    <a className={imgFullView && imgSelect == file.id ? "img_full_view" : "symbol symbol-75px"} onClick={() => imgViewChange(file.id)}>
                                                                        <img onError={e => { e.currentTarget.src = toAbsoluteUrl('/media/svg/files/doc.svg') }} src={process.env.REACT_APP_API_BASE_URL+'uploads/property/files/'+file.property_id+'/'+file.file} alt=""/>
                                                                        <div className="fs-5 fw-bolder text-dark mb-2">{file.fileoriginalname}</div>
                                                                        <div className="fs-7 fw-bold text-gray-400 mt-auto">{Moment(file.created_at).format("DD-MMMM-YYYY")}</div>
                                                                    </a>
                                                                    :
                                                                    <a className="symbol symbol-75px" href={process.env.REACT_APP_API_BASE_URL+'uploads/property/files/'+file.property_id+'/'+file.file} download target="_blank">
                                                                        <img src={toAbsoluteUrl("/media/svg/files/pdf.svg")} alt=""/>
                                                                        <div className="fs-5 fw-bolder text-dark mb-2">{file.fileoriginalname}</div>
                                                                        <div className="fs-7 fw-bold text-gray-400 mt-auto">{Moment(file.created_at).format("DD-MMMM-YYYY")}</div>
                                                                    </a>
                                                                    }
                                                                    
                                                                </a>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* confirm modal */}
                                                    <div className='modal fade' id={'delete_confirm_popup'+file.id} aria-hidden='true'>
                                                        <div className='modal-dialog modal-dialog-centered'>
                                                            <div className='modal-content'>
                                                                <div className='modal-header'>
                                                                    <h3>Confirmation</h3>
                                                                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                                                    </div>
                                                                </div>
                                                                <div className='modal-body py-lg-10 px-lg-10'>
                                                                    <p>Are you sure want to delete?</p>
                                                                    <div className='d-flex align-items-center justify-content-end'>
                                                                        <button className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal'>
                                                                            No
                                                                        </button>
                                                                        <button className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal' onClick={(e) => onDeleteFile(file.id)}>
                                                                            Yes
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </>
                                                    )
                                                })}
                                            </div>
                                    </div></>}
                                    </div>
                                    <div className={tabInfo == 'message' ? "tab-pane fade show active": "tab-pane fade"} id={"message"+propertyId} role="tabpanel" aria-labelledby={"message-tab"+propertyId}>
                                    <ul className="nav nav-pills mb-3 message_tabs" id={"pills-tab"+propertyId} role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id={"pills-mail-tab"+propertyId} data-bs-toggle="pill" data-bs-target={"#pills-mail"+propertyId} type="button" role="tab" aria-controls="pills-mail" aria-selected="true">Email</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id={"pills-whatsapp-tab"+propertyId} data-bs-toggle="pill" data-bs-target={"#pills-whatsapp"+propertyId} type="button" role="tab" aria-controls="pills-whatsapp" aria-selected="false">Whatsapp</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id={"pills-sms-tab"+propertyId} data-bs-toggle="pill" data-bs-target={"#pills-sms"+propertyId} type="button" role="tab" aria-controls="pills-sms" aria-selected="false">Sms</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id={"pills-calls-tab"+propertyId} data-bs-toggle="pill" data-bs-target={"#pills-calls"+propertyId} type="button" role="tab" aria-controls="pills-calls" aria-selected="false">Calls</button>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="pills-tabContent position-relative">
                                            <div className="tab-pane fade show active" id={"pills-mail"+propertyId} role="tabpanel" aria-labelledby="pills-mail-tab">
                                                <div className="mt-4">
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                {/* <div className="col-lg-1  d-flex align-items-center justify-content-lg-center my-2">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-lg-3 my-2">
                                                                    <span className="mail_icon"><i className="fas fa-envelope"></i></span>
                                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                        <p>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                    <div className="w-100 d-flex flex-wrap mt-2">
                                                                        <a href="#" className="d-flex mail_format me-2 mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.wrd</p>
                                                                        </a> 
                                                                        <a href="#" className="d-flex mail_format mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.pdf</p>
                                                                        </a> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-bs-toggle='modal' data-bs-target={'#mail_template_popup'} onClick={sendMail}>                                                
                                                <KTSVG path="/media/icons/duotune/general/gen041.svg" className="svg-icon-muted rounded-circle svg-icon-4hx position-absolute bottom-0 end-0 mb-9 me-9 text_primary" />
                                                </div>
                                                <div className='modal fade' id={'mail_template_popup'} aria-hidden='true'>
                                                    <div className='modal-dialog modal-dialog-centered'>
                                                        <div className='modal-content list_height'>
                                                            <div className='modal-header rbc-today py-0'>
                                                            <div className='card-header pt-2 d-flex align-items-center justify-content-center' id='kt_chat_contacts_header'>
                                                                <h3>Mail List</h3>
                                                            </div>
                                                                <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                <img src={toAbsoluteUrl('/media/custom/overview-icons/cancel_white.svg')} className="svg_icon text-dark" alt='' />
                                                                </div>
                                                            </div>
                                                            <div className='modal-body'>                                         
                                                            <div className="input-group form_search">
                                                                    <input type="text" className="form-control" name="search" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)}/>
                                                                    <div className="input-group-append">
                                                                        <button className="btn btn-secondary" type="button">
                                                                        <img src={toAbsoluteUrl('/media/custom/header-icons/search.svg')} className="svg_icon" alt='' />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <ul className="nav mb-1 d-block list_template">
                                                                {!search
                                                                    ? allTemplatesMail.map((item) => (
                                                                        // <li key={`${item.title}-${item.title}`}>{item.title}</li>
                                                                        <li className="nav-item w-100" key={item.title}>
                                                                        <div onClick={() => MailById(item.id)} className='btn' data-bs-toggle='modal' data-bs-target={'#mail_content_popup'}>
                                                                            <div className='d-flex align-items-center'>
                                                                                <div className='symbol symbol-35px symbol-circle'>
                                                                                    <span className='symbol-label bg_soft text_primary fs-6 fw-bolder'>
                                                                                    {item.title[0]}
                                                                                    </span>
                                                                                </div>

                                                                                <div className='ms-5'>
                                                                                    <p className='fs-5 fw-bolder text-gray-900 mb-0'>
                                                                                    {item.title} 
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    ))
                                                                    : filtered.map((item) => (<li className="nav-item w-100" key={item.title}>
                                                                    <div onClick={() => MailById(item.id)} className='btn' data-bs-toggle='modal' data-bs-target={'#mail_content_popup'}>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div className='symbol symbol-35px symbol-circle'>
                                                                                <span className='symbol-label bg_soft text_primary fs-6 fw-bolder'>
                                                                                {item.title[0]}
                                                                                </span>
                                                                            </div>

                                                                            <div className='ms-5'>
                                                                                <p className='p-3 fs-5 fw-bolder text-gray-900 mb-0'>
                                                                                {item.title} 
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='modal fade shadow-lg' id={'mail_content_popup'} aria-hidden='true'>
                                                        <div className='modal-dialog modal-lg modal-dialog-centered'>
                                                            <div className='modal-content'>
                                                                <div className='modal-header rbc-today'>
                                                                    <h3>Send Mail</h3>
                                                                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                    <img src={toAbsoluteUrl('/media/custom/overview-icons/cancel_white.svg')} className="svg_icon text-dark" alt='' />
                                                                    </div>
                                                                </div>
                                                                <div className='modal-body py-lg-10 px-lg-10'>
                                                                <form noValidate onSubmit={formikMail.handleSubmit} >
                                                                    <div className="row">
                                                                        <div className="col-md-6">                                            
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Title</label>
                                                                                <div className="input-group">
                                                                                    <input type="text" className="form-control" placeholder="title" {...formikMail.getFieldProps('title')}/> 
                                                                                </div>
                                                                                {formikMail.touched.title && formikMail.errors.title && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.title}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">                                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Subject</label>
                                                                                <div className="input-group">
                                                                                    <input type="text" className="form-control" placeholder="subject" {...formikMail.getFieldProps('subject')}/> 
                                                                                </div>
                                                                                {formikMail.touched.subject && formikMail.errors.subject && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.subject}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-6">                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Share with</label>
                                                                                <select className="form-select text-start bg-secondary bg-opacity-25 form-control" {...formikMail.getFieldProps('share_with')}>
                                                                                    <option value="default">Select</option>
                                                                                    <option value={1}>Option 1</option>
                                                                                    <option value={2}>Option 2</option>
                                                                                </select>  
                                                                            </div> 
                                                                            {formikMail.touched.share_with && formikMail.errors.share_with && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.share_with}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}                                                   
                                                                        </div>
                                                                        <div className="col-xl-6">                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Module</label>
                                                                                <select className="form-select form-control text-start bg-secondary bg-opacity-25" {...formikMail.getFieldProps('module_id')}>
                                                                                    <option value="default">Select</option>
                                                                                    <option value={1}>Lead</option>
                                                                                    <option value={2}>Contact</option>
                                                                                    <option value={3}>Task</option>
                                                                                    <option value={4}>Project</option>
                                                                                </select>  
                                                                            </div>   
                                                                            {formikMail.touched.module_id && formikMail.errors.module_id && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.module_id}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}                                                 
                                                                        </div>
                                                                        <div className="col">                                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Body</label>
                                                                                <div className="input-group">
                                                                                    <textarea style={{height: '200px'}} className="form-control" placeholder="Encryption" {...formikMail.getFieldProps('body')}/> 
                                                                                </div>
                                                                                {formikMail.touched.body && formikMail.errors.body && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.body}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                            </div>
                                                                        </div>                                        
                                                                    </div>
                                                                    <div className='card-footer py-3 text-center' id='kt_task_footer'>
                                                                        <button type='button' className='btn btn-secondary me-3' data-bs-dismiss='modal'>Cancel</button>
                                                                        <button
                                                                        type='submit'
                                                                        id='kt_add_teams_submit'
                                                                        className='btn btn_primary text-primary'
                                                                        disabled={formikMail.isSubmitting}
                                                                        >
                                                                        {!loading && <span className='indicator-label'>Send
                                                                        {/* <KTSVG
                                                                        path='/media/custom/save_white.svg'
                                                                        className='svg-icon-3 svg-icon-primary ms-2'
                                                                        /> */}
                                                                        </span>}
                                                                        {loading && (
                                                                            <span className='indicator-progress' style={{display: 'block'}}>
                                                                            Please wait...{' '}
                                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                            </span>
                                                                        )}
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                            <div className="tab-pane fade" id={"pills-whatsapp"+propertyId} role="tabpanel" aria-labelledby="pills-whatsapp-tab">
                                                <div className="mt-4">
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                {/* <div className="col-lg-1 align-items-center d-flex justify-content-lg-center my-2">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-lg-3 my-2">
                                                                    <span className="icon_bg"><img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} className="svg_icon" alt=''/></span>
                                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                        <p>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                <div className="col-lg-3 my-2">
                                                                    <span className="icon_bg"><img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} className="svg_icon" alt=''/></span>
                                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                        <p>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-bs-toggle='modal' data-bs-target={'#watsapp_template_popup'} onClick={sendWatsapp}>
                                                <KTSVG path="/media/icons/duotune/general/gen041.svg" className="svg-icon-muted rounded-circle svg-icon-4hx position-absolute bottom-0 end-0 mb-9 me-9 text_primary" />
                                                </div>
                                                <div className='modal fade' id={'watsapp_template_popup'} aria-hidden='true'>
                                                    <div className='modal-dialog modal-dialog-centered'>
                                                        <div className='modal-content list_height'>
                                                            <div className='modal-header rbc-today py-0'>
                                                            <div className='card-header pt-2 d-flex align-items-center justify-content-center' id='kt_chat_contacts_header'>
                                                                <h3>Mail List</h3>
                                                            </div>
                                                                <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                <img src={toAbsoluteUrl('/media/custom/overview-icons/cancel_white.svg')} className="svg_icon text-dark" alt='' />
                                                                </div>
                                                            </div>
                                                            <div className='modal-body'>                                         
                                                            <div className="input-group form_search">
                                                                    <input type="text" className="form-control" name="search" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)}/>
                                                                    <div className="input-group-append">
                                                                        <button className="btn btn-secondary" type="button">
                                                                        <img src={toAbsoluteUrl('/media/custom/header-icons/search.svg')} className="svg_icon" alt='' />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <ul className="nav mb-1 d-block list_template">
                                                                {!search
                                                                    ? allTemplatesMail.map((item) => (
                                                                        // <li key={`${item.title}-${item.title}`}>{item.title}</li>
                                                                        <li className="nav-item w-100" key={item.title}>
                                                                        <div onClick={() => MailById(item.id)} className='btn' data-bs-toggle='modal' data-bs-target={'#watsapp_content_popup'}>
                                                                            <div className='d-flex align-items-center'>
                                                                                <div className='symbol symbol-35px symbol-circle'>
                                                                                    <span className='symbol-label bg_soft text_primary fs-6 fw-bolder'>
                                                                                    {item.title[0]}
                                                                                    </span>
                                                                                </div>

                                                                                <div className='ms-5'>
                                                                                    <p className='fs-5 fw-bolder text-gray-900 mb-0'>
                                                                                    {item.title} 
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    ))
                                                                    : filtered.map((item) => (<li className="nav-item w-100" key={item.title}>
                                                                    <div onClick={() => MailById(item.id)} className='btn' data-bs-toggle='modal' data-bs-target={'#watsapp_content_popup'}>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div className='symbol symbol-35px symbol-circle'>
                                                                                <span className='symbol-label bg_soft text_primary fs-6 fw-bolder'>
                                                                                {item.title[0]}
                                                                                </span>
                                                                            </div>

                                                                            <div className='ms-5'>
                                                                                <p className='p-3 fs-5 fw-bolder text-gray-900 mb-0'>
                                                                                {item.title} 
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='modal fade shadow-lg' id={'watsapp_content_popup'} aria-hidden='true'>
                                                        <div className='modal-dialog modal-lg modal-dialog-centered'>
                                                            <div className='modal-content'>
                                                                <div className='modal-header rbc-today'>
                                                                    <h3>Send Mail</h3>
                                                                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                    <img src={toAbsoluteUrl('/media/custom/overview-icons/cancel_white.svg')} className="svg_icon text-dark" alt='' />
                                                                    </div>
                                                                </div>
                                                                <div className='modal-body py-lg-10 px-lg-10'>
                                                                <form noValidate onSubmit={formikMail.handleSubmit} >
                                                                    <div className="row">
                                                                        <div className="col-md-6">                                            
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Title</label>
                                                                                <div className="input-group">
                                                                                    <input type="text" className="form-control" placeholder="title" {...formikMail.getFieldProps('title')}/> 
                                                                                </div>
                                                                                {formikMail.touched.title && formikMail.errors.title && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.title}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">                                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Subject</label>
                                                                                <div className="input-group">
                                                                                    <input type="text" className="form-control" placeholder="subject" {...formikMail.getFieldProps('subject')}/> 
                                                                                </div>
                                                                                {formikMail.touched.subject && formikMail.errors.subject && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.subject}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-6">                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Share with</label>
                                                                                <select className="form-select text-start bg-secondary bg-opacity-25 form-control" {...formikMail.getFieldProps('share_with')}>
                                                                                    <option value="default">Select</option>
                                                                                    <option value={1}>Option 1</option>
                                                                                    <option value={2}>Option 2</option>
                                                                                </select>  
                                                                            </div> 
                                                                            {formikMail.touched.share_with && formikMail.errors.share_with && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.share_with}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}                                                   
                                                                        </div>
                                                                        <div className="col-xl-6">                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Module</label>
                                                                                <select className="form-select form-control text-start bg-secondary bg-opacity-25" {...formikMail.getFieldProps('module_id')}>
                                                                                    <option value="default">Select</option>
                                                                                    <option value={1}>Lead</option>
                                                                                    <option value={2}>Contact</option>
                                                                                    <option value={3}>Task</option>
                                                                                    <option value={4}>Project</option>
                                                                                </select>  
                                                                            </div>   
                                                                            {formikMail.touched.module_id && formikMail.errors.module_id && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.module_id}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}                                                 
                                                                        </div>
                                                                        <div className="col">                                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Body</label>
                                                                                <div className="input-group">
                                                                                    <textarea style={{height: '200px'}} className="form-control" placeholder="Encryption" {...formikMail.getFieldProps('body')}/> 
                                                                                </div>
                                                                                {formikMail.touched.body && formikMail.errors.body && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.body}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                            </div>
                                                                        </div>                                        
                                                                    </div>
                                                                    <div className='card-footer py-3 text-center' id='kt_task_footer'>
                                                                        <button type='button' className='btn btn-secondary me-3' data-bs-dismiss='modal'>Cancel</button>
                                                                        <button
                                                                        type='submit'
                                                                        id='kt_add_teams_submit'
                                                                        className='btn btn_primary text-primary'
                                                                        disabled={formikMail.isSubmitting}
                                                                        >
                                                                        {!loading && <span className='indicator-label'>Send
                                                                        {/* <KTSVG
                                                                        path='/media/custom/save_white.svg'
                                                                        className='svg-icon-3 svg-icon-primary ms-2'
                                                                        /> */}
                                                                        </span>}
                                                                        {loading && (
                                                                            <span className='indicator-progress' style={{display: 'block'}}>
                                                                            Please wait...{' '}
                                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                            </span>
                                                                        )}
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                            <div className="tab-pane fade" id={"pills-sms"+propertyId} role="tabpanel" aria-labelledby="pills-sms-tab">
                                                <div className="mt-4">
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                <div className="col-lg-3 my-2">
                                                                    <span className="icon_bg bg_primary_light"><img src={toAbsoluteUrl('/media/icons/duotune/communication/com007.svg')} className="svg_icon text-primary" alt=''/></span>
                                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                        <p>8934301210</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-bs-toggle='modal' data-bs-target={'#sms_template_popup'} onClick={sendSMS}>
                                                <KTSVG path="/media/icons/duotune/general/gen041.svg" className="svg-icon-muted rounded-circle svg-icon-4hx position-absolute bottom-0 end-0 mb-9 me-9 text_primary" />
                                                </div>
                                                <div className='modal fade' id={'sms_template_popup'} aria-hidden='true'>
                                                    <div className='modal-dialog modal-dialog-centered'>
                                                        <div className='modal-content list_height'>
                                                            <div className='modal-header rbc-today py-0'>
                                                            <div className='card-header pt-2 d-flex align-items-center justify-content-center' id='kt_chat_contacts_header'>
                                                                <h3>Mail List</h3>
                                                            </div>
                                                                <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                <img src={toAbsoluteUrl('/media/custom/overview-icons/cancel_white.svg')} className="svg_icon text-dark" alt='' />
                                                                </div>
                                                            </div>
                                                            <div className='modal-body'>                                         
                                                            <div className="input-group form_search">
                                                                    <input type="text" className="form-control" name="search" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)}/>
                                                                    <div className="input-group-append">
                                                                        <button className="btn btn-secondary" type="button">
                                                                        <img src={toAbsoluteUrl('/media/custom/header-icons/search.svg')} className="svg_icon" alt='' />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <ul className="nav mb-1 d-block list_template">
                                                                {!search
                                                                    ? allTemplatesMail.map((item) => (
                                                                        // <li key={`${item.title}-${item.title}`}>{item.title}</li>
                                                                        <li className="nav-item w-100" key={item.title}>
                                                                        <div onClick={() => MailById(item.id)} className='btn' data-bs-toggle='modal' data-bs-target={'#sms_content_popup'}>
                                                                            <div className='d-flex align-items-center'>
                                                                                <div className='symbol symbol-35px symbol-circle'>
                                                                                    <span className='symbol-label bg_soft text_primary fs-6 fw-bolder'>
                                                                                    {item.title[0]}
                                                                                    </span>
                                                                                </div>

                                                                                <div className='ms-5'>
                                                                                    <p className='fs-5 fw-bolder text-gray-900 mb-0'>
                                                                                    {item.title} 
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    ))
                                                                    : filtered.map((item) => (<li className="nav-item w-100" key={item.title}>
                                                                    <div onClick={() => MailById(item.id)} className='btn' data-bs-toggle='modal' data-bs-target={'#sms_content_popup'}>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div className='symbol symbol-35px symbol-circle'>
                                                                                <span className='symbol-label bg_soft text_primary fs-6 fw-bolder'>
                                                                                {item.title[0]}
                                                                                </span>
                                                                            </div>

                                                                            <div className='ms-5'>
                                                                                <p className='p-3 fs-5 fw-bolder text-gray-900 mb-0'>
                                                                                {item.title} 
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='modal fade shadow-lg' id={'sms_content_popup'} aria-hidden='true'>
                                                        <div className='modal-dialog modal-lg modal-dialog-centered'>
                                                            <div className='modal-content'>
                                                                <div className='modal-header rbc-today'>
                                                                    <h3>Send Mail</h3>
                                                                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                    <img src={toAbsoluteUrl('/media/custom/overview-icons/cancel_white.svg')} className="svg_icon text-dark" alt='' />
                                                                    </div>
                                                                </div>
                                                                <div className='modal-body py-lg-10 px-lg-10'>
                                                                <form noValidate onSubmit={formikMail.handleSubmit} >
                                                                    <div className="row">
                                                                        <div className="col-md-6">                                            
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Title</label>
                                                                                <div className="input-group">
                                                                                    <input type="text" className="form-control" placeholder="title" {...formikMail.getFieldProps('title')}/> 
                                                                                </div>
                                                                                {formikMail.touched.title && formikMail.errors.title && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.title}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">                                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Subject</label>
                                                                                <div className="input-group">
                                                                                    <input type="text" className="form-control" placeholder="subject" {...formikMail.getFieldProps('subject')}/> 
                                                                                </div>
                                                                                {formikMail.touched.subject && formikMail.errors.subject && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.subject}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-6">                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Share with</label>
                                                                                <select className="form-select text-start bg-secondary bg-opacity-25 form-control" {...formikMail.getFieldProps('share_with')}>
                                                                                    <option value="default">Select</option>
                                                                                    <option value={1}>Option 1</option>
                                                                                    <option value={2}>Option 2</option>
                                                                                </select>  
                                                                            </div> 
                                                                            {formikMail.touched.share_with && formikMail.errors.share_with && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.share_with}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}                                                   
                                                                        </div>
                                                                        <div className="col-xl-6">                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Module</label>
                                                                                <select className="form-select form-control text-start bg-secondary bg-opacity-25" {...formikMail.getFieldProps('module_id')}>
                                                                                    <option value="default">Select</option>
                                                                                    <option value={1}>Lead</option>
                                                                                    <option value={2}>Contact</option>
                                                                                    <option value={3}>Task</option>
                                                                                    <option value={4}>Project</option>
                                                                                </select>  
                                                                            </div>   
                                                                            {formikMail.touched.module_id && formikMail.errors.module_id && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.module_id}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}                                                 
                                                                        </div>
                                                                        <div className="col">                                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Body</label>
                                                                                <div className="input-group">
                                                                                    <textarea style={{height: '200px'}} className="form-control" placeholder="Encryption" {...formikMail.getFieldProps('body')}/> 
                                                                                </div>
                                                                                {formikMail.touched.body && formikMail.errors.body && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.body}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                            </div>
                                                                        </div>                                        
                                                                    </div>
                                                                    <div className='card-footer py-3 text-center' id='kt_task_footer'>
                                                                        <button type='button' className='btn btn-secondary me-3' data-bs-dismiss='modal'>Cancel</button>
                                                                        <button
                                                                        type='submit'
                                                                        id='kt_add_teams_submit'
                                                                        className='btn btn_primary text-primary'
                                                                        disabled={formikMail.isSubmitting}
                                                                        >
                                                                        {!loading && <span className='indicator-label'>Send
                                                                        {/* <KTSVG
                                                                        path='/media/custom/save_white.svg'
                                                                        className='svg-icon-3 svg-icon-primary ms-2'
                                                                        /> */}
                                                                        </span>}
                                                                        {loading && (
                                                                            <span className='indicator-progress' style={{display: 'block'}}>
                                                                            Please wait...{' '}
                                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                            </span>
                                                                        )}
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                            <div className="tab-pane fade" id={"pills-calls"+propertyId} role="tabpanel" aria-labelledby="pills-calls-tab">
                                                <div className="mt-4">
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                <div className="col-lg-3 my-2">
                                                                    <span className="icon_bg bg_warning_light"><img src={toAbsoluteUrl('/media/icons/duotune/communication/com005.svg')} className="svg_icon text-danger" alt=''/></span>
                                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                        <p>8934301210</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div onClick={sendCall}>
                                                <KTSVG path="/media/icons/duotune/general/gen041.svg" className="svg-icon-muted rounded-circle svg-icon-4hx position-absolute bottom-0 end-0 mb-9 me-9 text_primary" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id={"address"+propertyId} role="tabpanel" aria-labelledby={"address-tab"+propertyId}>
                                        <form noValidate onSubmit={formikAddress.handleSubmit}>
                                            <div className="row">
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Name of building</label>
                                                    <div className="input-group mb-3 input_prepend py-1">
                                                        <input type="text" className="form-control" {...formikAddress.getFieldProps('name_of_building')}  placeholder="Enter building name"/>
                                                    </div> 
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Module Number</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <input type="text" className="form-control" {...formikAddress.getFieldProps('module_number')}  placeholder="Enter Module Number"/>
                                                    </div>
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Address line 1</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <input type="text" className="form-control" {...formikAddress.getFieldProps('door_number')} placeholder="Enter Door Number"/>
                                                    </div>
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Address line 2</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <input type="text" className="form-control" {...formikAddress.getFieldProps('address_line1')} placeholder="Enter address line"/>
                                                    </div>
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Locality*</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <input type="text" className="form-control" {...formikAddress.getFieldProps('locality')} placeholder="Enter Area Name"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">City*</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <select 
                                                        {...formikAddress.getFieldProps('city')} 
                                                        className="btn btn-sm w-100 text-start form-select">
                                                            <option value=''>Select</option>
                                                            {cities.map((cityVal,i) =>{
                                                                return (
                                                                    <option selected={cityVal.id == propDetail.city} value={cityVal.id} key={i}>{cityVal.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div>  
                                                </div>
                                                <div className="col-md-6 col-xxl-3 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">State*</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <select 
                                                        {...formikAddress.getFieldProps('state')} 
                                                        className="btn btn-sm w-100 text-start form-select">
                                                            <option value=''>Select</option>
                                                            {states.map((stateVal,i) =>{
                                                                return (
                                                                    <option selected={stateVal.id == propDetail.state} value={stateVal.id} key={i}>{stateVal.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div>  
                                                </div>
                                                <div className="col-md-6 col-xxl-3 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Country*</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <select 
                                                        {...formikAddress.getFieldProps('country')} 
                                                        className="btn btn-sm w-100 text-start form-select">
                                                            <option value=''>Select</option>
                                                            {countries.map((countryVal,i) =>{
                                                                return (
                                                                    <option selected={countryVal.id == propDetail.country} value={countryVal.id} key={i}>{countryVal.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div>  
                                                </div>
                                                <div className="col-md-6 col-xxl-3 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Zip Code</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <input type="text" className="form-control" {...formikAddress.getFieldProps('pincode')}  placeholder="Zip Code"/>
                                                    </div>
                                                </div> 
                                                <div className="col-md-6 col-xxl-3 mb-2">
                                                    <label htmlFor="basic-url" className="form-label">Latitude</label>
                                                    <div className="input-group mb-3 input_prepend mx-1">
                                                        <input type="text" className="form-control" {...formikAddress.getFieldProps('latitude')} placeholder="Latitude"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-xxl-3 mb-2">
                                                    <label htmlFor="basic-url" className="form-label">Longtitude</label>
                                                    <div className="input-group mb-3 input_prepend mx-1">
                                                        <input type="text" className="form-control" {...formikAddress.getFieldProps('longitude')} placeholder="Longitude"/>
                                                    </div>
                                                </div>
                                                <div className="col-12 d-flex justify-content-center mb-4">
                                                    <button
                                                        type='submit'
                                                        id='kt_sign_up_submit'
                                                        className='btn btn_primary text-primary'
                                                        disabled={formikAddress.isSubmitting}
                                                        >
                                                        {!loading && <span className='indicator-label'>Save
                                                        <KTSVG
                                                        path='/media/custom/save_white.svg'
                                                        className='svg-icon-3 svg-icon-primary ms-2'
                                                        />
                                                        </span>}
                                                        {loading && (
                                                            <span className='indicator-progress' style={{display: 'block'}}>
                                                            Please wait...{' '}
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                            </span>
                                                        )}
                                                    </button>
                                                </div>
                                            </div> 
                                        </form> 
                                    </div>
                                    <div className="tab-pane fade" id={"features"+propertyId} role="tabpanel" aria-labelledby={"features-tab"+propertyId}>
                                        <form noValidate onSubmit={formikFeatures.handleSubmit}>
                                            <div className="row">
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Project Stage</label>
                                                    <div className="input-group mb-3 input_prepend py-1">
                                                        <select 
                                                            {...formikFeatures.getFieldProps('project_stage')} 
                                                            className="btn btn-sm w-100 text-start form-select">
                                                                <option value=''>Select</option>
                                                                {projectStages.map((projectStageVal,i) =>{
                                                                    return (
                                                                        <option selected={projectStageVal.id == propDetail.project_stage} value={projectStageVal.id} key={i}>{projectStageVal.name}</option> 
                                                                )})}
                                                        </select>
                                                    </div>
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Age of Project</label>
                                                    <div className="input-group mb-3 input_prepend py-1">
                                                        <select 
                                                            {...formikFeatures.getFieldProps('age_of_property')} 
                                                            className="btn btn-sm w-100 text-start form-select">
                                                                <option value=''>Select</option>
                                                                {ageOfProp.map((ageOfPropVal,i) =>{
                                                                    return (
                                                                        <option selected={ageOfPropVal.id == propDetail.age_of_property} value={ageOfPropVal.id} key={i}>{ageOfPropVal.name}</option> 
                                                                )})}
                                                        </select>
                                                        {/* <input type="text" className="form-control" {...formikFeatures.getFieldProps('age_of_property')} placeholder="Age of project"/> */}
                                                    </div>
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Furnishing</label>
                                                    <div className="input-group mb-3 input_prepend py-1">
                                                        <select 
                                                            {...formikFeatures.getFieldProps('furnishing')} 
                                                            className="btn btn-sm w-100 text-start form-select">
                                                                <option value=''>Select</option>
                                                                {furnishStatus.map((furnishStatusVal,i) =>{
                                                                    return (
                                                                        <option selected={furnishStatusVal.id == propDetail.furnishing} value={furnishStatusVal.id} key={i}>{furnishStatusVal.name}</option> 
                                                                )})}
                                                        </select>
                                                    </div>
                                                    {/* <FormControl sx={{ m: 0, width: "100%", mt: 0 }}>
                                                        <Select
                                                            multiple
                                                            displayEmpty
                                                            value={furnishName}
                                                            onChange={furnishingChange}
                                                            input={<OutlinedInput />}
                                                            renderValue={(selected) => {
                                                                if (selected.length === 0) {
                                                                return <p>Furnishing</p>;
                                                                }

                                                                return selected.join(', ');
                                                            }}
                                                            className='multi_select_field'
                                                            MenuProps={MenuProps}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                            >
                                                            <MenuItem disabled value="">
                                                                <em>Furnishing</em>
                                                            </MenuItem>
                                                            {furnishStatus.map((furnish) => (
                                                                <MenuItem
                                                                key={furnish.id}
                                                                value={furnish.id}
                                                                style={getStyles(furnish.name, furnishName, theme)}
                                                                >
                                                                {furnish.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl> */}
                                                </div>  
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Sale Price</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <input type="text" className="form-control" {...formikFeatures.getFieldProps('sale_price')} placeholder="Enter sale price"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Project Builtup Area</label>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="input-group first mb-3 input_prepend">
                                                                <input type="number" min="0" {...formikFeatures.getFieldProps('builtup_area_min')} className="form-control" placeholder="Min"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="input-group first mb-3 input_prepend">
                                                                <input type="number" min="0" {...formikFeatures.getFieldProps('builtup_area_max')} className="form-control" placeholder="Max"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Project Plot Area</label>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="input-group first mb-3 input_prepend">
                                                                <input type="number" min="0" {...formikFeatures.getFieldProps('plot_area_min')} className="form-control" placeholder="Min"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="input-group first mb-3 input_prepend">
                                                                <input type="number" min="0" {...formikFeatures.getFieldProps('plot_area_max')} className="form-control" placeholder="Max"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Project Tower</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <input type="text" className="form-control" {...formikFeatures.getFieldProps('tower')} placeholder="Enter project tower"/>
                                                    </div>
                                                </div> 
                                                {/* <div className="col-md-6 col-xxl-4 col-12 mb-3 edit_page_form">
                                                    <label htmlFor="basic-url" className="form-label">Specifications</label>
                                                    <FormControl sx={{ m: 0, width: '100%', mt: 0 }}>
                                                        <Select
                                                            multiple
                                                            displayEmpty
                                                            value={specsName}
                                                            onChange={specsChange}
                                                            input={<OutlinedInput />}
                                                            renderValue={(selected) => {
                                                                var name = [];
                                                            var id = [];

                                                            for(let i = 0; i < selected.length; i++){
                                                            var fields = selected[i].split('-');

                                                            var n = fields[0];
                                                            var d = fields[1];

                                                            name.push(n);
                                                            id.push(d);
                                                            }
                                                                if (selected.length === 0) {
                                                                return <p>Specifications</p>;
                                                                }

                                                                return name.join(', ');
                                                            }}
                                                            className='multi_select_field form-control'
                                                            MenuProps={MenuProps}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                            >
                                                            <MenuItem disabled value="">
                                                                <em>Specifications</em>
                                                            </MenuItem>
                                                            {specifications.map((specification) => (
                                                                <MenuItem
                                                                key={specification.id}
                                                                value={specification.name+'-'+specification.id}
                                                                style={getStyles(specification.name, specsName, theme)}
                                                                >
                                                                {specification.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>  */}
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">uds</label>
                                                    <div className="input-group first mb-3 input_prepend">
                                                        <input type="text" className="form-control" {...formikFeatures.getFieldProps('uds')} placeholder="Enter uds"/>
                                                    </div>
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">No. of Floors</label>
                                                    <div className="input-group first mb-3 input_prepend">
                                                        <input type="text" className="form-control" {...formikFeatures.getFieldProps('no_of_floors')} placeholder="Enter no of floors"/>
                                                    </div>
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Units Type</label>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="input-group first mb-3 input_prepend">
                                                                <input type="number" min="0" className="form-control" {...formikFeatures.getFieldProps('unit_type_min')} placeholder="Min"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="input-group first mb-3 input_prepend">
                                                                <input type="number" min="0" className="form-control" {...formikFeatures.getFieldProps('unit_type_max')} placeholder="Max"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Total No.of Units</label>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="input-group first mb-3 input_prepend">
                                                                <input type="number" min="0" {...formikFeatures.getFieldProps('no_of_units_min')} className="form-control" placeholder="Min"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="input-group first mb-3 input_prepend">
                                                                <input type="number" min="0" {...formikFeatures.getFieldProps('no_of_units_max')} className="form-control" placeholder="Max"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Ownership Type</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <select 
                                                        {...formikFeatures.getFieldProps('ownership_type')} 
                                                        className="btn btn-sm w-100 text-start form-select">
                                                            <option value=''>Select</option>
                                                            {ownershipType.map((ownershipTypeVal,i) =>{
                                                                return (
                                                                    <option selected={ownershipTypeVal.id == propDetail.ownership_type} value={ownershipTypeVal.id} key={i}>{ownershipTypeVal.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div>
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">No of Balcony</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <input type="number" min="0" className="form-control" {...formikFeatures.getFieldProps('balcony')} placeholder=""/>
                                                    </div>
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Project Facing</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <select 
                                                        {...formikFeatures.getFieldProps('property_facing')} 
                                                        className="btn btn-sm w-100 text-start form-select">
                                                            <option value=''>Select</option>
                                                            {propertyFacing.map((propertyFacingVal,i) =>{
                                                                return (
                                                                    <option selected={propertyFacingVal.id == propDetail.property_facing} value={propertyFacingVal.id} key={i}>{propertyFacingVal.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div>
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Kitchen Type</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <select 
                                                        {...formikFeatures.getFieldProps('kitchen_type')} 
                                                        className="btn btn-sm w-100 text-start form-select">
                                                            <option value=''>Select</option>
                                                            {kitchenType.map((kitchenTypeVal,i) =>{
                                                                return (
                                                                    <option selected={kitchenTypeVal.id == propDetail.kitchen_type} value={kitchenTypeVal.id} key={i}>{kitchenTypeVal.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div>
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Flooring</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <select 
                                                        {...formikFeatures.getFieldProps('flooring')} 
                                                        className="btn btn-sm w-100 text-start form-select">
                                                            <option value=''>Select</option>
                                                            {flooring.map((flooringVal,i) =>{
                                                                return (
                                                                    <option selected={flooringVal.id == propDetail.flooring} value={flooringVal.id} key={i}>{flooringVal.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div>
                                                </div> 
                                                <div className="col-md-6 col-xxl-2 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Vasthu Compliant</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <select 
                                                        {...formikFeatures.getFieldProps('vasthu_compliant')} 
                                                        className="btn btn-sm w-100 text-start form-select">
                                                            <option value=''>Select</option>
                                                            {vasthuComp.map((vasthuCompVal,i) =>{
                                                                return (
                                                                    <option selected={vasthuCompVal.id == propDetail.vasthu_compliant} value={vasthuCompVal.id} key={i}>{vasthuCompVal.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div>
                                                </div> 
                                                <div className="col-md-6 col-xxl-2 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Available From</label>
                                                    <div className="input-group mb-3">
                                                        <input type="date" className="form-control" {...formikFeatures.getFieldProps('available_from')} placeholder="date"/> 
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-xxl-2 col-12 mb-3 edit_page_form">
                                                    <label htmlFor="basic-url" className="form-label">Site Visit Preference</label>
                                                    <FormControl sx={{ m: 0, width: '100%', mt: 0 }}>
                                                        <Select
                                                            multiple
                                                            displayEmpty
                                                            value={siteVisitName}
                                                            onChange={siteVisitChange}
                                                            input={<OutlinedInput />}
                                                            renderValue={(selected) => {
                                                                var name = [];
                                                            var id = [];

                                                            for(let i = 0; i < selected.length; i++){
                                                            var fields = selected[i].split('-');

                                                            var n = fields[0];
                                                            var d = fields[1];

                                                            name.push(n);
                                                            id.push(d);
                                                            }
                                                                if (selected.length === 0) {
                                                                return <p>Site Visit Preferences</p>;
                                                                }

                                                                return name.join(', ');
                                                            }}
                                                            className='multi_select_field form-control'
                                                            MenuProps={MenuProps}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                            >
                                                            <MenuItem disabled value="">
                                                                <em>Site Visit Preferences</em>
                                                            </MenuItem>
                                                            {siteVisit.map((siteVisitVal) => (
                                                                <MenuItem
                                                                key={siteVisitVal.id}
                                                                value={siteVisitVal.name+'-'+siteVisitVal.id}
                                                                style={getStyles(siteVisitVal.name, siteVisitName, theme)}
                                                                >
                                                                {siteVisitVal.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    {/* <div className="input-group mb-3">
                                                        <input type="date" className="form-control" {...formikFeatures.getFieldProps('site_visit_preference')} placeholder="date"/> 
                                                    </div> */}
                                                </div>
                                                <div className="col-md-6 col-xxl-2 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">No.of Car Park</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <input type="text" className="form-control" {...formikFeatures.getFieldProps('no_of_car')} placeholder=""/>
                                                    </div>
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Key Custody</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <select 
                                                        {...formikFeatures.getFieldProps('key_custody')} 
                                                        className="btn btn-sm w-100 text-start form-select">
                                                            <option value=''>Select</option>
                                                            {keyCustody.map((keyCustodyVal,i) =>{
                                                                return (
                                                                    <option selected={keyCustodyVal.id == propDetail.key_custody} value={keyCustodyVal.id} key={i}>{keyCustodyVal.name}</option> 
                                                            )})}
                                                        </select>
                                                    </div>
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3 edit_page_form">
                                                    <label htmlFor="basic-url" className="form-label">Amenities</label>
                                                    <FormControl sx={{ m: 0, width: "100%", mt: 0 }}>
                                                        <Select
                                                            multiple
                                                            displayEmpty
                                                            value={aminityName}
                                                            onChange={handleChange}
                                                            input={<OutlinedInput />}
                                                            renderValue={(selected) => {
                                                                var name = [];
                                                                var id = [];

                                                                for(let i = 0; i < selected.length; i++){
                                                                    var fields = selected[i].split('-');

                                                                    var n = fields[0];
                                                                    var d = fields[1];

                                                                    name.push(n);
                                                                    id.push(d);
                                                                }
                                                                if (selected.length === 0) {
                                                                return <p>Amenities</p>;
                                                                }

                                                                return name.join(', ');
                                                            }}
                                                            className='multi_select_field form-control'
                                                            MenuProps={MenuProps}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                            >
                                                            <MenuItem disabled value="">
                                                                <em>Amenities</em>
                                                            </MenuItem>
                                                            {amenities.map((amenity) => (
                                                                <MenuItem
                                                                key={amenity.id}
                                                                value={amenity.name+'-'+amenity.id}
                                                                style={getStyles(amenity.name+'-'+amenity.id, aminityName, theme)}
                                                                >
                                                                {amenity.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Project Highlight</label>
                                                    <div className="input-group first mb-3 input_prepend">
                                                        <input type="text" className="form-control" {...formikFeatures.getFieldProps('property_highlight')} placeholder="Enter Property Highlight"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Car Park Type</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <select className="btn btn-sm w-100 text-start form-select" {...formikFeatures.getFieldProps('car_park_type')}>
                                                        <option value=''>Select</option>
                                                            <option selected={propDetail.car_park_type == "1"} value="1">Yes</option>
                                                            <option selected={propDetail.car_park_type == "0"} value="0">No</option>
                                                        </select>
                                                    </div>
                                                </div> 
                                                <div className="col-md-6 col-xxl-2 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Currenty under Loan</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <select className="btn btn-sm w-100 text-start form-select" {...formikFeatures.getFieldProps('currently_under_loan')}>
                                                        <option value=''>Select</option>
                                                            <option selected={propDetail.currently_under_loan == "1"} value="1">Yes</option>
                                                            <option selected={propDetail.currently_under_loan == "0"} value="0">No</option>
                                                        </select>
                                                    </div>
                                                </div> 
                                                <div className="col-md-6 col-xxl-2 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Water Supply</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <select className="btn btn-sm w-100 text-start form-select" {...formikFeatures.getFieldProps('water_supply')}>
                                                        <option value=''>Select</option>
                                                            <option selected={propDetail.water_supply == "1"} value="1">Yes</option>
                                                            <option selected={propDetail.water_supply == "0"} value="0">No</option>
                                                        </select>
                                                    </div>
                                                </div> 
                                                <div className="col-md-6 col-xxl-2 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Gated Community</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <select className="btn btn-sm w-100 text-start form-select" {...formikFeatures.getFieldProps('gated_community')}>
                                                        <option value=''>Select</option>
                                                            <option selected={propDetail.gated_community == "1"} value="1">Yes</option>
                                                            <option selected={propDetail.gated_community == "0"} value="0">No</option>
                                                        </select>
                                                    </div>
                                                </div> 
                                                <div className="col-md-6 col-xxl-2 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">RERA Registered</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <select className="btn btn-sm w-100 text-start form-select" {...formikFeatures.getFieldProps('rera_registered')}>
                                                        <option value=''>Select</option>
                                                            <option selected={propDetail.rera_registered == "1"} value="1">Yes</option>
                                                            <option selected={propDetail.rera_registered == "0"} value="0">No</option>
                                                        </select>
                                                    </div>
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">RERA Number</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <input type="text" className="form-control" {...formikFeatures.getFieldProps('rera_number')} placeholder="Enter RERA Number"/>
                                                    </div>
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Completion Certificate</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <input type="text" className="form-control" {...formikFeatures.getFieldProps('completion_certificate')} placeholder="Enter Completion Certificate"/>
                                                    </div>
                                                </div> 
                                                <div className="col-12 d-flex justify-content-center mb-4">
                                                    <button
                                                        type='submit'
                                                        id='kt_sign_up_submit'
                                                        className='btn btn_primary text-primary'
                                                        disabled={formikFeatures.isSubmitting}
                                                        >
                                                        {!loading && <span className='indicator-label'>Save
                                                        <KTSVG
                                                        path='/media/custom/save_white.svg'
                                                        className='svg-icon-3 svg-icon-primary ms-2'
                                                        />
                                                        </span>}
                                                        {loading && (
                                                            <span className='indicator-progress' style={{display: 'block'}}>
                                                            Please wait...{' '}
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                            </span>
                                                        )}
                                                    </button>
                                                </div>
                                            </div> 
                                        </form> 
                                    </div>
                                    <div className="tab-pane fade" id={"features-list"+propertyId} role="tabpanel" aria-labelledby={"features-list-tab"+propertyId}>
                                        <form noValidate onSubmit={formikunittype.handleSubmit}>
                                            
                                        {featuresList.length == 0 && <button type='button' className="btn btn-sm btn_soft_primary ps-5 pe-4" onClick={handleDocumentAdd}>
                                            <i className="fa fa-plus text_primary" aria-hidden="true"></i>
                                        </button>}
                                        {featuresList.map((singleFeature, index) => { 
                                            var i = index ;
                                            return (
                                                <>
                                                <div className="card my-3 bs_1" key={singleFeature.id}>
                                                <div className="card-body row">
                                                    <div className="col-md-3 col-xxl-2 col-12 mb-3">
                                                        <label htmlFor="basic-url" className="form-label">Unit Type</label>
                                                        <div className="input-group mb-3 input_prepend">
                                                            <select
                                                                {...formikunittype.getFieldProps('unit_type' + i)}
                                                                onChange={(e) => onPlanChange(singleFeature.id, i, e)}
                                                                id={'unit_type_' + i}
                                                                className="btn btn-sm w-100 text-start form-select">
                                                                <option value='0'>Select</option>
                                                                {unitType.map((unitTypeVal, j) => {
                                                                    return (
                                                                        <option value={unitTypeVal.id} key={unitTypeVal.id}>{unitTypeVal.name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-xxl-3 col-12 mb-3">
                                                        <label htmlFor="basic-url" className="form-label">Builtup Area</label>
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <div className="input-group first mb-3 input_prepend">
                                                                    <input type="number" min="0" id={'builtup_area_min_' + i} {...formikunittype.getFieldProps('builtup_area_min' + i)} className="form-control" placeholder="Min" />
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="input-group first mb-3 input_prepend">
                                                                    <input type="number" min="0" id={'builtup_area_max_' + i} {...formikunittype.getFieldProps('builtup_area_max' + i)} className="form-control" placeholder="Max" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 col-xxl-2 col-12 mb-3">
                                                        <label htmlFor="basic-url" className="form-label">Total No.of Units</label>
                                                        <div className="input-group mb-3 input_prepend">
                                                            <input type="text" 
                                                            // onKeyUp={(e) => onPlanChange(singleFeature.id, i, e)} 
                                                            className="form-control" id={'total_units_' + i} {...formikunittype.getFieldProps('total_units' + i)} placeholder="Total Units" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-xxl-2 col-12 mb-3">
                                                        <label htmlFor="basic-url" className="form-label">Currency</label>
                                                        <div className="input-group mb-3 input_prepend">
                                                            <select
                                                                id={'local_currency_' + i}
                                                                {...formikunittype.getFieldProps('local_currency' + i)}
                                                                onChange={(e) => onCurrencyChange(singleFeature.id, i, e)}
                                                                className="btn btn-sm w-100 text-start form-select">
                                                                {currency.map((currencyVal, j) => {
                                                                    return (
                                                                        <option value={currencyVal.id} key={j}>{currencyVal.name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-xxl-3 col-12 mb-3">
                                                        <label htmlFor="basic-url" className="form-label">Sale Price*</label>
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <div className="input-group first mb-3 input_prepend">
                                                                    <input type="text" id={'price_min_' + i} className="form-control" {...formikunittype.getFieldProps('price_min' + i)} placeholder="Min Sale Price" />
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="input-group first mb-3 input_prepend">
                                                                    <input type="text" id={'price_max_' + i} className="form-control" {...formikunittype.getFieldProps('price_max' + i)} placeholder="Max Sale Price" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                    <div className="col-12 d-flex justify-content-between align-items-center">
                                                        <span onDrop={(e: any) => processFile(e, i)} onDragOver={(e: any) => allowDrop(e, i)} className="btn btn-file d-flex flex-md-row p-2 p-md-4 d-none">
                                                            <i className="fa fa-upload my-2" aria-hidden="true"></i>Upload Floor Plan <input id={'file_' + i} {...formikunittype.getFieldProps('file' + i)} ref={profileView} onChange={(e: any) => handleFilePreview(e, i)} type="file" />
                                                        </span>
                                                        {featuresList.length !== 0 && (
                                                            <button type='button' className="btn btn-sm btn_soft_primary ps-5 pe-4" onClick={() => handleDocumentRemove(singleFeature.id, index)}>
                                                                <i className="fa fa-minus text_primary" aria-hidden="true"></i>
                                                            </button>
                                                        )}
                                                        {featuresList.length - 1 === index && (
                                                            <button type='button' className="btn btn-sm btn_soft_primary ps-5 pe-4" onClick={handleDocumentAdd}>
                                                                <i className="fa fa-plus text_primary" aria-hidden="true"></i>
                                                            </button>
                                                        )}
                                                    </div>
                                                    <small className='text_primary pt-1 d-none'>* Note: jpg, jpeg, pdf only acceptable</small>
                                                </div>
                                                </div></>
                                            )
                                        })}
                                        <div className="col-12 d-flex justify-content-center mb-6">
                                            <button
                                                type='submit'
                                                id='kt_unit_type_submit'
                                                className='btn btn_primary text-primary'
                                                disabled={formikunittype.isSubmitting}
                                                //  onClick={handleUnitType}
                                            >
                                                {!loading && <span className='indicator-label'>Save
                                                    <KTSVG
                                                        path='/media/custom/save_white.svg'
                                                        className='svg-icon-3 svg-icon-primary ms-2' />
                                                </span>}
                                                {loading && (
                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                        Please wait...{' '}
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                                
                                            </button>
                                        </div>
                                    </form>
                                    </div>
                                    <div className={tabInfo == 'sec_contact' ? "tab-pane fade show active": "tab-pane fade"} id={"contact"+propertyId} role="tabpanel" aria-labelledby={"contact-tab"+propertyId}>
                                        <div style={{ height: 400, width: '100%',}}>
                                        <div className="d-flex flex-column flex-center mb-9">   
                                            <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
                                            <h2>Under Construction</h2>
                                        </div>
                                        {Contactrows.length > 0
                                            ?
                                            <DataGrid
                                                rows={Contactrows}
                                                columns={Contactcolumns}
                                                pageSize={5}
                                                rowsPerPageOptions={[5]}
                                                checkboxSelection
                                                sx={{
                                                    fontSize:14,
                                                    fontWeight:500,
                                                }}
                                            />
                                            : <div className="text-center w-100">
                                                <span className="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor"></path><rect x="7" y="17" width="6" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="12" width="10" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="7" width="6" height="2" rx="1" fill="currentColor"></rect><path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor"></path></svg></span>
                                                <p className='mt-3'>No Secondary Contacts Available</p>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    <div className={tabInfo == 'lead' ? "tab-pane fade show active": "tab-pane fade"} id={"lead"+propertyId} role="tabpanel" aria-labelledby={"lead-tab"+propertyId}>
                                        <div style={{ height: 400, width: '100%',}}>
                                        {/* <div className="d-flex flex-column flex-center mb-9">   
                                            <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
                                            <h2>Under Construction</h2>
                                        </div> */}
                                        {leadProperty.length > 0
                                            ?
                                            <DataGrid
                                                rows={leadProperty}
                                                columns={leadPropertycolumns}
                                                pageSize={5}
                                                rowsPerPageOptions={[5]}
                                                checkboxSelection
                                                sx={{
                                                    fontSize:14,
                                                    fontWeight:500,
                                                }}
                                            />
                                            : <div className="text-center w-100">
                                                <span className="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor"></path><rect x="7" y="17" width="6" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="12" width="10" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="7" width="6" height="2" rx="1" fill="currentColor"></rect><path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor"></path></svg></span>
                                                <p className='mt-3'>No Leads Available</p>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    <div className={tabInfo == 'task' ? "tab-pane fade show active": "tab-pane fade"} id={"tasks"+propertyId} role="tabpanel" aria-labelledby={"tasks-tab"+propertyId}>
                                    <div style={{ height: 400, width: '100%',}}>
                                        {/* <div className="d-flex flex-column flex-center mb-9">   
                                            <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
                                            <h2>Under Construction</h2>
                                        </div> */}
                                        {taskProperty.length > 0
                                            ?
                                        <DataGrid
                                                rows={taskProperty}
                                                columns={taskPropertycolumns}
                                                pageSize={5}
                                                rowsPerPageOptions={[5]}
                                                checkboxSelection
                                                sx={{
                                                    fontSize:14,
                                                    fontWeight:500,
                                                }}
                                            />
                                            : <div className="text-center w-100">
                                                <span className="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor"></path><rect x="7" y="17" width="6" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="12" width="10" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="7" width="6" height="2" rx="1" fill="currentColor"></rect><path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor"></path></svg></span>
                                                <p className='mt-3'>No Tasks Available</p>
                                            </div>
                                            }
                                    </div>
                                    </div>
                                    <div className={tabInfo == 'timeline' ? "tab-pane fade show active": "tab-pane fade"} id={"timeline"+propertyId} role="tabpanel" aria-labelledby="timeline-tab">
                                    <div className='mb-9' style={{ height: 400, width: '100%',}}>
                                    {logList.length > 0
                                            ?
                                        <DataGrid
                                                rows={logList}
                                                columns={logContactcolumns}
                                                pageSize={5}
                                                rowsPerPageOptions={[5]}
                                                checkboxSelection
                                                sx={{
                                                    fontSize:14,
                                                    fontWeight:500,
                                                }}
                                            />
                                            : <div className="text-center w-100">
                                                <span className="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor"></path><rect x="7" y="17" width="6" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="12" width="10" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="7" width="6" height="2" rx="1" fill="currentColor"></rect><path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor"></path></svg></span>
                                                <p className='mt-3'>No Tasks Timeline Available</p>
                                            </div>
                                            }</div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>:
            <div className="card bg_primary">
                <div className="card-body d-flex justify-content-between">
                    <div>
                        <h5 className='text-white'>Project Details</h5>
                    </div>
                    <button onClick={minimaximize} className="mx-3 btn p-0">
                        <i className="fas fa-window-maximize text-white"></i>
                    </button>
                    <button type='button' id='kt_expand_close' data-bs-dismiss="offcanvas" onClick={closeDialogue} className="mx-3 btn p-0">
                        <i className="fas fa-times text-white"></i>
                    </button>
                </div>
            </div>
            }
        </div>
    )

}
export {PropertyDetails}