import React,{FC} from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const PersonalSettings: FC = () => {
    return(
        <div>
            <div className="d-flex flex-column flex-center mb-9">   
            <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
            <h2>Under Construction</h2>
        </div>
            <form action="" className='d-none'>
                <div className="row">
                    <div className="col-lg-6 col-xxl-4">
                        <div className="card bs_1">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6">
                                        {/* <img src="..." className="img-thumbnail" alt="..."/> */}
                                        <img src={toAbsoluteUrl('/media/avatars/blank.png')} className="img-thumbnail" alt="..."/>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="basic-url" className="form-label">Agent Name</label>
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" placeholder="Agent Name"/> 
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="basic-url" className="form-label">Designation</label>
                                            <select className="form-select btn_secondary btn-sm text-start">
                                                <option>CEO</option>
                                                <option>Manager</option>
                                            </select>  
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="basic-url" className="form-label">Phone Number</label>
                                            <div className="input-group mb-3">
                                                <input type="number" min="0" className="form-control" placeholder=""/> 
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="basic-url" className="form-label">Time Zone</label>
                                            <select className="form-select btn_secondary btn-sm text-start">
                                                <option>(GMT+5:30)</option>
                                                <option>(GMT+5:30)</option>
                                            </select>  
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="basic-url" className="form-label">Custom Fields</label>
                                            <div className="input-group first mb-3 input_prepend">
                                                <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                                    <option value="1">Type</option>
                                                    <option value="2">Type 1</option>
                                                    <option value="2">Type 2</option>
                                                </select>
                                                <input type="text" className="form-control" placeholder="Custom Value"/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="basic-url" className="form-label">Facebook</label>
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" placeholder="URL"/> 
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="basic-url" className="form-label">Instagram</label>
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" placeholder="URL"/> 
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="basic-url" className="form-label">Linkedin</label>
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" placeholder="URL"/> 
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="basic-url" className="form-label">Twitter</label>
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" placeholder="URL"/> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xxl-8">
                        <div className="card bs_1">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <div className="form-group">
                                            <label htmlFor="basic-url" className="form-label">Email Signature</label>
                                            <textarea
                                                className='form-control p-2 resize-none min-h-25px br_10'
                                                data-kt-autosize='true'
                                                rows={8}
                                                placeholder=''
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <div className="form-group">
                                            <label htmlFor="basic-url" className="form-label">Description</label>
                                            <textarea
                                                className='form-control p-2 resize-none min-h-25px br_10'
                                                data-kt-autosize='true'
                                                rows={8}
                                                placeholder='Description'
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    )
}

export {PersonalSettings}