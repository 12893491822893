import React,{ FC, useState, useEffect, useRef } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import { Offcanvas, Toast } from 'bootstrap';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as Yup from 'yup'
import { useAuth } from '../../../modules/auth';
import { useFormik } from 'formik'
import { getProjects ,getProperties} from '../../property/core/_requests';
import { getSource } from '../../contact/core/_requests';
import { getUsersByRole } from '../userManagement/core/_requests';
import { getTeamExceMembersId, getTeamsByProperty, updateContactSetting, getContactSettingById } from './core/_requests';
import Draggable from "react-smooth-draggable";


const initialValues = {
    project: "",
    team: "",
    source_id: "",
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

function getStyles(name, aminityName, theme) {
    return {
        fontWeight:
        aminityName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
    };
}


const ContactSettingEdit = (props) => {

    const taskSaveSchema = Yup.object().shape({
        project: Yup.string(),
        team: Yup.string(),             
        source_id: Yup.string(),             
    })

    
    const inputName = useRef<HTMLInputElement>(null)
    const [loading, setLoading] = useState(false);    
    const [assignToName, setAssignToName] = useState([]);
    const [assignToId, setAssignToId] = useState([]);
    const [property, setProperty] = useState([]);
    const [teams, setTeams] = useState([]);
    const [exceList, setExceList] = useState([]);
    const [teamMembersId, setTeamMembersId] = useState([]);
    const [contactSettingData, setContactSettingData] = useState([]);
    const [assignToDropList, setAssignToDropList] = useState([]);
    const [masterList, setMasterList] = useState([]);
    const [projectId, setProjectId] = useState('');
    const [source, setSource] = useState([]);
    const [users, setUsers] = useState([]);
    const {currentUser, logout} = useAuth();

    var roleId = currentUser?.designation;
    var userId = currentUser?.id;
    
    const theme = useTheme(); 

    console.log('masterList')
        console.log(props)
        console.log('masterList2')   

    const propertyList =  async () => {   
        const Response = await getProperties(userId, roleId)
        setProperty(Response);
        // fetchContactSettingDetails()
      } 

      const assingToChange = (event) => {
        const {
          target: { value },
        } = event;
    
        // console.log('assign event');
        // console.log(value);
    
        var name = [];
        var id = [];
    
        for(let i = 0; i < value.length; i++){
          var fields = value[i].split('-');
    
          var n = fields[0];
          var d = fields[1];
    
          name.push(n);
          id.push(d);
        }
    
        // console.log(name);
        // console.log(id);
    
        setAssignToId(id);
        setMasterList(value)
        // console.log('value')
        // console.log(value)
        setAssignToName(
          typeof value === 'string' ? value.split(',') : value,
        );
        // exceListCall();
        
      };

    const formik = useFormik({
        initialValues,
        validationSchema: taskSaveSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
        //   console.log('lead form body');
        //   console.log(values);
          try {
    
            var name = [];
        var id = [];
    
        for(let i = 0; i < masterList.length; i++){
          var fields = masterList[i].split('-');
    
          var n = fields[0];
          var d = fields[1];
    
          name.push(n);
          id.push(d);
        }

            const body = {
                "project_id":values.project,
                "team_id":values.team,
                "source_id":values.source_id,
                "user_id":id
            }
    
            const saveTaskData = await updateContactSetting(props.ContactSettingData, body);
           
            // document.getElementById('kt_contact')?.classList.remove('drawer-on');
            if(saveTaskData != null){
                setLoading(false);
                document.getElementById('kt_editcontactsettings_close')?.click();
                setTimeout(function() {
                  document.getElementById('contactSetting_reload')?.click();
                }, 2000);
                
            }
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          } 
    }})

    const closeDialogue = () => {
        var offCanvasEl = document.getElementById('kt_editcontactsettings');
        const bsOffcanvas = new Offcanvas(document.getElementById('kt_editcontactsettings'));
        bsOffcanvas.hide();
        offCanvasEl?.classList.add('d-none');
        offCanvasEl?.classList.remove('d-flex');
        document.body.style.overflow = '';
    }

    async function handleChange(event) {
        setAssignToName([]);
        setAssignToId([]);
        setMasterList([]);
        formik.setFieldValue('team', event ?? '');
        const TeamByIdResponse = await getTeamExceMembersId(event);
        setAssignToDropList(TeamByIdResponse);        
    }

    const selectedProject = async (id) => {
        
        setAssignToName([]);
        setAssignToId([]);
        setMasterList([]);
        formik.setFieldValue('project', id ?? '')
        const TeamsResponse = await getTeamsByProperty(id)
        setTeams(TeamsResponse);
    }

    const fetchContactSettingDetails = async (id) => {
        const Response = await getContactSettingById(id)
        setContactSettingData(Response);
        selectedProject(Response.project_id)
        handleChange(Response.team_id)
        formik.setFieldValue('team', Response.team_id ?? '');
        formik.setFieldValue('source_id', Response.source_id ?? '');

        var assignArray = [];
        var assignToArray = [];
        var assignNameArray = [];
        if(Response.user_id != null){
            assignArray = Response.user_id.split(",").map((e) => {
                return parseInt(e);
            });
        }
        if(Response.user_name != null){
            assignNameArray = Response.user_name.split(",").map((e) => {
                return e;
            });
        }
        for(let i = 0; i < assignArray.length; i++){
            assignToArray.push(assignNameArray[i] + '-'+assignArray[i].toString());
        }

        // console.log(assignToArray);
        // formik.setFieldValue('assign_to', assignArray ?? '');
        setAssignToId(assignArray);
        setAssignToName(assignNameArray);
        setMasterList(assignNameArray);
    }

    function reorder(subject, before, after) {
        // console.log(`move ${subject} between ${before} and ${after}`); 
      
      //   setMasterList(names[0]) 
      
        if (before !== undefined) {
          setMasterList((masterList) => {
            const draft = [...masterList];
    
            const index = draft.indexOf(subject);
            draft.splice(index, 1);
    
            const beforeIndex = draft.indexOf(before);
            draft.splice(beforeIndex + 1, 0, subject);
    
            return draft;
          });
        } else if (after !== undefined) {
          setMasterList((masterList) => {
            const draft = [...masterList];
    
            const index = draft.indexOf(subject);
            draft.splice(index, 1);
    
            const afterIndex = draft.indexOf(after);
            // console.log({ afterIndex, index });
            draft.splice(Math.max(0, afterIndex - 1), 0, subject);
    
            return draft;
          });
        }          
      }

      const sourceList =  async () => {   
        const sourceResponse = await getSource()
        setSource(sourceResponse);
      } 

      const usersList =  async () => {
        var usersId = currentUser?.id;
        var roleId = currentUser?.designation;
        const Response = await getUsersByRole(usersId, roleId)
        setUsers(Response);
        
    }

    useEffect(() => {
      sourceList();        
      usersList();          
      propertyList();          
      fetchContactSettingDetails(props.ContactSettingData); 
           
    }, [props.ContactSettingData]);

    return(
        <div className='card bg-white h-100 w-100 w-sm-100 w-md-75 w-lg-50'>
            <div className='card-header w-100' id='kt_team_header'>
                <h3 className='card-title fw-bolder text-dark'>Edit Contact Settings</h3>
                <div className='card-toolbar'>
                    <button
                    type='button'
                    className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                    id='kt_editcontactsettings_close'
                    onClick={closeDialogue}
                    >
                        <img src={toAbsoluteUrl('/media/custom/cancel_black.svg')} className="svg_icon" alt='' />
                        {/* <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' /> */}
                    </button>
                </div>
            </div>
            <div className='card-body position-relative' id='kt_task_body'>
                <form noValidate onSubmit={formik.handleSubmit} >
                    <div className="row mx-0 p-4 main_bg accordion">
                    {/* <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Team Name</label>
                            <div className="input-group mb-3 input_prepend">
                                <input type="text" className="form-control" {...formik.getFieldProps('team_name')} placeholder="Enter Team Name"/>
                            </div>
                            {formik.touched.team_name && formik.errors.team_name && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>{formik.errors.team_name}</span>
                                </div>
                            </div>
                            )}
                        </div> */}
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Project</label>
                            <div className="input-group mb-3">
                                <select className="form-select btn btn-sm w-100" {...formik.getFieldProps('project')} onChange={(e) => selectedProject(e.target.value)}>
                                <option value=''>Select</option>
                                {property.map((sourceobj,i) => {
                                    return (
                                            <option selected={i == 0 ? true: false} value={sourceobj.id} key={i}>{sourceobj.name_of_building}</option> 
                                    ) 
                                    })}  
                                </select>
                            </div>
                            {formik.touched.project && formik.errors.project && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>{formik.errors.project}</span>
                                </div>
                            </div>
                            )}
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Team</label>
                            <div className="input-group mb-3">
                                <select className="form-select btn btn-sm w-100" {...formik.getFieldProps('team')} onChange={(f) => handleChange(f.target.value)}>
                                <option value=''>Select</option>
                                {teams.map((teamsobj,j) => {
                                    return ( 
                                        <option selected={j == 0 ? true: false} value={teamsobj.id} key={j}>{teamsobj.team_name}</option>                                                        
                                        ) 
                                    })}  
                                </select>
                            </div> 
                            {formik.touched.team && formik.errors.team && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>{formik.errors.team}</span>
                                </div>
                            </div>
                            )}                                       
                        </div> 
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Source</label>
                            <div className="input-group mb-3">
                            <select className="form-select btn btn-sm w-100" 
                            {...formik.getFieldProps('source_id')}
                            >
                            <option value=''>Select</option>
                                {source.map((sourceValue,i)=> {
                                  return (
                                    <option selected={i == 0 ? true: false} value={sourceValue.id} key={i}>{sourceValue.name}</option>
                                  )
                                })}   
                            </select>
                            </div>
                          </div>                          
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Team Members</label>
                             
                            <FormControl sx={{ m: 0, width: '100%', mt: 0 }}>
                              <Select
                                  multiple
                                  displayEmpty
                                  value={assignToName}
                                  onChange={assingToChange}
                                  input={<OutlinedInput />}
                                  renderValue={(selected) => {
                                    var name = [];
                                    var id = [];

                                    for(let i = 0; i < selected.length; i++){
                                      var fields = selected[i].split('-');

                                      var n = fields[0];
                                      var d = fields[1];

                                      name.push(n);
                                      id.push(d);
                                    }

                                    // setAssignToId(id);

                                    // console.log('selected');
                                    // console.log(selected);
                                      if (selected.length === 0) {
                                        return <p>Assign To</p>;
                                      }

                                      return name.join(', ');
                                  }}
                                  className='multi_select_field'
                                  MenuProps={MenuProps}
                                  inputProps={{ 'aria-label': 'Without label' }}
                                  >
                                  <MenuItem disabled value="">
                                      <em>Assign To</em>
                                  </MenuItem>
                                  {users.map((assignVal) => (
                                    <MenuItem
                                      key={assignVal.user_id}
                                      value={assignVal.employee_name+'-'+assignVal.user_id}
                                      style={getStyles(assignVal.employee_name, assignToName, theme)}
                                      >
                                      {assignVal.employee_name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>                           
                        </div>
                        {masterList.length > 1 &&
                        <div className="col-md-8 my-3 mx-auto">
                            <div className='input-group'>
                            <div className="ContactReorder">
                                <div className="flex gap-2">
                                    <List
                                    className="bg-blue visiblity_check"
                                    reorder={reorder}
                                    list={masterList}
                                    name="all"
                                    />
                                
                                </div>
                                </div>
                            </div>                                       
                        </div>}
                        {/* <div className="col-md-8 my-3 mx-auto">
                            <div className='input-group'>
                                <ContactReorder names={teamMembersName}/>
                            </div>                                       
                        </div> */}
                    </div>
                    <div className='card-footer py-5 text-center' id='kt_task_footer'>
                        <button
                        type='submit'
                        id='kt_sign_up_submit'
                        className='btn btn_primary text-primary'
                        disabled={formik.isSubmitting}
                        >
                        {!loading && <span className='indicator-label'>Submit
                        <KTSVG
                        path='/media/custom/save_white.svg'
                        className='svg-icon-3 svg-icon-primary ms-2'
                        />
                        </span>}
                        {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                        </button>
                    </div>
                </form>
            </div>    
        </div>
    )
}

export {ContactSettingEdit}

const List = ({ reorder, className, name, list }) => {
    function onDragEnd({ list, dragIndex, dropIndex }) {
      reorder(list[dropIndex], list[dropIndex - 1], list[dropIndex + 1]);
    }
  
    return (
      <div className={`flex flex-col gap-1 w-full lead_drag ${className}`}>
        
        <Draggable onDragEnd={onDragEnd} list={list} cols={1} height={48}>
          {(segment) => <Segment>{segment}</Segment>}
        </Draggable>
      </div>
    );
  };
  
  const Segment = ({ children }) => {
    const colors = [
      "gray",
      "zinc",
      "neutral",
      "stone",
      "red",
      "orange",
      "amber",
      "yellow",
      "lime",
      "green",
      "emerald",
      "teal",
      "cyan",
      "sky",
      "blue",
      "indigo",
      "violet",
      "purple",
      "fuchsia",
      "pink",
      "rose"
    ];
  
    const id = children
      .split("")
      .reduce((total, char) => (total += char.charCodeAt(0)), 0);
  
    const color = colors[id % colors.length];
  
    return (
      <div
        className={`px-4 py-2 rounded shadow scale-100 bg-${color}-500 text-dark font-bold w-100 mx-2`}
      >
        {children}
      </div>
    );
  };