import React,{FC, useState,useEffect} from 'react'
import {KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import {Modal} from 'react-bootstrap';
import { getContactStatus, saveContactStatus, updateContactStatus, deleteContactStatus, deleteContactCategory } from './core/_requests'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'

const initialValues = {
    status_name: '',
}

const ContactStatusComponent: FC = () => {

    const statusSchema = Yup.object().shape({
        status_name: Yup.string().required('Contact status name is required'),
    })

    const [contactStatus, setContactStatus] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [editClicked, setEditClicked] = useState(false);
    const [editId, setEditId] = useState('');

    const statusList =  async () => {
        const statusResponse = await getContactStatus()

        console.log('all property');
        console.log(statusResponse);
        
        setContactStatus(statusResponse);
    }

    useEffect(() => {
        statusList();
    }, []);

    const formik = useFormik({
        initialValues,
        validationSchema: statusSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
          try {
            const body = {
                'company_id': '1',
                'name': values.status_name,
            }
            if(!editClicked){
                const saveStatusData = await saveContactStatus(body);
                setLoading(false)
                resetForm();
                statusList();
                console.log(saveStatusData);
            } else {
                const updateStatusData = await updateContactStatus(editId, body);
                setLoading(false)
                setEditClicked(false);
                setEditId('');
                resetForm();
                statusList();
                console.log(updateStatusData);
            }
          }
        catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
    }})

    const editTap = (value:any, id:any) => {
        console.log(value, id);
        setEditClicked(true);
        setEditId(id);
        formik.setFieldValue('status_name', value);
    }

    const editCancel = () => {
        setEditClicked(false);
        setEditId('');
        formik.setFieldValue('status_name', '');
    }

    const onDelete = async (id:any) => {
        console.log(id);
        await deleteContactStatus(id);
        statusList();
    }

    return(
        <div className='card-header' id='kt_chat_messenger_header'>
            <div className='card-title'>
                <div className='symbol-group symbol-hover'></div>
                <div className='d-flex justify-content-center flex-column me-3'>
                    <a
                        href='#'
                        className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-5 lh-1'
                    >
                        Contact Status
                    </a>
                    {contactStatus.map((Data, i) => {
                                return(
                    <div className='mb-0 lh-1 ms-2 mb-2 d-flex justify-content-between' key={i}>
                        <div>
                        <span className="svg-icon svg-icon-2 svg-icon-grey"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor"></rect><path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor"></path></svg></span>
                        <a href="#" className='fs-7 fw-bold text-gray-700 ps-2 pe-3'>{Data.name}</a>
                        </div>
                        
                        <div>
                        {Data.id != 5 &&
                        <><button onClick={(e) => editTap(Data.name, Data.id)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"><span className="svg-icon svg-icon-4"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path><path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path></svg></span></button><a href="#" data-bs-toggle='modal'
                                                    data-bs-target={'#delete_confirm_popup67845'+Data.id} className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"><span className="svg-icon svg-icon-4"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"></path><path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"></path><path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"></path></svg></span></a></>
                        }
                        </div>
                        
                            {/* confirm modal */}
                            <div className='modal fade' id={'delete_confirm_popup67845'+Data.id} aria-hidden='true'>
                                <div className='modal-dialog modal-dialog-centered'>
                                    <div className='modal-content'>
                                        <div className='modal-header'>
                                            <h3>Confirmation</h3>
                                            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                            </div>
                                        </div>
                                        <div className='modal-body py-lg-10 px-lg-10'>
                                            <p>Are you sure want to delete?</p>
                                            <div className='d-flex align-items-center justify-content-end'>
                                                <button className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal'>
                                                    No
                                                </button>
                                                <button className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal' onClick={(e) => onDelete(Data.id)}>
                                                    Yes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                    )})}
                </div> 
            </div>

            <div className='card-toolbar align-items-start justify-content-end'>
                <form noValidate onSubmit={formik.handleSubmit}>
                    <div className='me-n3 d-flex flex-column align-items-end'>
                        <input type="text" className="form-control" placeholder="Contact Status" {...formik.getFieldProps('status_name')}/>
                        {formik.touched.status_name && formik.errors.status_name && (
                            <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>{formik.errors.status_name}</span>
                            </div>
                            </div>
                        )}
                        <div className="d-flex align-items-center">
                            {editClicked ?
                            <button className='btn btn-sm btn-secondary mt-3 me-3' onClick={editCancel}>
                                Cancel
                            </button>
                            : null}
                            <button
                                type='submit'
                                id='kt_sign_up_submit'
                                className='btn btn-sm btn_primary text-primary mt-3'
                                disabled={formik.isSubmitting}
                                >
                                {!loading && <span className='indicator-label'>{editClicked ? "Update" : "Create" }
                                </span>}
                                {loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                            </div>
                        {/* <button
                            className='btn btn-sm btn_primary text-primary mt-3'
                        >
                            Create
                        </button> */}
                    </div>
                </form>
            </div>
            
        </div>
    )
}
export{ContactStatusComponent}