import React,{FC, useState, useEffect} from 'react'
import {useAuth} from '../../../app/modules/auth'
import MaterialTable from 'material-table'
import { forwardRef } from 'react';
import { getContactByRole, getContactStatus} from './core/_requests';
import Moment from 'moment';
import {useLocation} from 'react-router-dom';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const columns = [
    // { title: 'No.', render: rowData },
    { title: 'Contact Type', field: 'contact_type_name' },
    { title: 'Client Name', field: 'first_name' },
    { title: 'Contact No.', field: 'mobile' },
    { title: 'Email', field: 'email' },
    { title: 'Project', field: 'property_name' },
    { title: 'Assigned To', field: 'assign_to_name' },
    { title: 'Source', field: 'source_name' },
    { title: 'Created Date', render: rowData => Moment(rowData.created_at).format('DD-MMMM-YYYY') },
    { title: 'Last Note', field: 'language' },
    { title: 'Last Task Date', field: 'source' },
    { title: 'Next Task Date', field: 'source' },
//     { title: 'Status', render: rowData => <select className="form-select toggle_white toggle_white btn_primary rounded-pill btn-sm cursor-pointer status_btn" aria-label="Default select example" onChange={event => handleStatus(event, contactUser.id, contactUser.first_name+" "+contactUser.last_name, contactUser.source, contactUser.source_name, contactUser.property_id, contactUser.property_name, contactUser.assign_to, contactUser.assign_to_name)}>
//     <option disabled selected>Status</option>
//     {contactStatus.map((contactStatusValue,i) =>{
//         return (
//             <option value={contactStatusValue.id} selected={contactStatusValue.id == contactUser.status} key={i}>{contactStatusValue.name}</option> 
//     )})}
// </select> },    
];

const ContactList = (props) => {

    const {
        setContactList, contactList
       } = props
  
       const {state} = useLocation()
       console.log('qqq',state)


       const[contacts, setContacts] = useState([]);
       const[contactStatus, setContactStatus] = useState([]);
       const {currentUser, logout} = useAuth();

       const contactListByRole = async () => {
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const contactRes = await getContactByRole(userId, roleId);
        if(contactRes != null) {setContacts(contactRes)};
    }
    const funnelChartfilter = async () =>{
  //     var userId = currentUser?.id;
  //     var roleId = currentUser?.designation;
  //     var status = state.e.contact_status_id
  //     var day = state.day
  //     var from = state.from
  //     var to = state.to
  //  const contactRes = await getContactFilterbyDay(userId,roleId,status,day,from,to)
  //  console.log("filterby",contactRes);
  //  if(state){
  //   setContacts(contactRes)
  //  }

  }

  const contactStatusList =  async () => {
    const contactStatusResponse = await getContactStatus()
    setContactStatus(contactStatusResponse);
  }

    const [pageHeight, setPageHeight] = useState('');
    const setHeight = () => {
      let heigh ;
      if(window.innerHeight > 720) {
        heigh = '650px'
      } else {
        heigh = '550px'
      }
      setPageHeight(heigh)
    } 

    useEffect(() => {
      setHeight()
    }, [window.innerHeight]);

    useEffect(() => {
      contactListByRole();
      contactStatusList();
      funnelChartfilter();
    }, []);
    

    return (
      <div style={{ maxWidth: '100%' }} >
        <div>
        {/* {contactList} */}
        </div>
        <MaterialTable className="p-3"
          enableRowNumbers={true}
          icons={tableIcons}
          columns={columns}
          data={contacts}
          title=""
          // actions={[
          //   {
          //     icon: 'Edit',
          //     tooltip: 'Save User',
          //     onClick: (event, rowData) => {} 
          //   },
          //   rowData => ({
          //     icon: 'Delete',
          //     tooltip: 'Delete User',
          //     onClick: (event, rowData) => {},
          //   })
          // ]}
          // editable={{
          //   onRowUpdate: (newData, oldData) =>
          //     new Promise((resolve) => {
          //         alert(1);
                  
          //     }),
          //   onRowAdd: (newData) =>
          //     new Promise((resolve) => {
          //       alert(2)
          //     }),
          //   onRowDelete: (oldData) =>
          //     new Promise((resolve) => {
          //       alert(3)
          //     }),
          // }}
          options={{
            actionsColumnIndex: -1,
            maxBodyHeight: pageHeight,
            exportButton: true,
            columnsButton: true,
            headerStyle: {
                backgroundColor: '#ececec',
                color: '#000'
            },
            rowStyle: {
                backgroundColor: '#fff',
            }
          }}
        />
      </div>
    )
}

export {ContactList}

