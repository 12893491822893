import bootstrap, { Offcanvas } from 'bootstrap';
import React,{FC, useEffect, useState} from 'react'
import { ContactSettingsDrawer } from './contactSettingDrawer';
import { ContactSettingToolbar } from './contactSettingToolbar';
import { getContactSetting, getDeleteContactSetting } from './core/_requests';
import moment from 'moment';
import { useAuth } from '../../../modules/auth';
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { ContactSettingEdit } from './contactSettingEdit';


const ContactSettings: FC = () => {

    const [contactSetting, setContactSetting] = useState<any[]>([]);
    const [contactEditData, setContactEditData] = useState<any[]>([]);
    const [contactEditClicked, setContactEditClicked] = useState(false);
    const [contactSettingId, setContactSettingId] = useState<any>('');
    const {currentUser, logout} = useAuth();

    var userId = currentUser?.id;
    var roleId = currentUser?.designation;

    const contactSettingList =  async () => {   
        const Response = await getContactSetting()
        setContactSetting(Response);
      }

    const contactSettingDetails = (data:any) => {
        // alert(data)
        var offCanvasEl = document.getElementById('kt_editcontactsettings');
        const bsOffcanvas = new Offcanvas(offCanvasEl!);
        bsOffcanvas.show();
        offCanvasEl?.classList.add('d-flex');
        offCanvasEl?.classList.remove('d-none');
        setContactEditData(data);
        setContactEditClicked(true);
    }

    const deleteContactSetting = async (deleteid:any) => {
        // alert(contactSettingId)
        await getDeleteContactSetting(contactSettingId);
        contactSettingList();
        setContactSettingId('')
    }

      useEffect(() => {
        contactSettingList();
        // propertyStatusList();
    }, []);
    
    return(
        <>
        <ContactSettingsDrawer />
        <ContactSettingToolbar/>
        {/* <App/> */}
               <div>
                {/* grid view */}
                <div >
                    <button className='d-none' id='contactSetting_reload' onClick={contactSettingList}></button>
                    <div className="row">
                        <div className="card-group">   
                        {contactSetting.map((CSData, i) => {
                                return(
                            <div className="col-sm-6 col-xxl-3 col-xl-4 mb-3" key={CSData.id}>
                                <div className="card h-100 mb-5 mb-xl-8 mx-2 bs_1">
                                    <div className='card-body px-3 pt-3 pb-0'>
                                        <div className="d-flex align-items-center justify-content-between mb-5">
                                            <div className="d-flex align-items-center">                                                
                                                <form action="">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id={"exampleCheck"+CSData.id}/>
                                                        <label className="form-check-label id_label" htmlFor={"exampleCheck"+CSData.id}>
                                                            {CSData.id}
                                                        </label>
                                                    </div>
                                                </form>
                                                <div
                                                    id='kt_editcontactsettings'
                                                    className='detail_page_view offcanvas offcanvas-end justify-content-end bg-transparent d-none w-100 p-4'
                                                    // data-kt-drawer='true'
                                                    // data-kt-drawer-name='LeadSettings'
                                                    // data-kt-drawer-activate='true'
                                                    // data-kt-drawer-overlay='true'
                                                    // data-kt-drawer-width="{default:'100%', 'md': '700px'}"
                                                    // data-kt-drawer-direction='end'
                                                    // data-kt-drawer-toggle='#kt_addleadsettings_toggle'
                                                    // data-kt-drawer-close='#kt_addleadsettings_close'
                                                >
                                                    <ContactSettingEdit ContactSettingData={contactEditData} EditClickedCS={contactEditClicked}/>
                                                </div> 
                                            </div>
                                            <div className="d-flex">
                                            <a href='#'><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="leadicon me-2" onClick={(e) => contactSettingDetails(CSData.id)}/></a>
                                            <div className="btn-group">
                                                <a className="" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                    <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="leadicon me-2"/>
                                                </a>
                                                <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                                    <li><a className="dropdown-item" href="#" onClick={(e) => contactSettingDetails(CSData.id)}>Edit</a></li>
                                                    <li><a className="dropdown-item" href="#" data-bs-toggle='modal'
                                                        data-bs-target={'#delete_confirm_popup41eryheryhrh8'} onClick={() => setContactSettingId(CSData.id)}>Delete</a></li>
                                                </ul>
                                            </div>
                                            <div className='modal fade' id={'delete_confirm_popup41eryheryhrh8'} aria-hidden='true'>
                                                <div className='modal-dialog modal-dialog-centered'>
                                                    <div className='modal-content'>
                                                        <div className='modal-header'>
                                                            <h3>Confirmation</h3>
                                                            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                                            </div>
                                                        </div>
                                                        <div className='modal-body py-lg-10 px-lg-10'>
                                                            <p>Are you sure want to delete?</p>
                                                            <div className='d-flex align-items-center justify-content-end'>
                                                                <button className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal'>
                                                                    No
                                                                </button>
                                                                <button className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal' onClick={(e) => deleteContactSetting(CSData.id)}>
                                                                    Yes
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                           
                                        </div>                                        
                                        <div className='mb-3'>
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="row">
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                <img src={toAbsoluteUrl('/media/custom/google_ads.svg')} alt="" className="leadicon me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Project</small>
                                                                        <p className="mb-0 fw-500">{CSData.property_name}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                <img src={toAbsoluteUrl('/media/custom/google_ads.svg')} alt="" className="leadicon me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Source</small>
                                                                        <p className="mb-0 fw-500">{CSData.source_name}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                    <img src={toAbsoluteUrl('/media/custom/assign.svg')} alt="" className="leadicon me-2"/>
                                                                    <div className="d-flex flex-column" title="user">
                                                                        <small className="text_light">Team</small>
                                                                        <p className="mb-0 fw-500">{CSData.team_name}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                    <img src={toAbsoluteUrl('/media/custom/calendar.svg')} alt="" className="leadicon me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Ceated At</small>
                                                                        <p className="mb-0 fw-500">{moment(CSData.created_at).format('DD-MM-YYYY')}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-6 mb-3" title={CSData.user_name}>
                                                            <div className="task_content_single overflow-hidden">
                                                                <div className="d-flex align-items-start single_item">
                                                                    <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="leadicon me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Members</small>
                                                                        <p className="mb-0 fw-500">{CSData.user_name}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>  
                                                </div>
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                            )})}
                        </div>
                    </div>
                </div>            
            </div>
            </>
    )
}

export {ContactSettings}