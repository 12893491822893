import { Toast } from 'bootstrap'
import clsx from 'clsx'
import React, {FC, useEffect, useState} from 'react'
import { useAuth } from '../../../../app/modules/auth'
import { saveAttendanceCheckin } from '../../../../app/pages/dashboard/core/requests'
import { getUser } from '../../../../app/pages/settings/userManagement/core/_requests'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, QuickLinks, Search} from '../../../partials'
import { attendanceCheckin, getNotifications } from '../../../partials/layout/header-menus/core/_request'
import {useLayout} from '../../core'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const {config} = useLayout()

  const {currentUser, logout} = useAuth();
  const [notifications, setNotifications] = useState<any[]>([]);
  const [checkinButton, setCheckinButton] = useState<any>('');
  const [userInfo, setUserInfo] = useState<{[key: string]: any}>({});
  
  var userId = currentUser?.id;
  var roleId = currentUser?.designation;
  console.log("userId");
  console.log(userId);
  
  const notificationList =  async () => {   
    const contactStatusResponse = await getNotifications(userId)
    setNotifications(contactStatusResponse);
}

const getAttendanceCheckin = async () => {
  const response = await attendanceCheckin(userId, roleId)
  setCheckinButton(response)
}

const handleClick = () => {
  notificationList();
}

const FetchContactDetails =  async () => {    
  const fetchDetails = await getUser(userId)
  setUserInfo(fetchDetails);
}

const onSearch = () => {
  console.log('sssssss');
  var val = (document.getElementById('search_val') as HTMLInputElement);
  console.log(val.value);
  if (typeof window != "undefined") {
    // window.fi = (val.value) => {

    // };
  }
}

const handleCheckin = async () => {
  const body = {
    "user_id": userId,
    "created_by": userId                          
}

const attendanceData = await saveAttendanceCheckin(body);

if(attendanceData != null){
  const response = await attendanceCheckin(userId, roleId)
  setCheckinButton(response)
if (attendanceData == 'you cannot save attendance twice in a day') {  
  alert('you cannot save attendance twice in a day') 
} else {
  var toastEl = document.getElementById('myToastAttendance');
  const bsToast = new Toast(toastEl!);
  bsToast.show();
}                         
}
}

useEffect(() => {
  getAttendanceCheckin();
  notificationList();
  FetchContactDetails();  
},[]);

  return (
    <div className='d-flex align-items-center flex-shrink-0'>
      {roleId != 1 && checkinButton == 0 &&
        <button className="btn btn-sm btn_primary me-4 checkin_btn" onClick={handleCheckin}>Check in</button>}

      {/* Search */}
      {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <Search />
      </div> */}

      {/* Search */}
      <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
          <div className="input-group search_btn">
            <input type="text" id="search_val" className="form-control d-none d-sm-block" placeholder="Search"/>
            <div className="input-group-append">
              <button className="btn btn-secondary" type="button" onClick={onSearch}>
                <img src={toAbsoluteUrl('/media/custom/header-icons/search.svg')} className="svg_icon" alt='' />
              </button>
            </div>
          </div>
      </div>
     
      {/* Activities */}
        {/* begin::Drawer toggle */}
      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom',
            toolbarButtonHeightClass
          )}
          id='kt_activities_toggle'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen032.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div>
      </div> */}
        {/* end::Drawer toggle */}


      {/* NOTIFICATIONS */}
        {/* begin::Menu- wrapper */}
      <div className={clsx('d-flex align-items-center position-relative', toolbarButtonMarginClass)}>
        <div onClick={handleClick}
          className={clsx(
            'btn btn-icon btn-custom',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/custom/header-icons/notification.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div>
        {notifications.length > 0 &&
        <span className='bullet bullet-dot notification_indicator h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>}
        <HeaderNotificationsMenu />
        
      </div>
        {/* end::Menu wrapper */}


      {/* CHAT */}
      <div className={clsx('d-flex align-items-center position-relative d-none', toolbarButtonMarginClass)}>
        {/* begin::Menu wrapper */}
        <div
          className={clsx(
            'btn btn-icon btn-custom',
            toolbarButtonHeightClass
          )}
          // id='kt_drawer_chat_toggle'
        >
          <KTSVG
            path='/media/custom/menu-icons/message.svg'
            className={toolbarButtonIconSizeClass}
          />

          <span className='bullet bullet-dot notification_indicator h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
        </div>
        {/* end::Menu wrapper */}
      </div>

      {/* Quick links */}
        {/* begin::Menu wrapper */}
      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen025.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div>
        <QuickLinks />
      </div> */}
        {/* end::Menu wrapper */}

      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          
           <img onError={e => { e.currentTarget.src = toAbsoluteUrl('/media/avatars/blank.png') }} src={userInfo.profile_image ? process.env.REACT_APP_API_BASE_URL+'uploads/users/profile_image/'+userInfo.id+'/'+userInfo.profile_image : ''} className="user_img" alt='' />
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )}
    </div>
  )
}

export {Topbar}
