import axios, {AxiosResponse}  from 'axios'

const API_URL = process.env.REACT_APP_API_URL   
//lead
export const GET_COUNT_CITY_LEAD_URL = `${API_URL}/get_count_city_lead`
export const GET_COUNT_PROPERTY_TYPE_LEAD_URL = `${API_URL}/get_count_property_type_lead`
export const GET_COUNT_LEAD_URL = `${API_URL}/get_count_lead`
export const GET_COUNT_SOURCE_LEAD_URL = `${API_URL}/get_count_source_lead`
export const GET_COUNT_STATUS_LEAD_URL = `${API_URL}/get_count_status_lead`

export function getCountCityLead() {
    return axios.get(GET_COUNT_CITY_LEAD_URL)
    .then((response => response.data))
}

export function getCountPropertyTypeLead() {
    return axios.get(GET_COUNT_PROPERTY_TYPE_LEAD_URL)
    .then((response => response.data))
}

export function getCountLead() {
    return axios.get(GET_COUNT_LEAD_URL)
    .then((response => response.data))
}

export function getCountSourceLead() {
    return axios.get(GET_COUNT_SOURCE_LEAD_URL)
    .then((response => response.data))
}

export function getCountStatusLead() {
    return axios.get(GET_COUNT_STATUS_LEAD_URL)
    .then((response => response.data))
}

//property
export const GET_COUNT_PROPERTY_URL = `${API_URL}/get_count_property`
export const GET_COUNT_STATUS_PROPERTY_URL = `${API_URL}/get_count_status_property`
export const GET_COUNT_CITYWISE_PROPERTY_URL = `${API_URL}/get_count_citywise_property`
export const GET_COUNT_SEGMENT_PROPERTY_URL = `${API_URL}/get_count_segment_property`
export const GET_COUNT_PROPERTY_TYPE_URL = `${API_URL}/get_count_property_type_property`

export function getCountProperty() {
    return axios.get(GET_COUNT_PROPERTY_URL)
    .then((response => response.data))
}

export function getCountStatusProperty() {
    return axios.get(GET_COUNT_STATUS_PROPERTY_URL)
    .then((response => response.data))
}

export function getCountCitywiseProperty() {
    return axios.get(GET_COUNT_CITYWISE_PROPERTY_URL)
    .then((response => response.data))
}

export function getCountSegmentProperty() {
    return axios.get(GET_COUNT_SEGMENT_PROPERTY_URL)
    .then((response => response.data))
}

export function getCountPropertyType() {
    return axios.get(GET_COUNT_PROPERTY_TYPE_URL)
    .then((response => response.data))
}

//task
export const GET_COUNT_TASK_TYPE_URL = `${API_URL}/get_count_task_type_task`
export const GET_COUNT_STATUS_TASK_URL = `${API_URL}/get_count_status_task`

export function getCountTaskType() {
    return axios.get(GET_COUNT_TASK_TYPE_URL)
    .then((response => response.data))
}

export function getCountStatusTask() {
    return axios.get(GET_COUNT_STATUS_TASK_URL)
    .then((response => response.data))
}