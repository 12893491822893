import axios, {AxiosResponse}  from 'axios'

const API_URL = process.env.REACT_APP_API_URL   

// Source
export const GET_TEAM_BY_PROPERTY_URL = `${API_URL}/get_team_property_id`
export const GET_TEAM_MEMBERS_URL = `${API_URL}/get_team`
export const GET_TEAM_MEMBERS_EXCE_URL = `${API_URL}/get_team_members_exec`
export const SAVE_CONTACT_SETTING_URL = `${API_URL}/save_contact_setting`
export const GET_CONTACT_SETTING_URL = `${API_URL}/get_contact_setting`
export const PUT_CONTACT_SETTING_URL = `${API_URL}/put_contact_setting`
export const GET_CONTACT_SETTING_BYID_URL = `${API_URL}/get_contact_setting_by_id`
export const DELETE_CONTACT_SETTING_URL = `${API_URL}/delete_contact_setting`



// Source fetch api
export function getTeamsByProperty(id:any) {
    return axios.get(GET_TEAM_BY_PROPERTY_URL+'/'+id)
    .then((response => response.data))
}

export function getTeamById(obj:any) {
    return axios.get(GET_TEAM_MEMBERS_URL+'/'+obj)
    .then((response => response.data))
}

export function getTeamExceMembersId(obj:any) {
    return axios.get(GET_TEAM_MEMBERS_EXCE_URL+'/'+obj)
    .then((response => response.data))
}

export function saveContactSetting(obj:any) {
    return axios.post(SAVE_CONTACT_SETTING_URL,obj)
    .then((response => response.data))
}

export function getContactSetting() {
    return axios.get(GET_CONTACT_SETTING_URL)
    .then((response => response.data))
}

export function getContactSettingById(id:any) {
    return axios.get(GET_CONTACT_SETTING_BYID_URL+'/'+id)
    .then((response => response.data))
}

// lead update requirements api
export function updateContactSetting(Id:any, postData:any) {
    return axios.post(PUT_CONTACT_SETTING_URL+'/'+Id, postData)
    .then((response => response.data))
}

export function getDeleteContactSetting(deleteid:any) {
    return axios.put(DELETE_CONTACT_SETTING_URL+'/'+deleteid)
    .then((response => response.data))
}