import React,{FC, useEffect, useState} from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { FunnelChart } from 'react-funnel-pipeline';
import { useAuth } from '../../../modules/auth';
import { getUsersByRole } from '../../settings/userManagement/core/_requests';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { getCountStatus } from '../core/requests';

const initialValues = {
    start_date: "",
    end_date: "",
}

ChartJS.register(ArcElement, Tooltip, Legend);


const TransactionFunnel: FC = () => {

    const {currentUser, logout} = useAuth();
    const [search, setSearch] = useState("");
    const [transactionFunnel, setTransactionFunnel] = useState('');
    const [userName, setUserName] = useState('');
    const [loading, setLoading] = useState(false);
    const [CountStatus, setCountStatus] = useState<any[]>([]);
    const [filtered, setFiltered] = useState<any[]>([]); 
    const [users, setUsers] = useState<any[]>([]);

    const userId = currentUser?.id;
    const roleId = currentUser?.designation;

    // const CountStatusList =  async () => {
    //     const CountStatusResponse = await getCountStatus(userId, roleId)
    //     setCountStatus(CountStatusResponse);
    //   } 

    const usersList =  async () => {
      const Response = await getUsersByRole(userId, roleId)
      setUsers(Response);
      console.log('userlist', Response);
    }

    const rangeSelect = (val:any) => {
      setTransactionFunnel(val);
      if(val == 6) {
      document.getElementById('transactionFunnelCustomDateRange')?.click();
      }
    }

    const taskSaveSchema = Yup.object().shape({
        start_date: Yup.string(),
        end_date: Yup.string(),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: taskSaveSchema ,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
          try {
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
    }})

    useEffect(() => {
        // CountStatusList();        
        usersList();        
      }, []);
    
function filterItem(item:any, search:any) {
    if (search.startsWith("@")) {
      const bucket = search.substring(1).split(":");
      const searchBy = bucket[0];
      const searchFor = bucket[1];
      const searchByType = getType(item[searchBy]);
  
  
      if (!searchFor) return false;
      console.log("**** searchBy: ", searchBy);
      console.log("**** searchFor: ", searchFor);
      console.log("**** item: ", item);
      // When search is @status:error
      if (searchByType == "string") {
        // When search is @status:!error
        if (searchFor.startsWith("!")) {
          const bucket = searchFor.split("!");
          const searchForVal = bucket[1];
          return !item[searchBy].includes(searchForVal);
        }
        return item[searchBy].includes(searchFor);
      }
  
      // When search is @flavors:small..
      if (searchByType == "array") {
        // When search is @flavors:!small
        if (searchFor.startsWith("!")) {
          const bucket = searchFor.split("!");
          const searchForVal = bucket[1];
          return item[searchBy].find((val:any) => !val.includes(searchForVal));
        }
        return item[searchBy].find((val:any) => val.includes(searchFor));
      }
  
      // When search is @tags:org=sales
      if (searchByType == "object") {
        // When search is @tags:!sales
        if (searchFor.startsWith("!")) {
          const bucket = searchFor.split("!");
          const val = bucket[1] || "";
          return !item[searchBy][val];
        }
        // When search is @tags:org!=sales
        if (searchFor.includes("!=")) {
          const bucket2 = searchFor.split("!=");
          const key = bucket2[0];
          const val = bucket2[1] || "";
          return item[searchBy][key] && !item[searchBy][key].includes(val);
        }
        const bucket2 = searchFor.split("=");
        const key = bucket2[0];
        const val = bucket2[1] || "";
        return item[searchBy][key] && item[searchBy][key].includes(val);
      }
    } else {
      // When search is !computer.....
      if (search.startsWith("!")) {
        const bucket = search.split("!");
        const searchFor = bucket[1];
        return !item.employee_name.includes(searchFor);
      }
      // When search is computer.....
      return item.employee_name.includes(search);
    }
  }
  
  const getType = (value:any) => {
    if (Array.isArray(value)) {
      return "array";
    } else if (typeof value == "string") {
      return "string";
    } else if (value == null) {
      return "null";
    } else if (typeof value == "boolean") {
      return "boolean";
    } else if (Number(value) == value) {
      return "number";
    } else if (typeof value == "object") {
      return "object";
    }
    return "string";
  };

  useEffect(() => {
    let filteredData:any[] = [];
    if (search.length) {
      users.forEach((item) => {
        if (filterItem(item, search)) {
          filteredData.push({ ...item });
        }
      });
    }
    setFiltered(filteredData);
}, [search]);

    return(
        <>
        <div className="card mx-sm-1 bs_1 br_15 h-100 mb-4">
        <div className="card-heade border-0 d-flex align-items-center justify-content-between flex-wrap px-5 pt-5">
                <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Transactions</span>
                </h3>
                <div className='d-flex'>
                {roleId != 3 && <>
                <input className="form-select dash_btn m-2 fs-9" type="button" value={userName} id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false"/>
                <ul className="dropdown-menu db_dw_menu" aria-labelledby="defaultDropdown">
                    <div className="input-group form_search my-3 dropdown-item">
                    <input type="text" className="form-control" name="search" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)}/>
                    <div className="input-group-append">
                        <button className="btn btn-secondary" type="button">
                        <img src={toAbsoluteUrl('/media/custom/header-icons/search.svg')} className="svg_icon" alt='' />
                        </button>
                    </div>
                </div>
                {!search
                    ? users.map((item, i) => (
                    <li className="dropdown-item" key={i} onClick={() => setUserName(item.employee_name)}>{item.employee_name}</li>
                    ))
                    : filtered.map((item, j) => (
                      <li className="dropdown-item" key={j} onClick={() => setUserName(item.employee_name)}>{item.employee_name}</li>
                      ))}
                </ul></>}
                <select className="form-select dash_btn m-2 fs-9" onChange={(e) => rangeSelect(e.target.value)}>
                    <option selected>Today</option>
                    <option value="1">Yesterday</option>
                    <option value="2">Last Week</option>
                    <option value="4">Last Month</option>
                    <option value="6">Custom Date</option>
                </select>
                <button type='button' data-bs-toggle='modal' data-bs-target='#transactionFunnelCustomSelect' className='d-none' id='transactionFunnelCustomDateRange'>Custom Date</button>
                <div className='modal fade' id='transactionFunnelCustomSelect' aria-hidden='true'>
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className='modal-content'>
                          <form noValidate onSubmit={formik.handleSubmit}>
                            <div className='modal-header py-2'>
                                <h3>Select Custom Range</h3>
                                <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                </div>
                            </div>
                            <div className='modal-body py-3 px-lg-10 pb-2'>
                              <div className='row'>                                
                                <div className="col-6 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Start Date</label>
                                    <div className="input-group mb-3" {...formik.getFieldProps('start_date')}>
                                      <input type="date" className="form-control"></input> 
                                    </div>
                                </div>
                                <div className="col-6 mb-3">
                                    <label htmlFor="basic-url" className="form-label">End Date</label>
                                    <div className="input-group mb-3" {...formik.getFieldProps('end_date')}>
                                      <input type="date" className="form-control"></input> 
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className='model-footer'>
                              <div className='d-flex align-items-center justify-content-end pb-3 pe-6 pt-0'>
                              <button
                                type='submit'
                                id='kt_sign_up_submit'
                                className='btn btn-sm btn_primary text-primary'
                                disabled={formik.isSubmitting}
                              >
                                {!loading && <span className='indicator-label'>Filter
                                
                                </span>}
                                {loading && (
                                  <span className='indicator-progress' style={{display: 'block'}}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                  </span>
                                )}
                              </button>
                              </div>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
                </div>
            </div>
          <div className="card-body pt-0 px-5">
            <FunnelChart 
                data={CountStatus}
                pallette= {
                  ['#00508f', '#1a6eb2', '#008dff', '#239bfd', '#64b5f6', '#64b5f6', '#b4d3ed']
                }
              />
          </div>
        </div>
        </>
    )
}
export {TransactionFunnel}