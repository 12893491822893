import React,{FC, useEffect, useState} from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { right } from '@popperjs/core';
import { getTeamsList, getUsersByRole } from '../../settings/userManagement/core/_requests';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { useAuth } from '../../../modules/auth';
import { getCountCategory, getCountCity, getCountGender, getCountGroup, getCountLocality, getCountNationality, getCountSource, getCountState, getCountStatus, getCountType } from '../core/requests';

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
    plugins: {
        legend: {
            display: true,
            position: right,
            labels: {
                color: '#000',
                fontSize:'100',
            },
        }
    }
  };

const initialValues = {
    start_date: "",
    end_date: "",
}

const ContactDoughnut: FC = () => {

    const {currentUser, logout} = useAuth();
    const userId = currentUser?.id;
    const roleId = currentUser?.designation;
    const usersName = currentUser?.first_name;
    const [search, setSearch] = useState("");
    const [contactPie, setContactPie] = useState(1);
    const [userName, setUserName] = useState<any>(usersName);
    const [title, setTitle] = useState('');
    const [reqId, setReqId] = useState(userId);
    const [role, setRole] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState<any[]>([]);
    const [filtered, setFiltered] = useState<any[]>([]); 
    const [users, setUsers] = useState<any[]>([]);
    const [teams, setTeams] = useState<any[]>([]);
    const [filteredTeam, setFilteredTeam] = useState<any[]>([]);
    const [teamId, setTeamId] = useState(0);
    const [list, setList] = useState(1);

    const CountSourceList =  async (reqId:any, body:any, team:any) => {         
      const CountSourceResponse = await getCountSource(userId, roleId, reqId, body, team)
      setCount(CountSourceResponse); 
      setTitle('Source'); 
    }
    const CountTypeList =  async (reqId:any, body:any, team:any) => {
      const CountTypeResponse = await getCountType(userId, roleId, reqId, body, team)
      setCount(CountTypeResponse); 
      setTitle('Type');      
    }  
    const CountCategoryList =  async (reqId:any, body:any, team:any) => {
      const CountCategoryResponse = await getCountCategory(userId, roleId, reqId, body, team)
      setCount(CountCategoryResponse); 
      setTitle('Category');
    }  
    const CountStatusList =  async (reqId:any, body:any, team:any) => {
      const CountStatusResponse = await getCountStatus(userId, roleId, reqId, body, team)
      setCount(CountStatusResponse); 
      setTitle('Status');
    }  
    const CountGroupList =  async (reqId:any, body:any, team:any) => {
      const CountGroupResponse = await getCountGroup(userId, roleId, reqId, body, team)
      setCount(CountGroupResponse); 
      setTitle('Group');
    }  
    const CountCityList =  async (reqId:any, body:any, team:any) => {
      const CountCityResponse = await getCountCity(userId, roleId, reqId, body, team)
      setCount(CountCityResponse); 
      setTitle('City');
    }  
    const CountLocalityList =  async (reqId:any, body:any, team:any) => {
      const CountLocalityResponse = await getCountLocality(userId, roleId, reqId, body, team)
      setCount(CountLocalityResponse); 
      setTitle('Locality');
    }  
    const CountStateList =  async (reqId:any, body:any, team:any) => {
      const CountStateResponse = await getCountState(userId, roleId, reqId, body, team)
      setCount(CountStateResponse); 
      setTitle('State');
    }  
    const CountGenderList =  async (reqId:any, body:any, team:any) => {
      const CountGenderResponse = await getCountGender(userId, roleId, reqId, body, team)
      setCount(CountGenderResponse); 
      setTitle('Gender');
    }  
    const CountNationalityList =  async (reqId:any, body:any, team:any) => {      
      const CountNationalityResponse = await getCountNationality(userId, roleId, reqId, body, team)
      setCount(CountNationalityResponse); 
      setTitle('Nationality');
    }

    const usersList =  async () => {
        const userId = currentUser?.id;
        const roleId = currentUser?.designation;
      const Response = await getUsersByRole(userId, roleId)
      setUsers(Response);
      console.log('userlist', Response);
    }

    const rangeSelectPie = (val:any) => {
      setContactPie(val);
      if(val == 5) {
      document.getElementById('contactPieCustomDateRange')?.click();
      } else {
      var body = {
        'start_date': fromDate,
        'end_date': toDate,
        'filter': val,
      }
        if(title == 'Source') {CountSourceList(reqId, body, teamId)}
        if(title == 'Type') {CountTypeList(reqId, body, teamId)}
        if(title == 'Category') {CountCategoryList(reqId, body, teamId)}
        if(title == 'Status') {CountStatusList(reqId, body, teamId)}
        if(title == 'Group') {CountGroupList(reqId, body, teamId)}
        if(title == 'City') {CountCityList(reqId, body, teamId)}
        if(title == 'Locality') {CountLocalityList(reqId, body, teamId)}
        if(title == 'State') {CountStateList(reqId, body, teamId)}
        if(title == 'Gender') {CountGenderList(reqId, body, teamId)}
        if(title == 'Nationality') {CountNationalityList(reqId, body, teamId)}
      }
    }

      const taskSaveSchema = Yup.object().shape({
        start_date: Yup.string(),
        end_date: Yup.string(),
    })

      const formik = useFormik({
        initialValues,
        validationSchema: taskSaveSchema ,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
          try {
            setFromDate(values.start_date)
            setToDate(values.end_date)

            var body = {
              'start_date': values.start_date,
              'end_date': values.end_date,
              'filter': contactPie,
            }

            if(title == 'Source') {CountSourceList(reqId, body, teamId)}
            if(title == 'Type') {CountTypeList(reqId, body, teamId)}
            if(title == 'Category') {CountCategoryList(reqId, body, teamId)}
            if(title == 'Status') {CountStatusList(reqId, body, teamId)}
            if(title == 'Group') {CountGroupList(reqId, body, teamId)}
            if(title == 'City') {CountCityList(reqId, body, teamId)}
            if(title == 'Locality') {CountLocalityList(reqId, body, teamId)}
            if(title == 'State') {CountStateList(reqId, body, teamId)}
            if(title == 'Gender') {CountGenderList(reqId, body, teamId)}
            if(title == 'Nationality') {CountNationalityList(reqId, body, teamId)}

            resetForm();
            setLoading(false)
            document.getElementById('contactPieModelClose')?.click();
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
    }})

    const graphXContact = count.map((obj) => Object.values(obj)[0]);
    const graphYContact = count.map((obj) => Object.values(obj)[1]);

    const lableContact: any[] = [];
    for(var p=0;p<graphXContact.length;p++) {
    var val15 = graphXContact[p];
    var val16 = graphYContact[p];
    var value = val16+'-'+val15;
    lableContact.push(value);
    }

    const dataContact = {
        labels: lableContact,
        datasets: [
          {
            label: '# of Votes',
            data: graphXContact,
            backgroundColor: [
              '#e27e0c',
              '#ff6700',
              '#208ae6',
              '#03a128',
              '#f37485',
              '#0c4688',
            ],
            borderColor: [
                '#fff',
                '#fff',
                '#fff',
                '#fff',
                '#fff',
                '#fff',
            ],
            borderWidth: 5,
          },
        ],
      };

      const userFilter = (name:any, user:any, role:any) => {
        setUserName(name);
        setReqId(user);
        setRole(role);

        var body = {
          'start_date': fromDate,
          'end_date': toDate,
          'filter': contactPie,
        }

        if(title == 'Source') {CountSourceList(user, body, teamId)}
        if(title == 'Type') {CountTypeList(user, body, teamId)}
        if(title == 'Category') {CountCategoryList(user, body, teamId)}
        if(title == 'Status') {CountStatusList(user, body, teamId)}
        if(title == 'Group') {CountGroupList(user, body, teamId)}
        if(title == 'City') {CountCityList(user, body, teamId)}
        if(title == 'Locality') {CountLocalityList(user, body, teamId)}
        if(title == 'State') {CountStateList(user, body, teamId)}
        if(title == 'Gender') {CountGenderList(user, body, teamId)}
        if(title == 'Nationality') {CountNationalityList(user, body, teamId)}       
      }

      useEffect(() => {
        var body = {
          'start_date': fromDate,
          'end_date': toDate,
          'filter': contactPie,
        }
        CountSourceList(reqId, body, teamId);        
        usersList();        
      }, []);

      function filterItem(item:any, search:any) {
        if (search.startsWith("@")) {
          const bucket = search.toLowerCase().substring(1).split(":");
          const searchBy = bucket[0];
          const searchFor = bucket[1];
          const searchByType = getType(item[searchBy]);
      
          if (!searchFor) return false;
          console.log("**** searchBy: ", searchBy);
          console.log("**** searchFor: ", searchFor);
          console.log("**** item: ", item);
          if (searchByType == "string") {
            if (searchFor.startsWith("!")) {
              const bucket = searchFor.split("!");
              const searchForVal = bucket[1];
              return !item[searchBy].includes(searchForVal);
            }
            return item[searchBy].includes(searchFor);
          }
      
          if (searchByType == "array") {
            if (searchFor.startsWith("!")) {
              const bucket = searchFor.split("!");
              const searchForVal = bucket[1];
              return item[searchBy].find((val:any) => !val.includes(searchForVal));
            }
            return item[searchBy].find((val:any) => val.includes(searchFor));
          }
      
          if (searchByType == "object") {
            if (searchFor.startsWith("!")) {
              const bucket = searchFor.split("!");
              const val = bucket[1] || "";
              return !item[searchBy][val];
            }
            if (searchFor.includes("!=")) {
              const bucket2 = searchFor.split("!=");
              const key = bucket2[0];
              const val = bucket2[1] || "";
              return item[searchBy][key] && !item[searchBy][key].includes(val);
            }
            const bucket2 = searchFor.split("=");
            const key = bucket2[0];
            const val = bucket2[1] || "";
            return item[searchBy][key] && item[searchBy][key].includes(val);
          }
        } else {
          if (search.startsWith("!")) {
            const bucket = search.split("!");
            const searchFor = bucket[1];
            if(list == 1) {
            return !item.team_name_lower.includes(searchFor);
            } else {
            return !item.employee_name_lower.includes(searchFor);
          }
          }
          if(list == 1) {
          return item.team_name_lower.includes(search);
           } else {
          return item.employee_name_lower.includes(search);
           }
        }
      }
      
      const getType = (value:any) => {
        if (Array.isArray(value)) {
          return "array";
        } else if (typeof value == "string") {
          return "string";
        } else if (value == null) {
          return "null";
        } else if (typeof value == "boolean") {
          return "boolean";
        } else if (Number(value) == value) {
          return "number";
        } else if (typeof value == "object") {
          return "object";
        }
        return "string";
      }; 
    
      useEffect(() => {
        teamsList();
        usersList();
    
        let filteredData:any = [];
        if (search.length) {
          if(list == 1) {
            teams.forEach((item) => {
              if (filterItem(item, search.toLowerCase())) {
                filteredData.push({ ...item });
              }
            });
          } else {
            users.forEach((item) => {
              if (filterItem(item, search.toLowerCase())) {
                filteredData.push({ ...item });
              }
            });
          }
          
        }
        if(list == 1) {
        setFilteredTeam(filteredData);
        } else {
        setFiltered(filteredData);
        }
    }, [search]);

      const ListChange = (e:any) => {
        setList(e);
        setTeamId(0);
      }

      const teamsList =  async () => {
        var usersId = currentUser?.id;
        var roleId = currentUser?.designation;
        const Response = await getTeamsList(usersId, roleId)
        setTeams(Response);
        console.log('Response', Response)
    }

    const handleTeam = async (id:any, name:any) => {
      setTeamId(id);
      setReqId(id);
      setUserName(name);
      var body = {
        'start_date': fromDate,
        'end_date': toDate,
        'filter': contactPie,
      }

      if(title == 'Source') {CountSourceList(id, body, id)}
      if(title == 'Type') {CountTypeList(id, body, id)}
      if(title == 'Category') {CountCategoryList(id, body, id)}
      if(title == 'Status') {CountStatusList(id, body, id)}
      if(title == 'Group') {CountGroupList(id, body, id)}
      if(title == 'City') {CountCityList(id, body, id)}
      if(title == 'Locality') {CountLocalityList(id, body, id)}
      if(title == 'State') {CountStateList(id, body, id)}
      if(title == 'Gender') {CountGenderList(id, body, id)}
      if(title == 'Nationality') {CountNationalityList(id, body, id)}  
    } 

    return(
        <>
        <div className='modal fade' id='contactPieChartCustomDate' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>
                  <form noValidate onSubmit={formik.handleSubmit}>
                    <div className='modal-header py-2'>
                        <h3>Select Custom Range</h3>
                        <div id='contactPieModelClose' className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                        </div>
                    </div>
                    <div className='modal-body py-3 px-lg-10 pb-2'>
                      <div className='row'>                                
                        <div className="col-6 mb-3">
                            <label htmlFor="basic-url" className="form-label">Start Date</label>
                            <div className="input-group mb-3">
                              <input type="date" className="form-control" {...formik.getFieldProps('start_date')}/> 
                            </div>
                        </div>
                        <div className="col-6 mb-3">
                            <label htmlFor="basic-url" className="form-label">End Date</label>
                            <div className="input-group mb-3">
                              <input type="date" className="form-control" {...formik.getFieldProps('end_date')}/> 
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className='model-footer'>
                      <div className='d-flex align-items-center justify-content-end pb-3 pe-6 pt-0'>
                      <button
                        type='submit'
                        id='kt_sign_up_submit'
                        className='btn btn-sm btn_primary text-primary'
                        disabled={formik.isSubmitting}
                        data-bs-dismiss='modal'
                      >
                        {!loading && <span className='indicator-label'>Filter
                        
                        </span>}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                      </div>
                    </div>
                    </form>
                </div>
            </div>
        </div>
        <div className="card mx-sm-1 mx-xl-2 bs_1 h-100 br_15">        
            <div className="card-heade border-0 d-flex align-items-center justify-content-end pt-5 px-5">            
                
                <div className='d-flex justify-content-end align-items-center flex-wrap'>                  
                <input className="form-select dash_btn m-2 fs-9" type="button" value={title} id="selectDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false"/>
                <ul className="dropdown-menu db_dw_menu" aria-labelledby="selectDropdown">
                    <li className="dropdown-item" onClick={() => CountSourceList(reqId, {
                        'start_date': fromDate,
                        'end_date': toDate,
                        'filter': contactPie,
                      }, teamId)}>Source</li>
                    <li className="dropdown-item" onClick={() => CountTypeList(reqId, {
                        'start_date': fromDate,
                        'end_date': toDate,
                        'filter': contactPie,
                      }, teamId)}>Type</li>
                    <li className="dropdown-item" onClick={() => CountCategoryList(reqId, {
                        'start_date': fromDate,
                        'end_date': toDate,
                        'filter': contactPie,
                      }, teamId)}>Category</li>
                    <li className="dropdown-item" onClick={() => CountStatusList(reqId, {
                        'start_date': fromDate,
                        'end_date': toDate,
                        'filter': contactPie,
                      }, teamId)}>Status</li>
                    <li className="dropdown-item" onClick={() => CountGroupList(reqId, {
                        'start_date': fromDate,
                        'end_date': toDate,
                        'filter': contactPie,
                      }, teamId)}>Group</li>
                    <li className="dropdown-item" onClick={() => CountCityList(reqId, {
                        'start_date': fromDate,
                        'end_date': toDate,
                        'filter': contactPie,
                      }, teamId)}>City</li>
                    <li className="dropdown-item" onClick={() => CountLocalityList(reqId, {
                        'start_date': fromDate,
                        'end_date': toDate,
                        'filter': contactPie,
                      }, teamId)}>Locality</li>
                    <li className="dropdown-item" onClick={() => CountStateList(reqId, {
                        'start_date': fromDate,
                        'end_date': toDate,
                        'filter': contactPie,
                      }, teamId)}>State</li>
                    <li className="dropdown-item" onClick={() => CountGenderList(reqId, {
                        'start_date': fromDate,
                        'end_date': toDate,
                        'filter': contactPie,
                      }, teamId)}>Gender</li>
                    <li className="dropdown-item" onClick={() => CountNationalityList(reqId, {
                        'start_date': fromDate,
                        'end_date': toDate,
                        'filter': contactPie,
                      }, teamId)}>Nationality</li>
                </ul>
                {roleId != 3 && <>
                <input className="form-select dash_btn m-2 fs-9" type="button" value={userName} id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false"/>
                <ul className="dropdown-menu db_dw_menu" aria-labelledby="defaultDropdown">
                  <select className="form-select btn btn-sm btn_secondary" onChange={(e) => ListChange(e.target.value)}>
                    <option value="1">Teams</option>
                    <option value="2">Users</option>
                  </select>
                    <div className="input-group form_search my-3 dropdown-item">
                      <input type="text" className="form-control" name="search" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)}/>
                      <div className="input-group-append">
                        <button className="btn btn-secondary" type="button">
                        <img src={toAbsoluteUrl('/media/custom/header-icons/search.svg')} className="svg_icon" alt='' />
                        </button>
                      </div>
                    </div>
              {list == 1 ? <>
                  {!search
                      ? teams.map((userData, i) => {
                        return(                            
                        <li className="list-group p-4" key={i}>
                          <div className="">
                            <a type="button" onClick={(e) => handleTeam(userData.team_leader, userData.team_leader_name)}>
                                <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                                {userData.team_leader_name}
                            </a>
                            </div>                                  
                        </li>
                        )}) 
                      : filteredTeam.length ? filteredTeam.map((item) => (<li  className="list-group p-4">
                      <div className="">
                              <a type="button" onClick={(e) => handleTeam(item.team_leader, item.team_leader_name)}>
                                <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                                {item.team_leader_name}
                              </a>
                            </div>
                  </li>)) : <p>No Records!!!</p> }
              </> : <>
                {!search
                    ? users.map((item, i) => (
                    <li className="dropdown-item" key={i} onClick={() => userFilter(item.employee_name, item.user_id, item.designation)}>{item.employee_name}</li>
                    ))
                    : filtered.map((item, j) => (
                      <li className="dropdown-item" key={j} onClick={() => userFilter(item.employee_name, item.user_id, item.designation)}>{item.employee_name}</li>
                      ))}</>}
                </ul></>}
                <select className="form-select dash_btn m-2 fs-9" onChange={(e) => rangeSelectPie(e.target.value)}>
                    <option selected value="1">Today</option>
                    <option value="2">Yesterday</option>
                    <option value="3">Last Week</option>
                    <option value="4">Last Month</option>
                    <option value="6">This Month</option>
                    <option value="7">This Year</option>
                    <option value="5">Custom Date</option>
                </select>
                <button type='button' data-bs-toggle='modal' data-bs-target='#contactPieChartCustomDate' className='d-none' id='contactPieCustomDateRange'>Custom Date</button>
                
                </div>
                
            </div>
            
            <div className="card-body">
            <>
            <Doughnut className='d-block source_graph' options={options} data={dataContact} />  
            </>
            </div>
        </div>
        </>
    )
}
export {ContactDoughnut}