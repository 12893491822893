import React,{FC, useState,useEffect} from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import {useAuth} from '../../../app/modules/auth'
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import { Toast } from 'bootstrap';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { getAllUsers, getCompanyName, getProjectName, getTeamLeader, getTrnsactionTLDrop, saveTrnsactions } from './core/_requests';
import { getCity, getLeadSource, getUnitType } from '../settings/masters/core/_requests';
// import { getProjects } from '../task/core/_requests';
import {getProjects,getProperties} from '../property/core/_requests';
import { getLeadDetail, getLeadsByRole } from '../lead/core/_requests';
import { getContactByRole, getDeveloperNameList } from '../contact/core/_requests';
import Moment from 'moment';
import { getTeamsList } from '../settings/userManagement/core/_requests';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

const initialValues = {
    contact_id : '',
    lead_creation_date: '',
    booking_date: '',
    city: '',
    lead_source: '',
    team_leader: '',
    closed_by: '',
    developer_name: '',
    project_name: '',
    client_name: '',
    contact_number: '',
    email_id: '',
    discount_value: '',
    block_no: '',
    unit_no: '',
    floor_no: '',
    bhk_type: '',
    sizes: '',
    basic_price: '',
    frc: '',
    plc: '',
    car_parking_cost: '',
    agreement_value: '',
    discount_paid_status: '',
    pan: '',
    dob: '',
    doa: '',
    correspondence_address: '',
    Brokerage_percentage: '',
    aop_per: '',
    Brokerage_value: '',
    discount_amount: '',
    revenue: '',
    tds_value: '',
    s_gst_per: '',
    c_gst_per: '',
    i_gst_per: '',
    gst_amount: '',
    gross_brokerage_value: '',
    tds_per_ded_builder: '',
    tds_amount: '',
    after_tds_brokerage: '',
    actual_receivable_amount: '',
    incentive_per: '',
    incentive_without_tds: '',
    tds_per: '',
    net_incentive_earned: '',
    invoice_status: '',
    raised: '',
    pending: '',
    payment_status: '',
    amount_received: '',
    receiving_date: '',
    pending_brokerage: '',
    s_gst_2: '',
    c_gst_3: '',
    i_gst_4: '',
    gst_amount2: '',
    gross_brokerage_value2: '',
    tds_reducted_by_builder3: '',
    tds_amount2: '',
    after_tds_brokearge5: '',
    pending_receivable_amount: '',
    created_by: '',
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, aminityName: string[], theme: Theme) {
    return {
        fontWeight:
        aminityName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
    };
}

const mystyle = {
    padding: "0px",
    height: "34px",
    maxHeight: "200px",
    overflowY: "scroll",
    // display: "block"
};


  type Props = {
    setLeads?: any,
    leadName?: any,
    transactionEnabled?: any
    transactionData?: any,
}

const TransactionForm: FC<Props> = (props) => {

    const {
        transactionEnabled, transactionData, leadName, 
       } = props

        console.log('transactionData',transactionData);
        console.log('transactionEnabled',transactionEnabled);

    const transactionSaveSchema = Yup.object().shape({
        date: Yup.string(),
        lead_creation_date: Yup.string(),
        booking_date: Yup.string(),
        city: Yup.string(),
        lead_source: Yup.string(),
        team_leader: Yup.string(),
        closed_by: Yup.string(),
        developer_name: Yup.string(),
        project_name: Yup.string(),
        client_name: Yup.string(),
        contact_number: Yup.string(),
        email_id: Yup.string(),
        discount_value: Yup.string(),
        block_no: Yup.string(),
        discount_paid_status: Yup.string(),
        unit_no: Yup.string(),
        floor_no: Yup.string(),
        bhk_type: Yup.string(),
        sizes: Yup.string(),
        basic_price: Yup.string(),
        frc: Yup.string(),
        plc: Yup.string(),
        car_parking_cost: Yup.string(),
        agreement_value: Yup.string(),
        pan: Yup.string(),
        dob: Yup.string(),
        doa: Yup.string(),
        correspondence_address: Yup.string(),
        Brokerage_percentage: Yup.string(),
        aop_per: Yup.string(),
        Brokerage_value: Yup.string(),
        discount_amount: Yup.string(),
        revenue: Yup.string(),
        tds_value: Yup.string(),
        s_gst_per: Yup.string(),
        c_gst_per: Yup.string(),
        i_gst_per: Yup.string(),
        gst_amount: Yup.string(),
        gross_brokerage_value: Yup.string(),
        tds_per_ded_builder: Yup.string(),
        tds_amount: Yup.string(),
        after_tds_brokerage: Yup.string(),
        actual_receivable_amount: Yup.string(),
        incentive_per: Yup.string(),
        incentive_without_tds: Yup.string(),
        tds_per: Yup.string(),
        net_incentive_earned: Yup.string(),
        invoice_status: Yup.string(),
        raised: Yup.string(),
        pending: Yup.string(),
        payment_status: Yup.string(),
        amount_received: Yup.string(),
        receiving_date: Yup.string(),
        pending_brokerage: Yup.string(),
        s_gst_2: Yup.string(),
        c_gst_3: Yup.string(),
        i_gst_4: Yup.string(),
        gst_amount2: Yup.string(),
        gross_brokerage_value2: Yup.string(),
        tds_reducted_by_builder3: Yup.string(),
        tds_amount2: Yup.string(),
        after_tds_brokearge5: Yup.string(),
        pending_receivable_amount: Yup.string(),
    })

    const [loading, setLoading] = useState(false);
    const [taskTime, setTaskTime] = React.useState<Date | null>(new Date());
    const [users, setUsers] = useState<any[]>([]);
    const [assignToName, setAssignToName] = React.useState<string[]>([]);
    const [assignToId, setAssignToId] = React.useState<string[]>([]);
    const [assignTo, setAssignTo] = useState<any[]>([]);
    const [clients, setContacts] = useState<any[]>([]);
    const [sharedWithId, setSharedWithId] = useState<any[]>([]);
    const [sharedWithName, setSharedWithName] = useState<any[]>([]);
    const [closedById, setClosedById] = useState<any[]>([]);
    const [closedByName, setClosedByName] = useState<any[]>([]);
    const [teamLeaderName, setTeamLeaderName] = useState<any[]>([]);
    const [teamLeaderId, setTeamLeaderId] = useState<any[]>([]);
    const [leadSource, setLeadSource] = useState<any[]>([]);
    const [projects, setProjects] = useState<any[]>([]);
    const [unitType, setUnitType] = useState<any[]>([]);
    const [city, setCity] = useState<any[]>([]);
    const [developerNameList, setDeveloperNameList] = useState<any[]>([]);
    const [lead, setLead] = useState<any[]>([]);
    const [developer, setDeveloper] = useState<any[]>([]);
    const [teamLeader, setTeamLeader] = useState<any[]>([]);
    const [agreementValue, setAgreementValue] = useState<any>(null);
    const [leadId, setLeadId] = useState(false);
    const [leadDetail, setLeadDetail] = useState<{[key: string]: any}>({});
    const {currentUser, logout} = useAuth();

    const theme = useTheme(); 

    const clientList = async () => {
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const contactRes = await getContactByRole(userId, roleId);
        setContacts(contactRes);
    }

    const handleOnSearch = (string:any, results:any) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        console.log(string, results)
        }

        const handleOnHover = (result:any) => {
            // the item hovered
            console.log("result", result)
        }

        const handleOnFocus = () => {
            console.log('Focused')
        }

        const formatResult = (item:any) => {
            return (
                <>
                <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
                </>
            )
        }


    const teamLeaderChange = (event: SelectChangeEvent<typeof teamLeaderName>) => {
        const {
          target: { value },
        } = event;

        var name = [];
        var id = [];
        
        console.log('val',value)

        for(let i = 0; i < value.length; i++){
          var fields = value[i].split('-');
    
          var n = fields[0];
          var d = fields[1];

          name.push(n);
          id.push(d);
        }
    
        console.log(name);
        console.log(id);
        setTeamLeaderId(id);

        setTeamLeaderName(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
    };
    const sharedWithChange = (event: SelectChangeEvent<typeof sharedWithName>) => {
        const {
          target: { value },
        } = event;

        var name = [];
        var id = [];
    
        for(let i = 0; i < value.length; i++){
          var fields = value[i].split('-');
    
          var n = fields[0];
          var d = fields[1];
    
          name.push(n);
          id.push(d);
        }
    

        console.log(name);
        console.log(id);
    
        setSharedWithId(id);

        setSharedWithName(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
    };
    const closedByChange = (event: SelectChangeEvent<typeof closedByName>) => {
        const {
          target: { value },
        } = event;

        var name = [];
        var id = [];
    
        for(let i = 0; i < value.length; i++){
          var fields = value[i].split('-');
    
          var n = fields[0];
          var d = fields[1];
    
          name.push(n);
          id.push(d);
        }
    
        console.log(name);
        console.log(id);
    
        setClosedById(id);

        setClosedByName(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
    };

    const formik = useFormik({
        initialValues,
        validationSchema: transactionSaveSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
        //   console.log('lead form body');
        //   console.log(values);
        console.log('sddddddddassdsf');
          try {           
          

            var userId = currentUser?.id;
            var body = {
                "shared_with": sharedWithId,
                "booking_date":values.booking_date == "Invalid date" ? '' : values.booking_date,
                "city":values.city,
                "lead_source":values.lead_source,
                "team_leader": teamLeaderId,
                "closed_by": closedById,
                "developer_name":values.developer_name,
                "project_name":values.project_name,
                "client_name":values.client_name,
                "contact_number":values.contact_number,
                "email_id":values.email_id,
                "discount_paid_status":values.discount_paid_status,
                "discount_value":values.discount_value,
                "block_no":values.block_no,
                "unit_no":values.unit_no,
                "floor_no":values.floor_no,
                "bhk_type":values.bhk_type,
                "sizes":values.sizes,
                "basic_price":values.basic_price,
                "frc":values.frc,
                "plc":values.plc,
                "car_parking_cost":values.car_parking_cost,
                "agreement_value":values.agreement_value,
                "pan":values.pan,
                "dob":values.dob == "Invalid date" ? '' : values.dob,
                "doa":values.doa == "Invalid date" ? '' : values.doa,
                "correspondence_address":values.correspondence_address,
                "Brokerage_percentage":values.Brokerage_percentage,
                "Brokerage_value":values.Brokerage_value,
                "aop_per":values.aop_per,
                "discount_amount":values.discount_amount,
                "revenue":values.revenue,
                "tds_value":values.tds_value,
                "s_gst_per": '',
                "c_gst_per": '',
                "i_gst_per": '',
                "gst_amount": '',
                "gross_brokerage_value": '',
                "tds_per_ded_builder": '',
                "tds_amount": '',
                "after_tds_brokerage": '',
                "actual_receivable_amount": '',
                "incentive_per": '',
                "incentive_without_tds": '',
                "tds_per": '',
                "net_incentive_earned": '',
                "invoice_status": '',
                "raised": '',
                "pending": '',
                "payment_status": '',
                "amount_received": '',
                "receiving_date": '',
                "pending_brokerage": '',
                "s_gst_2": '',
                "c_gst_3": '',
                "i_gst_4": '',
                "gst_amount2": '',
                "gross_brokerage_value2": '',
                "tds_reducted_by_builder3": '',
                "tds_amount2": '',
                "after_tds_brokearge5": '',
                "pending_receivable_amount": '',
                "created_by": userId!.toString()
                }
          console.log('body',body);
            const saveTaskData = await saveTrnsactions(body);
    
            console.log('saveTaskData');
            console.log(saveTaskData);
            // document.getElementById('kt_contact')?.classList.remove('drawer-on');
            if(saveTaskData != null){
                setLoading(false);                
                resetForm();
                setClosedById([]);
                setSharedWithId([]);
                setTeamLeaderId([]);
                setClosedByName([]);
                setSharedWithName([]);
                setTeamLeaderName([]);
                document.getElementById('kt_transaction_close')?.click();
                document.getElementById('transaction_refresh_btn')?.click();
                var toastEl = document.getElementById('myToastAddTransaction');
                const bsToast = new Toast(toastEl!);
                bsToast.show();
            }
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
    }})

    const LeadSourceList =  async () => {
        const LeadSourceResponse = await getLeadSource()        
        setLeadSource(LeadSourceResponse);
    }

    // const projectList =  async () => {
    //     const projectResponse = await getProjectName();
    //     setProjects(projectResponse);
    // }
    const projectList =  async () => {
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const projectResponse = await getProperties(userId, roleId);
        setProjects(projectResponse);
    }

    const developerList =  async () => {
        const projectResponse = await getCompanyName();
        setDeveloper(projectResponse);
    }

    const UnitTypeList =  async () => {
        const UnitTypeResponse = await getUnitType()        
        setUnitType(UnitTypeResponse);
    }  
    
    const CityList =  async () => {
        const CityResponse = await getCity()
        setCity(CityResponse);
    }

    const TeamLeaderList =  async () => {
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const Response = await getTrnsactionTLDrop(userId, roleId)
        
        console.log('zzzzzzahbvjhvbof',Response)
        setTeamLeader(Response);
    }

    const usersList =  async () => {
        const Response = await getAllUsers()
        setUsers(Response);
    }

    const leadListByRole =  async () => {  
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const characterResponse = await getLeadsByRole(userId, roleId)

        
    var contactDropList = [];

    for(let i = 0; i < characterResponse.length; i++) {
        var data = { "id": characterResponse[i].id, "name": characterResponse[i].contact_name, }
        contactDropList.push(data);
    }

        setLead(contactDropList);
        console.log('lead',contactDropList)
    }

    const sum = (a:any, b:any) => {
       
        if(!a && b) {
            let c = 0 + parseInt(b);
            return c;
        } else if(!b && a) {
            let c = parseInt(a) + 0;
            return c;
        } else {
            let c = parseInt(a) + parseInt(b);
            return c;
        }        
    }

    const handleDiscount = (discount:any) => {
        formik.setFieldValue('discount_amount', discount ?? '');
        let brokerageValue:any = (document.getElementById('brokerage_value') as HTMLInputElement).value;
        let revenue:any = brokerageValue - discount;
        formik.setFieldValue('revenue', revenue ?? '');
    }

    const closeTransaction = () => {
        formik.resetForm();
    }

    const contactDropSelect = async (id:any, name:any) => {
        formik.setFieldValue('contact_id', id ?? '');
        // setContactType(type);
        console.log('type');
        console.log(name);
        console.log('trId',id)
        const fetchDetails = await getLeadDetail(id)
        setLeadDetail(fetchDetails);
        formik.setFieldValue('city', fetchDetails.city ?? '');
        formik.setFieldValue('lead_source', fetchDetails.lead_source ?? '');
        formik.setFieldValue('developer_name', fetchDetails.company_name ?? '');
        formik.setFieldValue('project_name', fetchDetails.property_id ?? '');
        formik.setFieldValue('client_name', fetchDetails.contact_id ?? '');
        formik.setFieldValue('contact_number', fetchDetails.mobile ?? '');
        formik.setFieldValue('email_id', fetchDetails.email ?? '');
        formik.setFieldValue('dob', Moment(fetchDetails.dob).format('YYYY-MM-DD') ?? '');
        formik.setFieldValue('doa', Moment(fetchDetails.doa).format('YYYY-MM-DD') ?? '');
      }
    

    const transactionLead = async (id:any) => {
        console.log('trId',id)
        const fetchDetails = await getLeadDetail(id)
        setLeadDetail(fetchDetails);
                

        formik.setFieldValue('city', fetchDetails.city ?? '');
        formik.setFieldValue('lead_source', fetchDetails.lead_source ?? '');
        formik.setFieldValue('developer_name', fetchDetails.company_name ?? '');
        formik.setFieldValue('project_name', fetchDetails.property_id ?? '');
        formik.setFieldValue('client_name', fetchDetails.contact_id ?? '');
        formik.setFieldValue('contact_number', fetchDetails.mobile ?? '');
        formik.setFieldValue('email_id', fetchDetails.email ?? '');
        formik.setFieldValue('dob', Moment(fetchDetails.dob).format('YYYY-MM-DD') ?? '');
        formik.setFieldValue('doa', Moment(fetchDetails.doa).format('YYYY-MM-DD') ?? '');
    }

    const developerNames =  async () => {  
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;   
        const contactDropResponse = await getDeveloperNameList(userId, roleId)
        setDeveloperNameList(contactDropResponse);
    }
    
    useEffect(() => {
        transactionData && transactionLead(transactionData);
    }, [transactionEnabled == true]);

    useEffect(() => {
        LeadSourceList();
        developerNames();
        projectList();
        UnitTypeList();
        CityList();
        TeamLeaderList();
        usersList();
        developerList();
        leadListByRole();
        clientList();
    }, []);

    return(
        <div className='card shadow-none rounded-0 w-100'>
            <div className='card-header w-100' id='kt_transaction_header'>
                <h3 className='card-title fw-bolder text-dark'>Add Transaction</h3>

                <div className='card-toolbar'>
                    <button
                    type='button'
                    className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                    id='kt_transaction_close'
                    onClick={closeTransaction}
                    >
                        <img src={toAbsoluteUrl('/media/custom/cancel_black.svg')} className="svg_icon" alt='' />
                        {/* <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' /> */}
                    </button>
                </div>
            </div>
            
            <div className='card-body position-relative' id='kt_transaction_body'>
                <form noValidate onSubmit={formik.handleSubmit}>
                <div className="accordion" id="accordionExample"> 
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Basic Details
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className="row">
                                    {/* <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Lead Creation Date</label>
                                        <div className="input-group mb-3">
                                            <input type="date" {...formik.getFieldProps('lead_creation_date')} className="form-control"/> 
                                        </div>
                                    </div> */}
                                    {/* {!transactionEnabled &&
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label required">Select Lead</label>
                                        <div className="input-group mb-3">
                                            <select className="btn_secondary btn btn-sm w-100" onChange={(e) => transactionLead(e.target.value)} >
                                            <option value=''>Select</option>
                                                {lead.map((data,i) =>{
                                                    return (
                                                        <option value={data.id} key={i}>{data.contact_name}-{data.id}</option> 
                                                )})}
                                            </select>
                                        </div>
                                    </div>} */}

                                    <div className={ transactionEnabled?'col-md-6 col-12 mb-3 d-none':'col-md-6 col-12 mb-3'} id='lead-tran'>
                                        <label htmlFor="basic-url" className="form-label required">Select Lead</label>
                                        {!transactionEnabled && leadName != null ?
                                        <div className="input-group mb-3">
                                         <input type="text" value={leadName['name']} onChange={(e) => transactionLead(leadName['name'])}  className="form-control "  placeholder="Contact Person" aria-label="Search a contact" />
                                        </div>                    
                                          : 
                                    <div className="input-group mb-3">  
                                    <div className='autocomplete_field'>
                                        <ReactSearchAutocomplete
                                            items={lead}
                                            {...formik.getFieldProps('contact_id')}
                                            onSearch={handleOnSearch}
                                            onHover={handleOnHover}
                                            onSelect={(val) => contactDropSelect(val.id,val.name)}
                                            onFocus={handleOnFocus}
                                            placeholder="search Lead"
                                            styling={mystyle}
                                            autoFocus
                                            formatResult={formatResult}
                                        />                                        
                                    </div>
                                </div> }
                                {formik.touched.contact_id && formik.errors.contact_id && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert' className='text-danger'>{formik.errors.contact_id}</span>
                                        </div>
                                    </div>
                                    )}
                                    </div>
                                    
                                    <><div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Booking Date</label>
                                                <div className="input-group mb-3">
                                                    <input type="date" {...formik.getFieldProps('booking_date')} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">City</label>
                                                    <div className="input-group mb-3">
                                                        <select disabled className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('city')}>
                                                            <option value=''>Select</option>
                                                            {city.map((taskProject, i) => {
                                                                return (
                                                                    <option selected={i == 0 ? true : false} value={taskProject.id} key={i}>{taskProject.name}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div><div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Lead Source</label>
                                                    <div className="input-group mb-3">
                                                        <select disabled className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('lead_source')}>
                                                            <option value=''>Select</option>
                                                            {leadSource.map((task, i) => {
                                                                return (
                                                                    <option selected={i == 0 ? true : false} value={task.id} key={i}>{task.name}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Team Leader</label>
                                                    <FormControl sx={{ m: 0, width: 300, mt: 0 }}>
                                                        <Select
                                                            multiple
                                                            displayEmpty
                                                            value={teamLeaderName}
                                                            onChange={teamLeaderChange}
                                                            input={<OutlinedInput />}
                                                            renderValue={(selected) => {
                                                                var name = [];
                                                                var id = [];

                                                                for (let i = 0; i < selected.length; i++) {
                                                                    var fields = selected[i].split('-');

                                                                    var n = fields[0];
                                                                    var d = fields[1];

                                                                    name.push(n);
                                                                    id.push(d);
                                                                }

                                                                // setAssignToId(id);
                                                                if (selected.length === 0) {
                                                                    return <p>Team Leader</p>;
                                                                }

                                                                return name.join(', ');
                                                            } }
                                                            className='multi_select_field'
                                                            MenuProps={MenuProps}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                        >
                                                            <MenuItem disabled value="">
                                                                <em>Team Leader</em>
                                                            </MenuItem>
                                                            {teamLeader.map((assignVal) => (
                                                                <MenuItem
                                                                    className=''
                                                                    key={assignVal.id}
                                                                    value={assignVal.first_name + '-' + assignVal.id}
                                                                    style={getStyles(assignVal.first_name, assignToName, theme)}
                                                                >
                                                                    {assignVal.first_name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div> */}
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Team Leader</label>
                                                    <FormControl sx={{ m: 0, width: 300, mt: 0 }}>
                                                        <Select
                                                            multiple
                                                            displayEmpty
                                                            value={teamLeaderName}
                                                            onChange={teamLeaderChange}
                                                            input={<OutlinedInput />}
                                                            renderValue={(selected) => {
                                                                var name = [];
                                                                var id = [];

                                                                for (let i = 0; i < selected.length; i++) {
                                                                    var fields = selected[i].split('-');

                                                                    var n = fields[0];
                                                                    var d = fields[1];

                                                                    name.push(n);
                                                                    id.push(d);
                                                                }

                                                                // setAssignToId(id);
                                                                if (selected.length === 0) {
                                                                    return <p>Team Leader</p>;
                                                                }

                                                                return name.join(', ');
                                                            } }
                                                            className='multi_select_field'
                                                            MenuProps={MenuProps}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                        >
                                                            <MenuItem disabled value="">
                                                                <em>Team Leader</em>
                                                            </MenuItem>
                                                            {teamLeader.map((assignVal) => (
                                                                <MenuItem
                                                                    className=''
                                                                    key={assignVal.team_leader}
                                                                    value={assignVal.team_leader_name + '-' + assignVal.team_leader}
                                                                    style={getStyles(assignVal.team_leader_name, assignToName, theme)}
                                                                >
                                                                    {assignVal.team_leader_name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>

                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Shared With</label>
                                                    <FormControl sx={{ m: 0, width: 300, mt: 0 }}>
                                                        <Select
                                                            multiple
                                                            displayEmpty
                                                            value={sharedWithName}
                                                            onChange={sharedWithChange}
                                                            input={<OutlinedInput />}
                                                            renderValue={(selected) => {
                                                                var name = [];
                                                                var id = [];

                                                                for (let i = 0; i < selected.length; i++) {
                                                                    var fields = selected[i].split('-');

                                                                    var n = fields[0];
                                                                    var d = fields[1];

                                                                    name.push(n);
                                                                    id.push(d);
                                                                }

                                                                // setAssignToId(id);
                                                                if (selected.length === 0) {
                                                                    return <p>Shared With</p>;
                                                                }

                                                                return name.join(', ');
                                                            } }
                                                            className='multi_select_field'
                                                            MenuProps={MenuProps}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                        >
                                                            <MenuItem disabled value="">
                                                                <em>Shared With</em>
                                                            </MenuItem>
                                                            {users.map((assignVal) => (
                                                                <MenuItem
                                                                    className=''
                                                                    key={assignVal.user_id}
                                                                    value={assignVal.employee_name + '-' + assignVal.user_id}
                                                                    style={getStyles(assignVal.employee_name, assignToName, theme)}
                                                                >
                                                                    {assignVal.employee_name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                {/* <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Closed by</label>
                                                    <FormControl sx={{ m: 0, width: 300, mt: 0 }}>
                                                        <Select
                                                            multiple
                                                            displayEmpty
                                                            value={closedByName}
                                                            onChange={closedByChange}
                                                            input={<OutlinedInput />}
                                                            renderValue={(selected) => {
                                                                var name = [];
                                                                var id = [];

                                                                for (let i = 0; i < selected.length; i++) {
                                                                    var fields = selected[i].split('-');

                                                                    var n = fields[0];
                                                                    var d = fields[1];

                                                                    name.push(n);
                                                                    id.push(d);
                                                                }

                                                                // setAssignToId(id);
                                                                if (selected.length === 0) {
                                                                    return <p>Closed by</p>;
                                                                }

                                                                return name.join(', ');
                                                            } }
                                                            className='multi_select_field'
                                                            MenuProps={MenuProps}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                        >
                                                            <MenuItem disabled value="">
                                                                <em>Closed by</em>
                                                            </MenuItem>
                                                            {users.map((assignVal) => (
                                                                <MenuItem
                                                                    className=''
                                                                    key={assignVal.user_id}
                                                                    value={assignVal.employee_name + '-' + assignVal.user_id}
                                                                    style={getStyles(assignVal.employee_name, assignToName, theme)}
                                                                >
                                                                    {assignVal.employee_name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div> */}
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Developer Name</label>
                                                    <div className="input-group mb-3">
                                                        <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('developer_name')}>
                                                        <option value=''>Select</option>
                                                            {developerNameList.map((task,i) =>{
                                                                return (<>
                                                                {task.id != null &&
                                                                <option selected={i == 0 ? true: false} value={task.id} key={i}>{task.developer_fullname}</option>} </>
                                                            )})}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Project Name</label>
                                                    <div className="input-group mb-3">
                                                        <select disabled className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('project_name')}>
                                                            <option value=''>Select</option>
                                                            {projects.map((task, i) => {
                                                                return (
                                                                    <option selected={i == 0 ? true : false} value={task.id} key={i}>{task.name_of_building}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Client Name</label>
                                                    <div className="input-group mb-3">
                                                        <select disabled className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('client_name')}>
                                                            <option value=''>Select</option>
                                                            {clients.map((task, i) => {
                                                                return (
                                                                    <option selected={i == 0 ? true : false} value={task.id} key={i}>{task.first_name + " " + task.last_name}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Contact Number</label>
                                                    <div className="input-group mb-3">
                                                        <input disabled type="number" {...formik.getFieldProps('contact_number')} className="form-control" />
                                                    </div>
                                                </div><div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Email</label>
                                                    <div className="input-group mb-3">
                                                        <input disabled type="email" {...formik.getFieldProps('email_id')} className="form-control" />
                                                    </div>
                                                </div>                                                
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Tower/Block (No. /Name)</label>
                                                    <div className="input-group mb-3">
                                                        <input type="text" {...formik.getFieldProps('block_no')} className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Unit Number</label>
                                                    <div className="input-group mb-3">
                                                        <input type="text" {...formik.getFieldProps('unit_no')} className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Floor Number</label>
                                                    <div className="input-group mb-3">
                                                        <input type="text" {...formik.getFieldProps('floor_no')} className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">BHK Type</label>
                                                    <div className="input-group mb-3">
                                                        <input type="text" {...formik.getFieldProps('bhk_type')} className="form-control" />
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">BHK Type</label>
                                                    <div className="input-group mb-3">
                                                        <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('bhk_type')}>
                                                            <option value=''>Select</option>
                                                            {unitType.map((taskPrior, i) => {
                                                                return (
                                                                    <option selected={i == 0 ? true : false} value={taskPrior.id} key={i}>{taskPrior.name}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div> */}
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Unit Size</label>
                                                    <div className="input-group mb-3">
                                                        <input type="text" {...formik.getFieldProps('sizes')} className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Basic Price</label>
                                                    <div className="input-group mb-3">
                                                        <input type="text" {...formik.getFieldProps('basic_price')} className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">FRC</label>
                                                    <div className="input-group mb-3">
                                                        <input type="text" {...formik.getFieldProps('frc')} className="form-control" />
                                                    </div>
                                                </div><div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">PLC</label>
                                                    <div className="input-group mb-3">
                                                        <input type="text" {...formik.getFieldProps('plc')} className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Car Parking Cost</label>
                                                    <div className="input-group mb-3">
                                                        <input type="text" {...formik.getFieldProps('car_parking_cost')} className="form-control" />
                                                    </div>
                                                </div><div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">PAN Number</label>
                                                    <div className="input-group mb-3">
                                                        <input type="text" {...formik.getFieldProps('pan')} className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Date of Birth</label>
                                                    <div className="input-group mb-3">
                                                        <input disabled type="date" {...formik.getFieldProps('dob')} className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Anniversary Date</label>
                                                    <div className="input-group mb-3">
                                                        <input disabled type="date" {...formik.getFieldProps('doa')} className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Agreement Value</label>
                                                    <div className="input-group mb-3">
                                                        <input type="number" {...formik.getFieldProps('agreement_value')} className="form-control" id="agreement_value" onChange={(e:any) => {
                                                            // setAgreementValue(e.target.value); 
                                                            formik.setFieldValue('agreement_value', e.target.value ?? '')
                                                            let brokeragePer:any = (document.getElementById('Brokerage_percentage') as HTMLInputElement)?.value;
                                                            let aopPer:any = (document.getElementById('aop_per') as HTMLInputElement)?.value;
                                                            let discPer:any = (document.getElementById('discount_value') as HTMLInputElement)?.value;
                                                            let brokerageValue:any = e.target.value * sum(brokeragePer, aopPer) / 100;
                                                            formik.setFieldValue('Brokerage_value', brokerageValue ?? ''); 
                                                            let discountAmount:any = brokerageValue * discPer / 100;
                                                            formik.setFieldValue('discount_amount', discountAmount ?? '');  
                                                            formik.setFieldValue('tds_value', brokerageValue * 5 / 100);  
                                                            let revenue:any = brokerageValue - discountAmount;
                                                            formik.setFieldValue('revenue', revenue ?? '');                                         
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Brokerage %</label>
                                                    <div className="input-group mb-3">
                                                        <input type="number" {...formik.getFieldProps('Brokerage_percentage')} onChange={(e:any) => {
                                                            formik.setFieldValue('Brokerage_percentage', e.target.value ?? '');
                                                            let agreementValue:any = (document.getElementById('agreement_value') as HTMLInputElement)?.value;
                                                            let aopPer:any = (document.getElementById('aop_per') as HTMLInputElement)?.value;
                                                            let discPer:any = (document.getElementById('discount_value') as HTMLInputElement)?.value;
                                                            let brokerageValue:any = agreementValue * sum(e.target.value, aopPer) / 100;
                                                            formik.setFieldValue('Brokerage_value', brokerageValue ?? '');
                                                            let discountAmount:any = brokerageValue * discPer / 100;
                                                            formik.setFieldValue('discount_amount', discountAmount ?? ''); 
                                                            formik.setFieldValue('tds_value', brokerageValue * 5 / 100);
                                                            let revenue:any = brokerageValue - discountAmount;
                                                            formik.setFieldValue('revenue', revenue ?? '');
                                                        }} className="form-control" id='Brokerage_percentage'/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">AOP %</label>
                                                    <div className="input-group mb-3">
                                                        <input type="number" {...formik.getFieldProps('aop_per')} className="form-control" id='aop_per' onChange={(e:any) => {
                                                            // setAgreementValue(e.target.value); 
                                                            formik.setFieldValue('aop_per', e.target.value ?? '')
                                                            let brokeragePer:any = (document.getElementById('Brokerage_percentage') as HTMLInputElement)?.value;
                                                            let agreementval:any = (document.getElementById('agreement_value') as HTMLInputElement)?.value;
                                                            let discPer:any = (document.getElementById('discount_value') as HTMLInputElement)?.value;
                                                            let brokerageValue:any = agreementval * sum(brokeragePer, e.target.value) / 100;
                                                            formik.setFieldValue('Brokerage_value', brokerageValue ?? '');  
                                                            let discountAmount:any = brokerageValue * discPer / 100;
                                                            formik.setFieldValue('discount_amount', discountAmount ?? ''); 
                                                            formik.setFieldValue('tds_value', brokerageValue * 5 / 100); 
                                                            let revenue:any = brokerageValue - discountAmount;
                                                            formik.setFieldValue('revenue', revenue ?? '');                                      
                                                        }}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Brokerage Value</label>
                                                    <div className="input-group mb-3">
                                                        <input type="number" {...formik.getFieldProps('Brokerage_value')} className="form-control" id="brokerage_value" onChange={(e:any) => {
                                                            formik.setFieldValue('Brokerage_value', e.target.value ?? '')
                                                            let discPer:any = (document.getElementById('discount_value') as HTMLInputElement)?.value;
                                                            let discountAmount:any = e.target.value * discPer / 100;
                                                            formik.setFieldValue('discount_amount', discountAmount ?? '');
                                                            formik.setFieldValue('tds_value', e.target.value * 5 / 100);
                                                            let revenue:any = e.target.value - discountAmount;
                                                            formik.setFieldValue('revenue', revenue ?? '');
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Discount % <small>(if any)</small></label>
                                                    <div className="input-group mb-3">
                                                        <input type="number" {...formik.getFieldProps('discount_value')} className="form-control" id='discount_value' onChange={(e:any) => {
                                                            formik.setFieldValue('discount_value', e.target.value ?? '')
                                                            let brokeragePer:any = (document.getElementById('Brokerage_percentage') as HTMLInputElement)?.value;
                                                            let agreementval:any = (document.getElementById('agreement_value') as HTMLInputElement)?.value;
                                                            let aopPer:any = (document.getElementById('aop_per') as HTMLInputElement)?.value;
                                                            let brokerageValue:any = agreementval * sum(brokeragePer, aopPer) / 100;
                                                            formik.setFieldValue('Brokerage_value', brokerageValue ?? ''); 
                                                            let discountAmount:any = brokerageValue * e.target.value / 100;
                                                            formik.setFieldValue('discount_amount', discountAmount ?? '');
                                                            formik.setFieldValue('tds_value', brokerageValue * 5 / 100);
                                                            let revenue:any = brokerageValue - discountAmount;
                                                            formik.setFieldValue('revenue', revenue ?? '');
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Discount Amount</label>
                                                    <div className="input-group mb-3">
                                                        <input type="number" {...formik.getFieldProps('discount_amount')} className="form-control" id='discount_amount' onChange={(e:any) => {
                                                            formik.setFieldValue('discount_amount', e.target.value ?? '');
                                                            let brokerageValue:any = (document.getElementById('brokerage_value') as HTMLInputElement).value;
                                                            let revenue:any = brokerageValue - e.target.value;
                                                            formik.setFieldValue('revenue', revenue ?? '');
                                                            formik.setFieldValue('tds_value', brokerageValue * 5 / 100);
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">TDS Value @ 5%</label>
                                                    <div className="input-group mb-3">
                                                        <input disabled type="number" {...formik.getFieldProps('tds_value')} className="form-control" id='tds_value' />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Discount Paid</label>
                                                    <div className="input-group mb-3">
                                                        {/* <input type="text" {...formik.getFieldProps('discount_amount')} className="form-control" onChange={(e) => handleDiscount(e.target.value)} /> */}
                                                        <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('discount_paid_status')}>
                                                            <option value=''>Select</option>
                                                            <option value='1'>Yes</option>
                                                            <option value='0'>No</option>                                                 
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Revenue</label>
                                                    <div className="input-group mb-3">
                                                        <input type="number" {...formik.getFieldProps('revenue')} className="form-control" />
                                                    </div>
                                                </div></>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Tax Details
                            </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className='row'>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">S GST %</label>
                                        <div className="input-group mb-3">
                                            <input type="text" {...formik.getFieldProps('s_gst_per')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">C GST %</label>
                                        <div className="input-group mb-3">
                                            <input type="text" {...formik.getFieldProps('c_gst_per')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">I GST %</label>
                                        <div className="input-group mb-3">
                                            <input type="text" {...formik.getFieldProps('i_gst_per')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">GST Amount</label>
                                        <div className="input-group mb-3">
                                            <input type="text" {...formik.getFieldProps('gst_amount')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Gross Brokerage Value</label>
                                        <div className="input-group mb-3">
                                            <input type="text" {...formik.getFieldProps('gross_brokerage_value')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">TDS % Deducted by Builder</label>
                                        <div className="input-group mb-3">
                                            <input type="text" {...formik.getFieldProps('tds_per_ded_builder')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">TDS Amount</label>
                                        <div className="input-group mb-3">
                                            <input type="text" {...formik.getFieldProps('tds_amount')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">After TDS Brokearge</label>
                                        <div className="input-group mb-3">
                                            <input type="text" {...formik.getFieldProps('after_tds_brokerage')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Actual Receivable Amount</label>
                                        <div className="input-group mb-3">
                                            <input type="text" {...formik.getFieldProps('actual_receivable_amount')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Incentive%</label>
                                        <div className="input-group mb-3">
                                            <input type="text" {...formik.getFieldProps('incentive_per')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Incentive Without TDS</label>
                                        <div className="input-group mb-3">
                                            <input type="text" {...formik.getFieldProps('incentive_without_tds')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">TDS%</label>
                                        <div className="input-group mb-3">
                                            <input type="text" {...formik.getFieldProps('tds_per')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Net Incentive Earned</label>
                                        <div className="input-group mb-3">
                                            <input type="text" {...formik.getFieldProps('net_incentive_earned')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Invoice Status</label>
                                        <div className="input-group mb-3">
                                            <input type="text" {...formik.getFieldProps('invoice_status')} className="form-control"/> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Payment Details
                            </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className='row'>                                    
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Raised %</label>
                                        <div className="input-group mb-3">
                                            <input type="text" {...formik.getFieldProps('raised')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Pending %</label>
                                        <div className="input-group mb-3">
                                            <input type="text" {...formik.getFieldProps('pending')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Payment Status</label>
                                        <div className="input-group mb-3">
                                            <input type="text" {...formik.getFieldProps('payment_status')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Amount Received</label>
                                        <div className="input-group mb-3">
                                            <input type="text" {...formik.getFieldProps('amount_received')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Receiving Date</label>
                                        <div className="input-group mb-3">
                                            <input type="date" {...formik.getFieldProps('receiving_date')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Pending Brokerage</label>
                                        <div className="input-group mb-3">
                                            <input type="text" {...formik.getFieldProps('pending_brokerage')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">S GST %</label>
                                        <div className="input-group mb-3">
                                            <input type="date" {...formik.getFieldProps('s_gst_2')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">C GST %</label>
                                        <div className="input-group mb-3">
                                            <input type="text" {...formik.getFieldProps('c_gst_3')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">I GST %</label>
                                        <div className="input-group mb-3">
                                            <input type="date" {...formik.getFieldProps('i_gst_4')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">GST Amount</label>
                                        <div className="input-group mb-3">
                                            <input type="date" {...formik.getFieldProps('gst_amount2')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Gross Brokerage Value</label>
                                        <div className="input-group mb-3">
                                            <input type="text" {...formik.getFieldProps('gross_brokerage_value2')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">TDS % Deducted by Builder</label>
                                        <div className="input-group mb-3">
                                            <input type="text" {...formik.getFieldProps('tds_reducted_by_builder3')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">TDS Amount</label>
                                        <div className="input-group mb-3">
                                            <input type="text" {...formik.getFieldProps('tds_amount2')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">After TDS Brokearge</label>
                                        <div className="input-group mb-3">
                                            <input type="text" {...formik.getFieldProps('after_tds_brokearge5')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Pending Receivable Amount</label>
                                        <div className="input-group mb-3">
                                            <input type="text" {...formik.getFieldProps('pending_receivable_amount')} className="form-control"/> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>                
                <div className='d-flex justify-content-center'>           
                    <button
                    type='submit'
                    id='kt_sign_up_submit'
                    className='btn btn_primary text-primary'
                    disabled={formik.isSubmitting}
                    >
                    {!loading && <span className='indicator-label'>Submit
                    <KTSVG
                    path='/media/custom/save_white.svg'
                    className='svg-icon-3 svg-icon-primary ms-2'
                    />
                    </span>}
                    {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                    </button>
                </div>
            </form> 
            </div>
        </div>
        
    )
}


export {TransactionForm}