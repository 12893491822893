import { DataGrid, GridColDef } from '@mui/x-data-grid'
import React,{FC, useEffect, useState} from 'react'
import Moment from 'moment';
import * as Yup from 'yup'
import { Theme, useTheme } from '@mui/material/styles';
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { Toast } from 'bootstrap';
import { getContacts,} from '../../task/core/_requests';
import {getProjects,getProperties} from '../../property/core/_requests'
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { useAuth } from '../../../modules/auth';
import { useFormik } from 'formik';
import { getFeeConfirmation, saveFeeConfirmation, updateFeeConfirmation } from '../core/_requests';
import { getFeeConfirmationStatus } from '../../settings/masters/core/_requests';
import { getTrnsactions } from '../../transaction/core/_requests';
import { getDeveloperNameList } from '../../contact/core/_requests';

const initialValues = {
    transaction_id: '',
    fee_confirmation_date: '',
    contact_name: '',
    developer_name: '',
    project_name: '',
    Brokerage_value: '',
    due_date: '',
    description: '',
    status: '',
    created_by: ''
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, aminityName: string[], theme: Theme) {
    return {
        fontWeight:
        aminityName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
    };
}

const mystyle = {
    padding: "0px",
    height: "34px",
    maxHeight: "200px",
    overflowY: "scroll",
};

type props = {
    feeConfirmationId:any,
}

const FeeConfirmationEdit: FC<props> = (props) => {

    const {feeConfirmationId} = props

    const theme = useTheme();

    const transactionSaveSchema = Yup.object().shape({
        transaction_id: Yup.string(),
       
    })

    const {currentUser, logout} = useAuth();
    const [loading, setLoading] = useState(false);
    const [projects, setProjects] = useState<any[]>([]);
    const [contacts, setContactsLead] = useState<any[]>([]);
    const [transactions, setTransactions] = useState<any[]>([]);
    const [developerNameList, setDeveloperNameList] = useState<any[]>([]);
    const [feeConfirmationStatus, setFeeConfirmationStatus] = useState<any[]>([]);
    const [feeConfirmationDetails, setFeeConfirmationDetails] = useState<{[key: string]: any}>({});

    const feeConfirmationById = async () => {
        const response = await getFeeConfirmation(feeConfirmationId)
        setFeeConfirmationDetails(response)

        formik.setFieldValue('fee_confirmation_date', Moment(response.fee_confirmation_date).format('YYYY-MM-DD') ?? '')
        formik.setFieldValue('contact_name', response.contact_name ?? '')
        formik.setFieldValue('developer_name', response.developer_name ?? '')
        formik.setFieldValue('project_name', response.project_name ?? '')
        formik.setFieldValue('Brokerage_value', response.Brokerage_value ?? '')
        formik.setFieldValue('due_date',Moment(response.due_date).format('YYYY-MM-DD') ?? '')
        formik.setFieldValue('description', response.description ?? '')
        formik.setFieldValue('status', response.status ?? '')
        formik.setFieldValue('transaction_id', response.transaction_id ?? '')
    }

    const FeeConfirmationStatusList =  async () => {
        const FeeConfirmationStatusResponse = await getFeeConfirmationStatus()
        setFeeConfirmationStatus(FeeConfirmationStatusResponse);
    }

    const projectList =  async () => {
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const projectResponse = await getProperties(userId, roleId);
        setProjects(projectResponse);
    }

    const contactsList =  async () => {   
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const contactsResponse = await getContacts(userId, roleId)
    
        console.log('leads contactsResponse');
        console.log(contactsResponse);
    
        var contactDropList = [];
    
        for(let i = 0; i < contactsResponse.length; i++) {
            var data = { "id": contactsResponse[i].id, "name": contactsResponse[i].first_name + ' '+ contactsResponse[i].last_name, "contact_type": contactsResponse[i].contact_type }
            contactDropList.push(data);
        }
    
        console.log(contactDropList);
        setContactsLead(contactDropList);
      }

      const contactDropSelect = (id:any, type:any) => {
        formik.setFieldValue('contact_name', id ?? '');
        console.log('type');
        console.log(type);
      }

      const formatResult = (item:any) => {
        return (
            <>
            <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
            </>
        )
    }

    const formik = useFormik({
        initialValues,
        validationSchema: transactionSaveSchema ,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
          try {
            var userId = currentUser?.id;

            var body ={
                "transaction_id": values.transaction_id,
                "fee_confirmation_date": values.fee_confirmation_date,
                "contact_name": values.contact_name,
                "developer_name": values.developer_name,
                "project_name": values.project_name,
                "Brokerage_value": values.Brokerage_value,
                "due_date": values.due_date,
                "description": values.description,
                "status": values.status,
                "created_by": userId
            }
          
            const saveTaskData = await updateFeeConfirmation(feeConfirmationId, body);
            if(saveTaskData != null){
                setLoading(false);
                document.getElementById('kt_feeConfirmation_details_close')?.click();
                document.getElementById('feeConfirmationReload')?.click();
                var toastEl = document.getElementById('myToastFeeConfirmationUpdate ');
                const bsToast = new Toast(toastEl!);
                bsToast.show();               
                resetForm();
            }
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
    }})

    const TransactionsList =  async () => {
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const Response = await getTrnsactions(userId, roleId)
        setTransactions(Response);
    }

    const developerNames =  async () => {  
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;   
        const contactDropResponse = await getDeveloperNameList(userId, roleId)
        setDeveloperNameList(contactDropResponse);
    } 

    useEffect(() => {
        projectList();
        developerNames();
        TransactionsList();
        contactsList();
        feeConfirmationId && feeConfirmationById();
        FeeConfirmationStatusList();
    }, [feeConfirmationId]);

    return(
        <>
        <div className='card shadow-none rounded-0 w-100'>
            <div className='card-header w-100' id='kt_feeConfirmation_header'>
                <h3 className='card-title fw-bolder text-dark'>Edit Fee Confirmation</h3>
                <div className='card-toolbar'>
                    <button
                    type='button'
                    className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                    id='kt_feeConfirmation_details_close'>
                        <img src={toAbsoluteUrl('/media/custom/cancel_black.svg')} className="svg_icon" alt='' />
                    </button>
                </div>
            </div>
            
            <div className='card-body position-relative' id='kt_feeConfirmation_body'>
                <form noValidate onSubmit={formik.handleSubmit}>
                <div className="accordion" id="accordionExample"> 
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Basic Details
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className="row">
                                <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Transaction</label>
                                        <div className="input-group mb-3">
                                            <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('transaction_id')}>
                                            <option value=''>Select</option>
                                                {transactions.map((trans,i) =>{
                                                    return (<>
                                                    {trans.id != null &&
                                                    <option selected={i == 0 ? true: false} value={trans.id} key={i}>{trans.client_name}</option>} </>
                                                )})}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">feeConfirmation Date</label>
                                        <div className="input-group mb-3">
                                            <input type="date" {...formik.getFieldProps('fee_confirmation_date')} className="form-control" placeholder="date"/> 
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Client Name</label>
                                        <div className="input-group mb-3">
                                            <div className='autocomplete_field w-100'>
                                                <ReactSearchAutocomplete
                                                    items={contacts}
                                                    {...formik.getFieldProps('contact_name')}
                                                    onSelect={(val) => contactDropSelect(val.id, val.contact_type)}
                                                    placeholder="choose client..."
                                                    styling={mystyle}
                                                    autoFocus
                                                    formatResult={formatResult}/>                                        
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Client Name</label>
                                        <div className="input-group mb-3">
                                            <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('contact_name')}>
                                            <option value=''>Select</option>
                                                {contacts.map((task,i) =>{
                                                    return (
                                                    <option selected={i == 0 ? true: false} value={task.id} key={i}>{task.name}</option> 
                                                )})}
                                            </select>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Developer Name</label>
                                        <div className="input-group mb-3">
                                            <input type="text" {...formik.getFieldProps('developer_name')} className="form-control" placeholder="Developer Name..."/> 
                                        </div>
                                    </div> */}
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Project</label>
                                        <div className="input-group mb-3">
                                            <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('project_name')}>
                                            <option value=''>Select</option>
                                                {projects.map((task,i) =>{
                                                    return (<>
                                                    {task.id != null &&
                                                    <option selected={i == 0 ? true: false} value={task.id} key={i}>{task.name_of_building}</option>} </>
                                                )})}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Developer Name</label>
                                        <div className="input-group mb-3">
                                            <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('developer_name')}>
                                            <option value=''>Select</option>
                                                {developerNameList.map((task,i) =>{
                                                    return (<>
                                                    {task.id != null &&
                                                    <option selected={i == 0 ? true: false} value={task.id} key={i}>{task.developer_fullname}</option>} </>
                                                )})}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Brokerage Value</label>
                                        <div className="input-group mb-3">
                                            <input type="number" min="0" {...formik.getFieldProps('Brokerage_value')} className="form-control" placeholder="Brokerage Value..."/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Due Date</label>
                                        <div className="input-group mb-3">
                                            <input type="date" {...formik.getFieldProps('due_date')} className="form-control" placeholder="Due Date..."/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Description</label>
                                        <div className="input-group mb-3">
                                            <textarea {...formik.getFieldProps('description')} className="form-control" placeholder="Description..."/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">status</label>
                                        <div className="input-group mb-3">
                                            <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('status')}>
                                            <option value=''>Select</option>
                                                {feeConfirmationStatus.map((task,i) =>{
                                                    return (
                                                    <option selected={i == 0 ? true: false} value={task.id} key={i}>{task.name}</option> 
                                                )})}
                                            </select>
                                        </div>
                                    </div>                                                            
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <div className='d-flex justify-content-center'>           
                    <button
                    type='submit'
                    id='kt_sign_up_submit'
                    className='btn btn_primary text-primary'
                    disabled={formik.isSubmitting}
                    >
                    {!loading && <span className='indicator-label'>Submit
                    <KTSVG
                    path='/media/custom/save_white.svg'
                    className='svg-icon-3 svg-icon-primary ms-2'
                    />
                    </span>}
                    {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                    </button>
                </div>
            </form> 
            </div>
        </div> 
        </>
    )

}

export {FeeConfirmationEdit}