/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {FC, useEffect, useState} from 'react'
import { Offcanvas, Toast } from 'bootstrap';
import { useLocation } from 'react-router-dom'
import {useAuth} from '../../../app/modules/auth'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { useLayout } from '../../../_metronic/layout/core'
import { deleteMultipleLeads, getLeadsByRole } from './core/_requests'


type Props = {
    sortByOnChangeLead?: any,
    layoutOnChange?: any,
    selectedLeads?: any,
    setLeadList?: any
}

const LeadToolbar: FC<Props> = (props) => {

  const {
    sortByOnChangeLead, layoutOnChange, selectedLeads, setLeadList
  } = props

  const {classes} = useLayout()
  let location = useLocation();
  console.log("location",location)
  const {currentUser, logout} = useAuth();
  const [actionValue, setActionValue] = useState<any>('');
  const [lead, setLead] = useState<any[]>([]);

  var roleId = currentUser?.designation;

  const leadList = async () => {
    var userId = currentUser?.id;
    var roleId = currentUser?.designation;
    const characterResponse = await getLeadsByRole(userId, roleId)
    setLead(characterResponse);
  }

  const actionChange = async (e:any) => {
    console.log(e.target.value);
    setActionValue(e.target.value);
    console.log('selectedContacts');
    let leadVal = selectedLeads.join(',')
    console.log(leadVal);
    let val = e.target.value;
    if(val == 'import') {
      document.getElementById('kt_lead_import_toggle')?.click();
      setActionValue("default");
    }
    if(val == 'delete'){
      console.log('deletedddd');
      if(leadVal != ''){
        const deleteRes = deleteMultipleLeads(leadVal);
        if(deleteRes != null) {
          var toastEl = document.getElementById('myToastUpdate');
          const bsToast = new Toast(toastEl!);
          bsToast.show();
          var userId = currentUser?.id;
          var roleId = currentUser?.designation;
          const characterResponse = await getLeadsByRole(userId, roleId)
          setLeadList(characterResponse);
        }
      }
    }
  }

  useEffect(() => {
    leadList();
}, []);

  const isMenu = location.pathname.includes('menu');

  return (
    <div className='toolbar d-flex align-items-end' id='kt_toolbar'>
      {/* begin::Container */}
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
      >
        {/* <DefaultTitle /> */}

        <div  className="menu_bar d-flex align-items-center justify-content-end w-100">
          {isMenu &&
              <>
              <div className='d-flex button_bar'>
                {/* contact actions */}
                <input disabled value={"total"+' '+'>'+' '+lead.length} className="me-3 btn btn-sm d-none d-md-block"/>
                <a className="me-4 btn btn-sm me-4" id='kt_lead_toggle'>Add+</a>
                <a className="me-4 btn btn-sm me-4 d-none" id='kt_contact_toggle'>Add+</a>
                <a className="me-4 btn btn-sm me-4 d-none" id='kt_task_toggle'>Add+</a>
                <a className="me-4 btn btn-sm me-4 d-none" id='kt_transaction_toggle'>Add+</a>
                <a className="me-4 btn btn-sm me-4 d-none" id='kt_lead_import_toggle'>Import+</a>
                <select className="form-control me-4 btn btn-sm" name="sort" id="sort" onChange={(e) => {sortByOnChangeLead(e.target.value)}}>
                    <option value="">Sort By</option>
                    <option value="created_at|asc">Created Ascending</option>
                    <option value="created_at|desc">Created Descending</option>
                    <option value="updated_at|asc">Updated Ascending</option>
                    <option value="updated_at|desc">Updated Descending</option>
                    <option value="lead_priority|asc">Priority High - Low</option>
                    <option value="lead_priority|desc">Priority Low - High</option>
                </select>
                <select className="form-control me-4 btn btn-sm" name="views" id="views" onChange={layoutOnChange}>
                  <option value="grid">Grid View</option>
                  <option value="list">List View</option>
                </select>
                <select className="form-control me-4 btn btn-sm d-none" name="type" id="type">
                  <option value="default">Type</option>
                  <option value="date">Residential</option>
                  <option value="dob">Commercial</option>
                  <option value="Plot">Plot</option>
                  <option value="developer">Developer</option>
                </select>
                <select className="form-control me-4 btn btn-sm" value={actionValue} onChange={actionChange} name="action" id="action">
                  <option value="default">Action</option>
                  { roleId == 1 && (<option value="delete">Delete</option>)}
                  <option value="archive">Archive</option>
                  <option value="export">Export</option>
                  <option value="import">Import</option>
                </select>
                               
              </div>
              <div className="d-flex">
                  <a className="me-4 btn btn-sm me-4" id='kt_filter_toggle'>
                    <KTSVG path='/media/custom/header-icons/filter.svg' className='svg-icon-5 svg-icon-gray-500 me-1'/>
                  </a>
              </div>
              </>
          }
        </div>
      </div>
      {/* end::Container */}
    </div>
  )
}



export {LeadToolbar}