import React,{FC, useState, useEffect} from 'react'
import {useAuth} from '../../../app/modules/auth'
import Moment from 'moment';
import MaterialTable from 'material-table'
import { forwardRef } from 'react';
import { getPropertiesTl } from './core/_requests';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const columns = [
    // { title: 'Id', field: 'id'},
    { title: 'Property', field: 'name_of_building' },
    { title: 'Location', field: 'locality' },
    { title: 'Property Type', field: 'property_type_name' },
    { title: 'Segment', field: 'segment_name' },
    { title: 'Contact Name', field: 'contact_name' },
    { title: 'Created Date', render: rowData => Moment(rowData.created_at).format('DD-MMMM-YYYY') },
    // { title: 'Property Unit Count', render: rowData => rowData.property_units.length },
    
];

const PropertyListView = (props) => {

    const {
        propertyListView
       } = props

      const [properties, setProperties] = useState([]);
      const {currentUser, logout} = useAuth();

       const propertyList =  async () => {
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const propertyResponse = await getPropertiesTl(userId, roleId)
        setProperties(propertyResponse);
    }

    const [pageHeight, setPageHeight] = useState('');
    const setHeight = () => {
      let heigh ;
      if(window.innerHeight > 720) {
        heigh = '650px'
      } else {
        heigh = '550px'
      }
      setPageHeight(heigh)
    } 

    useEffect(() => {
      setHeight()
    }, [window.innerHeight]);

    useEffect(() => {
      propertyList();
    }, []);

    return (
      <div style={{ maxWidth: '100%' }} >
        <div>
        {/* {contactList} */}
        </div>
        <MaterialTable className="p-3"
          icons={tableIcons}
          columns={columns}
          data={properties}
          title=""
          // actions={[
          //   {
          //     icon: 'Save',
          //     tooltip: 'Save User',
          //     onClick: (event, rowData) => {} 
          //   },
          //   rowData => ({
          //     icon: 'Delete',
          //     tooltip: 'Delete User',
          //     onClick: (event, rowData) => {},
          //   })
          // ]}
          options={{
            actionsColumnIndex: -1,
            maxBodyHeight: pageHeight,
            exportButton: true,
            columnsButton: true,
            headerStyle: {
                backgroundColor: '#ececec',
                color: '#000'
            },
            rowStyle: {
                backgroundColor: '#fff',
            }
          }}
        />
      </div>
    )
}

export {PropertyListView}

