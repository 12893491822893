import React,{FC, useState,useEffect} from 'react'
import {Link} from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import {useAuth} from '../../../app/modules/auth'
import { saveLead, getContacts, getLeadsByRole, getReqLocations, getCity, getAssignTo, getAmenity, getFurnishStatus, getPosesStatus, getLookingFor,getVasthu , getAgeOfProperty, getSegment, getPropertyType, getLeadSource, getLeadGroup, getLeadStatus, getSalesManagerList } from './core/_requests'
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { Toast } from 'bootstrap';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { getProjects } from '../task/core/_requests'
import { getPriority, getPropertyFacing } from '../settings/masters/core/_requests'
import { getAssignToList, getContactDetail, updateContactStatus } from '../contact/core/_requests'

const initialValues = {
    contact_id: '',
    looking_for: '',
    property_type: '',
    city: '',
    lead_source: '',
    lead_group: '',
    lead_priority: '',
    segment: '',
    fee_oppurtunity: '',
    property_id: '',
    project_facing: '',
    status: '',
    sales_manager: '',
    assign_to: [],
    // requirement_location: [],
    budget_min: '',
    budget_max: '',
    no_of_bedrooms_min: '',
    no_of_bedrooms_max: '',
    no_of_bathrooms_min: '',
    no_of_bathrooms_max: '',
    built_up_area_min: '',
    built_up_area_max: '',
    plot_area_min: '',
    plot_area_max: '',
    possession_status: [],
    age_of_property: '',
    vasthu_compliant:'',
    furnishing:[],
    car_park_min:'',
    car_park_max:'',
    timeline_for_closure_min:'',
    timeline_for_closure_max:'',
    amenities:[],
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, aminityName: string[], theme: Theme) {
    return {
        fontWeight:
        aminityName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
    };
}

const mystyle = {
    padding: "0px",
    height: "35px",
    maxHeight: "200px",
    overflowY: "scroll",
};

type Props = {
    setLeads?: any,
    contactName?: any,
    checkleadClick?: any
}

const LeadForm:  FC<Props> = (props) => {

const {
    setLeads, contactName, checkleadClick
    } = props

  //form submit
  const registrationSchema = Yup.object().shape({
    contact_id: Yup.string()
      .required('Contact name is required'),
    looking_for: Yup.string(),
    property_type: Yup.string(),
    city: Yup.string(),
    lead_source: Yup.string(),
    lead_group: Yup.string(),
    lead_priority: Yup.string(),
    segment: Yup.string(),
    fee_oppurtunity: Yup.string(),
    property_id: Yup.string().required('Property Id is required'),
    project_facing: Yup.string(),
    status: Yup.string(),
    sales_manager: Yup.string(),
    assign_to: Yup.array()
    .required('Assign To is required'),
    // requirement_location: Yup.string(),
    budget_min: Yup.string(),
    budget_max: Yup.string(),
    no_of_bedrooms_min: Yup.string(),
    no_of_bedrooms_max: Yup.string(),
    no_of_bathrooms_min: Yup.string(),
    no_of_bathrooms_max: Yup.string(),
    built_up_area_min: Yup.string(),
    built_up_area_max: Yup.string(),
    plot_area_min: Yup.string(),
    plot_area_max: Yup.string(),
    possession_status: Yup.array(),
    age_of_property: Yup.string(),
    vasthu_compliant: Yup.string(),
    furnishing: Yup.array(),
    car_park_min: Yup.string(),
    car_park_max: Yup.string(),
    timeline_for_closure_min: Yup.string(),
    timeline_for_closure_max: Yup.string(),
    amenities: Yup.array(),
  })
  
  const registrationSchemaContact = Yup.object().shape({
    contact_id: Yup.string(),
    looking_for: Yup.string(),
    property_type: Yup.string(),
    city: Yup.string(),
    lead_source: Yup.string(),
    lead_group: Yup.string(),
    lead_priority: Yup.string(),
    segment: Yup.string(),
    fee_oppurtunity: Yup.string(),
    property_id: Yup.string().required('Property Id is required'),
    project_facing: Yup.string(),
    status: Yup.string(),
    sales_manager: Yup.string(),
    assign_to: Yup.array(),
    requirement_location: Yup.string(),
    budget_min: Yup.string(),
    budget_max: Yup.string(),
    no_of_bedrooms_min: Yup.string(),
    no_of_bedrooms_max: Yup.string(),
    no_of_bathrooms_min: Yup.string(),
    no_of_bathrooms_max: Yup.string(),
    built_up_area_min: Yup.string(),
    built_up_area_max: Yup.string(),
    plot_area_min: Yup.string(),
    plot_area_max: Yup.string(),
    possession_status: Yup.array(),
    age_of_property: Yup.string(),
    vasthu_compliant: Yup.string(),
    furnishing: Yup.array(),
    car_park_min: Yup.string(),
    car_park_max: Yup.string(),
    timeline_for_closure_min: Yup.string(),
    timeline_for_closure_max: Yup.string(),
    amenities: Yup.array(),
  })

  const [loading, setLoading] = useState(false);
  const [aminityName, setAminityName] = React.useState<string[]>([]);
  const [assignToName, setAssignToName] = React.useState<string[]>([]);
  const [assignToId, setAssignToId] = React.useState<string[]>([]);
  const [furnishName, setFurnishName] = React.useState<string[]>([]);
  const [furnishId, setFurnishId] = React.useState<string[]>([]);
  const [posName, setPosName] = React.useState<string[]>([]);
  const [posId, setPosId] = React.useState<string[]>([]);
  const [contacts, setContactsLead] = useState<any[]>([]);
  const [cities, setCities] = useState<any[]>([]);
  const [reqLocation, setReqLocation] = useState<any[]>([]);
  const [assignTo, setAssignTo] = useState<any[]>([]);
  const [amenities, setAmenities] = useState<any[]>([]);
  const [amenitiesId, setAmenitiesId] = React.useState<string[]>([]);
  const [furnishStatus, setFurnishStatus] = useState<any[]>([]);
  const [posesStatus, setPosesStatus] = useState<any[]>([]);
  const [lookingFor, setLookingFor] = useState<any[]>([]);
  const [projects, setProjects] = useState<any[]>([]);
  const [segment, setSegment] = useState<any[]>([]);
  const [propertyType, setPropertyType] = useState<any[]>([]);
  const [leadSource, setLeadSource] = useState<any[]>([]);
  const [leadGroup, setLeadGroup] = useState<any[]>([]);
  const [leadStatus, setLeadStatus] = useState<any[]>([]);
  const [vasthu, setVasthu] = useState<any[]>([]);
  const [ageOfProp, setAgeOfProp] = useState<any[]>([]);
  const [priority, setPriority] = useState<any[]>([]);
  const [requirementLocationName, setRequirementLocationName] = useState<any[]>([]);
  const [requirementLocationId, setRequirementLocationId] = useState<any[]>([]);
  const [projectFacing, setProjectFacing] = useState<any[]>([]);
  const [salesManagerList, setSalesManagerList] = useState<any[]>([]);
  const [projectDopList, setProjectDopList] = useState<any[]>([]);
  const [locStg, setLocStg] = useState<any>('');
  const [contactType, setContactType] = useState<any>('');
  const {currentUser, logout} = useAuth();
  const [dataContact, setDataContact] = useState<{[key: string]: any}>(contactName);

   
const formik = useFormik({
    initialValues,
    validationSchema: checkleadClick && contactName != null ? registrationSchemaContact: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      console.log('lead form body');
      console.log(values);
      try {

        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
         
        var body;
        if(checkleadClick && contactName != null){
            // console.log(contactName['assign_to'].toString());
            var assignedTo = contactName['assign_to'].split(',');
            console.log(assignedTo);
            body = {
                "contact_id": contactName['id'].toString() ?? '',
                "looking_for": values.looking_for,
                "property_type": values.property_type,
                "city": values.city,
                "lead_source": values.lead_source,
                "lead_group": values.lead_group,
                "lead_priority": values.lead_priority,
                "segment": values.segment,
                "fee_oppurtunity": values.fee_oppurtunity,
                "property_id": values.property_id,
                "project_facing": values.project_facing,
                "status": values.status,
                "sales_manager": values.sales_manager,
                "created_by": userId,
                "assign_to": assignedTo,
                "requirement_location": requirementLocationId,
                "budget_min": values.budget_min,
                "budget_max": values.budget_max,
                "no_of_bedrooms_min": values.no_of_bedrooms_min,
                "no_of_bedrooms_max": values.no_of_bedrooms_max,
                "no_of_bathrooms_min": values.no_of_bathrooms_min,
                "no_of_bathrooms_max": values.no_of_bathrooms_max,
                "built_up_area_min": values.built_up_area_min,
                "built_up_area_max": values.built_up_area_max,
                "plot_area_min": values.plot_area_min,
                "plot_area_max": values.plot_area_max,
                "possession_status": posId,
                "age_of_property": values.age_of_property,
                "vasthu_compliant": values.vasthu_compliant,
                "furnishing": furnishId,
                "car_park_min": values.car_park_min,
                "car_park_max": values.car_park_max,
                "timeline_for_closure_min": values.timeline_for_closure_min,
                "timeline_for_closure_max": values.timeline_for_closure_max,
                "amenities": amenitiesId
            }
            const saveLeadData = await saveLead(body);

            console.log('saveLeadData');
            console.log(saveLeadData);
            // document.getElementById('kt_contact')?.classList.remove('drawer-on');
            if(saveLeadData != null){
                setLoading(false);
                document.getElementById('kt_lead_close')?.click();
                var toastEl = document.getElementById('myToastAdd');
                const bsToast = new Toast(toastEl!);
                bsToast.show();
                const body = {
                    "created_by": userId,
                    "status": '2'   
                }

                const updateTask = await updateContactStatus(contactName['id'], body);                
                resetForm();
                if(updateTask != null){
                    var toastEl = document.getElementById('myToastStatus');
                    const bsToast = new Toast(toastEl!);
                    bsToast.show();
                }
                const characterResponse = await getLeadsByRole(userId, roleId)
                setLeads(characterResponse)
                setAminityName([]);
                setAssignToName([]);
                setFurnishName([]);
                setPosName([]);

                
            }
        } else {
            body = {
                "contact_id": values.contact_id,
                "looking_for": values.looking_for,
                "property_type": values.property_type,
                "city": values.city,
                "lead_source": values.lead_source,
                "lead_group": values.lead_group,
                "lead_priority": values.lead_priority,
                "segment": values.segment,
                "sales_manager": values.sales_manager,
                "fee_oppurtunity": values.fee_oppurtunity,
                "property_id": values.property_id,
                "project_facing": values.project_facing,
                "status": values.status,
                "created_by": userId,
                "assign_to": assignToId.length > 0 ? assignToId: [userId],
                "requirement_location": requirementLocationId,
                "budget_min": values.budget_min,
                "budget_max": values.budget_max,
                "no_of_bedrooms_min": values.no_of_bedrooms_min,
                "no_of_bedrooms_max": values.no_of_bedrooms_max,
                "no_of_bathrooms_min": values.no_of_bathrooms_min,
                "no_of_bathrooms_max": values.no_of_bathrooms_max,
                "built_up_area_min": values.built_up_area_min,
                "built_up_area_max": values.built_up_area_max,
                "plot_area_min": values.plot_area_min,
                "plot_area_max": values.plot_area_max,
                "possession_status": posId,
                "age_of_property": values.age_of_property,
                "vasthu_compliant": values.vasthu_compliant,
                "furnishing": furnishId,
                "car_park_min": values.car_park_min,
                "car_park_max": values.car_park_max,
                "timeline_for_closure_min": values.timeline_for_closure_min,
                "timeline_for_closure_max": values.timeline_for_closure_max,
                "amenities": amenitiesId
            }
            const saveLeadData = await saveLead(body);

            console.log('saveLeadData');
            console.log(saveLeadData);
            // document.getElementById('kt_contact')?.classList.remove('drawer-on');
            if(saveLeadData != null){
                setLoading(false);
                resetForm();
                document.getElementById('kt_lead_close')?.click();
                var toastEl = document.getElementById('myToastAdd');
                const bsToast = new Toast(toastEl!);
                bsToast.show();
                const characterResponse = await getLeadsByRole(userId, roleId)
                setLeads(characterResponse)
                setAminityName([]);
                setAssignToName([]);
                setFurnishName([]);
                setPosName([]);
            }
        }

      } catch (error) {
        console.error(error)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
}})

  const theme = useTheme(); 

  const contactsList =  async () => {   
    var userId = currentUser?.id;
    var roleId = currentUser?.designation;
    const contactsResponse = await getContacts(userId, roleId)

    var contactDropList = [];

    for(let i = 0; i < contactsResponse.length; i++) {
        var data = { "id": contactsResponse[i].id, "name": contactsResponse[i].first_name + ' '+ contactsResponse[i].last_name, "contact_type": contactsResponse[i].contact_type }
        contactDropList.push(data);
    }

    console.log(contactDropList);
    setContactsLead(contactDropList);
  }

    const projectList =  async () => {
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const projectResponse = await getProjects(userId, roleId);
        setProjects(projectResponse); 
        
        var propertyDropList = [];

        for(let i = 0; i < projectResponse.length; i++) {
            var data = { "id": projectResponse[i].id, "name": projectResponse[i].name_of_building }
            propertyDropList.push(data);
        }

        setProjectDopList(propertyDropList);
    }

    const salesManagerListList =  async () => {
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const Response = await getSalesManagerList(userId, roleId);
        setSalesManagerList(Response);
    }

  const lookingForList = async () => {
    // setLookingFor
    const lookingResponse = await getLookingFor();
    setLookingFor(lookingResponse);
  }

    const segmentList = async () => {
        const segmentResponse = await getSegment();
        setSegment(segmentResponse);
    } 

    const PriorityList = async () => {
        const Response = await getPriority();
        setPriority(Response);
        console.log('ssefrgsergrtyerytyu');
        console.log(Response);
    } 

    const propertyTypeList = async () => {
        const propertyResponse = await getPropertyType();
        setPropertyType(propertyResponse);
    } 

    const leadSourceList = async () => {
        const sourceResponse = await getLeadSource();
        setLeadSource(sourceResponse);
    } 

    const leadGroupList = async () => {
        const groupResponse = await getLeadGroup();
        setLeadGroup(groupResponse);
    } 

    const leadStatusList = async () => {
        const statusResponse = await getLeadStatus();
        setLeadStatus(statusResponse);

        console.log('leads status');
        console.log(statusResponse);
    } 

  const citiesList =  async () => {   
    const cityResponse = await getCity();
    setCities(cityResponse);
  }

  const reqLocationsList =  async () => {   
    const locResponse = await getReqLocations();
    setReqLocation(locResponse);
  }

  const assignToList =  async () => {  
    var userId = currentUser?.id;
    var roleId = currentUser?.designation; 
    const assignToResponse = await getAssignToList(userId, roleId);
    setAssignTo(assignToResponse);
  }

  const amenitiesList =  async () => {   
    const amenityResponse = await getAmenity();
    if(amenityResponse != null){
        setAmenities(amenityResponse);
    }
  }

  const furnishStatusList =  async () => {   
    const furnishResponse = await getFurnishStatus();
    setFurnishStatus(furnishResponse);
  }

  const posesStatusList =  async () => {   
    const posessionResponse = await getPosesStatus();
    setPosesStatus(posessionResponse);
  }

  const vasthuList =  async () => {   
    const vasthuResponse = await getVasthu();
    setVasthu(vasthuResponse);
    }

const ageOfPropList =  async () => {   
    const response = await getAgeOfProperty();
    setAgeOfProp(response);
}

const projectFacingList =  async () => {   
    const response = await getPropertyFacing();
    setProjectFacing(response);
}

const editing = async () => {
    formik.setFieldValue('property_id', contactName['property_id'] ?? '');
    formik.setFieldValue('lead_source', contactName['source'] ?? '');
    formik.setFieldValue('lead_group', contactName['contact_group'] ?? '');
    const fetchDetails = await getContactDetail(contactName['id'])
    var assignArray = [];
    var assignNameArray = [];
    if(fetchDetails.assign_to != null){
        assignArray = fetchDetails.assign_to.split(",").map((e:any) => {
            return parseInt(e);
        });
    }
    if(fetchDetails.assign_to_name != null){
        assignNameArray = fetchDetails.assign_to_name.split(",").map((e:any) => {
            return e;
        });
    }
    setAssignToId(assignArray);
    setAssignToName(assignNameArray);    
}

useEffect(() => {
    contactName?.id && editing();
}, [contactName]);

  useEffect(() => {
    contactsList();
    assignToList();
    segmentList();
    propertyTypeList();
    leadSourceList();
    leadGroupList();
    leadStatusList();
    citiesList();
    reqLocationsList();
    vasthuList();
    ageOfPropList();
    amenitiesList();
    lookingForList();
    projectList();
    furnishStatusList();
    posesStatusList();
    projectFacingList();
    PriorityList();
    salesManagerListList();
  }, []);

  const handleOnSearch = (string:any, results:any) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results)
    }

    const handleOnHover = (result:any) => {
        // the item hovered
        console.log(result)
    }

    const handleOnSelect = (item:any) => {
        // the item selected
        console.log(item)
    }

    const handleOnFocus = () => {
        console.log('Focused')
    }

    const formatResult = (item:any) => {
        return (
            <>
            <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
            </>
        )
    }

    const formatResultProperty = (item:any) => {
        return (
            <>
            <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
            </>
        )
    }

  const openContactFrom = () => {
    console.log('weeerrr');
    document.getElementById('kt_lead_close')?.click();
    document.getElementById('kt_contact_toggle')?.click();
  }

  const handleChange = (event: SelectChangeEvent<typeof aminityName>) => {
    const {
      target: { value },
    } = event;

    var name = [];
    var id = [];

    for(let i = 0; i < value.length; i++){
      var fields = value[i].split('-');

      var n = fields[0];
      var d = fields[1];

      name.push(n);
      id.push(d);
    }

    console.log(name);
    console.log(id);

    setAmenitiesId(id);

    setAminityName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const locationHandleChange = (event: SelectChangeEvent<typeof requirementLocationName>) => {
    const {
      target: { value },
    } = event;

    var name = [];
    var id = [];

    for(let i = 0; i < value.length; i++){
      var fields = value[i].split('-');

      var n = fields[0];
      var d = fields[1];

      name.push(n);
      id.push(d);
    }

    console.log(name);
    console.log(id);

    setRequirementLocationId(id);

    setRequirementLocationName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const assingToChange = (event: SelectChangeEvent<typeof assignToName>) => {
    const {
      target: { value },
    } = event;

    var name = [];
    var id = [];

    for(let i = 0; i < value.length; i++){
      var fields = value[i].split('-');

      var n = fields[0];
      var d = fields[1];

      name.push(n);
      id.push(d);
    }

    console.log(name);
    console.log(id);

    setAssignToId(id);

    setAssignToName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const furnishingChange = (event: SelectChangeEvent<typeof furnishName>) => {
    const {
      target: { value },
    } = event;

    var name = [];
    var id = [];

    for(let i = 0; i < value.length; i++){
      var fields = value[i].split('-');

      var n = fields[0];
      var d = fields[1];

      name.push(n);
      id.push(d);
    }

    console.log(name);
    console.log(id);

    setFurnishId(id);

    setFurnishName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const possessionChange = (event: SelectChangeEvent<typeof posName>) => {
    const {
      target: { value },
    } = event;

    var name = [];
    var id = [];

    for(let i = 0; i < value.length; i++){
      var fields = value[i].split('-');

      var n = fields[0];
      var d = fields[1];

      name.push(n);
      id.push(d);
    }

    console.log(name);
    console.log(id);

    setPosId(id);

    setPosName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handlelstorage = () => {
    localStorage.removeItem("contactLeadId");
  }

  const contactDropSelect = (id:any, type:any) => {
    formik.setFieldValue('contact_id', id ?? '');
    setContactType(type);
    console.log('type');
    console.log(type);
  }

  const propertyDropSelect = (id:any, type:any) => {
    formik.setFieldValue('property_id', id ?? '');
  }

    return(
        <div className='card shadow-none rounded-0 w-100'>
        <div className='card-header w-100' id='kt_lead_header'>
          <h3 className='card-title fw-bolder text-dark'>Add Lead</h3>

          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-active-light-primary me-n5'
              id='kt_lead_close' onClick={handlelstorage}
            >
                <img src={toAbsoluteUrl('/media/custom/cancel_black.svg')} className="svg_icon" alt='' />
              {/* <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' /> */}
            </button>
          </div>
        </div>
        <div className='card-body position-relative' id='kt_lead_body'>
        
        <div className="accordion" id="leadAccordion"> 
            <form noValidate onSubmit={formik.handleSubmit} className='lead_form'>
                 <div className="accordion-item">
                    <h2 className="accordion-header" id="leadheadingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Basic Details
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="leadheadingOne" data-bs-parent="#leadAccordion">
                        <div className="accordion-body">
                            <div className="row">
                                <div className="col-md-6 col-12 mb-3">
                                    <label className="form-label required">Contact Person</label>
                                    {checkleadClick && contactName != null ?                                                                    
                                    <div className="input-group mb-3">
                                        <input type="text" value={contactName['name']} disabled name="contact_id" className='form-control form-control-lg form-control-solid' placeholder="Contact Person" aria-label="Search a contact"/>
                                    </div>
                                    : 
                                    <div className="input-group mb-3">
                                    <div className='autocomplete_field'>
                                        <ReactSearchAutocomplete
                                            items={contacts}
                                            {...formik.getFieldProps('contact_id')}
                                            onSearch={handleOnSearch}
                                            onHover={handleOnHover}
                                            onSelect={(val) => contactDropSelect(val.id, val.contact_type)}
                                            onFocus={handleOnFocus}
                                            placeholder="choose contact"
                                            styling={mystyle}
                                            autoFocus
                                            formatResult={formatResult}
                                        />                                        
                                    </div>
                                    <button className="btn btn-outline-secondary addContact_btn" type="button" onClick={openContactFrom}>
                                        <i className="fa fa-plus-circle text_primary"></i>
                                    </button>
                                </div> }
                                    {formik.touched.contact_id && formik.errors.contact_id && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert' className='text-danger'>{formik.errors.contact_id}</span>
                                        </div>
                                    </div>
                                    )}
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Looking For</label>
                                    <div className="input-group mb-3">
                                        <select 
                                            {...formik.getFieldProps('looking_for')}
                                            className={clsx(
                                            'form-select btn btn-sm w-100',
                                            {
                                                'is-invalid': formik.touched.looking_for && formik.errors.looking_for,
                                            },
                                            {
                                                'is-valid': formik.touched.looking_for && !formik.errors.looking_for,
                                            }
                                            )}
                                            name="looking_for"
                                            >
                                                <option value=''>Looking For</option>
                                            {lookingFor.map((lookingForVal,i) =>{
                                                return (
                                                    <option value={lookingForVal.id} key={i}>{lookingForVal.name}</option> 
                                            )})}
                                        </select>   
                                    </div>
                                    {formik.touched.looking_for && formik.errors.looking_for && (
                                        <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert' className='text-danger'>{formik.errors.looking_for}</span>
                                        </div>
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label className="form-label">Segment</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select 
                                        {...formik.getFieldProps('segment')}
                                        className="btn_secondary btn btn-sm w-100">
                                            <option value=''>Segment</option>
                                            {segment.map((segmentVal,i) =>{
                                                return (
                                                    <option value={segmentVal.id} selected={i == 0 ? true: false} key={i}>{segmentVal.name}</option> 
                                            )})}
                                        </select>
                                    </div>  
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label className="form-label">Property Type</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select 
                                        {...formik.getFieldProps('property_type')}
                                        className="btn_secondary btn btn-sm w-100">
                                            <option value=''>Property Type</option>
                                            {propertyType.map((propertyVal,i) =>{
                                                return (
                                                    <option value={propertyVal.id} selected={i == 0 ? true: false} key={i}>{propertyVal.name}</option> 
                                            )})}
                                        </select>
                                    </div>  
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Sales Manager</label>
                                    <div className="input-group mb-3 input_prepend py-1">
                                        <select className="btn btn-sm w-100 text-start form-select" {...formik.getFieldProps('sales_manager')}>
                                        <option value=''>Select</option>
                                            {salesManagerList.map((manager,i) =>{
                                                return (
                                                    <option value={manager.id} selected={i == 0 ? true: false} key={i}>{manager.first_name +' '+ manager.last_name}</option>  
                                            )})}
                                        </select>
                                    </div> 
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label className="form-label">Lead Priority</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select 
                                        {...formik.getFieldProps('lead_priority')}
                                        className="btn_secondary btn btn-sm w-100">
                                            <option value=''>select</option>                                            
                                            {priority.map((priorityVal,i) =>{
                                                return (
                                                    <option value={priorityVal.id} selected={i == 0 ? true: false} key={i}>{priorityVal.name}</option> 
                                            )})}                                            
                                        </select>
                                    </div>  
                                </div>                                 
                                <div className="col-md-6 col-12 mb-3">
                                    <label className="form-label">Lead Source</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select 
                                        {...formik.getFieldProps('lead_source')}
                                        className="btn_secondary btn btn-sm w-100">
                                            <option value=''>Lead Source</option>
                                            {leadSource.map((sourceVal,i) =>{
                                                return (
                                                    <option value={sourceVal.id} selected={i == 0 ? true: false} key={i}>{sourceVal.name}</option> 
                                            )})}
                                        </select>
                                    </div> 
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label className="form-label">Lead Group</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select 
                                        {...formik.getFieldProps('lead_group')}
                                        className="btn_secondary btn btn-sm w-100">
                                            <option value=''>Lead Group</option>
                                            {leadGroup.map((groupVal,i) =>{
                                                return (
                                                    <option value={groupVal.id} selected={i == 0 ? true: false} key={i}>{groupVal.name}</option> 
                                            )})}
                                        </select>
                                    </div> 
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label className="form-label">Opportunity Value</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="px-2 py-2 btn_secondary bg_secondary btn btn-sm prepend">
                                            <option selected value="0">₹</option>
                                            <option value="1">$</option>
                                        </select>
                                        <input type="text" {...formik.getFieldProps('fee_oppurtunity')} name="fee_oppurtunity" className="form-control" placeholder="0"/>
                                    </div>
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                <label className="form-label required">Property</label>
                                    {/* <label htmlFor="basic-url" className="form-label required">Project</label>
                                    <div className="input-group mb-3">
                                        <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('property_id')}>
                                        <option value=''>Select</option>
                                            {projects.map((project,i) =>{
                                                return (
                                                    <option selected={i == 0 ? true: false} value={project.id} key={i}>{project.name_of_building}</option> 
                                            )})}
                                        </select>
                                    </div>
                                    {formik.touched.property_id && formik.errors.property_id && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert' className='text-danger'>{formik.errors.property_id}</span>
                                        </div>
                                    </div>
                                    )} */}
                                    <div className="input-group mb-3">
                                        <div className='autocomplete_field'>
                                            <ReactSearchAutocomplete
                                                items={projectDopList}
                                                {...formik.getFieldProps('property_id')}
                                                onSearch={handleOnSearch}
                                                onHover={handleOnHover}
                                                onSelect={(val) => propertyDropSelect(val.id, val.name_of_building)}
                                                onFocus={handleOnFocus}
                                                placeholder="Choose Property..."
                                                styling={mystyle}
                                                autoFocus
                                                formatResult={formatResultProperty}
                                            />                                        
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label className="form-label">Status</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select 
                                        {...formik.getFieldProps('status')}
                                        className="btn_secondary btn btn-sm w-100">
                                            {leadStatus.map((statusVal,i) =>{
                                                return (
                                                    <option value={statusVal.id} selected={i == 0 ? true: false} key={i}>{statusVal.name}</option> 
                                            )})}
                                        </select>
                                    </div> 
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label className="form-label">Assign To</label>
                                    
                                    <FormControl sx={{ m: 0, width: '100%', mt: 0 }}>
                                        <Select
                                            multiple
                                            displayEmpty
                                            value={assignToName}
                                            onChange={assingToChange}
                                            input={<OutlinedInput />}
                                            renderValue={(selected) => {
                                                var name = [];
                                                var id = [];

                                                for(let i = 0; i < selected.length; i++){
                                                    var fields = selected[i].split('-');

                                                    var n = fields[0];
                                                    var d = fields[1];

                                                    name.push(n);
                                                    id.push(d);
                                                }

                                                if (selected.length === 0) {
                                                return <p>Assign To</p>;
                                                }

                                                return name.join(', ');
                                            }}
                                            className='multi_select_field'
                                            MenuProps={MenuProps}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                            <MenuItem disabled value="">
                                                <em>Assign To</em>
                                            </MenuItem>
                                            {assignTo.map((assignVal) => (
                                                <MenuItem
                                                key={assignVal.user_id}
                                                value={assignVal.employee_name+'-'+assignVal.user_id}
                                                style={getStyles(assignVal.employee_name, assignToName, theme)}
                                                >
                                                {assignVal.employee_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="leadheadingtwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsetwo" aria-expanded="false" aria-controls="collapsetwo">
                        Requirement
                        </button>
                    </h2>
                    <div id="collapsetwo" className="accordion-collapse collapse" aria-labelledby="leadheadingtwo" data-bs-parent="#leadAccordion">
                        <div className="accordion-body">
                            <div className="row">
                                <div className="col-md-6 col-6 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Bedrooms</label>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <input type="text" {...formik.getFieldProps('no_of_bedrooms_min')} name="no_of_bedrooms_min" className="form-control" placeholder="Min"/>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <input type="text" {...formik.getFieldProps('no_of_bedrooms_max')}  name="no_of_bedrooms_max" className="form-control" placeholder="Max"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Project Facing</label>
                                    <div className="input-group mb-3">
                                        <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('project_facing')}>
                                        <option value=''>Select</option>
                                            {projectFacing.map((project,i) =>{
                                                return (
                                                    <option value={project.id} key={i}>{project.name}</option> 
                                            )})}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-6 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Bathrooms</label>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <input type="number" min="0" {...formik.getFieldProps('no_of_bathrooms_min')}  name='no_of_bathrooms_min' className="form-control" placeholder="Min"/>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <input type="number" min="0" {...formik.getFieldProps('no_of_bathrooms_max')}  name='no_of_bathrooms_max' className="form-control" placeholder="Max"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-6 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Budget Range</label>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">                                                
                                                <input type="number" min="0" {...formik.getFieldProps('budget_min')} name='budget_min' className="form-control" placeholder="Min"/>
                                                <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                                    <option selected value="0">L</option>
                                                    <option value="1">Cr</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">                                            
                                                <input type="number" min="0" {...formik.getFieldProps('budget_max')} name='budget_max' className="form-control" placeholder="Max"/>
                                                <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                                    <option selected value="0">L</option>
                                                    <option value="1">Cr</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-6 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Built Area Range</label>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <input type="number" min="0" {...formik.getFieldProps('built_up_area_min')} name='built_up_area_min' className="form-control" placeholder="Min"/>
                                                <span className="input-group-text">Sqft</span>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <input type="number" min="0" {...formik.getFieldProps('built_up_area_max')} name='built_up_area_max' className="form-control" placeholder="Max"/>
                                                <span className="input-group-text">Sqft</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-6 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Plot Area Range</label>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <input type="number" min="0" {...formik.getFieldProps('plot_area_min')} name='plot_area_min' className="form-control" placeholder="Min"/>
                                                <span className="input-group-text">Sqft</span>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <input type="number" min="0" {...formik.getFieldProps('plot_area_max')} name='plot_area_max' className="form-control" placeholder="Max"/>
                                                <span className="input-group-text">Sqft</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-6 mb-3">
                                    <label htmlFor="basic-url" className="form-label">No. of Car Park</label>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <input type="number" min="0" {...formik.getFieldProps('car_park_min')} name="car_park_min" className="form-control" placeholder="Min"/>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <input type="number" min="0" {...formik.getFieldProps('car_park_max')} name="car_park_max" className="form-control" placeholder="Max"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-6 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Timeline for closure</label>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <input type="number" min="0" {...formik.getFieldProps('timeline_for_closure_min')} name="timeline_for_closure_min" className="form-control" placeholder="Min"/>
                                                <span className="input-group-text">Months</span>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <input type="number" min="0" {...formik.getFieldProps('timeline_for_closure_max')} name="timeline_for_closure_max" className="form-control" placeholder="Max"/>
                                                <span className="input-group-text">Months</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-md-6 col-12 mb-3">
                                    <label className="form-label">City</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select 
                                        {...formik.getFieldProps('city')} 
                                        className="btn_secondary btn btn-sm w-100">
                                            <option value="" >Select location</option>
                                            {cities.map((cityVal,i) =>{
                                                return (
                                                    <option value={cityVal.id} key={i}>{cityVal.name}</option> 
                                            )})}
                                        </select>
                                    </div> 
                                </div>
                                {/* <div className="col-md-6 col-12 mb-3">
                                    <label className="form-label">Requirement location</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select 
                                        {...formik.getFieldProps('requirement_location')} 
                                        className="btn_secondary btn btn-sm w-100">
                                            <option value="" >Select Requirement location</option>
                                            {reqLocation.map((reqLocationVal,i) =>{
                                                return (
                                                    <option value={reqLocationVal.id} key={i}>{reqLocationVal.name}</option> 
                                            )})}
                                        </select>
                                    </div> 
                                </div> */}
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Requirement location</label>
                                    <FormControl sx={{ m: 0, width: '100%', mt: 0 }}>
                                        <Select
                                            multiple
                                            displayEmpty
                                            value={requirementLocationName}
                                            onChange={locationHandleChange}
                                            input={<OutlinedInput />}
                                            renderValue={(selected) => {
                                                var name = [];
                                                var id = [];

                                                for(let i = 0; i < selected.length; i++){
                                                    var fields = selected[i].split('-');

                                                    var n = fields[0];
                                                    var d = fields[1];

                                                    name.push(n);
                                                    id.push(d);
                                                }

                                                if (selected.length === 0) {
                                                    return <p>Requirement location</p>;
                                                }

                                                return name.join(', ');
                                            }}
                                            MenuProps={MenuProps}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                            <MenuItem disabled value="">
                                                <em>Requirement location</em>
                                            </MenuItem>
                                            {reqLocation.map((reqLocationVal) => (
                                                <MenuItem
                                                key={reqLocationVal.id}
                                                value={reqLocationVal.name +'-'+ reqLocationVal.id}
                                                >
                                                {reqLocationVal.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label className="form-label">Age of Property</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select 
                                        {...formik.getFieldProps('age_of_property')} 
                                        className="btn_secondary btn btn-sm w-100">
                                            <option value=''>Age of Property</option>
                                            {ageOfProp.map((ageOfPropVal,i) =>{
                                                return (
                                                    <option value={ageOfPropVal.id} selected={i == 0 ? true: false} key={i}>{ageOfPropVal.name}</option> 
                                            )})}
                                        </select>
                                    </div> 
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label className="form-label">Vasthu / Feng Sui Compliant</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select 
                                        {...formik.getFieldProps('vasthu_compliant')} 
                                        className="btn_secondary btn btn-sm w-100">
                                            <option value=''>Vasthu Complient</option>
                                            {vasthu.map((vasthuVal,i) =>{
                                                return (
                                                    <option value={vasthuVal.id} selected={i == 0 ? true: false} key={i}>{vasthuVal.name}</option> 
                                            )})}
                                        </select>
                                    </div> 
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Amenities</label>
                                    <FormControl sx={{ m: 0, width: '100%', mt: 0 }}>
                                        <Select
                                            multiple
                                            displayEmpty
                                            value={aminityName}
                                            onChange={handleChange}
                                            input={<OutlinedInput />}
                                            renderValue={(selected) => {
                                                var name = [];
                                                var id = [];

                                                for(let i = 0; i < selected.length; i++){
                                                    var fields = selected[i].split('-');

                                                    var n = fields[0];
                                                    var d = fields[1];

                                                    name.push(n);
                                                    id.push(d);
                                                }

                                                if (selected.length === 0) {
                                                    return <p>Amenities</p>;
                                                }

                                                return name.join(', ');
                                            }}
                                            MenuProps={MenuProps}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                            <MenuItem disabled value="">
                                                <em>Amenities</em>
                                            </MenuItem>
                                            {amenities.map((amenity) => (
                                                <MenuItem
                                                key={amenity.id}
                                                value={amenity.name +'-'+ amenity.id}
                                                style={getStyles(amenity.name, aminityName, theme)}
                                                >
                                                {amenity.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label className="form-label">Furnishing Status</label>
                                    <FormControl sx={{ m: 0, width: '100%', mt: 0 }}>
                                        <Select
                                            multiple
                                            displayEmpty
                                            value={furnishName}
                                            onChange={furnishingChange}
                                            input={<OutlinedInput />}
                                            renderValue={(selected) => {
                                                var name = [];
                                                var id = [];

                                                for(let i = 0; i < selected.length; i++){
                                                    var fields = selected[i].split('-');

                                                    var n = fields[0];
                                                    var d = fields[1];

                                                    name.push(n);
                                                    id.push(d);
                                                }

                                                if (selected.length === 0) {
                                                return <p>Furnishing</p>;
                                                }

                                                return name.join(', ');

                                            }}
                                            className='multi_select_field'
                                            MenuProps={MenuProps}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                            <MenuItem disabled value="">
                                                <em>Furnishing</em>
                                            </MenuItem>
                                            {furnishStatus.map((furnish) => (
                                                <MenuItem
                                                key={furnish.id}
                                                value={furnish.name+ '-' +furnish.id}
                                                style={getStyles(furnish.name, furnishName, theme)}
                                                >
                                                {furnish.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                   
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label className="form-label">Posession Status</label>
                                    <FormControl sx={{ m: 0, width: '100%', mt: 0 }}>
                                        <Select
                                            multiple
                                            displayEmpty
                                            value={posName}
                                            onChange={possessionChange}
                                            input={<OutlinedInput />}
                                            renderValue={(selected) => {
                                                var name = [];
                                                var id = [];

                                                for(let i = 0; i < selected.length; i++){
                                                    var fields = selected[i].split('-');

                                                    var n = fields[0];
                                                    var d = fields[1];

                                                    name.push(n);
                                                    id.push(d);
                                                }

                                                if (selected.length === 0) {
                                                return <p>Posession</p>;
                                                }

                                                return name.join(', ');
                                            }}
                                            className='multi_select_field'
                                            MenuProps={MenuProps}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                            <MenuItem disabled value="">
                                                <em>Posession</em>
                                            </MenuItem>
                                            {posesStatus != null && posesStatus.map((posession) => (
                                            <MenuItem
                                            key={posession.id}
                                            value={posession.name+ '-' +posession.id}
                                            style={getStyles(posession.name, posName, theme)}
                                            >
                                            {posession.name}
                                            </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                   
                                </div> 
                                
                            </div>
                        </div>
                    </div>
                </div> 
                <div className='card-footer py-5 text-center' id='kt_contact_footer'>
                  <button
                  type='submit'
                  id='kt_sign_up_submit'
                  className='btn btn_primary text-primary'
                  disabled={formik.isSubmitting}
                >
                  {!loading && <span className='indicator-label'>Submit
                  <KTSVG
                  path='/media/custom/save_white.svg'
                  className='svg-icon-3 svg-icon-primary ms-2'
                />
                  </span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>                  
              </div>
            </form>
        </div>
      </div>
          
      </div>
    )
}


export {LeadForm}