import React,{FC, useState,useEffect} from 'react'
import {deleteLead, getLeadDetail, getLeads, getLeadsByRole, getLeadStatus, getSortLead, saveLeadNotes, sendMail, updateLeadStatus} from './core/_requests'
import { LeadDrawer } from './leadDrawers';
import { Offcanvas, Toast } from 'bootstrap';
import Moment from 'moment';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import {useAuth} from '../../../app/modules/auth'
import { LeadDetails } from './leadDetails';
import { LeadToolbar } from './leadToolbar';
import { LeadList } from './leadList';
import { getSortContact } from '../contact/core/_requests';
import * as Yup from 'yup'
import {useFormik} from 'formik'

const API_URL = process.env.REACT_APP_API_BASE_URL

const initialValues = {
    subject: '',
    message: '',
    reply: '',
    to: '',
}

const LeadPage: FC = () => {  

    const URL = window.location?.origin;
    
    const [lead, setLead] = useState<any[]>([]);
    const [leadStatus, setLeadStatus] = useState<any[]>([]);
    const {currentUser, logout} = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [detailData, setDetailData] = useState('');
    const [lostStatus, setLostStatus] = useState('');
    const [detailTab, setDetailTab] = useState('');
    const [detailClick, setDetailClick] = useState(false);
    const [taskClicked, setTaskClicked] = useState(false);
    const [transactionClicked, setTransactionClicked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [toggle, setToggle] = useState('grid');
    const [leadId, setLeadId] = useState('');
    const [leadCheckList, setLeadCheckList] = useState<any[]>([]);
    const [leadDetails, setLeadDetails] = useState('');
    const [currentContactName, setCurrentContactName] = useState<{[key: string]: any}>({});

    var roleId = currentUser?.designation;
    const handleHideData = (e:any) => {
        setToggle(e.target.value);
    };

    const leadMailSchema = Yup.object().shape({
        // task_type: Yup.string().required('Task type is required'),
        // priority: Yup.string().required('Priority is required'),
        // task_time: Yup.string().required('Task time is required'),
        // finish_time: Yup.string(),
        // project: Yup.string(),
        // contact: Yup.string(),
        // // agenda: Yup.string(),
        // assign_to: Yup.array(),
        // task_note: Yup.string().required('Task Description is required'),
        // task_status: Yup.string(),
    })



    const formik = useFormik({
        initialValues,
        validationSchema: leadMailSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
        //   console.log('lead form body');
        //   console.log(values);
          try {
    
            var userId = currentUser?.id;
            var roleId = currentUser?.designation;
            // console.log('currentUser', currentUser)
            const fetchDetails = await getLeadDetail(leadId)
        // console.log('lead Details');
        // console.log(fetchDetails);
        // setLeadDetail(fetchDetails);

                    const body = {
                        "lead_id": leadId,
                        "to": values.to,
                        "subject": values.subject,
                        "message": values.message  
                    }
    
                    const updateTask = await sendMail(body);
    
                    if(updateTask != null){
                        setLoading(false)
                        document.getElementById('lead_mail_close')?.click();
                        var toastEl = document.getElementById('myToastStatus');
                        const bsToast = new Toast(toastEl!);
                        bsToast.show();
                        resetForm();
                    }
                    
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
    }})

    const leadList =  async () => {   
        setIsLoading(true);
        const characterResponse = await getLeads()
        setLead(characterResponse);
        setIsLoading(false);
    }

    const leadListByRole =  async () => {   
        setIsLoading(true);
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const characterResponse = await getLeadsByRole(userId, roleId)
        setLead(characterResponse);
        // console.log('all leads');
        // console.log(characterResponse);
        setIsLoading(false);
    }

    const leadStatusList = async () => {
        const statusResponse = await getLeadStatus();
        setLeadStatus(statusResponse);
    } 

    const leadTypeChange = async (e:any, id:any) => {
        var userId = currentUser?.id;
        setLeadId(id)
        // console.log('status value')
        // console.log(e.target.value)
                  
            document.getElementById('lead_status_pop')?.click();
            setLostStatus(e.target.value);
            
    }

    const openTaskForm = (id:any, name:any, property_id:any, property_name:any, assign_to:any, assign_to_name:any) => {
        var data = {'id': id, 'name': name, 'property_id':property_id, 'property_name':property_name, 'assign_to':assign_to, 'assign_to_name':assign_to_name}
        setCurrentContactName(data);
        setTaskClicked(true);
        document.getElementById('kt_task_toggle')?.click();
      }

    const openTransactionForm = (data:any) => {
        setLeadDetails(data);
        setTransactionClicked(true);
        document.getElementById('kt_transaction_toggle')?.click();

    } 
    var div = document.getElementById('lead-tran')
       
    if(div?.style.display){
        div.style.display = "none";
    } 

    
    const openModal = (leadId:any, tabType:any) => {
        setDetailData(leadId);
        setLeadId(leadId);
        setDetailTab(tabType);
        setDetailClick(true);
        document.body.className += ' detail_opened';
        var offCanvasEl = document.getElementById('kt_expand'+leadId);
        offCanvasEl?.classList.remove('invisible');
        const bsOffcanvas = new Offcanvas(offCanvasEl!);
        bsOffcanvas.show();
    }

    const deleteModel = async (leadData:any) => {
        setIsLoading(false);
        // console.log(leadData);
        await deleteLead(leadData);
        setIsLoading(false);
        leadListByRole();
        var toastEl = document.getElementById('myToastDeleteStatus');
            const bsToast = new Toast(toastEl!);
            bsToast.show();
    } 
    
    const sortByChangeLead = async(e:any) => {
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        // console.log('lsudfhicrgniue');
        // console.log(e);
        const sortContacts = await getSortLead(userId, roleId, e);
        
        if(sortContacts != null){
            var toastEl = document.getElementById('myToastSort');
            // const bsToast = new Toast(toastEl!);
            // bsToast.show();
            setLead(sortContacts);
        }
    };

    useEffect(() => {
        // leadList();
        leadListByRole();
        leadStatusList();
    }, []);

    //table view
    const data = [];
    for (var i = 0; i < lead.length; i++) {
        // console.log(lead[i]);
        let object = {
            id: lead[i].id,
            contactId: lead[i].contact_id,
            lookingFor: lead[i].looking_for,
            propertyType: lead[i].property_type,
            city: lead[i].city,
            leadSource: lead[i].lead_source,
            leadGroup: lead[i].lead_group,
            segment: lead[i].segment,
            feeOppurtunity: lead[i].fee_oppurtunity,
            status: lead[i].status,
            assignTo: lead[i].assign_to_name,
            createdAt: Moment(lead[i].created_at).format("DD-MMMM-YYYY HH:mm"),
            updatedAt: Moment(lead[i].updated_at).format("DD-MMMM-YYYY HH:mm"),
            deletedAt: Moment(lead[i].deleted_at).format("DD-MMMM-YYYY HH:mm"),
            lookingForName: lead[i].looking_for_name,
            propertyTypeName: lead[i].property_type_name,
            leadSourceName: lead[i].lead_source_name,
            leadGroupName: lead[i].lead_group_name,
            leadStatusName: lead[i].lead_status_name,
            furnishingStatusName: lead[i].furnishing_status_name,
            amenitiesName: lead[i].amenities_name,
            posessionStatusName: lead[i].posession_status_name,
            sortable: true, 
        }
        data.push(object)
    }

    const leadOnSelect = (e:any, id:any) => {
        // console.log('sdsdsdsdsdsd');
        // console.log(e.target.checked);
        const newArr = [...leadCheckList];
        if(e.target.checked != false){
            setLeadCheckList(leadCheckList => [...leadCheckList, id]);
        } else {
            newArr.splice(newArr.findIndex(item => item === id), 1);
            setLeadCheckList(newArr);
        }
        // console.log(leadCheckList);
    }

    const notesFormSchema = Yup.object().shape({
        reply: Yup.string(),
    })

    const formikNotes = useFormik({
        initialValues,
        validationSchema: notesFormSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
        //   console.log('on presssed');
        //   console.log(leadId);
        //   setIsLoading(true)
          try {
    
            var userId = currentUser?.id;
            var roleId = currentUser?.designation;

            var notesBody = {
                "reply": values.reply,
                "lead_id": leadId,
                "user_id": userId,
                "parent_id": 0
            };
                           
            const leadNotesData = await saveLeadNotes(notesBody)
    
            // console.log('updateContactAddressData');
            // console.log(leadNotesData);
            if(leadNotesData != null){
                // setIsLoading(false);
                resetForm();
            //   var toastEl = document.getElementById('myToastUpdate');
            //   const bsToast = new Toast(toastEl!);
            //   bsToast.show();                
            }

            const body = {
                "created_by": userId,
                "status": lostStatus
            }
            const updateTask = await updateLeadStatus(leadId, body);
            if(updateTask != null){
                // var toastEl = document.getElementById('myToastStatus');
                // const bsToast = new Toast(toastEl!);
                // bsToast.show();
                leadListByRole();
            }
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setIsLoading(false)
          }
        },
    })

    const cancelStatusChange = () => {
        // alert();
        leadListByRole();
    }
    // console.log("jfgbcsygfuig")
    // console.log(window.location.pathname);
    // console.log(window.location);

    return(
        <div className="h-100 overflow-hidden">
            <LeadToolbar sortByOnChangeLead={sortByChangeLead} layoutOnChange={handleHideData} selectedLeads={leadCheckList} setLeadList={setLead}/>
            <LeadDrawer setLeadList={setLead} taskClick={taskClicked} clickContactName={currentContactName} transactionClick={transactionClicked} leadId={leadDetails}/>
            {isLoading ? 
            <div className='w-100 h-100'>
                <div className='d-flex justify-content-center flex-column align-items-center h_80vh'>
                    <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
                    <div className="spinner-border taskloader" role="status">                                    
                        <span className="sr-only">Loading...</span>
                    </div>
                </div> 
            </div> :
            <div className='h-100'>
            <div className={toggle == 'grid' ? "contact_page d-block" : 'd-none'}>
                <div className="row">
                    <div className="card-group">
                        {lead.map((leadData, i) => {
                            return(
                            <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-3" key={i}>
                                <div className="card h-100 mb-5 mx-2 bs_1 lead_card">
                                    <div className='card-body px-3 pt-3 pb-0'>
                                        <div className="d-flex align-items-center justify-content-between mb-5">
                                            <div className="d-flex align-items-center">
                                                {/* modal */}
                                                <div>
                                                    {
                                                        detailClick && detailData == leadData.id && 
                                                        <>
                                                            <div
                                                                id={'kt_expand'+leadData.id}
                                                                className='expand_area detail_page_view offcanvas offcanvas-end justify-content-end w-100 bg-transparent d-flex'
                                                                // data-kt-drawer='true'
                                                                // data-kt-drawer-name='filter'
                                                                // data-kt-drawer-activate='true'
                                                                // data-kt-drawer-overlay='true'
                                                                // data-kt-drawer-width="{default:'96%', 'md': '75%'}"
                                                                // data-kt-drawer-direction='end'
                                                                // data-kt-drawer-toggle={'#kt_expand_toggle'+leadData.id}
                                                                // data-kt-drawer-close='#kt_expand_close'
                                                            >
                                                                <LeadDetails leadId={leadData.id} setLeadList={setLead} tabInfo={detailTab} setDetailClicked={setDetailClick}/>
                                                            </div>
                                                        </>
                                                    }
                                                    {/* <div className="card bg_primary minimize_card d-none">
                                                        <div className="card-body d-flex justify-content-between">
                                                            <div>
                                                                <h5 className='text-white mb-0'>Contact Details</h5>
                                                            </div>
                                                            <a href="#" className="mx-3">
                                                                <i className="fas fa-window-minimize text-white"></i>
                                                            </a>
                                                            <a href="#" className="mx-3">
                                                                <i className="fas fa-times text-white"></i>
                                                            </a>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <form action="">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" onClick={(e) => leadOnSelect(e, leadData.id)} id={"contact"+leadData.id}/>
                                                        <label className="form-check-label id_label" htmlFor={"contact"+leadData.id}>
                                                            {leadData.id}
                                                        </label>
                                                    </div>
                                                </form>
                                                <div className="ms-3 ml-2 d-flex align-items-center">
                                                    <img src={toAbsoluteUrl('/media/custom/user.svg')} alt="" className="icon me-2" />
                                                    <p className="mb-0 contact_name pb-1">{leadData.contact_name}</p>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <a href='#' onClick={() => openModal(leadData.id, 'overview')}><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></a>
                                                
                                                <div className="btn-group">
                                                    <a className="" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false" onClick={() => setLeadId(leadData.id)}>
                                                        <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/>
                                                    </a>
                                                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                                        <li><a className="dropdown-item" href="#" onClick={() => openModal(leadData.id, 'overview')}>Edit</a></li>
                                                        { roleId == 1 ? <li><a className="dropdown-item" href="#"  data-bs-toggle='modal'
                                                            data-bs-target={'#delete_confirm_popup418'+leadData.id}>Delete</a></li>: <></>}
                                                        <li><a className="dropdown-item" href="#" onClick={() => openTaskForm(leadData.contact_id, leadData.contact_name, leadData.property_id, leadData.property_name_of_building, leadData.assign_to, leadData.assign_to_name)}>Add Task</a></li>
                                                        <li><a className="dropdown-item" href="#" onClick={() => openTransactionForm(leadData.id)}>Add Transaction</a></li>
                                                        <li><a className="dropdown-item" href="#" data-bs-toggle='modal'
                                                            data-bs-target={'#lead_send_mail_popup'}>Send Mail</a></li>
                                                        <li><a className="dropdown-item" href="#" onClick={() => {
                                                            navigator.clipboard.writeText(URL+'/menu/lead/leadReq'+'/'+leadData.id+'/'+new Date());
                                                            var toastEl = document.getElementById('copiedToast');
                                                            const bsToast = new Toast(toastEl!);
                                                            bsToast.show();
                                                            }}>Requirement Form Link</a></li>
                                                    </ul>
                                                </div>
                                                <div className='modal fade' id={'delete_confirm_popup418'+leadData.id} aria-hidden='true'>
                                                    <div className='modal-dialog modal-dialog-centered'>
                                                        <div className='modal-content'>
                                                            <div className='modal-header'>
                                                                <h3>Confirmation</h3>
                                                                <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                                                </div>
                                                            </div>
                                                            <div className='modal-body py-lg-10 px-lg-10'>
                                                                <p>Are you sure want to delete?</p>
                                                                <div className='d-flex align-items-center justify-content-end'>
                                                                    <button className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal'>
                                                                        No
                                                                    </button>
                                                                    <button className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal' onClick={(e) => deleteModel(leadData.id)}>
                                                                        Yes
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='modal fade' id={'lead_send_mail_popup'} aria-hidden='true'>
                                                    <div className='modal-dialog modal-dialog-centered'>
                                                        <div className='modal-content'>
                                                            <div className='modal-header py-2'>
                                                                <h3>Send Mail</h3>
                                                                <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                                                </div>
                                                            </div>
                                                            <div className='modal-body py-lg-5 px-lg-10'>
                                                                <form noValidate onSubmit={formik.handleSubmit} className='lead_form'>
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                        <label className="form-label">To</label>
                                                                            <div className="input-group first mb-3 input_prepend">
                                                                                <input type="text" {...formik.getFieldProps('to')} className="form-control" placeholder="Title"/>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="col-12">
                                                                        <label className="form-label">Title</label>
                                                                            <div className="input-group first mb-3 input_prepend">
                                                                                <input type="text" {...formik.getFieldProps('title')} className="form-control" placeholder="Title"/>
                                                                            </div>
                                                                        </div> */}
                                                                        <div className="col-12">
                                                                        <label className="form-label">Subject</label>
                                                                            <div className="input-group first mb-3 input_prepend">
                                                                                <input type="text" {...formik.getFieldProps('subject')} className="form-control" placeholder="Subject"/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                        <label className="form-label">Message</label>
                                                                            <div className="input-group first mb-3 input_prepend">
                                                                                <textarea rows={5} {...formik.getFieldProps('message')} className="form-control" placeholder=""/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='card-footer py-2 text-center' id='kt_contact_footer'>
                                                                        <button id='lead_mail_close' type='button' className='btn btn-secondary btn-sm' data-bs-dismiss='modal'>Cancel</button>
                                                                        <button
                                                                        type='submit'
                                                                        id='kt_sign_up_submit'
                                                                        className='btn btn-sm btn_primary text-primary ms-2'
                                                                        disabled={formik.isSubmitting}
                                                                        >
                                                                        {!loading && <span className='indicator-label'>send
                                                                        <KTSVG path="/media/icons/duotune/general/gen016.svg" className="svg-icon-white svg-icon-1hx ms-2 me-0" />
                                                                        </span>}
                                                                        {loading && (
                                                                            <span className='indicator-progress' style={{display: 'block'}}>
                                                                            Please wait...{' '}
                                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                            </span>
                                                                        )}
                                                                        </button>                  
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center mb-4">
                                            <div className="flex-shrink-0">
                                                <img onError={e => { e.currentTarget.src = toAbsoluteUrl('/media/custom/buyer.svg') }} src={leadData.contact_profile_image ? process.env.REACT_APP_API_BASE_URL+'uploads/contacts/profile_image/'+leadData.contact_id+'/'+leadData.contact_profile_image : ''} className="user_img" alt='' />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <div className="row">
                                                    <a href="mailto:{leadData.contact_email}" className="col-md-6 d-flex justify-content-between align-items-center mb-3 text-dark">
                                                        <img src={toAbsoluteUrl('/media/custom/envelope.svg')} alt="" className="icon me-2"/>
                                                        <p className="mb-0 fixed_text">{leadData.contact_email}</p>
                                                    </a>
                                                    <span className="col-md-6 d-flex align-items-center mb-3 text-dark">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/location_10.svg')} className="icon me-2" alt='' />
                                                        <p className="mb-0 fixed_text">{leadData.requirement_location_name != null ? leadData.requirement_location_name: '-'}</p>
                                                    </span>
                                                </div>
                                                <div className="row">
                                                    <a href={"tel:" + leadData.contact_mobile} className="col-md-6 d-flex align-items-center mb-2 text-dark">
                                                        <p className="mb-0 d-flex flex-wrap">
                                                            <span><img src={toAbsoluteUrl('/media/custom/phone.svg')} alt="" className="icon me-1" /></span>
                                                            {leadData.contact_mobile}
                                                        </p>
                                                    </a>
                                                    <a href={"https://api.whatsapp.com/send?phone="+ leadData.contact_mobile} target="new" className="col-md-6 d-flex align-items-center mb-2 text-dark">
                                                        <p className="mb-0 d-flex flex-wrap">
                                                            <span><img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} alt="" className="icon me-1" /></span>
                                                            {leadData.contact_mobile}
                                                        </p>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mb-3'>
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="row">
                                                    <div className="col-xl-8 col-md-8 col-sm-8 col-6 mb-4 py-1" title={leadData.property_name_of_building ?? ''}>
                                                            <div className="d-flex align-items-start single_item">
                                                                <img src={toAbsoluteUrl('/media/custom/lead/lead_1.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                                <div className="d-flex flex-column">
                                                                    <small className="text_light">Project</small>
                                                                    <p className="mb-0 fw-500">{leadData.property_name_of_building ?? ''}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1" title={leadData.property_type_name ?? ''}>
                                                            <div className="d-flex align-items-start single_item">
                                                                <img src={toAbsoluteUrl('/media/custom/lead/residential_5.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                                <div className="d-flex flex-column">
                                                                    <small className="text_light">Property Type</small>
                                                                    <p className="mb-0 fw-500">{leadData.property_type_name ?? '-'}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1" title={leadData.budget_min ?? '0' + '-' + leadData.budget_max ?? '0'}>
                                                            <div className="d-flex align-items-start single_item">
                                                                <img src={toAbsoluteUrl('/media/custom/lead/flat_6.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                                <div className="d-flex flex-column">
                                                                    <small className="text_light">Property Budget</small>
                                                                    <p className="mb-0 fw-500">{leadData.budget_min != null ? leadData.budget_min: '0'}-{leadData.budget_max != null ? leadData.budget_max: '0'}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1" title={leadData.lead_source_name ?? ''}>
                                                            <div className="d-flex align-items-start single_item">
                                                                <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                                <div className="d-flex flex-column">
                                                                    <small className="text_light">Source</small>
                                                                    <p className="mb-0 fw-500">{leadData.lead_source_name != null ? leadData.lead_source_name: '-'}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1" title={leadData.posession_name ?? '0'}>
                                                            <div className="d-flex align-items-start single_item">
                                                                <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                                <div className="d-flex flex-column">
                                                                    <small className="text_light">Possession</small>
                                                                    <p className="mb-0 fw-500">{leadData.posession_name != null ? leadData.posession_name: '-'}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1" title={leadData.built_up_area_min ?? '0' + '-' + leadData.built_up_area_max ?? '0'}>
                                                            <div className="d-flex align-items-start single_item">
                                                                <img src={toAbsoluteUrl('/media/custom/lead/bhk_4.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                                <div className="d-flex flex-column">
                                                                    <small className="text_light">Builtup Area</small>
                                                                    <p className="mb-0 fw-500">{leadData.built_up_area_min != null ? leadData.built_up_area_min : '0' } - {leadData.built_up_area_max != null ? leadData.built_up_area_max+' Sqft' : '0' }</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1" title={leadData.no_of_bedrooms_min ?? '0' + '-' + leadData.no_of_bedrooms_max ?? '0'}>
                                                            <div className="d-flex align-items-start single_item">
                                                                <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                                <div className="d-flex flex-column">
                                                                    <small className="text_light">Bedrooms</small>
                                                                    <p className="mb-0 fw-500">{leadData.no_of_bedrooms_min != null ? leadData.no_of_bedrooms_min : '0' }-{leadData.no_of_bedrooms_max != null ? leadData.no_of_bedrooms_max : '0' }</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1" title={leadData.no_of_bathrooms_min ?? '0' + '-' + leadData.no_of_bathrooms_max ?? '0'}>
                                                            <div className="d-flex align-items-start single_item">
                                                                <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                                <div className="d-flex flex-column">
                                                                    <small className="text_light">Bathrooms</small>
                                                                    <p className="mb-0 fw-500">{leadData.no_of_bathrooms_min != null ? leadData.no_of_bathrooms_min : '0' }-{leadData.no_of_bathrooms_max != null ? leadData.no_of_bathrooms_max : '0' }</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                            <div className="d-flex align-items-start single_item" title={leadData.assign_to_name ?? '-'}>
                                                                <img src={toAbsoluteUrl('/media/custom/lead/assign_9.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                                <div className="d-flex flex-column">
                                                                    <small className="text_light">Assign To</small>
                                                                    <p className="mb-0 fw-500">{leadData.assign_to_name ?? '-'}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1" title={leadData.lead_priority_name ?? '-'}>
                                                            <div className="d-flex align-items-start single_item">
                                                                <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                                <div className="d-flex flex-column">
                                                                    <small className="text_light">Lead Priority</small>
                                                                    <p className="mb-0 fw-500">{leadData.lead_priority_name ?? '-'}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1" title={Moment(leadData.created_at).format('DD-MM-YYYY')  ?? '-'}>
                                                            <div className="d-flex align-items-start single_item">
                                                                <img src={toAbsoluteUrl('/media/custom/calendar.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                                <div className="d-flex flex-column">
                                                                    <small className="text_light">Date</small>
                                                                    <p className="mb-0 fw-500">{Moment(leadData.created_at).format('DD-MM-YYYY')}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>  
                                                </div>
                                            </div>  
                                        </div>
                                        <div className="card-footer border-0 p-1">
                                            <div className="row">
                                                <div className="col-7 col-xl-8 icons_bar d-flex flex-wrap">
                                                    <a href="#" onClick={() => openModal(leadData.id, 'notes')} className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Notes">
                                                        <img src={toAbsoluteUrl('/media/custom/notes.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            0
                                                        </span>
                                                    </a>
                                                    <a href="#" onClick={() => openModal(leadData.id, 'files')} className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="File">
                                                        <img src={toAbsoluteUrl('/media/custom/file.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            0
                                                        </span>
                                                    </a>
                                                    <a href="#" onClick={() => openModal(leadData.id, 'merge')} className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Merge">
                                                        <img src={toAbsoluteUrl('/media/custom/merge.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            0
                                                        </span>
                                                    </a>
                                                    <a href="#" onClick={() => openModal(leadData.id, 'task')} className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Task">
                                                        <img src={toAbsoluteUrl('/media/custom/task.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            0
                                                        </span>
                                                    </a>
                                                    <a href="#" onClick={() => openModal(leadData.id, 'message')} className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Message">
                                                        <img src={toAbsoluteUrl('/media/custom/message.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            0
                                                        </span>
                                                    </a>
                                                    <a href="#" onClick={() => openModal(leadData.id, 'register')} className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Register">
                                                        <img src={toAbsoluteUrl('/media/custom/register.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            0
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="col-5 col-xl-4 d-flex align-items-center justify-content-end">
                                                    <select className="form-select toggle_white toggle_white btn_primary rounded-pill btn-sm cursor-pointer status_btn" aria-label="Default select example" onChange={(e) => leadTypeChange(e, leadData.id)}>
                                                        {leadStatus.map((statusVal,i) =>{
                                                            return (
                                                                <option value={statusVal.id} selected={statusVal.id == leadData.status} key={i}>{statusVal.name}</option> 
                                                        )})}
                                                    </select>
                                                </div>
                                                <a className="d-none" href="#" data-bs-toggle='modal' id='lead_status_pop'
                                                data-bs-target={'#contact_status_change'+leadData.id}></a>
                                                <div className='modal fade' id={'contact_status_change'+leadData.id} aria-hidden='true'>
                                                    <div className='modal-dialog modal-dialog-centered'>
                                                        <div className='modal-content'>
                                                            <div className='modal-header'>
                                                                <h3>Confirmation</h3>
                                                                <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                                                </div>
                                                            </div>
                                                            <div className='modal-body py-lg-10 px-lg-10'>
                                                                <form noValidate onSubmit={formikNotes.handleSubmit}>
                                                                    <textarea
                                                                        className='form-control main_bg border-0 p-2 resize-none min-h-25px br_10'
                                                                        data-kt-autosize='true'
                                                                        {...formikNotes.getFieldProps('reply')} 
                                                                        rows={7}
                                                                        placeholder='Reason'
                                                                    ></textarea>
                                                                    <div className='d-flex align-items-center justify-content-end'>
                                                                        <button className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal' type='button' onClick={(e) => cancelStatusChange()}>
                                                                            No
                                                                        </button>
                                                                        <button disabled={formikNotes.isSubmitting} className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal' type='submit'>
                                                                            Yes
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )})}
                    </div>
                </div>
            </div>
            {/* list view */}
            {toggle == 'list' &&
                <div className='h-100'>
                    <div className="card table_card p-3 overflow-auto">
                        <LeadList leadList={data}/>
                    </div>
                </div>
            }
            </div>
            }
            <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToast">
                <div className="toast-header">
                    <strong className="me-auto">Success</strong>
                    <button aria-label="Close" className="btn-close" 
                            data-bs-dismiss="toast" type="button">
                    </button>
                </div>
                <div className="toast-body">
                    Lead Updated Successfully!
                </div>
            </div>
            <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="copiedToast">
                <div className="toast-header">
                    <strong className="me-auto">Success</strong>
                    <button aria-label="Close" className="btn-close" 
                            data-bs-dismiss="toast" type="button">
                    </button>
                </div>
                <div className="toast-body">
                    Requirement Form Copied!!!
                </div>
            </div>
            <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastUpdate">
                <div className="toast-header">
                    <strong className="me-auto">Success</strong>
                    <button aria-label="Close" className="btn-close" 
                            data-bs-dismiss="toast" type="button">
                    </button>
                </div>
                <div className="toast-body">
                    Lead Updated Successfully!
                </div>
            </div>
            <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastStatus">
                <div className="toast-header">
                    <strong className="me-auto">Success</strong>
                    <button aria-label="Close" className="btn-close" 
                            data-bs-dismiss="toast" type="button">
                    </button>
                </div>
                <div className="toast-body">
                    Lead Status Updated Successfully!
                </div>
            </div>
            <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastDeleteStatus">
                <div className="toast-header">
                    <strong className="me-auto">Success</strong>
                    <button aria-label="Close" className="btn-close" 
                            data-bs-dismiss="toast" type="button">
                    </button>
                </div>
                <div className="toast-body">
                    Lead Status Deleted Successfully!
                </div>
            </div>
            <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastAdd">
                <div className="toast-header">
                        <strong className="me-auto">Success</strong>
                        <button aria-label="Close" className="btn-close" 
                                data-bs-dismiss="toast" type="button">
                        </button>
                </div>
                <div className="toast-body">
                    Lead Created Successfully!
                </div>
            </div>
            <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastUpload">
                <div className="toast-header">
                        <strong className="me-auto">Success</strong>
                        <button aria-label="Close" className="btn-close" 
                                data-bs-dismiss="toast" type="button">
                        </button>
                </div>
                <div className="toast-body">
                    Lead Imported Successfully!
                </div>
            </div>
        </div>
    )
}

export {LeadPage}
