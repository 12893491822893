import React,{FC, useEffect, useState} from 'react';
import { Bar } from 'react-chartjs-2';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { DashboardBarChart } from '../dashboard/barChart';
import { getCountCityLead, getCountLead, getCountPropertyTypeLead, getCountSourceLead, getCountStatusLead } from './core/_request';


export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
      //   text: 'Chart.js Bar Chart',
      },
    },
    
  };


const ReportsLead: FC = () => { 

    const [countCityLead, setCountCityLead] = useState<any[]>([]);
    const [countPropertyTypeLead, setCountPropertyTypeLead] = useState<any[]>([]);
    const [countLead, setCountLead] = useState<any[]>([]);
    const [countSourceLead, setCountSourceLead] = useState<any[]>([]);
        
    const CountCityLeadList =  async () => {    
        const CountCityLeadResponse = await getCountCityLead()
        setCountCityLead(CountCityLeadResponse);   
    }

    const CountPropertyTypeLeadList =  async () => {    
        const CountPropertyTypeLeadResponse = await getCountPropertyTypeLead()
        setCountPropertyTypeLead(CountPropertyTypeLeadResponse);   
    }

    const CountLeadList =  async () => {    
        const CountLeadResponse = await getCountLead()
        setCountLead(CountLeadResponse);   
    }

    const CountSourceLeadList =  async () => {    
        const CountSourceLeadResponse = await getCountSourceLead()
        setCountSourceLead(CountSourceLeadResponse);   
    }
    
    const countCityLabels = countCityLead.map((obj) => Object.values(obj)[1]);
    const countCitybar = countCityLead.map((obj) => Object.values(obj)[0]);
    
    const databarcountCity = {
    labels: countCityLabels,
    datasets: [{
        label: 'Citywise Lead',
        backgroundColor: '#3bb6f1',
        borderColor: 'rgb(255, 99, 132)',
        data: countCitybar,
    }]
    };

    const CountPropertyTypeLabels = countPropertyTypeLead.map((obj) => Object.values(obj)[1]);
    const CountPropertyTypebar = countPropertyTypeLead.map((obj) => Object.values(obj)[0]);
    
    const databarCountPropertyType = {
    labels: CountPropertyTypeLabels,
    datasets: [{
        label: 'Count Property Type',
        backgroundColor: '#3bb6f1',
        borderColor: 'rgb(255, 99, 132)',
        data: CountPropertyTypebar,
    }]
    };

    const countLeadLabels = countLead.map((obj) => Object.values(obj)[0]);
    const countLeadbar = countLead.map((obj) => Object.values(obj)[1]);

    console.log('countLeadLabels')
    console.log(countLeadLabels)
    console.log('countLeadbar')
    console.log(countLeadbar)
    
    const databarcountLead = {
    labels: countLeadLabels,
    datasets: [{
        label: 'Count Property Type',
        backgroundColor: '#3bb6f1',
        borderColor: 'rgb(255, 99, 132)',
        data: countLeadbar,
    }]
    };

    const countSourceLeadLabels = countSourceLead.map((obj) => Object.values(obj)[1]);
    const countSourceLeadbar = countSourceLead.map((obj) => Object.values(obj)[0]);
    
    const databarcountSourceLead = {
    labels: countSourceLeadLabels,
    datasets: [{
        label: 'Count Property Type',
        backgroundColor: '#3bb6f1',
        borderColor: 'rgb(255, 99, 132)',
        data: countSourceLeadbar,
    }]
    };

    useEffect(() => {
        CountCityLeadList();
        CountPropertyTypeLeadList();
        CountLeadList();
        CountSourceLeadList();
    }, []);

    return(
        <section className='report_lead'>
            <div className="row">
                <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 pb-5">
                    <div className="card h-100 bg-light type_card">
                        {/* <div className="card-header">
                            <div className="input-group form_search me-3">
                                <input type="text" className="form-control" placeholder="Search"/>
                                <div className="input-group-append">
                                    <button className="btn btn-secondary" type="button">
                                    <img src={toAbsoluteUrl('/media/custom/header-icons/search.svg')} className="svg_icon" alt='' />
                                    </button>
                                </div>
                            </div>
                        </div> */}
                        <div className="card-body px-4">
                            <ul className="nav nav-pills masters_tab d-block px-3 border-0" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className='text-dark nav-link active' id="leadReport-tab" data-bs-toggle="pill" data-bs-target="#leadReport" type="button" role="tab" aria-controls="leadReport" aria-selected="true">
                                    {/* <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' /> */}
                                    Lead Report
                                    </a>                                     
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className='text-dark nav-link' id="leadstagereport-tab" data-bs-toggle="pill" data-bs-target="#leadstagereport" type="button" role="tab" aria-controls="leadstagereport" aria-selected="true">
                                    {/* <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' /> */}
                                    Lead Stage Report
                                    </a>                                     
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className='text-dark nav-link' id="leadsourcereport-tab" data-bs-toggle="pill" data-bs-target="#leadsourcereport" type="button" role="tab" aria-controls="leadsourcereport" aria-selected="true">
                                    {/* <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' /> */}
                                    Lead Source Report
                                    </a>                                     
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className='text-dark nav-link' id="leadgroupchart-tab" data-bs-toggle="pill" data-bs-target="#leadgroupchart" type="button" role="tab" aria-controls="leadgroupchart" aria-selected="true">
                                    {/* <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' /> */}
                                    Lead Group Chart
                                    </a>                                     
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className='text-dark nav-link' id="leadbypropertytype-tab" data-bs-toggle="pill" data-bs-target="#leadbypropertytype" type="button" role="tab" aria-controls="leadbypropertytype" aria-selected="true">
                                    {/* <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' /> */}
                                    Lead By Property Type
                                    </a>                                     
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className='text-dark nav-link' id="leadbudgetwise-tab" data-bs-toggle="pill" data-bs-target="#leadbudgetwise" type="button" role="tab" aria-controls="leadbudgetwise" aria-selected="true">
                                    {/* <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' /> */}
                                    Lead Budget Wise
                                    </a>                                     
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className='text-dark nav-link' id="leadcampaign-tab" data-bs-toggle="pill" data-bs-target="#leadcampaign" type="button" role="tab" aria-controls="leadcampaign" aria-selected="true">
                                    {/* <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' /> */}
                                    Lead Campaign Wise
                                    </a>                                     
                                </li>                                
                                <li className="nav-item" role="presentation">
                                    <a className='text-dark nav-link' id="lostlead-tab" data-bs-toggle="pill" data-bs-target="#lostlead" type="button" role="tab" aria-controls="lostlead" aria-selected="true">
                                    {/* <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' /> */}
                                    Lost Lead
                                    </a>                                     
                                </li>                                
                                <li className="nav-item" role="presentation">
                                    <a className='text-dark nav-link' id="leadreassignment-tab" data-bs-toggle="pill" data-bs-target="#leadreassignment" type="button" role="tab" aria-controls="leadreassignment" aria-selected="true">
                                    {/* <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' /> */}
                                    Lead Reassignment Report
                                    </a>                                     
                                </li>                                
                                <li className="nav-item" role="presentation">
                                    <a className='text-dark nav-link' id="leadtask-tab" data-bs-toggle="pill" data-bs-target="#leadtask" type="button" role="tab" aria-controls="leadtask" aria-selected="true">
                                    {/* <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' /> */}
                                    Lead Task Report
                                    </a>                                     
                                </li>                                
                                <li className="nav-item" role="presentation">
                                    <a className='text-dark nav-link' id="leadcost-tab" data-bs-toggle="pill" data-bs-target="#leadcost" type="button" role="tab" aria-controls="leadcost" aria-selected="true">
                                    {/* <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' /> */}
                                    Lead Cost Report
                                    </a>                                     
                                </li>                                
                                <li className="nav-item" role="presentation">
                                    <a className='text-dark nav-link' id="citywiseleads-tab" data-bs-toggle="pill" data-bs-target="#citywiseleads" type="button" role="tab" aria-controls="citywiseleads" aria-selected="true">
                                    {/* <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' /> */}
                                    Citywise Leads Report
                                    </a>                                     
                                </li>                                
                                <li className="nav-item" role="presentation">
                                    <a className='text-dark nav-link' id="leadregistration-tab" data-bs-toggle="pill" data-bs-target="#leadregistration" type="button" role="tab" aria-controls="leadregistration" aria-selected="true">
                                    {/* <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' /> */}
                                    Lead Registration Report
                                    </a>                                     
                                </li>                                
                            </ul>                            
                        </div>
                    </div>
                </div>
                <div className="col-xxl-9 col-xl-8 col-lg-8 col-md-12 col-sm-12">                    
                    <div className="card-group">
                    <div className="tab-content w-100" id="pills-tabContent">
                        <div  className="tab-pane fade show active" id="leadReport" role="tabpanel" aria-labelledby="leadReport-tab">
                            <div className="row">
                                <div className="col-xxl-10 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">                                
                                    <div className="card h-100 bs_1 mx-3">
                                        <div className="card-header">
                                            <h4>Lead Report</h4>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <div>
                                                    <label htmlFor="from">From</label>
                                                    <input type="month" id='from' className="form-select rpt_btn me-2 mb-1"/>
                                                </div>
                                                <div>
                                                    <label htmlFor="to">To</label>
                                                    <input type="month" id='to' className="form-select rpt_btn me-2 mb-1"/>
                                                </div>
                                                <div>
                                                    <label htmlFor="to">Team</label>
                                                    <select className="form-select rpt_btn me-2 mb-1">
                                                        <option selected>Teams</option>
                                                        <option value="1">Brigade Sales Team</option>
                                                        <option value="1">Godrej Sales Team</option>
                                                        <option value="1">Shriram Sales Team</option>
                                                    </select>
                                                </div>                                              
                                            </div>
                                        </div>
                                        <div className="card-body p-3">
                                        <Bar options={options} data={databarcountLead} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div  className="tab-pane fade" id="leadstagereport" role="tabpanel" aria-labelledby="leadstagereport-tab">
                            <div className="row">                          
                                <div className="col-xxl-10 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
                                    <div className="card h-100 bs_1 mx-3">
                                        <div className="card-header">
                                            <h4>Lead Stage Report</h4>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <div>
                                                    <label htmlFor="from">From</label>
                                                    <input type="month" id='from' className="form-select rpt_btn me-2 mb-1"/>
                                                </div>
                                                <div>
                                                    <label htmlFor="to">To</label>
                                                    <input type="month" id='to' className="form-select rpt_btn me-2 mb-1"/>
                                                </div>
                                                <div>
                                                    <label htmlFor="to">Team</label>
                                                    <select className="form-select rpt_btn me-2 mb-1">
                                                        <option selected>Teams</option>
                                                        <option value="1">Brigade Sales Team</option>
                                                        <option value="1">Godrej Sales Team</option>
                                                        <option value="1">Shriram Sales Team</option>
                                                    </select>
                                                </div>                                              
                                            </div>
                                        </div>
                                        <div className="card-body p-3">
                                        <DashboardBarChart/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                        <div  className="tab-pane fade" id="leadsourcereport" role="tabpanel" aria-labelledby="leadsourcereport-tab">
                            <div className="row">                          
                                <div className="col-xxl-10 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
                                    <div className="card h-100 bs_1 mx-3">
                                        <div className="card-header">
                                            <h4>Lead Source Report</h4>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <div>
                                                    <label htmlFor="from">From</label>
                                                    <input type="month" id='from' className="form-select rpt_btn me-2 mb-1"/>
                                                </div>
                                                <div>
                                                    <label htmlFor="to">To</label>
                                                    <input type="month" id='to' className="form-select rpt_btn me-2 mb-1"/>
                                                </div>
                                                <div>
                                                    <label htmlFor="to">Team</label>
                                                    <select className="form-select rpt_btn me-2 mb-1">
                                                        <option selected>Teams</option>
                                                        <option value="1">Brigade Sales Team</option>
                                                        <option value="1">Godrej Sales Team</option>
                                                        <option value="1">Shriram Sales Team</option>
                                                    </select>
                                                </div>                                              
                                            </div>
                                        </div>
                                        <div className="card-body p-3">
                                        <Bar options={options} data={databarcountSourceLead} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                        <div  className="tab-pane fade" id="leadgroupchart" role="tabpanel" aria-labelledby="leadgroupchart-tab">
                            <div className="row">                          
                                <div className="col-xxl-10 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
                                    <div className="card h-100 bs_1 mx-3 blur_show">
                                        <div className="card-header">
                                            <h4>Lead Group Chart</h4>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <div>
                                                    <label htmlFor="from">From</label>
                                                    <input type="month" id='from' className="form-select rpt_btn me-2 mb-1"/>
                                                </div>
                                                <div>
                                                    <label htmlFor="to">To</label>
                                                    <input type="month" id='to' className="form-select rpt_btn me-2 mb-1"/>
                                                </div>
                                                <div>
                                                    <label htmlFor="to">Team</label>
                                                    <select className="form-select rpt_btn me-2 mb-1">
                                                        <option selected>Teams</option>
                                                        <option value="1">Brigade Sales Team</option>
                                                        <option value="1">Godrej Sales Team</option>
                                                        <option value="1">Shriram Sales Team</option>
                                                    </select>
                                                </div>                                              
                                            </div>
                                        </div>
                                        <div className="card-body p-3">
                                        <DashboardBarChart/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                        <div  className="tab-pane fade" id="leadbypropertytype" role="tabpanel" aria-labelledby="leadbypropertytype-tab">
                            <div className="row">                          
                                <div className="col-xxl-10 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
                                    <div className="card h-100 bs_1 mx-3">
                                        <div className="card-header">
                                            <h4>Builder Wise Revenue Report</h4>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <div>
                                                    <label htmlFor="from">From</label>
                                                    <input type="month" id='from' className="form-select rpt_btn me-2 mb-1"/>
                                                </div>
                                                <div>
                                                    <label htmlFor="to">To</label>
                                                    <input type="month" id='to' className="form-select rpt_btn me-2 mb-1"/>
                                                </div>
                                                <div>
                                                    <label htmlFor="to">Team</label>
                                                    <select className="form-select rpt_btn me-2 mb-1">
                                                        <option selected>Teams</option>
                                                        <option value="1">Brigade Sales Team</option>
                                                        <option value="1">Godrej Sales Team</option>
                                                        <option value="1">Shriram Sales Team</option>
                                                    </select>
                                                </div>                                              
                                            </div>
                                        </div>
                                        <div className="card-body p-3">
                                        <Bar options={options} data={databarCountPropertyType} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                        <div  className="tab-pane fade" id="leadbudgetwise" role="tabpanel" aria-labelledby="leadbudgetwise-tab">
                            <div className="row">                          
                                <div className="col-xxl-10 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
                                    <div className="card h-100 bs_1 mx-3">
                                        <div className="card-header">
                                            <h4>Lead Budget Wise</h4>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <div>
                                                    <label htmlFor="from">From</label>
                                                    <input type="month" id='from' className="form-select rpt_btn me-2 mb-1"/>
                                                </div>
                                                <div>
                                                    <label htmlFor="to">To</label>
                                                    <input type="month" id='to' className="form-select rpt_btn me-2 mb-1"/>
                                                </div>
                                                <div>
                                                    <label htmlFor="to">Team</label>
                                                    <select className="form-select rpt_btn me-2 mb-1">
                                                        <option selected>Teams</option>
                                                        <option value="1">Brigade Sales Team</option>
                                                        <option value="1">Godrej Sales Team</option>
                                                        <option value="1">Shriram Sales Team</option>
                                                    </select>
                                                </div>                                              
                                            </div>
                                        </div>
                                        <div className="card-body p-3">
                                        <DashboardBarChart/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                        <div  className="tab-pane fade" id="leadcampaign" role="tabpanel" aria-labelledby="leadcampaign-tab">
                            <div className="row">                          
                                <div className="col-xxl-10 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
                                    <div className="card h-100 bs_1 mx-3">
                                        <div className="card-header">
                                            <h4>Lead Campaign Wise</h4>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <input type="date" />
                                                <select className="form-select rpt_btn me-2 mb-1">
                                                    <option selected>Teams</option>
                                                    <option value="1">Brigade Sales Team</option>
                                                    <option value="1">Godrej Sales Team</option>
                                                    <option value="1">Shriram Sales Team</option>
                                                </select>                                                
                                            </div>
                                        </div>
                                        <div className="card-body p-3">
                                        <DashboardBarChart/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                        <div  className="tab-pane fade" id="lostlead" role="tabpanel" aria-labelledby="lostlead-tab">
                            <div className="row">                          
                                <div className="col-xxl-10 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
                                    <div className="card h-100 bs_1 mx-3">
                                        <div className="card-header">
                                            <h4>Lost Lead</h4>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <div>
                                                    <label htmlFor="from">From</label>
                                                    <input type="month" id='from' className="form-select rpt_btn me-2 mb-1"/>
                                                </div>
                                                <div>
                                                    <label htmlFor="to">To</label>
                                                    <input type="month" id='to' className="form-select rpt_btn me-2 mb-1"/>
                                                </div>
                                                <div>
                                                    <label htmlFor="to">Team</label>
                                                    <select className="form-select rpt_btn me-2 mb-1">
                                                        <option selected>Teams</option>
                                                        <option value="1">Brigade Sales Team</option>
                                                        <option value="1">Godrej Sales Team</option>
                                                        <option value="1">Shriram Sales Team</option>
                                                    </select>
                                                </div>                                              
                                            </div>
                                        </div>
                                        <div className="card-body p-3">
                                        <DashboardBarChart/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                        <div  className="tab-pane fade" id="leadreassignment" role="tabpanel" aria-labelledby="leadreassignment-tab">
                            <div className="row">                          
                                <div className="col-xxl-10 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
                                    <div className="card h-100 bs_1 mx-3 blur_show">
                                        <div className="card-header">
                                            <h4>Lead Reassignment Report</h4>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <div>
                                                    <label htmlFor="from">From</label>
                                                    <input type="month" id='from' className="form-select rpt_btn me-2 mb-1"/>
                                                </div>
                                                <div>
                                                    <label htmlFor="to">To</label>
                                                    <input type="month" id='to' className="form-select rpt_btn me-2 mb-1"/>
                                                </div>
                                                <div>
                                                    <label htmlFor="to">Team</label>
                                                    <select className="form-select rpt_btn me-2 mb-1">
                                                        <option selected>Teams</option>
                                                        <option value="1">Brigade Sales Team</option>
                                                        <option value="1">Godrej Sales Team</option>
                                                        <option value="1">Shriram Sales Team</option>
                                                    </select>
                                                </div>                                              
                                            </div>
                                        </div>
                                        <div className="card-body p-3">
                                        <DashboardBarChart/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                        <div  className="tab-pane fade" id="leadtask" role="tabpanel" aria-labelledby="leadtask-tab">
                            <div className="row">                          
                                <div className="col-xxl-10 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
                                    <div className="card h-100 bs_1 mx-3">
                                        <div className="card-header">
                                            <h4>Lead Task Report</h4>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <div>
                                                    <label htmlFor="from">From</label>
                                                    <input type="month" id='from' className="form-select rpt_btn me-2 mb-1"/>
                                                </div>
                                                <div>
                                                    <label htmlFor="to">To</label>
                                                    <input type="month" id='to' className="form-select rpt_btn me-2 mb-1"/>
                                                </div>
                                                <div>
                                                    <label htmlFor="to">Team</label>
                                                    <select className="form-select rpt_btn me-2 mb-1">
                                                        <option selected>Teams</option>
                                                        <option value="1">Brigade Sales Team</option>
                                                        <option value="1">Godrej Sales Team</option>
                                                        <option value="1">Shriram Sales Team</option>
                                                    </select>
                                                </div>                                              
                                            </div>
                                        </div>
                                        <div className="card-body p-3">
                                        <DashboardBarChart/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                        <div  className="tab-pane fade" id="leadcost" role="tabpanel" aria-labelledby="leadcost-tab">
                            <div className="row">                          
                                <div className="col-xxl-10 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
                                    <div className="card h-100 bs_1 mx-3 blur_show">
                                        <div className="card-header">
                                            <h4>Lead Cost Report</h4>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <div>
                                                    <label htmlFor="from">From</label>
                                                    <input type="month" id='from' className="form-select rpt_btn me-2 mb-1"/>
                                                </div>
                                                <div>
                                                    <label htmlFor="to">To</label>
                                                    <input type="month" id='to' className="form-select rpt_btn me-2 mb-1"/>
                                                </div>
                                                <div>
                                                    <label htmlFor="to">Team</label>
                                                    <select className="form-select rpt_btn me-2 mb-1">
                                                        <option selected>Teams</option>
                                                        <option value="1">Brigade Sales Team</option>
                                                        <option value="1">Godrej Sales Team</option>
                                                        <option value="1">Shriram Sales Team</option>
                                                    </select>
                                                </div>                                              
                                            </div>
                                        </div>
                                        <div className="card-body p-3">
                                        <DashboardBarChart/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                        <div  className="tab-pane fade" id="citywiseleads" role="tabpanel" aria-labelledby="citywiseleads-tab">
                            <div className="row">                          
                                <div className="col-xxl-10 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
                                    <div className="card h-100 bs_1 mx-3">
                                        <div className="card-header">
                                            <h4>Citywise Leads Report</h4>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <div>
                                                    <label htmlFor="from">From</label>
                                                    <input type="month" id='from' className="form-select rpt_btn me-2 mb-1"/>
                                                </div>
                                                <div>
                                                    <label htmlFor="to">To</label>
                                                    <input type="month" id='to' className="form-select rpt_btn me-2 mb-1"/>
                                                </div>
                                                <div>
                                                    <label htmlFor="to">Team</label>
                                                    <select className="form-select rpt_btn me-2 mb-1">
                                                        <option selected>Teams</option>
                                                        <option value="1">Brigade Sales Team</option>
                                                        <option value="1">Godrej Sales Team</option>
                                                        <option value="1">Shriram Sales Team</option>
                                                    </select>
                                                </div>                                              
                                            </div>
                                        </div>
                                        <div className="card-body p-3">
                                        <Bar options={options} data={databarcountCity} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                        <div  className="tab-pane fade" id="leadregistration" role="tabpanel" aria-labelledby="leadregistration-tab">
                            <div className="row">                          
                                <div className="col-xxl-10 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
                                    <div className="card h-100 bs_1 mx-3">
                                        <div className="card-header">
                                            <h4>Lead Registration Report</h4>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <div>
                                                    <label htmlFor="from">From</label>
                                                    <input type="month" id='from' className="form-select rpt_btn me-2 mb-1"/>
                                                </div>
                                                <div>
                                                    <label htmlFor="to">To</label>
                                                    <input type="month" id='to' className="form-select rpt_btn me-2 mb-1"/>
                                                </div>
                                                <div>
                                                    <label htmlFor="to">Team</label>
                                                    <select className="form-select rpt_btn me-2 mb-1">
                                                        <option selected>Teams</option>
                                                        <option value="1">Brigade Sales Team</option>
                                                        <option value="1">Godrej Sales Team</option>
                                                        <option value="1">Shriram Sales Team</option>
                                                    </select>
                                                </div>                                              
                                            </div>
                                        </div>
                                        <div className="card-body p-3">
                                        <DashboardBarChart/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                    </div>                        
                    </div>
                </div>
            </div>
        </section>
    )
}
export{ReportsLead}