import React,{FC} from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'


const Support: FC = () => {
    return(
        <>
        {/* <div className="d-flex flex-column flex-center">
            <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
            <h1>Under Construction</h1>
        </div> */}
        {/* <h1 className='sptfs d-flex justify-content-center'>Support</h1> */}
        <div className='d-flex justify-content-center'>
            <p className='pt-5 fs-1 fw-bolder'>Have Some Questions<span className='sptpg ps-2'>?  </span><span className='ps-3'>We're All </span>  <span className='sptpg ps-2'>E</span>ar<span className='sptpg'>z</span>!!</p>            
        </div>
        <div className='d-flex justify-content-center'>
        <p>Reach out to Mahipal Rajpurohit on <a href='tel:+91 77690 79396'>+91 77690 79396</a> / <a href='mailto:mahipalraj@listez.io'>mahipalraj@listez.io</a> for any queries or feedbacks!</p>
        </div>
        <div className='container'>
            <div className='row pt-4'>
            <div className='col-md-6'>
            <img className='w-100' src={toAbsoluteUrl('/media/avatars/wpQR.jpeg')}/>
            </div>
            <div className='col-md-6'>
            <img className='w-100' src={toAbsoluteUrl('/media/avatars/mahipal.jpeg')}/>
            </div>
            </div>
        </div>
            </>
    )
}

export {Support}