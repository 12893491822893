import React,{FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import Moment from 'moment';
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import { Offcanvas, Toast } from 'bootstrap';
import { updateUser, getUsers,  getUser, getDesignation, getDept, getBranch, updateOtherDetails, updateProofDetails, updatePackageDetails, updateProfileImage, getTeamLeader, updateUserGoal } from './core/_requests';
import { useAuth } from '../../../modules/auth';
import { getCity, getGender, getMaritalStatus } from '../masters/core/_requests';
import { SettingsPhone } from '@material-ui/icons';



const initialValues = {
    first_name: "",
    last_name: "",
    employee_id: "",
    designation: "",
    department: "",
    gender: "",
    branch: "",
    blood_group: "",
    fathers_name: "",
    spouse_name: "",
    spouse_dob: "",
    medical_condition: "",
    p_phone_number: "",
    sec_mobile: "",
    email: "",
    pass: "",
    password: "",
    o_phone_number: "",
    o_email: "",
    emergency_contact_no: "",
    emergency_contact_person: "",
    relation_person: "",
    no_of_kids: "",
    kid_name_1: "",
    kid_name_2: "",
    kid_name_3: "",
    aadhar_number: "",
    pan_number: "",
    dob: "",
    marital_status: "",
    anniversary_date: "",
    date_of_joining: "",
    past_employment_history: "",
    years_of_experience: "",
    last_company: "",
    city: "",
    portfolio_head: "",
    target_for_fy_rs: "",
    target_for_fy_units: "",
    team_leader: "",
    monthly_ctc: "",
    monthly_take_home: "",
    incentive: "",
    bank_record_name: "",
    bank_name: "",
    branch_name: "",
    acc_number: "",
    ifsc_code: "",
    permenent_address: "",
    correspondence_address: "",
    created_by: "",
    profile_image: "",
    crm_login_password: "",
    crm_login_id: "",
    base_salary: "",
    pf: "",
    hra: "",
    total_ctc: "", 
    official_mobile_number: "", 
    duration: "",
    local_address: "",
    official_mail: "",
    mediclaim: "",
    conveyance: "",
    misc: "",
    annual_target: "",
    status_changed: "",
    no_of_units_committed: "",
    no_of_sales: "",
    goal_calls: "",
    goal_talktime: "",
    goal_leads_generated: "",
    goal_leads_converted: "",
    goal_site_visit: "",
    goal_meetings: "",
    goal_bookings: "",
    goal_revenue: "",
}

type Props = {
    currentUserId?: any,
    setUser?: any,
    setDetailClicked?: any,
}

const EditUser:  FC<Props> = (props) => {
    const {
        currentUserId, 
        setUser,
        setDetailClicked
      } = props      

    //   const [isLoading, setIsLoading] = useState(false)
      const [replyPress, setReplyPress] = useState(false)

    const registrationSchema = Yup.object().shape({        
        blood_group: Yup.string(),
        fathers_name: Yup.string(),
        spouse_name: Yup.string(),
        spouse_dob: Yup.string(),
        medical_condition: Yup.string(),        
        emergency_contact_person: Yup.string(),
        relation_person: Yup.string(),
        no_of_kids: Yup.string(),
        kid_name_1: Yup.string(),
        kid_name_2: Yup.string(),
        kid_name_3: Yup.string(),        
        years_of_experience: Yup.string(),
        last_company: Yup.string(),
        city: Yup.string(),
        portfolio_head: Yup.string(),
        target_for_fy_rs: Yup.string(),
        target_for_fy_units: Yup.string(),
        team_leader: Yup.string(),
        monthly_ctc: Yup.string(),
        monthly_take_home: Yup.string(),
        incentive: Yup.string(),
        bank_record_name: Yup.string(),
        bank_name: Yup.string(),
        branch_name: Yup.string(),
        acc_number: Yup.string(),
        ifsc_code: Yup.string(),
        permenent_address: Yup.string(),
        correspondence_address: Yup.string(),
        created_by: Yup.string(),
        profile_image: Yup.string(),
        crm_login_password: Yup.string(),
        crm_login_id: Yup.string(),
        base_salary: Yup.string(),
        pf: Yup.string(),
        hra: Yup.string(), 
        official_mobile_number: Yup.string(), 
        duration: Yup.string(),
        local_address: Yup.string(),
        official_mail: Yup.string(),
        mediclaim: Yup.string(),
        conveyance: Yup.string(),
        misc: Yup.string(),
        annual_target: Yup.string(),
        status_changed: Yup.string(),
        no_of_units_committed: Yup.string(),
        no_of_sales: Yup.string(),
        goal_calls: Yup.string(),
        goal_talktime: Yup.string(),
        goal_leads_generated: Yup.string(),
        goal_leads_converted: Yup.string(),
        goal_site_visit: Yup.string(),
        goal_meetings: Yup.string(),
        goal_bookings: Yup.string(),
        goal_revenue: Yup.string(),
    })

    const registrationBasicSchema = Yup.object().shape({
        first_name: Yup.string().required('First Name is required'),
        last_name: Yup.string(),
        employee_id: Yup.string().required('Employee Id is required'),
        designation: Yup.string().required('Designation is required'),
        department: Yup.string(),
        branch: Yup.string(),
        p_phone_number: Yup.string().required('Phone is required'),
        email: Yup.string().required('Email is required'),
        o_phone_number: Yup.string(),
        o_email: Yup.string(),
        emergency_contact_no: Yup.string(),
        aadhar_number: Yup.string(),
        pan_number: Yup.string(),
        dob: Yup.string(),
        marital_status: Yup.string(),
        anniversary_date: Yup.string(),
        date_of_joining: Yup.string(),
        past_employment_history: Yup.string(),
        total_ctc: Yup.string(),
    })

    const [userInfo, setUserInfo] = useState<{[key: string]: any}>({});
    const [Dob, setDob] = React.useState<Date | null>(new Date());  
    const [AnniversaryDate, setAnniversaryDate] = React.useState<Date | null>(new Date());  
    const [DateofJoining, setDateofJoining] = React.useState<Date | null>(new Date());  
    const {currentUser, logout} = useAuth();
    const [imageFile, setImageFile] = useState(null);
    const [city, setCity] = useState<any[]>([]);
    const [editClicked, setEditClicked] = useState(false);
    const [editId, setEditId] = useState('');
    const [design, setDesign] = useState<any[]>([]);
    const [dept, setDept] = useState<any[]>([]);
    const [branch, setBranch] = useState<any[]>([]);
    const [gender, setGender] = useState<any[]>([]);
    const [maritalStatus, setMaritalStatus] = useState<any[]>([]);
    const [isExpand, setIsExpand] = useState(true);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [changeClicked, setChangeClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tabInfo, info] = useState('basicDetails');
    const [phone, SetPhone] = useState('');
    const [designation, setDesignation] = useState<any>('')
    const [selectedDesi, setSelectedDesi] = useState<any>('')
    const [teamLeader, setTeamLeader] = useState<any[]>([]);

    const FetchContactDetails =  async (id : number) => {
        // contactId : number
        setIsLoading(true)
        console.log('fetchDetails ---edit user');
        console.log(id);    
        const fetchDetails = await getUser(id)
        console.log(fetchDetails);
        setUserInfo(fetchDetails);
        setSelectedDesi(fetchDetails.designation);
        SetPhone(fetchDetails.p_phone_number)
        
        formik.setFieldValue('first_name', fetchDetails.first_name ?? '')
        formik.setFieldValue('last_name', fetchDetails.last_name ?? '')
        formik.setFieldValue('employee_id', fetchDetails.employee_id ?? '')
        formik.setFieldValue('designation', fetchDetails.designation ?? '')
        formik.setFieldValue('department', fetchDetails.department ?? '')
        formik.setFieldValue('branch', fetchDetails.branch ?? '')
        formik.setFieldValue('p_phone_number', fetchDetails.p_phone_number ?? '')
        formik.setFieldValue('sec_mobile', fetchDetails.sec_mobile ?? '')
        formik.setFieldValue('o_phone_number', fetchDetails.o_phone_number ?? '')
        formik.setFieldValue('o_email', fetchDetails.o_email ?? '')
        formik.setFieldValue('email', fetchDetails.email ?? '')
        formik.setFieldValue('emergency_contact_no', fetchDetails.emergency_contact_no ?? '')
        formik.setFieldValue('aadhar_number', fetchDetails.aadhar_number ?? '')
        formik.setFieldValue('pan_number', fetchDetails.pan_number ?? '')
        formik.setFieldValue('dob', Moment(fetchDetails.dob).format('YYYY-MM-DD') ?? '')
        formik.setFieldValue('marital_status', fetchDetails.marital_status ?? '')
        formik.setFieldValue('anniversary_date', Moment(fetchDetails.anniversary_date).format('YYYY-MM-DD') ?? '')
        formik.setFieldValue('date_of_joining', Moment(fetchDetails.date_of_joining).format('YYYY-MM-DD') ?? '')
        formik.setFieldValue('total_ctc', fetchDetails.total_ctc ?? '')

        formik2.setFieldValue('gender', fetchDetails.gender ?? '')
        formik2.setFieldValue('blood_group', fetchDetails.blood_group ?? '')
        formik2.setFieldValue('father_name', fetchDetails.father_name ?? '')
        formik2.setFieldValue('spouse_name', fetchDetails.spouse_name ?? '')
        formik2.setFieldValue('spouse_dob', Moment(fetchDetails.spouse_dob).format('YYYY-MM-DD') ?? '')
        formik2.setFieldValue('medical_condition', fetchDetails.medical_condition ?? '')
        formik2.setFieldValue('emergency_contact_person', fetchDetails.emergency_contact_person ?? '')
        formik2.setFieldValue('relation_person', fetchDetails.relation_person ?? '')
        formik2.setFieldValue('fathers_name', fetchDetails.fathers_name ?? '')
        formik2.setFieldValue('no_of_kids', fetchDetails.no_of_kids ?? '')
        formik2.setFieldValue('kid_name_1', fetchDetails.kid_name_1 ?? '')
        formik2.setFieldValue('kid_name_2', fetchDetails.kid_name_2 ?? '')
        formik2.setFieldValue('kid_name_3', fetchDetails.kid_name_3 ?? '')
        formik2.setFieldValue('crm_login_id', fetchDetails.crm_login_id ?? '')
        formik2.setFieldValue('crm_login_password', fetchDetails.crm_login_password ?? '')
        formik2.setFieldValue('correspondence_address', fetchDetails.correspondence_address ?? '')
        formik2.setFieldValue('permenent_address', fetchDetails.permenent_address ?? '')

        formik3.setFieldValue('past_employment_history', fetchDetails.past_employment_history ?? '')
        formik3.setFieldValue('years_of_experiance', fetchDetails.years_of_experiance ?? '')
        formik3.setFieldValue('last_company', fetchDetails.last_company ?? '')
        formik3.setFieldValue('city', fetchDetails.city ?? '')
        formik3.setFieldValue('portfolio_head', fetchDetails.portfolio_head ?? '')
        formik3.setFieldValue('target_for_fy_rs', fetchDetails.target_for_fy_rs ?? '')
        formik3.setFieldValue('target_for_fy_units', fetchDetails.target_for_fy_units ?? '')
        formik3.setFieldValue('team_leader', fetchDetails.team_leader ?? '')
        formik3.setFieldValue('monthly_ctc', fetchDetails.monthly_ctc ?? '')
        formik3.setFieldValue('monthly_takehome', fetchDetails.monthly_takehome ?? '')
        formik3.setFieldValue('incentive', fetchDetails.incentive ?? '')
        formik3.setFieldValue('duration', fetchDetails.duration ?? '')
        formik3.setFieldValue('official_mail', fetchDetails.official_mail ?? '')
        formik3.setFieldValue('official_mobile_number', fetchDetails.official_mobile_number ?? '')
        formik3.setFieldValue('local_address', fetchDetails.local_address ?? '')
        formik3.setFieldValue('monthly_take_home', fetchDetails.monthly_take_home ?? '')
        formik3.setFieldValue('years_of_experience', fetchDetails.years_of_experience ?? '')
        
        formik4.setFieldValue('bank_record_name', fetchDetails.bank_record_name ?? '')
        formik4.setFieldValue('bank_name', fetchDetails.bank_name ?? '')
        formik4.setFieldValue('branch_name', fetchDetails.branch_name ?? '')
        formik4.setFieldValue('acc_number', fetchDetails.acc_number ?? '')
        formik4.setFieldValue('ifsc_code', fetchDetails.ifsc_code ?? '')
        formik4.setFieldValue('base_salary', fetchDetails.base_salary ?? '')
        formik4.setFieldValue('pf', fetchDetails.pf ?? '')
        formik4.setFieldValue('hra', fetchDetails.hra ?? '')
        formik4.setFieldValue('mediclaim', fetchDetails.mediclaim ?? '')
        formik4.setFieldValue('conveyance', fetchDetails.conveyance ?? '')
        formik4.setFieldValue('misc', fetchDetails.misc ?? '')
        formik4.setFieldValue('incentive', fetchDetails.incentive ?? '')

        formik5.setFieldValue('annual_target', fetchDetails.annual_target ?? '')
        formik5.setFieldValue('status_changed', fetchDetails.status_changed ?? '')
        formik5.setFieldValue('no_of_units_committed', fetchDetails.no_of_units_committed ?? '')
        formik5.setFieldValue('no_of_sales', fetchDetails.no_of_sales ?? '')
        formik5.setFieldValue('goal_calls', fetchDetails.goal_calls ?? '')
        formik5.setFieldValue('goal_talktime', fetchDetails.goal_talktime ?? '')
        formik5.setFieldValue('goal_leads_generated', fetchDetails.goal_leads_generated ?? '')
        formik5.setFieldValue('goal_leads_converted', fetchDetails.goal_leads_converted ?? '')
        formik5.setFieldValue('goal_site_visit', fetchDetails.goal_site_visit ?? '')
        formik5.setFieldValue('goal_meetings', fetchDetails.goal_meetings ?? '')
        formik5.setFieldValue('goal_bookings', fetchDetails.goal_bookings ?? '')
        formik5.setFieldValue('goal_revenue', fetchDetails.goal_revenue ?? '')
        setIsLoading(false)
    }

    const DesignList =  async () => {   
        const designResponse = await getDesignation();
        setDesign(designResponse);
    }

    const DeptList =  async () => {   
        const deptResponse = await getDept();
        setDept(deptResponse);
    }

    const BranchList =  async () => {   
        const branchResponse = await getBranch();
        setBranch(branchResponse);
    }
    
    const genderList =  async () => {
        const genderResponse = await getGender()
        setGender(genderResponse);
    }
    const MaritalStatusList =  async () => {
        const MaritalStatusResponse = await getMaritalStatus()
        setMaritalStatus(MaritalStatusResponse);
    }
    const CityList =  async () => {
        const CityResponse = await getCity()
        setCity(CityResponse);
    }

    const closeDialogue = () => {
        setDetailClicked(false);
        var offCanvasEl = document.getElementById('kt_expand'+userInfo.id);
        offCanvasEl?.classList.add('invisible');
        const bsOffcanvas = new Offcanvas(offCanvasEl!);
        bsOffcanvas.hide();
    }

    useEffect(() => {
        // statusList();
        FetchContactDetails(currentUserId);
        DesignList();
        DeptList();
        BranchList();
        genderList();
        teamLeaderList();
        CityList();
        MaritalStatusList(); 
    }, [currentUserId]);

      const formik = useFormik({
        initialValues,
        validationSchema: registrationBasicSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
            setIsLoading(true)
          try {
            var userId = currentUser?.id;
            
            const body = {     
                "first_name" :  values.first_name,
                "last_name": values.last_name,
                "employee_id" : values.employee_id,
                "designation" : values.designation,
                "department" : values.department,
                "branch" : values.branch,
                "p_phone_number" : values.p_phone_number,
                "sec_mobile" : values.sec_mobile,
                "email" : values.email,
                "o_phone_number" : values.o_phone_number,
                "o_email" : values.o_email,
                "emergency_contact_no" : values.emergency_contact_no,
                "aadhar_number" : values.aadhar_number,
                "pan_number" : values.pan_number,
                "dob" : values.dob == "Invalid date" ? '' : values.dob,
                "marital_status" : values.marital_status,
                "anniversary_date" : values.anniversary_date == "Invalid date" ? '' : values.anniversary_date,
                "date_of_joining" : values.date_of_joining == "Invalid date" ? '' : values.date_of_joining,
                "created_by":userId,                                
            } 

            const updateUserData = await updateUser(currentUserId, body)
    
            console.log('updateUserData');
            console.log(updateUserData);
            // document.getElementById('kt_contact')?.classList.remove('drawer-on');
            if(updateUserData!= null){
                // const saveStatusData = await saveAddUserData(body);
                setIsLoading(false)
                document.getElementById('kt_expand_close')?.click();
                var toastEl = document.getElementById('myToastUpdate');
                const bsToast = new Toast(toastEl!);
                bsToast.show();
                // resetForm();
                const characterResponse = await getUsers()
                setUser(characterResponse)
                // statusList();
                console.log("this");
            } 
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setIsLoading(false)
          }
        },
      })

      const formik2 = useFormik({
        initialValues,
        validationSchema: registrationSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
            setIsLoading(true)
          try {
            var userId = currentUser?.id;
            
            const body = {
                "crm_login_id": values.crm_login_id,
                "crm_login_password": values.crm_login_password,
                "gender": values.gender,
                "blood_group": values.blood_group,
                "permenent_address": values.permenent_address,
                "correspondence_address": values.correspondence_address,
                "fathers_name": values.fathers_name,
                "spouse_name": values.spouse_name,
                "spouse_dob": values.spouse_dob == "Invalid date" ? '' : values.spouse_dob,
                "medical_condition": values.medical_condition,
                "emergency_contact_number": '',
                "emergency_contact_person": values.emergency_contact_person,
                "relation_person": values.relation_person,
                "no_of_kids": values.no_of_kids,
                "kid_name_1": values.kid_name_1,
                "kid_name_2": values.kid_name_2,
                "kid_name_3": values.kid_name_3
            }
    
            const updateUserData = await updateOtherDetails(currentUserId, body)
    
            console.log('updateUserData');
            console.log(updateUserData);
            // document.getElementById('kt_contact')?.classList.remove('drawer-on');
            if(updateUserData!= null){
                // const saveStatusData = await saveAddUserData(body);
                setIsLoading(false)
                document.getElementById('kt_expand_close')?.click();
                var toastEl = document.getElementById('myToastUpdate');
                const bsToast = new Toast(toastEl!);
                bsToast.show();
                // resetForm();
                const characterResponse = await getUsers()
                setUser(characterResponse)
                // statusList();
                console.log("this");
            } 
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setIsLoading(false)
          }
        },
      })

      const formik3 = useFormik({
        initialValues,
        validationSchema: registrationSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
            setIsLoading(true)
          try {
            var userId = currentUser?.id;
            
            const body = {
                "years_of_experience": values.years_of_experience,
                "last_company": values.last_company,
                "duration": values.duration,
                "official_mobile_number": values.official_mobile_number,
                "official_mail": values.official_mail,
                "local_address": values.local_address,
                "city": values.city,
                "team_leader": values.team_leader,
                "portfolio_head": values.portfolio_head,
                "target_for_fy_rs": values.target_for_fy_rs,
                "target_for_fy_units": values.target_for_fy_units,
                "monthly_ctc": values.monthly_ctc,
                "incentive": values.incentive,
                "monthly_take_home": values.monthly_take_home,
            }   
    
            const updateUserData = await updateProofDetails(currentUserId, body)
    
            console.log('updateUserData');
            console.log(updateUserData);
            // document.getElementById('kt_contact')?.classList.remove('drawer-on');
            if(updateUserData!= null){
                // const saveStatusData = await saveAddUserData(body);
                setIsLoading(false)
                document.getElementById('kt_expand_close')?.click();
                var toastEl = document.getElementById('myToastUpdate');
                const bsToast = new Toast(toastEl!);
                bsToast.show();
                // resetForm();
                const characterResponse = await getUsers()
                setUser(characterResponse)
                // statusList();
                console.log("this");
            } 
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setIsLoading(false)
          }
        },
      })

      const formik4 = useFormik({
        initialValues,
        validationSchema: registrationSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
            setIsLoading(true)
          try {
            var userId = currentUser?.id;
            
            const body = {
                "base_salary": values.base_salary,
                "pf": values.pf,
                "hra": values.hra,
                "mediclaim": values.mediclaim,
                "conveyance": values.conveyance,
                "misc": values.misc,
                "bank_record_name": values.bank_record_name,
                "bank_name": values.bank_name,
                "acc_number": values.acc_number,
                "ifsc_code": values.ifsc_code,
                "branch_name": values.branch_name,
            }
    
            const updateUserData = await updatePackageDetails(currentUserId, body)
    
            console.log('updateUserData');
            console.log(updateUserData);
            // document.getElementById('kt_contact')?.classList.remove('drawer-on');
            if(updateUserData!= null){
                // const saveStatusData = await saveAddUserData(body);
                setIsLoading(false)
                document.getElementById('kt_expand_close')?.click();
                var toastEl = document.getElementById('myToastUpdate');
                const bsToast = new Toast(toastEl!);
                bsToast.show();
                // resetForm();
                const characterResponse = await getUsers()
                setUser(characterResponse)
                // statusList();
                console.log("this");
            } 
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setIsLoading(false)
          }
        },
      })
      const formik5 = useFormik({
        initialValues,
        validationSchema: registrationSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setIsLoading(true)
          try {
            var userId = currentUser?.id;
            
            const body = {
                "annual_target": values.annual_target,
                "status_changed": values.status_changed,
                "no_of_units_committed": values.no_of_units_committed,
                "no_of_sales": values.no_of_sales,
                "goal_calls": values.goal_calls,
                "goal_talktime": values.goal_talktime,
                "goal_leads_generated": values.goal_leads_generated,
                "goal_leads_converted": values.goal_leads_converted,
                "goal_site_visit": values.goal_site_visit,
                "goal_meetings": values.goal_meetings,
                "goal_bookings": values.goal_bookings,
                "goal_revenue": values.goal_revenue 
            }
    
            const updateUserData = await updateUserGoal(currentUserId, body)
    
            console.log('updateUserData');
            console.log(updateUserData);
            // document.getElementById('kt_contact')?.classList.remove('drawer-on');
            if(updateUserData!= null){
                // const saveStatusData = await saveAddUserData(body);
                setIsLoading(false)
                document.getElementById('kt_expand_close')?.click();
                var toastEl = document.getElementById('myToastUpdate');
                const bsToast = new Toast(toastEl!);
                bsToast.show();
                // resetForm();
                const characterResponse = await getUsers()
                setUser(characterResponse)
                // statusList();
                console.log("this");
            } 
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setIsLoading(false)
          }
        },
      })

      const [contactType, setContactType] = useState<any[]>([]);

      const handleImagePreview2 = (e:any) => {
        let image_as_base64:any = URL.createObjectURL(e.target.files[0])
        let image_as_files:any = e.target.files[0];
      
        
        setImageFile(image_as_files);
      }

      const minimaximize = () => {
        setIsExpand(current => !current);
    }
    
    const fullScreenChange = () => {
        setIsFullScreen(current => !current);
    }  

    const changeImage = async (id:any, image:any) => {
        let image_as_files:any = image.target.files[0];
        var formData = new FormData(); 
        formData.append('profile_image', image_as_files);
        
        const saveUserData = await updateProfileImage(id, formData)
        if(saveUserData != null){                
            setChangeClicked(false);
            FetchContactDetails(id)
            const characterResponse = await getUsers()
            setUser(characterResponse)                                     
        }
    }
    const removeImage = async (id:any) => {
        var formData = new FormData(); 
        formData.append('profile_image', '');
        
        const saveUserData = await updateProfileImage(id, formData)
        if(saveUserData != null){                
            setChangeClicked(false);
            FetchContactDetails(id)
            const characterResponse = await getUsers()
            setUser(characterResponse)                                     
        }
    }

    const teamLeaderList = async () => {
        const response = await getTeamLeader(selectedDesi)
        setTeamLeader(response);
    }

    const desiChange = (e:any) => {
        setSelectedDesi(e);
        formik.setFieldValue('designation', e ?? '')
    }

    useEffect(() => {
        teamLeaderList();
    }, [selectedDesi]);
      
    return(
        
        <div className={isExpand ? isFullScreen ? "w-100 contact_details_page full_screen" : "w-75 contact_details_page full_screen m-5": "contact_details_page small_screen d-flex align-items-end justify-content-end m-5"}>
            { isLoading ? 
            <div className="card main_bg h-100">
                <div className='w-100 h-100'>
                    <div className='d-flex justify-content-center flex-column align-items-center vh-100'>
                        <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="w-250px h-50px mb-9" alt='' />
                        <div className="spinner-border taskloader" style={{width:"3rem", height: "3rem"}} role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div> 
                </div> 
            </div> :
             isExpand ?
            <div className="card main_bg h-100">
                <div className="card-header mb-4">
                    <div className='card-toolbar d-flex align-items-center justify-content-end'>
                        <button className="btn m-3 expand_btn" onClick={fullScreenChange}>
                            <img src={isFullScreen ? toAbsoluteUrl('/media/custom/overview-icons/comp_white.svg') : toAbsoluteUrl('/media/custom/overview-icons/expand_white.svg')} className="svg_icon" alt='' />
                        </button>
                        <button className="btn m-3 minimize_btn" onClick={minimaximize}>
                            <img src={toAbsoluteUrl('/media/custom/overview-icons/minimize_white.svg')} className="svg_icon" alt='' />
                        </button>
                        <button
                            type='button'
                            className='btn m-3 close_btn'
                            id='kt_expand_close'
                            data-bs-dismiss="offcanvas"
                            onClick={closeDialogue}
                            >
                                <img src={toAbsoluteUrl('/media/custom/overview-icons/cancel_white.svg')} className="svg_icon" alt='' />
                        </button>
                    </div>
                    {/* <h3>Contact Full screen</h3> */}
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="card-group">
                            <div className="col-xxl-6 col-12 mb-3">
                                <div className="card bs_1 name_card h-100 mx-2">
                                    <div className="card-body p-3">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 position-relative" onClick={() => setChangeClicked(true)}>
                                                {changeClicked == false ? 
                                                    <img onError={e => { e.currentTarget.src = toAbsoluteUrl('/media/avatars/blank.png') }} src={userInfo.profile_image ? process.env.REACT_APP_API_BASE_URL+'uploads/users/profile_image/'+userInfo.id+'/'+userInfo.profile_image : ''} className="user_img" alt='' /> : 
                                                    <><span className="user_img btn btn-file">
                                                        <i className="fa fa-upload my-2" aria-hidden="true"></i>Upload <input type="file" onChange={(e) => changeImage(userInfo.id, e)}/>
                                                    </span><div className='text-center position-absolute top-0 end-0'><button type='button' className='btn btn-sm btn-icon btn-secondary rounded-circle' title='Remove image' onClick={(e) => removeImage(userInfo.id)}><img src={toAbsoluteUrl('/media/custom/overview-icons/cancel_white.svg')} className="svg_icon" alt='' /></button></div></>}
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="d-flex align-items-center">
                                                            
                                                            <h4 className="mb-0 ms-2">{userInfo.first_name+' '+userInfo.last_name}</h4>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <a href={"mailto:"+ userInfo.email} className="btn_soft_primary"><i className="fas fa-envelope"></i></a>
                                                        <a href={"tel:"+ userInfo.p_phone_number} className="btn_soft_primary"><i className="fas fa-phone-alt"></i></a>
                                                        {/* <a href="/#" className="btn_soft_primary"><i className="fas fa-clipboard-list"></i></a> */}
                                                        <a href={"https://api.whatsapp.com/send?phone="+ userInfo.p_phone_number} className="btn_soft_primary">
                                                            <img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} className="svg_icon" alt='' />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-6 col-12 mb-3">
                                <div className="card bs_1 h-100 mx-2 info_card">
                                    <div className="card-body p-3">
                                        <div className='row w-100 p-3'>
                                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 p-2">
                                                <small className="mb-0">Email</small>
                                                <p className="mb-0">{userInfo.email}</p>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 p-2">
                                                <small className="mb-0">Phone Number</small>
                                                <p className="mb-0">{phone}</p>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 p-2">
                                                <small className="mb-0">Contact Category</small>
                                                <p className="mb-0">{userInfo.sec_mobile}</p>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 p-2">
                                                <small className="mb-0">Created By</small>
                                                <p className="mb-0">{userInfo.created_by_name}</p>
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab_container bg_white br_10 bs_1 mx-2">
                        <div className="row mt-4">
                            <div className="col-12">
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'basicDetails' ? "nav-link active" : "nav-link"} id="basicDetails-tab" data-bs-toggle="pill" data-bs-target={"#basicDetails"+currentUserId} type="button" role="tab" aria-controls={"basicDetails"+currentUserId} aria-selected="true">Basic Details</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'personalDetails' ? "nav-link active" : "nav-link"} id="personalDetails-tab" data-bs-toggle="pill" data-bs-target={"#personalDetails"+currentUserId} type="button" role="tab" aria-controls={"personalDetails"+currentUserId} aria-selected="false">Personal Details</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'professionalDetails' ? "nav-link active" : "nav-link"} id="professionalDetails-tab" data-bs-toggle="pill" data-bs-target={"#professionalDetails"+currentUserId} type="button" role="tab" aria-controls={"professionalDetails"+currentUserId} aria-selected="false">Professional Details</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'financialDetails' ? "nav-link active" : "nav-link"} id="financialDetails-tab" data-bs-toggle="pill" data-bs-target={"#financialDetails"+currentUserId} type="button" role="tab" aria-controls={"financialDetails"+currentUserId} aria-selected="false">Financial Details</button>
                                    </li>                                    
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'goalDetails' ? "nav-link active" : "nav-link"} id="golDetails-tab" data-bs-toggle="pill" data-bs-target={"#goalDetails"+currentUserId} type="button" role="tab" aria-controls={"goalDetails"+currentUserId} aria-selected="false">Goals Details</button>
                                    </li>                                    
                                </ul>
                                <div className="tab-content pt-5" id="pills-tabContent">
                                    <div className={tabInfo == 'basicDetails' ? "tab-pane fade show active" : "tab-pane fade"} id={"basicDetails"+currentUserId} role="tabpanel" aria-labelledby="basicDetails-tab">
                                    <div className='mb-9' style={{ height: '100%', width: '100%',}}>
                                        <form noValidate onSubmit={formik.handleSubmit}>
                                            <div className='row'>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label required">First Name</label>
                                            <div className="input-group first mb-3 input_prepend">
                                                <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                                    <option value="Mr">Mr</option>
                                                    <option value="Mrs">Mrs</option>
                                                </select>
                                                <input type="text" {...formik.getFieldProps('first_name')}
                                                    className="form-control" placeholder="First Name" />
                                            </div>
                                            {formik.touched.first_name && formik.errors.first_name && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.first_name}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Last Name</label>
                                            <div className="input-group">
                                                <input type="text" {...formik.getFieldProps('last_name')}
                                                    className="form-control" placeholder="Last Name" />
                                            </div>
                                            {/* {formik.touched.last_name && formik.errors.last_name && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.last_name}</span>
                                                    </div>
                                                </div>
                                            )} */}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label required">Employee ID</label>
                                            <div className="input-group">
                                                <input type="text" {...formik.getFieldProps('employee_id')} className="form-control" placeholder="Enter Id" />
                                            </div>
                                            {formik.touched.employee_id && formik.errors.employee_id && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.employee_id}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label required">Designation</label>
                                            <div className="input-group mb-3">
                                                <select
                                                    {...formik.getFieldProps('designation')} id='designationId' onChange={(e) => desiChange(e.target.value)}
                                                    className="form-select btn btn-sm w-100 text-start">
                                                    <option disabled value=''>Select</option>
                                                    {design.map((designValue,i)=> {
                                                    return (
                                                    <option selected={i == 0 ? true: false} value={designValue.id} key={i}>{designValue.name}</option>
                                                    )
                                                    })} 
                                                </select>
                                                {/* <input type="text" {...formik.getFieldProps('designation')} className="form-control" placeholder="Designation" /> */}
                                            </div>
                                            {formik.touched.designation && formik.errors.designation && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert' className='text-danger'>{formik.errors.designation}</span>
                                                </div>
                                            </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label required">Department</label>
                                            <div className="input-group mb-3">
                                                <select 
                                                    {...formik.getFieldProps('department')} 
                                                    className="form-select btn btn-sm w-100 text-start">
                                                    <option disabled value=''>Select</option>
                                                    {dept.map((deptValue,i)=> {
                                                    return (
                                                    <option selected={i == 0 ? true: false} value={deptValue.id} key={i}>{deptValue.name}</option>
                                                    )
                                                    })} 
                                                </select>
                                                {/* <input type="text" {...formik.getFieldProps('department')} className="form-control" placeholder="Department" /> */}
                                            </div>
                                            {formik.touched.department && formik.errors.department && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.department}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Branch</label>
                                            <div className="input-group mb-3">
                                                <select 
                                                    {...formik.getFieldProps('branch')} 
                                                    className="form-select btn btn-sm w-100 text-start">
                                                    <option disabled value=''>Select</option>
                                                    {branch.map((branchValue,i)=> {
                                                    return (
                                                    <option selected={i == 0 ? true: false} value={branchValue.id} key={i}>{branchValue.name}</option>
                                                    )
                                                    })} 
                                                </select>
                                                {/* <input type="text" {...formik.getFieldProps('branch')} className="form-control" placeholder="Department" /> */}
                                            </div>
                                            {formik.touched.branch && formik.errors.branch && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.branch}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label required">Phone Number</label>
                                            <div className="input-group mb-3 input_prepend">
                                                <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                                    <option value="">+91</option>
                                                </select>
                                                <input type="number" min="0" {...formik.getFieldProps('p_phone_number')} className="form-control" placeholder="Enter your Phone Number" maxLength={10} />
                                            </div>
                                            {formik.touched.p_phone_number && formik.errors.p_phone_number && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.p_phone_number}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label required">Sec. Phone Number</label>
                                            <div className="input-group mb-3 input_prepend">
                                                <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                                    <option value="">+91</option>
                                                </select>
                                                <input type="number" min="0" {...formik.getFieldProps('sec_mobile')} className="form-control" placeholder="Enter your Phone Number" maxLength={10} />
                                            </div>
                                            {formik.touched.sec_mobile && formik.errors.sec_mobile && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.sec_mobile}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label required">Email ID</label>
                                            <div className="input-group mb-3">
                                                <input type="email" {...formik.getFieldProps('email')} className="form-control" placeholder="Enter your email" />
                                            </div>
                                            {formik.touched.email && formik.errors.email && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.email}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Aadhar Number</label>
                                            <div className="input-group mb-3 input_prepend">
                                                <input type="number" min="0" {...formik.getFieldProps('aadhar_number')} className="form-control" placeholder="Enter Aadhar" maxLength={12} />
                                            </div>
                                            {formik.touched.aadhar_number && formik.errors.aadhar_number && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.aadhar_number}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">PAN Number</label>
                                            <div className="input-group mb-3 input_prepend">
                                                <input type="text" {...formik.getFieldProps('pan_number')} className="form-control" placeholder="Enter PAN" maxLength={10} />
                                            </div>
                                            {formik.touched.pan_number && formik.errors.pan_number && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.pan_number}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Date of Birth</label>
                                            <div className="input-group mb-3">
                                                <input type="date" {...formik.getFieldProps('dob')} className="form-control" placeholder="date" />
                                            </div>
                                            {formik.touched.dob && formik.errors.dob && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.dob}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Marital Status</label>
                                            <div className="input-group mb-3">
                                                <select className="form-select btn btn-sm w-100 text-start" {...formik.getFieldProps('marital_status')} >
                                                    <option disabled value="">Select</option>
                                                    {maritalStatus.map((branchValue,i)=> {
                                                    return (
                                                    <option selected={i == 0 ? true: false} value={branchValue.id} key={i}>{branchValue.name}</option>
                                                    )
                                                    })} 
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Anniversary Date</label>
                                                <div className="input-group mb-3">
                                                    <input type="date" {...formik.getFieldProps('anniversary_date')} className="form-control" placeholder="date" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Date of Joining</label>
                                            <div className="input-group mb-3">
                                                <input type="date" {...formik.getFieldProps('date_of_joining')} className="form-control" placeholder="date" />
                                            </div>
                                            {formik.touched.date_of_joining && formik.errors.date_of_joining && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.date_of_joining}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Emergency Contact Number</label>
                                            <div className="input-group mb-3 input_prepend">
                                                <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                                    <option value="">+91</option>
                                                </select>
                                                <input type="number" min="0" {...formik.getFieldProps('emergency_contact_no')} className="form-control" placeholder="Enter Phone Number" maxLength={10} />
                                            </div>
                                            {formik.touched.emergency_contact_no && formik.errors.emergency_contact_no && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.emergency_contact_no}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Official Phone Number</label>
                                            <div className="input-group mb-3 input_prepend">
                                                <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                                    <option value="">+91</option>
                                                </select>
                                                <input type="number" min="0" {...formik.getFieldProps('o_phone_number')} className="form-control" placeholder="Enter Phone Number" maxLength={10} />
                                            </div>
                                            {formik.touched.o_phone_number && formik.errors.o_phone_number && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.o_phone_number}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Official Email ID</label>
                                            <div className="input-group mb-3">
                                                <input type="email" {...formik.getFieldProps('o_email')} className="form-control" placeholder="Enter your email" />
                                            </div>
                                            {formik.touched.o_email && formik.errors.o_email && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.o_email}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        </div>
                                        <div className='text-center'>
                                            <button
                                                type='submit'
                                                id='kt_sign_up_submit'
                                                className='btn btn_primary text-primary'
                                                disabled={formik.isSubmitting}
                                            >
                                                {!isLoading && <span className='indicator-label'>Submit
                                                    <KTSVG
                                                        path='/media/custom/save_white.svg'
                                                        className='svg-icon-3 svg-icon-primary ms-2' />
                                                </span>}
                                                {isLoading && (
                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                        Please wait...{' '}
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                        </form>
                                    </div>
                                    </div>
                                    <div className={tabInfo == 'personalDetails' ? "tab-pane fade show active" : "tab-pane fade"} id={"personalDetails"+currentUserId} role="tabpanel" aria-labelledby="personalDetails-tab">
                                    <div className='mb-9' style={{ height: '100%', width: '100%',}}>
                                    <form noValidate onSubmit={formik2.handleSubmit}>
                                        <div className='row'>
                                        <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Gender</label>
                                        <div className="input-group mb-3">
                                            <select className="form-select btn btn-sm w-100 text-start" {...formik2.getFieldProps('gender')}>
                                                <option disabled value="">Select Gender</option>
                                                {gender.map((branchValue,i)=> {
                                                    return (
                                                    <option selected={i == 0 ? true: false} value={branchValue.id} key={i}>{branchValue.name}</option>
                                                    )
                                                    })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Blood Group</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik2.getFieldProps('blood_group')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Father's Name</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik2.getFieldProps('fathers_name')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Medical Condition</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik2.getFieldProps('medical_condition')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Emergency Contact Person Name</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik2.getFieldProps('emergency_contact_person')} placeholder=""/>
                                        </div>
                                    </div>                                     
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">CRM Id</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik2.getFieldProps('crm_login_id')} placeholder=""/>
                                        </div>
                                    </div>                                     
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">CRM Password</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik2.getFieldProps('crm_login_password')} placeholder=""/>
                                        </div>
                                    </div>                                     
                                    
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Emergency Contact Person Relation</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik2.getFieldProps('relation_person')} placeholder=""/>
                                        </div>
                                    </div> 
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Permanent Address</label>
                                            <div className="input-group">
                                                <textarea rows={4} {...formik2.getFieldProps('permenent_address')} className="form-control" placeholder="Permanent Address" />
                                            </div>
                                            {formik2.touched.permenent_address && formik2.errors.permenent_address && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik2.errors.permenent_address}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Correspondence Address</label>
                                            <div className="input-group">
                                                <textarea rows={4} {...formik2.getFieldProps('correspondence_address')} className="form-control" placeholder="Correspondence Address" />
                                            </div>
                                            {formik2.touched.correspondence_address && formik2.errors.correspondence_address && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik2.errors.correspondence_address}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Spouse Name</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" {...formik2.getFieldProps('spouse_name')} placeholder="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Spouse DOB</label>
                                                    <div className="input-group">
                                                        <input type="date" className="form-control" {...formik2.getFieldProps('spouse_dob')} placeholder="" />
                                                    </div>
                                                </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Number of Kids</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik2.getFieldProps('no_of_kids')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">1st Kid Name</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik2.getFieldProps('kid_name_1')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">2nd Kid Name</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik2.getFieldProps('kid_name_2')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">3rd Kid Name</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik2.getFieldProps('kid_name_3')} placeholder=""/>
                                        </div>
                                    </div>
                                        </div>
                                        <div className='text-center'>
                                            <button
                                                type='submit'
                                                id='kt_sign_up_submit'
                                                className='btn btn_primary text-primary'
                                                disabled={formik2.isSubmitting}
                                            >
                                                {!isLoading && <span className='indicator-label'>Submit
                                                    <KTSVG
                                                        path='/media/custom/save_white.svg'
                                                        className='svg-icon-3 svg-icon-primary ms-2' />
                                                </span>}
                                                {isLoading && (
                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                        Please wait...{' '}
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                        </form>
                                        </div>
                                    </div>
                                    <div className={tabInfo == 'professionalDetails' ? "tab-pane fade show active" : "tab-pane fade"} id={"professionalDetails"+currentUserId} role="tabpanel" aria-labelledby="professionalDetails-tab">
                                    <div className='mb-9' style={{ height: '100%', width: '100%',}}>
                                    <form noValidate onSubmit={formik3.handleSubmit}>
                                        <div className='row'>
                                        <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Year of Experiance</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik3.getFieldProps('years_of_experience')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Last Company Name</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik3.getFieldProps('last_company')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">City</label>
                                        <div className="input-group mb-3">
                                            <select className="form-select btn btn-sm w-100 text-start" {...formik3.getFieldProps('city')}>
                                                <option disabled value="">Select City</option>
                                                {city.map((branchValue,i)=> {
                                                    return (
                                                    <option selected={i == 0 ? true: false} value={branchValue.id} key={i}>{branchValue.name}</option>
                                                    )
                                                    })}
                                            </select>
                                        </div>
                                    </div>
                                    {selectedDesi != 1 && <>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Team Leader</label>
                                        <div className="input-group mb-3">
                                            <select className="form-select btn btn-sm w-100 text-start" {...formik3.getFieldProps('team_leader')}>
                                                <option disabled value="">Select</option>
                                                {teamLeader.map((team,i)=> {
                                                    return (
                                                    <option selected={i == 0 ? true: false} value={team.id} key={i}>{team.first_name}</option>
                                                    )
                                                    })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Portfolio Head</label>
                                        <div className="input-group mb-3">
                                            <select className="form-select btn btn-sm w-100 text-start" {...formik3.getFieldProps('portfolio_head')}>
                                                <option disabled value="">Select</option>
                                                {teamLeader.map((team,i)=> {
                                                    return (
                                                    <option selected={i == 0 ? true: false} value={team.id} key={i}>{team.first_name}</option>
                                                    )
                                                    })}
                                            </select>
                                        </div>
                                    </div></>}
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Duration</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik3.getFieldProps('duration')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Official Phone Number</label>
                                            <div className="input-group mb-3 input_prepend">
                                                <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                                    <option value="date">+91</option>
                                                </select>
                                                <input type="number" min="0" {...formik3.getFieldProps('official_mobile_number')} className="form-control" placeholder="Enter Phone Number" maxLength={10} />
                                            </div>
                                            {formik3.touched.o_phone_number && formik3.errors.o_phone_number && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik3.errors.o_phone_number}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Official Email ID</label>
                                            <div className="input-group mb-3">
                                                <input type="email" {...formik3.getFieldProps('official_mail')} className="form-control" placeholder="Enter your email" />
                                            </div>
                                            {formik3.touched.o_email && formik3.errors.o_email && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik3.errors.o_email}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Company Address</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik3.getFieldProps('local_address')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Target for the FY <small>(in Rs.)</small></label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik3.getFieldProps('target_for_fy_rs')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Target for the FY <small>(in Units)</small></label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik3.getFieldProps('target_for_fy_units')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Monthly CTC</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik3.getFieldProps('monthly_ctc')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Incentive %</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik3.getFieldProps('incentive')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Monthly Take Home</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik3.getFieldProps('monthly_take_home')} placeholder=""/>
                                        </div>
                                    </div>
                                        </div>
                                        <div className='text-center'>
                                            <button
                                                type='submit'
                                                id='kt_sign_up_submit'
                                                className='btn btn_primary text-primary'
                                                disabled={formik3.isSubmitting}
                                            >
                                                {!isLoading && <span className='indicator-label'>Submit
                                                    <KTSVG
                                                        path='/media/custom/save_white.svg'
                                                        className='svg-icon-3 svg-icon-primary ms-2' />
                                                </span>}
                                                {isLoading && (
                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                        Please wait...{' '}
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                        </form>
                                        </div>
                                    </div>
                                    <div className={tabInfo == 'financialDetails' ? "tab-pane fade show active" : "tab-pane fade"} id={"financialDetails"+currentUserId} role="tabpanel" aria-labelledby="financialDetails-tab">
                                    <div className='mb-9' style={{ height: '100%', width: '100%',}}>
                                    <form noValidate onSubmit={formik4.handleSubmit}>
                                        <div className='row'>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Base Salary</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik4.getFieldProps('base_salary')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">PF</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik4.getFieldProps('pf')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">HRA</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik4.getFieldProps('hra')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Mediclaim</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik4.getFieldProps('mediclaim')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Conveyance</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik4.getFieldProps('conveyance')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">MISC</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik4.getFieldProps('misc')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Incentive %</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik4.getFieldProps('incentive')} placeholder=""/>
                                        </div>
                                    </div>
                                <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Name <small>(As Per Bank record)</small></label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik4.getFieldProps('bank_record_name')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Bank Name</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik4.getFieldProps('bank_name')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Account No.</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik4.getFieldProps('acc_number')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">IFSC Code</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik4.getFieldProps('ifsc_code')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Branch Name</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik4.getFieldProps('branch_name')} placeholder=""/>
                                        </div>
                                    </div>
                                        </div>
                                        <div className='text-center'>
                                            <button
                                                type='submit'
                                                id='kt_sign_up_submit'
                                                className='btn btn_primary text-primary'
                                                disabled={formik4.isSubmitting}
                                            >
                                                {!isLoading && <span className='indicator-label'>Submit
                                                    <KTSVG
                                                        path='/media/custom/save_white.svg'
                                                        className='svg-icon-3 svg-icon-primary ms-2' />
                                                </span>}
                                                {isLoading && (
                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                        Please wait...{' '}
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                        </form>
                                        </div>
                                    </div>  
                                    <div className={tabInfo == 'goalDetails' ? "tab-pane fade show active" : "tab-pane fade"} id={"goalDetails"+currentUserId} role="tabpanel" aria-labelledby="goalDetails-tab">
                                        <div className='mb-9' style={{ height: '100%', width: '100%',}}>
                                            <form noValidate onSubmit={formik5.handleSubmit}>
                                                <div className='row'>
                                                    <div className="col-md-6 col-12 mb-3">
                                                        <label htmlFor="basic-url" className="form-label">Annual Target</label>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" {...formik5.getFieldProps('annual_target')} placeholder=""/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12 mb-3">
                                                        <label htmlFor="basic-url" className="form-label">Status Changed</label>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" {...formik5.getFieldProps('status_changed')} placeholder=""/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12 mb-3">
                                                        <label htmlFor="basic-url" className="form-label">No. of units commited</label>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" {...formik5.getFieldProps('no_of_units_committed')} placeholder=""/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12 mb-3">
                                                        <label htmlFor="basic-url" className="form-label">No. of sales</label>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" {...formik5.getFieldProps('no_of_sales')} placeholder=""/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12 mb-3">
                                                        <label htmlFor="basic-url" className="form-label">Goal Calls</label>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" {...formik5.getFieldProps('goal_calls')} placeholder=""/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12 mb-3">
                                                        <label htmlFor="basic-url" className="form-label">Goal Talktime</label>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" {...formik5.getFieldProps('goal_talktime')} placeholder=""/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12 mb-3">
                                                        <label htmlFor="basic-url" className="form-label">Goal Leads Generated</label>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" {...formik5.getFieldProps('goal_leads_generated')} placeholder=""/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12 mb-3">
                                                        <label htmlFor="basic-url" className="form-label">Goal Leads Converted</label>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" {...formik5.getFieldProps('goal_leads_converted')} placeholder=""/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12 mb-3">
                                                        <label htmlFor="basic-url" className="form-label">Goal Site Visit</label>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" {...formik5.getFieldProps('goal_site_visit')} placeholder=""/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12 mb-3">
                                                        <label htmlFor="basic-url" className="form-label">Goal Meetings</label>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" {...formik5.getFieldProps('goal_meetings')} placeholder=""/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12 mb-3">
                                                        <label htmlFor="basic-url" className="form-label">Goal Bookings</label>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" {...formik5.getFieldProps('goal_bookings')} placeholder=""/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12 mb-3">
                                                        <label htmlFor="basic-url" className="form-label">Goal Revenue</label>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" {...formik5.getFieldProps('goal_revenue')} placeholder=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='text-center'>
                                                    <button
                                                        type='submit'
                                                        id='kt_sign_up_submit'
                                                        className='btn btn_primary text-primary'
                                                        disabled={formik5.isSubmitting}
                                                    >
                                                        {!isLoading && <span className='indicator-label'>Submit
                                                            <KTSVG
                                                                path='/media/custom/save_white.svg'
                                                                className='svg-icon-3 svg-icon-primary ms-2' />
                                                        </span>}
                                                        {isLoading && (
                                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                                Please wait...{' '}
                                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                            </span>
                                                        )}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>:
            <div className="card bg_primary">
                <div className="card-body d-flex justify-content-between">
                    <div>
                        <h5 className='text-white'>Lead Details</h5>
                    </div>
                    <button onClick={minimaximize} className="mx-3 btn p-0">
                        <i className="fas fa-window-maximize text-white"></i>
                    </button>
                    <button type='button' id='kt_expand_close' data-bs-dismiss="offcanvas" onClick={closeDialogue} className="mx-3 btn p-0">
                        <i className="fas fa-times text-white"></i>
                    </button>
                </div>
            </div>
            }
        </div>
    )
}


export {EditUser}