import React,{FC, useEffect, useState} from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useAuth } from '../../../modules/auth';
import { getTeamsList, getUsersByRole } from '../../settings/userManagement/core/_requests';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { FunnelChart } from 'react-funnel-pipeline';
import { getCountStatusProperty } from '../core/requests';

ChartJS.register(ArcElement, Tooltip, Legend);

const initialValues = {
    start_date: "",
    end_date: "",
}

const PropertyFunnel: FC = () => {

  const {currentUser, logout} = useAuth();
  const userId = currentUser?.id;
  const roleId = currentUser?.designation;
  const usersName = currentUser?.first_name;
  const [search, setSearch] = useState("");
  const [propertyFunnel, setPropertyFunnel] = useState(1);
  const [userName, setUserName] = useState<any>(usersName);
  const [reqId, setReqId] = useState(userId);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [CountProperty, setCountProperty] = useState<any[]>([]);
  const [filtered, setFiltered] = useState<any[]>([]);  
  const [users, setUsers] = useState<any[]>([]);
  const [teams, setTeams] = useState<any[]>([]);
  const [filteredTeam, setFilteredTeam] = useState<any[]>([]);
  const [teamId, setTeamId] = useState(0);
  const [list, setList] = useState(1);
  

  const CountPropertyList =  async () => {  
    var body = {
      'start_date': fromDate,
      'end_date': toDate,
      'filter': propertyFunnel,
    }  
      const CountPropertyResponse = await getCountStatusProperty(userId, roleId, userId, body, teamId)
      setCountProperty(CountPropertyResponse);   
  }

  const usersList =  async () => {
    const Response = await getUsersByRole(userId, roleId)
    setUsers(Response);
  }

  const rangeSelect = async (val:any) => {
    setPropertyFunnel(val);
    setFromDate('');
    setToDate('');
    if(val == 5) {
    document.getElementById('propertyFunnelCustomDateRange')?.click();
    } else { 
    var body = {
      'start_date': fromDate,
      'end_date': toDate,
      'filter': val,
    } 
    const CountPropertyResponse = await getCountStatusProperty(userId, roleId, reqId, body, teamId)
    setCountProperty(CountPropertyResponse);      
  }
  }

  const userFilter = async (name:any, id:any) => {
    setReqId(id);
    setUserName(name);
    var body = {
      'start_date': fromDate,
      'end_date': toDate,
      'filter': propertyFunnel,
    } 
    const CountPropertyResponse = await getCountStatusProperty(userId, roleId, id, body, teamId)
    setCountProperty(CountPropertyResponse);    
  }

    const taskSaveSchema = Yup.object().shape({
      start_date: Yup.string(),
      end_date: Yup.string(),
  })

    const formik = useFormik({
      initialValues,
      validationSchema: taskSaveSchema ,
      onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
        setLoading(true)
        try {
  
          setFromDate(values.start_date)
          setToDate(values.end_date)

          var body = {
            'start_date': values.start_date,
            'end_date': values.end_date,
            'filter': propertyFunnel,
          }  

          const CountPropertyResponse = await getCountStatusProperty(userId, roleId, reqId, body, teamId)            

          if(CountPropertyResponse != null){
            setCountProperty(CountPropertyResponse);            
            resetForm();
            setLoading(false)
            document.getElementById('propertyFunnelModelClose')?.click();
          }

        } catch (error) {
          console.error(error)
          setStatus('The registration details is incorrect')
          setSubmitting(false)
          setLoading(false)
        }
  }})

    useEffect(() => {
      CountPropertyList();        
      usersList();        
    }, []);

    function filterItem(item:any, search:any) {
      if (search.startsWith("@")) {
        const bucket = search.toLowerCase().substring(1).split(":");
        const searchBy = bucket[0];
        const searchFor = bucket[1];
        const searchByType = getType(item[searchBy]);
    
        if (!searchFor) return false;
        console.log("**** searchBy: ", searchBy);
        console.log("**** searchFor: ", searchFor);
        console.log("**** item: ", item);
        if (searchByType == "string") {
          if (searchFor.startsWith("!")) {
            const bucket = searchFor.split("!");
            const searchForVal = bucket[1];
            return !item[searchBy].includes(searchForVal);
          }
          return item[searchBy].includes(searchFor);
        }
    
        if (searchByType == "array") {
          if (searchFor.startsWith("!")) {
            const bucket = searchFor.split("!");
            const searchForVal = bucket[1];
            return item[searchBy].find((val:any) => !val.includes(searchForVal));
          }
          return item[searchBy].find((val:any) => val.includes(searchFor));
        }
    
        if (searchByType == "object") {
          if (searchFor.startsWith("!")) {
            const bucket = searchFor.split("!");
            const val = bucket[1] || "";
            return !item[searchBy][val];
          }
          if (searchFor.includes("!=")) {
            const bucket2 = searchFor.split("!=");
            const key = bucket2[0];
            const val = bucket2[1] || "";
            return item[searchBy][key] && !item[searchBy][key].includes(val);
          }
          const bucket2 = searchFor.split("=");
          const key = bucket2[0];
          const val = bucket2[1] || "";
          return item[searchBy][key] && item[searchBy][key].includes(val);
        }
      } else {
        if (search.startsWith("!")) {
          const bucket = search.split("!");
          const searchFor = bucket[1];
          if(list == 1) {
          return !item.team_name_lower.includes(searchFor);
          } else {
          return !item.employee_name_lower.includes(searchFor);
        }
        }
        if(list == 1) {
        return item.team_name_lower.includes(search);
         } else {
        return item.employee_name_lower.includes(search);
         }
      }
    }
    
    const getType = (value:any) => {
      if (Array.isArray(value)) {
        return "array";
      } else if (typeof value == "string") {
        return "string";
      } else if (value == null) {
        return "null";
      } else if (typeof value == "boolean") {
        return "boolean";
      } else if (Number(value) == value) {
        return "number";
      } else if (typeof value == "object") {
        return "object";
      }
      return "string";
    }; 
  
    useEffect(() => {
      teamsList();
      usersList();
  
      let filteredData:any = [];
      if (search.length) {
        if(list == 1) {
          teams.forEach((item) => {
            if (filterItem(item, search.toLowerCase())) {
              filteredData.push({ ...item });
            }
          });
        } else {
          users.forEach((item) => {
            if (filterItem(item, search.toLowerCase())) {
              filteredData.push({ ...item });
            }
          });
        }
        
      }
      if(list == 1) {
      setFilteredTeam(filteredData);
      } else {
      setFiltered(filteredData);
      }
  }, [search]);

    const ListChange = (e:any) => {
      setList(e);
      setTeamId(0);
    }

    const teamsList =  async () => {
      var usersId = currentUser?.id;
      var roleId = currentUser?.designation;
      const Response = await getTeamsList(usersId, roleId)
      setTeams(Response);
      console.log('Response', Response)
  }

  const handleTeam = async (id:any, name:any) => {
    setTeamId(id);
    setReqId(id);
    setUserName(name);
    var body = {
      'start_date': fromDate,
      'end_date': toDate,
      'filter': propertyFunnel,
    }  
      const CountPropertyResponse = await getCountStatusProperty(userId, roleId, id, body, id)
      setCountProperty(CountPropertyResponse);   
  }

    return(
        <>
        <div className="card mx-sm-1 bs_1 br_15 h-100 mb-4">
        <div className="card-heade border-0 d-flex align-items-center justify-content-between flex-wrap px-5 pt-5">
                <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Status</span>
                </h3>
                <div className='d-flex'>
                {roleId != 3 && <>
                <input className="form-select dash_btn m-2 fs-9" type="button" value={userName} id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false"/>
                <ul className="dropdown-menu db_dw_menu" aria-labelledby="defaultDropdown">
                <select className="form-select btn btn-sm btn_secondary" onChange={(e) => ListChange(e.target.value)}>
                    <option value="1">Teams</option>
                    <option value="2">Users</option>
                  </select>
                    <div className="input-group form_search my-3 dropdown-item">
                      <input type="text" className="form-control" name="search" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)}/>
                      <div className="input-group-append">
                        <button className="btn btn-secondary" type="button">
                        <img src={toAbsoluteUrl('/media/custom/header-icons/search.svg')} className="svg_icon" alt='' />
                        </button>
                      </div>
                    </div>
              {list == 1 ? <>
                  {!search
                      ? teams.map((userData, i) => {
                        return(                            
                        <li className="list-group p-4" key={i}>
                          <div className="">
                            <a type="button" onClick={(e) => handleTeam(userData.team_leader, userData.team_leader_name)}>
                                <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                                {userData.team_leader_name}
                            </a>
                            </div>                                  
                        </li>
                        )}) 
                      : filteredTeam.length ? filteredTeam.map((item) => (<li  className="list-group p-4">
                      <div className="">
                              <a type="button" onClick={(e) => handleTeam(item.team_leader, item.team_leader_name)}>
                                <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                                {item.team_leader_name}
                              </a>
                            </div>
                  </li>)) : <p>No Records!!!</p> }
              </> : <>
                {!search
                    ? users.map((item, i) => (
                    <li className="dropdown-item" key={i} onClick={() => userFilter(item.employee_name, item.user_id)}>{item.employee_name}</li>
                    ))
                    : filtered.map((item, j) => (
                      <li className="dropdown-item" key={j} onClick={() => userFilter(item.employee_name, item.user_id)}>{item.employee_name}</li>
                      ))}</>}
                </ul></>}
                <select className="form-select dash_btn m-2 fs-9" onChange={(e) => rangeSelect(e.target.value)}>
                    <option selected value="1">Today</option>
                    <option value="2">Yesterday</option>
                    <option value="3">Last Week</option>
                    <option value="4">Last Month</option>
                    <option value="6">This Month</option>
                    <option value="7">This Year</option>
                    <option value="5">Custom Date</option>
                </select>
                <button type='button' data-bs-toggle='modal' data-bs-target='#propertyFunnelCustomSelect' className='d-none' id='propertyFunnelCustomDateRange'>Custom Date</button>
                <div className='modal fade' id='propertyFunnelCustomSelect' aria-hidden='true'>
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className='modal-content'>
                          <form noValidate onSubmit={formik.handleSubmit}>
                            <div className='modal-header py-2'>
                                <h3>Select Custom Range</h3>
                                <div id='propertyFunnelModelClose' className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                </div>
                            </div>
                            <div className='modal-body py-3 px-lg-10 pb-2'>
                              <div className='row'>                                
                                <div className="col-6 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Start Date</label>
                                    <div className="input-group mb-3">
                                      <input type="date" className="form-control" {...formik.getFieldProps('start_date')}/> 
                                    </div>
                                </div>
                                <div className="col-6 mb-3">
                                    <label htmlFor="basic-url" className="form-label">End Date</label>
                                    <div className="input-group mb-3">
                                      <input type="date" className="form-control" {...formik.getFieldProps('end_date')}/> 
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className='model-footer'>
                              <div className='d-flex align-items-center justify-content-end pb-3 pe-6 pt-0'>
                              <button
                                type='submit'
                                id='kt_sign_up_submit'
                                className='btn btn-sm btn_primary text-primary'
                                disabled={formik.isSubmitting}
                              >
                                {!loading && <span className='indicator-label'>Filter
                                
                                </span>}
                                {loading && (
                                  <span className='indicator-progress' style={{display: 'block'}}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                  </span>
                                )}
                              </button>
                              </div>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
                </div>
            </div>
          <div className="card-body pt-0 px-5">
            <FunnelChart 
                data={CountProperty}
                pallette= {
                  ['#00508f', '#1a6eb2', '#008dff', '#239bfd', '#64b5f6', '#64b5f6', '#b4d3ed']
                }
              />
          </div>
        </div>
        </>
    )
}
export {PropertyFunnel}