import axios, {AxiosResponse}  from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL   

export const GET_CONTACTS_URL = `${API_URL}/get_contact_drop_list`
export const GET_CURRENCY_URL = `${API_URL}/get_currency`
export const GET_COUNTRY_URL = `${API_URL}/get_country`
export const GET_PROJECT_URL = `${API_URL}/get_property_drop_list`
export const GET_PROPERTIES_URL = `${API_URL}/get_properties`
export const GET_PROPERTIES_TL_URL = `${API_URL}/get_properties_tl`
export const GET_PROPERTY_URL = `${API_URL}/get_property`
export const GET_AVAILABLE_FOR_URL = `${API_URL}/get_available_for`
export const GET_PROPERTY_TYPE_URL = `${API_URL}/get_property_type`
export const GET_PROPERTY_STATUS_URL = `${API_URL}/get_property_status`
export const GET_PROPERTY_SOURCE_URL = `${API_URL}/get_property_source`
export const GET_PROPERTY_IN_DEPTH_URL = `${API_URL}/get_property_in_depth`
export const GET_PROPERTY_FACING_URL = `${API_URL}/get_property_facing`
export const GET_AGE_OF_PROPERTY_URL = `${API_URL}/get_age_of_property`
export const GET_PROJECT_STAGE_URL = `${API_URL}/get_project_stage`
export const GET_SPECIFIACTIONS_URL = `${API_URL}/get_specification`
export const GET_SITE_VISIT_PREF_URL = `${API_URL}/get_site_visit_preference`
export const GET_KITCHEN_TYPE_URL = `${API_URL}/get_kitchen_type`
export const GET_OWNERSHIP_URL = `${API_URL}/get_ownership_type`
export const GET_FLOORING_URL = `${API_URL}/get_flooring`
export const GET_VASTHU_COMP_URL = `${API_URL}/get_vasthu_compliant`
export const GET_CITY_URL = `${API_URL}/get_city`
export const GET_SEGMENT_URL = `${API_URL}/get_segment`
export const GET_STATE_URL = `${API_URL}/get_state`
export const GET_ASSIGN_TO_URL = `${API_URL}/get_users`
export const GET_AMENITY_URL = `${API_URL}/get_amenities`
export const GET_UNIT_TYPE_URL = `${API_URL}/get_unit_type`
export const GET_KEY_CUSTODY_URL = `${API_URL}/get_key_custody`
export const UPDATE_PROP_STATUS_URL = `${API_URL}/put_propertyStatus_property`
export const GET_FURNISHING_STATUS_URL = `${API_URL}/get_furnishing_status`
export const GET_POSESSION_STATUS_URL = `${API_URL}/get_posession_status`
export const SAVE_PROPERTY_URL = `${API_URL}/save_property`
export const UPLOAD_FILE_PROPERTY = `${API_URL}/uploadfileProperty`
export const UPDATE_PROPERTY_URL = `${API_URL}/put_property`
export const UPDATE_PROPERTY_ADDRESS_URL = `${API_URL}/put_property_address`
export const UPDATE_PROPERTY_FEATURES_URL = `${API_URL}/put_property_residential`
export const SAVE_PROPERTY_NOTES = `${API_URL}/save_property_notes`
export const GET_PROPERTY_NOTES = `${API_URL}/get_all_property_notes`
export const DELETE_PROPERTY_URL = `${API_URL}/delete_property`
export const DELETE_MULTI_PROPERTY_URL = `${API_URL}/delete_property_multiple`
export const UPLOAD_MULITI_IMAGES_PROPERTY_URL = `${API_URL}/upload_mimages_property`
export const GET_MULITI_IMAGES_PROPERTY_URL = `${API_URL}/get_mimages_property`
export const DELETE_MULITI_IMAGES_PROPERTY_URL = `${API_URL}/delete_mimages_property`
export const UPDATE_PROPERTY_NOTES_URL = `${API_URL}/put_property_notes`
export const DELETE_PROPERTY_NOTES_URL = `${API_URL}/delete_property_notes`
export const GET_LEAD_PROPERTY_URL = `${API_URL}/get_lead_property`
export const GET_TASK_PROPERTY_URL = `${API_URL}/get_task_property`
export const GET_LOGS_URL = `${API_URL}/get_logs`
export const GET_PROP_FEATURES_LIST_URL = `${API_URL}/get_property_unittype`
export const SAVE_PROPERTY_FILTER_URL = `${API_URL}/save_property_filter`
export const GET_PROPERTY_FILTERS_URL = `${API_URL}/get_property_filters`
export const GET_PROPERTY_FILTER_URL = `${API_URL}/get_property_filter`
export const GET_PROPERTY_UNIT_TYPE_URL = `${API_URL}/put_property_unittype`
export const DELETE_PROPERTY_UNIT_TYPE_URL = `${API_URL}/delete_property_unittype`

// property fetch api
export function getProperties(userId:any, roleId:any) {
  return axios.get(GET_PROPERTIES_URL+'/'+userId+'/'+roleId+'/'+userId)
  .then((response => response.data))
}

export function getPropertiesTl(userId:any, roleId:any) {
  return axios.get(GET_PROPERTIES_TL_URL+'/'+userId+'/'+roleId+'/'+userId)
  .then((response => response.data))
}
 
// property dropdown fetch api
export function getProjects(userId:any, roleId:any) {
  return axios.get(GET_PROJECT_URL+'/'+userId+'/'+roleId+'/'+userId)
  .then((response => response.data))
}

// project stage fetch api
export function getCountries() {
  return axios.get(GET_COUNTRY_URL)
  .then((response => response.data))
}

// project stage fetch api
export function getProjectStage() {
  return axios.get(GET_PROJECT_STAGE_URL)
  .then((response => response.data))
}

// Specification fetch api
export function getSpecification() {
  return axios.get(GET_SPECIFIACTIONS_URL)
  .then((response => response.data))
}

// Site visit preference fetch api
export function getSiteVisitPref() {
  return axios.get(GET_SITE_VISIT_PREF_URL)
  .then((response => response.data))
}

// age of property fetch api
export function getAgeOfProperty() {
  return axios.get(GET_AGE_OF_PROPERTY_URL)
  .then((response => response.data))
}

// property fetch api
export function getPropertyType() {
  return axios.get(GET_PROPERTY_TYPE_URL)
  .then((response => response.data))
}

// property fetch api
export function getPropertyStatus() {
  return axios.get(GET_PROPERTY_STATUS_URL)
  .then((response => response.data))
}

// property source fetch api
export function getPropertySource() {
  return axios.get(GET_PROPERTY_SOURCE_URL)
  .then((response => response.data))
}

// property facing fetch api
export function getPropertyFacing() {
  return axios.get(GET_PROPERTY_FACING_URL)
  .then((response => response.data))
}

// property In-depth fetch api
export function getPropertyInDepth() {
  return axios.get(GET_PROPERTY_IN_DEPTH_URL)
  .then((response => response.data))
}

// kitchen type fetch api
export function getKitchenType() {
  return axios.get(GET_KITCHEN_TYPE_URL)
  .then((response => response.data))
}

// flooring fetch api
export function getFlooring() {
  return axios.get(GET_FLOORING_URL)
  .then((response => response.data))
}

// key custody fetch api
export function getKeyCustody() {
  return axios.get(GET_KEY_CUSTODY_URL)
  .then((response => response.data))
}

// vasthu complaint fetch api
export function getVasthuComp() {
  return axios.get(GET_VASTHU_COMP_URL)
  .then((response => response.data))
}

// get asking for
export function getAvailableFor() {
  return axios.get(GET_AVAILABLE_FOR_URL)
  .then((response => response.data))
}

// furnishing status list fetch api
export function getFurnishStatus() {
  return axios.get(GET_FURNISHING_STATUS_URL)
  .then((response => response.data))
}

// possess status list fetch api
export function getPosesStatus() {
  return axios.get(GET_POSESSION_STATUS_URL)
  .then((response => response.data))
}

// furnishing status list fetch api
export function getOwnershipType() {
  return axios.get(GET_OWNERSHIP_URL)
  .then((response => response.data))
}

// update property status api
export function updatePropertyStatus(propertyId:any ,body:any) {
  if(propertyId != null){
    return axios.put(UPDATE_PROP_STATUS_URL+'/'+propertyId, body)
    .then((response => response.data))
  }
}

// update property status api
export function updatePropertyUnitType(body:any) {
    return axios.put(GET_PROPERTY_UNIT_TYPE_URL, body)
    .then((response => response.data))
}

// contact list fetch api
export function getContacts(id:any, role:any) {
  return axios.get(GET_CONTACTS_URL+'/'+id+'/'+role+'/'+id)
  .then((response => response.data))
}

// get segment
export function getSegment() {
  return axios.get(GET_SEGMENT_URL)
  .then((response => response.data))
}

// contact list fetch api
export function getCurrency() {
  return axios.get(GET_CURRENCY_URL)
  .then((response => response.data))
}

// city list fetch api
export function getCity() {
  return axios.get(GET_CITY_URL)
  .then((response => response.data))
}

// state list fetch api
export function getState() {
  return axios.get(GET_STATE_URL)
  .then((response => response.data))
}

// amenity list fetch api
export function getAssignTo() {
  return axios.get(GET_ASSIGN_TO_URL)
  .then((response => response.data))
}

// amenity list fetch api
export function getAmenity() {
  return axios.get(GET_AMENITY_URL)
  .then((response => response.data))
}

// unit type fetch api
export function getUnitType() {
  return axios.get(GET_UNIT_TYPE_URL)
  .then((response => response.data))
}

// property detail fetch api
export function getPropertyDetail(propertyId:any) {
    return axios.get(GET_PROPERTY_URL+'/'+propertyId)
    .then((response => response.data))
}

// save property fetch api
export function saveProperty(postData:any) {
  return axios.post(SAVE_PROPERTY_URL, postData)
  .then((response => response.data))
}

// property update api
export function updateProperty(propertyId:any ,postData:any) {
  if(propertyId != null){
    return axios.put(UPDATE_PROPERTY_URL+'/'+propertyId, postData)
    .then((response => response.data))
  }
}

// property address update api
export function updatePropertyAddress(propertyId:any ,postData:any) {
  if(propertyId != null){
    return axios.put(UPDATE_PROPERTY_ADDRESS_URL+'/'+propertyId, postData)
    .then((response => response.data))
  }
}

// property features update api
export function updatePropertyFeatures(propertyId:any ,postData:any) {
  if(propertyId != null){
    return axios.put(UPDATE_PROPERTY_FEATURES_URL+'/'+propertyId, postData)
    .then((response => response.data))
  }
}

// upload contact
export function uploadFileProperty(postData:any, headers:any) {
  return axios.post(UPLOAD_FILE_PROPERTY,postData, headers)
  .then((response => response.data))
}

// get Task Against Contact Id
export function savePropertyNotes(body:any) {
  return axios.post(SAVE_PROPERTY_NOTES,body)
  .then((response => response.data))
}

// get Task Against Contact Id
export function getPropertyNotes(propertyId:number) {
  if(propertyId != null){
    return axios.get(GET_PROPERTY_NOTES+'/'+propertyId)
    .then((response => response.data))
  }
}

// property features update api
export function deleteProperty(postData:any) {
  return axios.put(DELETE_PROPERTY_URL+'/'+postData)
  .then((response => response.data))
}

// upload property
export function uploadMultipleFileProperty(propertyId:any ,postData:any, headers:any) {
  if(propertyId != null){
    return axios.post(UPLOAD_MULITI_IMAGES_PROPERTY_URL+'/'+propertyId, postData, headers)
    .then((response => response.data))
  }
}

// delete property multiple api
export function deleteMultipleProperty(propertyId:any) {
  if(propertyId != null){
    return axios.put(DELETE_MULTI_PROPERTY_URL+'/'+propertyId)
    .then((response => response.data))
  }
}

// get Task Against Contact Id
export function getMulitiFileProperty(propertyId:number) {
  if(propertyId != null){
    return axios.get(GET_MULITI_IMAGES_PROPERTY_URL+'/'+propertyId)
    .then((response => response.data))
  }
}

// delete property multiple api
export function deleteMultiFileProperty(propertyId:any) {
  if(propertyId != null){
    return axios.put(DELETE_MULITI_IMAGES_PROPERTY_URL+'/'+propertyId)
    .then((response => response.data))
  }
}

// update property notes multiple api
export function updatePropertyNotes(propertyId:any, body:any) {
  if(propertyId != null){
    return axios.put(UPDATE_PROPERTY_NOTES_URL+'/'+propertyId, body)
    .then((response => response.data))
  }
}

// delete property notes multiple api
export function deletePropertyNotes(propertyId:any, userId:any, parentId:any) {
  if(propertyId != null){
    return axios.put(DELETE_PROPERTY_NOTES_URL+'/'+propertyId+'/'+userId+'/'+parentId)
    .then((response => response.data))
  }
}

// delete property notes multiple api
export function deletePropertyUnitType(propertyId:any) {
  if(propertyId != null){
    return axios.put(DELETE_PROPERTY_UNIT_TYPE_URL+'/'+propertyId)
    .then((response => response.data))
  }
}

// get Task Against Contact Id
export function getLeadProperty(propertyId:number) {
  if(propertyId != null){
    return axios.get(GET_LEAD_PROPERTY_URL+'/'+propertyId)
    .then((response => response.data))
  }
}

// get Task Against Contact Id
export function getTaskProperty(propertyId:number) {
  if(propertyId != null){
    return axios.get(GET_TASK_PROPERTY_URL+'/'+propertyId)
    .then((response => response.data))
  }
}

// property log fetch api
export function getLog(propertyId:any) {
  if(propertyId != null){
    return axios.get(GET_LOGS_URL+'/'+propertyId+'/4')
    .then((response => response.data))
  }
}

// property features list fetch api
export function getFeaturesList(propertyId:any) {
  if(propertyId != null){
    return axios.get(GET_PROP_FEATURES_LIST_URL+'/'+propertyId)
    .then((response => response.data))
  }
}

// property fetch api
export function getPropertiesFilter(userId:any, roleId:any, body:any, headers:any) {
  return axios.get(GET_PROPERTIES_URL+'/'+userId+'/'+roleId+'/'+userId+'?available_for='+body.available_for+'&commission_min='+body.commission_min+'&commission_max='+body.commission_max+'&property_type='+body.property_type+'&property_status='+body.property_status+'&country='+body.country+'&age_of_property_min='+body.age_of_property_min+'&property_facing='+body.property_facing+'&gated_community='+body.gated_community+'&vasthu_compliant='+body.vasthu_compliant+'&no_of_units_min='+body.no_of_units_min+'&no_of_units_max='+body.no_of_units_max+'&no_of_floors_min='+body.no_of_floors_min+'&no_of_floors_max='+body.no_of_floors_max+'&rera_registered='+body.rera_registered+'&created_at='+body.created_date+'&property='+body.property)
  .then((response => response.data))
}

// property fetch api
export function getPropertiesSortBy(userId:any, roleId:any, sortBy:any) {
  return axios.get(GET_PROPERTIES_URL+'/'+userId+'/'+roleId+'/'+userId+'?order_by='+sortBy)
  .then((response => response.data))
}

// get Task Against Contact Id
export function savePropertyFilter(body:any) {
  return axios.post(SAVE_PROPERTY_FILTER_URL,body)
  .then((response => response.data))
}

// PropertyFilters fetch api
export function getPropertyFilters(userId:any) {
  return axios.get(GET_PROPERTY_FILTERS_URL+'/'+userId)
  .then((response => response.data))
}

// PropertyFilters fetch api
export function getPropertyFilter(Id:any) {
  return axios.get(GET_PROPERTY_FILTER_URL+'/'+Id)
  .then((response => response.data))
}