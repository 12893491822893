import React,{FC} from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const ThemeBuilder: FC = () => {
    return(
        <div>
            <div className="d-flex flex-column flex-center mb-9">   
                <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
                <h2>Under Construction</h2>
            </div>
            <form action="" className='d-none'>
                <div className="row">
                    <div className="card-group">
                        <div className="col-lg-6 col-sm-6">
                            <div className="card h-100 bs_1 mx-2 mb-2">
                                <div className="card-header d-flex align-items-center">
                                    <h3>Theme Setup</h3>
                                </div>
                                <div className="card-body">
                                    <div className="form-group mb-4">
                                        <label htmlFor="basic-url" className="form-label">Font Family</label>
                                        <select className="form-select btn-sm text-start">
                                            <option>Roboto</option>
                                            <option>Poppins</option>
                                        </select>  
                                    </div>
                                    <div className="form-group d-flex flex-column mb-4">
                                        <label htmlFor="primary" className="form-label">Primary:</label>
                                        <input type="color" id="primary" name="primary" defaultValue="#ff6700"/>
                                    </div>
                                    <div className="form-group d-flex flex-column mb-4">
                                        <label htmlFor="secondary" className="form-label">Secondary:</label>
                                        <input type="color" id="secondary" name="secondary" defaultValue="#e6e6e6"/>
                                    </div>
                                    <div className="form-group d-flex flex-column mb-4">
                                        <label htmlFor="tertiary" className="form-label">Tertiary:</label>
                                        <input type="color" id="tertiary" name="tertiary" defaultValue="#f6f6f6"/>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <button type="submit" className="btn btn_primary">Save</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                            <div className="card h-100 bs_1 mx-2 mb-2">
                                <div className="card-header d-flex align-items-center">
                                    <h3>Module Setting</h3>
                                </div>
                                <div className="card-body">
                                    <div className="form-group d-flex flex-column mb-4">
                                        <label htmlFor="contactColor" className="form-label">Contact:</label>
                                        <input type="color" id="contactColor" name="contactColor" defaultValue="#ff6700"/>
                                    </div>
                                    <div className="form-group d-flex flex-column mb-4">
                                        <label htmlFor="leadColor" className="form-label">Lead:</label>
                                        <input type="color" id="leadColor" name="leadColor" defaultValue="#e6e6e6"/>
                                    </div>
                                    <div className="form-group d-flex flex-column mb-4">
                                        <label htmlFor="projectColor" className="form-label">Project:</label>
                                        <input type="color" id="projectColor" name="projectColor" defaultValue="#f5f8fa"/>
                                    </div>
                                    <div className="form-group d-flex flex-column mb-4">
                                        <label htmlFor="taskColor" className="form-label">Task:</label>
                                        <input type="color" id="taskColor" name="taskColor" defaultValue="#f6f6f6"/>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <button type="submit" className="btn btn_primary">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export {ThemeBuilder}