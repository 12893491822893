import React,{FC, useEffect, useState} from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { useAuth } from '../../modules/auth';
import { getAttendanceTodayCheckin, getTaskStatusContact } from './core/requests';
import { getUsersByRole } from '../settings/userManagement/core/_requests';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import moment from 'moment';

ChartJS.register(ArcElement, Tooltip, Legend);

const initialValues = {
    start_date: "",
    end_date: "",
}

const InTimeList: FC = () => {

  const {currentUser, logout} = useAuth();
  const userId = currentUser?.id;
  const roleId = currentUser?.designation;
  const [search, setSearch] = useState("");
  const [filtered, setFiltered] = useState<any[]>([]);
  const [userChckinList, setUserChckinList] = useState<any[]>([]);
  
  const attendanceList = async () => {
    const rsponse = await getAttendanceTodayCheckin(userId, roleId, userId)
    setUserChckinList(rsponse);
  }

    useEffect(() => { 
      attendanceList();      
    }, []);

    function filterItem(item:any, search:any) {
      if (search.startsWith("@")) {
        const bucket = search.toLowerCase().substring(1).split(":");
        const searchBy = bucket[0];
        const searchFor = bucket[1];
        const searchByType = getType(item[searchBy]);
    
    
        if (!searchFor) return false;
        console.log("**** searchBy: ", searchBy);
        console.log("**** searchFor: ", searchFor);
        console.log("**** item: ", item);
        // When search is @status:error
        if (searchByType == "string") {
          // When search is @status:!error
          if (searchFor.startsWith("!")) {
            const bucket = searchFor.split("!");
            const searchForVal = bucket[1];
            return !item[searchBy].includes(searchForVal);
          }
          return item[searchBy].includes(searchFor);
        }
    
        // When search is @flavors:small..
        if (searchByType == "array") {
          // When search is @flavors:!small
          if (searchFor.startsWith("!")) {
            const bucket = searchFor.split("!");
            const searchForVal = bucket[1];
            return item[searchBy].find((val:any) => !val.includes(searchForVal));
          }
          return item[searchBy].find((val:any) => val.includes(searchFor));
        }
    
        // When search is @tags:org=sales
        if (searchByType == "object") {
          // When search is @tags:!sales
          if (searchFor.startsWith("!")) {
            const bucket = searchFor.split("!");
            const val = bucket[1] || "";
            return !item[searchBy][val];
          }
          // When search is @tags:org!=sales
          if (searchFor.includes("!=")) {
            const bucket2 = searchFor.split("!=");
            const key = bucket2[0];
            const val = bucket2[1] || "";
            return item[searchBy][key] && !item[searchBy][key].includes(val);
          }
          const bucket2 = searchFor.split("=");
          const key = bucket2[0];
          const val = bucket2[1] || "";
          return item[searchBy][key] && item[searchBy][key].includes(val);
        }
      } else {
        // When search is !computer.....
        if (search.startsWith("!")) {
          const bucket = search.split("!");
          const searchFor = bucket[1];
          return !item.title_lower?.includes(searchFor);
        }
        // When search is computer.....
        return item.title_lower?.includes(search);
      }
    }
    
    const getType = (value:any) => {
      if (Array.isArray(value)) {
        return "array";
      } else if (typeof value == "string") {
        return "string";
      } else if (value == null) {
        return "null";
      } else if (typeof value == "boolean") {
        return "boolean";
      } else if (Number(value) == value) {
        return "number";
      } else if (typeof value == "object") {
        return "object";
      }
      return "string";
    };

    useEffect(() => {
      let filteredData:any[] = [];
      if (search.length) {
        userChckinList.forEach((item) => {
          if (filterItem(item, search.toLowerCase())) {
            filteredData.push({ ...item });
          }
        });
      }
      setFiltered(filteredData);
  }, [search]);

    return(
        <>
        <div className="card mx-sm-1 mx-xl-2 bs_1 h-100 br_15 bar_chart intime_list">
          <h3 className='p-5'>Checked-in Today</h3>
          <div className='p-5 pt-0'>
          <input type="text" className="form-control" name="search" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)}/> 
          </div>                   
            <div className='card-body pt-5'>
              {!search ?<>
                {userChckinList.map((Data, i) => {
                  return(
                    <div className='d-flex align-items-center mb-7 bg-light br_10 p-2' key={i}>
                    <div className='symbol symbol-35px me-5'>
                        <span className={Data.status == 1 ? 'symbol-label bg_completed' : Data.status == 2 ? 'symbol-label bg_cancelled' : 'symbol-label bg_inprocess'}>
                        </span>
                    </div>
                    <div className='d-flex justify-content-between w-100'>
                        <a  className='text-dark text-hover-primary fs-6 fw-bolder'>
                        {Data.title}
                        </a>
                        <p className="mb-0">{moment(Data.start).format('hh:mm a')}</p>                    
                    </div>
                    </div>                    
                  )})}</>
                  : <>
                  {filtered.map((Data, i) => {
                  return(
                    <div className='d-flex align-items-center mb-7 bg-light br_10 p-2' key={i}>
                    <div className='symbol symbol-35px me-5'>
                        <span className={Data.status == 1 ? 'symbol-label bg_completed' : Data.status == 2 ? 'symbol-label bg_cancelled' : 'symbol-label bg_inprocess'}>
                        </span>
                    </div>
                    <div className='d-flex justify-content-between w-100'>
                        <a  className='text-dark text-hover-primary fs-6 fw-bolder'>
                        {Data.title}
                        </a>
                        <p className="mb-0">{moment(Data.start).format('hh:mm a')}</p>                    
                    </div>
                    </div>                    
                  )})}
                  </>}
                {/* <div className='d-flex align-items-center mb-7 bg-light br_10 p-2' key={i}>
                    <div className='symbol symbol-35px me-5'>
                        <span className={TaskData.name === 'Completed' ? 'symbol-label bg_completed' : TaskData.name === 'in process' ? 'symbol-label bg_inprocess' : TaskData.name === 'Cancel' ? 'symbol-label bg_cancelled' : 'symbol-label bg_pending'}>
                        </span>
                    </div>
                    <div className='d-flex justify-content-between w-100'>
                        <a  className='text-dark text-hover-primary fs-6 fw-bolder'>
                        {TaskData.name}
                        </a>
                        <p className="mb-0">{TaskData.value}</p> */}
                    
                </div>
        </div>
        </>
    )
}
export {InTimeList}