import React,{FC, useState,useEffect} from 'react'
import {getContactType,getContactStatus,getContactByRole,getLocality,getSource,getNationality,getFilteredContact, getAssignToList, saveContactFilter, getContactFilter, getContactFilterById, getContactGroup, getCity} from './core/_requests'
import { Toast } from 'bootstrap';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Theme, useTheme } from '@mui/material/styles';
import {Link} from 'react-router-dom'
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {useAuth} from '../../../app/modules/auth'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as Yup from 'yup'
import {useFormik} from 'formik'
import Moment from 'moment';
import {getProjects,getProperties} from '../property/core/_requests';



const initialValues = {
    contact_type: '',
    status: '',
    assign_to: [],
    source: '',
    locality: '',
    city: '',
    date_of_birth: '',
    nationality: '',
    contact_group: '',
    created_date: '',
    filter_name: '',
    property: '',
    // updated_date: '',
    include_archived: false,
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, aminityName: string[], theme: Theme) {
  return {
      fontWeight:
      aminityName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
  };
}

const mystyle = {
  padding: "0px",
  height: "34px",
  maxHeight: "200px",
  overflowY: "scroll",
  // display: "block"
};

type Props = {
    setContacts?: any,
    setFiltered?: any,
    
}

const ContactFilter:  FC<Props> = (props) => {
    
  const {
    setContacts, setFiltered
  } = props

    const filterSchema = Yup.object().shape({
        contact_type: Yup.string(),
        status: Yup.string(),
        source: Yup.string(),
        locality: Yup.string(),
        city: Yup.string(),
        date_of_birth: Yup.string(),
        nationality: Yup.string(),
        property: Yup.string(),
        contact_group: Yup.string(),
        created_date: Yup.string(),
        filter_name: Yup.string(),
        // updated_date: Yup.string(),
        include_archived: Yup.bool(),
    });

    const theme = useTheme();
    
    const [loading, setLoading] = useState(false)
    const [save, setSave] = useState(false)
    const [assignTo, setAssignTo] = useState<any[]>([]);
    const {currentUser, logout} = useAuth();
    const [assignToName, setAssignToName] = React.useState<string[]>([]);
    const [assignToId, setAssignToId] = React.useState<string[]>([]);
    const [assignTostr, setAssignTostr] = React.useState<any>('');

    var userId = currentUser?.id;
        var roleId = currentUser?.designation;

    const assingToChange = (event: SelectChangeEvent<typeof assignToName>) => {
        const {
          target: { value },
        } = event;
    
        console.log('assign event');
        console.log(value);
    
        var name = [];
        var id = [];
    
        for(let i = 0; i < value.length; i++){
          var fields = value[i].split('-');
    
          var n = fields[0];
          var d = fields[1];
    
          name.push(n);
          id.push(d);
        }
    
        console.log(name);
        console.log(id);
        var assto = id.join(', ');
    
        setAssignToId(id);
        console.log('id');
        console.log(id);
        setAssignTostr(assto);
    
        setAssignToName(
          typeof value === 'string' ? value.split(',') : value,
        );
      };

      const saveFilter = () => {
        setSave(true);
    }

    const searchFilter = () => {
        setSave(false);
    }

    const resetFilter = async () => {
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;

        console.log('sdasdsd')
        formik.resetForm();
        setAssignTostr([]);
        setAssignToId([]);
        setAssignToName([]);
        document.getElementById('kt_filter_close')?.click();
        var toastEl = document.getElementById('myToastUnFiltered');
        const bsToast = new Toast(toastEl!);
        bsToast.show();
        // const characterResponse = await getContactByRole(userId, roleId);
        // setContacts(characterResponse)
        setFiltered(false)
    }

    const formik = useFormik({
        initialValues,
        validationSchema: filterSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
                setLoading(true)
          var userId = currentUser?.id.toString();
          var roleId = currentUser?.designation.toString();

          try {
            var formBody = {
                'contact_type': values.contact_type.toString(),
                'status': values.status,
                'assign_to': assignToId,
                'source': values.source,
                'locality': values.locality,
                'city': values.city,
                'property': values.property,
                'date_of_birth': values.date_of_birth,
                'nationality': values.nationality,
                'contact_group': values.contact_group,
                'created_date': values.created_date,
                'filter_name': values.filter_name,
                // 'updated_date': values.updated_date,
                'created_by' : userId
            };         
            
            if (save) {
                const saveFilteredContactData = await saveContactFilter(formBody)
            

                if(saveFilteredContactData != null){
                    setLoading(false);
                    // document.getElementById('kt_filter_close')?.click();
                    var toastEl = document.getElementById('myToastFiltered');
                    const bsToast = new Toast(toastEl!);
                    bsToast.show();
                    resetForm();
                    console.log(saveFilteredContactData)
                    ContactFilterList();
                    setAssignToId([]);
                    setAssignToName([]);
                }

            } else {

                const headers = {
                    headers: {
                        "Content-type": "application/json",
                    },                    
                  }

                const saveFilteredContactData = await getFilteredContact(userId, roleId, formBody, headers)

                if(saveFilteredContactData != null){
                    setLoading(false);
                    document.getElementById('kt_filter_close')?.click();
                    var toastEl = document.getElementById('myToastFiltered');
                    const bsToast = new Toast(toastEl!);
                    bsToast.show();
                    resetForm();
                    setAssignToId([]);
                    setAssignToName([]);
                    console.log(saveFilteredContactData)
                    setContacts(saveFilteredContactData)
                    setFiltered(true)
                }
            }    
          }
          catch (error) {
            console.error(error)
            setLoading(false)
          }  
        },
      })

    const [contactType, setContactType] = useState<any[]>([]);
    const [sourceList, setSourceList] = useState<any[]>([]);
    const [statusList, setStatusList] = useState<any[]>([]);
    const [locality, setLocality] = useState<any[]>([]);
    const [nationality, setNationality] = useState<any[]>([]);
    const [contactFilter, setContactFilter] = useState<any[]>([]);
    const [contactGroup, setContactGroup] = useState<any[]>([]);
    const [property, setProperty] = useState<any[]>([]);
    const [city, setCity] = useState<any[]>([]);
    const [filterDetail, setFilterDetail] = useState<{[key: string]: any}>({});

    const contactTypeList =  async () => {   
        const contactTypeResponse = await getContactType()
        setContactType(contactTypeResponse);
    }

    const getStatus =  async () => {   
        const statusResponse = await getContactStatus()
        setStatusList(statusResponse);
    }

    const getSourceList =  async () => {   
        const sourceResponse = await getSource()
        setSourceList(sourceResponse);
    }

    const assignToList =  async () => {   
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;   
        const assignToResponse = await getAssignToList(userId, roleId);
        setAssignTo(assignToResponse);
    } 

    const Localitites =  async () => {   
        const localityResponse = await getLocality()
        setLocality(localityResponse);
    }

    const ContactFilterList =  async () => {   
        const ContactFilterResponse = await getContactFilter(userId)
        setContactFilter(ContactFilterResponse);
    }

    const nationalities =  async () => {   
        const nationalityResponse = await getNationality()
        setNationality(nationalityResponse);
    }

    const groupList =  async () => {
        const groupResponse = await getContactGroup()        
        setContactGroup(groupResponse);
    }

    const CityList =  async () => {
        const CityResponse = await getCity()
        setCity(CityResponse);
    }

    const filterTap =  async (Id : number) => {
        // contactId : number
        setLoading(true);
        const fetchDetails = await getContactFilterById(Id)
        console.log('fetchDetails ----');    
        console.log(fetchDetails);
        setFilterDetail(fetchDetails)          
        // formik.setFieldValue('assign_to', fetchDetails.assign_to ?? '')
        formik.setFieldValue('contact_type', fetchDetails.contact_type ?? '')
        formik.setFieldValue('status', fetchDetails.status ?? '')
        formik.setFieldValue('source', fetchDetails.source ?? '')
        formik.setFieldValue('city', fetchDetails.city ?? '')
        formik.setFieldValue('locality', fetchDetails.locality ?? '')
        formik.setFieldValue('property', fetchDetails.property ?? '')
        formik.setFieldValue('date_of_birth', Moment(fetchDetails.date_of_birth).format("YYYY-MM-DD") ?? '')
        formik.setFieldValue('nationality', fetchDetails.nationality ?? '')
        formik.setFieldValue('contact_group', fetchDetails.contact_group ?? '')
        formik.setFieldValue('created_date', Moment(fetchDetails.created_date).format("YYYY-MM-DD") ?? '')
        // formik.setFieldValue('updated_date', Moment(fetchDetails.updated_date).format("YYYY-MM-DD") ?? '')
        setLoading(false);

        var assignArray = [];
        var assignNameArray = [];
        if(fetchDetails.assign_to != null){
            assignArray = fetchDetails.assign_to.split(",").map((e:any) => {
                return e;
            });
        }
        if(fetchDetails.assign_to_name != null){
            assignNameArray = fetchDetails.assign_to_name.split(",").map((e:any) => {
                return e;                
            });
            // console.log('assignNameArray');
            // console.log(assignNameArray);
        }

        setAssignToId(assignArray)
        console.log("assignArray")
        console.log(assignArray)
        setAssignToName(assignNameArray)
    }    

    const propertyList =  async () => {   
        const Response = await getProperties(userId, roleId)
        setProperty(Response);
    } 

    useEffect(() => {
        contactTypeList();
        getStatus();
        propertyList();
        getSourceList();
        assignToList();
        Localitites();
        nationalities();
        ContactFilterList();
        groupList();
        CityList();
    }, []);

    return(
        <div className='card shadow-none rounded-0 w-100'>
            <div className='card-header w-100 d-flex align-items-center justify-content-between' id='kt_filter_header'>
                <h3 className='card-title fw-bolder text-dark'>Contact Filter</h3>

                <div className='card-toolbar'>
                    {/* <div>
                        <div className="input-group form_search me-3">
                            <input type="text" className="form-control" placeholder="Search"/>
                            <div className="input-group-append">
                                <button className="btn btn-secondary" type="button">
                                <img src={toAbsoluteUrl('/media/custom/header-icons/search.svg')} className="svg_icon" alt='' />
                                </button>
                            </div>
                        </div>
                    </div> */}
                    <button
                    type='button'
                    className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                    id='kt_filter_close'
                    >
                        <img src={toAbsoluteUrl('/media/custom/cancel_black.svg')} className="svg_icon" alt='' />
                    </button>
                </div>
            </div>
                <div className='card-body position-relative' id='kt_filter_body'>
                <form noValidate onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Contact Type</label>
                            <div className="input-group mb-3">
                                <select className="form-select btn btn-sm w-100" {...formik.getFieldProps('contact_type')}>
                                    <option value=''>Select</option>
                                    {contactType.map((contactTypeValue,i)=> {
                                        return (
                                            <option selected={contactTypeValue.id == filterDetail.contact_type} value={contactTypeValue.id} key={i}>{contactTypeValue.name}</option>
                                        )
                                    })} 
                                </select>
                            </div>
                        </div>
                        {/* <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Company Name</label>
                            <div className="input-group mb-3">
                                <select className="btn_secondary btn btn-sm w-100">
                                    <option>All</option>
                                    <option>All</option>
                                </select>
                            </div>
                        </div> */}
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Status</label>
                            <div className="input-group mb-3">
                                <select className="form-select btn btn-sm w-100" {...formik.getFieldProps('status')}>
                                <option value=''>Select</option>
                                    {statusList?.map((statusListValue,i)=> {
                                        return (
                                            <option selected={statusListValue.id == filterDetail.status} value={statusListValue.id} key={i}>{statusListValue.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3 edit_page_form">
                            <label htmlFor="basic-url" className="form-label">Assign To</label>
                            {/* <div className="input-group mb-3">
                                <select className="form-select btn btn-sm w-100" {...formik.getFieldProps('assign_to')}>
                                <option value=''>Select</option>
                                    {assignTo.map((assignToVal,i)=> {
                                        return (
                                            <option selected={assignToVal.id == filterDetail.assign_to} value={assignToVal.id} key={i}>{assignToVal.first_name+' '+assignToVal.last_name}</option>
                                        )
                                    })}
                                </select>
                            </div> */}
                            <FormControl sx={{ m: 0, width: '100%', mt: 0 }}>
                              <Select
                                  multiple
                                  displayEmpty
                                  value={assignToName}
                                  onChange={assingToChange}
                                  input={<OutlinedInput />}
                                  renderValue={(selected) => {
                                    var name = [];
                                    var id = [];

                                    for(let i = 0; i < selected.length; i++){
                                      var fields = selected[i].split('-');

                                      var n = fields[0];
                                      var d = fields[1];

                                      name.push(n);
                                      id.push(d);
                                    }

                                    console.log('selected');
                                    console.log(selected);
                                      if (selected.length === 0) {
                                        return <p>Assign To</p>;
                                      }

                                      return name.join(', ');
                                  }}
                                  className='multi_select_field'
                                  MenuProps={MenuProps}
                                  inputProps={{ 'aria-label': 'Without label' }}
                                  >
                                  <MenuItem disabled value="">
                                      <em>Assign To</em>
                                  </MenuItem>
                                  {assignTo.map((assignVal) => (
                                    <MenuItem
                                      key={assignVal.user_id}
                                      value={assignVal.employee_name+'-'+assignVal.user_id}
                                      style={getStyles(assignVal.employee_name, assignToName, theme)}
                                      >
                                      {assignVal.employee_name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                            {/* <div className="input-group mb-3">
                                <select className="form-select btn btn-sm w-100" {...formik.getFieldProps('assign_to')}>
                                    <option value="1">nirmal</option>
                                    <option value="2">kumar</option>
                                </select>
                            </div> */}
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Source</label>
                            <div className="input-group mb-3">
                                <select className="form-select btn btn-sm w-100" {...formik.getFieldProps('source')}>
                                <option value=''>Select</option>
                                    {sourceList.map((source,i)=> {
                                        return (
                                            <option selected={source.id == filterDetail.source} value={source.id} key={i}>{source.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Property</label>
                            <div className="input-group mb-3">
                            <select className="form-select btn btn-sm w-100" 
                            {...formik.getFieldProps('property')}
                            >
                                <option value=''>select</option>
                                {property.map((value,i)=> {
                                  return (<>
                                    {value.name_of_building && 
                                    <option selected={i == 0 ? true: false} value={value.id} key={i}>{value.name_of_building}</option>}
                                    </>
                                  )
                                })}   
                            </select>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Locality</label>
                            <div className="input-group mb-3">
                                <select className="form-select btn btn-sm w-100" {...formik.getFieldProps('locality')}>
                                <option value=''>Select</option>
                                    {locality.map((localityVal,i)=> {
                                        return (
                                            <option selected={localityVal.id == filterDetail.locality} value={localityVal.id} key={i}>{localityVal.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">City</label>
                            <div className="input-group mb-3">
                                <select className="form-select btn btn-sm w-100" {...formik.getFieldProps('city')}>
                                <option value=''>Select</option>
                                {city.map((city,i)=> {
                                        return (
                                            <option selected={city.id == filterDetail.city} value={city.id} key={i}>{city.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Date of Birth</label>
                            <div className="input-group mb-3">
                                <input type="date" className="form-control" {...formik.getFieldProps('date_of_birth')} placeholder="date"/> 
                            </div>
                        </div>
                        {/* <div className="col-md-6 col-12 mb-3">
                            <div className="row">
                                <div className="col-6">
                                    <label htmlFor="basic-url" className="form-label">Created By</label>
                                    <div className="input-group mb-3">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option>All</option>
                                            <option>All</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="basic-url" className="form-label">Updated By</label>
                                    <div className="input-group mb-3">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option>All</option>
                                            <option>All</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Branch</label>
                            <div className="input-group mb-3">
                                <select className="btn_secondary btn btn-sm w-100">
                                    <option>All</option>
                                    <option>All</option>
                                </select>
                            </div>
                        </div> */}
                        <div className="col-md-6 col-12 mb-3">
                            <div className="row">
                                <div className="col-6">
                                    <label htmlFor="basic-url" className="form-label">Nationality</label>
                                    <div className="input-group mb-3">
                                        <select className="form-select btn btn-sm w-100" {...formik.getFieldProps('nationality')}>
                                        <option value=''>Select</option>
                                            {nationality.map((nationalityVal,i)=> {
                                                // console.log('nationalityVal');
                                                // console.log(nationalityVal.id);
                                                // console.log('nationality');
                                                // console.log(filterDetail.nationality);
                                                return (
                                                    <option selected={nationalityVal.id == filterDetail.nationality} value={nationalityVal.id} key={i}>{nationalityVal.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="basic-url" className="form-label">Group</label>
                                    <div className="input-group mb-3">
                                        <select className="form-select btn btn-sm w-100" {...formik.getFieldProps('contact_group')}>
                                        <option value=''>Select</option>
                                        {contactGroup.map((contactGrp,i)=> {
                                            
                                                return (
                                            // <option value='1'>pending</option>
                                            <option selected={contactGrp.id == filterDetail.contact_group} value={contactGrp.id} key={i}>{contactGrp.name}</option>)
                                            
                                        })}                                            
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Status Changed By</label>
                            <div className="input-group mb-3">
                                <select className="btn_secondary btn btn-sm w-100">
                                    <option>All</option>
                                    <option>All</option>
                                </select>
                            </div>
                        </div> */}
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Created Date</label>
                            <div className="input-group mb-3">
                                <input type="date" className="form-control" {...formik.getFieldProps('created_date')} placeholder="date"/> 
                            </div>
                        </div>
                        {/* <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Updated Date</label>
                            <div className="input-group mb-3">
                                <input type="date" className="form-control" {...formik.getFieldProps('updated_date')} placeholder="date"/> 
                            </div>
                        </div> */}
                        {/* <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Last Note Date</label>
                            <div className="input-group mb-3">
                                <input type="date" className="form-control" placeholder="date"/> 
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Status Changed Date</label>
                            <div className="input-group mb-3">
                                <input type="date" className="form-control" placeholder="date"/> 
                            </div>
                        </div> */}
                    </div>
                    <div className='card-footer py-5 text-center filter_footer d-flex flex-column flex-md-row align-items-center justify-content-between' id='kt_filter_footer'>
                    <div className="mb-3 mb-md-0">
                        <div className="form-check d-flex">
                            <input className="form-check-input" type="checkbox" value="" id="filterCheck"/>
                            <label className="form-check-label mx-3" htmlFor="filterCheck">
                                Include Archived Records
                            </label>
                        </div>
                    </div>
                    <div className="mb-3 mb-md-0">
                        <button data-bs-toggle='modal'
                            data-bs-target={'#contact_filter_save_popup'} className='btn btn-sm btn_soft_primary save_btn p-3 pr-0 mx-3 br_10' title="Save" type='button'>
                            <KTSVG
                                path='/media/custom/save_orange.svg'
                                className='svg-icon-3 svg-icon-primary me-0'
                            />
                            {/* <i className="far fa-save mx-auto"></i> */}
                        </button>
                        <button className='btn btn-sm reset_btn mx-3 br_10' type='button' onClick={resetFilter}>
                            <i className="fas fa-undo"></i>
                            Reset
                        </button>
                        <button className='btn btn-sm btn_primary mx-3 br_10' type='submit' onClick={searchFilter}>
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="currentColor"></path></svg>
                            {/* <i className="fas fa-search text-white"></i> */}
                            Filter
                        </button>
                    </div>
                    <div className='modal fade' id={'contact_filter_save_popup'} aria-hidden='true'>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className='modal-content'>
                                <div className='modal-header'>
                                    <h3>Want to Save Filter?</h3>
                                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                    </div>
                                </div>
                                <div className='modal-body py-lg-10 px-lg-10'>
                                    <div className="mb-3 text-start">
                                        <label htmlFor="basic-url" className="form-label">Filter Name</label>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" {...formik.getFieldProps('filter_name')} placeholder=""/> 
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-end'>
                                        <button type='button' className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal'>
                                            No
                                        </button>
                                        <button type='submit' className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal' onClick={saveFilter}>
                                            Yes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            {contactFilter.length > 0
            ? <div className='row'>
                <div className='col-12'>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Saved Filters
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <div className='row'>
                                {contactFilter.map((Data, i) => {
                                return(
                                    <div className='col-xxl-4'>
                                        <div onClick={(e) => filterTap(Data.id)} className="d-flex cursor_pointer align-items-center bg_soft rounded py-5 px-4 mb-7">
                                            {/* <KTSVG
                                                path='/media/icons/duotune/general/gen040.svg'
                                                className='svg-icon-1 cursor_pointer bg-white rounded-circle svg-icon-danger me-3'
                                            />  */}
                                            <div className="flex-grow-1">
                                                <a href="#" className="fw-bold text-gray-800 text-hover-primary fs-6">{Data.filter_name}</a>
                                                <span className="text-muted fw-semibold d-block">{Moment(Data.created_at).format("DD-MMMM-YYYY HH:mm")}</span>
                                            </div>
                                            {/* <span className="fw-bold text-success py-1">+50%</span> */}
                                        </div>
                                    </div>
                                    // <div>{Data.id}</div>
                                )})}
                            </div>
                        </div>
                    </div>
                </div>
                    
                </div>
            </div> : <></>
            }
            
            {/* <div className='mt-3 overflow-scroll'>
            <table className='table border role_table sort_table_size'>
                <thead>
                <tr className='bg-gray-200'>
                    <td className='ps-5' scope="col">Id</td>
                    <td className='sort_table' scope="col">Status</td>
                    <td className='sort_table' scope="col">Contact Type</td>
                    <td className='sort_table' scope="col">Assigned to</td>
                    <td className='sort_table' scope="col">Source</td>
                    <td className='sort_table' scope="col">City</td>
                    <td className='sort_table' scope="col">Created Date</td>
                    <td className='sort_table' scope="col">Updated Date</td>
                    <td className='sort_table' scope="col">Action</td>
                </tr>
                </thead>
                <tbody>
                {contactFilter.map((Data, i) => {
                return(
                <tr className='' key={i}>
                    <td className='ps-5' scope="row">{Data.id}</td>
                    <td>{Data.status_name}</td>
                    <td>{Data.contact_type_name}</td>
                    <td>{Data.assign_to}</td>
                    <td>{Data.source_name}</td>
                    <td>{Data.city_name}</td>
                    <td>{Moment(Data.created_at).format("DD-MMMM-YYYY HH:MM")}</td>
                    <td>{Moment(Data.updated_at).format("DD-MMMM-YYYY HH:MM")}</td>
                    <td className='d-flex shrink-0 h-100 w-100 p-3'>
                    <button onClick={(e) => filterTap(Data.id)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"><span className="svg-icon svg-icon-4"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="currentColor"></path></svg></span></button>                                    
                    </td>
                </tr>
                )})}
                </tbody>
            </table>                            
            </div> */}
        </div>                
    </div>
    )
}


export {ContactFilter}