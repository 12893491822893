import React,{FC, useState,useEffect} from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Offcanvas, Toast } from 'bootstrap';
import { getBranch, getPropertyType, getTeam, getTeamEmployees, getTeamLeader, getUsers, saveTeam, updateTeam } from './core/_requests';
import { getProperties,getProjects} from '../../property/core/_requests';
import { useAuth } from '../../../modules/auth';


const initialValues = {
    team_name: "",
    branch_id: "",
    project_id: "",  
    users_e: [],
    users_tl: ""
}

type Props = {
    currentTeamId?: any,
    setDetailClicked?: any,
}

const EditTeam:  FC<Props> = (props) => { 
    
    const {
        currentTeamId, 
        setDetailClicked
      } = props

    const teamsSaveSchema = Yup.object().shape({
        team_name: Yup.string().required('Team Name is required'),
        branch_id: Yup.string(),       
        project_id: Yup.string().required('Project is required'),
        users_tl: Yup.string().required('Team Leader is required'),          
    })

    const [loading, setLoading] = useState(false);    
    const [usersName, setusersName] = useState<string[]>([]);
    const [usersId, setusersId] = useState<any[]>([]);
    const {currentUser, logout} = useAuth();
    const [Users, setUsers] = useState<any[]>([]);
    const [Branch, setBranch] = useState<any[]>([]);
    const [Property, setProperty] = useState<any[]>([]);
    const [leader, setLeader] = useState<any[]>([]);
    const [team, setTeam] = useState<{[key: string]: any}>({});
    var userId = currentUser?.id;
    var roleId = currentUser?.designation;

    const theme = useTheme(); 

    const usersChange = (event: SelectChangeEvent<typeof usersName>) => {
        const {
          target: { value },
        } = event;
    
        // console.log('assign event');
        // console.log(value);
    
        var name = [];
        var id = [];
    
        for(let i = 0; i < value.length; i++){
          var fields = value[i].split('-');
    
          var n = fields[0];
          var d = fields[1];
    
          name.push(n);
          id.push(d);
        }
    
        console.log(name);
        console.log(id);
    
        setusersId(id);
    
        setusersName(
          typeof value === 'string' ? value.split(',') : value,
        );
      };

      function getStyles(name: string, aminityName: string[], theme: Theme) {
        return {
            fontWeight:
            aminityName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
        };
    }

      const teamMembers =  async () => {
        const Response = await getTeam(currentTeamId)
        setTeam(Response);
        
        formik.setFieldValue('team_name', Response.team_name ?? '')
        formik.setFieldValue('branch_id', Response.branch_id ?? '')
        formik.setFieldValue('project_id', Response.project_id ?? '')
        formik.setFieldValue('users_tl', Response.users_tl ?? '')

        var userArray = [];
        var userNameArray = [];
        if(Response.users_e != null){
            userArray = Response.users_e.split(",").map((e:any) => {
                return parseInt(e);
            });
        }
        if(Response.users_e_name != null){
            userNameArray = Response.users_e_name.split(",").map((e:any) => {
                return e;
            });
        }

        setusersId(userArray)
        setusersName(userNameArray)
    }

    const UsersList =  async () => {
        const Response = await getTeamEmployees()
        setUsers(Response);        
    }
    // const LeaderList =  async () => {
    //     const LeaderResponse = await getTeamLeader()
    //     setLeader(LeaderResponse);
    // }

    const BranchList =  async () => {
        const BranchResponse = await getBranch()
        setBranch(BranchResponse);
    }

    const PropertyList =  async () => {
        const PropertyResponse = await getProperties(userId, roleId)
        setProperty(PropertyResponse);
    } 

    const formik = useFormik({
        initialValues,
        validationSchema: teamsSaveSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
          try {
              
            const body = {
                "team_name" : values.team_name,
                "branch_id" : values.branch_id,
                "project_id" : values.project_id,
                "users_e" : usersId,
                "users_tl" : values.users_tl,                
            }                
          
            const saveTeamData = await updateTeam(currentTeamId, body);
           
            // document.getElementById('kt_contact')?.classList.remove('drawer-on');
            if(saveTeamData != null){
                setLoading(false);
                document.getElementById('kt_team_edit_close')?.click();
                var toastEl = document.getElementById('myToastAdd');
                const bsToast = new Toast(toastEl!);
                bsToast.show();
                closeDialogue();
                resetForm();
                setusersName([]);
            }
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
    }})

    const closeDialogue = () => {
        setDetailClicked(false);
        var offCanvasEl = document.getElementById('kt_expand'+currentTeamId);
        offCanvasEl?.classList.add('invisible');
        const bsOffcanvas = new Offcanvas(offCanvasEl!);
        bsOffcanvas.hide();
    }   

    useEffect(() => {
        UsersList();
        BranchList();
        PropertyList();
        // LeaderList();
        teamMembers();
    }, []);

    return(
        <><div className='card shadow-none w-xl-50 w-lg-75 w-md-100'>
            <div className='card-header d-flex justify-content-between align-items-center w-100' id='kt_team_header'>
                <h3 className='card-title fw-bolder text-dark'>Edit Team</h3>

                <div className='card-toolbar'>
                    <button
                        type='button'
                        className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                        id='kt_team_edit_close' onClick={closeDialogue}
                    >
                        <img src={toAbsoluteUrl('/media/custom/cancel_black.svg')} className="svg_icon" alt='' />
                        {/* <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' /> */}
                    </button>
                </div>
            </div>
            <form noValidate onSubmit={formik.handleSubmit}>
                <div className='card-body position-relative' id='kt_team_body'>
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Team Details
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="row">
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Team Name</label>
                                            <div className="input-group mb-3 input_prepend">
                                                <input type="text" className="form-control" {...formik.getFieldProps('team_name')} placeholder="Enter Team Name" />
                                            </div>
                                            {formik.touched.team_name && formik.errors.team_name && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.team_name}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Branch Name</label>
                                            <div className="input-group mb-3">
                                                <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('branch_id')}>
                                                    <option value=''>Select</option>
                                                    {Branch.map((Branchobj, i) => {
                                                        return (
                                                            <option selected={i == 0 ? true : false} value={Branchobj.id} key={i}>{Branchobj.name}</option>

                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            {/* {formik.touched.branch_id && formik.errors.branch_id && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.branch_id}</span>
                                                    </div>
                                                </div>
                                            )} */}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Project</label>
                                            <div className="input-group mb-3">
                                                <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('project_id')}>
                                                    <option disabled value=''>Select</option>
                                                    {Property.map((Propertyobj, j) => {
                                                        return (
                                                            <option selected={j == 0 ? true : false} value={Propertyobj.id} key={j}>{Propertyobj.name_of_building}</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            {formik.touched.project_id && formik.errors.project_id && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.project_id}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Team Leader</label>
                                            <div className="input-group mb-3">
                                                <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('users_tl')}>
                                                    <option value=''>Select</option>
                                                    {leader.map((leaderobj, j) => {
                                                        return (
                                                            <option selected={j == 0 ? true : false} value={leaderobj.id} key={j}>{leaderobj.first_name} {leaderobj.last_name}</option>

                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            {formik.touched.users_tl && formik.errors.users_tl && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.users_tl}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Team Members</label>
                                            <FormControl sx={{ m: 0, width: 300, mt: 0 }}>
                                                <Select
                                                    multiple
                                                    displayEmpty
                                                    value={usersName}
                                                    onChange={usersChange}
                                                    input={<OutlinedInput />}
                                                    renderValue={(selected) => {
                                                        var name = [];
                                                        var id = [];

                                                        for (let i = 0; i < selected.length; i++) {
                                                            var fields = selected[i].split('-');

                                                            var n = fields[0];
                                                            var d = fields[1];

                                                            name.push(n);
                                                            id.push(d);
                                                        }

                                                        // setAssignToId(id);
                                                        if (selected.length === 0) {
                                                            return <p>Users</p>;
                                                        }

                                                        return name.join(', ');
                                                    } }
                                                    className='multi_select_field'

                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem disabled value="">
                                                        <em>Users</em>
                                                    </MenuItem>
                                                    {Users.map((Usersobj) => {
                                                        return (
                                                            <MenuItem
                                                                className=''
                                                                key={Usersobj.id}
                                                                value={Usersobj.first_name + ' ' + Usersobj.last_name + '-' + Usersobj.id}
                                                                style={getStyles(Usersobj.first_name+' '+Usersobj.last_name, usersName, theme)}
                                                            >
                                                                {Usersobj.first_name + ' ' + Usersobj.last_name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-footer py-5 text-center' id='kt_task_footer'>
                    <button
                        type='submit'
                        id='kt_add_teams_submit'
                        className='btn btn_primary text-primary'
                        disabled={formik.isSubmitting}
                    >
                        {!loading && <span className='indicator-label'>Submit
                            <KTSVG
                                path='/media/custom/save_white.svg'
                                className='svg-icon-3 svg-icon-primary ms-2' />
                        </span>}
                        {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
            </form>
        </div>
        <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastAdd">
                <div className="toast-header">
                    <strong className="me-auto">Success</strong>
                    <button aria-label="Close" className="btn-close"
                        data-bs-dismiss="toast" type="button">
                    </button>
                </div>
                <div className="toast-body">
                    Contact Filtered Reset Successfully!
                </div>
            </div></>
    )
}


export {EditTeam}