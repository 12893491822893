import React,{FC, useState, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import { TransactionDetails } from './transactionDetails';
import { TransactionForm } from './transactionForm';

type Props = {
  setContactList?: any,
  
}

const TransactionDrawer: FC<Props> = (props) => {
  const {
   setContactList
  } = props

  return(
  <div>

    {/* Add Contact Drawer */}
    <div
        id='kt_transaction'
        className='bg-white'
        data-kt-drawer='true'
        data-kt-drawer-name='transaction'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'100%', 'md': '700px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#kt_transaction_toggle'
        data-kt-drawer-close='#kt_transaction_close'
      >
        <TransactionForm />
    </div>
    
  </div>
  )
}

export {TransactionDrawer}
// export default AddContact