import React,{FC} from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { positions } from '@mui/system';
import { right } from '@popperjs/core';

ChartJS.register(ArcElement, Tooltip, Legend);




export const data2 = {
    labels: ['Google Ads', 'MagicBricks', 'FaceBook', 'Instagram', 'Newspaper', 'Walk-in'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
            '#e27e0c',
            '#ff6700',
            '#208ae6',
            '#03a128',
            '#f37485',
            '#0c4688',
        ],
        borderColor: [
            '#fff',
            '#fff',
            '#fff',
            '#fff',
            '#fff',
            '#fff',
        ],
        borderWidth: 6,
      },
    ],
  };

  export const options2 = {
    plugins: {
        legend: {
            display: true,
            position: right,
            labels: {
                color: '#000',
                fontSize:'50',
            },
        }
    }
};

  

const DashboardPieChart: FC = () => {
    return(
        // <div>
        <>
        <Doughnut options={options2} data={data2} />
        </>
            // <Doughnut options={options} data={data} />
        // </div>
    )
}
export {DashboardPieChart}