import clsx from 'clsx'
import React, {FC} from 'react'
import { useLocation } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import { useLayout } from '../../../_metronic/layout/core'
import {useAuth} from '../../../app/modules/auth';
import { saveAttendanceCheckin } from './core/requests';
import { Toast } from 'bootstrap';


const DashboardToolbar: FC = () => {

  const {classes} = useLayout()
  const {currentUser, logout} = useAuth();
  let location = useLocation();
 
  var roleId = currentUser?.designation;
  var userId = currentUser?.id;

//   const isMenu = location.pathname.includes('menu');
  const isMenu = true;

  return (
    <div className='toolbar d-flex align-items-end' id='kt_toolbar'>
      
      {/* begin::Container */}
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
      >
        {/* <DefaultTitle /> */}

        <div  className="menu_bar d-flex align-items-center justify-content-end w-100">
          {isMenu &&
              <>
              <div className='d-flex button_bar'>
                {/* <a className="me-4 btn btn-sm me-4 d-none" id='kt_lead_toggle'>Add+</a>
                <a className="me-4 btn btn-sm me-4 d-none" id='kt_task_toggle'>Add+</a>
                <a className="me-4 btn btn-sm me-4" id='kt_contact_import_toggle'>Import+</a>
                <select className="form-select me-4 btn btn-sm" name="sort" id="sort" onChange={(e) => {sortByOnChange(e.target.value)}}>
                  <option value="">Sort By</option>
                  <option value="created_at|asc">Created Ascending</option>
                  <option value="created_at|desc">Created Descending</option>
                  <option value="updated_at|asc">Updated Ascending</option>
                  <option value="updated_at|desc">Updated Descending</option>
                  <option value="first_name|asc">FirstName A - Z</option>
                  <option value="first_name|desc">FirstName Z - A</option>
                </select>
                <select className="form-select me-4 btn btn-sm" name="views" id="views" onChange={layoutOnChange}>
                    <option value="grid">Grid View</option>
                    <option value="list">List View</option>
                </select>
                <select className="form-select me-4 btn btn-sm" name="type" id="type">
                  <option value="default">Type</option>
                  <option value="date">Residential</option>
                  <option value="dob">Commercial</option>
                  <option value="Plot">Plot</option>
                  <option value="developer">Developer</option>
                </select>
                <select className="form-select me-4 btn btn-sm" name="action" onChange={actionChange} id="action">
                  <option value="default">Action</option>
                  {roleId == 1 && (<option value="delete">Delete</option>)}
                  <option value="archive">Archive</option>
                  <option value="export">Export</option>
                </select>
                <a href={toAbsoluteUrl('/sheets/contacts.xlsx')} title='Download sample file for import' className="me-4 btn btn-sm me-4 d-block" download="contacts_sheet.xlsx">
                  <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-4 svg-icon-dark me-1'/>
                  Sample File
                </a> */}
              </div>
              {/* <div className="d-flex">
                  <a className="me-4 btn btn-sm me-4" id='kt_filter_toggle'>
                    <KTSVG path='/media/custom/header-icons/filter.svg' className='svg-icon-5 svg-icon-gray-500 me-1'/>
                  </a>
              </div> */}
              </>
          }
        </div>
      </div>
    </div>
  )
}
export {DashboardToolbar}