import React,{FC, useState, useEffect, useCallback, useRef} from 'react'
import ReactDOM from 'react-dom'
import Moment from 'moment';
import { Toast, Offcanvas } from 'bootstrap';
import {getContactDetail,getSecondaryContactsList, getDupContactsList, getContactsDropList ,getLeadContactsList, getContactStatus,getSource, getContactType, getNationality, getLanguage, getPetOwner, getCity, getState, getGender, updateContact, updateContactAdditional, getLog, getTaskList, getDoNotDisturb, uploadMultipleFile, getMaritalStatus, getAssignToList, getContactGroup, updateContactAddress, saveContactNotes, getContactNotes, getContactByRole, getContactFiles, deleteContactFile, deleteContactNotes, updateContactNotes, getDeveloperNameList} from './core/_requests'
import { Theme, useTheme } from '@mui/material/styles';
import {Link} from 'react-router-dom'
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import {useAuth} from '../../../app/modules/auth'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import Dropzone, { useDropzone } from 'react-dropzone'
// import { openDefaultEditor } from 'pintura';

import DataTable from 'react-data-table-component'; 

import { DataGrid,
    GridColDef,
    // GridColumnHeaderParams,
    GridRenderCellParams,
    // GridValueGetterParams
 } from '@mui/x-data-grid';
import {getProjects,getProperties } from '../property/core/_requests';
import { getSegment } from '../settings/masters/core/_requests';
import { Mail } from '../settings/templates/email';
import { getAllTemplatesMail } from '../settings/templates/core/_requests';

 const initialValues = {
    // basic info
    salutation: '',
    first_name: '',
    last_name: '',
    email: '',
    phone_number_type: '',
    mobile: '',
    code: '',
    source: '1',
    property_id: '',
    company_name: '',
    developer_name: '',
    contact_group: '',
    contact_type: '1',
    contact_category: '1',
    remarks: '',
    status: '1',
    assign_to: '',
    // profile_image:'',
    is_secondary_contact: '0',
    secondary_contact_id: '0',
    // additional info
    designation: '',
    do_not_disturb: '',
    marital_status: '',
    gender: '',
    number_of_children: '',
    wedding_anniversary: '',
    nationality: '',
    language: '',
    pet_owner: '',
    dob: '',
    invoice_name: '',
    gst_number: '',
    // address info
    address_1: '',
    address_2: '',
    locality: '',
    // country: '1',
    city: '1',
    state: '1',
    zip_code: '',
    national_id:'',
    facebook: '',
    instagram: '',
    linkedin: '',
    twitter: '',
    reply: '',
    childReply: '',
    title: "",
    subject: "",
    share_with: "",
    module_id: "",
    body: "",
  }

const secContactcolumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70,headerClassName: 'dg_header' },
    { field: 'first_name', headerName: 'First name', width: 150,headerClassName: 'dg_header' },
    { field: 'last_name', headerName: 'Last name', width: 150,headerClassName: 'dg_header' },
    { field: 'email', headerName: 'Email', width: 180,headerClassName: 'dg_header' },
    { field: 'mobile', headerName: 'Phone Number', type: 'number', width: 150,headerClassName: 'dg_header'},
    { field: 'remarks', headerName: 'Remarks', width: 240,headerClassName: 'dg_header' },
    { field: 'created_by', headerName: 'Created By', width: 130,headerClassName: 'dg_header' },
    { field: 'created_at', headerName: 'Created On', width: 180,headerClassName: 'dg_header' },
  ];

const duplicateContactcolumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70,headerClassName: 'dg_header' },
    { field: 'first_name', headerName: 'First name', width: 150,headerClassName: 'dg_header' },
    { field: 'last_name', headerName: 'Last name', width: 150,headerClassName: 'dg_header' },
    { field: 'email', headerName: 'Email', width: 180,headerClassName: 'dg_header' },
    { field: 'mobile', headerName: 'Phone Number', type: 'number', width: 150,headerClassName: 'dg_header'},
    { field: 'remarks', headerName: 'Remarks', width: 240,headerClassName: 'dg_header' },
    { field: 'created_by', headerName: 'Created By', width: 130,headerClassName: 'dg_header' },
    { field: 'created_at', headerName: 'Created On', width: 180,headerClassName: 'dg_header' },
  ];

  const logContactcolumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70,headerClassName: 'dg_header' },
    { field: 'user_id', headerName: 'User ID', width: 100,headerClassName: 'dg_header' },
    { field: 'user_name', headerName: 'User Name', width: 200,headerClassName: 'dg_header' },
    { field: 'module_name_ref', headerName: 'Module Name', width: 150,headerClassName: 'dg_header' },
    { field: 'note', headerName: 'Note', width: 500,headerClassName: 'dg_header' },
    { field: 'created_at', headerName: 'Created At', width: 200,headerClassName: 'dg_header' },
];

const taskListcolumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70,headerClassName: 'dg_header' },
    { field: 'task_type', headerName: 'Task Type', width: 200,headerClassName: 'dg_header' },
    { field: 'priority', headerName: 'Priority', width: 200,headerClassName: 'dg_header' },
    { field: 'task_time', headerName: 'Task Time', width: 200,headerClassName: 'dg_header' },
    { field: 'finish_time', headerName: 'Finish Time', width: 200,headerClassName: 'dg_header' },
    // { field: 'agenda', headerName: 'Agenda', width: 200,headerClassName: 'dg_header' },
    // { field: 'project', headerName: 'Project', width: 200,headerClassName: 'dg_header' },
    // { field: 'contact', headerName: 'Contact', width: 200,headerClassName: 'dg_header' },
    // { field: 'task_note', headerName: 'Task Note', width: 200,headerClassName: 'dg_header' },
    // { field: 'task_status', headerName: 'Task Status', width: 200,headerClassName: 'dg_header' },
    // { field: 'assign_to_name', headerName: 'Assign To', width: 200,headerClassName: 'dg_header' },
    // { field: 'contact_email', headerName: 'Contact Email', width: 200,headerClassName: 'dg_header' },
    // { field: 'contact_mobile', headerName: 'Contact Mobile', width: 200,headerClassName: 'dg_header' },
  ];    
//     renderCell: (params: GridRenderCellParams<Date>) => (
    //     <strong>
    //       <img src={toAbsoluteUrl('/media/custom/user.svg')} alt="" className="table_icon me-2" />
          
    //     </strong>
    //   ),
const leadcolumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 ,headerClassName: 'dg_header'},
    // { field: 'profile_image', headerName: 'Image', width: 150, headerClassName: 'dg_header',
    //     renderCell: (params: GridRenderCellParams<any>) => (
    //         <strong>
    //         <img onError={e => { e.currentTarget.src = process.env.REACT_APP_API_BASE_URL+'uploads/no-image.jpg' }} src={process.env.REACT_APP_API_BASE_URL+'uploads/contacts/profile_image/'+contactUser.id+'/'+contactUser.profile_image} alt="" className="table_icon me-2" />
    //         {params.id}
    //         </strong>
    //     ),
    // },
    { field: 'first_name', headerName: 'First name', width: 150, headerClassName: 'dg_header'},
    { field: 'last_name', headerName: 'Last name', width: 150,headerClassName: 'dg_header' },
    { field: 'email', headerName: 'Email', width: 180, headerClassName: 'dg_header' },
    { field: 'mobile', headerName: 'Phone Number', type: 'number', width: 150, headerClassName: 'dg_header'},
    { field: 'remarks', headerName: 'Remarks', width: 240, headerClassName: 'dg_header'},
    { field: 'status', headerName: 'Status', width: 130, headerClassName: 'dg_header'},
    { field: 'source', headerName: 'Source', width: 130, headerClassName: 'dg_header'},
    { field: 'created_at', headerName: 'Created on', width: 130, headerClassName: 'dg_header'},
    { field: 'assign_to', headerName: 'Assign To', width: 130, headerClassName: 'dg_header'},
    { field: 'fee_oppurtunity', headerName: 'Budget', width: 130, headerClassName: 'dg_header'},
    { field:'Action', renderCell: () => (
        <select className="form-select toggle_white btn_primary rounded-pill btn-sm cursor-pointer status_btn" aria-label="Default select example">
            <option selected>Status</option>
            <option value="1">Pending</option>
            <option value="2">Completed</option>
        </select>
      ),
    }
  ];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const thumbsContainer = {
    display: 'flex',
    // flexDirection: 'row',
    // flexWrap: 'wrap',
    marginTop: 16
  };
  
  const thumb = {
    display: 'inline-flex',
    borderRadius: 5,
    // border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    // boxSizing: 'border-box'
  };
  
  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };
  
  const img = {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  };

  const thumbButton = {
    // position: 'absolute',
    right: 10,
    bottom: 10,
};


function getStyles(name: string, aminityName: string[], theme: Theme) {
  return {
      fontWeight:
      aminityName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
  };
}

const mystyle = {
  padding: "0px",
  height: "34px",
  maxHeight: "200px",
  overflowY: "scroll",
  // display: "block"
};

type Props = {
    contactId?: any,
    setContactList?: any,
    tabInfo?: any,
    sortByOnChange?: any,
    setDetailClicked?: any
}

const ContactDetails: FC<Props> = (props) => {
    const {
        contactId, setContactList, tabInfo, sortByOnChange, setDetailClicked
      } = props

      const theme = useTheme();

      const [loading, setLoading] = useState(false)
      const [replyPress, setReplyPress] = useState(false)
      const {currentUser, logout} = useAuth();
      const [isLoading, setIsLoading] = useState(false);

      const basicFormSchema = Yup.object().shape({
        first_name: Yup.string()
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols'),
        last_name: Yup.string()            
            .max(50, 'Maximum 50 symbols'),
        email: Yup.string()
            .email('Wrong email format'),
        mobile: Yup.string()
            .required('Mobile is required'),
        salutation: Yup.string(),
        phone_number_type: Yup.string(),
        code: Yup.string(),
        source: Yup.string(),
        company_name: Yup.string(),
        developer_name: Yup.string(),
        contact_group: Yup.string(),
        contact_type: Yup.string(),
        contact_category: Yup.string(),
        remarks: Yup.string(),
        status: Yup.string(),
        property_id: Yup.string(),
        assign_to: Yup.string(),
        // profile_image: Yup.string(),
        is_secondary_contact: Yup.string(),
        secondary_contact_id: Yup.string(),
      })

      const additionalFormSchema = Yup.object().shape({
        designation: Yup.string(),
        do_not_disturb: Yup.string(),
        marital_status: Yup.string(),
        gender: Yup.string(),
        number_of_children: Yup.string(),
        wedding_anniversary: Yup.string(),
        nationality: Yup.string(),
        language: Yup.string(),
        pet_owner: Yup.string(),
        dob: Yup.string(),
        invoice_name: Yup.string(),
        gst_number: Yup.string(),
      })

      const notesFormSchema = Yup.object().shape({
        reply: Yup.string(),
      })

      const notesReplyFormSchema = Yup.object().shape({
        childReply: Yup.string(),
      })

      const addressFormSchema = Yup.object().shape({
        address_1: Yup.string(),
        address_2: Yup.string(),
        locality: Yup.string(),
        // country: Yup.string(),
        city: Yup.string(),
        state: Yup.string(),
        zip_code: Yup.string(),
        national_id: Yup.string(),
        // facebook: Yup.string(),
        // instagram: Yup.string(),
        // linkedin: Yup.string(),
        // twitter: Yup.string(),
      })

      const assingToChange = (event: SelectChangeEvent<typeof assignToName>) => {
        const {
          target: { value },
        } = event;
    
        console.log('assign event');
        console.log(value);
    
        var name = [];
        var id = [];
    
        for(let i = 0; i < value.length; i++){
          var fields = value[i].split('-');
    
          var n = fields[0];
          var d = fields[1];
    
          name.push(n);
          id.push(d);
        }
    
        console.log(name);
        console.log(id);
    
        setAssignToId(id);
    
        setAssignToName(
          typeof value === 'string' ? value.split(',') : value,
        );
      };

      const formik = useFormik({
        initialValues,
        validationSchema: basicFormSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          console.log('on presssed');
          setLoading(true)
          try {
            // var formData = new FormData();
    
            var userId = currentUser?.id;
            var roleId = currentUser?.designation;
    
            // formData.append('first_name', values.first_name);
            // formData.append('last_name', values.last_name);
            // formData.append('email', values.email);
            // formData.append('mobile', values.mobile);
            // formData.append('created_by', userId!);
            // formData.append('city', values.city);
            // formData.append('contact_type', values.contact_type);
            // formData.append('source', values.source);
            // formData.append('contact_category', values.contact_category);
            // formData.append('status', values.status);
            // formData.append('national_id', values.national_id);
            // formData.append('nationality', values.nationality);
            // formData.append('dob', values.dob);

            var formBody = {
                'salutation': values.salutation,
                'first_name': values.first_name,
                'last_name': values.last_name,
                'email': values.email,
                'mobile': values.mobile,
                'phone_number_type': values.phone_number_type,
                'assign_to': assignToId.length > 0 ? assignToId: [userId],
                'source': values.source,
                'company_name': values.company_name,
                'developer_name': values.developer_name,
                'code': values.code,
                'contact_group': values.contact_group,
                'contact_type': values.contact_type.toString(),
                'contact_category': values.contact_category,
                'remarks': values.remarks,
                'status': values.status,
                'property_id': values.property_id,
                'is_secondary_contact': values.is_secondary_contact,
                'secondary_contact_id': values.secondary_contact_id,
                // 'profile_image': '',
                'created_by': userId,
            };
            
            const headers = {
              headers: {
                  "Content-type": "application/json",
              },                    
            }
    
            const updateContactData = await updateContact(contactId ,formBody, headers)
    
            console.log('saveContactData');
            console.log(updateContactData);
            // document.getElementById('kt_contact')?.classList.remove('drawer-on');
            if(updateContactData != null){
              setLoading(false);
              setDetailClicked(false);
              document.getElementById('kt_expand_close')?.click();
              document.getElementById('contactReload')?.click();
              var toastEl = document.getElementById('myToastUpdate');
              const bsToast = new Toast(toastEl!);
              bsToast.show();
            //   resetForm();
            }
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
        },
      })

      const formikAdditional = useFormik({
        initialValues,
        validationSchema: additionalFormSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          console.log('on presssed');
          setLoading(true)
          try {
    
            var userId = currentUser?.id;
            var roleId = currentUser?.designation;

            var formBody = {
                'designation': values.designation,
                'do_not_disturb': values.do_not_disturb,
                'marital_status': values.marital_status,
                'gender': values.gender,
                'number_of_children': values.number_of_children,
                'wedding_anniversary': values.wedding_anniversary == "Invalid date" ? "" : values.wedding_anniversary,
                'nationality': values.nationality,
                'language': values.language,
                'pet_owner': values.pet_owner,
                'dob': values.dob == "Invalid date" ? "" : values.dob,
                'invoice_name': values.invoice_name,
                'gst_number': values.gst_number,
                'created_by': userId,
            };
            
            const headers = {
              headers: {
                  "Content-type": "application/json",
              },                    
            }
    
            const updateContactAdditionalData = await updateContactAdditional(contactId ,formBody, headers)
    
            console.log('updateContactAdditionalData');
            console.log(updateContactAdditionalData);
            // document.getElementById('kt_contact')?.classList.remove('drawer-on');
            if(updateContactAdditionalData != null){
              setLoading(false);
              setDetailClicked(false);
              document.getElementById('kt_expand_close')?.click();
              var toastEl = document.getElementById('myToastUpdate');
              const bsToast = new Toast(toastEl!);
              bsToast.show();
              const characterResponse = await getContactByRole(userId, roleId);
              setContactList(characterResponse);
            //   resetForm();
            }
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
        },
      })

      const formikNotes = useFormik({
        initialValues,
        validationSchema: notesFormSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          console.log('on presssed');
          setLoading(true)
          try {
    
            var userId = currentUser?.id;
            var roleId = currentUser?.designation;

            var notesBody = {
                "reply": values.reply,
                "contact_id": contactId,
                "user_id": userId,
                "parent_id": 0
            };
                           
            const updateContactAddressData = await saveContactNotes(notesBody)
    
            console.log('updateContactAddressData');
            console.log(updateContactAddressData);
            if(updateContactAddressData != null){
              setLoading(false);
              var toastEl = document.getElementById('myToastUpdate');
              const bsToast = new Toast(toastEl!);
              bsToast.show();
              resetForm();
              const notesResponse = await getContactNotes(contactId)
              setContactNotes(notesResponse);
            }
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
        },
      })

      const formikNotesReply = useFormik({
        initialValues,
        validationSchema: notesReplyFormSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          console.log('on presssed');
          setLoading(true)
          try {
    
            var userId = currentUser?.id;

            var notesBody = {
                "reply": values.childReply,
                "contact_id": contactId,
                "user_id": userId,
                "parent_id": parentId
            };
                           
            const saveContactNotesData = await saveContactNotes(notesBody)
    
            console.log('saveContactNotesData');
            console.log(saveContactNotesData);
            if(saveContactNotesData != null){
              setLoading(false);
              var toastEl = document.getElementById('myToastUpdate');
              const bsToast = new Toast(toastEl!);
              bsToast.show();
              resetForm();
              const notesResponse = await getContactNotes(contactId)
              setContactNotes(notesResponse);
            }
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
        },
      })

      const formikAddress = useFormik({
        initialValues,
        validationSchema: addressFormSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          console.log('on presssed');
          setLoading(true)
          try {
    
            var userId = currentUser?.id;
            var roleId = currentUser?.designation;

            var formBody = {
                'address_1': values.address_1,
                'address_2': values.address_2,
                'locality': values.locality,
                // 'country': values.country,
                'city': values.city,
                'state': values.state,
                'zip_code':values.zip_code,
                'national_id': values.national_id,
                'facebook': values.facebook,
                'instagram': values.instagram,
                'linkedin': values.linkedin,
                'twitter': values.twitter,
                'created_by': userId,
            };
            
            const headers = {
              headers: {
                  "Content-type": "application/json",
              },                    
            }
    
            const updateContactAddressData = await updateContactAddress(contactId ,formBody, headers)
    
            console.log('updateContactAddressData');
            console.log(updateContactAddressData);
            if(updateContactAddressData != null){
              setLoading(false);
              setDetailClicked(false);
              document.getElementById('kt_expand_close')?.click();
              var toastEl = document.getElementById('myToastUpdate');
              const bsToast = new Toast(toastEl!);
              bsToast.show();
              const characterResponse = await getContactByRole(userId, roleId);
              setContactList(characterResponse);
            }
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
        },
      })

      const onReply = () => {
        setReplyPress(current => !current)
      }

    const replyNotesRef = useRef([]);

    const [assignToName, setAssignToName] = React.useState<string[]>([]);
    const [assignToId, setAssignToId] = React.useState<string[]>([]);
    const [contactDetail, setContactDetail] = useState<{[key: string]: any}>({});
    const [secContactsList, setSecContactsList] = useState<any[]>([]);
    const [leadContactsList, setLeadContactsList] = useState<any[]>([]);
    const [duplicateContactsList, setDuplicateContactsList] = useState<any[]>([]);
    const [contactDrop, setContactDrop] = useState<any[]>([]);
    const [contactGroup, setContactGroup] = useState<any[]>([]);
    const [contactNotes, setContactNotes] = useState<any[]>([]);
    const [assignTo, setAssignTo] = useState<any[]>([]);
    const [logList, setLogList] = useState<any[]>([]);
    const [taskList, setTaskList] = useState<any[]>([]);
    const [firstName, setFirstName] = useState<any>(null);
    const [lastName, setLastName] = useState<any>(null);
    const [getEmail, setGetEmail] = useState<any>(null);
    const [mobile, setMobile] = useState<any>(null);
    const [doNotDisturb, setDoNotDisturb] = useState<any[]>([]);
    const [maritalStatus, setMaritalStatus] = useState<any[]>([]);
    const [language, setLanguage] = useState<any[]>([]);
    const [petOwner, setPetOwner] = useState<any[]>([]);
    const [gender, setGender] = useState<any[]>([]);
    const [locality, setLocality] = useState<any>(null);
    const [nationalId, setNationalId] = useState<any>(null);
    const [contactStatus, setContactStatus] = useState<any[]>([]);
    const [contactCategory, setContactCategory] = useState<any[]>([]);
    const [filesVal, setFilesVal] = useState<any[]>([]);
    const [contactType, setContactType] = useState<any[]>([]);
    const [source, setSource] = useState<any[]>([]);
    const [nationality, setNationality] = useState<any[]>([]);
    const [city, setCity] = useState<any[]>([]);
    const [state, setState] = useState<any[]>([]);
    const [isExpand, setIsExpand] = useState(true);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isFormError, setIsFormError] = useState(false);
    const [isFilesError, setIsFilesError] = useState(false);
    const [secSelected, setSecSelected] = useState(true);
    const [imgFullView, setImgFullView] = useState(false);
    const [imgSelect, setImgSelect] = useState('');
    const [parentId, setParentId] = useState<String>('');
    const [reassign, setReassign] = useState<any[]>([]);
    const [files, setFiles] = useState<any[]>([]);
    const [formFiles, setFormFiles] = useState<any[]>([]);
    const [property, setProperty] = useState<any[]>([]);
    const [developerNameList, setDeveloperNameList] = useState<any[]>([]);
    const [allTemplatesMail, setAllTemplatesMail] = useState<any[]>([]);
    const [filesData, setFilesData] = useState<any>([]);
    const [noteEditVal, setNoteEditVal] = useState<any>('');
    const [search, setSearch] = useState<any>("");
    const [reassignToName, setReassignTo] = useState<any>("");
    const [filtered, setFiltered] = useState<any[]>([]);


    const AllTemplatesMailList =  async () => {
        const AllTemplatesMailResponse = await getAllTemplatesMail()
        setAllTemplatesMail(AllTemplatesMailResponse);
    }

    const MailById = async (id:any) => {
        // setSelectedId(id);
        // const TemplatesMailResponse = await getTemplateMail(id)
        // setTemplatesMail(TemplatesMailResponse);
        // setDataBinded(true);
        // formik.setFieldValue('title', TemplatesMailResponse.title ?? '');
        // formik.setFieldValue('subject', TemplatesMailResponse.subject ?? '');
        // formik.setFieldValue('share_with', TemplatesMailResponse.share_with ?? '');
        // formik.setFieldValue('module_id', TemplatesMailResponse.module_id ?? '');
        // formik.setFieldValue('body', TemplatesMailResponse.body ?? '');
    }

    function filterItem(item: any, search: string) {
        if (search.startsWith("@")) {
          const bucket = search.substring(1).split(":");
          const searchBy = bucket[0];
          const searchFor = bucket[1];
          const searchByType = getType(item[searchBy]);
      
          // When we want to limit the search by provided property types
          // i.e. filterBy: ["tags", "flavors", "ip", ...];
          // if (item.filterBy && item.filterBy.length) {
          //     if (!item.filterBy.includes(searchBy)) return false;
          // }
      
          if (!searchFor) return false;
          console.log("**** searchBy: ", searchBy);
          console.log("**** searchFor: ", searchFor);
          console.log("**** item: ", item);
          // When search is @status:error
          if (searchByType == "string") {
            // When search is @status:!error
            if (searchFor.startsWith("!")) {
              const bucket = searchFor.split("!");
              const searchForVal = bucket[1];
              return !item[searchBy].includes(searchForVal);
            }
            return item[searchBy].includes(searchFor);
          }
      
          // When search is @flavors:small..
          if (searchByType == "array") {
            // When search is @flavors:!small
            if (searchFor.startsWith("!")) {
              const bucket = searchFor.split("!");
              const searchForVal = bucket[1];
              return item[searchBy].find((val: string | any[]) => !val.includes(searchForVal));
            }
            return item[searchBy].find((val: string | any[]) => val.includes(searchFor));
          }
      
          // When search is @tags:org=sales
          if (searchByType == "object") {
            // When search is @tags:!sales
            if (searchFor.startsWith("!")) {
              const bucket = searchFor.split("!");
              const val = bucket[1] || "";
              return !item[searchBy][val];
            }
            // When search is @tags:org!=sales
            if (searchFor.includes("!=")) {
              const bucket2 = searchFor.split("!=");
              const key = bucket2[0];
              const val = bucket2[1] || "";
              return item[searchBy][key] && !item[searchBy][key].includes(val);
            }
            const bucket2 = searchFor.split("=");
            const key = bucket2[0];
            const val = bucket2[1] || "";
            return item[searchBy][key] && item[searchBy][key].includes(val);
          }
        } else {
          // When search is !computer.....
          if (search.startsWith("!")) {
            const bucket = search.split("!");
            const searchFor = bucket[1];
            return !item.title.includes(searchFor);
          }
          // When search is computer.....
          return item.title.includes(search);
        }
      }
      
      const getType = (value:any) => {
        if (Array.isArray(value)) {
          return "array";
        } else if (typeof value == "string") {
          return "string";
        } else if (value == null) {
          return "null";
        } else if (typeof value == "boolean") {
          return "boolean";
        } else if (Number(value) == value) {
          return "number";
        } else if (typeof value == "object") {
          return "object";
        }
        return "string";
      };

    useEffect(() => {
        AllTemplatesMailList();
        var filteredData: any[] = [];
        if (search.length > 0) {
            allTemplatesMail.forEach((item) => {
            if (filterItem(item, search)) {
            filteredData.push({ ...item });
            }
        });
        } else {
            filteredData = [];
        }
        setFiltered(filteredData);        
    }, [search]);
    
    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            // 'image/*': ['.jpeg', '.jpg'],         
            // 'document/*': ['.pdf']
        },
        onDrop: acceptedFiles => {
        console.log(acceptedFiles);
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        setFormFiles(acceptedFiles);
        console.log('files =======');
        var test: any[] = [];
        acceptedFiles.map(file => {
            console.log(file.lastModified);
            let data = {
                "lastModified": file.lastModified,
                // "lastModifiedDate": file.lastModifiedDate,
                "name": file.name,
                "size": file.size,
                "type": file.type,
                // "webkitRelativePath": acceptedFiles.webkitRelativePath
            }            
            console.log(data);
            test.push(data);
            // setFilesData(data);
        });
        console.log(acceptedFiles);
        console.log(test);
        }
    });

    const saveFiles = async () => {
        if(files.length > 0){
            setIsFilesError(false);
            try {

                var formData = new FormData();

                // for(var file in files){
                //     formData.append('uploadfiles', file);
                //     console.log(file);    
                // }

                // formData.append('uploadfiles', JSON.stringify(files));
                
                for (var i = 0; i < files.length; i++) {
                    formData.append('uploadfiles', files[i]);
                    console.log('filessssss')
                    console.log(files[i]);
                }
               
                const headers = {
                    headers: {
                        "Content-type": "multipart/form-data",
                    },                    
                }
                               
                const saveContactFiles = await uploadMultipleFile(contactId, formData, headers)
        
                console.log('saveContactFiles');
                console.log(saveContactFiles);
                if(saveContactFiles != null){
                  setLoading(false);
                  var toastEl = document.getElementById('myToastUpdate');
                  const bsToast = new Toast(toastEl!);
                  bsToast.show();
                  setFiles([]);
                  contactFilesList(contactId);
                //   const notesResponse = await getContactNotes(contactId)
                //   setContactNotes(notesResponse);
                }
        
              } catch (error) {
                console.error(error)
                setLoading(false)
              }   
        }
    
        else {
            setIsFilesError(true);
        }
    }

    const onDeleteFile = async (id:any) => {
        console.log(id);
        const deleteRes = await deleteContactFile(id);
        console.log(deleteRes);
        if(deleteRes != null){
            var toastEl = document.getElementById('myToastUpdate');
            const bsToast = new Toast(toastEl!);
            bsToast.show();
            contactFilesList(contactId);
        }
    }

    const replyDelete = async (id:any, parentId:any) => {
        var userId = currentUser?.id;
        console.log(id);
        console.log(userId);
        const deleteNotes = await deleteContactNotes(id, userId, parentId);
        console.log(deleteNotes);
        if(deleteNotes != null){
            var toastEl = document.getElementById('myToastUpdate');
            const bsToast = new Toast(toastEl!);
            bsToast.show();
            contactNotesList();
            // setTaskNoteList(taskId);
        }
    }

    const replyEdit = async (id:any, val:any) => {
        setParentId(id);
        // let replyVal = (document.getElementById('child_reply'+id) as HTMLInputElement)!.value;
        
        setNoteEditVal(val);
    
    }

    const cancelEdit = async (id:any) => {
        setParentId('');
    }


    const editOnSubmit = async (id:any) => {
        setParentId(id);
        let editVal = (document.getElementById('edit_field'+id) as HTMLInputElement)!.value;
        console.log('sdsdsdsd');
        console.log(editVal);
    
        if(editVal != ''){
            setIsFormError(false);
            try {
                var userId = currentUser?.id;
              
                var notesBody = {
                    "reply": editVal,
                    "user_id": userId
                };
                               
                const editNotesData = await updateContactNotes(id, notesBody)
        
                console.log('editNotesData');
                console.log(editNotesData);
                if(editNotesData != null){
                  setLoading(false);
                  (document.getElementById('edit_field'+id) as HTMLInputElement).value = '';
                  setNoteEditVal('');
                  var toastEl = document.getElementById('myToastUpdate');
                  const bsToast = new Toast(toastEl!);
                  bsToast.show();
                  contactNotesList();
                //   const notesResponse = await getTaskNotes(taskId)
                //   setTaskNoteList(notesResponse);
                }
        
              } catch (error) {
                console.error(error)
                setLoading(false)
              }   
        }
    
        else {
            setIsFormError(true);
        }
    
    }

    const mailSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        subject: Yup.string().required('Subject is required'),       
        share_with: Yup.string().required('Share with is required'),
        module_id: Yup.string().required('Module is required'),       
        body: Yup.string().required('Body is required'),       
    })

    const formikMail = useFormik({
        initialValues,
        validationSchema: mailSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
          try {
              
            const body = {
                "title" : values.title,
                "subject" : values.subject,
                "share_with" : values.share_with,
                "module_id" :values.module_id,
                "body" : values.body,
            }
                
            console.log('lead form body');
            console.log(body);
            
                // const saveTemplatMailData = await saveTemplateMail(body);
            
                // if(saveTemplatMailData != null){
                //     setLoading(false);
                //     var toastEl = document.getElementById('myToastAdd');
                //     const bsToast = new Toast(toastEl!);
                //     bsToast.show();
                //     resetForm();
                //     AllTemplatesMailList();
                // }
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
    }})

    const editImage = (image:any, done:any) => {
        const imageFile = image.pintura ? image.pintura.file : image;
        const imageState = image.pintura ? image.pintura.data : {};
    
        // const editor = openDefaultEditor({
        //     src: imageFile,
        //     imageState,
        // });
    
        // editor.on('close', () => {
            // the user cancelled editing the image
        // });
    
        // editor.on('process', ({ dest, imageState }) => {
        //     Object.assign(dest, {
        //         pintura: { file: imageFile, data: imageState },
        //     });
        //     done(dest);
        // });
    };

    const handleClick = (index:any) => {
        setFiles([
            ...files.slice(0, index),
            ...files.slice(index + 1, files.length)
          ]);
    }

    const thumbs = files.map((file:any, index:any) => {
        const pieces = file.path.split('.');
        const last = pieces[pieces.length - 1];

       return (
        <div style={thumb} key={file.name} className="position-relative">
            <div style={thumbInner}>
                { last != 'pdf' ?
                <img
                src={file.preview}
                className='w-100 h-100 img-thumbnail rounded fit_contain'
                onLoad={() => { URL.revokeObjectURL(file.preview) }}
                /> : <img
                src={toAbsoluteUrl('/media/svg/files/pdf.svg')}
                className='w-100 h-100 img-thumbnail rounded fit_contain'
                />
                }
                
            </div>
            <a onClick={() => handleClick(index)} className='icon position-absolute top-0 end-0 rounded bg-gray border-0'><span className="svg-icon svg-icon-muted"><svg width="" height="30" viewBox="3 3 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
            <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)" fill="currentColor"/>
            <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)" fill="currentColor"/>
            </svg>
            </span></a>
            {/* <button
                style={thumbButton}
                onClick={() =>
                    editImage(file, (output:any) => {
                        const updatedFiles = [...files];

                        // replace original image with new image
                        updatedFiles[index] = output;

                        // revoke preview URL for old image
                        if (file.preview) URL.revokeObjectURL(file.preview);

                        // set new preview URL
                        Object.assign(output, {
                            preview: URL.createObjectURL(output),
                        });

                        // update view
                        setFiles(updatedFiles);
                    })
                }
            >
                Edit
            </button> */}
        </div>
    )});

const FetchContactDetails =  async (contactId : number) => {
    // contactId : number
    setIsLoading(true);
    const fetchDetails = await getContactDetail(contactId)
    console.log('fetchDetails');    
    console.log(fetchDetails);
    setContactDetail(fetchDetails)  
    setFirstName(fetchDetails.first_name)
    setLastName(fetchDetails.last_name)
    setGetEmail(fetchDetails.email)
    setMobile(fetchDetails.mobile)
    setLocality(fetchDetails.locality_name)
    setNationalId(fetchDetails.national_id)
    // basic info binding
    formik.setFieldValue('salutation', fetchDetails.salutation ?? '')
    formik.setFieldValue('first_name', fetchDetails.first_name ?? '')
    formik.setFieldValue('last_name', fetchDetails.last_name ?? '')
    formik.setFieldValue('email', fetchDetails.email ?? '')
    formik.setFieldValue('phone_number_type', fetchDetails.phone_number_type ?? '')
    formik.setFieldValue('code', fetchDetails.code ?? '')
    formik.setFieldValue('mobile', fetchDetails.mobile ?? '')
    formik.setFieldValue('contact_group', fetchDetails.contact_group ?? '')
    formik.setFieldValue('contact_category', fetchDetails.contact_category ?? '')
    formik.setFieldValue('company_name', fetchDetails.company_name ?? '')
    formik.setFieldValue('developer_name', fetchDetails.developer_name ?? '')
    formik.setFieldValue('source', fetchDetails.source ?? '')
    formik.setFieldValue('remarks', fetchDetails.remarks ?? '')
    formik.setFieldValue('status', fetchDetails.status ?? '')
    formik.setFieldValue('property_id', fetchDetails.property_id ?? '')
    formik.setFieldValue('contact_type', fetchDetails.contact_type ?? '')
    formik.setFieldValue('is_secondary_contact', fetchDetails.is_secondary_contact ?? '')
    formik.setFieldValue('secondary_contact_id', fetchDetails.secondary_contact_id ?? '')
    // formik.setFieldValue('city', fetchDetails.city)
    // formik.setFieldValue('national_id', fetchDetails.national_id)
    // formik.setFieldValue('locality', fetchDetails.locality_name)
    
    var assignArray = [];
    var assignNameArray = [];
    if(fetchDetails.assign_to != null){
        assignArray = fetchDetails.assign_to.split(",").map((e:any) => {
            return parseInt(e);
        });
    }
    if(fetchDetails.assign_to_name != null){
        assignNameArray = fetchDetails.assign_to_name.split(",").map((e:any) => {
            return e;
        });
    }

    setAssignToId(assignArray);
    setAssignToName(assignNameArray);
    if(fetchDetails.is_secondary_contact == '0'){
        setSecSelected(false);
    } else {
        setSecSelected(true);
    }

    // additional info binding
    formikAdditional.setFieldValue('designation', fetchDetails.designation ?? '')
    formikAdditional.setFieldValue('do_not_disturb', fetchDetails.do_not_disturb ?? '')
    formikAdditional.setFieldValue('marital_status', fetchDetails.marital_status ?? '')
    formikAdditional.setFieldValue('gender', fetchDetails.gender ?? '')
    formikAdditional.setFieldValue('number_of_children', fetchDetails.number_of_children ?? '')
    formikAdditional.setFieldValue('wedding_anniversary', Moment(fetchDetails.wedding_anniversary ?? '').format('YYYY-MM-DD'))
    formikAdditional.setFieldValue('nationality', fetchDetails.nationality ?? '')
    formikAdditional.setFieldValue('language', fetchDetails.language ?? '')
    formikAdditional.setFieldValue('pet_owner', fetchDetails.pet_owner ?? '')
    formikAdditional.setFieldValue('dob', Moment(fetchDetails.dob ?? '').format('YYYY-MM-DD'))
    formikAdditional.setFieldValue('invoice_name', fetchDetails.invoice_name ?? '')
    formikAdditional.setFieldValue('gst_number', fetchDetails.gst_number ?? '')
    
    // address & social binding
    formikAddress.setFieldValue('address_1', fetchDetails.address_1 ?? '')
    formikAddress.setFieldValue('address_2', fetchDetails.address_2 ?? '')
    formikAddress.setFieldValue('locality', fetchDetails.locality ?? '')
    // formikAddress.setFieldValue('country', fetchDetails.country)
    formikAddress.setFieldValue('city', fetchDetails.city ?? '')
    formikAddress.setFieldValue('state', fetchDetails.state ?? '')
    formikAddress.setFieldValue('zip_code', fetchDetails.zip_code ?? '')
    formikAddress.setFieldValue('national_id', fetchDetails.national_id ?? '')
    formikAddress.setFieldValue('facebook', fetchDetails.facebook ?? '')
    formikAddress.setFieldValue('instagram', fetchDetails.instagram ?? '')
    formikAddress.setFieldValue('linkedin', fetchDetails.linkedin ?? '')
    formikAddress.setFieldValue('twitter', fetchDetails.twitter ?? '')
    setIsLoading(false);
}
const contactStatusList =  async () => {   
    const contactStatusResponse = await getContactStatus()
    setContactStatus(contactStatusResponse);
}
const nationalityList =  async () => {   
    const nationalityResponse = await getNationality()
    setNationality(nationalityResponse);
}
const cityList =  async () => {   
    const cityResponse = await getCity()
    setCity(cityResponse);
} 

const stateList =  async () => {
    const stateResponse = await getState()
    setState(stateResponse);
} 

const doNotDisturbList =  async () => {   
    const doNotDisturbResponse = await getDoNotDisturb()
    setDoNotDisturb(doNotDisturbResponse);
} 

const maritalStatusList =  async () => {   
    const maritalStatusResponse = await getMaritalStatus()
    setMaritalStatus(maritalStatusResponse);
} 

const languageList =  async () => {   
    const languageResponse = await getLanguage()
    setLanguage(languageResponse);
} 

const propertyList =  async () => { 
    var userId = currentUser?.id;
    var roleId = currentUser?.designation;  
    const Response = await getProperties(userId, roleId)
    setProperty(Response);
} 

const petOwnerList =  async () => {   
    const petOwnerResponse = await getPetOwner()
    setPetOwner(petOwnerResponse);
} 

const genderList =  async () => {   
    const genderResponse = await getGender()
    setGender(genderResponse);
} 

const assignToList =  async () => { 
    var userId = currentUser?.id;
    var roleId = currentUser?.designation;   
    const assignToResponse = await getAssignToList(userId, roleId);
    setAssignTo(assignToResponse);
}

const sourceList =  async () => {   
    const sourceResponse = await getSource()
    setSource(sourceResponse);
}

const contactTypeList =  async () => {   
    const contactTypeResponse = await getContactType()
    setContactType(contactTypeResponse);
}

const contactCategoryList =  async () => {   
    const contactCateResponse = await getSegment()
    setContactCategory(contactCateResponse);
}   

const contactFilesList =  async (contactId : number) => {   
    const contactFileResponse = await getContactFiles(contactId)
    setFilesVal(contactFileResponse);
    console.log('files sdsdsd');
    console.log(contactFileResponse);
}   

const minimaximize = () => {
    setIsExpand(current => !current);
}

const fullScreenChange = () => {
    setIsFullScreen(current => !current);
}

const fetchSecContacts = async (contactId : number) => {
    const fetchSecList = await getSecondaryContactsList(contactId);
    setSecContactsList(fetchSecList);
}

const secOnChange = (val:any) => {
    console.log(val)
    formik.setFieldValue('is_secondary_contact', val ?? '');
    if(val == 1){
      setSecSelected(true);
    }
    else {
      setSecSelected(false);
    }
}

const imgViewChange = (id:any) => {
    console.log(id);
    setImgFullView(!imgFullView)
    setImgSelect(id)
}

const replyOnSubmit = async (id:any) => {
    setParentId(id);
    let replyVal = (document.getElementById('child_reply'+id) as HTMLInputElement)!.value;
    console.log('sdsdsdsd');
    console.log(replyVal);

    if(replyVal != ''){
        setIsFormError(false);
        try {
            var userId = currentUser?.id;
            var notesBody = {
                "reply": replyVal,
                "contact_id": contactId,
                "user_id": userId,
                "parent_id": id
            };
                           
            const saveContactNotesData = await saveContactNotes(notesBody)
    
            console.log('saveContactNotesData');
            console.log(saveContactNotesData);
            if(saveContactNotesData != null){
              setLoading(false);
              (document.getElementById('child_reply'+id) as HTMLInputElement).value = ''
              var toastEl = document.getElementById('myToastUpdate');
              const bsToast = new Toast(toastEl!);
              bsToast.show();
              const notesResponse = await getContactNotes(contactId)
              setContactNotes(notesResponse);
            }
    
          } catch (error) {
            console.error(error)
            setLoading(false)
          }   
    }

    else {
        setIsFormError(true);
    }

}

const fetchLeadContacts = async (contactId : number) => {
    const fetchLeadList = await getLeadContactsList(contactId);
    setLeadContactsList(fetchLeadList);
    // console.log('lead contacts');
    // console.log(fetchLeadList);
}

const fetchDuplicateContacts = async (contactId : number) => {
    const fetchDupList = await getDupContactsList(contactId);
    setDuplicateContactsList(fetchDupList);
}

const contactDropList =  async () => {  
    var userId = currentUser?.id;
    var roleId = currentUser?.designation;   
    const contactDropResponse = await getContactsDropList(userId, roleId)
    setContactDrop(contactDropResponse);
} 

const developerNames =  async () => {  
    var userId = currentUser?.id;
    var roleId = currentUser?.designation;   
    const contactDropResponse = await getDeveloperNameList(userId, roleId)
    setDeveloperNameList(contactDropResponse);
} 

const fetchLog = async (contactId : number) => {
    const fetchLogList = await getLog(contactId);
    setLogList(fetchLogList);
}

const fetchTask = async () => {
    const fetchTaskList = await getTaskList(contactId);
    setTaskList(fetchTaskList);
}

const contactGroupList =  async () => {   
    const groupResponse = await getContactGroup()
    setContactGroup(groupResponse);
} 

const contactNotesList =  async () => {   
    const notesResponse = await getContactNotes(contactId)
    setContactNotes(notesResponse);
}

const closeDialogue = () => {
    setDetailClicked(false);
    var offCanvasEl = document.getElementById('kt_expand'+contactId);
    offCanvasEl?.classList.add('invisible');
    const bsOffcanvas = new Offcanvas(offCanvasEl!);
    bsOffcanvas.hide();
    
}

const sendMail = () => {
    // alert('mail')
}
const sendWatsapp = () => {
    // alert('watsapp')
}
const sendSMS = () => {
    // alert('sms')
}
const sendCall = () => {
    // alert('call')
}



useEffect(() => {
    contactId != undefined && FetchContactDetails(contactId)
    fetchSecContacts(contactId)
    fetchLeadContacts(contactId)
    fetchDuplicateContacts(contactId)
    fetchLog(contactId)
    contactStatusList();
    developerNames();
    nationalityList();
    contactDropList();
    doNotDisturbList();
    maritalStatusList();
    genderList();
    languageList();
    petOwnerList();
    assignToList();
    contactGroupList();
    contactTypeList();
    propertyList();
    cityList();
    stateList();
    fetchTask();
    sourceList();
    contactCategoryList();
    contactFilesList(contactId);
    contactNotesList();
    }, [contactId]);
    
    return(
        <div className={isExpand ? isFullScreen ? "w-100 contact_details_page full_screen" : "w-75 contact_details_page full_screen m-5": "contact_details_page small_screen d-flex align-items-end justify-content-end m-5"}>
            {
            isLoading ? 
            <div className="card main_bg h-100">
                <div className='w-100 h-100'>
                    <div className='d-flex justify-content-center flex-column align-items-center vh-100'>
                        <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
                        <div className="spinner-border taskloader" style={{width:"3rem", height: "3rem"}} role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div> 
                </div> 
            </div> :
            isExpand ?
            <div className="card main_bg h-100">
                <div className="card-header d-flex align-items-center">
                    <div className="row w-100">
                        <div className="col-sm-12 col-12 d-flex align-items-center justify-content-end">
                            <div className='card-toolbar'>
                                <button className='btn mx-3 expand_btn' onClick={fullScreenChange}>
                                    <img src={isFullScreen ? toAbsoluteUrl('/media/custom/overview-icons/comp_white.svg') : toAbsoluteUrl('/media/custom/overview-icons/expand_white.svg')} className="svg_icon" alt='' />
                                </button>
                                <button className="btn mx-3 minimize_btn" onClick={minimaximize}>
                                    <img src={toAbsoluteUrl('/media/custom/overview-icons/minimize_white.svg')} className="svg_icon" alt='' />
                                </button>
                                <button
                                    type='button'
                                    id='kt_expand_close'
                                    data-bs-dismiss="offcanvas"
                                    onClick={closeDialogue}
                                    className='btn me-n5 mx-3 close_btn'
                                    >
                                    <img src={toAbsoluteUrl('/media/custom/overview-icons/cancel_white.svg')} className="svg_icon" alt='' />
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* <h3>Contact Full screen</h3> */}
                </div>
               
                <div className="card-body">
                    <div className="row">
                        <div className="card-group">
                            <div className="col-xxl-6 col-12 mb-3">
                                <div className="card bs_1 name_card h-100 mx-2">
                                    <div className="card-body p-3">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0">
                                                {/* <img src={toAbsoluteUrl('/media/avatars/300-23.jpg')} className="user_img" alt='' /> */}
                                                <img onError={e => { e.currentTarget.src = toAbsoluteUrl('/media/custom/buyer.svg') }} src={contactDetail.profile_image ? process.env.REACT_APP_API_BASE_URL+'uploads/contacts/profile_image/'+contactDetail.id+'/'+contactDetail.profile_image : ''} className="user_img" alt='' />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="d-flex">
                                                            <h4 className="mb-0">{contactDetail.first_name+" "+contactDetail.last_name}</h4>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <a href={"mailto:" + contactDetail.email} className="btn_soft_primary"><i className="fas fa-envelope"></i></a>
                                                        <a href={"tel:" + contactDetail.mobile} className="btn_soft_primary"><i className="fas fa-phone-alt"></i></a>
                                                        <a href="#" className="btn_soft_primary"><i className="fas fa-clipboard-list"></i></a>
                                                        <a href={"https://api.whatsapp.com/send?phone="+ contactDetail.mobile} target="new" className="btn_soft_primary">
                                                            <img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} className="svg_icon" alt='' />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-6 col-12 mb-3">
                                <div className="card bs_1 h-100 mx-2 info_card">
                                    <div className="card-body p-3">
                                        <div className='row w-100 p-3'>
                                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 p-2">
                                                <small className="mb-0">Email</small>
                                                <p className="mb-0">{contactDetail.email}</p>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 p-2">
                                                <small className="mb-0">Phone Number</small>
                                                <p className="mb-0">{contactDetail.mobile}</p>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 p-2">
                                                <small className="mb-0">Contact Category</small>
                                                <p className="mb-0">{contactDetail.contact_type_name}</p>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 p-2">
                                                <small className="mb-0">Created By</small>
                                                <p className="mb-0">{contactDetail.created_by_name}</p>
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab_container bg_white br_10 bs_1">
                        <div className="row mt-4">
                            <div className="col-12">
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'overview' ? "nav-link active" : "nav-link"} id="overview-tab" data-bs-toggle="pill" data-bs-target={"#overview"+contactId} type="button" role="tab" aria-controls="overview" aria-selected="true">Overview</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="additional-tab" data-bs-toggle="pill" data-bs-target={"#additional"+contactId} type="button" role="tab" aria-controls="additional" aria-selected="true">Additional Information</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="contactAddress-tab" data-bs-toggle="pill" data-bs-target={"#contactAddress"+contactId} type="button" role="tab" aria-controls="contactAddress" aria-selected="true">Contact Address</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'notes' ? "nav-link active" : "nav-link"} id={"notes-tab"+contactId} data-bs-toggle="pill" data-bs-target={"#notes"+contactId} type="button" role="tab" aria-controls={"notes"+contactId} aria-selected="false">Notes</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'files' ? "nav-link active" : "nav-link"} id={"files-tab"+contactId} data-bs-toggle="pill" data-bs-target={"#files"+contactId} type="button" role="tab" aria-controls={"files"+contactId} aria-selected="false">Files</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'message' ? "nav-link active" : "nav-link"} id={"message-tab"+contactId} data-bs-toggle="pill" data-bs-target={"#message"+contactId} type="button" role="tab" aria-controls={"message"+contactId} aria-selected="false">Messages</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'sec_contact' ? "nav-link active" : "nav-link"} id={"contact-tab"+contactId} data-bs-toggle="pill" data-bs-target={"#contact"+contactId} type="button" role="tab" aria-controls={"contact"+contactId} aria-selected="false">Sec.contact</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'lead' ? "nav-link active" : "nav-link"} id={"lead-tab"+contactId} data-bs-toggle="pill" data-bs-target={"#lead"+contactId} type="button" role="tab" aria-controls={"lead"+contactId} aria-selected="false">Lead</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id={"duplicate-tab"+contactId} data-bs-toggle="pill" data-bs-target={"#duplicate"+contactId} type="button" role="tab" aria-controls={"duplicate"+contactId} aria-selected="false">Duplicate</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={tabInfo == 'task' ? "nav-link active" : "nav-link"} id={"tasks-tab"+contactId} data-bs-toggle="pill" data-bs-target={"#tasks"+contactId} type="button" role="tab" aria-controls={"tasks"+contactId} aria-selected="false">Tasks</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id={"timeline-tab"+contactId} data-bs-toggle="pill" data-bs-target={"#timeline"+contactId} type="button" role="tab" aria-controls={"timeline"+contactId} aria-selected="false">Active timeline</button>
                                    </li>
                                </ul>
                                <div className="tab-content pt-5" id="pills-tabContent">
                                    <div className={tabInfo == 'overview' ? "tab-pane fade show active": "tab-pane fade"} id={"overview"+contactId} role="tabpanel" aria-labelledby="overview-tab">
                                        <form noValidate onSubmit={formik.handleSubmit} >
                                        <div className="row">
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">First Name</label>
                                                <div className="input-group mb-3 input_prepend">
                                                    <select {...formik.getFieldProps('salutation')} className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                                        <option selected={contactDetail.salutation == 'Mr'} value="Mr">Mr</option>
                                                        <option selected={contactDetail.salutation == 'Ms'} value="Ms">Ms</option>
                                                        <option selected={contactDetail.salutation == 'Mrs'} value="Mrs">Mrs</option>
                                                    </select>
                                                    <input required type="text" {...formik.getFieldProps('first_name')} name="first_name" className={clsx(
                                                    'form-control form-control-sm form-control-solid',
                                                    {
                                                        'is-invalid': formik.touched.first_name && formik.errors.first_name,
                                                    },
                                                    {
                                                        'is-valid': formik.touched.first_name && !formik.errors.first_name,
                                                    }
                                                    )} 
                                                    placeholder="Enter First Name" onChange={(e) => {
                                                        setFirstName(e.target.value)
                                                        formik.setFieldValue('first_name', e.target.value ?? '')
                                                    }} value={firstName}/>
                                                </div>
                                                {formik.touched.first_name && formik.errors.first_name && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                    <span role='alert' className='text-danger'>{formik.errors.first_name}</span>
                                                    </div>
                                                </div>
                                                )}
                                            </div> 
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Last Name</label>
                                                <div className="input-group mb-3 input_prepend">
                                                    <input type="text" {...formik.getFieldProps('last_name')} className={clsx(
                                                'form-control form-control-sm form-control-solid',
                                                {
                                                    'is-invalid': formik.touched.first_name && formik.errors.first_name,
                                                },
                                                {
                                                    'is-valid': formik.touched.first_name && !formik.errors.first_name,
                                                }
                                                )}  name="last_name" placeholder="Enter Last Name"  onChange={(e) => {
                                                        setLastName(e.target.value)
                                                        formik.setFieldValue('last_name', e.target.value ?? '')
                                                    }} value={lastName}/>
                                                </div>
                                                {formik.touched.last_name && formik.errors.last_name && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                    <span role='alert' className='text-danger'>{formik.errors.last_name}</span>
                                                    </div>
                                                </div>
                                                )}
                                            </div> 
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Email</label>
                                                <div className="input-group mb-3 input_prepend">
                                                    <input type="text" {...formik.getFieldProps('email')} name="email" className={clsx(
                                                'form-control form-control-sm form-control-solid',
                                                {
                                                    'is-invalid': formik.touched.first_name && formik.errors.first_name,
                                                },
                                                {
                                                    'is-valid': formik.touched.first_name && !formik.errors.first_name,
                                                }
                                                )} placeholder="Enter Email" onChange={(e) => {
                                                        setGetEmail(e.target.value)
                                                        formik.setFieldValue('email', e.target.value ?? '')
                                                    }} value={getEmail}/>
                                                </div>
                                                {formik.touched.email && formik.errors.email && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                    <span role='alert' className='text-danger'>{formik.errors.email}</span>
                                                    </div>
                                                </div>
                                                )}
                                            </div> 
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Phone Number Type</label>
                                                <div className="input-group mb-3">
                                                    <select className="btn btn-sm w-100 text-start form-select"
                                                    {...formik.getFieldProps('phone_number_type')}
                                                    >
                                                        <option >Select Number Type</option>
                                                        <option selected={contactDetail.phone_number_type == 'mobile'} value='mobile'>Mobile</option>
                                                        <option selected={contactDetail.phone_number_type == 'office'} value='office'>Office</option>
                                                    </select>      
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label required">Phone Number</label>
                                                <div className="input-group mb-3 input_prepend">
                                                    <select
                                                    {...formik.getFieldProps('code')}
                                                    className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                                        <option selected value="+91">+91</option>
                                                        <option value="+044">+044</option>
                                                    </select>
                                                    <input type="number" {...formik.getFieldProps('mobile')} name="mobile" className={clsx(
                                                    'form-control form-control-sm form-control-solid',
                                                    {
                                                        'is-invalid': formik.touched.first_name && formik.errors.first_name,
                                                    },
                                                    {
                                                        'is-valid': formik.touched.first_name && !formik.errors.first_name,
                                                    }
                                                    )} placeholder="Enter Number" onChange={(e) => {
                                                        setMobile(e.target.value)
                                                        formik.setFieldValue('mobile', e.target.value ?? '')
                                                    }} value={mobile}/>
                                                </div>
                                                {formik.touched.mobile && formik.errors.mobile && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                    <span role='alert' className='text-danger'>{formik.errors.mobile}</span>
                                                    </div>
                                                </div>
                                                )}
                                            </div> 
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Contact Group</label>
                                                <div className="input-group mb-3">
                                                    <select className="btn btn-sm w-100 text-start form-select" 
                                                    {...formik.getFieldProps('contact_group')}
                                                    >
                                                        <option value="">Select</option>
                                                        {contactGroup.map((contactGroupVal,i)=> {
                                                        return (
                                                            <option selected={contactGroupVal.id == contactDetail.contact_group} value={contactGroupVal.id} key={i}>{contactGroupVal.name}</option>
                                                        )
                                                        })}   
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Contact Type</label>
                                                <div className="input-group mb-3">
                                                <select 
                                                {...formik.getFieldProps('contact_type')} 
                                                name="contact_type" className='btn btn-sm w-100 text-start form-select'>
                                                    <option  >Select</option>
                                                    {contactType.map((contactTypeValue,i)=> {
                                                    return (
                                                        <option selected={contactTypeValue.id == contactDetail.contact_type} value={contactTypeValue.id} key={i}>{contactTypeValue.name}</option>
                                                    )
                                                    })} 
                                                </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Contact Category</label>
                                                <div className="input-group mb-3 input_prepend">
                                                    <select className="btn btn-sm w-100 text-start form-select"
                                                    {...formik.getFieldProps('contact_category')}
                                                    >
                                                        <option value="" >Select</option>
                                                        {contactCategory.map((contactCategoryValue,i) =>{
                                                            return (
                                                                <option value={contactCategoryValue.id} selected={contactCategoryValue.id == contactDetail.city} key={i}>{contactCategoryValue.name}</option> 
                                                        )})}
                                                    </select>
                                                </div>
                                            </div> 
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Company Name</label>
                                                <div className="input-group">
                                                    <input type="text" {...formik.getFieldProps('company_name')} name="company_name" className="form-control form-control-sm form-control-solid" placeholder="Enter Company Name"/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Developer Name</label>
                                                <div className="input-group">
                                                    <input type="text" {...formik.getFieldProps('developer_name')} name="developer_name" className="form-control form-control-sm form-control-solid" placeholder="Enter developer Name"/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Source</label>
                                                <div className="input-group mb-3 input_prepend">
                                                    <select className="btn btn-sm w-100 text-start form-select"
                                                    {...formik.getFieldProps('source')}
                                                    >
                                                        <option value="" >Select</option>
                                                        {source.map((sourceValue,i) =>{
                                                            return (
                                                                <option value={sourceValue.id} selected={sourceValue.id == contactDetail.source} key={i}>{sourceValue.name}</option> 
                                                        )})}
                                                    </select>
                                                </div>
                                            </div> 
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Remarks</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" {...formik.getFieldProps('remarks')} name="remarks" className="form-control" placeholder=""/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3 edit_page_form">
                                                <label htmlFor="basic-url" className="form-label">Assign to</label>
                                                <FormControl sx={{ m: 0, width: '100%', mt: 0 }}>
                                                    <Select
                                                        multiple
                                                        displayEmpty
                                                        value={assignToName}
                                                        onChange={assingToChange}
                                                        input={<OutlinedInput />}
                                                        renderValue={(selected) => {
                                                            var name = [];
                                                            var id = [];

                                                            for(let i = 0; i < selected.length; i++){
                                                            var fields = selected[i].split('-');

                                                            var n = fields[0];
                                                            var d = fields[1];

                                                            name.push(n);
                                                            id.push(d);
                                                            }

                                                            if (selected.length === 0) {
                                                                return <p>Assign To</p>;
                                                            }

                                                            return name.join(', ');
                                                        }}
                                                        className='multi_select_field'
                                                        MenuProps={MenuProps}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        >
                                                        <MenuItem disabled value="">
                                                            <em>Assign To</em>
                                                        </MenuItem>
                                                        {assignTo.map((assignVal) => (
                                                            <MenuItem
                                                            key={assignVal.user_id}
                                                            value={assignVal.employee_name+'-'+assignVal.user_id}
                                                            style={getStyles(assignVal.employee_name, assignToName, theme)}
                                                            >
                                                            {assignVal.employee_name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Status</label>
                                                <div className="input-group mb-3 input_prepend py-1">
                                                    <select className="btn btn-sm w-100 text-start form-select" 
                                                    {...formik.getFieldProps('status')}
                                                    >
                                                        <option value="" >Select</option>
                                                        {contactStatus.map((contactStatusValue,i) =>{
                                                            return (
                                                                <option value={contactStatusValue.id} selected={contactStatusValue.id == contactDetail.status} key={i}>{contactStatusValue.name}</option> 
                                                        )})}
                                                    </select>
                                                </div> 
                                            </div>  
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Property Name</label>
                                                <div className="input-group mb-3 input_prepend py-1">
                                                    <select className="btn btn-sm w-100 text-start form-select" 
                                                    {...formik.getFieldProps('property_id')}
                                                    >
                                                        <option value="" >Select</option>
                                                        {property.map((propertyValue,i) =>{
                                                            return (
                                                                <option value={propertyValue.id} selected={propertyValue.id == contactDetail.property_id} key={i}>{propertyValue.name_of_building}</option> 
                                                        )})}
                                                    </select>
                                                </div> 
                                            </div>  
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Is Secondary Contact</label>
                                                <div className="input-group mb-3 input_prepend">
                                                    <select className="btn btn-sm w-100 text-start form-select" onChange={(e) => secOnChange(e.target.value)}>
                                                        <option selected={contactDetail.is_secondary_contact == '1'} value="1">Yes</option>
                                                        <option selected={contactDetail.is_secondary_contact == '0'} value="0">No</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {/* secondary_contact_id */}
                                            {secSelected &&
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Secondary Contact</label>
                                                <div className="input-group mb-3">
                                                <select className="btn btn-sm w-100 text-start form-select" 
                                                {...formik.getFieldProps('secondary_contact_id')}
                                                >
                                                <option value=''>select secondary contact</option>
                                                {contactDrop.map((contactDropVal,i)=> {
                                                    return (
                                                    <option selected={contactDropVal.id == contactDetail.secondary_contact_id } value={contactDropVal.id} key={i}>{contactDropVal.first_name+ ' '+contactDropVal.last_name}</option>
                                                    )
                                                })}
                                                </select>
                                                </div>
                                            </div>
                                            }
                                            <div className="col-12 d-flex justify-content-center mb-4">
                                                <button type='submit' disabled={formik.isSubmitting} className="btn btn_primary">
                                                    {!loading && <span className='indicator-label'>Save
                                                    <KTSVG
                                                    path='/media/custom/save_white.svg'
                                                    className='svg-icon-3 svg-icon-primary ms-2'
                                                    />
                                                    </span>}
                                                    {loading && (
                                                        <span className='indicator-progress' style={{display: 'block'}}>
                                                        Please wait...{' '}
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                        </form>
                                    </div>
                                    <div className="tab-pane fade" id={"additional"+contactId} role="tabpanel" aria-labelledby="additional-tab">
                                        <form noValidate onSubmit={formikAdditional.handleSubmit} >
                                        <div className="row">
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Designation</label>
                                                <div className="input-group mb-3 input_prepend">
                                                    <input type="text" {...formikAdditional.getFieldProps('designation')} className='form-control form-control-sm form-control-solid' name="designation" placeholder="Enter Designation"/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Do not Disturb</label>
                                                <div className="input-group mb-3">
                                                    <select 
                                                    {...formikAdditional.getFieldProps('do_not_disturb')}
                                                    className="btn btn-sm w-100 text-start form-select">
                                                    <option  >Select</option>
                                                    {doNotDisturb.map((doNotDisturbValue,i)=> {
                                                    return (
                                                        <option selected={contactDetail.do_not_disturb == doNotDisturbValue.id} value={doNotDisturbValue.id} key={i}>{doNotDisturbValue.name}</option>
                                                    )
                                                    })} 
                                                    </select>      
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Marital Status</label>
                                                <div className="input-group mb-3">
                                                    <select 
                                                    {...formikAdditional.getFieldProps('marital_status')}
                                                    className="btn btn-sm w-100 text-start form-select">
                                                    <option  >Select</option>
                                                    {maritalStatus.map((maritalStatusValue,i)=> {
                                                    return (
                                                        <option selected={contactDetail.marital_status == maritalStatusValue.id} value={maritalStatusValue.id} key={i}>{maritalStatusValue.name}</option>
                                                    )
                                                    })} 
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Gender</label>
                                                <div className="input-group mb-3">
                                                    <select 
                                                    {...formikAdditional.getFieldProps('gender')}
                                                    className="btn btn-sm w-100 text-start form-selec">
                                                    <option  >Select</option>
                                                    {gender.map((genderValue,i)=> {
                                                    return (
                                                        <option selected={contactDetail.gender == genderValue.id} value={genderValue.id} key={i}>{genderValue.name}</option>
                                                    )
                                                    })} 
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">No of Children</label>
                                                <div className="input-group mb-3">
                                                    <input type="number" min="0" {...formikAdditional.getFieldProps('number_of_children')} name="number_of_children" className="form-control" placeholder="Enter"/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Wedding Anniversary</label>
                                                <div className="input-group mb-3">
                                                    <input type="date" {...formikAdditional.getFieldProps('wedding_anniversary')} name="wedding_anniversary" className="form-control" placeholder="date"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Nationality</label>
                                                <div className="input-group mb-3 input_prepend">
                                                    <select className="btn btn-sm w-100 text-start form-select"
                                                     {...formikAdditional.getFieldProps('nationality')}>
                                                        <option value="default" disabled>Select</option>
                                                        {nationality.map((nationalityValue,i) =>{
                                                            return (
                                                                <option value={nationalityValue.id} selected={nationalityValue.id == contactDetail.nationality}>{nationalityValue.name}</option> 
                                                        )})}
                                                    </select>
                                                </div>
                                            </div> 
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Language</label>
                                                <div className="input-group mb-3">
                                                    <select 
                                                    {...formikAdditional.getFieldProps('language')}
                                                    className="btn btn-sm w-100 text-start form-select">
                                                    <option  >Select</option>
                                                    {language.map((languageValue,i)=> {
                                                    return (
                                                        <option selected={languageValue.id == contactDetail.language} value={languageValue.id} key={i}>{languageValue.name}</option>
                                                    )
                                                    })} 
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Pet owner</label>
                                                <div className="input-group mb-3">
                                                    <select 
                                                    {...formikAdditional.getFieldProps('pet_owner')}
                                                    className="btn btn-sm w-100 text-start form-select">
                                                    <option  >Select</option>
                                                    {petOwner.map((petOwnerValue,i)=> {
                                                    return (
                                                        <option selected={petOwnerValue.id == contactDetail.pet_owner} value={petOwnerValue.id} key={i}>{petOwnerValue.name}</option>
                                                    )
                                                    })} 
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">DOB</label>
                                                <div className="input-group mb-3">
                                                    <input type="date" {...formikAdditional.getFieldProps('dob')} name="dob"  className="form-control" placeholder="date" /> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Invoice Name</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" {...formikAdditional.getFieldProps('invoice_name')} name="invoice_name" className="form-control" placeholder="Enter invoice name"/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">GST Number</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" {...formikAdditional.getFieldProps('gst_number')} name="gst_number" className="form-control" placeholder="Enter GST No."/>
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center mb-4">
                                                <button type='submit' disabled={formikAdditional.isSubmitting} className="btn btn_primary">
                                                    {!loading && <span className='indicator-label'>Save
                                                    <KTSVG
                                                    path='/media/custom/save_white.svg'
                                                    className='svg-icon-3 svg-icon-primary ms-2'
                                                    />
                                                    </span>}
                                                    {loading && (
                                                        <span className='indicator-progress' style={{display: 'block'}}>
                                                        Please wait...{' '}
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    )}
                                                </button>
                                            </div>

                                        </div>
                                        </form>
                                    </div>
                                    <div className="tab-pane fade" id={"contactAddress"+contactId} role="tabpanel" aria-labelledby="contactAddress-tab">
                                        <form noValidate onSubmit={formikAddress.handleSubmit} >
                                            <div className="row">
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Address 1</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <input type="text" {...formikAddress.getFieldProps('address_1')} name="address_1" className="form-control" placeholder="Address 1"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Address 2</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <input type="text" {...formikAddress.getFieldProps('address_2')} name="address_2" className="form-control" placeholder="Address 2"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Locality</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <input type="text" {...formikAddress.getFieldProps('locality')} name="locality" className="form-control" placeholder="Locality"/>
                                                    </div>
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">City</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <select className={clsx(
                                                            'form-select btn btn-sm w-100 text-start',
                                                            // {
                                                            // 'is-invalid': formikAddress.touched.city && formikAddress.errors.city,
                                                            // },
                                                            // {
                                                            // 'is-valid': formikAddress.touched.city && !formikAddress.errors.city,
                                                            // }
                                                        )}
                                                        {...formikAddress.getFieldProps('city')}
                                                        >
                                                            <option value="" >Select{contactDetail.city}</option>
                                                            {city.map((cityValue,i) =>{
                                                                return (
                                                                    <option value={cityValue.id} selected={cityValue.id == contactDetail.city} key={i}>{cityValue.name}</option>
                                                            )})}
                                                        </select>
                                                    </div>
                                                    {/* {formikAddress.touched.city && formikAddress.errors.city && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                            <span role='alert' className='text-danger'>{formikAddress.errors.city}</span>
                                                            </div>
                                                        </div>
                                                    )} */}
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">State*</label>
                                                    <div className="input-group mb-3">
                                                    <select 
                                                    {...formikAddress.getFieldProps('state')}
                                                    className={clsx(
                                                    'form-select btn btn-sm w-100 text-start',
                                                    // {
                                                    //     'is-invalid': formikAddress.touched.state && formikAddress.errors.state,
                                                    // },
                                                    // {
                                                    //     'is-valid': formikAddress.touched.state && !formikAddress.errors.state,
                                                    // }
                                                    )}
                                                    name="state"
                                                    >
                                                        <option >Select</option>
                                                        {state.map((stateValue,i)=> {
                                                            return (
                                                            <option selected={i == 0 ? true: false} value={stateValue.id} key={i}>{stateValue.name}</option>
                                                            )
                                                        })}
                                                    </select>   
                                                        
                                                    </div>
                                                    {/* {formikAddress.touched.state && formikAddress.errors.state && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formikAddress.errors.state}</span>
                                                        </div>
                                                    </div>
                                                    )} */}
                                                </div>
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Zip Code</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <input type="text" {...formikAddress.getFieldProps('zip_code')} name="zip_code" className="form-control" placeholder="Zip Code"/>
                                                    </div>
                                                </div> 
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">National ID</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <input type="text" {...formikAddress.getFieldProps('national_id')} name="national_id" className="form-control" placeholder="Enter ID" />
                                                    </div>
                                                </div> 

                                                <hr/>
                                                <h3 className='my-3'>Social Links</h3>
                                                
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Facebook</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <input type="text" {...formikAddress.getFieldProps('facebook')} name="facebook" className="form-control" placeholder="Facebook"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Instagram</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <input type="text" {...formikAddress.getFieldProps('instagram')} name="instagram" className="form-control" placeholder="Instagram"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Linkedin</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <input type="text" {...formikAddress.getFieldProps('linkedin')} name="linkedin" className="form-control" placeholder="Linkedin"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Twitter</label>
                                                    <div className="input-group mb-3 input_prepend">
                                                        <input type="text" {...formikAddress.getFieldProps('twitter')} name="twitter" className="form-control" placeholder="Twitter"/>
                                                    </div>
                                                </div>
                                                <div className="col-12 d-flex justify-content-center mb-4">
                                                    <button type='submit' disabled={formikAddress.isSubmitting} className="btn btn_primary">
                                                        {!loading && <span className='indicator-label'>Save
                                                        <KTSVG
                                                        path='/media/custom/save_white.svg'
                                                        className='svg-icon-3 svg-icon-primary ms-2'
                                                        />
                                                        </span>}
                                                        {loading && (
                                                            <span className='indicator-progress' style={{display: 'block'}}>
                                                            Please wait...{' '}
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                            </span>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className={tabInfo == 'notes' ? "tab-pane fade show active": "tab-pane fade"} id={"notes"+contactId} role="tabpanel" aria-labelledby={"notes-tab"+contactId}>                                    
                                        <div className="card mb-5 mb-xl-8">
                                            <div className='card-body py-0 px-2'>
                                                <div className='main_bg px-lg-5 px-4 pt-4 pb-1 mb-6'>
                                                    <div className='d-flex align-items-center mb-3'>
                                                    
                                                        {/* begin::User */}
                                                        <div className='d-flex align-items-center flex-grow-1'>
                                                            {/* begin::Info */}
                                                            <div className='d-flex flex-column'>
                                                                <h3 className='text-gray-800 text-hover-primary fs-6 fw-bolder'>
                                                                    Add Note
                                                                </h3>
                                                            </div>
                                                            {/* end::Info */}
                                                        </div>
                                                        {/* end::User */}
                                                    </div>
                                                    <form noValidate onSubmit={formikNotes.handleSubmit}>
                                                        <div className='position-relative mb-3 pb-4 border-bottom border-secondary'>
                                                            <input {...formikNotes.getFieldProps('reply')}
                                                                className='form-control border-0 p-0 pe-10 resize-none min-h-25px'
                                                                data-kt-autosize='true'
                                                                // rows={1}
                                                                placeholder='Reply..'
                                                            ></input>
                                                            <div className='position-absolute top-0 end-0 me-n5'>
                                                                {/* <span className='btn btn-icon btn-sm btn-active-color-primary pe-0 me-2'>
                                                                <KTSVG
                                                                    path='/media/icons/duotune/communication/com012.svg'
                                                                    className='svg-icon-3 mb-3'
                                                                />
                                                                </span> */}

                                                                <button type='submit' disabled={formikNotes.isSubmitting} className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                    <KTSVG path='/media/icons/duotune/general/gen016.svg' className='svg-icon-2 mb-3' />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        {formikNotes.touched.reply && formikNotes.errors.reply && (
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>
                                                                <span role='alert' className='text-danger'>{formikNotes.errors.reply}</span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </form>
                                                </div>
                                                <div className='notes_list mt-4 card-body'>
                                                    <h4 className='mb-3'>Notes List</h4>
                                                    <hr/>
                                                    {contactNotes.map((contactNote, i) => {
                                                        return (
                                                    <div className='mb-5' key={contactNote.id}>
                                                        {contactNote.reply1 == 'NO'
                                                        ? <div className='note_question'>
                                                            {/* begin::Header */}
                                                            <div className='d-flex align-items-center mb-3'>
                                                                {/* begin::User */}
                                                                <div className='d-flex align-items-center flex-grow-1'>
                                                                    {/* begin::Avatar */}
                                                                    <div className='symbol symbol-45px me-5'>
                                                                    <img onError={e => { e.currentTarget.src = toAbsoluteUrl('/media/avatars/300-6.jpg') }} src={contactNote.user_profile_image != '' ? contactNote.user_profile_image ?? toAbsoluteUrl('/media/avatars/300-6.jpg') : toAbsoluteUrl('/media/avatars/300-6.jpg')} alt='' />
                                                                    </div>
                                                                    {/* end::Avatar */}

                                                                    {/* begin::Info */}
                                                                    <div className='d-flex flex-column'>
                                                                    <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bolder'>
                                                                        {contactNote.user_name ?? 'User'}
                                                                    </a>
                                                                    <span className='text-gray-400 fw-bold'>{Moment(contactNote.created_at).format("DD-MMMM-YYYY HH:mm")}</span>
                                                                    </div>
                                                                    {/* end::Info */}
                                                                </div>
                                                                {/* end::User */}
                                                            </div>
                                                            {/* end::Header */}

                                                            {/* begin::Post */}
                                                            <div className='mb-7 pb-5 border-bottom border-secondary d-flex justify-content-between'>
                                                                {/* begin::Text */}
                                                                { noteEditVal != '' && parentId == contactNote.id ?
                                                                            <div className='text-gray-800 position-relative w-75'>
                                                                                <input
                                                                                    className='form-control border-0 p-0 pe-10 resize-none min-h-25px'
                                                                                    data-kt-autosize='true'
                                                                                    // rows={1}
                                                                                    placeholder='Reply..'
                                                                                    id={'edit_field'+contactNote.id}
                                                                                    defaultValue={noteEditVal}
                                                                                    // onChange={(e) => childReplyChange(e)}
                                                                                ></input>
                                                                                {/* <div className='position-absolute top-0 end-0 me-n5'>     
                                                                                    <button type='button' onClick={() => editOnSubmit(contactNote.id)} className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                    <KTSVG path='/media/icons/duotune/general/gen016.svg' className='svg-icon-2 mb-3' />
                                                                                    </button>
                                                                                </div> */}
                                                                            </div>
                                                                            : 
                                                                            <div className='text-gray-800'>
                                                                             {contactNote.reply}
                                                                            </div>
                                                                            }
                                                                            { currentUser?.id == contactNote.user_id ?
                                                                            <span>
                                                                                { noteEditVal != '' && parentId == contactNote.id ?
                                                                                <><button type='button' onClick={() => cancelEdit(contactNote.id)} className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-icon-2 mb-3"><path opacity="0.3" d="M6.7 19.4L5.3 18C4.9 17.6 4.9 17 5.3 16.6L16.6 5.3C17 4.9 17.6 4.9 18 5.3L19.4 6.7C19.8 7.1 19.8 7.7 19.4 8.1L8.1 19.4C7.8 19.8 7.1 19.8 6.7 19.4Z" fill="black"></path><path opacity="0.3" d="M19.5 18L18.1 19.4C17.7 19.8 17.1 19.8 16.7 19.4L5.40001 8.1C5.00001 7.7 5.00001 7.1 5.40001 6.7L6.80001 5.3C7.20001 4.9 7.80001 4.9 8.20001 5.3L19.5 16.6C19.9 16.9 19.9 17.6 19.5 18Z" fill="black"></path></svg>
                                                                                        </button>
                                                                                        <button type='button' onClick={() => editOnSubmit(contactNote.id)} className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                                <KTSVG path='/media/icons/duotune/general/gen016.svg' className='svg-icon-2 mb-3' />
                                                                                            </button></>:
                                                                                <button type='button' onClick={() => replyEdit(contactNote.id, contactNote.reply)} className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                <KTSVG path="/media/icons/duotune/art/art005.svg" className="svg-icon-2 mb-3"/>
                                                                                </button>}
                                                                                {currentUser?.designation == 1 && <button type='button'
                                                                                data-bs-toggle='modal'
                                                                                data-bs-target={'#delete_note_popup'+contactNote.id} 
                                                                                className='btn btn-icon btn-sm btn-active-color-danger ps-0'>
                                                                                <KTSVG path="/media/icons/duotune/general/gen027.svg" className="svg-icon-2 mb-3" />
                                                                                </button> }
                                                                            </span> : currentUser?.designation == 1 ?
                                                                            <span>
                                                                                <button type='button' data-bs-toggle='modal'
                                                                                data-bs-target={'#delete_note_popup'+contactNote.id}  className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                <KTSVG path="/media/icons/duotune/general/gen027.svg" className="svg-icon-2 mb-3" />
                                                                                </button>
                                                                            </span> : <></>
                                                                            }
                                                                            {/* confirm modal */}
                                                                            <div className='modal fade' id={'delete_note_popup'+contactNote.id} aria-hidden='true'>
                                                                                <div className='modal-dialog modal-dialog-centered'>
                                                                                    <div className='modal-content'>
                                                                                        <div className='modal-header'>
                                                                                            <h3>Confirmation</h3>
                                                                                            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='modal-body py-lg-10 px-lg-10'>
                                                                                            <p>Are you sure want to delete?</p>
                                                                                            <div className='d-flex align-items-center justify-content-end'>
                                                                                                <button className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal'>
                                                                                                    No
                                                                                                </button>
                                                                                                <button className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal' onClick={(e) => replyDelete(contactNote.id, contactNote.parent_id)}>
                                                                                                    Yes
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                {/* end::Text */}
                                                            </div>
                                                            {/* end::Post */}
                                                        </div> :
                                                        <div className='mb-7 ps-10 note_answer'>
                                                            <div className='d-flex mb-5'>
                                                                <div className='symbol symbol-45px me-5'>
                                                                    <img src={contactNote.user_profile_image != '' ? contactNote.user_profile_image ?? toAbsoluteUrl('/media/avatars/300-6.jpg') : toAbsoluteUrl('/media/avatars/300-6.jpg')} alt='' />
                                                                </div>
                                                                <div className='d-flex flex-column flex-row-fluid'>
                                                                <div className='d-flex align-items-center flex-wrap mb-1'>
                                                                    <a href='#' className='text-gray-800 text-hover-primary fw-bolder me-2'>
                                                                    {contactNote.user_name ?? 'User'}
                                                                    </a>

                                                                    <span className='text-gray-400 fw-bold fs-7'>{Moment(contactNote.created_at).format("DD-MMMM-YYYY HH:mm")}</span>
                                                                </div>
                                                                
                                                                <div className=' d-flex justify-content-between'>                                            
                                                                            { noteEditVal != '' && parentId == contactNote.id ?
                                                                            <div className='text-gray-800 position-relative w-75'>
                                                                                <input
                                                                                    className='form-control border-0 p-0 pe-10 resize-none min-h-25px'
                                                                                    data-kt-autosize='true'
                                                                                    // rows={1}
                                                                                    placeholder='Reply..'
                                                                                    id={'edit_field'+contactNote.id}
                                                                                    defaultValue={noteEditVal}
                                                                                    // onChange={(e) => childReplyChange(e)}
                                                                                ></input>
                                                                                {/* <div className='position-absolute top-0 end-0 me-n5'>     
                                                                                    <button type='button' onClick={() => editOnSubmit(contactNote.id)} className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                    <KTSVG path='/media/icons/duotune/general/gen016.svg' className='svg-icon-2 mb-3' />
                                                                                    </button>
                                                                                </div> */}
                                                                            </div>
                                                                            : 
                                                                            <div className='text-gray-800'>
                                                                             {contactNote.reply}
                                                                            </div>
                                                                            } 
                                                                                <span>
                                                                                { currentUser?.id == contactNote.user_id ?
                                                                            <span>
                                                                                { noteEditVal != '' && parentId == contactNote.id ?
                                                                                <><button type='button' onClick={() => cancelEdit(contactNote.id)} className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-icon-2 mb-3"><path opacity="0.3" d="M6.7 19.4L5.3 18C4.9 17.6 4.9 17 5.3 16.6L16.6 5.3C17 4.9 17.6 4.9 18 5.3L19.4 6.7C19.8 7.1 19.8 7.7 19.4 8.1L8.1 19.4C7.8 19.8 7.1 19.8 6.7 19.4Z" fill="black"></path><path opacity="0.3" d="M19.5 18L18.1 19.4C17.7 19.8 17.1 19.8 16.7 19.4L5.40001 8.1C5.00001 7.7 5.00001 7.1 5.40001 6.7L6.80001 5.3C7.20001 4.9 7.80001 4.9 8.20001 5.3L19.5 16.6C19.9 16.9 19.9 17.6 19.5 18Z" fill="black"></path></svg>
                                                                                        </button>
                                                                                        <button type='button' onClick={() => editOnSubmit(contactNote.id)} className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                                <KTSVG path='/media/icons/duotune/general/gen016.svg' className='svg-icon-2 mb-3' />
                                                                                            </button></>:
                                                                                <button type='button' onClick={() => replyEdit(contactNote.id, contactNote.reply)} className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                <KTSVG path="/media/icons/duotune/art/art005.svg" className="svg-icon-2 mb-3"/>
                                                                                </button>}

                                                                                {currentUser?.designation == 1 && <button type='button'
                                                                                data-bs-toggle='modal'
                                                                                data-bs-target={'#delete_note_popup2'+contactNote.id} 
                                                                                className='btn btn-icon btn-sm btn-active-color-danger ps-0'>
                                                                                <KTSVG path="/media/icons/duotune/general/gen027.svg" className="svg-icon-2 mb-3" />
                                                                                </button> }
                                                                            </span> : currentUser?.designation == 1 ?
                                                                            <span>
                                                                                <button type='button' data-bs-toggle='modal'
                                                                                data-bs-target={'#delete_note_popup2'+contactNote.id}  className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                                <KTSVG path="/media/icons/duotune/general/gen027.svg" className="svg-icon-2 mb-3" />
                                                                                </button>
                                                                            </span> : <></>
                                                                            }
                                                                                </span>
                                                                            </div>
                                                            
                                                                </div>
                                                                {/* confirm modal */}
                                                                <div className='modal fade' id={'delete_note_popup2'+contactNote.id} aria-hidden='true'>
                                                                                <div className='modal-dialog modal-dialog-centered'>
                                                                                    <div className='modal-content'>
                                                                                        <div className='modal-header'>
                                                                                            <h3>Confirmation</h3>
                                                                                            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='modal-body py-lg-10 px-lg-10'>
                                                                                            <p>Are you sure want to delete?</p>
                                                                                            <div className='d-flex align-items-center justify-content-end'>
                                                                                                <button className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal'>
                                                                                                    No
                                                                                                </button>
                                                                                                <button className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal' onClick={(e) => replyDelete(contactNote.id, contactNote.parent_id)}>
                                                                                                    Yes
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div> 
                                                            </div>
                                                        </div>
                                                        }
                                                        

                                                        {/* begin::Replies */}

                                                        {/* end::Replies */}

                                                        {/* begin::Reply input */}
                                                        {/* <form noValidate onSubmit={() => replyOnSubmit(contactNote.id)}> */}
                                                        {contactNote.reply1 == 'NO' && 
                                                        <>
                                                        <div className='position-relative mb-6'>
                                                                <input
                                                                    className='form-control border-0 p-0 pe-10 resize-none min-h-25px'
                                                                    data-kt-autosize='true'
                                                                    // rows={1}
                                                                    placeholder='Reply..'
                                                                    id={'child_reply'+contactNote.id}
                                                                    // onChange={(e) => childReplyChange(e, contactNote.id)}
                                                                ></input>
                                                                
                                                                <div className='position-absolute top-0 end-0 me-n5'>

                                                                    <button type='button' onClick={() => replyOnSubmit(contactNote.id)} className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                                                                        <KTSVG path='/media/icons/duotune/general/gen016.svg' className='svg-icon-2 mb-3' />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            {isFormError && contactNote.id == parentId && (
                                                                <div className='fv-plugins-message-container'>
                                                                    <div className='fv-help-block'>
                                                                    <span role='alert' className='text-danger'>Reply need to fill</span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            </>
                                                            }
                                                            
                                                            <div className='separator mb-4'></div>
                                                        {/* </form> */}
                                                        {/* edit::Reply input */}
                                                    </div>
                                                        )
                                                    })}
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={tabInfo == 'files' ? "tab-pane fade show active": "tab-pane fade"} id={"files"+contactId} role="tabpanel" aria-labelledby={"files-tab"+contactId}>
                                        <div {...getRootProps({className: 'dropzone'})}>
                                            <div className='myDIV main_bg'>
                                                {/* <div className='d-flex align-items-center justify-content-center h-100 vh-25'>
                                                    <input {...getInputProps()} />
                                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                                </div> */}
                                                <div className="d-flex align-items-center justify-content-center h-100 vh-25">
                                                    <span className="btn btn-file h-100 w-100">
                                                        {/* <i className="fa fa-upload my-2" aria-hidden="true"></i> */}
                                                        <KTSVG
                                                        path='/media/icons/duotune/files/fil022.svg'
                                                        className='svg-icon-1 text_primary ms-2'
                                                        />
                                                        <p className='text_primary'>Upload Files Here</p>
                                                        <small className='text-dark'>* Note: jpg, jpeg, pdf only acceptable</small>
                                                        <input {...getInputProps()}/>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <aside style={thumbsContainer}>
                                            {thumbs}
                                        </aside>
                                        <div className='p-5 text-end'>
                                            <button
                                                type='button'
                                                id='kt_sign_up_submit'
                                                className='btn btn_primary text-primary'
                                                // disabled={formik.isSubmitting}
                                                onClick={saveFiles}
                                                >
                                                {!loading && <span className='indicator-label'>Save
                                                <KTSVG
                                                path='/media/custom/save_white.svg'
                                                className='svg-icon-3 svg-icon-primary ms-2'
                                                />
                                                </span>}
                                                {loading && (
                                                    <span className='indicator-progress' style={{display: 'block'}}>
                                                    Please wait...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                        {filesVal.length > 0 &&
                                        <div className='main_bg p-4 mb-9 rounded'>
                                            <h3 className='px-5 py-3'>Files</h3>
                                            <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
                                            {filesVal.map((file, i) => {
                                                const pieces = file.fileoriginalname.split('.');
                                                const last = pieces[pieces.length - 1];
                                                console.log('sdsdsdsdsdsd');
                                                console.log(imgFullView);
                                                console.log(process.env.REACT_APP_API_BASE_URL+'uploads/contacts/files/'+file.contact_id+'/'+file.file);
                                                return ( 
                                                    <>
                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                                        <div className="card h-100">
                                                            <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                                                            <a href="#" data-bs-toggle='modal'
                                                            data-bs-target={'#delete_confirm_popup'+file.id} className="btn delete_btn btn-icon btn-bg-light btn-active-color-danger btn-sm"><span className="svg-icon svg-icon-4"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"></path><path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"></path><path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"></path></svg></span></a>
                                                                <div className="text-gray-800 hover d-flex flex-column">
                                                                    {last != 'pdf' ? 
                                                                    <a className={imgFullView && imgSelect == file.id ? "img_full_view" : "symbol symbol-75px"} onClick={() => imgViewChange(file.id)}>
                                                                        <img className='mb-5' onError={e => { e.currentTarget.src = toAbsoluteUrl('/media/svg/files/doc.svg') }} src={process.env.REACT_APP_API_BASE_URL+'uploads/contacts/files/'+file.contact_id+'/'+file.file} alt=""/>
                                                                        <div className="fs-5 fw-bolder text-dark mb-2">{file.fileoriginalname}</div>
                                                                        <div className="fs-7 fw-bold text-gray-400 mt-auto">{Moment(file.created_at).format("DD-MMMM-YYYY")}</div>
                                                                    </a>
                                                                    :
                                                                    <a className="symbol symbol-75px" href={process.env.REACT_APP_API_BASE_URL+'uploads/contacts/files/'+file.contact_id+'/'+file.file} download target="_blank">
                                                                        <img className='mb-5' src={toAbsoluteUrl("/media/svg/files/pdf.svg")} alt=""/>
                                                                        <div className="fs-5 fw-bolder text-dark mb-2">{file.fileoriginalname}</div>
                                                                        <div className="fs-7 fw-bold text-gray-400 mt-auto">{Moment(file.created_at).format("DD-MMMM-YYYY")}</div>
                                                                    </a>
                                                                    }
                                                                    
                                                                </div>                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* confirm modal */}
                                                    <div className='modal fade' id={'delete_confirm_popup'+file.id} aria-hidden='true'>
                                                        <div className='modal-dialog modal-dialog-centered'>
                                                            <div className='modal-content'>
                                                                <div className='modal-header'>
                                                                    <h3>Confirmation</h3>
                                                                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                                                    </div>
                                                                </div>
                                                                <div className='modal-body py-lg-10 px-lg-10'>
                                                                    <p>Are you sure want to delete?</p>
                                                                    <div className='d-flex align-items-center justify-content-end'>
                                                                        <button className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal'>
                                                                            No
                                                                        </button>
                                                                        <button className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal' onClick={(e) => onDeleteFile(file.id)}>
                                                                            Yes
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </>
                                                    )
                                                })}
                                            </div>
                                    </div>}
                                    </div>
                                    <div className={tabInfo == 'message' ? "tab-pane fade show active": "tab-pane fade"} id={"message"+contactId} role="tabpanel" aria-labelledby={"message-tab"+contactId}>                                        
                                        {/* <h3>Messages</h3> */}
                                        <ul className="nav nav-pills mb-3 message_tabs" id={"pills-tab"+contactId} role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id={"pills-mail-tab"+contactId} data-bs-toggle="pill" data-bs-target={"#pills-mail"+contactId} type="button" role="tab" aria-controls="pills-mail" aria-selected="true">Email</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id={"pills-whatsapp-tab"+contactId} data-bs-toggle="pill" data-bs-target={"#pills-whatsapp"+contactId} type="button" role="tab" aria-controls="pills-whatsapp" aria-selected="false">Whatsapp</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id={"pills-sms-tab"+contactId} data-bs-toggle="pill" data-bs-target={"#pills-sms"+contactId} type="button" role="tab" aria-controls="pills-sms" aria-selected="false">Sms</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id={"pills-calls-tab"+contactId} data-bs-toggle="pill" data-bs-target={"#pills-calls"+contactId} type="button" role="tab" aria-controls="pills-calls" aria-selected="false">Calls</button>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="pills-tabContent position-relative">
                                            <div className="tab-pane fade show active" id={"pills-mail"+contactId} role="tabpanel" aria-labelledby="pills-mail-tab">
                                                <div className="mt-4">
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                {/* <div className="col-lg-1  d-flex align-items-center justify-content-lg-center my-2">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-lg-3 my-2 d-flex align-items-center">
                                                                    <label className="form-check-label d-flex align-items-center" htmlFor="flexCheckDefault">
                                                                        <span className="mail_icon ms-3"><i className="fas fa-envelope"></i></span>
                                                                        <p className='my-3'>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                    <div className="w-100 d-flex flex-wrap mt-2">
                                                                        <a href="#" className="d-flex mail_format me-2 mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.wrd</p>
                                                                        </a> 
                                                                        <a href="#" className="d-flex mail_format mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.pdf</p>
                                                                        </a> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                {/* <div className="col-lg-1  d-flex align-items-center justify-content-lg-center my-2">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-lg-3 my-2 d-flex align-items-center">
                                                                    <label className="form-check-label d-flex align-items-center" htmlFor="flexCheckDefault">
                                                                        <span className="mail_icon ms-3"><i className="fas fa-envelope"></i></span>
                                                                        <p className='my-3'>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                    <div className="w-100 d-flex flex-wrap mt-2">
                                                                        <a href="#" className="d-flex mail_format me-2 mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.wrd</p>
                                                                        </a> 
                                                                        <a href="#" className="d-flex mail_format mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.pdf</p>
                                                                        </a> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                {/* <div className="col-lg-1  d-flex align-items-center justify-content-lg-center my-2">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-lg-3 my-2 d-flex align-items-center">
                                                                    <label className="form-check-label d-flex align-items-center" htmlFor="flexCheckDefault">
                                                                        <span className="mail_icon ms-3"><i className="fas fa-envelope"></i></span>
                                                                        <p className='my-3'>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                    <div className="w-100 d-flex flex-wrap mt-2">
                                                                        <a href="#" className="d-flex mail_format me-2 mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.wrd</p>
                                                                        </a> 
                                                                        <a href="#" className="d-flex mail_format mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.pdf</p>
                                                                        </a> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                {/* <div className="col-lg-1  d-flex align-items-center justify-content-lg-center my-2">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-lg-3 my-2 d-flex align-items-center">
                                                                    <label className="form-check-label d-flex align-items-center" htmlFor="flexCheckDefault">
                                                                        <span className="mail_icon ms-3"><i className="fas fa-envelope"></i></span>
                                                                        <p className='my-3'>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                    <div className="w-100 d-flex flex-wrap mt-2">
                                                                        <a href="#" className="d-flex mail_format me-2 mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.wrd</p>
                                                                        </a> 
                                                                        <a href="#" className="d-flex mail_format mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.pdf</p>
                                                                        </a> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                {/* <div className="col-lg-1  d-flex align-items-center justify-content-lg-center my-2">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-lg-3 my-2 d-flex align-items-center">
                                                                    <label className="form-check-label d-flex align-items-center" htmlFor="flexCheckDefault">
                                                                        <span className="mail_icon ms-3"><i className="fas fa-envelope"></i></span>
                                                                        <p className='my-3'>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                    <div className="w-100 d-flex flex-wrap mt-2">
                                                                        <a href="#" className="d-flex mail_format me-2 mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.wrd</p>
                                                                        </a> 
                                                                        <a href="#" className="d-flex mail_format mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.pdf</p>
                                                                        </a> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                {/* <div className="col-lg-1  d-flex align-items-center justify-content-lg-center my-2">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-lg-3 my-2 d-flex align-items-center">
                                                                    <label className="form-check-label d-flex align-items-center" htmlFor="flexCheckDefault">
                                                                        <span className="mail_icon ms-3"><i className="fas fa-envelope"></i></span>
                                                                        <p className='my-3'>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                    <div className="w-100 d-flex flex-wrap mt-2">
                                                                        <a href="#" className="d-flex mail_format me-2 mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.wrd</p>
                                                                        </a> 
                                                                        <a href="#" className="d-flex mail_format mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.pdf</p>
                                                                        </a> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                {/* <div className="col-lg-1  d-flex align-items-center justify-content-lg-center my-2">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-lg-3 my-2 d-flex align-items-center">
                                                                    <label className="form-check-label d-flex align-items-center" htmlFor="flexCheckDefault">
                                                                        <span className="mail_icon ms-3"><i className="fas fa-envelope"></i></span>
                                                                        <p className='my-3'>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                    <div className="w-100 d-flex flex-wrap mt-2">
                                                                        <a href="#" className="d-flex mail_format me-2 mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.wrd</p>
                                                                        </a> 
                                                                        <a href="#" className="d-flex mail_format mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.pdf</p>
                                                                        </a> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                {/* <div className="col-lg-1  d-flex align-items-center justify-content-lg-center my-2">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-lg-3 my-2 d-flex align-items-center">
                                                                    <label className="form-check-label d-flex align-items-center" htmlFor="flexCheckDefault">
                                                                        <span className="mail_icon ms-3"><i className="fas fa-envelope"></i></span>
                                                                        <p className='my-3'>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                    <div className="w-100 d-flex flex-wrap mt-2">
                                                                        <a href="#" className="d-flex mail_format me-2 mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.wrd</p>
                                                                        </a> 
                                                                        <a href="#" className="d-flex mail_format mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.pdf</p>
                                                                        </a> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                {/* <div className="col-lg-1  d-flex align-items-center justify-content-lg-center my-2">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-lg-3 my-2 d-flex align-items-center">
                                                                    <label className="form-check-label d-flex align-items-center" htmlFor="flexCheckDefault">
                                                                        <span className="mail_icon ms-3"><i className="fas fa-envelope"></i></span>
                                                                        <p className='my-3'>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                    <div className="w-100 d-flex flex-wrap mt-2">
                                                                        <a href="#" className="d-flex mail_format me-2 mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.wrd</p>
                                                                        </a> 
                                                                        <a href="#" className="d-flex mail_format mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.pdf</p>
                                                                        </a> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                {/* <div className="col-lg-1  d-flex align-items-center justify-content-lg-center my-2">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-lg-3 my-2 d-flex align-items-center">
                                                                    <label className="form-check-label d-flex align-items-center" htmlFor="flexCheckDefault">
                                                                        <span className="mail_icon ms-3"><i className="fas fa-envelope"></i></span>
                                                                        <p className='my-3'>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                    <div className="w-100 d-flex flex-wrap mt-2">
                                                                        <a href="#" className="d-flex mail_format me-2 mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.wrd</p>
                                                                        </a> 
                                                                        <a href="#" className="d-flex mail_format mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.pdf</p>
                                                                        </a> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                {/* <div className="col-lg-1  d-flex align-items-center justify-content-lg-center my-2">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-lg-3 my-2 d-flex align-items-center">
                                                                    <label className="form-check-label d-flex align-items-center" htmlFor="flexCheckDefault">
                                                                        <span className="mail_icon ms-3"><i className="fas fa-envelope"></i></span>
                                                                        <p className='my-3'>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                    <div className="w-100 d-flex flex-wrap mt-2">
                                                                        <a href="#" className="d-flex mail_format me-2 mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.wrd</p>
                                                                        </a> 
                                                                        <a href="#" className="d-flex mail_format mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.pdf</p>
                                                                        </a> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                {/* <div className="col-lg-1  d-flex align-items-center justify-content-lg-center my-2">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-lg-3 my-2 d-flex align-items-center">
                                                                    <label className="form-check-label d-flex align-items-center" htmlFor="flexCheckDefault">
                                                                        <span className="mail_icon ms-3"><i className="fas fa-envelope"></i></span>
                                                                        <p className='my-3'>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                    <div className="w-100 d-flex flex-wrap mt-2">
                                                                        <a href="#" className="d-flex mail_format me-2 mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.wrd</p>
                                                                        </a> 
                                                                        <a href="#" className="d-flex mail_format mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.pdf</p>
                                                                        </a> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                {/* <div className="col-lg-1  d-flex align-items-center justify-content-lg-center my-2">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-lg-3 my-2 d-flex align-items-center">
                                                                    <label className="form-check-label d-flex align-items-center" htmlFor="flexCheckDefault">
                                                                        <span className="mail_icon ms-3"><i className="fas fa-envelope"></i></span>
                                                                        <p className='my-3'>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                    <div className="w-100 d-flex flex-wrap mt-2">
                                                                        <a href="#" className="d-flex mail_format me-2 mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.wrd</p>
                                                                        </a> 
                                                                        <a href="#" className="d-flex mail_format mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.pdf</p>
                                                                        </a> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                {/* <div className="col-lg-1  d-flex align-items-center justify-content-lg-center my-2">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-lg-3 my-2 d-flex align-items-center">
                                                                    <label className="form-check-label d-flex align-items-center" htmlFor="flexCheckDefault">
                                                                        <span className="mail_icon ms-3"><i className="fas fa-envelope"></i></span>
                                                                        <p className='my-3'>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                    <div className="w-100 d-flex flex-wrap mt-2">
                                                                        <a href="#" className="d-flex mail_format me-2 mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.wrd</p>
                                                                        </a> 
                                                                        <a href="#" className="d-flex mail_format mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.pdf</p>
                                                                        </a> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                {/* <div className="col-lg-1  d-flex align-items-center justify-content-lg-center my-2">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-lg-3 my-2 d-flex align-items-center">
                                                                    <label className="form-check-label d-flex align-items-center" htmlFor="flexCheckDefault">
                                                                        <span className="mail_icon ms-3"><i className="fas fa-envelope"></i></span>
                                                                        <p className='my-3'>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                    <div className="w-100 d-flex flex-wrap mt-2">
                                                                        <a href="#" className="d-flex mail_format me-2 mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.wrd</p>
                                                                        </a> 
                                                                        <a href="#" className="d-flex mail_format mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.pdf</p>
                                                                        </a> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                {/* <div className="col-lg-1  d-flex align-items-center justify-content-lg-center my-2">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-lg-3 my-2 d-flex align-items-center">
                                                                    <label className="form-check-label d-flex align-items-center" htmlFor="flexCheckDefault">
                                                                        <span className="mail_icon ms-3"><i className="fas fa-envelope"></i></span>
                                                                        <p className='my-3'>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                    <div className="w-100 d-flex flex-wrap mt-2">
                                                                        <a href="#" className="d-flex mail_format me-2 mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.wrd</p>
                                                                        </a> 
                                                                        <a href="#" className="d-flex mail_format mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.pdf</p>
                                                                        </a> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-bs-toggle='modal' data-bs-target={'#mail_template_popup'} onClick={sendMail}>                                                
                                                <KTSVG path="/media/icons/duotune/general/gen041.svg" className="svg-icon-muted rounded-circle svg-icon-4hx position-absolute bottom-0 end-0 mb-9 me-9 text_primary" />
                                                </div>
                                                <div className='modal fade' id={'mail_template_popup'} aria-hidden='true'>
                                                    <div className='modal-dialog modal-dialog-centered'>
                                                        <div className='modal-content list_height'>
                                                            <div className='modal-header rbc-today py-0'>
                                                            <div className='card-header pt-2 d-flex align-items-center justify-content-center' id='kt_chat_contacts_header'>
                                                                <h3>Mail List</h3>
                                                            </div>
                                                                <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                <img src={toAbsoluteUrl('/media/custom/overview-icons/cancel_white.svg')} className="svg_icon text-dark" alt='' />
                                                                </div>
                                                            </div>
                                                            <div className='modal-body'>                                         
                                                            <div className="input-group form_search">
                                                                    <input type="text" className="form-control" name="search" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)}/>
                                                                    <div className="input-group-append">
                                                                        <button className="btn btn-secondary" type="button">
                                                                        <img src={toAbsoluteUrl('/media/custom/header-icons/search.svg')} className="svg_icon" alt='' />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <ul className="nav mb-1 d-block list_template">
                                                                {!search
                                                                    ? allTemplatesMail.map((item) => (
                                                                        // <li key={`${item.title}-${item.title}`}>{item.title}</li>
                                                                        <li className="nav-item w-100" key={item.title}>
                                                                        <div onClick={() => MailById(item.id)} className='btn' data-bs-toggle='modal' data-bs-target={'#mail_content_popup'}>
                                                                            <div className='d-flex align-items-center'>
                                                                                <div className='symbol symbol-35px symbol-circle'>
                                                                                    <span className='symbol-label bg_soft text_primary fs-6 fw-bolder'>
                                                                                    {item.title[0]}
                                                                                    </span>
                                                                                </div>

                                                                                <div className='ms-5'>
                                                                                    <p className='fs-5 fw-bolder text-gray-900 mb-0'>
                                                                                    {item.title} 
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    ))
                                                                    : filtered.map((item) => (<li className="nav-item w-100" key={item.title}>
                                                                    <div onClick={() => MailById(item.id)} className='btn' data-bs-toggle='modal' data-bs-target={'#mail_content_popup'}>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div className='symbol symbol-35px symbol-circle'>
                                                                                <span className='symbol-label bg_soft text_primary fs-6 fw-bolder'>
                                                                                {item.title[0]}
                                                                                </span>
                                                                            </div>

                                                                            <div className='ms-5'>
                                                                                <p className='p-3 fs-5 fw-bolder text-gray-900 mb-0'>
                                                                                {item.title} 
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='modal fade shadow-lg' id={'mail_content_popup'} aria-hidden='true'>
                                                        <div className='modal-dialog modal-lg modal-dialog-centered'>
                                                            <div className='modal-content'>
                                                                <div className='modal-header rbc-today'>
                                                                    <h3>Send Mail</h3>
                                                                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                    <img src={toAbsoluteUrl('/media/custom/overview-icons/cancel_white.svg')} className="svg_icon text-dark" alt='' />
                                                                    </div>
                                                                </div>
                                                                <div className='modal-body py-lg-10 px-lg-10'>
                                                                <form noValidate onSubmit={formikMail.handleSubmit} >
                                                                    <div className="row">
                                                                        <div className="col-md-6">                                            
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Title</label>
                                                                                <div className="input-group">
                                                                                    <input type="text" className="form-control" placeholder="title" {...formikMail.getFieldProps('title')}/> 
                                                                                </div>
                                                                                {formikMail.touched.title && formikMail.errors.title && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.title}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">                                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Subject</label>
                                                                                <div className="input-group">
                                                                                    <input type="text" className="form-control" placeholder="subject" {...formikMail.getFieldProps('subject')}/> 
                                                                                </div>
                                                                                {formikMail.touched.subject && formikMail.errors.subject && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.subject}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-6">                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Share with</label>
                                                                                <select className="form-select text-start bg-secondary bg-opacity-25 form-control" {...formikMail.getFieldProps('share_with')}>
                                                                                    <option value="default">Select</option>
                                                                                    <option value={1}>Option 1</option>
                                                                                    <option value={2}>Option 2</option>
                                                                                </select>  
                                                                            </div> 
                                                                            {formikMail.touched.share_with && formikMail.errors.share_with && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.share_with}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}                                                   
                                                                        </div>
                                                                        <div className="col-xl-6">                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Module</label>
                                                                                <select className="form-select form-control text-start bg-secondary bg-opacity-25" {...formikMail.getFieldProps('module_id')}>
                                                                                    <option value="default">Select</option>
                                                                                    <option value={1}>Lead</option>
                                                                                    <option value={2}>Contact</option>
                                                                                    <option value={3}>Task</option>
                                                                                    <option value={4}>Project</option>
                                                                                </select>  
                                                                            </div>   
                                                                            {formikMail.touched.module_id && formikMail.errors.module_id && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.module_id}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}                                                 
                                                                        </div>
                                                                        <div className="col">                                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Body</label>
                                                                                <div className="input-group">
                                                                                    <textarea style={{height: '200px'}} className="form-control" placeholder="Encryption" {...formikMail.getFieldProps('body')}/> 
                                                                                </div>
                                                                                {formikMail.touched.body && formikMail.errors.body && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.body}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                            </div>
                                                                        </div>                                        
                                                                    </div>
                                                                    <div className='card-footer py-3 text-center' id='kt_task_footer'>
                                                                        <button type='button' className='btn btn-secondary me-3' data-bs-dismiss='modal'>Cancel</button>
                                                                        <button
                                                                        type='submit'
                                                                        id='kt_add_teams_submit'
                                                                        className='btn btn_primary text-primary'
                                                                        disabled={formikMail.isSubmitting}
                                                                        >
                                                                        {!loading && <span className='indicator-label'>Send
                                                                        {/* <KTSVG
                                                                        path='/media/custom/save_white.svg'
                                                                        className='svg-icon-3 svg-icon-primary ms-2'
                                                                        /> */}
                                                                        </span>}
                                                                        {loading && (
                                                                            <span className='indicator-progress' style={{display: 'block'}}>
                                                                            Please wait...{' '}
                                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                            </span>
                                                                        )}
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                            <div className="tab-pane fade" id={"pills-whatsapp"+contactId} role="tabpanel" aria-labelledby="pills-whatsapp-tab">
                                                <div className="mt-4">
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                {/* <div className="col-lg-1 align-items-center d-flex justify-content-lg-center my-2">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-lg-3 my-2">
                                                                    <span className="icon_bg"><img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} className="svg_icon" alt=''/></span>
                                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                        <p>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                <div className="col-lg-4 my-2">
                                                                    <span className="icon_bg"><img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} className="svg_icon" alt=''/></span>
                                                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                        <p>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p>27/04/2022</p>
                                                                    <p className="pe-3">4.00pm</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-bs-toggle='modal' data-bs-target={'#watsapp_template_popup'} onClick={sendWatsapp}>
                                                <KTSVG path="/media/icons/duotune/general/gen041.svg" className="svg-icon-muted rounded-circle svg-icon-4hx position-absolute bottom-0 end-0 mb-9 me-9 text_primary" />
                                                </div>
                                                <div className='modal fade' id={'watsapp_template_popup'} aria-hidden='true'>
                                                    <div className='modal-dialog modal-dialog-centered'>
                                                        <div className='modal-content list_height'>
                                                            <div className='modal-header rbc-today py-0'>
                                                            <div className='card-header pt-2 d-flex align-items-center justify-content-center' id='kt_chat_contacts_header'>
                                                                <h3>Mail List</h3>
                                                            </div>
                                                                <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                <img src={toAbsoluteUrl('/media/custom/overview-icons/cancel_white.svg')} className="svg_icon text-dark" alt='' />
                                                                </div>
                                                            </div>
                                                            <div className='modal-body'>                                         
                                                            <div className="input-group form_search">
                                                                    <input type="text" className="form-control" name="search" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)}/>
                                                                    <div className="input-group-append">
                                                                        <button className="btn btn-secondary" type="button">
                                                                        <img src={toAbsoluteUrl('/media/custom/header-icons/search.svg')} className="svg_icon" alt='' />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <ul className="nav mb-1 d-block list_template">
                                                                {!search
                                                                    ? allTemplatesMail.map((item) => (
                                                                        // <li key={`${item.title}-${item.title}`}>{item.title}</li>
                                                                        <li className="nav-item w-100" key={item.title}>
                                                                        <div onClick={() => MailById(item.id)} className='btn' data-bs-toggle='modal' data-bs-target={'#watsapp_content_popup'}>
                                                                            <div className='d-flex align-items-center'>
                                                                                <div className='symbol symbol-35px symbol-circle'>
                                                                                    <span className='symbol-label bg_soft text_primary fs-6 fw-bolder'>
                                                                                    {item.title[0]}
                                                                                    </span>
                                                                                </div>

                                                                                <div className='ms-5'>
                                                                                    <p className='fs-5 fw-bolder text-gray-900 mb-0'>
                                                                                    {item.title} 
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    ))
                                                                    : filtered.map((item) => (<li className="nav-item w-100" key={item.title}>
                                                                    <div onClick={() => MailById(item.id)} className='btn' data-bs-toggle='modal' data-bs-target={'#watsapp_content_popup'}>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div className='symbol symbol-35px symbol-circle'>
                                                                                <span className='symbol-label bg_soft text_primary fs-6 fw-bolder'>
                                                                                {item.title[0]}
                                                                                </span>
                                                                            </div>

                                                                            <div className='ms-5'>
                                                                                <p className='p-3 fs-5 fw-bolder text-gray-900 mb-0'>
                                                                                {item.title} 
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='modal fade shadow-lg' id={'watsapp_content_popup'} aria-hidden='true'>
                                                        <div className='modal-dialog modal-lg modal-dialog-centered'>
                                                            <div className='modal-content'>
                                                                <div className='modal-header rbc-today'>
                                                                    <h3>Send Mail</h3>
                                                                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                    <img src={toAbsoluteUrl('/media/custom/overview-icons/cancel_white.svg')} className="svg_icon text-dark" alt='' />
                                                                    </div>
                                                                </div>
                                                                <div className='modal-body py-lg-10 px-lg-10'>
                                                                <form noValidate onSubmit={formikMail.handleSubmit} >
                                                                    <div className="row">
                                                                        <div className="col-md-6">                                            
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Title</label>
                                                                                <div className="input-group">
                                                                                    <input type="text" className="form-control" placeholder="title" {...formikMail.getFieldProps('title')}/> 
                                                                                </div>
                                                                                {formikMail.touched.title && formikMail.errors.title && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.title}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">                                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Subject</label>
                                                                                <div className="input-group">
                                                                                    <input type="text" className="form-control" placeholder="subject" {...formikMail.getFieldProps('subject')}/> 
                                                                                </div>
                                                                                {formikMail.touched.subject && formikMail.errors.subject && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.subject}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-6">                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Share with</label>
                                                                                <select className="form-select text-start bg-secondary bg-opacity-25 form-control" {...formikMail.getFieldProps('share_with')}>
                                                                                    <option value="default">Select</option>
                                                                                    <option value={1}>Option 1</option>
                                                                                    <option value={2}>Option 2</option>
                                                                                </select>  
                                                                            </div> 
                                                                            {formikMail.touched.share_with && formikMail.errors.share_with && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.share_with}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}                                                   
                                                                        </div>
                                                                        <div className="col-xl-6">                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Module</label>
                                                                                <select className="form-select form-control text-start bg-secondary bg-opacity-25" {...formikMail.getFieldProps('module_id')}>
                                                                                    <option value="default">Select</option>
                                                                                    <option value={1}>Lead</option>
                                                                                    <option value={2}>Contact</option>
                                                                                    <option value={3}>Task</option>
                                                                                    <option value={4}>Project</option>
                                                                                </select>  
                                                                            </div>   
                                                                            {formikMail.touched.module_id && formikMail.errors.module_id && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.module_id}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}                                                 
                                                                        </div>
                                                                        <div className="col">                                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Body</label>
                                                                                <div className="input-group">
                                                                                    <textarea style={{height: '200px'}} className="form-control" placeholder="Encryption" {...formikMail.getFieldProps('body')}/> 
                                                                                </div>
                                                                                {formikMail.touched.body && formikMail.errors.body && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.body}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                            </div>
                                                                        </div>                                        
                                                                    </div>
                                                                    <div className='card-footer py-3 text-center' id='kt_task_footer'>
                                                                        <button type='button' className='btn btn-secondary me-3' data-bs-dismiss='modal'>Cancel</button>
                                                                        <button
                                                                        type='submit'
                                                                        id='kt_add_teams_submit'
                                                                        className='btn btn_primary text-primary'
                                                                        disabled={formikMail.isSubmitting}
                                                                        >
                                                                        {!loading && <span className='indicator-label'>Send
                                                                        {/* <KTSVG
                                                                        path='/media/custom/save_white.svg'
                                                                        className='svg-icon-3 svg-icon-primary ms-2'
                                                                        /> */}
                                                                        </span>}
                                                                        {loading && (
                                                                            <span className='indicator-progress' style={{display: 'block'}}>
                                                                            Please wait...{' '}
                                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                            </span>
                                                                        )}
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                            <div className="tab-pane fade" id={"pills-sms"+contactId} role="tabpanel" aria-labelledby="pills-sms-tab">
                                                <div className="mt-4">
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                <div className="col-lg-3 my-2">
                                                                    <span className="icon_bg bg_primary_light"><img src={toAbsoluteUrl('/media/icons/duotune/communication/com007.svg')} className="svg_icon text-primary" alt=''/></span>
                                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                        <p>8934301210</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-bs-toggle='modal' data-bs-target={'#sms_template_popup'} onClick={sendSMS}>
                                                <KTSVG path="/media/icons/duotune/general/gen041.svg" className="svg-icon-muted rounded-circle svg-icon-4hx position-absolute bottom-0 end-0 mb-9 me-9 text_primary" />
                                                </div>
                                                <div className='modal fade' id={'sms_template_popup'} aria-hidden='true'>
                                                    <div className='modal-dialog modal-dialog-centered'>
                                                        <div className='modal-content list_height'>
                                                            <div className='modal-header rbc-today py-0'>
                                                            <div className='card-header pt-2 d-flex align-items-center justify-content-center' id='kt_chat_contacts_header'>
                                                                <h3>Mail List</h3>
                                                            </div>
                                                                <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                <img src={toAbsoluteUrl('/media/custom/overview-icons/cancel_white.svg')} className="svg_icon text-dark" alt='' />
                                                                </div>
                                                            </div>
                                                            <div className='modal-body'>                                         
                                                            <div className="input-group form_search">
                                                                    <input type="text" className="form-control" name="search" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)}/>
                                                                    <div className="input-group-append">
                                                                        <button className="btn btn-secondary" type="button">
                                                                        <img src={toAbsoluteUrl('/media/custom/header-icons/search.svg')} className="svg_icon" alt='' />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <ul className="nav mb-1 d-block list_template">
                                                                {!search
                                                                    ? allTemplatesMail.map((item) => (
                                                                        // <li key={`${item.title}-${item.title}`}>{item.title}</li>
                                                                        <li className="nav-item w-100" key={item.title}>
                                                                        <div onClick={() => MailById(item.id)} className='btn' data-bs-toggle='modal' data-bs-target={'#sms_content_popup'}>
                                                                            <div className='d-flex align-items-center'>
                                                                                <div className='symbol symbol-35px symbol-circle'>
                                                                                    <span className='symbol-label bg_soft text_primary fs-6 fw-bolder'>
                                                                                    {item.title[0]}
                                                                                    </span>
                                                                                </div>

                                                                                <div className='ms-5'>
                                                                                    <p className='fs-5 fw-bolder text-gray-900 mb-0'>
                                                                                    {item.title} 
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    ))
                                                                    : filtered.map((item) => (<li className="nav-item w-100" key={item.title}>
                                                                    <div onClick={() => MailById(item.id)} className='btn' data-bs-toggle='modal' data-bs-target={'#sms_content_popup'}>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div className='symbol symbol-35px symbol-circle'>
                                                                                <span className='symbol-label bg_soft text_primary fs-6 fw-bolder'>
                                                                                {item.title[0]}
                                                                                </span>
                                                                            </div>

                                                                            <div className='ms-5'>
                                                                                <p className='p-3 fs-5 fw-bolder text-gray-900 mb-0'>
                                                                                {item.title} 
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='modal fade shadow-lg' id={'sms_content_popup'} aria-hidden='true'>
                                                        <div className='modal-dialog modal-lg modal-dialog-centered'>
                                                            <div className='modal-content'>
                                                                <div className='modal-header rbc-today'>
                                                                    <h3>Send Mail</h3>
                                                                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                    <img src={toAbsoluteUrl('/media/custom/overview-icons/cancel_white.svg')} className="svg_icon text-dark" alt='' />
                                                                    </div>
                                                                </div>
                                                                <div className='modal-body py-lg-10 px-lg-10'>
                                                                <form noValidate onSubmit={formikMail.handleSubmit} >
                                                                    <div className="row">
                                                                        <div className="col-md-6">                                            
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Title</label>
                                                                                <div className="input-group">
                                                                                    <input type="text" className="form-control" placeholder="title" {...formikMail.getFieldProps('title')}/> 
                                                                                </div>
                                                                                {formikMail.touched.title && formikMail.errors.title && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.title}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">                                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Subject</label>
                                                                                <div className="input-group">
                                                                                    <input type="text" className="form-control" placeholder="subject" {...formikMail.getFieldProps('subject')}/> 
                                                                                </div>
                                                                                {formikMail.touched.subject && formikMail.errors.subject && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.subject}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-6">                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Share with</label>
                                                                                <select className="form-select text-start bg-secondary bg-opacity-25 form-control" {...formikMail.getFieldProps('share_with')}>
                                                                                    <option value="default">Select</option>
                                                                                    <option value={1}>Option 1</option>
                                                                                    <option value={2}>Option 2</option>
                                                                                </select>  
                                                                            </div> 
                                                                            {formikMail.touched.share_with && formikMail.errors.share_with && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.share_with}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}                                                   
                                                                        </div>
                                                                        <div className="col-xl-6">                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Module</label>
                                                                                <select className="form-select form-control text-start bg-secondary bg-opacity-25" {...formikMail.getFieldProps('module_id')}>
                                                                                    <option value="default">Select</option>
                                                                                    <option value={1}>Lead</option>
                                                                                    <option value={2}>Contact</option>
                                                                                    <option value={3}>Task</option>
                                                                                    <option value={4}>Project</option>
                                                                                </select>  
                                                                            </div>   
                                                                            {formikMail.touched.module_id && formikMail.errors.module_id && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.module_id}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}                                                 
                                                                        </div>
                                                                        <div className="col">                                        
                                                                            <div className="form-group mb-4">
                                                                                <label htmlFor="basic-url" className="form-label">Body</label>
                                                                                <div className="input-group">
                                                                                    <textarea style={{height: '200px'}} className="form-control" placeholder="Encryption" {...formikMail.getFieldProps('body')}/> 
                                                                                </div>
                                                                                {formikMail.touched.body && formikMail.errors.body && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>
                                                                                    <span role='alert' className='text-danger'>{formikMail.errors.body}</span>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                            </div>
                                                                        </div>                                        
                                                                    </div>
                                                                    <div className='card-footer py-3 text-center' id='kt_task_footer'>
                                                                        <button type='button' className='btn btn-secondary me-3' data-bs-dismiss='modal'>Cancel</button>
                                                                        <button
                                                                        type='submit'
                                                                        id='kt_add_teams_submit'
                                                                        className='btn btn_primary text-primary'
                                                                        disabled={formikMail.isSubmitting}
                                                                        >
                                                                        {!loading && <span className='indicator-label'>Send
                                                                        {/* <KTSVG
                                                                        path='/media/custom/save_white.svg'
                                                                        className='svg-icon-3 svg-icon-primary ms-2'
                                                                        /> */}
                                                                        </span>}
                                                                        {loading && (
                                                                            <span className='indicator-progress' style={{display: 'block'}}>
                                                                            Please wait...{' '}
                                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                            </span>
                                                                        )}
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                            <div className="tab-pane fade" id={"pills-calls"+contactId} role="tabpanel" aria-labelledby="pills-calls-tab">
                                                <div className="mt-4">
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                <div className="col-lg-3 my-2">
                                                                    <span className="icon_bg bg_warning_light"><img src={toAbsoluteUrl('/media/icons/duotune/communication/com005.svg')} className="svg_icon text-danger" alt=''/></span>
                                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                        <p>8934301210</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div onClick={sendCall}>
                                                <KTSVG path="/media/icons/duotune/general/gen041.svg" className="svg-icon-muted rounded-circle svg-icon-4hx position-absolute bottom-0 end-0 mb-9 me-9 text_primary" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={tabInfo == 'sec_contact' ? "tab-pane fade show active": "tab-pane fade"} id={"contact"+contactId} role="tabpanel" aria-labelledby={"contact-tab"+contactId}>
                                        {/* {secContactsList.map((secContact, i) => {
                                            return (
                                                <div key={i}>
                                                    {secContact.id}
                                                </div>
                                            )
                                        })} */}
                                        <div style={{ height: 400, width: '100%',}}>
                                            {secContactsList.length > 0
                                            ?
                                            <DataGrid
                                                rows={secContactsList}
                                                columns={secContactcolumns}
                                                pageSize={5}
                                                rowsPerPageOptions={[5]}
                                                checkboxSelection
                                                sx={{
                                                    fontSize:14,
                                                    fontWeight:500,
                                                }}
                                            />
                                            : <div className="text-center w-100">
                                                <span className="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor"></path><rect x="7" y="17" width="6" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="12" width="10" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="7" width="6" height="2" rx="1" fill="currentColor"></rect><path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor"></path></svg></span>
                                                <p className='mt-3'>No Secondary Contacts Available</p>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    <div className={tabInfo == 'lead' ? "tab-pane fade show active": "tab-pane fade"} id={"lead"+contactId} role="tabpanel" aria-labelledby={"lead-tab"+contactId}>
                                        <div className='mb-9' style={{ height: 400, width: '100%',}}>
                                            {leadContactsList.length > 0 
                                            ? <DataGrid
                                                rows={leadContactsList}
                                                columns={leadcolumns}
                                                pageSize={5}
                                                rowsPerPageOptions={[5]}
                                                checkboxSelection
                                                sx={{
                                                    fontSize:14,
                                                    fontWeight:500,
                                                }}
                                            />
                                            : <div className="text-center w-100">
                                                <span className="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor"></path><rect x="7" y="17" width="6" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="12" width="10" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="7" width="6" height="2" rx="1" fill="currentColor"></rect><path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor"></path></svg></span>
                                                <p className='mt-3'>No Lead Contacts Available</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id={"duplicate"+contactId} role="tabpanel" aria-labelledby="duplicate-tab">
                                        <div className='mb-9' style={{ height: 400, width: '100%',}}>
                                            {duplicateContactsList.length > 0
                                                ?  <DataGrid
                                                    rows={duplicateContactsList}
                                                    columns={duplicateContactcolumns}
                                                    pageSize={5}
                                                    rowsPerPageOptions={[5]}
                                                    checkboxSelection
                                                    sx={{
                                                        fontSize:14,
                                                        fontWeight:500,
                                                    }}
                                                /> :
                                                <div className="text-center w-100">
                                                    <span className="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor"></path><rect x="7" y="17" width="6" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="12" width="10" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="7" width="6" height="2" rx="1" fill="currentColor"></rect><path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor"></path></svg></span>
                                                    <p className='mt-3'>No Duplicate Contacts Available</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className={tabInfo == 'task' ? "tab-pane fade show active": "tab-pane fade"} id={"tasks"+contactId} role="tabpanel" aria-labelledby="tasks-tab">
                                    <div className='mb-9' style={{ height: 400, width: '100%',}}>
                                            {taskList.length > 0
                                            ?
                                            <DataGrid
                                                rows={taskList}
                                                columns={taskListcolumns}
                                                pageSize={5}
                                                rowsPerPageOptions={[5]}
                                                checkboxSelection
                                                sx={{
                                                    fontSize:14,
                                                    fontWeight:500,
                                                }}
                                            />
                                            : <div className="text-center w-100">
                                                <span className="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor"></path><rect x="7" y="17" width="6" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="12" width="10" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="7" width="6" height="2" rx="1" fill="currentColor"></rect><path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor"></path></svg></span>
                                                <p className='mt-3'>No Tasks Available</p>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id={"timeline"+contactId} role="tabpanel" aria-labelledby="timeline-tab">
                                    <div className='mb-9' style={{ height: 400, width: '100%',}}>
                                            {logList.length > 0
                                            ?
                                            <DataGrid
                                                rows={logList}
                                                columns={logContactcolumns}
                                                pageSize={5}
                                                rowsPerPageOptions={[5]}
                                                checkboxSelection
                                                sx={{
                                                    fontSize:14,
                                                    fontWeight:500,
                                                }}
                                            />
                                            : <div className="text-center w-100">
                                                <span className="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor"></path><rect x="7" y="17" width="6" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="12" width="10" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="7" width="6" height="2" rx="1" fill="currentColor"></rect><path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor"></path></svg></span>
                                                <p className='mt-3'>No Active Timelines Available</p>
                                            </div>
                                            }
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> :
            <div className="card bg_primary">
                <div className="card-body d-flex justify-content-between">
                    <div>
                        <h5 className='text-white'>Contact Details</h5>
                    </div>
                    <button onClick={minimaximize} className="mx-3 btn p-0">
                        <i className="fas fa-window-maximize text-white"></i>
                    </button>
                    <button type='button' id='kt_expand_close' data-bs-dismiss="offcanvas" onClick={closeDialogue} className="mx-3 btn p-0">
                        <i className="fas fa-times text-white"></i>
                    </button>
                </div>
            </div>
            }
        </div>  
    )

}
export {ContactDetails}