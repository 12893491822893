import React,{FC, useState,useEffect} from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import { Offcanvas, Toast } from 'bootstrap';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Container, Draggable } from 'react-smooth-dnd';
import { getSource, getTeamById, getTeams } from './core/_requests';

import App from './drag';



const initialValues = {
    source: "",
    team: "",     
    team_members: []
}

function getStyles(name: string, aminityName: string[], theme: Theme) {
    return {
        fontWeight:
        aminityName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
    };
}

const LeadSetting:  FC = () => {

    const taskSaveSchema = Yup.object().shape({
        team_name: Yup.string().required('task type is required'),
        branch: Yup.string().required('priority is required'),       
        team_members: Yup.string().required('Team Members is required'),             
    })

    const [loading, setLoading] = useState(false);    
    const [assignToName, setAssignToName] = useState<string[]>([]);
    const [assignToId, setAssignToId] = useState<string[]>([]);
    const [source, setSource] = useState<any[]>([]);
    const [teams, setTeams] = useState<any[]>([]);
    const [teamById, setTeamById] = useState<any[]>([]);
    
    const theme = useTheme(); 

    const SourceList =  async () => {
        const SourceResponse = await getSource()
        setSource(SourceResponse);
    }

    const TeamsList =  async () => {
        const TeamsResponse = await getTeams()
        setTeams(TeamsResponse);
    }


    const assingToChange = (event: SelectChangeEvent<typeof assignToName>) => {
        const {
          target: { value },
        } = event;

        var name = [];
        var id = [];
        var array = [];
    
        for(let i = 0; i < value.length; i++){
            array.push(value[i]);
          var fields = value[i].split('-');
    
          var n = fields[0];
          var d = fields[1];
    
          name.push(n);
          id.push(d);
        }
    
        setAssignToId(id);

        setAssignToName(name);
    };

    const formik = useFormik({
        initialValues,
        validationSchema: taskSaveSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
        //   console.log('lead form body');
        //   console.log(values);
          try {
    
            const body = {
                "source": "",
                "team": "",
                "tean_members":"" ,                
            }
    
            // const saveTaskData = await saveTask();
           
            // // document.getElementById('kt_contact')?.classList.remove('drawer-on');
            // if(saveTaskData != null){
            //     setLoading(false);
            //     document.getElementById('kt_task_close')?.click();
            //     var toastEl = document.getElementById('myToastAdd');
            //     const bsToast = new Toast(toastEl!);
            //     bsToast.show();
            //     // const characterResponse = await getTasks()
            //     // setTasks(characterResponse)
            //     resetForm();
            //     setAssignToName([]);
            // }
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
    }})

    const closeDialogue = () => {
        var offCanvasEl = document.getElementById('kt_addleadsettings');
        const bsOffcanvas = new Offcanvas(offCanvasEl!);
        bsOffcanvas.hide();
        offCanvasEl?.classList.add('d-none');
        offCanvasEl?.classList.remove('d-flex');
        document.body.style.overflow = '';
    }

    async function handleChange(event: { target: { value: any; }; }) {
        console.log(event.target.value);
        const tval = event.target.value;
        formik.setFieldValue('team', tval ?? '');
        const TeamByIdResponse = await getTeamById(tval);
        console.log("fgsduvkb");
        console.log(TeamByIdResponse);

        var membername = [];
        if(TeamByIdResponse.users_e_name != null){
            membername = TeamByIdResponse.users_e_name.split(",").map((e:any) => {
                return e;
            });
        }
        var name = [];
        var id = [];

        for(let i = 0; i < membername.length; i++){
            var fields = membername[i].split('-');

            var n = fields[0];
            var d = fields[1];

            name.push(n);
            id.push(d);
        }

        console.log("membername");
        console.log(membername);
        console.log("simple");
        console.log(name);
        setTeamById(name);
        setAssignToName([]);

        // setTeamById(TeamByIdResponse);
        
    }

    useEffect(() => {
        SourceList();
        TeamsList();        
    }, []);

    return(
        <div className='card bg-white h-100 w-50'>
            <div className='card-header w-100' id='kt_team_header'>
                <h3 className='card-title fw-bolder text-dark'>Add Lead Settings</h3>
                <div className='card-toolbar'>
                    <button
                    type='button'
                    className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                    id='kt_addleadsettings_close'
                    onClick={closeDialogue}
                    >
                        <img src={toAbsoluteUrl('/media/custom/cancel_black.svg')} className="svg_icon" alt='' />
                        {/* <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' /> */}
                    </button>
                </div>
            </div>
            <div className='card-body position-relative' id='kt_task_body'>
                <form noValidate onSubmit={formik.handleSubmit} >
                    <div className="row mx-0 p-4 main_bg accordion">
                    {/* <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Team Name</label>
                            <div className="input-group mb-3 input_prepend">
                                <input type="text" className="form-control" {...formik.getFieldProps('team_name')} placeholder="Enter Team Name"/>
                            </div>
                            {formik.touched.team_name && formik.errors.team_name && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>{formik.errors.team_name}</span>
                                </div>
                            </div>
                            )}
                        </div> */}
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Source</label>
                            <div className="input-group mb-3">
                                <select className="form-select btn btn-sm w-100" {...formik.getFieldProps('source')}>
                                <option value=''>Select</option>
                                {source.map((sourceobj,i) => {
                                    return (
                                            <option selected={i == 0 ? true: false} value={sourceobj.name} key={i}>{sourceobj.name}</option> 
                                    ) 
                                    })}  
                                </select>
                            </div>
                            {formik.touched.source && formik.errors.source && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>{formik.errors.source}</span>
                                </div>
                            </div>
                            )}
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Team</label>
                            <div className="input-group mb-3">
                                <select className="form-select btn btn-sm w-100" {...formik.getFieldProps('team')} onChange={handleChange}>
                                <option value=''>Select</option>
                                {teams.map((teamsobj,j) => {
                                    return ( 
                                        <option selected={j == 0 ? true: false} value={teamsobj.id} key={j}>{teamsobj.team_name}</option>                                                        
                                        ) 
                                    })}  
                                </select>
                            </div> 
                            {formik.touched.team && formik.errors.team && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>{formik.errors.team}</span>
                                </div>
                            </div>
                            )}                                       
                        </div>                          
                        <div className="col-md-12 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Team Members</label>
                            <FormControl sx={{ m: 0, width: 300, mt: 0 }}>
                                <Select
                                    multiple
                                    displayEmpty
                                    value={assignToName}
                                    onChange={assingToChange}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => {
                                        var name = [];
                                        var id = [];

                                        for(let i = 0; i < selected.length; i++){
                                            var fields = selected[i].split('-');

                                            var n = fields[0];
                                            var d = fields[1];

                                            name.push(n);
                                            id.push(d);
                                        }

                                        // setAssignToId(id)
;
                                        
                                        if (selected.length === 0) {
                                        return <p>Members</p>;
                                        }

                                        return name.join(', ');
                                    }}
                                    className='multi_select_field'
                                    
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                    <MenuItem disabled value="">
                                        <em>Members</em>
                                    </MenuItem>
                                    {teamById.map((teamByIdobj,k) => {
                                    return ( 
                                        <MenuItem
                                        className=''                                                    
                                        selected={k == 0 ? true: false} value={teamByIdobj} key={k}
                                        >
                                        {teamByIdobj} 
                                        </MenuItem>
                                            ) 
                                        })}                                 
                                </Select>
                            </FormControl>
                            
                        </div>
                        <div className="col-md-8 my-3 mx-auto">
                            <div className='input-group'>
                                <App names={teamById}/>
                            </div>                                       
                        </div>
                        {/* <div>
                            <DndProvider backend={HTML5Backend}>
                                <Container />
                            </DndProvider>
                        </div> */}
                    </div>
                    <div className='card-footer py-5 text-center' id='kt_task_footer'>
                        <button
                        type='submit'
                        id='kt_sign_up_submit'
                        className='btn btn_primary text-primary'
                        disabled={formik.isSubmitting}
                        >
                        {!loading && <span className='indicator-label'>Submit
                        <KTSVG
                        path='/media/custom/save_white.svg'
                        className='svg-icon-3 svg-icon-primary ms-2'
                        />
                        </span>}
                        {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                        </button>
                    </div>
                </form>
            </div>
            {/* <div className='card-body position-relative' id='kt_team_body'>
                <div className="accordion" id="accordionExample"> 
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Lead Details
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            
            
        </div>
    )
}


export {LeadSetting}