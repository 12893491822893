import axios, {AxiosResponse}  from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const SAVE_TRANSACTIONS_URL = `${API_URL}/save_transaction`
export const GET_TRANSACTIONS_URL = `${API_URL}/get_transactions`
export const GET_TRANSACTION_URL = `${API_URL}/get_transaction`
export const PUT_TRANSACTIONS_URL = `${API_URL}/put_transaction`
export const PUT_TRANSACTIONS_BROKERAGE_URL = `${API_URL}/put_transaction_payment_details`
export const PUT_TRANSACTIONS_INVOICE_URL = `${API_URL}/put_transaction_payment_status`
export const GET_TEAM_LEADER_URL = `${API_URL}/get_team_members_tl`
export const GET_USERS_ALL_URL = `${API_URL}/get_users_all`
export const GET_COMPANU_NAME_URL = `${API_URL}/get_properties_companyname`
export const GET_PROJECT_NAME_URL = `${API_URL}/get_properties_projectname`
export const DELETE_TRANSACTION_URL = `${API_URL}/delete_transaction`
export const USER_TRANSACTION_DD_URL = `${API_URL}/get_users_reassign_dropdown`

// getTrnsactions fetch api
export function getTrnsactionTLDrop(id:any, role:any) {
    return axios.get(USER_TRANSACTION_DD_URL+'/'+id+'/'+role+'/'+id)
    .then((response => response.data))
}

// saveTrnsactions fetch api
export function saveTrnsactions(postData:any) {
    return axios.post(SAVE_TRANSACTIONS_URL, postData)
    .then((response => response.data))
}

// getTrnsactions fetch api
export function getTrnsactions(id:any, role:any) {
    return axios.get(GET_TRANSACTIONS_URL+'/'+id+'/'+role+'/'+id)
    .then((response => response.data))
}

// getTrnsaction fetch api
export function getTrnsaction(id:any) {
    return axios.get(GET_TRANSACTION_URL+'/'+id)
    .then((response => response.data))
}

// update Trnsactions api
export function updateTrnsactions(Id:any ,body:any) {
    return axios.put(PUT_TRANSACTIONS_URL+'/'+Id, body)
    .then((response => response.data))
}

// update Trnsactions api
export function updateTrnsactionsBD(Id:any ,body:any) {
    return axios.put(PUT_TRANSACTIONS_BROKERAGE_URL+'/'+Id, body)
    .then((response => response.data))
}

// update Trnsactions api
export function updateTrnsactionsID(Id:any ,body:any) {
    return axios.put(PUT_TRANSACTIONS_INVOICE_URL+'/'+Id, body)
    .then((response => response.data))
}

// Delete Trnsactions api
export function deleteTrnsaction(Id:any) {
    return axios.put(DELETE_TRANSACTION_URL+'/'+Id)
    .then((response => response.data))
}

// getTeamLeader fetch api
export function getTeamLeader() {
    return axios.get(GET_TEAM_LEADER_URL)
    .then((response => response.data))
}

// getAllUsers fetch api
export function getAllUsers() {
    return axios.get(GET_USERS_ALL_URL)
    .then((response => response.data))
}

// getAllUsers fetch api
export function getCompanyName() {
    return axios.get(GET_COMPANU_NAME_URL)
    .then((response => response.data))
}

// getAllUsers fetch api
export function getProjectName() {
    return axios.get(GET_PROJECT_NAME_URL)
    .then((response => response.data))
}