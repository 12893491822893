import React,{FC, useEffect, useState} from 'react';
import { Offcanvas, Toast } from 'bootstrap';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import { deleteTrnsaction, getTrnsactions } from './core/_requests';
import { TransactionDrawer } from './transactionDrawer';
import { TransactionToolbar } from './transactionToolbar';
import Moment from 'moment';
import { TransactionDetails } from './transactionDetails';
import { useAuth } from '../../modules/auth';


const TransactionList: FC = () => {

    const {currentUser, logout} = useAuth();
    const [transactions, setTransactions] = useState<any[]>([]);
    const [detailData, setDetailData] = useState('');
    const [detailTab, setDetailTab] = useState('');
    const [detailClick, setDetailClick] = useState(false);
    const [transactionCheckList, setTransactionCheckList] = useState<any[]>([]);

    const TransactionsList =  async () => {
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const Response = await getTrnsactions(userId, roleId)
        setTransactions(Response);
        console.log('Response');
        console.log(Response);
    }

    const openModal = (Id:any, tabType:any) => {
        setDetailData(Id);
        setDetailTab(tabType);
        setDetailClick(true);
        document.body.className += ' detail_opened';
        var offCanvasEl = document.getElementById('kt_expand');
        offCanvasEl?.classList.remove('invisible');
        const bsOffcanvas = new Offcanvas(offCanvasEl!);
        bsOffcanvas.show();
    }

    const transactionDelete = async (id:any) => {
        const response = await deleteTrnsaction(id)
        if (response != null) {
            TransactionsList()
            var toastEl = document.getElementById('myToastDeleteTransaction');
            const bsToast = new Toast(toastEl!);
            bsToast.show();
        }
    }

    const transactionOnSelect = (e:any, id:any) => {
        console.log('sdsdsdsdsdsd');
        console.log(e.target.checked);
        const newArr = [...transactionCheckList];
        if(e.target.checked != false){
            setTransactionCheckList(transactionCheckList => [...transactionCheckList, id]);
        } else {
            newArr.splice(newArr.findIndex(item => item === id), 1);
            setTransactionCheckList(newArr);
        }
        console.log(transactionCheckList);
    }

    useEffect(() => {
        TransactionsList();
    }, []);

    return(
        <div className="transaction_page">
            <TransactionToolbar/>
            <TransactionDrawer/>
            <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastAddTransaction">
                <div className="toast-header">
                    <strong className="me-auto">Success</strong>
                    <button aria-label="Close" className="btn-close btn-close-color-white" 
                                data-bs-dismiss="toast" type="button">
                    </button>
                </div>
                <div className="toast-body">
                    <div>Transaction Created Successfully!</div>
                </div>
            </div>
            <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastUpdateTransaction">
                <div className="toast-header">
                    <strong className="me-auto">Success</strong>
                    <button aria-label="Close" className="btn-close btn-close-color-white" 
                                data-bs-dismiss="toast" type="button">
                    </button>
                </div>
                <div className="toast-body">
                    <div>Transaction Updated Successfully!</div>
                </div>
            </div>
            <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastDeleteTransaction">
                <div className="toast-header">
                    <strong className="me-auto">Success</strong>
                    <button aria-label="Close" className="btn-close btn-close-color-white" 
                                data-bs-dismiss="toast" type="button">
                    </button>
                </div>
                <div className="toast-body">
                    <div>Transaction Deleted Successfully!</div>
                </div>
            </div>
            <button className='d-none' type='button' id='transaction_refresh_btn' onClick={TransactionsList}>refresh</button>
            <div className="row">
                <div className="card-group">
                    {transactions.map((trans, i) => {
                    return(
                    <div className="col-xxl-4 col-xl-6 col-lg-12 col-md-12 col-sm-12 mb-4" key={i}>
                        <div className="card h-100 mb-5 mb-xl-8 mx-2 bs_1">
                            <div className='card-body px-3 pt-3 pb-0'>
                                <div className="d-flex align-items-center justify-content-between mb-5">
                                    <div className="d-flex align-items-center">
                                        <form action="">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" onClick={(e) => transactionOnSelect(e, trans.id)} id={"transaction"+trans.id}/>
                                                <label className="form-check-label id_label" htmlFor={"transaction"+trans.id}>
                                                    {trans.id}
                                                </label>
                                            </div>
                                        </form>
                                        {detailClick && detailData == trans.id &&
                                        <div
                                            id={'kt_transaction_details_toggle'+trans.id}
                                            className='expand_area detail_page_view offcanvas offcanvas-end justify-content-end w-100 bg-transparent d-flex'
                                        >
                                            <TransactionDetails  transactionId={trans.id} setDetailClicked={setDetailClick} />
                                        </div>}
                                        <div className="ms-3 ml-2 d-flex align-items-center">
                                            <img src={toAbsoluteUrl('/media/custom/user.svg')} alt="" className="icon me-2" />
                                            <p className="mb-0 contact_name">{trans.client_name}</p>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <a href='#' onClick={() => openModal(trans.id, 'overview')}><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></a>
                                        {/* <a href="#"><img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/></a> */}
                                        <div className="btn-group">
                                            <a className="" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/>
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                                <li><a className="dropdown-item" href="#"  onClick={() => openModal(trans.id, 'overview')}>Edit</a></li>
                                                <li><a className="dropdown-item" href="#" data-bs-toggle='modal'
                                                        data-bs-target={'#delete_confirm_popup_transaction'+trans.id}>Delete</a></li>
                                            </ul>
                                        </div>
                                        <div className='modal fade' id={'delete_confirm_popup_transaction'+trans.id} aria-hidden='true'>
                                            <div className='modal-dialog modal-dialog-centered'>
                                                <div className='modal-content'>
                                                    <div className='modal-header'>
                                                        <h3>Confirmation</h3>
                                                        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                                        </div>
                                                    </div>
                                                    <div className='modal-body py-lg-10 px-lg-10'>
                                                        <p>Are you sure want to delete?</p>
                                                        <div className='d-flex align-items-center justify-content-end'>
                                                            <button className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal'>
                                                                No
                                                            </button>
                                                            <button className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal'  onClick={() => transactionDelete(trans.id)}>
                                                                Yes
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="flex-shrink-0">
                                        <img src={toAbsoluteUrl('/media/avatars/300-23.jpg')} className="user_img" alt='' />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <div className='d-flex align-items-center mb-3 '>
                                        <a href={'mailto:'+ trans.email_id} className="text-dark d-flex align-items-center">
                                            <img src={toAbsoluteUrl('/media/custom/envelope.svg')} alt="" className="icon me-2"/>
                                            <p className="mb-0 fixed_text">{trans.email_id}</p>
                                        </a>
                                        </div>                                        
                                        <div className="">
                                            <div className="d-flex align-items-center justify-content-between mb-2">
                                                <a href={'tel:'+ trans.contact_number} className="mb-0 d-flex flex-wrap text-dark">
                                                    <span>
                                                        <img src={toAbsoluteUrl('/media/custom/phone.svg')} alt="" className="icon me-2" />
                                                    </span>
                                                        {trans.contact_number}
                                                </a>
                                                <a href={"https://api.whatsapp.com/send?phone="+ trans.contact_number} target="popup" onClick={(e) => window.open('http://kanishkkunal.com','popup','width=1400,height=800')} className="d-flex align-items-center mb-2 text-dark">
                                                        <p className="mb-0 d-flex flex-wrap">
                                                            <span><img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} alt="" className="icon me-1" /></span>
                                                                {trans.contact_number}
                                                        </p>
                                                </a>
                                            </div>
                                        </div>   
                                    </div>
                                </div>
                                <div className='mb-3 mt-6 transaction_details'>
                                    <div className="row">
                                        <div className="col-xxl-4 col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1" title={trans.developer_name}>
                                            <div className="d-flex align-items-center single_item overflow-hidden">
                                                <img src={toAbsoluteUrl('/media/custom/lead/assign_9.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Developer</small>
                                                    <p className="mb-0 fw-500 text-nowrap">{trans.developer_fullname}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1" title={trans.city_name}>
                                            <div className="d-flex align-items-center single_item overflow-hidden">
                                                <img src={toAbsoluteUrl('/media/custom/lead/location_10.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Locality</small>
                                                    <p className="mb-0 fw-500 text-nowrap">{trans.city_name}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1" title={trans.property_name_of_building}>
                                            <div className="d-flex align-items-center single_item overflow-hidden">
                                                <img src={toAbsoluteUrl('/media/custom/project_type.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Project</small>
                                                    <p className="mb-0 fw-500 text-nowrap">{trans.property_name_of_building}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1" title={trans.block_no}>
                                            <div className="d-flex align-items-center single_item overflow-hidden">
                                                <img src={toAbsoluteUrl('/media/custom/lead/bhk_4.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Block No.</small>
                                                    <p className="mb-0 fw-500 text-nowrap">{trans.block_no}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1" title={trans.floor_no}>
                                            <div className="d-flex align-items-center single_item overflow-hidden">
                                                <img src={toAbsoluteUrl('/media/custom/floor.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Floor</small>
                                                    <p className="mb-0 fw-500 text-nowrap">{trans.floor_no}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1" title={trans.client_name}>
                                            <div className="d-flex align-items-center single_item overflow-hidden">
                                                <img src={toAbsoluteUrl('/media/custom/project_name.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Client</small>
                                                    <p className="mb-0 fw-500 text-nowrap">{trans.client_name}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1" title={trans.team_leader_name}>
                                            <div className="d-flex align-items-center single_item overflow-hidden">
                                                <img src={toAbsoluteUrl('/media/custom/lead/location_10.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Team Leader</small>
                                                    <p className="mb-0 fw-500 text-nowrap">{trans.team_leader_name}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1" title={trans.bhk_type}>
                                            <div className="d-flex align-items-center single_item overflow-hidden">
                                                <img src={toAbsoluteUrl('/media/custom/lead/bhk_4.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">BHK Type</small>
                                                    <p className="mb-0 fw-500 text-nowrap">{trans.bhk_type}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1" title={trans.shared_with_name}>
                                            <div className="d-flex align-items-center single_item overflow-hidden">
                                                <img src={toAbsoluteUrl('/media/custom/lead/bhk_4.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Shared With</small>
                                                    <p className="mb-0 fw-500 text-nowrap">{trans.shared_with_name}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1" title={trans.closed_by_name}>
                                            <div className="d-flex align-items-center single_item overflow-hidden">
                                                <img src={toAbsoluteUrl('/media/custom/lead/lead_1.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Closed By</small>
                                                    <p className="mb-0 fw-500 text-nowrap">{trans.closed_by_name}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1" title={trans.basic_price}>
                                            <div className="d-flex align-items-center single_item overflow-hidden">
                                                <img src={toAbsoluteUrl('/media/custom/lead/budget_3.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Basic Price</small>
                                                    <p className="mb-0 fw-500 text-nowrap">{trans.basic_price}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1" title={trans.agreement_value}>
                                            <div className="d-flex align-items-center single_item overflow-hidden">
                                                <img src={toAbsoluteUrl('/media/custom/lead/budget_3.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Agreement Value</small>
                                                    <p className="mb-0 fw-500 text-nowrap">{trans.agreement_value}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1" title={trans.amount_received}>
                                            <div className="d-flex align-items-center single_item overflow-hidden">
                                                <img src={toAbsoluteUrl('/media/custom/lead/budget_3.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Collected</small>
                                                    <p className="mb-0 fw-500 text-nowrap">{trans.amount_received}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1" title={trans.pending_receivable_amount}>
                                            <div className="d-flex align-items-center single_item overflow-hidden">
                                                <img src={toAbsoluteUrl('/media/custom/lead/budget_3.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Due</small>
                                                    <p className="mb-0 fw-500 text-nowrap">{trans.pending_receivable_amount}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1" title={trans.discount_value}>
                                            <div className="d-flex align-items-center single_item overflow-hidden">
                                                <img src={toAbsoluteUrl('/media/custom/lead/budget_3.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Cashback</small>
                                                    <p className="mb-0 fw-500 text-nowrap">{trans.discount_value}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1" title={trans.lead_source_name}>
                                            <div className="d-flex align-items-center single_item overflow-hidden">
                                                <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Sources</small>
                                                    <p className="mb-0 fw-500 text-nowrap">{trans.lead_source_name}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1" title={trans.created_by_name}>
                                            <div className="d-flex align-items-center single_item overflow-hidden">
                                                <img src={toAbsoluteUrl('/media/custom/lead/assign_9.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Created By</small>
                                                    <p className="mb-0 fw-500 text-nowrap">{trans.created_by_name}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1" title={Moment(trans.date).format('DD-MMMM-YYYY')}>
                                            <div className="d-flex align-items-center single_item overflow-hidden">
                                                <img src={toAbsoluteUrl('/media/custom/lead/created_8.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Booked Date</small>
                                                    <p className="mb-0 fw-500 text-nowrap">{Moment(trans.booking_date).format('DD-MM-YYYY')}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1" title={Moment(trans.created_at).format('DD-MMMM-YYYY')}>
                                            <div className="d-flex align-items-center single_item overflow-hidden">
                                                <img src={toAbsoluteUrl('/media/custom/lead/created_8.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Created Date</small>
                                                    <p className="mb-0 fw-500 text-nowrap">{Moment(trans.created_att).format('DD-MM-YYYY')}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1" title={Moment(trans.updated_at).format('DD-MMMM-YYYY')}>
                                            <div className="d-flex align-items-center single_item overflow-hidden">
                                                <img src={toAbsoluteUrl('/media/custom/lead/created_8.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Updated Date</small>
                                                    <p className="mb-0 fw-500 text-nowrap">{Moment(trans.updated_at).format('DD-MM-YYYY')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer border-0 p-1">
                                    <div className="row">
                                        <div className="col-7 col-xl-8 icons_bar d-flex flex-wrap">
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Notes">
                                                <img src={toAbsoluteUrl('/media/custom/notes.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    13
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="File">
                                                <img src={toAbsoluteUrl('/media/custom/file.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    16
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Task">
                                                <img src={toAbsoluteUrl('/media/custom/task.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    88
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Message">
                                                <img src={toAbsoluteUrl('/media/custom/message.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    5
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_bg rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Whatsapp">
                                                <img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} className="svg_icon" alt=''/>
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    13
                                                </span>
                                            </a>
                                        </div>
                                        <div className="col-5 col-xl-4 d-flex align-items-center justify-content-end">
                                            <select className="form-select toggle_white toggle_white btn_primary rounded-pill btn-sm cursor-pointer status_btn" aria-label="Default select example">
                                                <option selected>Status</option>
                                                <option value="1">Pending</option>
                                                <option value="2">Completed</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    )})}
                </div>
            </div>
        </div>
    )
}
export {TransactionList}