import { DataGrid, GridColDef } from '@mui/x-data-grid'
import React,{FC, useEffect, useState} from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { FinanceDrawer } from './financeDrawers';
import { FinanceToolbar } from './financeToolbar';
import moment from 'moment';
import { Expenses } from './expense/expenses';
import { FeeConfirmation } from './fee confirmation/feeConfirmation';
import { ProformaInvoice } from './proforma_invoice/proformaInvoice';
import { Invoice } from './invoice/invoice';
import { Collection } from './collection/collection';
import { Incentive } from './incentive/incentive';
import { Cashback } from './cashback/cashback';
import { getLeadSource } from '../lead/core/_requests';
import { getProjects } from '../task/core/_requests';
import { getCity, getUnitType } from '../property/core/_requests';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {useAuth} from '../../modules/auth'
import { Theme, useTheme } from '@mui/material/styles';
import { getTrnsactions } from '../transaction/core/_requests';
import { BrokerageForm } from './transaction details/brokerage';
import { InvoicingForm } from './transaction details/invoicing';
import MaterialTable from 'material-table'
import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, aminityName, theme) {
    return {
        fontWeight:
        aminityName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
    };
}

  const initialValues = {
    lead_creation_date: '',
    booking_date: '',
    city: '',
    lead_source: '',
    team_leader: '',
    closed_by: '',
    shared_with: '',
    developer_name: '',
    project_name: '',
    client_name: '',
    contact_number: '',
    email_id: '',
    discount_value: '',
    block_no: '',
    unit_no: '',
    floor_no: '',
    bhk_type: '',
    sizes: '',
    basic_price: '',
    frc: '',
    plc: '',
    car_parking_cost: '',
    agreement_value: '',
    pan: '',
    dob: '',
    doa: '',
    correspondence_address: '',
    Brokerage_percentage: '',
    brokerage_value: '',
    discount_amount: '',
    revenue: '',
    aop_per: '',
    discount_paid_status: '',
    tds_value: '',
    s_gst_per: '',
    c_gst_per: '',
    i_gst_per: '',
    gst_amount: '',
    gross_brokerage_value: '',
    tds_per_ded_builder: '',
    tds_amount: '',
    after_tds_brokerage: '',
    actual_receivable_amount: '',
    incentive_per: '',
    incentive_without_tds: '',
    tds_per: '',
    net_incentive_earned: '',
    invoice_status: '',
    raised: '',
    pending: '',
    payment_status: '',
    amount_received: '',
    receiving_date: '',
    pending_brokerage: '',
    s_gst_2: '',
    c_gst_3: '',
    i_gst_4: '',
    gst_amount2: '',
    gross_brokerage_value2: '',
    tds_reducted_by_builder3: '',
    tds_amount2: '',
    after_tds_brokearge5: '',
    pending_receivable_amount: '',
    created_by: '',
}
  
const Finance = () => { 

    const [pageHeight, setPageHeight] = useState('');
    const setHeight = () => {
      let heigh ;
      if(window.innerHeight > 720) {
        heigh = '600px'
      } else {
        heigh = '450px'
      }
      setPageHeight(heigh)
    } 
    useEffect(() => {
        setHeight()
      }, [window.innerHeight]);

    const theme = useTheme(); 
    const {currentUser, logout} = useAuth();
    const [tab, setTab] = useState('transaction');
    const [loading, setLoading] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [city, setCity] = useState([]);
    const [unitType, setUnitType] = useState([]);
    const [projects, setProjects] = useState([]);
    const [leadSource, setLeadSource] = useState([]);
    const [taskType, setTaskType] = useState([]);
    const [teamLeader, setTeamLeader] = useState([]);
    const [users, setUsers] = useState([]);
    const [transactionsId, setTransactionsId] = useState('');

    console.log('transactionsId', transactionsId)

    const transactionSaveSchema = Yup.object().shape({
        date: Yup.string(),
        lead_creation_date: Yup.string(),
        booking_date: Yup.string(),
        city: Yup.string(),
        lead_source: Yup.string(),
        team_leader: Yup.string(),
        closed_by: Yup.string(),
        shared_with: Yup.string(),
        developer_name: Yup.string(),
        project_name: Yup.string(),
        client_name: Yup.string(),
        contact_number: Yup.string(),
        email_id: Yup.string(),
        discount_value: Yup.string(),
        block_no: Yup.string(),
        unit_no: Yup.string(),
        floor_no: Yup.string(),
        bhk_type: Yup.string(),
        sizes: Yup.string(),
        basic_price: Yup.string(),
        frc: Yup.string(),
        plc: Yup.string(),
        car_parking_cost: Yup.string(),
        agreement_value: Yup.string(),
        pan: Yup.string(),
        dob: Yup.string(),
        doa: Yup.string(),
        correspondence_address: Yup.string(),
        Brokerage_percentage: Yup.string(),
        Brokerage_value: Yup.string(),
        discount_amount: Yup.string(),
        revenue: Yup.string(),
        aop_per: Yup.string(),
        discount_paid_status: Yup.string(),
        tds_value: Yup.string(),
        s_gst_per: Yup.string(),
        c_gst_per: Yup.string(),
        i_gst_per: Yup.string(),
        gst_amount: Yup.string(),
        gross_brokerage_value: Yup.string(),
        tds_per_ded_builder: Yup.string(),
        tds_amount: Yup.string(),
        after_tds_brokerage: Yup.string(),
        actual_receivable_amount: Yup.string(),
        incentive_per: Yup.string(),
        incentive_without_tds: Yup.string(),
        tds_per: Yup.string(),
        net_incentive_earned: Yup.string(),
        invoice_status: Yup.string(),
        raised: Yup.string(),
        pending: Yup.string(),
        payment_status: Yup.string(),
        amount_received: Yup.string(),
        receiving_date: Yup.string(),
        pending_brokerage: Yup.string(),
        s_gst_2: Yup.string(),
        c_gst_3: Yup.string(),
        i_gst_4: Yup.string(),
        gst_amount2: Yup.string(),
        gross_brokerage_value2: Yup.string(),
        tds_reducted_by_builder3: Yup.string(),
        tds_amount2: Yup.string(),
        after_tds_brokearge5: Yup.string(),
        pending_receivable_amount: Yup.string(),
    })    

    const formikid = useFormik({
        initialValues,
        validationSchema: transactionSaveSchema ,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
        //   console.log('lead form body');
        //   console.log(values);
        console.log('sddddddddassdsf');
          try {      

            var userId = currentUser?.id;
            var roleId = currentUser?.designation;
            
            var id_body = {
                "raised": values.raised,
                "pending": values.pending,
                "payment_status": values.payment_status,
                "amount_received": values.amount_received,
                "receiving_date": values.receiving_date == "Invalid date" ? '' : values.receiving_date,
                "pending_brokerage": values.pending_brokerage,
                "s_gst_2": values.s_gst_2,
                "c_gst_3": values.c_gst_3,
                "i_gst_4": values.i_gst_4,
                "gst_amount2": values.gst_amount2,
                "gross_brokerage_value2": values.gross_brokerage_value2,
                "tds_reducted_by_builder3": values.tds_reducted_by_builder3,
                "tds_amount2": values.tds_amount2,
                "after_tds_brokearge5": values.after_tds_brokearge5,
                "pending_receivable_amount": values.pending_receivable_amount,
                "created_by":userId
                }
          
            // const saveTaskData = await updateTrnsactionsID(transactionId, id_body);
    
            // console.log('saveTaskData');
            // console.log(saveTaskData);
            // // document.getElementById('kt_contact')?.classList.remove('drawer-on');
            // if(saveTaskData != null){
            //     setLoading(false);
            //     document.getElementById('kt_transaction_details_close')?.click();
            //     document.getElementById('transaction_refresh_btn')?.click();
            //     var toastEl = document.getElementById('myToastUpdateTransaction');
            //     const bsToast = new Toast(toastEl!);
            //     bsToast.show();
            //     // resetForm();
            // }
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
    }})

    const LeadSourceList =  async () => {
        const LeadSourceResponse = await getLeadSource()        
        setLeadSource(LeadSourceResponse);
    }

    const projectList =  async () => {
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const projectResponse = await getProjects(userId, roleId);
        setProjects(projectResponse);
    }

    const UnitTypeList =  async () => {
        const UnitTypeResponse = await getUnitType()        
        setUnitType(UnitTypeResponse);
    }  
    
    const CityList =  async () => {
        const CityResponse = await getCity()
        setCity(CityResponse);
    }

    const TransactionsList =  async () => {
        setLoading(true)
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const Response = await getTrnsactions(userId, roleId)
        setTransactions(Response);
        setLoading(false)
    }

    // const transactionsList = [
    //     { field: 'id', renderCell: ({value}) => <>
    //     <button className='btn btn-sm btn-icon' title='More' id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
    //         <KTSVG path="/media/icons/duotune/general/gen052.svg" className="svg-icon-muted svg-icon-2 btn-icon btn btn-sm btn-active-color-primary btn-active-bg-gray-400" />
    //     </button>
    //     <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
    //         <li className="list-group p-2 ps-4 btn-icon btn-active-bg-gray-700"><a type='button' id='kt_feeconfirmation_form_toggle' onClick={() => setTransactionsId(value)}>Fee Confirmation</a></li>
    //         <li className="list-group p-2 ps-4 btn-icon btn-active-bg-gray-700"><a type='button' id='kt_proformaInvoice_form_toggle' onClick={() => setTransactionsId(value)}>Proforma Invoice</a></li>
    //         <li className="list-group p-2 ps-4 btn-icon btn-active-bg-gray-700"><a type='button' id='kt_Invoice_form_toggle' onClick={() => setTransactionsId(value)}>Invoice</a></li>
    //         <li className="list-group p-2 ps-4 btn-icon btn-active-bg-gray-700"><a type='button' id='kt_expense_form_toggle'>Add Expense</a></li>
    //     </ul>
    //     <button className='btn btn-sm btn-icon' title='Brokerage Details' id='kt_brokerage_details_toggle'
    //     onClick={() => setTransactionsId(value)}
    //     >
    //         <KTSVG path="/media/icons/duotune/graphs/gra006.svg" className="svg-icon-muted svg-icon-2 btn-icon btn btn-sm btn-active-color-primary btn-active-bg-gray-400" />
    //     </button>
    //     <button className='btn btn-sm btn-icon' title='Invoicing Details' id='kt_invoicing_details_toggle'
    //     onClick={() => setTransactionsId(value)}
    //     >
    //         <KTSVG path="/media/icons/duotune/general/gen005.svg" className="svg-icon-muted svg-icon-2 btn-icon btn btn-sm btn-active-color-primary btn-active-bg-gray-400" />
    //     </button>
    //     </>, headerName: 'Actions', width: 150, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'created_at', valueFormatter: ({value}) => Moment(value).format('DD-MM-YYYY'), headerName: 'Lead Creation Date', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'created_at', valueFormatter: ({value}) => Moment(value).format('MMMM'), headerName: 'Month', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'booking_date', valueFormatter: ({value}) => Moment(value).format('DD-MMMM-YYYY'), headerName: 'Booking Date', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'city_name', headerName: 'City', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'developer_fullname', headerName: 'Source', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'team_leader_name', headerName: 'Team Leader / Shared With', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'closed_by_name', headerName: 'Closed By / Shared With', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'developer_fullname', headerName: 'Builder Name', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'property_name_of_building', headerName: 'Project Name', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'client_name', headerName: 'Client Name', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'contact_number', headerName: 'Contact No.', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'email_id', headerName: 'Email Id', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'discount_value', headerName: 'Discount (If Any)', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'block_no', headerName: 'Block / Tower No.', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'unit_no', headerName: 'Unit No.', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'floor_no', headerName: 'Floor No.', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'bhk_type', headerName: 'BHK Types', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'sizes', headerName: 'Sizes', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'basic_price', headerName: 'Basic Price', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'frc', headerName: 'FRC', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'plc', headerName: 'PLC', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'car_parking_cost', headerName: 'Car Parking', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'agreement_value', headerName: 'Agreement Value', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'pan', headerName: 'PAN', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'dob', headerName: 'DOB', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'doa', headerName: 'DOA', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'developer_fullname', headerName: 'Correspondence Address', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'Brokerage_percentage', headerName: 'Brokerage %', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'aop_per', headerName: 'AOP %', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'Brokerage_value', headerName: 'Brokerage Value', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'discount_amount', headerName: 'Discount Amount', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'revenue', headerName: 'Revenue', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 's_gst_per', headerName: 'S GST %', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'c_gst_per', headerName: 'C GST %', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'i_gst_per', headerName: 'I GST %', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'gst_amount', headerName: 'GST Amount', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'gross_brokerage_value', headerName: 'Gross Brokerage Value', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'tds_per_ded_builder', headerName: 'TDS % Deducted by Builder', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'tds_amount', headerName: 'TDS Amount', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'after_tds_brokerage', headerName: 'After TDS Brokearge', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'actual_receivable_amount', headerName: 'Actual Receivable Amount', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'incentive_per', headerName: 'Incentive%', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'incentive_without_tds', headerName: 'Incentive Without TDS', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'tds_per', headerName: 'TDS%', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'net_incentive_earned', headerName: 'Net Incentive Earned', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'invoice_status', headerName: 'Invoice Status', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'raised', headerName: 'Raised %', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'pending', headerName: 'Pending %', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'payment_status', headerName: 'Payment Status', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'amount_received', headerName: 'Amount Received', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'receiving_date', headerName: 'Receiving Date', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'pending_brokerage', headerName: 'Pending Brokerage', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 's_gst_2', headerName: 'S GST %2', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'c_gst_3', headerName: 'C GST %3', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'i_gst_4', headerName: 'I GST %4', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'gst_amount2', headerName: 'GST Amount2', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'gross_brokerage_value2', headerName: 'Gross Brokerage Value2', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'tds_reducted_by_builder3', headerName: 'TDS % Deducted by Builder3', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'tds_amount2', headerName: 'TDS Amount2', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'after_tds_brokearge5', headerName: 'After TDS Brokearge5', width: 350, headerClassName: 'dg_header', disableReorder: false },
    //     { field: 'pending_receivable_amount', headerName: 'Pending Receivable Amount', width: 350, headerClassName: 'dg_header', disableReorder: false },
        
    //   ];

      const columns = [
        { field: 'id', cellStyle: {whiteSpace: 'nowrap'}, title: 'Id'},
        { field: 'id', title: 'Actions', cellStyle: {whiteSpace: 'nowrap'}, render: rowData => <>
            <button className='btn btn-sm btn-icon' title='More' id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                <KTSVG path="/media/icons/duotune/general/gen052.svg" className="svg-icon-muted svg-icon-2 btn-icon btn btn-sm btn-active-color-primary btn-active-bg-gray-400" />
            </button>
            <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                <li className="list-group p-2 ps-4 btn-icon btn-active-bg-gray-700"><a type='button' id='kt_feeconfirmation_form_toggle' onClick={() => setTransactionsId(rowData.id)}>Fee Confirmation</a></li>
                <li className="list-group p-2 ps-4 btn-icon btn-active-bg-gray-700"><a type='button' id='kt_proformaInvoice_form_toggle' onClick={() => setTransactionsId(rowData.id)}>Proforma Invoice</a></li>
                <li className="list-group p-2 ps-4 btn-icon btn-active-bg-gray-700"><a type='button' id='kt_Invoice_form_toggle' onClick={() => setTransactionsId(rowData.id)}>Invoice</a></li>
                <li className="list-group p-2 ps-4 btn-icon btn-active-bg-gray-700"><a type='button' id='kt_expense_form_toggle'>Add Expense</a></li>
            </ul>
            <button className='btn btn-sm btn-icon' title='Brokerage Details' id='kt_brokerage_details_toggle'
            onClick={() => {
                setTransactionsId(rowData.id);
                console.log("jjjjjjjjjjjj",rowData);
            }}
            >
                <KTSVG path="/media/icons/duotune/graphs/gra006.svg" className="svg-icon-muted svg-icon-2 btn-icon btn btn-sm btn-active-color-primary btn-active-bg-gray-400" />
            </button>
            <button className='btn btn-sm btn-icon' title='Invoicing Details' id='kt_invoicing_details_toggle'
            onClick={() => setTransactionsId(rowData.id)}
            >
                <KTSVG path="/media/icons/duotune/general/gen005.svg" className="svg-icon-muted svg-icon-2 btn-icon btn btn-sm btn-active-color-primary btn-active-bg-gray-400" />
            </button>
            </>, headerName: 'Actions', width: 150, headerClassName: 'dg_header', disableReorder: false },
       { field: 'created_at', cellStyle: {whiteSpace: 'nowrap'}, title: 'Lead Creation Date', render: rowData => moment(rowData).format('DD-MM-YYYY')},
       { field: 'created_at', cellStyle: {whiteSpace: 'nowrap'}, title: 'Month', render: rowData => moment(rowData).format('MMMM')},
       { field: 'booking_date', cellStyle: {whiteSpace: 'nowrap'}, title: 'Bookingg Date', render: rowData => moment(rowData).format('DD-MM-YYYY')},
       { field: 'city_name', cellStyle: {whiteSpace: 'nowrap'}, title: 'City'},
       { field: 'lead_source_name', cellStyle: {whiteSpace: 'nowrap'}, title: 'Source'},
       { field: 'team_leader_name', cellStyle: {whiteSpace: 'nowrap'}, title: 'Team Leader / Shared With'},
       { field: 'closed_by_name', cellStyle: {whiteSpace: 'nowrap'}, title: 'Closed By / Shared With'},
       { field: 'developer_fullname', cellStyle: {whiteSpace: 'nowrap'}, title: 'Builder Name'},
       { field: 'property_name_of_building', cellStyle: {whiteSpace: 'nowrap'}, title: 'Project Name'},
       { field: 'client_name', cellStyle: {whiteSpace: 'nowrap'}, title: 'Client Name'},
       { field: 'contact_number', cellStyle: {whiteSpace: 'nowrap'}, title: 'Contact No.'},
       { field: 'email_id', cellStyle: {whiteSpace: 'nowrap'}, title: 'Email Id'},
       { field: 'discount_value', cellStyle: {whiteSpace: 'nowrap'}, title: 'Discount (If Any)'},
       { field: 'block_no', cellStyle: {whiteSpace: 'nowrap'}, title: 'Block / Tower No.'},
       { field: 'unit_no', cellStyle: {whiteSpace: 'nowrap'}, title: 'Unit No.'},
       { field: 'floor_no', cellStyle: {whiteSpace: 'nowrap'}, title: 'Floor No.'},
       { field: 'bhk_type', cellStyle: {whiteSpace: 'nowrap'}, title: 'BHK Types'},
       { field: 'sizes', cellStyle: {whiteSpace: 'nowrap'}, title: 'Sizes'},
       { field: 'basic_price', cellStyle: {whiteSpace: 'nowrap'}, title: 'Basic Price'},
       { field: 'frc', cellStyle: {whiteSpace: 'nowrap'}, title: 'FRC'},
       { field: 'plc', cellStyle: {whiteSpace: 'nowrap'}, title: 'PLC'},
       { field: 'car_parking_cost', cellStyle: {whiteSpace: 'nowrap'}, title: 'Car Parking'},
       { field: 'agreement_value', cellStyle: {whiteSpace: 'nowrap'}, title: 'Agreement Value'},
       { field: 'pan', cellStyle: {whiteSpace: 'nowrap'}, title: 'PAN'},
       { field: 'dob', cellStyle: {whiteSpace: 'nowrap'}, title: 'DOB'},
       { field: 'doa', cellStyle: {whiteSpace: 'nowrap'}, title: 'DOA'},
       { field: 'client_address_1', cellStyle: {whiteSpace: 'nowrap'}, title: 'Correspondence Address'},
       { field: 'Brokerage_percentage', cellStyle: {whiteSpace: 'nowrap'}, title: 'Brokerage %'},
       { field: 'aop_per', cellStyle: {whiteSpace: 'nowrap'}, title: ' AOP %'},
       { field: 'Brokerage_value', cellStyle: {whiteSpace: 'nowrap'}, title: 'Brokerage Value'},
       { field: 'discount_amount', cellStyle: {whiteSpace: 'nowrap'}, title: 'Discount Amount'},
       { field: 'revenue', cellStyle: {whiteSpace: 'nowrap'}, title: 'Revenue'},
       { field: 's_gst_per', cellStyle: {whiteSpace: 'nowrap'}, title: 'S GST %'},
       { field: 'c_gst_per', cellStyle: {whiteSpace: 'nowrap'}, title: 'C GST %'},
       { field: 'i_gst_per', cellStyle: {whiteSpace: 'nowrap'}, title: 'I GST %'},
       { field: 'gst_amount', cellStyle: {whiteSpace: 'nowrap'}, title: 'GST Amount'},
       { field: 'gross_brokerage_value', cellStyle: {whiteSpace: 'nowrap'}, title: 'Gross Brokerage Value'},
       { field: 'tds_per_ded_builder', cellStyle: {whiteSpace: 'nowrap'}, title: 'TDS % Deducted by Builder'},
       { field: 'tds_amount', cellStyle: {whiteSpace: 'nowrap'}, title: 'TDS Amount'},
       { field: 'after_tds_brokerage', cellStyle: {whiteSpace: 'nowrap'}, title: 'After TDS Brokearge'},
       { field: 'actual_receivable_amount', cellStyle: {whiteSpace: 'nowrap'}, title: 'Actual Receivable Amount'},
       { field: 'incentive_per', cellStyle: {whiteSpace: 'nowrap'}, title: 'Incentive%'},
       { field: 'incentive_without_tds', cellStyle: {whiteSpace: 'nowrap'}, title: 'Incentive Without TDS'},
       { field: 'tds_per', cellStyle: {whiteSpace: 'nowrap'}, title: 'TDS%'},
       { field: 'net_incentive_earned', cellStyle: {whiteSpace: 'nowrap'}, title: 'Net Incentive Earned'},
       { field: 'invoice_status', cellStyle: {whiteSpace: 'nowrap'}, title: 'Invoice Status'},
       { field: 'raised', cellStyle: {whiteSpace: 'nowrap'}, title: 'Raised %'},
       { field: 'pending', cellStyle: {whiteSpace: 'nowrap'}, title: 'Pending %'},
       { field: 'payment_status', cellStyle: {whiteSpace: 'nowrap'}, title: 'Payment Status'},
       { field: 'amount_received', cellStyle: {whiteSpace: 'nowrap'}, title: 'Amount Received'},
       { field: 'receiving_date', cellStyle: {whiteSpace: 'nowrap'}, title: 'Receiving Date'},
       { field: 'pending_brokerage', cellStyle: {whiteSpace: 'nowrap'}, title: 'Pending Brokerage'},
       { field: 's_gst_2', cellStyle: {whiteSpace: 'nowrap'}, title: 'S GST %2'},
       { field: 'c_gst_3', cellStyle: {whiteSpace: 'nowrap'}, title: 'C GST %3'},
       { field: 'i_gst_4', cellStyle: {whiteSpace: 'nowrap'}, title: 'I GST %4'},
       { field: 'gst_amount2', cellStyle: {whiteSpace: 'nowrap'}, title: 'GST Amount2'},
       { field: 'gross_brokerage_value2', cellStyle: {whiteSpace: 'nowrap'}, title: 'Gross Brokerage Value2'},
       { field: 'tds_reducted_by_builder3', cellStyle: {whiteSpace: 'nowrap'}, title: 'TDS % Deducted by Builder3'},
       { field: 'tds_amount2', cellStyle: {whiteSpace: 'nowrap'}, title: 'TDS Amount2'},
       { field: 'after_tds_brokearge5', cellStyle: {whiteSpace: 'nowrap'}, title: 'After TDS Brokearge5'},
       { field: 'pending_receivable_amount', cellStyle: {whiteSpace: 'nowrap'}, title: 'Pending Receivable Amount'},  
    ];

    useEffect(() => {
        LeadSourceList();
        TransactionsList();
        projectList();
        UnitTypeList();
        CityList();
    }, [])
    

    return(
        <>
        <FinanceToolbar tabDetails={tab}/>
        <FinanceDrawer tabDetails={tab} tId={transactionsId}/>
        <button id='kt_expense_details_toggle' className='d-none'>edit</button>
        <button id='transIdReset' onClick={() => setTransactionsId('')} className='d-none'>transaction reset</button>
        {/* <button id='expenseDeleteButton' className='d-none' onClick={() => expenseDelete()}></button> */}
        <div className="user_manage_page bg_white h-100 p-4">
                <ul className="nav nav-pills mb-5" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation" onClick={() => setTab('transaction')}>
                        <button className="nav-link active" id="transaction-tab" data-bs-toggle="pill" data-bs-target="#transaction" type="button" role="tab" aria-controls="transaction" aria-selected="true">Transaction</button>
                    </li>
                    <li className="nav-item" role="presentation" onClick={() => setTab('feeConfirmation')}>
                        <button className="nav-link" id="pills-org-tab" data-bs-toggle="pill" data-bs-target="#pills-org" type="button" role="tab" aria-controls="pills-org" aria-selected="true">Fee Confirmation</button>
                    </li>
                    <li className="nav-item" role="presentation" onClick={() => setTab('proformaInvoice')}>
                        <button className="nav-link" id="user-charts-tab" data-bs-toggle="pill" data-bs-target="#user-charts" type="button" role="tab" aria-controls="user-charts" aria-selected="false">Proforma invoice</button>
                    </li>
                    <li className="nav-item" role="presentation" onClick={() => setTab('invoice')}>
                        <button className="nav-link" id="manage-teams-tab" data-bs-toggle="pill" data-bs-target="#manage-teams" type="button" role="tab" aria-controls="manage-teams" aria-selected="false">Invoice</button>
                    </li>
                    <li className="nav-item" role="presentation" onClick={() => setTab('collection')}>
                        <button className="nav-link" id="attendance-tab" data-bs-toggle="pill" data-bs-target="#attendance" type="button" role="tab" aria-controls="attendance" aria-selected="false">Collection</button>
                    </li>
                    <li className="nav-item" role="presentation" onClick={() => setTab('expenses')}>
                        <button className="nav-link" id="performance-tab" data-bs-toggle="pill" data-bs-target="#performance" type="button" role="tab" aria-controls="performance" aria-selected="false">Expenses</button>
                    </li>
                    <li className="nav-item" role="presentation" onClick={() => setTab('incentive')}>
                        <button className="nav-link" id="time-sheets-tab" data-bs-toggle="pill" data-bs-target="#time-sheets" type="button" role="tab" aria-controls="time-sheets" aria-selected="false">Incentive</button>
                    </li>
                    <li className="nav-item" role="presentation" onClick={() => setTab('cashback')}>
                        <button className="nav-link" id="cashback-tab" data-bs-toggle="pill" data-bs-target="#cashback" type="button" role="tab" aria-controls="cashback" aria-selected="false">Cashback</button>
                    </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="transaction" role="tabpanel" aria-labelledby="transaction-tab">
                    <div
                            id='kt_finance_brokerage_details'
                            className='bg-white'
                            data-kt-drawer='true'
                            data-kt-drawer-name='finance_brokerage_details'
                            data-kt-drawer-activate='true'
                            data-kt-drawer-overlay='true'
                            data-kt-drawer-width="{default:'100%', 'md': '700px'}"
                            data-kt-drawer-direction='end'
                            data-kt-drawer-toggle='#kt_brokerage_details_toggle'
                            data-kt-drawer-close='#kt_brokerage_details_close'
                        >
                            <BrokerageForm tId={transactionsId}/>
                        </div>
                        <div
                            id='kt_finance_invoicing_details'
                            className='bg-white'
                            data-kt-drawer='true'
                            data-kt-drawer-name='finance_invoicing_details'
                            data-kt-drawer-activate='true'
                            data-kt-drawer-overlay='true'
                            data-kt-drawer-width="{default:'100%', 'md': '700px'}"
                            data-kt-drawer-direction='end'
                            data-kt-drawer-toggle='#kt_invoicing_details_toggle'
                            data-kt-drawer-close='#kt_invoicing_details_close'
                        >
                            <InvoicingForm tId={transactionsId}/>
                        </div>
                        <div style={{ width: '100%', }} className="position-relative"> 
                        {loading ? 
                            <div className='w-100 h-100'>
                                <div className='d-flex justify-content-center flex-column align-items-center h_80vh'>
                                    <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
                                    <div className="spinner-border taskloader" role="status">                                    
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div> 
                            </div> :   <>                    
                        {transactions.length > 0
                        ? <>
                        {/* <DataGrid
                            rows={transactions}
                            columns={transactionsList}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            checkboxSelection={true}
                            // AutoGenerateColumns={false}
                            // suppressDragLeaveHidesColumns={true}
                            sx={{
                                fontSize: 12,
                                fontWeight: 400,
                            }} /> */}
                            <MaterialTable className="p-3"
                                enableRowNumbers={true}
                                icons={tableIcons}
                                columns={columns}
                                data={transactions}
                                title=""
                                // actions={[
                                //   {
                                //     icon: 'Edit',
                                //     tooltip: 'Save User',
                                //     onClick: (event, rowData) => {} 
                                //   },
                                //   rowData => ({
                                //     icon: 'Delete',
                                //     tooltip: 'Delete User',
                                //     onClick: (event, rowData) => {},
                                //   })
                                // ]}
                                // editable={{
                                //   onRowUpdate: (newData, oldData) =>
                                //     new Promise((resolve) => {
                                //         alert(1);
                                        
                                //     }),
                                //   onRowAdd: (newData) =>
                                //     new Promise((resolve) => {
                                //       alert(2)
                                //     }),
                                //   onRowDelete: (oldData) =>
                                //     new Promise((resolve) => {
                                //       alert(3)
                                //     }),
                                // }}
                                options={{
                                    actionsColumnIndex: -1,
                                    maxBodyHeight: pageHeight,
                                    exportButton: true,
                                    columnsButton: true,
                                    headerStyle: {
                                        position: "sticky",
                                        backgroundColor: '#ececec',
                                        color: '#000',
                                        whiteSpace: 'nowrap'
                                    },
                                    rowStyle: {
                                        backgroundColor: '#fff',
                                    }
                                }}
                                /></>
                        : <div className="text-center w-100">
                            <span className="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor"></path><rect x="7" y="17" width="6" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="12" width="10" height="2" rx="1" fill="currentColor"></rect><rect x="7" y="7" width="6" height="2" rx="1" fill="currentColor"></rect><path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor"></path></svg></span>
                            <p className='mt-3'>No Transactions Available</p>
                        </div>}</>}
                        </div>
                    </div>
                    <div className="tab-pane fade" id="invoicingDetails" role="tabpanel" aria-labelledby="invoicingDetails-tab">
                        <div style={{ height: 630, width: '100%', }}>
                            <form noValidate onSubmit={formikid.handleSubmit}>
                                <div className='row'>                                    
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Raised %</label>
                                        <div className="input-group mb-3">
                                            <input type="number" min="0" {...formikid.getFieldProps('raised')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Pending %</label>
                                        <div className="input-group mb-3">
                                            <input type="number" min="0" {...formikid.getFieldProps('pending')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Payment Status</label>
                                        <div className="input-group mb-3">
                                            <input type="text" {...formikid.getFieldProps('payment_status')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Amount Received</label>
                                        <div className="input-group mb-3">
                                            <input type="number" min="0" {...formikid.getFieldProps('amount_received')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Receiving Date</label>
                                        <div className="input-group mb-3">
                                            <input type="date" {...formikid.getFieldProps('receiving_date')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Pending Brokerage</label>
                                        <div className="input-group mb-3">
                                            <input type="number" min="0" {...formikid.getFieldProps('pending_brokerage')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">S GST %</label>
                                        <div className="input-group mb-3">
                                            <input type="number" min="0" {...formikid.getFieldProps('s_gst_2')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">C GST %</label>
                                        <div className="input-group mb-3">
                                            <input type="number" min="0" {...formikid.getFieldProps('c_gst_3')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">I GST %</label>
                                        <div className="input-group mb-3">
                                            <input type="number" min="0" {...formikid.getFieldProps('i_gst_4')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">GST Amount</label>
                                        <div className="input-group mb-3">
                                            <input type="number" min="0" {...formikid.getFieldProps('gst_amount2')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Gross Brokerage Value</label>
                                        <div className="input-group mb-3">
                                            <input type="number" min="0" {...formikid.getFieldProps('gross_brokerage_value2')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">TDS % Deducted by Builder</label>
                                        <div className="input-group mb-3">
                                            <input type="number" min="0" {...formikid.getFieldProps('tds_reducted_by_builder3')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">TDS Amount</label>
                                        <div className="input-group mb-3">
                                            <input type="number" min="0" {...formikid.getFieldProps('tds_amount2')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">After TDS Brokearge</label>
                                        <div className="input-group mb-3">
                                            <input type="number" min="0" {...formikid.getFieldProps('after_tds_brokearge5')} className="form-control"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Pending Receivable Amount</label>
                                        <div className="input-group mb-3">
                                            <input type="number" min="0" {...formikid.getFieldProps('pending_receivable_amount')} className="form-control"/> 
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center mb-6'>           
                                    <button
                                    type='submit'
                                    id='kt_sign_up_submit'
                                    className='btn btn_primary text-primary'
                                    disabled={formikid.isSubmitting}
                                    >
                                    {!loading && <span className='indicator-label'>Submit
                                    <KTSVG
                                    path='/media/custom/save_white.svg'
                                    className='svg-icon-3 svg-icon-primary ms-2'
                                    />
                                    </span>}
                                    {loading && (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-org" role="tabpanel" aria-labelledby="pills-org-tab">
                        <div style={{ height: 630, width: '100%', }}>
                            <FeeConfirmation/>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="user-charts" role="tabpanel" aria-labelledby="user-charts-tab">
                        <div style={{ height: 630, width: '100%', }}>
                            <ProformaInvoice/>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="manage-teams" role="tabpanel" aria-labelledby="manage-teams-tab">
                        <div style={{ height: 630, width: '100%', }}>
                            <Invoice/>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="attendance" role="tabpanel" aria-labelledby="attendance-tab">
                        <div style={{ height: 630, width: '100%', }}>
                            <Collection/>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="performance" role="tabpanel" aria-labelledby="performance-tab">
                        <div className='row'>                            
                            <div className='col-md-3'>
                                <div className='card bs_1 d-flex align-items-between'>
                                    <div className="d-flex align-items-center py-3">
                                        <span className="bullet bullet-vertical h-50px bg-danger"></span>
                                        <div className="form-check form-check-custom form-check-solid mx-5">
                                            <input className="form-check-input" type="checkbox" value="" />
                                        </div>
                                        <div className="flex-grow-1">
                                            <a href="#" className="text-gray-800 text-hover-danger fw-bold fs-6">Customers Update</a>
                                            <span className="text-muted fw-semibold d-block">Due in 1 week</span>
                                        </div>
                                        <span className="badge badge-light-danger fs-8 fw-bold">New</span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='card bs_1 d-flex align-items-between'>
                                    <div className="d-flex align-items-center py-3">
                                        <span className="bullet bullet-vertical h-50px bg-success"></span>
                                        <div className="form-check form-check-custom form-check-solid mx-5">
                                            <input className="form-check-input" type="checkbox" value="" />
                                        </div>
                                        <div className="flex-grow-1">
                                            <a href="#" className="text-gray-800 text-hover-success fw-bold fs-6">Customers Update</a>
                                            <span className="text-muted fw-semibold d-block">Due in 1 week</span>
                                        </div>
                                        <span className="badge badge-light-success fs-8 fw-bold">New</span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='card bs_1 d-flex align-items-between'>
                                    <div className="d-flex align-items-center py-3">
                                        <span className="bullet bullet-vertical h-50px bg-primary"></span>
                                        <div className="form-check form-check-custom form-check-solid mx-5">
                                            <input className="form-check-input" type="checkbox" value="" />
                                        </div>
                                        <div className="flex-grow-1">
                                            <a href="#" className="text-gray-800 text-hover-primary fw-bold fs-6">Customers Update</a>
                                            <span className="text-muted fw-semibold d-block">Due in 1 week</span>
                                        </div>
                                        <span className="badge badge-light-primary fs-8 fw-bold">New</span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='card bs_1 d-flex align-items-between'>
                                    <div className="d-flex align-items-center py-3">
                                        <span className="bullet bullet-vertical h-50px bg-info"></span>
                                        <div className="form-check form-check-custom form-check-solid mx-5">
                                            <input className="form-check-input" type="checkbox" value="" />
                                        </div>
                                        <div className="flex-grow-1">
                                            <a href="#" className="text-gray-800 text-hover-info fw-bold fs-6">Customers Update</a>
                                            <span className="text-muted fw-semibold d-block">Due in 1 week</span>
                                        </div>
                                        <span className="badge badge-light-info fs-8 fw-bold">New</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mt-4' style={{ height: 630, width: '100%', }}>
                            <Expenses/>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="cashback" role="tabpanel" aria-labelledby="pills-cashback-tab">
                        <div style={{ height: 630, width: '100%', }}>
                            <Cashback/>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="time-sheets" role="tabpanel" aria-labelledby="time-sheets-tab">
                        <div style={{ height: 630, width: '100%', }}>                            
                            <Incentive/>
                        </div>
                    </div>
                </div>
            </div></>
    )
}

export {Finance}


