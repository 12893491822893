import React,{FC, useState} from 'react'
import { Mail } from './email';
import { SMS } from './sms';
import { Watsapp } from './watsapp';

const Templates: FC = () => {

    const [clicked1, setClicked1] = useState<Boolean>(false);
    const [clicked2, setClicked2] = useState<Boolean>(false);

    const clickedSms = () => {
        setClicked1(true);
    }

    const clickedWatsapp = () => {
        setClicked2(true);
    }

    return(
        <div className="bg_white h-100 p-4">            
            <ul className="user_manage_page nav nav-pills mb-5" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="pills-org-tab" data-bs-toggle="pill" data-bs-target="#pills-org" type="button" role="tab" aria-controls="pills-org" aria-selected="true">Email</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="user-charts-tab" onClick={clickedSms} data-bs-toggle="pill" data-bs-target="#user-charts" type="button" role="tab" aria-controls="user-charts" aria-selected="false">SMS</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="manage-teams-tab" onClick={clickedWatsapp} data-bs-toggle="pill" data-bs-target="#manage-teams" type="button" role="tab" aria-controls="manage-teams" aria-selected="false">Whatsapp</button>
                </li>                
            </ul>
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-org" role="tabpanel" aria-labelledby="pills-org-tab">
                    <Mail/>
                </div>
                <div className="tab-pane fade" id="user-charts" role="tabpanel" aria-labelledby="user-charts-tab">
                    { clicked1 && <SMS/> }
                </div>
                <div className="tab-pane fade" id="manage-teams" role="tabpanel" aria-labelledby="manage-teams-tab">
                    { clicked2 && <Watsapp/> }
                </div>                  
            </div>
        </div>
    )
}

export {Templates}