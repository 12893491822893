import React,{FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import { Toast } from 'bootstrap';
import { saveUser, getUsers, updateUser, deleteUser, getDesignation, getDept, getBranch, getTeamLeader } from './core/_requests';
import { useAuth } from '../../../modules/auth';
import { getCity, getGender, getMaritalStatus } from '../masters/core/_requests';


const initialValues = {
    first_name: "",
    last_name: "",
    employee_id: "",
    designation: "",
    department: "",
    gender: "",
    branch: "",
    blood_group: "",
    father_name: "",
    spouse_name: "",
    spouse_dob: "",
    medical_condition: "",
    p_phone_number: "",
    email: "",
    pass: "",
    password: "",
    o_phone_number: "",
    sec_mobile: "",
    o_email: "",
    emergency_contact_no: "",
    emergency_contact_person_name: "",
    emergency_contact_person_relation: "",
    number_of_kids: "",
    kid_name1: "",
    kid_name2: "",
    kid_name3: "",
    aadhar_number: "",
    pan_number: "",
    dob: "",
    marital_status: "",
    anniversary_date: "",
    date_of_joining: "",
    past_employment_history: "",
    year_of_experiance: "",
    last_company: "",
    city: "",
    portfolio_head: "",
    target_for_fy_rs: "",
    target_for_fy_units: "",
    team_leader: "",
    monthly_ctc: "",
    monthly_takehome: "",
    incentive: "",
    name_asper_bank_record: "",
    bank_name: "",
    branch_name: "",
    account_no: "",
    ifsc_code: "",
    permenent_address: "",
    correspondant_address: "",
    created_by: "",
    profile_image: "",
    crm_login_password: "",
    crm_login_id: "",
    base_salary: "",
    pf: "",
    hra: "",
    total_ctc: "", 
    official_mobile_number: "", 
    duration: "",
    local_address: "",
    official_mail: "",
    mediclaim: "",
    conveyance: "",
    misc: "",
    annual_target: "",
    status_changed: "",
    no_of_units_committed: "",
    no_of_sales: "",
    goal_calls: "",
    goal_talktime: "",
    goal_leads_generated: "",
    goal_leads_converted: "",
    goal_site_visit: "",
    goal_meetings: "",
    goal_bookings: "",
    goal_revenue: "",
}

type Props = {
    setUser?: any
}

const AddUserSettings:  FC<Props> = (props) => {

    const {setUser} = props

    
    const [loading, setLoading] = useState(false)
    const [designation, setDesignation] = useState<any>('')
    const [Dob, setDob] = React.useState<Date | null>(new Date());  
    const [AnniversaryDate, setAnniversaryDate] = React.useState<Date | null>(new Date());  
    const [DateofJoining, setDateofJoining] = React.useState<Date | null>(new Date());  
    const {currentUser, logout} = useAuth();    
    const [design, setDesign] = useState<any[]>([]);
    const [gender, setGender] = useState<any[]>([]);
    const [teamLeader, setTeamLeader] = useState<any[]>([]);
    const [maritalStatus, setMaritalStatus] = useState<any[]>([]);
    const [dept, setDept] = useState<any[]>([]);
    const [branch, setBranch] = useState<any[]>([]);
    const [city, setCity] = useState<any[]>([]);
    const [message, setMessage] = useState(false);
    const [imgPre, setImgPre] = useState(false);
    const [imagePreview, setImagePreview] = useState<any>(null);
    const [imageFile, setImageFile] = useState(null);
    const [req, setReq] = useState(false);
      
    var userId = currentUser?.id;
    var roleId = currentUser?.designation;

    var registrationSchema;
    if(designation != 1) {
        registrationSchema = Yup.object().shape({
        first_name: Yup.string()
            .min(3, 'Minimum 3 characters')
            .max(50, 'Maximum 50 characters')
            .required('Name is required'),
        last_name: Yup.string()            
            .max(50, 'Maximum 50 characters'),          
        email: Yup.string()
            .email('Wrong email format')
            .min(3, 'Minimum 3 characters')
            .max(50, 'Maximum 50 characters')
            .required('Email is required'),
        p_phone_number: Yup.string()
            .required('Mobile number is required'),
        sec_mobile: Yup.string(),
        o_email: Yup.string()
            .email('Wrong email format')
            .min(3, 'Minimum 3 characters')
            .max(50, 'Maximum 50 characters'),
        o_phone_number: Yup.string(),
        employee_id: Yup.string()
            .required('Employee Id is required'),
        designation: Yup.string()
            .required('Designation is required'),
        department: Yup.string()
            .required('Department is required'),
        branch: Yup.string(),
        gender: Yup.string(),
        blood_group: Yup.string(),
        father_name: Yup.string(),
        spouse_name: Yup.string(),
        spouse_dob: Yup.string(),
        medical_condition: Yup.string(),
        pass: Yup.string()
            .required('Password is required')
            .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            ),
        password: Yup.string()
            .required()
            .oneOf([Yup.ref("pass"), null], "Passwords must match"),
        emergency_contact_no: Yup.string(),
        emergency_contact_person_name: Yup.string(),
        emergency_contact_person_relation: Yup.string(),
        number_of_kids: Yup.string(),
        kid_name1: Yup.string(),
        kid_name2: Yup.string(),
        kid_name3: Yup.string(),
        aadhar_number: Yup.string()
            .min(12,)
            .max(12,),
        pan_number: Yup.string()
            .min(10,)
            .max(10,),
        dob: Yup.string(),
        marital_status: Yup.string(),
        anniversary_date: Yup.string(),
        date_of_joining: Yup.string(),
        past_employment_history: Yup.string(),
        year_of_experiance: Yup.string(),
        last_company: Yup.string(),
        city: Yup.string(),
        permenent_address: Yup.string(),
        team_leader: Yup.string().required('Team Leader is required'),
        portfolio_head: Yup.string(),
        target_for_fy_rs: Yup.string(),
        target_for_fy_units: Yup.string(),
        monthly_ctc: Yup.string(),
        monthly_takehome: Yup.string(),
        incentive: Yup.string(),
        name_asper_bank_record: Yup.string(),
        bank_name: Yup.string(),
        account_no: Yup.string(),
        ifsc_code: Yup.string(),
        branch_name: Yup.string(),
        misc: Yup.string(),
        conveyance: Yup.string(),
        mediclaim: Yup.string(),
        hra: Yup.string(),
        pf: Yup.string(),
        total_ctc: Yup.string(),
        official_mobile_number: Yup.string(),
        base_salary: Yup.string(),
        crm_login_id: Yup.string(),
        crm_login_password: Yup.string(),
        duration: Yup.string(),
        official_mail: Yup.string(),
        local_address: Yup.string(),
        correspondant_address: Yup.string(),
        profile_image: Yup.object(),
                
      })} else {
        registrationSchema = Yup.object().shape({
            first_name: Yup.string()
                .min(3, 'Minimum 3 characters')
                .max(50, 'Maximum 50 characters')
                .required('Name is required'),
            last_name: Yup.string()            
                .max(50, 'Maximum 50 characters'),          
            email: Yup.string()
                .email('Wrong email format')
                .min(3, 'Minimum 3 characters')
                .max(50, 'Maximum 50 characters')
                .required('Email is required'),
            p_phone_number: Yup.string()
                .required('Mobile number is required'),
            sec_mobile: Yup.string(),
            o_email: Yup.string()
                .email('Wrong email format')
                .min(3, 'Minimum 3 characters')
                .max(50, 'Maximum 50 characters'),
            o_phone_number: Yup.string(),
            employee_id: Yup.string()
                .required('Employee Id is required'),
            designation: Yup.string()
                .required('Designation is required'),
            department: Yup.string()
                .required('Department is required'),
            branch: Yup.string(),
            gender: Yup.string(),
            blood_group: Yup.string(),
            father_name: Yup.string(),
            spouse_name: Yup.string(),
            spouse_dob: Yup.string(),
            medical_condition: Yup.string(),
            pass: Yup.string()
                .required('Password is required')
                .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                ),
            password: Yup.string()
                .required()
                .oneOf([Yup.ref("pass"), null], "Passwords must match"),
            emergency_contact_no: Yup.string(),
            emergency_contact_person_name: Yup.string(),
            emergency_contact_person_relation: Yup.string(),
            number_of_kids: Yup.string(),
            kid_name1: Yup.string(),
            kid_name2: Yup.string(),
            kid_name3: Yup.string(),
            aadhar_number: Yup.string()
                .min(12,)
                .max(12,),
            pan_number: Yup.string()
                .min(10,)
                .max(10,),
            dob: Yup.string(),
            marital_status: Yup.string(),
            anniversary_date: Yup.string(),
            date_of_joining: Yup.string(),
            past_employment_history: Yup.string(),
            year_of_experiance: Yup.string(),
            last_company: Yup.string(),
            city: Yup.string(),
            permenent_address: Yup.string(),
            team_leader: Yup.string(),
            portfolio_head: Yup.string(),
            target_for_fy_rs: Yup.string(),
            target_for_fy_units: Yup.string(),
            monthly_ctc: Yup.string(),
            monthly_takehome: Yup.string(),
            incentive: Yup.string(),
            name_asper_bank_record: Yup.string(),
            bank_name: Yup.string(),
            account_no: Yup.string(),
            ifsc_code: Yup.string(),
            branch_name: Yup.string(),
            misc: Yup.string(),
            conveyance: Yup.string(),
            mediclaim: Yup.string(),
            hra: Yup.string(),
            pf: Yup.string(),
            total_ctc: Yup.string(),
            official_mobile_number: Yup.string(),
            base_salary: Yup.string(),
            crm_login_id: Yup.string(),
            crm_login_password: Yup.string(),
            duration: Yup.string(),
            official_mail: Yup.string(),
            local_address: Yup.string(),
            correspondant_address: Yup.string(),
            profile_image: Yup.object(),
                    
          })
      }

    const formik = useFormik({
        initialValues,
        validationSchema: registrationSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
        //   console.log(values)
        //   console.log(values)
          try {             

            var formData = new FormData(); 
            formData.append('first_name', values.first_name);
            formData.append('last_name', values.last_name);
            formData.append('employee_id', values.employee_id);
            formData.append('designation', values.designation);
            formData.append('department', values.department);
            formData.append('branch', values.branch);
            formData.append('p_phone_number', values.p_phone_number);
            formData.append('email', values.email);
            formData.append('o_phone_number', values.o_phone_number);
            formData.append('sec_mobile', values.sec_mobile);
            formData.append('o_email', values.o_email);
            formData.append('emergency_contact_no', values.emergency_contact_no);
            formData.append('aadhar_number', values.aadhar_number);
            formData.append('pan_number', values.pan_number);
            formData.append('dob', values.dob);
            formData.append('permenent_address', values.permenent_address);
            formData.append('correspondence_address', values.correspondant_address);
            formData.append('marital_status', values.marital_status);
            formData.append('anniversary_date', values.anniversary_date);
            formData.append('date_of_joining', values.date_of_joining);
            formData.append('past_employment_history', values.past_employment_history);
            formData.append('password', values.password);
            formData.append('gender', values.gender);
            formData.append('blood_group', values.blood_group);
            formData.append('fathers_name', values.father_name);
            formData.append('spouse_name', values.spouse_name);
            formData.append('spouse_dob', values.spouse_dob);
            formData.append('medical_condition', values.medical_condition);
            formData.append('emergency_contact_person', values.emergency_contact_person_name);
            formData.append('relation_person', values.emergency_contact_person_relation);
            formData.append('no_of_kids', values.number_of_kids);
            formData.append('kid_name_1', values.kid_name1);
            formData.append('kid_name_2', values.kid_name2);
            formData.append('kid_name_3', values.kid_name3);
            formData.append('years_of_experience', values.year_of_experiance);
            formData.append('last_company', values.last_company);
            formData.append('city', values.city);
            formData.append('team_leader', values.team_leader);
            formData.append('portfolio_head', values.portfolio_head);
            formData.append('target_for_fy_rs', values.target_for_fy_rs);
            formData.append('target_for_fy_units', values.target_for_fy_units);
            formData.append('monthly_ctc', values.monthly_ctc);
            formData.append('monthly_take_home', values.monthly_takehome);
            formData.append('official_mobile_number', values.official_mobile_number);
            formData.append('official_mail', values.official_mail);
            formData.append('incentive', values.incentive);
            formData.append('bank_record_name', values.name_asper_bank_record);
            formData.append('bank_name', values.bank_name);
            formData.append('acc_number', values.account_no);
            formData.append('ifsc_code', values.ifsc_code);
            formData.append('branch_name', values.branch_name);
            formData.append('crm_login_id', values.crm_login_id);
            formData.append('crm_login_password', values.crm_login_password);
            formData.append('base_salary', values.base_salary);
            formData.append('pf', values.pf);
            formData.append('hra', values.hra);
            formData.append('duration', values.duration);
            formData.append('local_address', values.local_address);
            formData.append('mediclaim', values.mediclaim);
            formData.append('conveyance', values.conveyance);
            formData.append('misc', values.misc);
            formData.append('total_ctc', values.total_ctc);
            formData.append('annual_target', values.annual_target);
            formData.append('status_changed', values.status_changed);
            formData.append('no_of_units_committed', values.no_of_units_committed);
            formData.append('no_of_sales', values.no_of_sales);
            formData.append('goal_calls', values.goal_calls);
            formData.append('goal_talktime', values.goal_talktime);
            formData.append('goal_leads_generated', values.goal_leads_generated);
            formData.append('goal_leads_converted', values.goal_leads_converted);
            formData.append('goal_site_visit', values.goal_site_visit);
            formData.append('goal_meetings', values.goal_meetings);
            formData.append('goal_bookings', values.goal_bookings);
            formData.append('goal_revenue', values.goal_revenue);
            formData.append('created_by', userId!.toString());
            formData.append('profile_image', imageFile!);

            console.log(formData);
    
            const saveUserData = await saveUser(formData)
    
            console.log('saveUserData');
            console.log(saveUserData);
            // document.getElementById('kt_contact')?.classList.remove('drawer-on');
            if(saveUserData!= null){                
                setLoading(false)
                document.getElementById('kt_usersettings_close')?.click();
                document.getElementById('teamListReload')?.click();
                var toastEl = document.getElementById('myToastAdd');
                const bsToast = new Toast(toastEl!);
                bsToast.show();
                const characterResponse = await getUsers()
                setUser(characterResponse)
                resetForm();
                imgRemove();
                // statusList();
                console.log("this");
                console.log(characterResponse);                                        
            } 
    
          } catch (error) {
            alert()
            document.getElementById('openAcc')?.click()
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
        },
    })

    const [contactType, setContactType] = useState<any[]>([]);

    const handleImagePreview = (e:any) => {
        let image_as_base64:any = URL.createObjectURL(e.target.files[0]);
        let image_as_files:any = e.target.files[0];
      
        // setImagePreview(image_as_base64);
        setImageFile(image_as_files);
        setImagePreview(image_as_base64);
        setImgPre(true);
    }

    const DesignList =  async () => {   
        const designResponse = await getDesignation();
        setDesign(designResponse);
    }

    const DeptList =  async () => {   
        const deptResponse = await getDept();
        setDept(deptResponse);
    }

    const BranchList =  async () => {   
        const branchResponse = await getBranch();
        setBranch(branchResponse);
    }
    const genderList =  async () => {
        const genderResponse = await getGender()
        setGender(genderResponse);
    }
    const MaritalStatusList =  async () => {
        const MaritalStatusResponse = await getMaritalStatus()
        setMaritalStatus(MaritalStatusResponse);
    }
    const CityList =  async () => {
        const CityResponse = await getCity()
        setCity(CityResponse);
    }

    const handleChange = (event: { target: { value: any; }; }) => {
        setMessage(event.target.value);
    
        console.log('value is:', event.target.value);
      };

      const secOnChange = (val:any) => {
        console.log(val)
        formik.setFieldValue('marital_status', val ?? '');
        if(val == 1){
            setMessage(false);
        }
        else {
            setMessage(true);
        }
      }

    const imgRemove = () => {
        setImageFile(null);
        setImagePreview('');
        setImgPre(false);
    }

    // const teamLeaderList = async () => {
    //     const response = await getTeamLeader(designation)
    //     setTeamLeader(response);
    // }

    const designations = async (e:any) => {
        setDesignation(e);
        const response = await getTeamLeader(e)
        setTeamLeader(response);
        formik.setFieldValue('designation', e ?? '')
    }
    
    useEffect(() => {
        DesignList();
        DeptList();
        BranchList();
        genderList();
        MaritalStatusList();
        CityList();
    }, []);

      
    return(
         
        <><div className='card shadow-none rounded w-100'>
            <div aria-atomic="true" aria-live="assertive" className="toast bg_primary p-0 text-white position-fixed end-0 bottom-0" id="myToastAdd">
                <div className="toast-header">
                    <strong className="me-auto">Success</strong>
                    <button aria-label="Close" className="btn-close btn-close-color-white" 
                                data-bs-dismiss="toast" type="button">
                    </button> 
                </div>
                <div className="toast-body">
                    <div>User Created Successfully!</div>
                </div>
            </div>
            <div className='card-header w-100 d-flex align-items-center justify-content-between' id='kt_usersettings_header'>
                <h3 className='card-title fw-bolder text-dark'>Add User</h3>

                <div className='card-toolbar'>
                    {/* <div>
                    <div className="input-group form_search me-3">
                        <input type="text" className="form-control" placeholder="Search"/>
                        <div className="input-group-append">
                        <button className="btn btn-secondary" type="button">
                            <img src={toAbsoluteUrl('/media/custom/header-icons/search.svg')} className="svg_icon" alt='' />
                        </button>
                        </div>
                    </div>
                    </div> */}
                    <button
                        type='button'
                        className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                        id='kt_usersettings_close'
                    >
                        <img src={toAbsoluteUrl('/media/custom/cancel_black.svg')} className="svg_icon" alt='' />
                    </button>
                </div>
            </div>
            <div className='card-body position-relative' id='kt_usersettings_body'>
                <div className="accordion" id="accordionExample">
                    <form noValidate onSubmit={formik.handleSubmit}>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Basic Details
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="row">
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label required">First Name</label>
                                            <div className="input-group first mb-3 input_prepend">
                                                <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                                    <option value="Mr">Mr</option>
                                                    <option value="Mrs">Mrs</option>
                                                </select>
                                                <input type="text" {...formik.getFieldProps('first_name')}
                                                    className="form-control" placeholder="First Name" />
                                            </div>
                                            {formik.touched.first_name && formik.errors.first_name && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.first_name}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Last Name</label>
                                            <div className="input-group">
                                                <input type="text" {...formik.getFieldProps('last_name')}
                                                    className="form-control" placeholder="Last Name" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label required">Employee ID</label>
                                            <div className="input-group">
                                                <input type="text" {...formik.getFieldProps('employee_id')} className="form-control" placeholder="Enter Id" />
                                            </div>
                                            {formik.touched.employee_id && formik.errors.employee_id && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.employee_id}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label required">Designation</label>
                                            <div className="input-group mb-3">
                                                <select 
                                                    {...formik.getFieldProps('designation')} 
                                                    className="form-select btn btn-sm w-100" onChange={(e) => designations(e.target.value)}>
                                                    <option value=''>Select</option>
                                                    {design.map((designValue,i)=> {
                                                    return (
                                                    <option selected={i == 0 ? true: false} value={designValue.id} key={i}>{designValue.name}</option>
                                                    )
                                                    })} 
                                                </select>
                                            </div>
                                            {formik.touched.designation && formik.errors.designation && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert' className='text-danger'>{formik.errors.designation}</span>
                                                </div>
                                            </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label required">Department</label>
                                            <div className="input-group mb-3">
                                                <select 
                                                    {...formik.getFieldProps('department')} 
                                                    className="form-select btn btn-sm w-100">
                                                    <option disabled value=''>Select</option>
                                                    {dept.map((deptValue,i)=> {
                                                    return (
                                                    <option selected={i == 0 ? true: false} value={deptValue.id} key={i}>{deptValue.name}</option>
                                                    )
                                                    })} 
                                                </select>
                                                {/* <input type="text" {...formik.getFieldProps('department')} className="form-control" placeholder="Department" /> */}
                                            </div>
                                            {formik.touched.department && formik.errors.department && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.department}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Branch</label>
                                            <div className="input-group mb-3">
                                                <select 
                                                    {...formik.getFieldProps('branch')} 
                                                    className="form-select btn btn-sm w-100">
                                                    <option disabled value=''>Select</option>
                                                    {branch.map((branchValue,i)=> {
                                                    return (
                                                    <option selected={i == 0 ? true: false} value={branchValue.id} key={i}>{branchValue.name}</option>
                                                    )
                                                    })} 
                                                </select>
                                                {/* <input type="text" {...formik.getFieldProps('branch')} className="form-control" placeholder="Department" /> */}
                                            </div>
                                            {formik.touched.branch && formik.errors.branch && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.branch}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Photo</label>
                                            <div className='d-flex'>
                                            <span className="btn btn-file">
                                                <i className="fa fa-upload my-2" aria-hidden="true"></i>Upload <input type="file"
                                                    //  {...formik.getFieldProps('document'+i)}
                                                    onChange={handleImagePreview}
                                                    name={'profile_image'} />
                                                    
                                            </span>
                                            {imgPre &&
                                            <><div className='position-relative'><img src={imagePreview} alt="image preview" height={100} width={100} />
                                            <a onClick={(e) => imgRemove()} className="icon position-absolute px-1 top-0 end-0 rounded bg-gray border-0"><span className="svg-icon svg-icon-muted"><svg width="" height="30" viewBox="3 3 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect><rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)" fill="currentColor"></rect><rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)" fill="currentColor"></rect></svg></span></a></div></>}
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label required">Phone Number</label>
                                            <div className="input-group mb-3 input_prepend">
                                                <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                                    <option value="date">+91</option>
                                                </select>
                                                <input type="number" min="0" {...formik.getFieldProps('p_phone_number')} className="form-control" placeholder="Enter your Phone Number" maxLength={10}/>
                                            </div>
                                            {formik.touched.p_phone_number && formik.errors.p_phone_number && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.p_phone_number}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label required">Sec. Phone Number</label>
                                            <div className="input-group mb-3 input_prepend">
                                                <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                                    <option value="date">+91</option>
                                                </select>
                                                <input type="number" min="0" {...formik.getFieldProps('sec_mobile')} className="form-control" placeholder="Enter your sec. Phone No." maxLength={10}/>
                                            </div>
                                            {formik.touched.sec_mobile && formik.errors.sec_mobile && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.sec_mobile}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label required">Email ID</label>
                                            <div className="input-group mb-3">
                                                <input type="email" {...formik.getFieldProps('email')} className="form-control" placeholder="Enter your email" />
                                            </div>
                                            {formik.touched.email && formik.errors.email && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.email}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label required">Password</label>
                                            <div className="input-group mb-3">
                                                <input type="password" {...formik.getFieldProps('pass')} className="form-control" placeholder="Enter your Password" />
                                            </div>
                                            {formik.touched.pass && formik.errors.pass && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.pass}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label required">Confirm Password</label>
                                            <div className="input-group mb-3">
                                                <input type="password" {...formik.getFieldProps('password')} className="form-control" placeholder="Confirm your Password" />
                                            </div>
                                            {formik.touched.password && formik.errors.password && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.password}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Aadhar Number</label>
                                            <div className="input-group mb-3 input_prepend">
                                                <input type="number" min="0" {...formik.getFieldProps('aadhar_number')} className="form-control" placeholder="Enter Aadhar"  maxLength={12}/>
                                            </div>
                                            {formik.touched.aadhar_number && formik.errors.aadhar_number && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.aadhar_number}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">PAN Number</label>
                                            <div className="input-group mb-3 input_prepend">
                                                <input type="text" {...formik.getFieldProps('pan_number')} className="form-control" placeholder="Enter PAN"  maxLength={10}/>
                                            </div>
                                            {formik.touched.pan_number && formik.errors.pan_number && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.pan_number}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Date of Birth</label>
                                            <div className="input-group mb-3">
                                                <input type="date" {...formik.getFieldProps('dob')} className="form-control" placeholder="date" />
                                            </div>
                                            {formik.touched.dob && formik.errors.dob && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.dob}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>                                                                            
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Personal Details
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="row">
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Marital Status</label>
                                            <div className="input-group mb-3">
                                                <select className="form-select btn btn-sm w-100" {...formik.getFieldProps('marital_status')} onChange={(e) => secOnChange(e.target.value)}>
                                                    <option disabled value="">Select</option>
                                                    {maritalStatus.map((branchValue,i)=> {
                                                    return (
                                                    <option selected={i == 0 ? true: false} value={branchValue.id} key={i}>{branchValue.name}</option>
                                                    )
                                                    })} 
                                                </select>
                                            </div>
                                            {/* {formik.touched.marital_status && formik.errors.marital_status && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.marital_status}</span>
                                                    </div>
                                                </div>
                                            )} */}
                                        </div>
                                    { message && 
                                        <><div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Anniversary Date</label>
                                                <div className="input-group mb-3">
                                                    <input type="date" {...formik.getFieldProps('anniversary_date')} className="form-control" placeholder="date" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Spouse Name</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" {...formik.getFieldProps('spouse_name')} placeholder="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Spouse DOB</label>
                                                    <div className="input-group">
                                                        <input type="date" className="form-control" {...formik.getFieldProps('spouse_dob')} placeholder="" />
                                                    </div>
                                                </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Number of Kids</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('number_of_kids')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">1st Kid Name</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('kid_name1')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">2nd Kid Name</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('kid_name2')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">3rd Kid Name</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('kid_name3')} placeholder=""/>
                                        </div>
                                    </div></>} 
                                        <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Gender</label>
                                        <div className="input-group mb-3">
                                            <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('gender')}>
                                                <option disabled value="">Select Gender</option>
                                                {gender.map((branchValue,i)=> {
                                                    return (
                                                    <option selected={i == 0 ? true: false} value={branchValue.id} key={i}>{branchValue.name}</option>
                                                    )
                                                    })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Blood Group</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('blood_group')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Father's Name</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('father_name')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Medical Condition</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('medical_condition')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Emergency Contact Person Name</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('emergency_contact_person_name')} placeholder=""/>
                                        </div>
                                    </div>                                     
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">CRM Id</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('crm_login_id')} placeholder=""/>
                                        </div>
                                    </div>                                     
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">CRM Password</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('crm_login_password')} placeholder=""/>
                                        </div>
                                    </div>                                     
                                    <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Emergency Contact Number</label>
                                            <div className="input-group mb-3 input_prepend">
                                                <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                                    <option value="date">+91</option>
                                                </select>
                                                <input type="number" min="0" {...formik.getFieldProps('emergency_contact_no')} className="form-control" placeholder="Enter Phone Number" maxLength={10}/>
                                            </div>
                                            {formik.touched.emergency_contact_no && formik.errors.emergency_contact_no && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.emergency_contact_no}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Emergency Contact Person Relation</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('emergency_contact_person_relation')} placeholder=""/>
                                        </div>
                                    </div> 
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Permanent Address</label>
                                            <div className="input-group">
                                                <textarea rows={4} {...formik.getFieldProps('permenent_address')} className="form-control" placeholder="Permanent Address" />
                                            </div>
                                            {formik.touched.permenent_address && formik.errors.permenent_address && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.permenent_address}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Correspondence Address</label>
                                            <div className="input-group">
                                                <textarea rows={4} {...formik.getFieldProps('correspondant_address')} className="form-control" placeholder="Correspondence Address" />
                                            </div>
                                            {formik.touched.correspondant_address && formik.errors.correspondant_address && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.correspondant_address}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>                                
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" id='openAcc'>
                                Professional Details
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className='row'>
                                    <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Date of Joining</label>
                                            <div className="input-group mb-3">
                                                <input type="date" {...formik.getFieldProps('date_of_joining')} className="form-control" placeholder="date" />
                                            </div>
                                            {formik.touched.date_of_joining && formik.errors.date_of_joining && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.date_of_joining}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Year of Experiance</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('year_of_experiance')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Last Company Name</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('last_company')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Last Company Phone</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('official_mobile_number')} placeholder=""  maxLength ={10}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Last Company Mail</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('official_mail')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">City</label>
                                        <div className="input-group mb-3">
                                            <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('city')}>
                                                <option disabled value="">Select City</option>
                                                {city.map((branchValue,i)=> {
                                                    return (
                                                    <option selected={i == 0 ? true: false} value={branchValue.id} key={i}>{branchValue.name}</option>
                                                    )})}
                                            </select>
                                        </div>
                                    </div>
                                    {designation && designation != 1 && <>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label required">Team Leader</label>
                                        <div className="input-group mb-3">
                                            <select className="btn_secondary btn btn-sm w-100" autoFocus {...formik.getFieldProps('team_leader')}>
                                                <option disabled value="">Select</option>
                                                {teamLeader.map((team,i)=> {
                                                    return (
                                                    <option selected={i == 0 ? true: false} value={team.id} key={i}>{team.first_name}</option>
                                                    )
                                                    })}
                                            </select>
                                        </div>
                                        {/* {formik.touched.team_leader && formik.errors.team_leader && document.getElementById('openAcc')?.click()} */}
                                        {formik.touched.team_leader && formik.errors.team_leader && 
                                            (   <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.team_leader}</span>
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Portfolio Head</label>
                                        <div className="input-group mb-3">
                                            <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('portfolio_head')}>
                                                <option disabled value="">Select</option>
                                                {teamLeader.map((team,i)=> {
                                                    return (
                                                    <option selected={i == 0 ? true: false} value={team.id} key={i}>{team.first_name}</option>
                                                    )
                                                    })}
                                            </select>
                                        </div>
                                    </div></>}                                   
                                    <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Official Phone Number</label>
                                            <div className="input-group mb-3 input_prepend">
                                                <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                                    <option value="date">+91</option>
                                                </select>
                                                <input type="number" min="0" {...formik.getFieldProps('o_phone_number')} className="form-control" placeholder="Enter Phone Number"  maxLength ={10}/>
                                            </div>
                                            {formik.touched.o_phone_number && formik.errors.o_phone_number && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.o_phone_number}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Official Email ID</label>
                                            <div className="input-group mb-3">
                                                <input type="email" {...formik.getFieldProps('o_email')} className="form-control" placeholder="Enter your email" />
                                            </div>
                                            {formik.touched.o_email && formik.errors.o_email && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.o_email}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Target for the FY <small>(in Rs.)</small></label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('target_for_fy_rs')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Duration</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('duration')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Company Address</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('local_address')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Target for the FY <small>(in Units)</small></label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('target_for_fy_units')} placeholder=""/>
                                        </div>
                                    </div>                                    
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Monthly CTC</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('monthly_ctc')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Monthly Take Home</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('monthly_takehome')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Incentive %</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('incentive')} placeholder=""/>
                                        </div>
                                    </div>                                         
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFour">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                Financial Details
                                </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className='row'>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Base Salary</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('base_salary')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">PF</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('pf')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">HRA</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('hra')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Mediclaim</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('mediclaim')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Conveyance</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('conveyance')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Total CTC</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('total_ctc')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">MISC</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('misc')} placeholder=""/>
                                        </div>
                                    </div>
                                <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Name <small>(As Per Bank record)</small></label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('name_asper_bank_record')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Bank Name</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('bank_name')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Account No.</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('account_no')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">IFSC Code</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('ifsc_code')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Branch Name</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('branch_name')} placeholder=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFive">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                Goal Details
                                </button>
                            </h2>
                            <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className='row'>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Annual Target</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('annual_target')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Status Changed</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('status_changed')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">No. of units commited</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('no_of_units_committed')} placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">No. of sales</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('no_of_sales')} placeholder=""/>
                                        </div>
                                    </div>                                    
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Goal Calls</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('goal_calls')} placeholder=""/>
                                        </div>
                                    </div>                                    
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Goal Talktime</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('goal_talktime')} placeholder=""/>
                                        </div>
                                    </div>                                    
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Goal Leads Generated</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('goal_leads_generated')} placeholder=""/>
                                        </div>
                                    </div>                                    
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Goal Leads Converted</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('goal_leads_converted')} placeholder=""/>
                                        </div>
                                    </div>                                    
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Goal Site Vist</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('goal_site_visit')} placeholder=""/>
                                        </div>
                                    </div>                                    
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Goal Meetings</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('goal_meetings')} placeholder=""/>
                                        </div>
                                    </div>                                    
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Goal Bookings</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('goal_bookings')} placeholder=""/>
                                        </div>
                                    </div>                                    
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Goal Revenue</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" {...formik.getFieldProps('goal_revenue')} placeholder=""/>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className='text-center'>
                            <button
                                type='submit'
                                id='kt_sign_up_submit'
                                className='btn btn_primary text-primary'
                                disabled={formik.isSubmitting}
                            >
                                {!loading && <span className='indicator-label'>Submit
                                    <KTSVG
                                        path='/media/custom/save_white.svg'
                                        className='svg-icon-3 svg-icon-primary ms-2' />
                                </span>}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>

                    </form>
                </div>
            </div>
            <div className='card-footer py-5 text-center' id='kt_usersettings_footer'>

                {/* <Link to='' className='btn btn_primary text-primary'>
      
    </Link> */}

            </div>
        </div>
        <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastAdd">
                <div className="toast-header">
                    <strong className="me-auto">Success</strong>
                    <button aria-label="Close" className="btn-close"
                        data-bs-dismiss="toast" type="button">
                    </button>
                </div>
                <div className="toast-body">
                    User Saved Successfully!
                </div>
            </div></>
    )
}


export {AddUserSettings}