import React,{FC} from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const Settings: FC = () => {
    return(
    <div className="tab-pane" id="pills-personal" role="tabpanel" aria-labelledby="pills-personal-tab">
        <form action="">
            <div className="row">
                <div className="col-lg-12 col-xxl-12">
                    <div className="card bs_1">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">                                    
                                        <div className="col-xl-4 col-md-6 xol-sm-6 col-md-6 xol-sm-6">                                                                                     
                                            <div className="form-group mb-4">
                                                <label htmlFor="basic-url" className="form-label">SMTP</label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" placeholder="SMTP"/> 
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-md-6 xol-sm-6">                                        
                                            <div className="form-group mb-4">
                                                <label htmlFor="basic-url" className="form-label">Host</label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" placeholder="Host"/> 
                                                </div>
                                            </div>
                                        </div>                                      
                                        <div className="col-xl-4 col-md-6 xol-sm-6">                                                                                     
                                            <div className="form-group mb-4">
                                                <label htmlFor="basic-url" className="form-label">Port</label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" placeholder="Port"/> 
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-md-6 xol-sm-6">                                        
                                            <div className="form-group mb-4">
                                                <label htmlFor="basic-url" className="form-label">User Id</label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" placeholder="User Id"/> 
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-md-6 xol-sm-6">                                                                                     
                                            <div className="form-group mb-4">
                                                <label htmlFor="basic-url" className="form-label">Password</label>
                                                <div className="input-group">
                                                    <input type="password" className="form-control" placeholder="Password"/> 
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-md-6 xol-sm-6">                                        
                                            <div className="form-group mb-4">
                                                <label htmlFor="basic-url" className="form-label">Encryption</label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" placeholder="Encryption"/> 
                                                </div>
                                            </div>
                                        </div>                                                
                                    </div>
                                    <div className='text-end mt-4'>
                                        <button className='btn btn_primary'>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    )
}

export {Settings}