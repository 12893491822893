import React,{FC, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import {ContactStatusComponent} from './master_contact_status'
import { ContactGroupComponent, } from './master_contact_group';
import { ContactCategoryComponent } from './master_contact_category'
import { ContactTypeComponent } from './master_contact_type';
import { AmenityComponent } from './amaster_amenity';
import { DoNotDistrubComponent } from './master_donotdistrub';
import { GenderComponent } from './master_gender';
import { IdDocumentComponent } from './master_id_document';
import { LanguageComponent } from './master_language';
import { LocalityComponent } from './master_locality';
import { MaritalStatusComponent } from './master_marital_status';
import { NationalityComponent } from './master_nationality';
import { PetOwnerComponent } from './master_pet_owner';
import { SourceComponent } from './master_source';
import { StateComponent } from './master_state';
import { LookingForComponent } from './master_lookingfor';
import { PropertyTypeComponent } from './master_property_type';
import { LeadSourceComponent } from './master_lead_source';
import { LeadGroupComponent } from './master_lead_group';
import { LeadStatusComponent } from './master_lead_status';
import { FurnishingStatusComponent } from './master_furnishing_status';
import { PosessionStatusComponent } from './master_posession_status';
import { VasthuComplaintComponent } from './master_vasthu_complaint';
import { KeyCustodyComponent } from './master_key_custody';
import { KitchenTypeComponent } from './master_kitchen_type';
import { FlooringComponent } from './master_flooring';
import { OwnershipTypeComponent } from './master_ownership_type';
import { PlotTypeComponent } from './master_plot_type';
import { PropertyFacingComponent } from './master_property_facing';
import { PropertySourceComponent } from './master_property_source';
import { PropertyStatusComponent } from './master_property_status';
import { SegmentComponent } from './master_segment';
import { WaterSupplyComponent } from './master_water_supply';
import { CityComponent } from './master_city';
import { AgeOfPropertyComponent } from './master_age_of_property';
import { TaskTypeComponent } from './master_task_type';
import { TaskStatusComponent } from './master_task_status';
import { CurrencyComponent } from './master_currency';
import { DepartmentComponent } from './master_department';
import { DesignationComponent } from './master_designation';
import { BranchComponent } from './master_branch';
import { PropertyInDepthComponent } from './master_property_in_depth';
import { CountryComponent } from './master_country';
import { ProjectStageComponent } from './master_project_stage';
import { SpecificationComponent } from './master_Specification';
import { SiteVisitPreferenceComponent } from './master_site_visit_preference';
import { AvailableForComponent } from './master_available_for';
import { UnitTypeComponent } from './master_unit_type';
import { AttendanceStatusComponent } from './master_attendance_status';
import { PriorityComponent } from './master_priority';
import { MoneyTermsComponent } from './master_money_terms';
import { PortalComponent } from './master_portal';
import { LegalApprovalComponent } from './master_legal_approval';
import { LeaveTypeComponent } from './master_leave_type';
import { ExpenseTypeComponent } from './master_expense_type';
import { getMasterList } from './core/_requests';
import { FinancePaymentStatusComponent } from './master_finance_payment_status';
import { FeeConfirmationStatusComponent } from './master_feeconfirmation_status';
import { FinanceInvoiceStatusComponent } from './master_finance_invoice_status';
import { FinancePaymentModeComponent } from './master_finance_payment_mode';



const Masters: FC = () => {
    
    const [toggle, setToggle] = useState(false);
    const [masterList, setMasterList] = useState<any[]>([]);
    const [filtered, setFiltered] = useState<any[]>([]);
    const [search, setSearch] = useState<any>('');
    const [masterId, setMasterId] = useState<any>('1');

    const mastersList = async () => {
        const response = await getMasterList()
        setMasterList(response);
    }    

    useEffect(() => {
        mastersList();
    }, []);

    function filterItem(item:any, search:any) {
        if (search.startsWith("@")) {
          const bucket = search.toLowerCase().substring(1).split(":");
          const searchBy = bucket[0];
          const searchFor = bucket[1];
          const searchByType = getType(item[searchBy]);
      
      
          if (!searchFor) return false;
          console.log("**** searchBy: ", searchBy);
          console.log("**** searchFor: ", searchFor);
          console.log("**** item: ", item);
          // When search is @status:error
          if (searchByType == "string") {
            // When search is @status:!error
            if (searchFor.startsWith("!")) {
              const bucket = searchFor.split("!");
              const searchForVal = bucket[1];
              return !item[searchBy].includes(searchForVal);
            }
            return item[searchBy].includes(searchFor);
          }
      
          // When search is @flavors:small..
          if (searchByType == "array") {
            // When search is @flavors:!small
            if (searchFor.startsWith("!")) {
              const bucket = searchFor.split("!");
              const searchForVal = bucket[1];
              return item[searchBy].find((val:any) => !val.includes(searchForVal));
            }
            return item[searchBy].find((val:any) => val.includes(searchFor));
          }
      
          // When search is @tags:org=sales
          if (searchByType == "object") {
            // When search is @tags:!sales
            if (searchFor.startsWith("!")) {
              const bucket = searchFor.split("!");
              const val = bucket[1] || "";
              return !item[searchBy][val];
            }
            // When search is @tags:org!=sales
            if (searchFor.includes("!=")) {
              const bucket2 = searchFor.split("!=");
              const key = bucket2[0];
              const val = bucket2[1] || "";
              return item[searchBy][key] && !item[searchBy][key].includes(val);
            }
            const bucket2 = searchFor.split("=");
            const key = bucket2[0];
            const val = bucket2[1] || "";
            return item[searchBy][key] && item[searchBy][key].includes(val);
          }
        } else {
          // When search is !computer.....
          if (search.startsWith("!")) {
            const bucket = search.split("!");
            const searchFor = bucket[1];
            return !item.lower_name.includes(searchFor);
          }
          // When search is computer.....
          return item.lower_name.includes(search);
        }
      }
      
      const getType = (value:any) => {
        if (Array.isArray(value)) {
          return "array";
        } else if (typeof value == "string") {
          return "string";
        } else if (value == null) {
          return "null";
        } else if (typeof value == "boolean") {
          return "boolean";
        } else if (Number(value) == value) {
          return "number";
        } else if (typeof value == "object") {
          return "object";
        }
        return "string";
      };

      useEffect(() => {
        let filteredData:any[] = [];
        if (search.length) {
            masterList.forEach((item) => {
            if (filterItem(item, search.toLowerCase())) {
              filteredData.push({ ...item });
            }
          });
        }
        setFiltered(filteredData);
    }, [search]);

    return(
        <div className='d-flex flex-column flex-lg-row masters_main h-100'>
            <div className='flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0'>
                <div className='card card-flush h-100'>
                <div className='card-header pt-7' id='kt_chat_contacts_header'>
                <h3>Master Field</h3>
                    <form className='w-100 position-relative' autoComplete='off'>
                    <KTSVG
                        path='/media/icons/duotune/general/gen021.svg'
                        className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y'
                    />

                    <input
                        type='text' onChange={(e) => setSearch(e.target.value)}
                        className='form-control form-control-solid px-15'
                        name='search'
                        placeholder='Search masters...'
                    />
                    </form>
                    
                </div>
                <div className='card-body pt-5' id='kt_chat_contacts_body'>
                    <div
                    className='scroll-y me-n5 pe-5 h-200px h-lg-auto thistab'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header'
                    data-kt-scroll-wrappers='#kt_content, #kt_chat_contacts_body'
                    data-kt-scroll-offset='0px'
                    >
                        <ul className="nav nav-pills mb-5 masters_tab d-block" id="pills-tab" role="tablist">
                       
                            {!search ? masterList.map((master, i) => {
                                return(
                            <li className="nav-item w-100 mb-3" role="presentation" key={i} onClick={() => setMasterId(master.id)}>
                                <div className='d-flex flex-stack py-4 nav-link active bg-light cursor-pointer' id="ContactStatus_tab" data-bs-toggle="pill" data-bs-target="#ContactStatus" role="tab" aria-controls="ContactStatus" aria-selected="true">
                                    <div className='d-flex align-items-center'>
                                        <div className='symbol symbol-45px symbol-circle'>
                                            <span className='symbol-label bg_soft text_primary fs-6 fw-bolder'>
                                            {master.name.split(" ")[0][0]}{master.name.split(" ")[1] == undefined ? '' : master.name.split(" ")[1][0]}
                                            </span>
                                        </div>

                                        <div className='ms-5'>
                                            <p className='fs-5 fw-bolder text-gray-900 mb-0'>
                                            {master.name}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column align-items-end ms-2'>
                                    <span className='text-muted fs-7 mb-1'>
                                        <span className="svg-icon svg-icon-2 svg-icon-secondary"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor"></rect><path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor"></path></svg></span>
                                    </span>
                                    </div>
                                </div>
                            </li>)})
                            :
                            filtered.map((master, i) => {
                                return(
                            <li className="nav-item w-100 mb-3" role="presentation" key={i} onClick={() => setMasterId(master.id)}>
                                <div className='d-flex flex-stack py-4 nav-link active bg-light cursor-pointer' id="ContactStatus_tab" data-bs-toggle="pill" data-bs-target="#ContactStatus" role="tab" aria-controls="ContactStatus" aria-selected="true">
                                    <div className='d-flex align-items-center'>
                                        <div className='symbol symbol-45px symbol-circle'>
                                            <span className='symbol-label bg_soft text_primary fs-6 fw-bolder'>
                                            {master.name.split(" ")[0][0]}{master.name.split(" ")[1] == undefined ? '' : master.name.split(" ")[1][0]}
                                            </span>
                                        </div>

                                        <div className='ms-5'>
                                            <p className='fs-5 fw-bolder text-gray-900 mb-0'>
                                            {master.name}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column align-items-end ms-2'>
                                    <span className='text-muted fs-7 mb-1'>
                                        <span className="svg-icon svg-icon-2 svg-icon-secondary"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor"></rect><path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor"></path></svg></span>
                                    </span>
                                    </div>
                                </div>
                            </li>)})}                            
                        </ul>
                    </div>
                </div>
                </div>
            </div>

            <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10'>
                <div className="card">
                    <div className='card-header pt-7' id='kt_chat_contacts_header'>
                        <h3>Master Field Mapping</h3>
                    </div>
                    <div className="tab-content p-4" id="pills-tabContent">
                        <div className='card tab-pane fade show active' id="ContactStatus" role="tabpanel" aria-labelledby="ContactStatus_tab">

                            {masterId == 2 && <ContactStatusComponent />}
                        
                            {masterId == 33 && <ContactGroupComponent />}
                        
                            {masterId == 4 && <ContactTypeComponent />}

                            {masterId == 3 && <ContactCategoryComponent />}
                            
                            {masterId == 1 && <AmenityComponent />}
                            
                            {masterId == 5 && <DoNotDistrubComponent />}
                            
                            {masterId == 6 && <GenderComponent />}
                            
                            {masterId == 7 && <IdDocumentComponent />}
                            
                            {masterId == 8 && <LanguageComponent />}
                            
                            {masterId == 9 && <LocalityComponent />}

                            {masterId == 10 && <MaritalStatusComponent />}
                            
                            {masterId == 11 && <NationalityComponent />}
                            
                            {masterId == 12 && <PetOwnerComponent />}
                            
                            {masterId == 13 && <SourceComponent />}
                            
                            {masterId == 14 && <StateComponent />}
                            
                            {masterId == 15 && <LookingForComponent />}
                            
                            {masterId == 16 && <PropertyTypeComponent />}
                            
                            {masterId == 17 && <LeadSourceComponent />}
                            
                            {masterId == 18 && <LeadGroupComponent />}
                            
                            {masterId == 19 && <LeadStatusComponent />}
                            
                            {masterId == 20 && <FurnishingStatusComponent />}
                            
                            {masterId == 21 && <PosessionStatusComponent />}
                            
                            {masterId == 22 && <VasthuComplaintComponent />}
                            
                            {masterId == 23 && <KeyCustodyComponent />}
                            
                            {masterId == 24 && <KitchenTypeComponent />}
                            
                            {masterId == 25 && <FlooringComponent />}
                        
                            {masterId == 26 && <OwnershipTypeComponent />}
                            
                            {masterId == 27 && <PlotTypeComponent />}
                            
                            {masterId == 28 && <PropertyFacingComponent />}
                            
                            {masterId == 29 && <PropertySourceComponent />}
                            
                            {masterId == 30 && <PropertyStatusComponent />}
                            
                            {masterId == 31 && <SegmentComponent />}
                            
                            {masterId == 32 && <WaterSupplyComponent />}
                            
                            {masterId == 34 && <CityComponent />}
                            
                            {masterId == 35 && <AgeOfPropertyComponent />}
                            
                            {masterId == 37 && <TaskTypeComponent />}
                            
                            {masterId == 38 && <TaskStatusComponent />}
                            
                            {masterId == 39 && <CurrencyComponent />}
                        
                            {masterId == 40 && <DesignationComponent/>}
                            
                            {masterId == 41 && <DepartmentComponent />}
                            
                            {masterId == 42 && <BranchComponent />}
                            
                            {masterId == 43 && <PropertyInDepthComponent />}
                            
                            {masterId == 44 && <CountryComponent />}
                            
                            {masterId == 45 && <ProjectStageComponent />}
                            
                            {masterId == 46 && <SpecificationComponent />}
                            
                            {masterId == 47 && <SiteVisitPreferenceComponent />}
                            
                            {masterId == 48 && <AvailableForComponent />}
                            
                            {masterId == 49 && <UnitTypeComponent />}
                            
                            {masterId == 51 && <AttendanceStatusComponent />}
                            
                            {masterId == 36 && <PriorityComponent />}
                            
                            {masterId == 53 && <MoneyTermsComponent />}
                            
                            {masterId == 52 && <PortalComponent />}
                            
                            {masterId == 50 && <LegalApprovalComponent />}
                            
                            {masterId == 54 && <LeaveTypeComponent />}
                            
                            {masterId == 55 && <ExpenseTypeComponent />}

                            {masterId == 56 && <FeeConfirmationStatusComponent />}

                            {masterId == 57 && <FinanceInvoiceStatusComponent />}

                            {masterId == 58 && <FinancePaymentModeComponent />}
                        </div>                        
                    </div>    
                </div>
            </div>
        </div>
    )
}
export {Masters}