import React,{FC, useState,useEffect} from 'react'
import {deleteProperty, getProperties, getPropertiesSortBy, getPropertiesTl, getPropertyStatus, savePropertyNotes, updatePropertyStatus} from './core/_requests'
import Moment from 'moment';
import { Offcanvas, Toast } from 'bootstrap';
import { PropertyDrawer } from './propertyDrawers';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import {useAuth} from '../../../app/modules/auth'
import { PropertyDetails } from './propertyDetails'
import { PropertyListView } from './propertyListView'
import { PropertyToolbar } from './propertyToolbar';
import * as Yup from 'yup'
import {useFormik} from 'formik'

const initialValues = {
    reply: '',
}

const PropertyList: FC = () => {

    const [properties, setProperties] = useState<any[]>([]);
    const [propertyStatus, setPropertyStatus] = useState<any[]>([]);
    const {currentUser, logout} = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [detailData, setDetailData] = useState('');
    const [detailTab, setDetailTab] = useState('');
    const [propertyStatusChanges, setPropertyStatusChange] = useState('');
    const [propertyId, setPropertyId] = useState<any>('');
    const [detailClick, setDetailClick] = useState(false);
    const [propCheckList, setPropCheckList] = useState<any[]>([]);
    const [toggle, setToggle] = useState('grid');

    var roleId = currentUser?.designation;
    var userId = currentUser?.id;

    
  console.log('setPropertiesList in grid view')
  console.log(properties)

    const propertyList =  async () => {
        setIsLoading(true);
        const propertyResponse = await getPropertiesTl(userId, roleId)
        setProperties(propertyResponse);
        setIsLoading(false);
    }

    const handleHideData = (e:any) => {
        setToggle(e.target.value);
    };

    const propertyStatusList = async() => {
        const propertyStatusResponse = await getPropertyStatus()        
        setPropertyStatus(propertyStatusResponse)
    }

    const propertyStatusChange = async (e:any, id:any) => {
        setPropertyId(id);
        document.getElementById('property_status_pop')?.click();
        setPropertyStatusChange(e.target.value);
        
    }

    const openModal = (propId:any, tabType:any) => {
        setDetailData(propId);
        setDetailTab(tabType);
        setDetailClick(true);
        document.body.className += ' detail_opened';
        var offCanvasEl = document.getElementById('kt_expand'+propId);
        offCanvasEl?.classList.remove('invisible');
        const bsOffcanvas = new Offcanvas(offCanvasEl!);
        bsOffcanvas.show();
    }

    const deleteModel = async (leadData:any) => {
        setIsLoading(false);
        console.log(leadData);
        await deleteProperty(leadData);
        setIsLoading(false);
        propertyList();
        var toastEl = document.getElementById('myToastDeleteStatus');
            const bsToast = new Toast(toastEl!);
            bsToast.show();
    } 

    useEffect(() => {
        propertyList();
        propertyStatusList();
    }, []);

    const data = [];
    for (var i = 0; i < properties.length; i++) {
        console.log(properties[i]);
        let object = {
            id: properties[i].id,
            contactId: properties[i].contact_id,
            contactName: properties[i].contact_name,
            companyName: properties[i].company_name,
            PropertyIndepth: properties[i].property_indepth_name,
            segment: properties[i].segment_name,
            propertyType: properties[i].property_type_name,
            PropertySource: properties[i].property_source_name,
            PropertyStatus: properties[i].property_status_name,
            PropertyName: properties[i].property_name,
            legalApproval: properties[i].property_legal_approval_name,
            // createdAt: Moment(properties[i].created_at).format("DD-MMMM-YYYY HH:MM"),
            // updatedAt: Moment(properties[i].updated_at).format("DD-MMMM-YYYY HH:MM"),
            // deletedAt: Moment(properties[i].deleted_at).format("DD-MMMM-YYYY HH:MM"),
            address: properties[i].property_address,
            country: properties[i].country,
            state: properties[i].state,
            city: properties[i].city,
            locality: properties[i].locality,
            pincode: properties[i].pincode,
            availableFor: properties[i].available_for_name,
            sortable: true, 
        }
        data.push(object)
    }

    const notesFormSchema = Yup.object().shape({
        reply: Yup.string(),
    })

    const formikNotes = useFormik({
        initialValues,
        validationSchema: notesFormSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
        //   console.log('on presssed');
        //   console.log(leadId);
        //   setIsLoading(true)
          try {
            console.log('propertyId')
            console.log(propertyId)
            var userId = currentUser?.id;
            var roleId = currentUser?.designation;

            var notesBody = {
                "reply": values.reply,
                "property_id": propertyId,
                "user_id": userId,
                "parent_id": 0
            };
                           
            const leadNotesData = await savePropertyNotes(notesBody)
    
            // console.log('updateContactAddressData');
            // console.log(leadNotesData);
            if(leadNotesData != null){
                // setIsLoading(false);
                resetForm();
            //   var toastEl = document.getElementById('myToastUpdate');
            //   const bsToast = new Toast(toastEl!);
            //   bsToast.show();                
            }

            const body = {
                "property_status": propertyStatusChanges,
                "created_by": userId,
            }
    
            const updateTask = await updatePropertyStatus(propertyId, body);
            console.log(updateTask);
    
            if(updateTask != null){
                var toastEl = document.getElementById('myToastStatus');
                const bsToast = new Toast(toastEl!);
                bsToast.show();
                propertyList();
            }
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setIsLoading(false)
          }
        },
    })

    const cancelStatusChange = () => {
        // alert();
        propertyList();
    }

    const propOnSelect = (e:any, id:any) => {
        console.log('sdsdsdsdsdsd');
        console.log(e.target.checked);
        const newArr = [...propCheckList];
        if(e.target.checked != false){
            setPropCheckList(propCheckList => [...propCheckList, id]);
        } else {
            newArr.splice(newArr.findIndex(item => item === id), 1);
            setPropCheckList(newArr);
        }
        console.log(propCheckList);
    }

    const sortByChangeProperty = async (e:any) => {
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const propertyResponse = await getPropertiesSortBy(userId, roleId, e)
        setProperties(propertyResponse);
    }

    return (
        <div className="property_list">
            <PropertyToolbar sortByOnChangeProperty={sortByChangeProperty} selectedProps={propCheckList} setPropList={setProperties} layoutOnChange={handleHideData}/>
            <PropertyDrawer setPropList={setProperties}/>            
            <button className='d-none' type='button' id='propertyReloadBtn' onClick={propertyList}>reload</button>
            {isLoading ? 
                <div className='w-100 h-100'>
                    <div className='d-flex justify-content-center flex-column align-items-center h_80vh'>
                        <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
                        <div className="spinner-border taskloader" role="status">                                    
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div> 
                </div> :<>
                <div className={toggle == 'grid' ? "contact_page d-block" : 'd-none'}>
                <div className="row">
                    <div className="card-group">
                    {properties.map((propertyData, i) => {
                                return(
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xl-4 mb-4" key={i}>
                            <div className="card h-100 mb-5 mb-xl-8 mx-2 bs_1 property_card">
                                <div className='card-body px-3 pt-3 pb-2 pb-md-0'>
                                    <div className="d-flex align-items-center justify-content-between mb-5">
                                    <div className="d-flex align-items-center">
                                        {/* modal */}
                                        <div>
                                            {
                                                detailClick && detailData == propertyData.id && 
                                                <>
                                                    <div
                                                        id={'kt_expand'+propertyData.id}
                                                        className='expand_area detail_page_view offcanvas offcanvas-end justify-content-end w-100 bg-transparent d-flex'
                                                        // data-kt-drawer='true'
                                                        // data-kt-drawer-name='filter'
                                                        // data-kt-drawer-activate='true'
                                                        // data-kt-drawer-overlay='true'
                                                        // data-kt-drawer-width="{default:'96%', 'md': '75%'}"
                                                        // data-kt-drawer-direction='end'
                                                        // data-kt-drawer-toggle={'#kt_expand_toggle'+propertyData.id}
                                                        // data-kt-drawer-close='#kt_expand_close'
                                                    >
                                                        <PropertyDetails propertyId={propertyData.id} setPropertyList={setProperties} tabInfo={detailTab} setDetailClicked={setDetailClick}/>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        {/* modal */}
                                            <form action="">
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" onClick={(e) => propOnSelect(e, propertyData.id)} id={"contact"+propertyData.id}/>
                                                    <label className="form-check-label id_label" htmlFor={"contact"+propertyData.id}>
                                                        {propertyData.id}
                                                    </label>
                                                </div>
                                            </form>
                                            <div className="ms-3 ml-2 d-flex align-items-center">
                                                <p className="mb-0 contact_name pb-1">{propertyData.company_name}</p>
                                            </div>
                                            {/* <p className="mb-0 me-3">2365415</p> */}
                                    </div>
                                    <div className="d-flex">
                                            <a href='#' onClick={() => openModal(propertyData.id, 'overview')}><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></a>
                                            <div className="btn-group">
                                                <a className="" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                    <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/>
                                                </a>
                                                <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                                    <li><a className="dropdown-item" href="#" onClick={() => openModal(propertyData.id, 'overview')}>Edit</a></li>
                                                    { roleId == 1 ? <li><a className="dropdown-item" href="#" data-bs-toggle='modal'
                                                        data-bs-target={'#delete_confirm_popup418'+propertyData.id}>Delete</a></li> : <></>}
                                                </ul>
                                            </div>
                                            <div className='modal fade' id={'delete_confirm_popup418'+propertyData.id} aria-hidden='true'>
                                                <div className='modal-dialog modal-dialog-centered'>
                                                    <div className='modal-content'>
                                                        <div className='modal-header'>
                                                            <h3>Confirmation</h3>
                                                            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                                            </div>
                                                        </div>
                                                        <div className='modal-body py-lg-10 px-lg-10'>
                                                            <p>Are you sure want to delete?</p>
                                                            <div className='d-flex align-items-center justify-content-end'>
                                                                <button className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal'>
                                                                    No
                                                                </button>
                                                                <button className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal' onClick={(e) => deleteModel(propertyData.id)}>
                                                                    Yes
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        
                                    <div className='mb-3'>
                                        <div className="d-flex gap-3 p-2">
                                            <div className="">
                                                {/* <img src={toAbsoluteUrl('/media/avatars/property_img1.jpg')} className="br_5 w-85px" alt='' /> */}
                                                <img onError={e => { e.currentTarget.src = toAbsoluteUrl('/media/avatars/propertySample.jpg') }} src={propertyData.property_image_filename ? process.env.REACT_APP_API_BASE_URL+'uploads/property/images/'+propertyData.id+'/'+propertyData.property_image_filename : ''} className="br_10 w-85px h-100" alt='' />
                                            </div>
                                            <div className="">
                                                <div>
                                                    {/* <h3>{propertyData.company_name != 'null' ? propertyData.company_name : 'Company'}</h3> */}
                                                    <p>
                                                        <img src={toAbsoluteUrl('/media/custom/project_type.svg')} className="svg_icon me-1" alt='' />
                                                        {propertyData.name_of_building ?? 'Property Name'}
                                                    </p>
                                                    <p>
                                                        <img src={toAbsoluteUrl('/media/custom/lead/location_10.svg')} className="svg_icon me-1" alt='' />{propertyData.address_line2 ?? 'No Address'}</p>
                                                    <div className="d-flex">
                                                        <p className="me-2">
                                                            <img src={toAbsoluteUrl('/media/custom/residential.svg')} className="svg_icon me-1" alt='' />
                                                            {propertyData.segment_name ?? 'No Segment'}
                                                        </p>
                                                        <p>
                                                            <img src={toAbsoluteUrl('/media/custom/flat.svg')} className="svg_icon me-1" alt='' />
                                                            {propertyData.property_type_name ?? 'No type'}
                                                        </p>
                                                    </div>
                                                </div>  
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mb-3'>
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="accordion" id="accordionPanelsStayOpenExample">
                                                    {/* {propertyData.property_units[0].builtup_area_max} */}
                                                    {propertyData.property_units.length > 0 ? 
                                                    <div className="accordion-item border-0">
                                                        <p className="accordion-header" id="panelsStayOpen-headingOne">
                                                            <button className="accordion-button collapsed py-2 px-xxl-5 px-md-4 px-3 text-dark fw-bold" type="button" data-bs-toggle="collapse" data-bs-target={"#property_features"+propertyData.id} aria-expanded="true" aria-controls={"property_features"+propertyData.id}>
                                                                Project Features
                                                            </button>
                                                        </p>                                                        
                                                        <div id={"property_features"+propertyData.id} className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                                                            <div className="accordion property_accordion px-2 py-1" id="property_accordion2">
                                                            {propertyData.property_units.map((unitData:any, index:any) => {
                                                            return (
                                                                <>
                                                                <div className="accordion-item px-xxl-4">
                                                                    <p className="accordion-header d-flex justify-content-between" id={"headingTwo"+index+propertyData.id}>
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#property_info"+index+propertyData.id} aria-expanded="false" aria-controls={"property_info"+index+propertyData.id}>
                                                                        <img src={toAbsoluteUrl('/media/custom/lead/bhk_4.svg')} className="svg_icon me-1" alt='' />
                                                                        {unitData.unit_type}
                                                                        </button>
                                                                        <p className='mb-0'>{unitData.builtup_area_min}-{unitData.builtup_area_max} Sqft</p>
                                                                        
                                                                    </p>
                                                                    <div id={"property_info"+index+propertyData.id} className="accordion-collapse collapse" aria-labelledby={"headingTwo"+index+propertyData.id} data-bs-parent="#property_accordion2">
                                                                        <div className="accordion-body px-0 py-2">
                                                                            <div className="d-flex justify-content-between">
                                                                            <p className='mb-0'>{unitData.price_min}-{unitData.price_max} {unitData.currency_name}</p>
                                                                                <p className='mb-0'>{unitData.total_units} Units</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </>
                                                                )})}
                                                            </div>
                                                        </div>
                                                    </div> : <>
                                                    <div className="accordion-item border-0">
                                                        <p className="accordion-header" id="panelsStayOpen-headingOne">
                                                            <button className="accordion-button collapsed py-2 px-xxl-5 px-md-4 px-3 text-dark fw-bold" type="button" data-bs-toggle="collapse" data-bs-target={"#property_features"+propertyData.id} aria-expanded="true" aria-controls={"property_features"+propertyData.id}>
                                                                Project Features
                                                            </button>
                                                        </p>
                                                        <div id={"property_features"+propertyData.id} className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                                                            <div className='accordion-item px-xxl-4 mb-1'>
                                                            No Features available
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </>}
                                                    
                                                    
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                    <div className="d-flex align-items-center me-2 mb-2">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/assign_9.svg')} alt="" className="icon me-2"/>
                                                        <p className="ml-2 mb-0 fixed_text">{propertyData.assign_to_name ?? '--'}</p>
                                                    </div>
                                                    <div className="d-flex align-items-center me-2 mb-2"> 
                                                        <img src={toAbsoluteUrl('/media/custom/calendar.svg')} alt="" className="icon me-2"/>
                                                        <p className="ml-2 mb-0 fixed_text">{Moment(propertyData.created_at).format('DD-MMMM-YYYY')}</p>
                                                    </div>
                                                    {/* <small className="mb-0 bg_secondary rounded-pill p-2 me-2 mb-2">{propertyData.property_status_name ?? 'Under Construction'}</small> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer border-0 p-1">
                                        <div className="row">
                                            <div className="col-7 col-xl-8 icons_bar d-flex flex-wrap">
                                                <a href="#" onClick={() => openModal(propertyData.id, 'notes')} className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Notes">
                                                    <img src={toAbsoluteUrl('/media/custom/notes.svg')} className="svg_icon" alt='' />
                                                    <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                        0
                                                    </span>
                                                </a>
                                                <a href="#" onClick={() => openModal(propertyData.id, 'files')} className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="file">
                                                    <img src={toAbsoluteUrl('/media/custom/file.svg')} className="svg_icon" alt='' />
                                                    <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                        0
                                                    </span>
                                                </a>
                                                <a href="#" onClick={() => openModal(propertyData.id, 'message')} className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Message">
                                                    <img src={toAbsoluteUrl('/media/custom/message.svg')} className="svg_icon" alt='' />
                                                    <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                        0
                                                    </span>
                                                </a>
                                                <a href="#" onClick={() => openModal(propertyData.id, 'sec_contact')} className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="SecContact">
                                                    <img src={toAbsoluteUrl('/media/custom/sec_contact.svg')} className="svg_icon" alt='' />
                                                    <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                        0
                                                    </span>
                                                </a>
                                                <a href="#" onClick={() => openModal(propertyData.id, 'lead')} className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Lead">
                                                    <img src={toAbsoluteUrl('/media/custom/lead.svg')} className="svg_icon" alt='' />
                                                    <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                        0
                                                    </span>
                                                </a>
                                                <a href="#" onClick={() => openModal(propertyData.id, 'task')} className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Task">
                                                    <img src={toAbsoluteUrl('/media/custom/task.svg')} className="svg_icon" alt='' />
                                                    <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                        0
                                                    </span>
                                                </a>
                                            </div>
                                            <div className="col-5 col-xl-4 d-flex align-items-center justify-content-end">
                                                <select className="form-select toggle_white toggle_white btn_primary rounded-pill btn-sm cursor-pointer status_btn" aria-label="Default select example" onChange={(e) => propertyStatusChange(e, propertyData.id)}>
                                                    {propertyStatus.map((propStatus, i) => {
                                                        return (
                                                        <option value={propStatus.id} selected={propStatus.id == propertyData.property_status}>{propStatus.name}</option>
                                                        )
                                                    })}
                                            </select>
                                            </div>
                                            <a className="d-none" href="#" data-bs-toggle='modal' id='property_status_pop'
                                                data-bs-target={'#contact_status_change'+propertyData.id}></a>
                                                <div className='modal fade' id={'contact_status_change'+propertyData.id} aria-hidden='true'>
                                                    <div className='modal-dialog modal-dialog-centered'>
                                                        <div className='modal-content'>
                                                            <div className='modal-header'>
                                                                <h3>Confirmation</h3>
                                                                <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                                                </div>
                                                            </div>
                                                            <div className='modal-body py-lg-10 px-lg-10'>
                                                                <form noValidate onSubmit={formikNotes.handleSubmit}>
                                                                    <textarea
                                                                        className='form-control main_bg border-0 p-2 resize-none min-h-25px br_10'
                                                                        data-kt-autosize='true'
                                                                        {...formikNotes.getFieldProps('reply')} 
                                                                        rows={7}
                                                                        placeholder='Reason'
                                                                    ></textarea>
                                                                    <div className='d-flex align-items-center justify-content-end'>
                                                                        <button className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal' type='button' onClick={(e) => cancelStatusChange()}>
                                                                            No
                                                                        </button>
                                                                        <button disabled={formikNotes.isSubmitting} className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal' type='submit'>
                                                                            Yes
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )})}
                    </div>
                </div>
                </div>
                { toggle == 'list' &&
                <div className=''>
                    <div className="card table_card p-3">
                        <PropertyListView propertyListView={data}/>
                    </div>
                </div>
                }</>
            }
            <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToast">
                <div className="toast-header">
                        <strong className="me-auto">Success</strong>
                        <button aria-label="Close" className="btn-close" 
                                data-bs-dismiss="toast" type="button">
                        </button>
                </div>
                <div className="toast-body">
                    Property Updated Successfully!
                </div>
            </div>
             <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastStatus">
                <div className="toast-header">
                        <strong className="me-auto">Success</strong>
                        <button aria-label="Close" className="btn-close" 
                                data-bs-dismiss="toast" type="button">
                        </button>
                </div>
                <div className="toast-body">
                    Property Status Updated Successfully!
                </div>
            </div>
             <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastDeleteStatus">
                <div className="toast-header">
                        <strong className="me-auto">Success</strong>
                        <button aria-label="Close" className="btn-close" 
                                data-bs-dismiss="toast" type="button">
                        </button>
                </div>
                <div className="toast-body">
                    Property Status Deleted Successfully!
                </div>
            </div>
             <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastUpdate">
                <div className="toast-header">
                        <strong className="me-auto">Success</strong>
                        <button aria-label="Close" className="btn-close" 
                                data-bs-dismiss="toast" type="button">
                        </button>
                </div>
                <div className="toast-body">
                    Property Updated Successfully!
                </div>
            </div>
            <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastAdd">
                <div className="toast-header">
                        <strong className="me-auto">Success</strong>
                        <button aria-label="Close" className="btn-close" 
                                data-bs-dismiss="toast" type="button">
                        </button>
                </div>
                <div className="toast-body">
                    Property Created Successfully!
                </div>
            </div>
            <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastUpload">
                <div className="toast-header">
                    <strong className="me-auto">Success</strong>
                    <button aria-label="Close" className="btn-close" 
                            data-bs-dismiss="toast" type="button">
                    </button>
                </div>
                <div className="toast-body">
                    Property Imported Successfully!
                </div>
            </div>
        </div>
    )
}

export {PropertyList}
