import React,{FC, useEffect, useRef, useState} from 'react'
import DataTable from 'react-data-table-component';
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { deleteRoleManagement, getDesignation, getDesignationDrop, getRoleManagement, getRoleManagementList, saveRoleManagement, updateRoleManagement } from './core/_requests';
import { deleteDesignation, saveDesignation, updateDesignation } from '../masters/core/_requests';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Moment from 'moment';
import { useAuth } from '../../../modules/auth';
import { Theme, useTheme } from '@mui/material/styles';
import {Link} from 'react-router-dom'
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


const initialValues = {
    designation: '',
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, aminityName: string[], theme: Theme) {
  return {
      fontWeight:
      aminityName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
  };
}

const Designationcolumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 50,headerClassName: 'dg_header' },
    { field: 'name', headerName: 'Name', width: 150,headerClassName: 'dg_header' },
    { field: 'created_by', headerName: 'Created By', width: 240,headerClassName: 'dg_header' },
    { field: 'updated_at', headerName: 'Updated At', type: 'number', width: 240,headerClassName: 'dg_header'},
  ];

const RoleProfile: FC = () => {

    const [contactSwitch, setContactSwitch] = useState(false);
    const checkboxRef = useRef();
    const DesignationSchema = Yup.object().shape({
        designation: Yup.string().required('Designation is required'),
    })

    const [Designation, setDesignation] = useState<any[]>([]);
    const [assignToId, setAssignToId] = useState<any[]>([]);
    const [assignToName, setAssignToName] = useState<any[]>([]);
    const [desDrop, setDesDrop] = useState<any[]>([]);
    const [getById, setgetById] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [editClicked, setEditClicked] = useState(false);
    const [editId, setEditId] = useState('');
    const {currentUser, logout} = useAuth();
    const theme = useTheme();

    var userId = currentUser?.id;
    var roleId = currentUser?.designation;
    var orgId = currentUser?.organization_id;
    // console.log('currentUser34234234', currentUser?.organization_id)
    const DesignationList =  async () => {
        const DesignationResponse = await getRoleManagementList()
        setDesignation(DesignationResponse);
    }

    const dropdownList = async () => {
        const response = await getDesignationDrop()
        setDesDrop(response);
    }

    useEffect(() => {
        DesignationList();
        dropdownList();
    }, []);
    
    const formik = useFormik({
        initialValues,
        validationSchema: DesignationSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
            console.log()
          setLoading(true)
          try {
            let des = assignToId.join(',').toString();
            const body = {
                'designation': values.designation,
                'reporting_to': des, 
                'created_by': userId
            }
            if(!editClicked){
                const saveDesignationData = await saveRoleManagement(body);
                setLoading(false)
                resetForm();
                setAssignToId([]);
                setAssignToName([]);
                DesignationList();
                console.log(saveDesignationData);
            } else {
                const updateDesignationData = await updateRoleManagement(editId, body);
                setLoading(false)
                setEditClicked(false);
                setEditId('');
                resetForm();
                setAssignToId([]);
                setAssignToName([]);
                DesignationList();
                console.log(updateDesignationData);
            }
          }
        catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
    }})

    const editTap = async (value:any, id:any) => {       
        console.log(value, id);
        const response = await getRoleManagement(id)
        setgetById(response);
        setEditClicked(true);
        setEditId(id);
        document.getElementById('kt_addprofile_open')?.click();
        formik.setFieldValue('designation', response.designation ?? ''); 

        var assignArray = [];
        var assignNameArray = [];
        if(response.reporting_to != null){
            assignArray = response.reporting_to.split(",").map((e:any) => {
                return parseInt(e);
            });
        }
        if(response.reporting_to_name != null){
            assignNameArray = response.reporting_to_name.split(",").map((e:any) => {
                return e;
            });
        }
    
        setAssignToId(assignArray);
        setAssignToName(assignNameArray);   
    }

    const editCancel = () => {
        setEditClicked(false);
        setEditId('');
        setAssignToId([]);
        setAssignToName([]);
        formik.setFieldValue('designation', '');
    }

    const onDelete = async (id:any) => {
        console.log(id);
        await deleteRoleManagement(id);
        DesignationList();
    }


    const assingToChange = (event: SelectChangeEvent<typeof assignToName>) => {
        const {
          target: { value },
        } = event;
    
        console.log('assign event');
        console.log(value);
    
        var name = [];
        var id = [];
        
    
        for(let i = 0; i < value.length; i++){
          var fields = value[i].split('-');
    
          var n = fields[0];
          var d = fields[1];
    
          name.push(n);
          id.push(d);
        }
    
        console.log(name);
        console.log(id);
    
        setAssignToId(id);
    
        setAssignToName(
          typeof value === 'string' ? value.split(',') : value,
        );
      };

    // const handleClick = () => {
    //     open
    // }

    // console.log(checkboxRef.current.checked)
    
 console.log('contactSwitch')
 console.log(contactSwitch)
    return(
        <section className="role_profile">
            <div className="row">
                <div className="card-group">
                    <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 mb-4">
                        <div className="card h-100 bs_1 mx-3">
                            <div className="card-header d-flex justify-content-between">                               
                                    <h4>Profile Setting</h4>
                                    {roleId == 1 &&
                                    <button className="btn btn-icon" type="button" id='kt_addprofile_open' data-bs-toggle='modal'
                                data-bs-target={'#add_profile_popup'}>
                                        <KTSVG path="/media/icons/duotune/general/gen041.svg" className="svg-icon-muted svg-icon-2hx" />
                                    </button> }                             
                                {/* open modal */}
                            <div className='modal fade' id={'add_profile_popup'} aria-hidden='true'>
                                <div className='modal-dialog modal-dialog-centered'>
                                    <div className='modal-content'>
                                        <div className='modal-header'>
                                            <h3>Add Profile</h3>
                                            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={editCancel} data-bs-dismiss='modal'>
                                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                            </div>
                                        </div>
                                        <div className='modal-body py-lg-10 px-lg-10'>
                                        <form noValidate onSubmit={formik.handleSubmit}>
                                            
                                                {/* <input type="text" className="form-control" placeholder="Designation" {...formik.getFieldProps('Designation_name')}/>
                                                {formik.touched.Designation_name && formik.errors.Designation_name && (
                                                    <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>{formik.errors.Designation_name}</span>
                                                    </div>
                                                    </div>
                                                )} */}
                                                <div className="mb-3">
                                                    <label htmlFor="basic-url" className="form-label required">Designation</label>
                                                    <div className="input-group first mb-3 input_prepend">
                                                        <input type="text" {...formik.getFieldProps('designation')}
                                                            className="form-control" placeholder="Designation..." />
                                                            {/* <select 
                                                                {...formik.getFieldProps('designation')} 
                                                                className="form-select w-100">
                                                                <option value=''>Select</option>
                                                                {desDrop.map((designValue,i)=> {
                                                                return (
                                                                <option selected={i == 0 ? true: false} value={designValue.id} key={i}>{designValue.name}</option>
                                                                )
                                                                })} 
                                                            </select> */}
                                                    </div>
                                                    {formik.touched.designation && formik.errors.designation && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert' className='text-danger'>{formik.errors.designation}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="basic-url" className="form-label">Report to</label>
                                                    <FormControl sx={{ m: 0, width: '100%', mt: 0 }}>
                                                        <Select
                                                            multiple
                                                            displayEmpty
                                                            value={assignToName}
                                                            onChange={assingToChange}
                                                            input={<OutlinedInput />}
                                                            renderValue={(selected) => {
                                                                var name = [];
                                                                var id = [];

                                                                for(let i = 0; i < selected.length; i++){
                                                                var fields = selected[i].split('-');

                                                                var n = fields[0];
                                                                var d = fields[1];

                                                                name.push(n);
                                                                id.push(d);
                                                                }

                                                                // setAssignToId(id);

                                                                console.log('selected');
                                                                console.log(selected);
                                                                if (selected.length === 0) {
                                                                    return <p>Report To</p>;
                                                                }

                                                                return name.join(', ');
                                                            }}
                                                            className='multi_select_field'
                                                            MenuProps={MenuProps}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                            >
                                                            <MenuItem disabled value="">
                                                                <em>Report To</em>
                                                            </MenuItem>
                                                            {desDrop.map((assignVal) => (
                                                                <MenuItem
                                                                key={assignVal.id}
                                                                value={assignVal.name+'-'+assignVal.id}
                                                                style={getStyles(assignVal.name, assignToName, theme)}
                                                                >
                                                                {assignVal.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        </FormControl>
                                                    </div>
                                                <div className='me-n3 d-flex flex-column align-items-end'>
                                                <div className="d-flex align-items-center">
                                                    {editClicked &&
                                                    <button className='btn btn-sm btn-secondary mt-3 me-3' onClick={editCancel}  data-bs-toggle='modal'>
                                                        Cancel
                                                    </button>}
                                                    <button
                                                        type='submit'
                                                        data-bs-toggle='modal'
                                                        id='kt_sign_up_submit'
                                                        className='btn btn-sm btn_primary text-primary mt-3'
                                                        disabled={formik.isSubmitting}
                                                        >
                                                        {!loading && <span className='indicator-label'>{editClicked ? "Update" : "Create" }
                                                        </span>}
                                                        {loading && (
                                                            <span className='indicator-progress' style={{display: 'block'}}>
                                                            Please wait...{' '}
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                            </span>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div className="card-body">
                            {/* <DataGrid
                                rows={Designation}
                                columns={Designationcolumns}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                checkboxSelection
                                sx={{
                                    fontSize:14,
                                    fontWeight:500,
                                }}
                            /> */}
                            <table className='table border role_table'>
                                <thead>
                                <tr className='bg-gray-200'>
                                    <td className='ps-5' scope="col">Id</td>
                                    <td scope="col">Name</td>
                                    <td scope="col">Report to</td>
                                    {/* <td scope="col">Created By</td>
                                    <td scope="col">Updated At</td> */}
                                    {roleId == 1 && <>
                                    <td scope="col">Actions</td></>}
                                </tr>
                                </thead>
                                <tbody>
                                {Designation.map((Data, i) => {
                                return(
                                <tr className=''>
                                    <td className='ps-5' scope="row">{i+1}</td>
                                    <td>{Data.designation_name}</td>
                                    <td>{Data.reporting_to_name}</td>
                                    {/* <td>{Moment(Data.created_by).format("DD-MM-YYYY HH:mm")}</td>
                                    <td>{Moment(Data.updated_at).format("DD-MM-YYYY HH:mm")}</td> */}
                                    <td className='d-flex shrink-0 h-100 w-100 p-3'>
                                    {roleId == 1 && <>
                                    <button onClick={(e) => editTap(Data.name, Data.id)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"><span className="svg-icon svg-icon-4"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path><path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path></svg></span></button>
                                    
                                    <a href="#" data-bs-toggle='modal'
                                    data-bs-target={'#delete_confirm_popup452222'+Data.id} className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"><span className="svg-icon svg-icon-4"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"></path><path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"></path><path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"></path></svg></span></a></>}
                                    <div className='modal fade' id={'delete_confirm_popup452222'+Data.id} aria-hidden='true'>
                                    <div className='modal-dialog modal-dialog-centered'>
                                        <div className='modal-content'>
                                            <div className='modal-header'>
                                                <h3>Confirmation</h3>
                                                <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                                </div>
                                            </div>
                                            <div className='modal-body py-lg-10 px-lg-10'>
                                                <p>Are you sure want to delete?</p>
                                                <div className='d-flex align-items-center justify-content-end'>
                                                    <button className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal'>
                                                        No
                                                    </button>
                                                    <button className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal' onClick={(e) => onDelete(Data.id)}>
                                                        Yes
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    </td>
                                </tr>
                                
                            
                                )})}
                                </tbody>
                            </table>
                            
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 mb-4">
                        <div className="card h-100 bs_1 mx-3">
                            <div className="card-header">
                                <h4>Role Setting</h4>
                            </div>
                            <div className="card-body">
                                <form action="">
                                    <div className="row">
                                        <div className="col-md-6 col-12 mb-3">
                                            <label className="form-label">Search</label>
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" placeholder="Enter to Search" aria-label="Search"/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion" id="accordionExample">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header d-flex flex-center" id="headingOne">
                                                        <button className="accordion-button p-4" disabled={true} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                            Contact
                                                        </button>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1"/>
                                                            {/* <label className="form-check-label" htmlFor="flexSwitchCheckDefault1"></label> */}
                                                        </div>
                                                    </h2>
                                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <div className="row">
                                                                <div className="col-12 d-md-flex justify-content-between">
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">View*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Edit*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Delete*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Archive*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Permission</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Assign</option>
                                                                                <option>Bulk Edit</option>
                                                                                <option>Create</option>
                                                                                <option>Import</option>
                                                                                <option>Export</option>
                                                                                <option>Notify</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Hidden Fields-Own Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Read Only Fields-Own Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Hidden Fields-Other Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Read Only Fields-Other Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header d-flex flex-center" id="headingTwo">
                                                        <button className="accordion-button p-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                            Lead
                                                        </button>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault2"/>
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault2"></label>
                                                        </div>
                                                    </h2>
                                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <div className="row">
                                                                <div className="col-12 d-md-flex justify-content-between">
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">View*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Edit*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Delete*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Archive*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Permission</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Assign</option>
                                                                                <option>Bulk Edit</option>
                                                                                <option>Create</option>
                                                                                <option>Import</option>
                                                                                <option>Export</option>
                                                                                <option>Notify</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Hidden Fields-Own Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Read Only Fields-Own Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Hidden Fields-Other Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Read Only Fields-Other Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header d-flex flex-center" id="headingThree">
                                                        <button className="accordion-button p-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                            Project
                                                        </button>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault3"/>
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault3"></label>
                                                        </div>
                                                    </h2>
                                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <div className="row">
                                                                <div className="col-12 d-md-flex justify-content-between">
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">View*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Edit*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Delete*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Archive*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Permission</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Assign</option>
                                                                                <option>Bulk Edit</option>
                                                                                <option>Create</option>
                                                                                <option>Import</option>
                                                                                <option>Export</option>
                                                                                <option>Notify</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Hidden Fields-Own Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Read Only Fields-Own Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Hidden Fields-Other Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Read Only Fields-Other Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header d-flex flex-center" id="headingFour">
                                                        <button className="accordion-button p-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                            Transactions
                                                        </button>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault4"/>
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault4"></label>
                                                        </div>
                                                    </h2>
                                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <div className="row">
                                                                <div className="col-12 d-md-flex justify-content-between">
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">View*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Edit*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Delete*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Archive*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Permission</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Assign</option>
                                                                                <option>Bulk Edit</option>
                                                                                <option>Create</option>
                                                                                <option>Import</option>
                                                                                <option>Export</option>
                                                                                <option>Notify</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Hidden Fields-Own Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Read Only Fields-Own Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Hidden Fields-Other Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Read Only Fields-Other Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header d-flex flex-center" id="headingFive">
                                                        <button className="accordion-button p-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                            Task
                                                        </button>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault5"/>
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault5"></label>
                                                        </div>
                                                    </h2>
                                                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <div className="row">
                                                                <div className="col-12 d-md-flex justify-content-between">
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">View*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Edit*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Delete*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Archive*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Permission</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Assign</option>
                                                                                <option>Bulk Edit</option>
                                                                                <option>Create</option>
                                                                                <option>Import</option>
                                                                                <option>Export</option>
                                                                                <option>Notify</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Hidden Fields-Own Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Read Only Fields-Own Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Hidden Fields-Other Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Read Only Fields-Other Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header d-flex flex-center" id="headingSix">
                                                        <button className="accordion-button p-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                            File
                                                        </button>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault6"/>
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault6"></label>
                                                        </div>
                                                    </h2>
                                                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <div className="row">
                                                                <div className="col-12 d-md-flex justify-content-between">
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">View*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Edit*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Delete*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Archive*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Permission</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Assign</option>
                                                                                <option>Bulk Edit</option>
                                                                                <option>Create</option>
                                                                                <option>Import</option>
                                                                                <option>Export</option>
                                                                                <option>Notify</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Hidden Fields-Own Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Read Only Fields-Own Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Hidden Fields-Other Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Read Only Fields-Other Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header d-flex flex-center" id="headingSeven">
                                                        <button className="accordion-button p-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                            Finance
                                                        </button>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault7"/>
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault7"></label>
                                                        </div>
                                                    </h2>
                                                    <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <div className="row">
                                                                <div className="col-12 d-md-flex justify-content-between">
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">View*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Edit*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Delete*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Archive*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Permission</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Assign</option>
                                                                                <option>Bulk Edit</option>
                                                                                <option>Create</option>
                                                                                <option>Import</option>
                                                                                <option>Export</option>
                                                                                <option>Notify</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Hidden Fields-Own Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Read Only Fields-Own Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Hidden Fields-Other Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Read Only Fields-Other Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header d-flex flex-center" id="headingEight">
                                                        <button className="accordion-button p-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                            Message
                                                        </button>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault8"/>
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault8"></label>
                                                        </div>
                                                    </h2>
                                                    <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <div className="row">
                                                                <div className="col-12 d-md-flex justify-content-between">
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">View*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Edit*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Delete*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Archive*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Permission</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Assign</option>
                                                                                <option>Bulk Edit</option>
                                                                                <option>Create</option>
                                                                                <option>Import</option>
                                                                                <option>Export</option>
                                                                                <option>Notify</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header d-flex flex-center" id="headingNine">
                                                        <button className="accordion-button p-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                                            Reports
                                                        </button>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault9"/>
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault9"></label>
                                                        </div>
                                                    </h2>
                                                    <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <div className="row">
                                                                <div className="col-12 d-md-flex justify-content-between">
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">View*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Edit*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Delete*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Archive*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Permission</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                            <option value=''>Select</option>
                                                                                <option>Assign</option>
                                                                                <option>Bulk Edit</option>
                                                                                <option>Create</option>
                                                                                <option>Import</option>
                                                                                <option>Export</option>
                                                                                <option>Notify</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export {RoleProfile}