import React,{FC} from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import DataTable from 'react-data-table-component'; 


const columns = [
    {
        name: 'ID',
        selector: (row: { id: any; }) => row.id,
        sortable: true,
    },
    {
        name: 'Name',
        selector: (row: { name: any; }) => row.name,
        sortable: true,
    },
    {
        name: 'Date',
        selector: (row: { date: any; }) => row.date,
        sortable: true,
    },
    {
        name: 'Time In',
        selector: (row: { timein: any; }) => row.timein,
        sortable: true,
    },
    {
        name: 'Time Out',
        selector: (row: { timeout: any; }) => row.timeout,
        sortable: true,
    },
    {
        name: 'Location',
        selector: (row: { location: any; }) => row.location,
        sortable: true,
    },
    {
        name: 'Remarks',
        selector: (row: { remarks: any; }) => row.remarks,
        sortable: true,
    },
];

const data = [
    {
        id: 9658,
        name: 'Raj',
        date:'20/05/2022',
        timein:'12:00 PM',
        timeout:'5.00 PM',
        location:'Koramangala',
        remarks:'Inpection at Brigade',
    },
    {
        id: 9688,
        name: 'Ram',
        date:'20/05/2022',
        timein:'12:00 PM',
        timeout:'5.00 PM',
        location:'Shivaji Nagar',
        remarks:'Inpection at Brigade',
    },
    {
        id: 968,
        name: 'Nirmal',
        date:'20/05/2022',
        timein:'12:00 PM',
        timeout:'5.00 PM',
        location:'Shivaji Nagar',
        remarks:'Inpection at Brigade',
    },
]


const TimeSheet: FC = () => {
    return(
        <section className="timesheet_area">
            <div className="row">
                <div className="col-12">
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                    />
                </div>
            </div>
        </section>
    )
}
export{TimeSheet}