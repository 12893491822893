/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import {useAuth} from '../../../app/modules/auth'
import 'react-funnel-pipeline/dist/index.css'
import { right } from '@popperjs/core';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ReactSpeedometer from 'react-d3-speedometer';
import {
  getCountAll,
} from './core/requests';

import { DashboardToolbar } from './DashboardToolbar'
import { getAssignTo } from '../property/core/_requests'
import { getTeamMembers, getTeams, getUsersByRole } from '../settings/userManagement/core/_requests'
import { ContactBar } from './contact/contactBar';
import { LeadBar } from './lead/leadBar';
import { TaskBar } from './task/taskBar';
import { TransactionBar } from './transaction/transactionBar';
import { ProjectBar } from './project/projectBar';
import { ContactFunnel } from './contact/contactFunnel';
import { LeadFunnel } from './lead/leadFunnel';
import { TaskFunnel } from './task/taskFunnel';
import { TransactionFunnel } from './transaction/transactionFunnel';
import { ContactDoughnut } from './contact/contactDoughnut';
import { ContactTaskList } from './contact/contactTaskList';
import { ContactSpeedometer } from './contact/contactSpeedometer';
import { LeadDoughnut } from './lead/leadDoughnut';
import { PropertyDoughnut } from './project/projectDoughnut';
import { TaskDoughnut } from './task/taskDoughnut';
import { PropertyFunnel } from './project/projectFunnel';
import { PropertyTaskList } from './project/projectTaskList';
import { TaskTaskList } from './task/taskTasklist';
import { LeadTaskList } from './lead/leadTaskList';
import { AttendanceBar } from './attendance_chart'
import { InTimeList } from './intime_list'
import { TransactionDoughnut } from './transaction/transactionDoughnut'
import { LeadSpeedometer } from './lead/leadSpeedometer'
import { PropertyAssignDoughnut } from './project/projectSpeedometer'
import { OverviewCountBar } from './overview_Admin/count_Overview'
import { OverviewCount2Bar } from './overview_Admin/count_overview2'
import { OverViewDoughnut } from './overview_Admin/count_piechart'
import { OverViewFunnel } from './overview_Admin/overall_funnel'
import { OverViewSpeedometer } from './overview_Admin/overall_speedomener'
import { OverallFunnel2 } from './overview_Admin/NewOverAllFunnel'
import { ContactOverviewDoughnut } from './overview_Admin/ContactOverviewPie'
import { TransactionDoughnutOverview } from './overview_Admin/TransactionOverviewPie'
import { LeadDoughnutOverview } from './overview_Admin/LeadOverviewPie'
import { ContactOverviewDoughnut2 } from './overview_Admin/ContactOverviewPie2'
import { LeadDoughnutOverview2 } from './overview_Admin/LeadOverviewPie2'
import { OverviewCount3Bar } from './overview_Admin/count_overview3'
import { TransactionDoughnutOverview2 } from './overview_Admin/TransactionOverviewPie2'

ChartJS.register(
  CategoryScale,
  ArcElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
    //   text: 'Chart.js Bar Chart',
    },
  },
};

const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July','Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

export const data = {
  labels,
  datasets: [{
    label: 'transaction',
    backgroundColor: '#3bb6f1',
    borderColor: 'rgb(255, 99, 132)',
    data: [0, 10, 5, 2, 20, 30, 45, 50, 55, 60],
  }]
};



export const data2 = {
  labels: ['Google Ads', 'MagicBricks', 'FaceBook', 'Instagram', 'Newspaper', 'Walk-in'],
  datasets: [
    {
      label: '# of Votes',
      data: [20,10,5,45,20],
      backgroundColor: [
          '#e27e0c',
          '#ff6700',
          '#208ae6',
          '#03a128',
          '#f37485',
          '#0c4688',
      ],
      borderColor: [
          '#fff',
          '#fff',
          '#fff',
          '#fff',
          '#fff',
          '#fff',
      ],
      borderWidth: 5,
    },
  ],
};

export const options2 = {
  plugins: {
      legend: {
          display: true,
          position: right,
          labels: {
              color: '#000',
              fontSize:'100',
          },
      }
  }
};


const DashboardWrapper: FC = () => {

  const intl = useIntl()
  var x = 0;

  const [countAll, setCountAll] = useState<{[key: string]: any}>({});
  const {currentUser, logout} = useAuth();

  const [CountSource, setCountSource] = useState<any[]>([]);
  const [CountType, setCountType] = useState<any[]>([]);
  const [CountCategory, setCountCategory] = useState<any[]>([]);
  const [CountStatus, setCountStatus] = useState<any[]>([]);
  const [CountGroup, setCountGroup] = useState<any[]>([]);
  const [CountCity, setCountCity] = useState<any[]>([]);
  const [CountLocality, setCountLocality] = useState<any[]>([]);
  const [CountState, setCountState] = useState<any[]>([]);
  const [CountGender, setCountGender] = useState<any[]>([]);
  const [CountNationality, setCountNationality] = useState<any[]>([]);
  const [userList, setUserList] = useState<any[]>([]);   

  
  const [dropToggle, setDropToggle] = useState('source');
  const [CBDC2, setCBDC2] = useState('');
  const [CBDC3, setCBDC3] = useState('');
  const [LBDC2, setLBDC2] = useState('');  


  const [teams, setTeams] = useState<any[]>([]); 
  const [teamMembers, setTeamMembers] = useState<any[]>([]); 
  const [users, setUsers] = useState<any[]>([]);

 
 console.log('currentUser', currentUser)

 const roleId = currentUser?.designation;

 function piechange(event: { target: { value: any } }) {
  console.log("value");
  console.log(event.target.value);
  x = event.target.value;
  setDropToggle(event.target.value);
}


const assignToList =  async () => {   
  const assignToResponse = await getAssignTo()
  setUserList(assignToResponse);
}

//ALL
  
  const CountAllModule =  async () => {
    var userId = currentUser?.id;
    var roleId = currentUser?.designation;
      const CountAllRes = await getCountAll(userId, roleId, userId)
      setCountAll(CountAllRes);
}

  const usersList =  async () => {
    var usersId = currentUser?.id;
    var roleId = currentUser?.designation;
    const Response = await getUsersByRole(usersId, roleId)
    setUsers(Response);
  }

useEffect(() => {
  usersList();

}, []);


useEffect(() => {
  CountAllModule();
  assignToList();
  teamsList();
  // teamMembersList();
}, []);


const graphXContact = CountSource.map((obj) => Object.values(obj)[0]);
const graphXContacttype = CountType.map((obj) => Object.values(obj)[0]);
const graphXContactcategory = CountCategory.map((obj) => Object.values(obj)[0]);
const graphXContactstatus = CountStatus.map((obj) => Object.values(obj)[0]);
const graphXContactgroup = CountGroup.map((obj) => Object.values(obj)[0]);
const graphXContactcity = CountCity.map((obj) => Object.values(obj)[0]);
const graphXContactlocality = CountLocality.map((obj) => Object.values(obj)[0]);
const graphXContactstate = CountState.map((obj) => Object.values(obj)[0]);
const graphXContactgender = CountGender.map((obj) => Object.values(obj)[0]);
const graphXContactnationality = CountNationality.map((obj) => Object.values(obj)[0]);
const graphYContact = CountSource.map((obj) => Object.values(obj)[1]);
const graphYContacttype = CountType.map((obj) => Object.values(obj)[1]);
const graphYContactcategory = CountCategory.map((obj) => Object.values(obj)[1]);
const graphYContactstatus = CountStatus.map((obj) => Object.values(obj)[1]);
const graphYContactgroup = CountGroup.map((obj) => Object.values(obj)[1]);
const graphYContactcity = CountCity.map((obj) => Object.values(obj)[1]);
const graphYContactlocality = CountLocality.map((obj) => Object.values(obj)[1]);
const graphYContactstate = CountState.map((obj) => Object.values(obj)[1]);
const graphYContactgender = CountGender.map((obj) => Object.values(obj)[1]);
const graphYContactnationality = CountNationality.map((obj) => Object.values(obj)[1]);


const lableContactSource = [];
for(var i=0;i<graphXContact.length;i++) {
  var val1 = graphXContact[i];
  var val2 = graphYContact[i];
  var value = val2+'-'+val1;
  lableContactSource.push(value);
}
const lableContactType= [];
for(var j=0;j<graphXContacttype.length;j++) {
  var val3 = graphXContacttype[j];
  var val4 = graphYContacttype[j];
  var value1 = val4+'-'+val3;
  lableContactType.push(value1);
}
const lableContactCategory = [];
for(var k=0;k<graphXContactcategory.length;k++) {
  var val5 = graphXContactcategory[k];
  var val6 = graphYContactcategory[k];
  var value2 = val6+'-'+val5;
  lableContactCategory.push(value2);
}
const lableContactStatus = [];
for(var l=0;l<graphXContactstatus.length;l++) {
  var val7 = graphXContactstatus[l];
  var val8 = graphYContactstatus[l];
  var value3 = val8+'-'+val7;
  lableContactStatus.push(value3);
}
const lableContactGroup = [];
for(var m=0;m<graphXContactgroup.length;m++) {
  var val9 = graphXContactgroup[m];
  var val10 = graphYContactgroup[m];
  var value4 = val10+'-'+val9;
  lableContactGroup.push(value4);
}

const lableContactCity = [];
for(var n=0;n<graphXContactcity.length;n++) {
  var val11 = graphXContactcity[n];
  var val12 = graphYContactcity[n];
  var value5 = val12+'-'+val11;
  lableContactCity.push(value5);
}
const lableContactLocality = [];
for(var o=0;o<graphXContactlocality.length;o++) {
  var val13 = graphXContactlocality[o];
  var val14 = graphYContactlocality[o];
  var value6 = val14+'-'+val13;
  lableContactLocality.push(value6);
}
const lableContactState = [];
for(var p=0;p<graphXContactstate.length;p++) {
  var val15 = graphXContactstate[p];
  var val16 = graphYContactstate[p];
  var value7 = val16+'-'+val15;
  lableContactState.push(value7);
}
const lableContactGender = [];
for(var q=0;q<graphXContactgender.length;q++) {
  var val17 = graphXContactgender[q];
  var val18 = graphYContactgender[q];
  var value8 = val18+'-'+val17;
  lableContactGender.push(value8);
}
const lableContactNationality = [];
for(var r=0;r<graphXContactnationality.length;r++) {
  var val19 = graphXContactnationality[r];
  var val20 = graphYContactnationality[r];
  var value9 = val20+'-'+val19;
  lableContactNationality.push(value9);
}

const teamsList =  async () => {
  const Response = await getTeams()
  setTeams(Response);
}
const goalMemberContact = async (id:any) => {
}
const goalMemberLead = async (id:any) => {
  
}

  return (
    <>
    <DashboardToolbar/>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <div className={roleId != 5 ? "dashboard_wrapper" : "d-none"}>
      <div aria-atomic="true" aria-live="assertive" className="toast bg_primary toast_position text-white position-fixed end-0 bottom-0 m-3" id="myToastAttendance">
          <div className="toast-header">
              <strong className="me-auto">Success</strong>
              <button aria-label="Close" className="btn-close btn-close-color-white" 
                          data-bs-dismiss="toast" type="button">
              </button> 
          </div>
          <div className="toast-body">
              <div>Checked-in Successfully!</div>
          </div>
      </div>
    {/* Stats card */}
      <div className="">
        <ul className="nav nav-pills mb-3 d-flex justify-content-center row" id="pills-tab" role="tablist">
        {roleId == 1 &&
          <li className="nav-item col m-0" role="presentation">
            <button className={roleId == 1 ? "nav-link active p-1 br_10 w-100 h-100 m-0" : "nav-link p-1 br_10 w-100 h-100 m-0"} id="overview-tab" data-bs-toggle="pill" data-bs-target="#overview" type="button" role="tab" aria-controls="overview" aria-selected="true">
              <div className="card h-100 w-100">
                <div className="counter_card h-100 w-100 br_10 bs_1">
                  <div className="d-flex align-items-center justify-content-center py-2">
                    <div className="d-flex align-items-center p-2">
                      <div className="flex-shrink-0 bg-dark rounded_circle me-2 me-xxl-4">
                        <img src={toAbsoluteUrl('/media/custom/menu-icons/dashboard.svg')} alt="" />
                      </div>
                      <div className="flex-grow-1 ms-xl-1 ms-xxl-3">
                        <h3 className="mb-0">Dashboard</h3>
                        {/* <p className="mb-0 text-nowrap">Total {'#'+countAll.tasks}
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </li>}
          <li className="nav-item col m-0" role="presentation">
            <button className={roleId == 1 ? "nav-link p-1 br_10 w-100 h-100 me-0" : "nav-link active p-1 br_10 w-100 h-100 me-0"} id="contact-tab" data-bs-toggle="pill" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="true">
              <div className="card h-100 w-100">
                <div className="counter_card h-100 w-100 br_10 bs_1">
                  <div className="d-flex align-items-center justify-content-center py-2">
                    <div className="d-flex align-items-center p-2">
                      <div className="flex-shrink-0 bg-dark rounded_circle me-2 me-xxl-4">
                        <img src={toAbsoluteUrl('/media/custom/menu-icons/contact.svg')} alt="" />
                      </div>
                      <div className="flex-grow-1 ms-xl-1 ms-xxl-3">
                        <h3 className="mb-0">Contact</h3>
                        <p className="mb-0 text-nowrap">Total {'#'+countAll.contacts}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </li>
          <li className="nav-item col m-0" role="presentation">
            <button className="nav-link p-1 br_10 w-100 h-100 m-0" id="lead-tab" data-bs-toggle="pill" data-bs-target="#lead" type="button" role="tab" aria-controls="lead" aria-selected="true">
              <div className="card h-100 w-100">
                <div className="counter_card h-100 w-100 br_10 bs_1">
                  <div className="d-flex align-items-center justify-content-center py-2">
                    <div className="d-flex align-items-center p-2">
                      <div className="flex-shrink-0 bg-dark rounded_circle me-2 me-xxl-4">
                        <img src={toAbsoluteUrl('/media/custom/menu-icons/lead.svg')} alt="" />
                      </div>
                      <div className="flex-grow-1 ms-xl-1 ms-xxl-3">
                        <h3 className="mb-0">Lead</h3>
                        <p className="mb-0 text-nowrap">Total {'#'+countAll.leads}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </li>
          <li className="nav-item col m-0" role="presentation">
            <button className="nav-link p-1 br_10 w-100 h-100 m-0" id="property-tab" data-bs-toggle="pill" data-bs-target="#property" type="button" role="tab" aria-controls="property" aria-selected="true">
              <div className="card h-100 w-100">
                <div className="counter_card h-100 w-100 br_10 bs_1">
                  <div className="d-flex align-items-center justify-content-center py-2">
                    <div className="d-flex align-items-center p-2">
                      <div className="flex-shrink-0 bg-dark rounded_circle me-2 me-xxl-4">
                        <img src={toAbsoluteUrl('/media/custom/menu-icons/project.svg')} alt="" />
                      </div>
                      <div className="flex-grow-1 ms-xl-1 ms-xxl-3">
                        <h3 className="mb-0">Project</h3>
                        <p className="mb-0 text-nowrap">Total {'#'+countAll.properties}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </li>
          <li className="nav-item col m-0" role="presentation">
            <button className="nav-link p-1 br_10 w-100 h-100 m-0" id="transaction-tab" data-bs-toggle="pill" data-bs-target="#transaction" type="button" role="tab" aria-controls="transaction" aria-selected="true">
              <div className="card h-100 w-100">
                <div className="counter_card h-100 w-100 br_10 bs_1">
                  <div className="d-flex align-items-center justify-content-center py-2">
                    <div className="d-flex align-items-center p-2">
                      <div className="flex-shrink-0 bg-dark rounded_circle me-2 me-xxl-4">
                        <img src={toAbsoluteUrl('/media/custom/menu-icons/transaction.svg')} alt="" />
                      </div>
                      <div className="flex-grow-1 ms-xl-1 ms-xxl-3">
                        <h3 className="mb-0">Transaction</h3>
                        <p className="mb-0 text-nowrap">Total {'#'+countAll.transactions}</p>
                        {/* <p className="mb-0">Total {'#'+countAll[0].transactions ?? '#0'}</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </li>
          {roleId != 1 &&
          <li className="nav-item col m-0" role="presentation">
            <button className="nav-link p-1 br_10 w-100 h-100 m-0" id="task-tab" data-bs-toggle="pill" data-bs-target="#task" type="button" role="tab" aria-controls="task" aria-selected="true">
              <div className="card h-100 w-100">
                <div className="counter_card h-100 w-100 br_10 bs_1">
                  <div className="d-flex align-items-center justify-content-center py-2">
                    <div className="d-flex align-items-center p-2">
                      <div className="flex-shrink-0 bg-dark rounded_circle me-2 me-xxl-4">
                        <img src={toAbsoluteUrl('/media/custom/menu-icons/task.svg')} alt="" />
                      </div>
                      <div className="flex-grow-1 ms-xl-1 ms-xxl-3">
                        <h3 className="mb-0">Task</h3>
                        <p className="mb-0 text-nowrap">Total {'#'+countAll.tasks}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </li>}          
          
          </ul>        
      </div>
    {/* Stats card */}

    {/* contact */}
    <div className="tab-content" id="pills-tabContent">
    <div className={roleId == 1 ? "tab-pane fade" : "tab-pane fade show active"} id="contact" role="tabpanel" aria-labelledby="contact-tab">
    <div className="row">
      <div className="col-xl-9 col-xxl-8">
        <div className="row">
              <div className="card-group p-0">
                  <div className="col-md-6 col-12 mb-4">
                    <ContactBar/>
                  </div>
                  <div className="col-md-6 col-12 mb-4">
                    <ContactSpeedometer/>
                  </div>
              </div>
              <div className="card-group p-0">
                  <div className="col-md-6 col-12 mb-4 pie_chart">
                    <ContactDoughnut/>
                  </div>
                  <div className="col-md-6 col-12 mb-4 task_list">
                      <ContactTaskList/>
                  </div>
              </div> 
        </div>
      </div>
      <div className="col-xxl-4 col-xl-3 funnel_chart mb-4">        
        <ContactFunnel/>
      </div>
    </div>         
    </div>

         
   {/* Lead*/}      
    
    <div className="tab-pane fade" id="lead" role="tabpanel" aria-labelledby="lead-tab">
    <div className="row">
      <div className="col-xl-9 col-xxl-8">
        <div className="row">
              <div className="card-group p-0">
                  <div className="col-md-6 col-12 mb-4">
                    <LeadBar/>
                  </div>
                  <div className="col-md-6 col-12 mb-4">
                    <LeadSpeedometer/>
                  </div>
              </div>
              <div className="card-group p-0">
                  <div className="col-md-6 col-12 mb-4 pie_chart">
                    <LeadDoughnut/>
                  </div>
                  <div className="col-md-6 col-12 mb-4 task_list">
                      <LeadTaskList/>
                  </div>
              </div> 
        </div>
      </div>
      <div className="col-xxl-4 col-xl-3 funnel_chart mb-4">
        <LeadFunnel/>
      </div>
       </div>    
    </div>
    


    {/* Property*/}              
     
     <div className="tab-pane fade" id="property" role="tabpanel" aria-labelledby="property-tab">
     <div className="row">
      <div className="col-xl-9 col-xxl-8">
        <div className="row">
              <div className="card-group p-0">
                  <div className="col-md-6 col-12 mb-4">
                    <ProjectBar/>
                  </div>
                  <div className="col-md-6 col-12 mb-4 pie_chart">
                    <PropertyAssignDoughnut/>
                  </div>
              </div>
              <div className="card-group p-0">
                  <div className="col-md-6 col-12 mb-4 pie_chart">
                    <PropertyDoughnut/>
                  </div>
                  <div className="col-md-6 col-12 mb-4 task_list">
                    <PropertyTaskList/>
                  </div>
              </div> 
        </div>
      </div>
      <div className="col-xxl-4 col-xl-3 funnel_chart mb-4">
        <PropertyFunnel/>
      </div>
       </div>    
     </div>

   {/*task*/}           
    {roleId != 1 &&
    <div className="tab-pane fade" id="task" role="tabpanel" aria-labelledby="task-tab">
    <div className="row">
      <div className="col-xl-9 col-xxl-8">
        <div className="row">
              <div className="card-group p-0">
                  <div className="col-md-6 col-12 mb-4">
                    <TaskBar/>
                  </div>
                  <div className="col-md-6 col-12 mb-4">
                    <div className="card  mx-sm-1 mx-xl-2 card-xl-stretch h-100 mb-xl-8 mb-3 bs_1 br_15">
                      <div className="card-heade border-0 d-md-flex align-items-center justify-content-between pt-5 px-5">
                          <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bolder fs-3 mb-1'>Goals</span>
                          </h3>
                          <div className="d-flex justify-content-end align-items-center flex-wrap">
                          <select className="form-select dash_btn m-2" onChange={(e) => setCBDC2(e.target.value)}>
                              <option selected>Today</option>
                              <option value="1">Yesterday</option>
                              <option value="2">Last Week</option>
                              <option value="3">Quaterly</option>
                              <option value="4">Monthly</option>
                              <option value="5">Yearly</option>
                              <option value="6">Custom Date</option>
                          </select>
                          {CBDC2 == '6' &&
                          <>
                          <input className="form-select dash_btn m-2" type="date"/>
                          <input className="form-select dash_btn m-2" type="date"/>
                          </>}
                              {roleId == 1 &&
                              <select className="form-select dash_btn me-2 mb-1">
                                <option value='' selected>Team</option>
                                {teams.map((assignVal, i) => (
                                <option selected={i == 0 ? true: false} value={assignVal.id}>{assignVal.team_name}</option>
                                ))}
                              </select>}
                              <select className="form-select dash_btn me-2 mb-1" onChange={(e) => goalMemberContact(e.target.value)}>
                                <option value='' selected>Members</option>
                                {teamMembers.map((memb, i) => (
                                <option selected={i == 0 ? true: false} value={memb.emp_id}>{memb.employee_name}</option>
                                ))}
                              </select>
                          </div>
                      </div>
                      <div className="card-body pt-0 px-2 d-flex justify-content-center align-items-center">
                      <div className="row w-100">
                        <div className="col-lg-8 col-xxl-8 col-12">
                            <ReactSpeedometer
                                needleHeightRatio={0.7}
                                maxValue={100}
                                value={1}
                                needleTransitionDuration={5000}
                                needleColor="#000"
                                startColor="#faa267"
                                segments={5}
                                endColor="#e55f04"
                                textColor="grey"
                            />
                        </div>
                        <div className="col-lg-4 col-xxl-4 d-flex flex-lg-column align-items-end">
                            <div className="border border-gray-300 border-dashed rounded meter_value">
                                <div className="fw-bold text-gray-800">Goal</div>
                                <div className="fs-7 text-gray-800 fw-bolder">#</div>
                            </div>
                            <div className="border border-gray-300 border-dashed rounded meter_value">
                                <div className="fw-bold text-gray-800">Achieved</div>
                                <div className="fs-7 text-gray-800 fw-bolder">#</div>
                            </div>
                        </div>
                    </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div className="card-group p-0">
                  <div className="col-md-6 col-12 mb-4 pie_chart">
                    <TaskDoughnut/>
                  </div>
                  <div className="col-md-6 col-12 mb-4 task_list">
                    <TaskTaskList/>
                  </div>
              </div> 
        </div>
      </div>
      <div className="col-xxl-4 col-xl-3 funnel_chart mb-4">
        <TaskFunnel/>
      </div>
       </div>     
    </div>}
    

   {/*Transaction*/}            
    
    <div className="tab-pane fade" id="transaction" role="tabpanel" aria-labelledby="transaction-tab">
    <div className="row">
      <div className="col-xl-9 col-xxl-8">
        <div className="row">
              <div className="card-group p-0">
                  <div className="col-md-6 col-12 mb-4">
                    <TransactionBar/>
                  </div>
                  <div className="col-md-6 col-12 mb-4">
                    <div className="card  mx-sm-1 mx-xl-2 card-xl-stretch h-100 mb-xl-8 mb-3 bs_1 br_15">
                      <div className="card-heade border-0 d-md-flex align-items-center justify-content-between pt-5 px-5">
                          <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bolder fs-3 mb-1'>Goals</span>
                          </h3>
                          <div className="d-flex justify-content-end align-items-center flex-wrap">
                          <select className="form-select dash_btn m-2" onChange={(e) => setCBDC2(e.target.value)}>
                              <option selected>Today</option>
                              <option value="1">Yesterday</option>
                              <option value="2">Last Week</option>
                              <option value="3">Quaterly</option>
                              <option value="4">Monthly</option>
                              <option value="5">Yearly</option>
                              <option value="6">Custom Date</option>
                          </select>
                          {CBDC2 == '6' &&
                          <>
                          <input className="form-select dash_btn m-2" type="date"/>
                          <input className="form-select dash_btn m-2" type="date"/>
                          </>}
                              {roleId == 1 &&
                              <select className="form-select dash_btn me-2 mb-1">
                                <option value='' selected>Team</option>
                                {teams.map((assignVal, i) => (
                                <option selected={i == 0 ? true: false} value={assignVal.id}>{assignVal.team_name}</option>
                                ))}
                              </select>}
                              <select className="form-select dash_btn me-2 mb-1" onChange={(e) => goalMemberContact(e.target.value)}>
                                <option value='' selected>Members</option>
                                {teamMembers.map((memb, i) => (
                                <option selected={i == 0 ? true: false} value={memb.emp_id}>{memb.employee_name}</option>
                                ))}
                              </select>
                          </div>
                      </div>
                      <div className="card-body pt-0 px-2 d-flex justify-content-center align-items-center">
                      <div className="row w-100">
                        <div className="col-lg-8 col-xxl-8 col-12">
                            <ReactSpeedometer
                                needleHeightRatio={0.7}
                                maxValue={100}
                                value={1}
                                needleTransitionDuration={5000}
                                needleColor="#000"
                                startColor="#faa267"
                                segments={5}
                                endColor="#e55f04"
                                textColor="grey"
                            />
                        </div>
                        <div className="col-lg-4 col-xxl-4 d-flex flex-lg-column align-items-end">
                            <div className="border border-gray-300 border-dashed rounded meter_value">
                                <div className="fw-bold text-gray-800">Goal</div>
                                <div className="fs-7 text-gray-800 fw-bolder">#</div>
                            </div>
                            <div className="border border-gray-300 border-dashed rounded meter_value">
                                <div className="fw-bold text-gray-800">Achieved</div>
                                <div className="fs-7 text-gray-800 fw-bolder">#</div>
                            </div>
                        </div>
                    </div>
                      </div>
                    </div>
                    
                  </div>
              </div>
              <div className="card-group p-0">
                  <div className="col-md-6 col-12 mb-4 pie_chart">
                    {/* <div className="card mx-sm-1 mx-xl-2 bs_1 br_15">
                        <div className="card-heade border-0 d-flex align-items-center justify-content-between pt-5 px-5">
                          <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bolder fs-3 mb-1'><span className={dropToggle === 'source' ? 'd-block source_graph': 'd-none'}>Source</span></span>
                            <span className='card-label fw-bolder fs-3 mb-1'><span className={dropToggle === 'type' ? 'd-block source_graph': 'd-none'}>Type</span></span>
                            <span className='card-label fw-bolder fs-3 mb-1'><span className={dropToggle === 'category' ? 'd-block source_graph': 'd-none'}>Category</span></span>
                            <span className='card-label fw-bolder fs-3 mb-1'><span className={dropToggle === 'status' ? 'd-block source_graph': 'd-none'}>Status</span></span>
                            <span className='card-label fw-bolder fs-3 mb-1'><span className={dropToggle === 'group' ? 'd-block source_graph': 'd-none'}>Group</span></span>
                            <span className='card-label fw-bolder fs-3 mb-1'><span className={dropToggle === 'city' ? 'd-block source_graph': 'd-none'}>City</span></span>
                            <span className='card-label fw-bolder fs-3 mb-1'><span className={dropToggle === 'locality' ? 'd-block source_graph': 'd-none'}>Locality</span></span>
                            <span className='card-label fw-bolder fs-3 mb-1'><span className={dropToggle === 'state' ? 'd-block source_graph': 'd-none'}>State</span></span>
                            <span className='card-label fw-bolder fs-3 mb-1'><span className={dropToggle === 'gender' ? 'd-block source_graph': 'd-none'}>Gender</span></span>
                            <span className='card-label fw-bolder fs-3 mb-1'><span className={dropToggle === 'nationality' ? 'd-block source_graph': 'd-none'}>Nationality</span></span>
                          </h3>
                          <div className='d-flex justify-content-end align-items-center flex-wrap'>
                          <select className="form-select dash_btn m-2" onChange={(e) => setCBDC3(e.target.value)}>
                              <option selected>Today</option>
                              <option value="1">Yesterday</option>
                              <option value="2">Last Week</option>
                              <option value="3">Quaterly</option>
                              <option value="4">Monthly</option>
                              <option value="5">Yearly</option>
                              <option value="6">Custom Date</option>
                          </select>
                          {CBDC3 == '6' &&
                          <>
                          <input className="form-select dash_btn m-2" type="date"/>
                          <input className="form-select dash_btn m-2" type="date"/>
                          </>}
                          <select className="form-select dash_btn m-2" onChange={piechange}>
                            <option selected value="source">Source</option>
                            <option value="type">Type</option>
                            <option value="category">Category</option>
                            <option value="status">Status</option>
                            <option value="group">Group</option>
                            <option value="city">City</option>
                            <option value="locality">Locality</option>
                            <option value="state">State</option>
                            <option value="gender">Gender</option>
                            <option value="nationality">Nationality</option>
                          </select>
                          </div>
                          
                        </div>
                      <div className="card-body pt-0">
                      <>
                        <Doughnut className={dropToggle === 'source' ? 'd-block source_graph': 'd-none'} options={options2} data={dataContact1} />
                        <Doughnut className={dropToggle === 'type' ? 'd-block type_graph': 'd-none'} options={options2} data={dataContact2} />
                        <Doughnut className={dropToggle === 'category' ? 'd-block category_graph': 'd-none'} options={options2} data={dataContact3} />
                        <Doughnut className={dropToggle === 'status' ? 'd-block status_graph': 'd-none'} options={options2} data={dataContact4} />
                        <Doughnut className={dropToggle === 'group' ? 'd-block group_graph': 'd-none'} options={options2} data={dataContact5} />
                        <Doughnut className={dropToggle === 'city' ? 'd-block city_graph': 'd-none'} options={options2} data={dataContact6} />
                        <Doughnut className={dropToggle === 'locality' ? 'd-block locality_graph': 'd-none'} options={options2} data={dataContact7} />
                        <Doughnut className={dropToggle === 'state' ? 'd-block state_graph': 'd-none'} options={options2} data={dataContact8} />
                        <Doughnut className={dropToggle === 'gender' ? 'd-block gender_graph': 'd-none'} options={options2} data={dataContact9} />
                        <Doughnut className={dropToggle === 'nationality' ? 'd-block nationality_graph': 'd-none'} options={options2} data={dataContact10} />
                      </>
                      </div>
                    </div> */}
                     <TransactionDoughnut/>
                  </div>                 
                  <div className="col-md-6 col-12 mb-4 task_list">
                  <div className='card mx-sm-1 mx-xl-2 bs_1 br_15'>
                        <div className='card-heade border-0 pt-5 d-flex justify-content-between px-5'>
                          <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bolder text-dark'>Tasks Overview</span>
                          </h3>
                          <div className="d-flex align-items-center justify-content-between">
                          </div>
                        </div>
                        <div className='card-body pt-5'>
                        {CountStatus.map((TaskData, i) => {
                                return(
                          <div className='d-flex align-items-center mb-7 bg-light br_10 p-2' key={i}>
                            <div className='symbol symbol-35px me-5'>
                              <span className={TaskData.name === 'Completed' ? 'symbol-label bg_completed' : TaskData.name === 'in process' ? 'symbol-label bg_inprocess' : TaskData.name === 'Cancel' ? 'symbol-label bg_cancelled' : 'symbol-label bg_pending'}>
                              </span>
                            </div>
                            <div className='d-flex justify-content-between w-100'>
                              <a  className='text-dark text-hover-primary fs-6 fw-bolder'>
                              {TaskData.name}
                              </a>
                              <p className="mb-0">{TaskData.value}</p>
                            </div>
                          </div>
                        )})}
                          
                        </div>
                      </div>
                  </div>
              </div> 
        </div>
      </div>
      <div className="col-xxl-4 col-xl-3 funnel_chart mb-4">
        <TransactionFunnel/>
      </div>
       </div>   
    </div>

    {/* Admin Overview */}
    {roleId == 1 &&                              
    <div className={roleId == 1 ? "tab-pane fade show active" : "tab-pane fade"} id="overview" role="tabpanel" aria-labelledby="overview-tab">
      <div className="row">
        <div className="card-group p-0">
            <div className="col-xl-4 col-md-12 col-12 mb-4">
              <OverviewCountBar/>
            </div>                  
            <div className="col-xl-4 col-md-6 col-12 mb-4 pie_chart">
              <ContactOverviewDoughnut2/>
            </div>                  
            <div className="col-xl-4 col-md-6 col-12 mb-4 pie_chart">
              <ContactOverviewDoughnut/>
            </div>
        </div>
        <div className="card-group p-0">
            <div className="col-xl-4 col-md-6 col-12 mb-4">
              <OverviewCount2Bar/>
            </div>                  
            <div className="col-xl-4 col-md-6 col-12 mb-4 pie_chart">
              <LeadDoughnutOverview2/>
            </div>                  
            <div className="col-xl-4 col-md-6 col-12 mb-4 pie_chart">
              <LeadDoughnutOverview/>
            </div>
        </div>
        <div className="card-group p-0">
            <div className="col-xl-4 col-md-6 col-12 mb-4">
              <OverviewCount3Bar/>
            </div>                  
            <div className="col-xl-4 col-md-6 col-12 mb-4 pie_chart">
              <TransactionDoughnutOverview2/>
            </div>                  
            <div className="col-xl-4 col-md-6 col-12 mb-4 pie_chart">
              <TransactionDoughnutOverview/>
            </div>
        </div>
        <div className="card-group p-0">
            <div className="col-xl-4 col-md-6 col-12 mb-4">
              <OverviewCount2Bar/>
            </div>                  
            <div className="col-xl-4 col-md-6 col-12 mb-4 funnel_chart">
              <OverallFunnel2/>
            </div>                  
            <div className="col-xl-4 col-md-6 col-12 mb-4">
              <OverViewSpeedometer/>
            </div>                  
        </div>
      </div>     
    </div>}

   </div>             
  </div>                  
    <div className={roleId == 5 ? "row" : "d-none"}>
      <div className='col-12 col-md-8'>
        {/* <ChartsWidget1 className={''}/> */}
        <AttendanceBar/>
      </div>
      <div className='col-12 col-md-4 p-md-0 mt-5 mt-md-0'>
        <InTimeList/>
      </div>      
    </div>
    </>
  )
}

export {DashboardWrapper}
