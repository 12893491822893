/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {FC, useEffect, useState} from 'react'
import { Toast, Offcanvas } from 'bootstrap';
import { useLocation } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import { useLayout } from '../../../_metronic/layout/core'
import { deleteMultipleContacts, getContactByRole } from './core/_requests';
import {useAuth} from '../../../app/modules/auth';

type Props = {
    sortByOnChange?: any,
    layoutOnChange?: any,
    selectedContacts?: any,
    setContactList?: any
}

const ContactToolbar: FC<Props> = (props) => {

  const {
      sortByOnChange, layoutOnChange, selectedContacts, setContactList
    } = props

  const {classes} = useLayout()
  const {currentUser, logout} = useAuth();
  const [contacts, setContacts] = useState<any[]>([]);
  const [actionValue, setActionValue] = useState<any>('');
  let location = useLocation();
  console.log('sortByOnChange')
  console.log(sortByOnChange.value)

  var roleId = currentUser?.designation;

  const contactList = async () => {
    var userId = currentUser?.id;
    var roleId = currentUser?.designation;
    const characterResponse = await getContactByRole(userId, roleId);
    setContacts(characterResponse);
  }

  const actionChange = async (e:any) => {
    console.log(e.target.value);
    setActionValue(e.target.value);
    console.log('selectedContacts');
    var userId = currentUser?.id;
    var roleId = currentUser?.designation;
    if(e.target.value == "import") {
      document.getElementById('kt_contact_import_toggle')?.click();
      setActionValue("default");
    }
    let contactVal = selectedContacts.join(',')
    console.log(contactVal);
    let val = e.target.value;
    if(val == 'delete'){
      console.log('deletedddd');
      if(contactVal != ''){
        const deleteRes = deleteMultipleContacts(contactVal);
        if(deleteRes != null) {
          var toastEl = document.getElementById('myToastUpdate');
          const bsToast = new Toast(toastEl!);
          bsToast.show();
          const characterResponse = await getContactByRole(userId, roleId);
          setContactList(characterResponse);
        }
      }
    }
  }

  // const sortByOnChange = (e:any) => {

  // }
  useEffect(() => {
    contactList();
}, []);

  const isMenu = location.pathname.includes('menu');

  return (
    <div className='toolbar d-flex align-items-end' id='kt_toolbar'>
      {/* begin::Container */}
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
      >
        {/* <DefaultTitle /> */}

        <div  className="menu_bar d-flex align-items-center justify-content-end w-100">
          {isMenu &&
              <>
              <div className=''>
                <div className='d-flex button_bar'>
                {/* contact actions */}
                <input disabled value={"total"+' '+'>'+' '+contacts.length} className="me-3 btn btn-sm d-none d-md-block"/>
                <button className="me-3 btn btn-sm d-block" id='kt_contact_toggle'>Add+</button>
                <a className="me-4 btn btn-sm d-none" id='kt_lead_toggle'>Add+</a>
                <a className="me-4 btn btn-sm d-none" id='kt_task_toggle'>Add+</a>
                <button className="me-3 btn btn-sm d-none" id='kt_contact_import_toggle'>Import+</button>
                
                <select className="form-control me-3 btn btn-sm" name="sort" id="sort" onChange={(e) => {sortByOnChange(e.target.value)}}>
                  <option value="">Sort By</option>
                  <option value="created_at|asc">Created Ascending</option>
                  <option value="created_at|desc">Created Descending</option>
                  <option value="updated_at|asc">Updated Ascending</option>
                  <option value="updated_at|desc">Updated Descending</option>
                  <option value="first_name|asc">FirstName A - Z</option>
                  <option value="first_name|desc">FirstName Z - A</option>
                </select>
                <select className="form-control me-3 btn btn-sm" name="views" id="views" onChange={layoutOnChange}>
                    <option value="grid">Grid View</option>
                    <option value="list" onClick={() => contactList()}>List View</option>
                    {/* <option value="pipe">PieLine View</option> */}
                </select>
                <select className="form-control me-3 btn btn-sm d-none" name="type" id="type">
                  <option value="default">Type</option>
                  <option value="date">Residential</option>
                  <option value="dob">Commercial</option>
                  <option value="Plot">Plot</option>
                  <option value="developer">Developer</option>
                </select>
                <select className="form-control me-3 btn btn-sm" value={actionValue} name="action" onChange={(e) => actionChange(e)} id="action">
                  <option value="default">Action</option>
                  {/* <option value="add">Add</option>
                  <option value="edit">Edit</option>
                  <option value="bulk">Bulk Edit</option> */}
                  {roleId == 1 && (<option value="delete">Delete</option>)}
                  <option value="archive">Archive</option>
                  {/* <option value="import">Import</option> */}
                  <option value="export">Export</option>
                  <option value="import">Import</option>
                  {/* <option value="selectall">Select All</option> */}
                </select>
                
                </div>
              </div>
              <div>
              </div>  
              <div className="d-flex">
                  <a className="me-3 btn btn-sm" id='kt_filter_toggle'>
                    <KTSVG path='/media/custom/header-icons/filter.svg' className='svg-icon-5 svg-icon-gray-500 me-1'/>
                  </a>
              </div>
              </>
          }
        </div>
      </div>
      {/* end::Container */}
    </div>
  )
}



export {ContactToolbar}