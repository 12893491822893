import React,{FC, useState} from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

import DataTable from 'react-data-table-component'; 
import { Sms } from './sms';
import { SocialMedia } from './socialMedia'
import { Settings } from './mailSettings';


const Integrations: FC = () => {
    // const dataee = localStorage.getItem('data');
    // console.log('dataee');
    // console.log(dataee);
    const [toggle, setToggle] = useState(false);

    const handleHideData = () => {
        setToggle(!toggle);
    };

    return(
        <><div className="d-flex flex-column flex-center mb-9">
            <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
            <h2>Under Construction</h2>
        </div>
        <div className="user_manage_page bg_white h-100 p-4 d-none">
                <ul className="nav nav-pills mb-5" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="pills-org-tab" data-bs-toggle="pill" data-bs-target="#pills-org" type="button" role="tab" aria-controls="pills-org" aria-selected="true">SMS</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="user-charts-tab" data-bs-toggle="pill" data-bs-target="#user-charts" type="button" role="tab" aria-controls="user-charts" aria-selected="false">Social Media</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="manage-teams-tab" data-bs-toggle="pill" data-bs-target="#manage-teams" type="button" role="tab" aria-controls="manage-teams" aria-selected="false">Mail Settings</button>
                    </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-org" role="tabpanel" aria-labelledby="pills-org-tab">
                        <Sms />
                    </div>
                    <div className="tab-pane fade" id="user-charts" role="tabpanel" aria-labelledby="user-charts-tab">
                        <SocialMedia />
                    </div>
                    <div className="tab-pane fade" id="manage-teams" role="tabpanel" aria-labelledby="manage-teams-tab">
                        <Settings />
                    </div>
                </div>
            </div></>
    )
}
export {Integrations}