import axios, {AxiosResponse}  from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL   

// Source
export const GET_SOURCE_URL = `${API_URL}/get_source`
export const GET_TEAMS_URL = `${API_URL}/get_teams`
export const GET_TEAM_BY_ID_URL = `${API_URL}/get_team`



// Source fetch api
export function getSource() {
    return axios.get(GET_SOURCE_URL)
    .then((response => response.data))
}

export function getTeams() {
    return axios.get(GET_TEAMS_URL)
    .then((response => response.data))
}    

export function getTeamById(obj:any) {
    return axios.get(GET_TEAM_BY_ID_URL+'/'+obj)
    .then((response => response.data))
} 