import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'

import { TaskPage } from '../pages/task/task'
import { PropertyList } from '../pages/property/property'
// import { ContactPage } from '../pages/contact/contact'
import {LeadPage} from '../pages/lead/lead'
import { Settings } from '../pages/settings/settings'
import { TransactionList } from '../pages/transaction/transactionList'
import { File } from '../pages/file/file'
import { Finance } from '../pages/finance/finance'
import { Support } from '../pages/support/support'
import { Reports } from '../pages/reports/reports'
import Message from 'react-intl/src/components/message'
import { MessagePage } from '../pages/message/message'
import { OrgSettings } from '../pages/settings/organization/orgSettings'
import { UserManagement } from '../pages/settings/userManagement/userManagement'
import { Masters } from '../pages/settings/masters/masters'
import { AlertsAndNotificationSettings } from '../pages/settings/alertsAndNotificationSettings/alertsAndNotificationSettings'
import { Integrations } from '../pages/settings/Integrations/integrations'
import { ThemeBuilder } from '../pages/settings/ThemeBuilder/themeBuilder'
import { Templates } from '../pages/settings/templates/Templates'
import { PersonalSettings } from '../pages/settings/MyProfile/personalSettings'
import { LeadSettings } from '../pages/settings/LeadSettings/leadSettings'
import { FormBuilder } from '../pages/settings/FormBuilder/formBuilder'
import { ContactSettings } from '../pages/settings/ContactSettings/contactSettings'
import { ContactPageClone } from '../pages/contact/contactClone'


const PrivateRoutes = () => {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />

        <Route
          path='menu/lead/*'
          element={
            <SuspensedView>
              <LeadPage/>
              {/* <WidgetsPage /> */}
            </SuspensedView>
          }
        />  

        <Route
          path='menu/contact/*'
          element={
            <SuspensedView>
              {/* <WidgetsPage /> */}
              <ContactPageClone/>
            </SuspensedView>
          }
        />
        <Route
          path='menu/task/'
          element={
            <SuspensedView>
              <TaskPage/>
              {/* <WidgetsPage /> */}
            </SuspensedView>
          }
        />
        <Route
          path='menu/property/*'
          element={
            <SuspensedView>
              <PropertyList/>
              {/* <WidgetsPage/> */}
            </SuspensedView>
          }
        />                            
        <Route
          path='menu/settings/*'
          element={
            <SuspensedView>
              {/* <WidgetsPage/> */}
              <Settings/>
            </SuspensedView>
          }
        />                            
        <Route
          path='menu/transaction/*'
          element={
            <SuspensedView>
              <TransactionList/>
              {/* <WidgetsPage/> */}
            </SuspensedView>
          }
        />                            
        <Route
          path='menu/file/*'
          element={
            <SuspensedView>
              <File/>
              {/* <WidgetsPage/> */}
            </SuspensedView>
          }
        />                            
        <Route
          path='menu/finance/*'
          element={
            <SuspensedView>
              <Finance/>
              {/* <WidgetsPage/> */}
            </SuspensedView>
          }
        />                            
        <Route
          path='menu/support/*'
          element={
            <SuspensedView>
              <Support/>
              {/* <WidgetsPage/> */}
            </SuspensedView>
          }
        />                            
        <Route
          path='reports/*'
          element={
            <SuspensedView>
              <Reports/>
              {/* <WidgetsPage/> */}
            </SuspensedView>
          }
        />                            
        <Route
          path='menu/message/*'
          element={
            <SuspensedView>
              <MessagePage/>
              {/* <WidgetsPage/> */}
            </SuspensedView>
          }
        />                            
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />

    {/* Settings Routes start*/}
        <Route
          path='menu/settings/organization-settings'
          element={
            <SuspensedView>
              <OrgSettings/>
            </SuspensedView>
          }
        />
        <Route
          path='menu/settings/user-settings'
          element={
            <SuspensedView>
              <UserManagement/>
            </SuspensedView>
          }
        />
        <Route
          path='menu/settings/masters'
          element={
            <SuspensedView>
              <Masters/>
            </SuspensedView>
          }
        />
        <Route
          path='menu/settings/alertsAndNotificationSettings'
          element={
            <SuspensedView>
              <AlertsAndNotificationSettings/>
            </SuspensedView>
          }
        />
        <Route
          path='menu/settings/integrations'
          element={
            <SuspensedView>
              <Integrations/>
            </SuspensedView>
          }
        />
        <Route
          path='menu/settings/themeBuilder'
          element={
            <SuspensedView>
              <ThemeBuilder/>
            </SuspensedView>
          }
        />
        <Route
          path='menu/settings/Templates'
          element={
            <SuspensedView>
              <Templates/>
            </SuspensedView>
          }
        />
        <Route
          path='menu/settings/personalSettings'
          element={
            <SuspensedView>
              <PersonalSettings/>
            </SuspensedView>
          }
        />
        <Route
          path='menu/settings/contactSettings'
          element={
            <SuspensedView>
              <ContactSettings/>
            </SuspensedView>
          }
        />
        <Route
          path='menu/settings/leadSettings'
          element={
            <SuspensedView>
              <LeadSettings/>
            </SuspensedView>
          }
        />
        <Route
          path='menu/settings/formBuilder'
          element={
            <SuspensedView>
              <FormBuilder/>
            </SuspensedView>
          }
        />
        
    {/* Settings Routes end*/}

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}