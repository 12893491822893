import React,{FC, useState, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import { LeadSetting } from './addLeadSetting'


type Props = {
    setUserList?: any
  }

const LeadSettingsDrawer: FC<Props> = (props) => {
    const {
        setUserList
      } = props
  
  return(
  <div>
    {/* Add Contact Drawer */}
    <div
        id='kt_addleadsettings'
        className='detail_page_view offcanvas offcanvas-end justify-content-end bg-transparent d-none w-100 p-4'
        // data-kt-drawer='true'
        // data-kt-drawer-name='LeadSettings'
        // data-kt-drawer-activate='true'
        // data-kt-drawer-overlay='true'
        // data-kt-drawer-width="{default:'100%', 'md': '700px'}"
        // data-kt-drawer-direction='end'
        // data-kt-drawer-toggle='#kt_addleadsettings_toggle'
        // data-kt-drawer-close='#kt_addleadsettings_close'
      >
        <LeadSetting/>
    </div>
      

  </div>
  )
}


export {LeadSettingsDrawer}
// export default TaskDrawer