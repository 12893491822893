import axios, {AxiosResponse}  from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const GET_CONTACTS_URL = `${API_URL}/get_contact_drop_list`
export const GET_LEADS_URL = `${API_URL}/get_leads`
export const GET_TASKS_URL = `${API_URL}/get_tasks`
export const GET_PROJECTS_URL = `${API_URL}/get_properties`
export const GET_TASKS_BY_ROLE_URL = `${API_URL}/get_task_tl`
export const GET_TASK_URL = `${API_URL}/get_task`
export const GET_TASK_TYPE_URL = `${API_URL}/get_task_type`
export const GET_TASK_STATUS_URL = `${API_URL}/get_task_status`
export const GET_PRIORITY_URL = `${API_URL}/get_priority`
export const SAVE_TASKS_URL = `${API_URL}/save_task`
export const UPDATE_TASKS_URL = `${API_URL}/put_task`
export const UPDATE_TASK_STATUS_URL = `${API_URL}/put_taskStatus_task`
export const UPDATE_TASK_PRIORITY_URL = `${API_URL}/put_taskPriority_task`
export const GET_ASSIGN_TO_URL = `${API_URL}/get_users`
export const UPLOAD_FILE_URL = `${API_URL}/uploadfileTask`
export const SAVE_TASK_NOTES = `${API_URL}/save_task_notes`
export const GET_TASK_NOTES = `${API_URL}/get_all_task_notes`
export const DELETE_TASK_URL = `${API_URL}/delete_task`
export const DELETE_MULTI_TASK_URL = `${API_URL}/delete_task_multiple`
export const UPLOAD_MULITI_IMAGES_TASK_URL = `${API_URL}/upload_mimages_task`
export const GET_MULITI_IMAGES_TASK_URL = `${API_URL}/get_mimages_task`
export const DELETE_MULITI_IMAGES_TASK_URL = `${API_URL}/delete_mimages_task`
export const EDIT_TASK_NOTES_URL = `${API_URL}/put_task_notes`
export const DELETE_TASK_NOTES_URL = `${API_URL}/delete_task_notes`
export const GET_LOGS_URL = `${API_URL}/get_logs`
export const GET_TASK_FILTERS_URL = `${API_URL}/get_task_filters`
export const GET_TASK_FILTER_URL = `${API_URL}/get_task_filter`
export const SAVE_TASK_FILTER_URL = `${API_URL}/save_task_filter`


// task fetch api
export function getTasks() {
    return axios.get(GET_TASKS_URL)
    .then((response => response.data))
}

// task fetch api
export function getProjects(userId:any, roleId:any) {
    return axios.get(GET_PROJECTS_URL+'/'+userId+'/'+roleId+'/'+userId)
    .then((response => response.data))
}

// get tasks by role
export function getTasksByRole(id:any, role:any) {
    return axios.get(GET_TASKS_BY_ROLE_URL+'/'+id+'/'+role+'/'+id)
    .then((response => response.data))
}

// task type fetch api
export function getTaskType() {
    return axios.get(GET_TASK_TYPE_URL)
    .then((response => response.data))
}

// task status fetch api
export function getTaskStatus() {
    return axios.get(GET_TASK_STATUS_URL)
    .then((response => response.data))
}

// task priority fetch api
export function getTaskPriority() {
    return axios.get(GET_PRIORITY_URL)
    .then((response => response.data))
}

// task fetch api
export function getTaskDetail(taskId:any) {
    return axios.get(GET_TASK_URL+'/'+taskId)
    .then((response => response.data))
}

// update task api
export function updateTaskStatus(taskId:any ,body:any) {
    return axios.put(UPDATE_TASK_STATUS_URL+'/'+taskId, body)
    .then((response => response.data))
}


// upload lead fetch api
export function uploadFileTask(postData:any, headers:any) {
    return axios.post(UPLOAD_FILE_URL, postData, headers)
    .then((response => response.data))
}

// update task api
export function updateTaskPriority(taskId:any ,body:any) {
    return axios.put(UPDATE_TASK_PRIORITY_URL+'/'+taskId, body)
    .then((response => response.data))
}

// save task api
export function saveTask(body:any) {
    return axios.post(SAVE_TASKS_URL, body)
    .then((response => response.data))
}

// update task api
export function updateTask(taskId:any ,body:any) {
    return axios.put(UPDATE_TASKS_URL+'/'+taskId, body)
    .then((response => response.data))
}

// lead fetch api
export function getLeads() {
    return axios.get(GET_LEADS_URL)
    .then((response => response.data))
}

// contact list fetch api
export function getContacts(id:any, role:any) {
    return axios.get(GET_CONTACTS_URL+'/'+id+'/'+role+'/'+id)
    .then((response => response.data))
  }

// assignto list fetch api
export function getAssignTo() {
    return axios.get(GET_ASSIGN_TO_URL)
    .then((response => response.data))
  }

  // get Task Against Contact Id
export function saveTaskNotes(body:any) {
    return axios.post(SAVE_TASK_NOTES,body)
    .then((response => response.data))
  }

  // get Task Against Contact Id
  export function getTaskNotes(contactId:number) {
    return axios.get(GET_TASK_NOTES+'/'+contactId)
    .then((response => response.data))
  } 

  // update task api
export function deleteTask(taskId:any) {
    return axios.put(DELETE_TASK_URL+'/'+taskId)
    .then((response => response.data))
}

// upload task
export function uploadMultipleFileTask(id:any ,postData:any, headers:any) {
    return axios.post(UPLOAD_MULITI_IMAGES_TASK_URL+'/'+id, postData, headers)
    .then((response => response.data))
}

// delete task multiple api
export function deleteMultipleTasks(taskId:any) {
    return axios.put(DELETE_MULTI_TASK_URL+'/'+taskId)
    .then((response => response.data))
}

// get Task Against Contact Id
export function getMultipleFilesTasks(contactId:number) {
    return axios.get(GET_MULITI_IMAGES_TASK_URL+'/'+contactId)
    .then((response => response.data))
}

// delete task multiple api
export function deleteMultipleFilesTasks(taskId:any) {
    return axios.put(DELETE_MULITI_IMAGES_TASK_URL+'/'+taskId)
    .then((response => response.data))
}

// delete task multiple api
export function editTaskNotes(taskId:any, editBody:any) {
    return axios.put(EDIT_TASK_NOTES_URL+'/'+taskId, editBody)
    .then((response => response.data))
}

// delete task multiple api
export function deleteTaskNotes(taskId:any, userId:any, parentId:any) {
    return axios.put(DELETE_TASK_NOTES_URL+'/'+taskId+'/'+userId+'/'+parentId)
    .then((response => response.data))
}

// task fetch api
export function getLog(taskId:any) {
    return axios.get(GET_LOGS_URL+'/'+taskId+'/3')
    .then((response => response.data))
}

// task filter api
export function getTaskFilters(userId:any) {
    return axios.get(GET_TASK_FILTERS_URL+'/'+userId)
    .then((response => response.data))
}

// task filter api
export function getTaskFilter(Id:any) {
    return axios.get(GET_TASK_FILTER_URL+'/'+Id)
    .then((response => response.data))
}

// upload task
export function saveTaskFilter(postData:any) {
    return axios.post(SAVE_TASK_FILTER_URL,postData)
    .then((response => response.data))
}

// get tasks by role
export function getFilteredTasks(id:any, role:any, body:any, headers:any) {
    return axios.get(GET_TASKS_BY_ROLE_URL+'/'+id+'/'+role+'/'+id+'?task_type='+body.task_type+'&priority='+body.priority+'&task_status='+body.task_status+'&created_at='+body.created_date+'&assign_to='+body.assign_to+'&property_id='+body.property)
    .then((response => response.data))
}

// task fetch api
export function getTaskSortBy(id:any, role:any, sortBy:any) {
    return axios.get(GET_TASKS_BY_ROLE_URL+'/'+id+'/'+role+'/'+id+'?order_by='+sortBy)
    .then((response => response.data))
  }