import React,{FC, useEffect, useState} from 'react'
import { useAuth } from '../../../modules/auth';
import { ContactDoughnut } from '../contact/contactDoughnut';
import { ContactFunnel } from '../contact/contactFunnel';
import { ContactSpeedometer } from '../contact/contactSpeedometer';
import { LeadDoughnut } from '../lead/leadDoughnut';
import { LeadFunnel } from '../lead/leadFunnel';
import { LeadSpeedometer } from '../lead/leadSpeedometer';
import { PropertyDoughnut } from '../project/projectDoughnut';
import { PropertyFunnel } from '../project/projectFunnel';
import { TaskDoughnut } from '../task/taskDoughnut';
import { TaskFunnel } from '../task/taskFunnel';
import { ContactOverviewSpeedometer } from './contactOverviewSpeedometer';
import { LeadOverviewSpeedometer } from './leadOverviewSpeedometer';

const OverViewSpeedometer: FC = () => {

    const {currentUser, logout} = useAuth();
    const userId = currentUser?.id;
    const roleId = currentUser?.designation;
    const usersName = currentUser?.first_name;
    const [moduleSelect, setModuleSelect] = useState<any>(1);

    useEffect(() => {

    }, []);

    return(
        <>
        <div className='card mx-sm-1 bs_1 br_15 h-100 mb-4 position-relative'>
            {/* <select className="form-select dash_btn m-2 fs-9 position-absolute bottom-0 left-0 button_indexing" onChange={(e) => {
                setModuleSelect(e.target.value);
            }}>
                <option selected value="1">Contact</option>

                <option value="2">Lead</option>
            </select> */}
            {moduleSelect == 1 && <ContactOverviewSpeedometer setModuleSelectContact={setModuleSelect} moduleSelectContact={moduleSelect} /> }
            {moduleSelect == 2 && <LeadOverviewSpeedometer setModuleSelectLead={setModuleSelect} moduleSelectLead={moduleSelect} /> }
            {/* {moduleSelect == 3 && <PropertyFunnel/> }
            {moduleSelect == 4 && <TaskFunnel/> } */}
        </div>       
        </>
    )
}

export {OverViewSpeedometer}