import React,{FC, useEffect, useState} from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import MenuItem from '@mui/material/MenuItem';
import { Toast } from 'bootstrap';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { useAuth } from '../../modules/auth';
import Moment from 'moment';
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import { getAgeOfProperty, getAvailableFor, getCountry, getProjectStage, getPropertyFacing, getPropertySource, getPropertyStatus, getPropertyType, getSource, getVasthuComplaint } from '../settings/masters/core/_requests';
import { getProperties, getPropertiesFilter, getPropertyFilter, getPropertyFilters, savePropertyFilter,getProjects } from './core/_requests';

const initialValues = {
    available_for: [],
    commission_min: '',
    commission_max: '',
    property_type: '',
    property: '',
    property_source: '',
    property_status : '',
    country : '',
    age_of_property_min: '',
    property_facing : '',
    project_stage : '',
    gated_community : '',
    vasthu_compliant : '',
    no_of_units_min: '',
    no_of_units_max: '',
    no_of_floors_min: '',
    no_of_floors_max: '',
    rera_registered: '',
    created_date: '',
    created_by: '',
    filter_name: '',
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, aminityName: string[], theme: Theme) {
    return {
        fontWeight:
        aminityName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
    };
}

const mystyle = {
    padding: "0px",
    height: "34px",
    maxHeight: "200px",
    overflowY: "scroll",
    // display: "block"
};

type Props = {
    setProperty?: any
}

const PropertyFilter:  FC<Props> = (props) => {

    const {
        setProperty
        } = props

    const theme = useTheme(); 

    const {currentUser, logout} = useAuth();
    const [AvailableFor, setAvailableFor] = useState<any[]>([]);
    const [propertyType, setPropertyType] = useState<any[]>([]);
    const [projectStage, setProjectStage] = useState<any[]>([]);
    const [propertyStatus, setPropertyStatus] = useState<any[]>([]);
    const [ageOfProperty, setAgeOfProperty] = useState<any[]>([]);
    const [propertyFacing, setPropertyFacing] = useState<any[]>([]);
    const [vasthuComplaint, setVasthuComplaint] = useState<any[]>([]);
    const [availableForId, setavailableForId] = useState<any[]>([]);
    const [availableForName, setavailableForName] = useState<any[]>([]);
    const [propertyFilters, setPropertyFilters] = useState<any[]>([]);
    const [country, setCountry] = useState<any[]>([]);
    const [source, setSource] = useState<any[]>([]);
    const [property, setProperties] = useState<any[]>([]);
    const [save, setSave] = useState(false);
      
    var userId = currentUser?.id;
    var roleId = currentUser?.designation; 

    const propertyFilterSchema = Yup.object().shape({
        commission_min : Yup.string(),
        commission_max : Yup.string(),
        property_type : Yup.string(),
        property : Yup.string(),
        property_source : Yup.string(),
        property_status : Yup.string(),
        country : Yup.string(),
        age_of_property_min : Yup.string(),
        property_facing : Yup.string(),
        project_stage : Yup.string(),
        gated_community : Yup.string(),
        vasthu_compliant : Yup.string(),
        buildup_area_min : Yup.string(),
        buildup_area_max : Yup.string(),
        uds_min: Yup.string(),
        uds_max: Yup.string(),
        no_of_units_min : Yup.string(),
        no_of_units_max : Yup.string(),
        no_of_floors_min : Yup.string(),
        no_of_floors_max : Yup.string(),
        rera_registered : Yup.string(),
        created_date : Yup.string(),
        created_by : Yup.string(),
        filter_name : Yup.string(),
    })

    const AvailableForList =  async () => {
        const AvailableForResponse = await getAvailableFor()        
        setAvailableFor(AvailableForResponse);
    }
    const PropertyTypeList =  async () => {
        const PropertyTypeResponse = await getPropertyType() 
        setPropertyType(PropertyTypeResponse);
    }
    const ProjectStageList =  async () => {
        const ProjectStageResponse = await getProjectStage()
        setProjectStage(ProjectStageResponse);
    }
    const SourceList =  async () => {
        const SourceResponse = await getPropertySource()
        setSource(SourceResponse);
    }
    const PropertyStatusList =  async () => {
        const PropertyStatusResponse = await getPropertyStatus()
        setPropertyStatus(PropertyStatusResponse);
    }
    const CountryList =  async () => {
        const CountryResponse = await getCountry()
        setCountry(CountryResponse);
    }
    const AgeOfPropertyList =  async () => {
        const AgeOfPropertyResponse = await getAgeOfProperty()
        setAgeOfProperty(AgeOfPropertyResponse);
    }
    const PropertyFacingList =  async () => {
        const PropertyFacingResponse = await getPropertyFacing() 
        setPropertyFacing(PropertyFacingResponse);
    }
    const VasthuComplaintList =  async () => {
        const VasthuComplaintResponse = await getVasthuComplaint()
        setVasthuComplaint(VasthuComplaintResponse);
    }
    const LeadFilterList =  async () => {
        const Response = await getPropertyFilters(userId);
        setPropertyFilters(Response);
    }

    const availableForChange = (event: SelectChangeEvent<typeof availableForName>) => {
        const {
          target: { value },
        } = event;
        var name = [];
        var id = [];

        for(let i = 0; i < value.length; i++){
        var fields = value[i].split('-');

        var n = fields[0];
        var d = fields[1];

        name.push(n);
        id.push(d);
        }

        console.log(name);
        console.log(id);

        setavailableForId(id);

        setavailableForName(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
    };

    const formik = useFormik({
        initialValues,
        validationSchema: propertyFilterSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
        //   setLoading(true)
        //   console.log('lead form body');
        //   console.log(values);
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        try {
            

        console.log('sddddddddassdsf');

        const body = {
            "available_for" : availableForId,
            "commission_min": values.commission_min,
            "commission_max": values.commission_max,
            "property_type": values.property_type,
            "property": values.property,
            "property_source": values.property_source,
            "property_status" : values.property_status,
            "country" : values.country,
            "age_of_property_min": values.age_of_property_min,
            "property_facing" : values.property_facing,
            "project_stage" : values.project_stage,
            "gated_community" : values.gated_community,
            "vasthu_compliant" : values.vasthu_compliant,
            "no_of_units_min": values.no_of_units_min,
            "no_of_units_max": values.no_of_units_max,
            "no_of_floors_min": values.no_of_floors_min,
            "no_of_floors_max": values.no_of_floors_max,
            "rera_registered": values.rera_registered,
            "created_date": Moment(values.created_date).format("YYYY-MM-DD") == "Invalid date" ? '' : Moment(values.created_date).format("YYYY-MM-DD"),
            "filter_name": values.filter_name,
            "created_by": userId, 
        }

        const headers = {
            headers: {
                "Content-type": "application/json",
            },                    
          }

          if(save) {

            const filterLeadData = await savePropertyFilter(body);

                if(filterLeadData != null){
                    document.getElementById('kt_filter_close')?.click();
                    var toastEl = document.getElementById('myToastAdd');
                    const bsToast = new Toast(toastEl!);
                    bsToast.show();
                    LeadFilterList();
                    resetForm();
                    setavailableForName([])
                }
            } else {
                const filterPropertyData = await getPropertiesFilter(userId, roleId, body, headers);

                console.log('updateLeadData');
                console.log(filterPropertyData);
                // document.getElementById('kt_contact')?.classList.remove('drawer-on');
                if(filterPropertyData != null) {
                    document.getElementById('kt_property_filter_close')?.click();
                    var toastEl = document.getElementById('myToastAdd');
                    const bsToast = new Toast(toastEl!);
                    bsToast.show();
                    console.log('filterPropertyData');
                    console.log(filterPropertyData);
                    setProperty(filterPropertyData);
                    resetForm();
                    setavailableForName([])
                }
            }
        } catch (error) {
        console.error(error)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        }
    }})

    const resetFilter = async () => {
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;

        console.log('sdasdsd')
        formik.resetForm();
        setavailableForName([])
        document.getElementById('kt_property_filter_close')?.click();
        document.getElementById('propertyReloadBtn')?.click();
        var toastEl = document.getElementById('myToastAdd');
        const bsToast = new Toast(toastEl!);
        bsToast.show();
        
    }

    const filterTap =  async (Id : any) => {
        const fetchDetails = await getPropertyFilter(Id)
        console.log('fetchDetails');    
        console.log(fetchDetails);         
        formik.setFieldValue('commission_min', fetchDetails.commission_min ?? '')
        formik.setFieldValue('commission_max', fetchDetails.commission_max ?? '')
        formik.setFieldValue('property_type', fetchDetails.property_type ?? '')
        formik.setFieldValue('property', fetchDetails.property ?? '')
        formik.setFieldValue('property_source', fetchDetails.property_source ?? '')
        formik.setFieldValue('property_status', fetchDetails.property_status ?? '')
        formik.setFieldValue('country', fetchDetails.country ?? '')
        formik.setFieldValue('age_of_property_min', fetchDetails.age_of_property_min ?? '')
        formik.setFieldValue('property_facing', fetchDetails.property_facing ?? '')
        formik.setFieldValue('project_stage', fetchDetails.project_stage ?? '')
        formik.setFieldValue('gated_community', fetchDetails.gated_community ?? '')
        formik.setFieldValue('vasthu_compliant', fetchDetails.vasthu_compliant ?? '')
        formik.setFieldValue('no_of_units_min', fetchDetails.no_of_units_min ?? '')
        formik.setFieldValue('no_of_units_max', fetchDetails.no_of_units_max ?? '')
        formik.setFieldValue('no_of_floors_min', fetchDetails.no_of_floors_min ?? '')
        formik.setFieldValue('no_of_floors_max', fetchDetails.no_of_floors_max ?? '')
        formik.setFieldValue('rera_registered', fetchDetails.rera_registered ?? '')
        formik.setFieldValue('created_date', Moment(fetchDetails.created_date ?? '').format("YYYY-MM-DD"))

        var availableArray = [];
        var availableNameArray = [];
        if(fetchDetails.available_for != null){
            availableArray = fetchDetails.available_for.split(",").map((e:any) => {
                return e;
            });
        }
        if(fetchDetails.available_for_name != null){
            availableNameArray = fetchDetails.available_for_name.split(",").map((e:any) => {
                return e;
            });
        }

        setavailableForId(availableArray)
        setavailableForName(availableNameArray)

    }

    const propertyList =  async () => {   
        const Response = await getProperties(userId, roleId)
        setProperties(Response);
    }

    useEffect(() => {
        AvailableForList();
        PropertyTypeList();
        ProjectStageList();
        PropertyStatusList();
        AgeOfPropertyList();
        PropertyFacingList();
        VasthuComplaintList();
        SourceList();
        LeadFilterList();
        CountryList();
        propertyList();
    }, []);

    return(
        <div className='card shadow-none rounded-0 w-100'>
            <div className='card-header w-100' id='kt_filter_header'>
                <h3 className='card-title fw-bolder text-dark'>Project Filter</h3>
                <div className='card-toolbar'>
                    <button
                    type='button'
                    className='btn btn-icon btn-active-light-primary me-n5'
                    id='kt_property_filter_close'
                    >
                        <img src={toAbsoluteUrl('/media/custom/cancel_black.svg')} className="svg_icon" alt='' />
                    {/* <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' /> */}
                    </button>
                </div>
            </div>                            
                <div className='card-body position-relative' id='kt_filter_body'>
                <form noValidate onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-md-6 col-12 mb-3 edit_page_form">
                            <label htmlFor="basic-url" className="form-label">Available For</label>
                            <FormControl sx={{ m: 0, width: '100%', mt: 0 }}>
                                <Select
                                    multiple
                                    displayEmpty
                                    value={availableForName}
                                    onChange={availableForChange}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => {
                                        var name = [];
                                        var id = [];

                                        for(let i = 0; i < selected.length; i++){
                                            var fields = selected[i].split('-');

                                            var n = fields[0];
                                            var d = fields[1];

                                            name.push(n);
                                            id.push(d);
                                        }

                                        if (selected.length === 0) {
                                        return <p>Available For</p>;
                                        }

                                        return name.join(', ');
                                    }}
                                    className='multi_select_field btn w-100 text-start form-select bg-gray-100 rounded'
                                    MenuProps={MenuProps}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                    <MenuItem disabled value="">
                                        <em>Available For</em>
                                    </MenuItem>
                                    {AvailableFor.map((Val) => (
                                        <MenuItem
                                        key={Val.id}
                                        value={Val.name+'-'+Val.id}
                                        >
                                        {Val.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div> 
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Commission</label>
                            <div className="row">
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="number" min="0" className="input-group btn w-100 text-start input_prepend bg-gray-100 rounded" placeholder="Min" {...formik.getFieldProps('commission_min')}/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="number" min="0" className="input-group btn w-100 text-start input_prepend bg-gray-100 rounded" placeholder="Max" {...formik.getFieldProps('commission_max')}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Project Type</label>
                            <div className="input-group mb-3">
                                <select className="btn w-100 text-start form-select bg-gray-100 rounded" {...formik.getFieldProps('property_type')}>
                                    <option value=''>select</option>
                                    {propertyType.map((Data, i) => {
                                return(
                                    <option value={Data.id} key={i}>{Data.name}</option>
                                    )})}
                                </select>      
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Project Stage</label>
                            <div className="input-group mb-3">
                                <select className="btn w-100 text-start form-select bg-gray-100 rounded" {...formik.getFieldProps('project_stage')}>
                                <option value=''>select</option>
                                    {projectStage.map((Data, i) => {
                                return(
                                    <option value={Data.id} key={i}>{Data.name}</option>
                                    )})}
                                </select>      
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Agent Source</label>
                            <div className="input-group mb-3">
                                <select className="btn w-100 text-start form-select bg-gray-100 rounded" {...formik.getFieldProps('property_source')}>
                                <option value=''>select</option>
                                    {source.map((Data, i) => {
                                return(
                                    <option value={Data.id} key={i}>{Data.name}</option>
                                    )})}
                                </select>      
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Status</label>
                            <div className="input-group mb-3">
                                <select className="btn w-100 text-start form-select bg-gray-100 rounded" {...formik.getFieldProps('property_status')}>
                                    <option>All</option>
                                    <option value=''>select</option>
                                    {propertyStatus.map((Data, i) => {
                                return(
                                    <option value={Data.id} key={i}>{Data.name}</option>
                                    )})}
                                </select>      
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Country</label>
                            <div className="input-group mb-3">
                                <select className="btn w-100 text-start form-select bg-gray-100 rounded" {...formik.getFieldProps('country')}>
                                <option value=''>select</option>
                                    {country.map((Data, i) => {
                                return(
                                    <option value={Data.id} key={i}>{Data.name}</option>
                                    )})}
                                </select>      
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Facing</label>
                            <div className="input-group mb-3">
                                <select className="btn w-100 text-start form-select bg-gray-100 rounded" {...formik.getFieldProps('property_facing')}>
                                <option value=''>select</option>
                                    {propertyFacing.map((Data, i) => {
                                return(
                                    <option value={Data.id} key={i}>{Data.name}</option>
                                    )})}
                                </select>      
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Gated Community</label>
                            <div className="input-group mb-3">
                                <select className="btn w-100 text-start form-select bg-gray-100 rounded" {...formik.getFieldProps('gated_community')}>
                                <option value=''>Select</option>
                                    <option value='1'>Yes</option>
                                    <option value='0'>No</option>
                                </select>      
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Project</label>
                            <div className="input-group mb-3">
                            <select className="btn w-100 text-start form-select bg-gray-100 rounded" 
                            {...formik.getFieldProps('property')}
                            >
                                <option value=''>Select</option>
                                {property.map((value,i)=> {
                                  return (<>{value.name_of_building != null &&
                                    <option selected={i == 0 ? true: false} value={value.id} key={i}>{value.name_of_building}</option>}
                                    </>
                                  )
                                })}   
                            </select>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Age Of Project</label>
                            <div className="input-group mb-3">
                                <select className="btn w-100 text-start form-select bg-gray-100 rounded" {...formik.getFieldProps('age_of_property_min')}>
                                <option value=''>Select</option>
                                {ageOfProperty.map((Data, i) => {
                                return(
                                    <option value={Data.id} key={i}>{Data.name}</option>
                                    )})}
                                </select>      
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Vasthu</label>
                            <div className="input-group mb-3">
                                <select className="btn w-100 text-start form-select bg-gray-100 rounded" {...formik.getFieldProps('vasthu_compliant')}>
                                <option value=''>select</option>
                                    {vasthuComplaint.map((Data, i) => {
                                return(
                                    <option value={Data.id} key={i}>{Data.name}</option>
                                    )})}
                                </select>      
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">No.of Units</label>
                            <div className="row">
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="number" min="0" className="input-group btn w-100 text-start input_prepend bg-gray-100 rounded" placeholder="Min" {...formik.getFieldProps('no_of_units_min')}/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="number" min="0" className="input-group btn w-100 text-start input_prepend bg-gray-100 rounded" placeholder="Max" {...formik.getFieldProps('no_of_units_max')}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Floors</label>
                            <div className="row">
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="number" min="0" className="input-group btn w-100 text-start input_prepend bg-gray-100 rounded" placeholder="Min" {...formik.getFieldProps('no_of_floors_min')}/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="number" min="0" className="input-group btn w-100 text-start input_prepend bg-gray-100 rounded" placeholder="Max" {...formik.getFieldProps('no_of_floors_max')}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">RERA Registered</label>
                            <div className="input-group mb-3">
                                <select className="btn w-100 text-start form-select bg-gray-100 rounded" {...formik.getFieldProps('rera_registered')}>
                                    <option value=''>Select</option>
                                    <option value='1'>Yes</option>
                                    <option value='0'>No</option>
                                </select>      
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Created Date</label>
                            <div className="input-group mb-3">
                                <input type="date" className="input-group btn w-100 text-start input_prepend bg-gray-100 rounded" placeholder="date" {...formik.getFieldProps('created_date')}/> 
                            </div>
                        </div>
                    </div> 
                    <div className='text-end'>               
                        <div className="mb-3 mb-md-0">
                            <div className="form-check d-flex">
                                <input className="form-check-input" type="checkbox" value="" id="filterCheck"/>
                                <label className="form-check-label mx-3" htmlFor="filterCheck">
                                    Include Archived Records
                                </label>
                            </div>
                        </div>
                        <div className="mb-3 mb-md-0">
                            <button type='button' data-bs-toggle='modal' data-bs-target={'#property_filter_save_popup'} className='btn btn_soft_primary save_btn p-3 pr-0 mx-3 br_10' title="Save">
                                <KTSVG
                                    path='/media/custom/save_orange.svg'
                                    className='svg-icon-3 svg-icon-primary me-0'
                                />
                            </button>
                            <button className='btn reset_btn mx-3 br_10' onClick={resetFilter}>
                                <i className="fas fa-undo"></i>
                                Reset
                            </button>
                            <button className='btn btn_primary mx-3 br_10' onClick={(e) => setSave(false)}>
                                <KTSVG path='/media/custom/search_white.svg' className='svg-icon-5 svg-icon-gray-500 me-1'/>
                                Search
                            </button>
                        </div>
                        <div className='modal fade' id={'property_filter_save_popup'} aria-hidden='true'>
                            <div className='modal-dialog modal-dialog-centered'>
                                <div className='modal-content'>
                                    <div className='modal-header'>
                                        <h3>Want to Save Filter?</h3>
                                        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                        </div>
                                    </div>
                                    <div className='modal-body py-lg-10 px-lg-10'>
                                        <div className="mb-3 text-start">
                                            <label htmlFor="basic-url" className="form-label">Filter Name</label>
                                            <div className="input-group mb-3">
                                                <input type="text" className="input-group btn w-100 text-start input_prepend bg-gray-100 rounded" placeholder="" {...formik.getFieldProps('filter_name')}/> 
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-end'>
                                            <button type='button' className='btn btn-sm btn-secondary mt-3 me-3' data-bs-dismiss='modal'>
                                                No
                                            </button>
                                            <button type='submit' className='btn btn-sm btn_primary text-primary mt-3' data-bs-dismiss='modal' onClick={(e) => setSave(true)}>
                                                Yes
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </form>
                    <div className='mt-5'>
                    {propertyFilters.length > 0
                    ? <div className='row'>
                        <div className='col-12'>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Saved Filters
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className='row'>
                                        {propertyFilters.map((Data, i) => {
                                        return(
                                            <div className='col-xxl-4'>
                                                <div onClick={(e) => filterTap(Data.id)} className="d-flex cursor_pointer align-items-center bg_soft rounded py-5 px-4 mb-7">
                                                    <div className="flex-grow-1">
                                                        <a href="#" className="fw-bold text-gray-800 text-hover-primary fs-6">{Data.filter_name}</a>
                                                        <span className="text-muted fw-semibold d-block">{Moment(Data.created_at).format("DD-MM-YYYY HH:mm")}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )})}
                                    </div>
                                </div>
                            </div>
                        </div>
                            
                        </div>
                    </div> : <></>
                    }
                    </div>
                </div>            
        </div>
    )
}


export {PropertyFilter}