import React,{FC, useState, useEffect, useRef} from 'react'
import {Link} from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import {useAuth} from '../../../app/modules/auth'
import {getContacts, getCountries, getProperties, getAvailableFor, getPropertyStatus, getPropertyType, getPropertySource, getPropertyInDepth, getAssignTo, getCity, getState, getAmenity, getUnitType, getFurnishStatus, getPosesStatus, getOwnershipType, getPropertyFacing, getKitchenType, getFlooring, getKeyCustody, getVasthuComp, getCurrency, saveProperty, getSegment, getProjectStage, getSpecification, getSiteVisitPref, getAgeOfProperty} from './core/_requests'
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Toast } from 'bootstrap';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { useDropzone } from 'react-dropzone'
import { getLegalApproval, getWaterSupply } from '../settings/masters/core/_requests'
import { typeOf } from 'react-is'
import { getAssignToList } from '../contact/core/_requests'

const initialValues = {
    contact_id: '',
    available_for: [],
    commission: '',
    property_indepth: '',
    segment: '',
    property_type: '',
    property_source: '',
    legal_approval: '',
    property_status: '',
    description: '',
    project_stage: '',
    age_of_property: '',
    furnishing: '',
    price_min: '',
    gst: '',
    invoice_name: '',
    price_max: '',
    builtup_area_min: '',
    builtup_area_max: '',
    plot_area_min: '',
    plot_area_max: '',
    tower: '',
    // specification: [],
    uds: '',
    no_of_floors: '',
    ownership_type: '',
    balcony: '',
    property_facing: '',
    kitchen_type: '',
    flooring: '',
    vasthu_compliant: '',
    currently_under_loan: '',
    available_from: '',
    site_visit_preference: [],
    key_custody: '',
    no_of_car: '',
    car_park_type: '',
    water_suppply: '',
    gated_community: '',
    amenities:[],
    property_highlight: '',
    rera_registered: '',
    rera_number: '',
    completion_certificate: '',
    name_of_building: '',
    address_line1: '',
    country: '',
    state: '',
    city: '',
    locality: '',
    pincode: '',
    latitude: '',
    longitude: '',
    door_number: '',
    module_number: '',
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const thumbsContainer = {
    display: 'flex',
    // flexDirection: 'row',
    // flexWrap: 'wrap',
    marginTop: 16
  };

const thumb = {
    display: 'inline-flex',
    borderRadius: 5,
    // border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    // boxSizing: 'border-box'
  };
  
  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };

function getStyles(name: string, aminityName: string[], theme: Theme) {
    return {
        fontWeight:
        aminityName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
    };
}

const mystyle = {
    padding: "0px",
    height: "34px",
    maxHeight: "200px",
    overflowY: "scroll",
    // display: "block"
};

type Props = {
    setProperty?: any
}

const ProjectForm: FC<Props> = (props) => {

    const {setProperty} = props

    const [loading, setLoading] = useState(false);
    const [rera, setrera] = useState<any>('');
    const [aminityName, setAminityName] = React.useState<string[]>([]);
    const [assignToName, setAssignToName] = React.useState<string[]>([]);
    const [assignToId, setAssignToId] = useState<any[]>([]);
    const [furnishName, setFurnishName] = React.useState<string[]>([]);
    const [specsName, setSpecsName] = React.useState<string[]>([]);
    const [siteVisitName, setSiteVisitName] = React.useState<string[]>([]);
    const [posName, setPosName] = React.useState<string[]>([]);
    const [contacts, setContacts] = useState<any[]>([]);
    const [availableFor, setAvailableFor] = useState<any[]>([]);
    const [propertyType, setPropertyType] = useState<any[]>([]);
    const [propertyStatus, setPropertyStatus] = useState<any[]>([]);
    const [propertySource, setPropertySource] = useState<any[]>([]);
    const [propertyInDepth, setPropertyInDepth] = useState<any[]>([]);
    const [legalApproval, setLegalApproval] = useState<any[]>([]);
    const [propertyFacing, setPropertyFacing] = useState<any[]>([]);
    const [projectStages, setProjectStages] = useState<any[]>([]);
    const [ageOfProp, setAgeOfProp] = useState<any[]>([]);
    // const [specifications, setSpecifications] = useState<any[]>([]);
    const [siteVisit, setSiteVisit] = useState<any[]>([]);
    const [keyCustody, setKeyCustody] = useState<any[]>([]);
    const [kitchenType, setKitchenType] = useState<any[]>([]);
    const [ownershipType, setOwnershipType] = useState<any[]>([]);
    const [flooring, setFlooring] = useState<any[]>([]);
    const [vasthuComp, setVasthuComp] = useState<any[]>([]);
    const [cities, setCities] = useState<any[]>([]);
    const [states, setStates] = useState<any[]>([]);
    const [files, setFiles] = useState<any[]>([]);
    const [countries, setCountries] = useState<any[]>([]);
    const [segment, setSegment] = useState<any[]>([]);
    const [assignTo, setAssignTo] = useState<any[]>([]);
    const [amenities, setAmenities] = useState<any[]>([]);
    const [unitType, setUnitType] = useState<any[]>([]);
    const [furnishStatus, setFurnishStatus] = useState<any[]>([]);
    const [posesStatus, setPosesStatus] = useState<any[]>([]);
    const [availableName, setAvailableName] = useState<any[]>([]);
    const [currency, setCurrency] = useState<any[]>([]);
    const [planData, setPlanData] = useState<any[]>([]);
    const [currentFeature, setCurrentFeature] = useState('');
    // const [base64Image, setBase64Image] = useState<any>('');
    const [base64Image, setBase64Image] = useState<any[]>([]);
    const [specsId, setSpecsId] = useState<any[]>([]);
    const [aminityId, setAminityId] = useState<any[]>([]);
    const [siteVisitId, setSiteVisitId] = useState<any[]>([]);
    const [waterSupply, setWaterSupply] = useState<any[]>([]);
    const [availableId, setAvailableId] = React.useState<string[]>([]);
    const [documentList, setDocumentList] = useState<any[]>([{ document: "" }]);
    const profileView = useRef<HTMLInputElement>(null);
    const [documentFile, setDocumentFile] = React.useState(() => documentList.map((x) => true));
    const {currentUser, logout} = useAuth();

    var roleId = currentUser?.designation;
    var userId = currentUser?.id;


    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            'image/*': ['.jpeg', '.jpg'],         
            'document/*': ['.pdf']
        },
        onDrop: acceptedFiles => {
        console.log(acceptedFiles);
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        // setFormFiles(acceptedFiles);

        var test: any[] = [];
        acceptedFiles.map(file => {
            console.log(file.lastModified);
            let data = {
                "lastModified": file.lastModified,
                // "lastModifiedDate": file.lastModifiedDate,
                "name": file.name,
                "size": file.size,
                "type": file.type,
                // "webkitRelativePath": acceptedFiles.webkitRelativePath
            }            
            console.log(data);
            test.push(data);
            // setFilesData(data);
        });
        console.log('files =======');
        console.log(files);
        }
    });  

    const handleDocumentRemove = async (index:any) => {
        const list = [...documentList];
        list.splice(index, 1);
        setDocumentList(list);

        var featureData = [];

        for(let i = 1; i <= documentList.length; i++){

            let fUnitType = (document.getElementById('unit_type_'+i) as HTMLInputElement).value;
            let fTotalUnits = (document.getElementById('total_units_'+i) as HTMLInputElement).value;
            // let fBathrooms = (document.getElementById('bathrooms_'+i) as HTMLInputElement).value;
            let fPriceMin = (document.getElementById('price_min_'+i) as HTMLInputElement).value;
            let fPriceMax = (document.getElementById('price_max_'+i) as HTMLInputElement).value;
            let fBuiltMin = (document.getElementById('builtup_area_min_'+i) as HTMLInputElement).value;
            let fBuiltMax = (document.getElementById('builtup_area_max_'+i) as HTMLInputElement).value;
            // let fPlotMin = (document.getElementById('plot_area_min_'+i) as HTMLInputElement).value;
            // let fPlotMax = (document.getElementById('plot_area_max_'+i) as HTMLInputElement).value;
            let fLocalCurrency = (document.getElementById('local_currency_'+i) as HTMLInputElement).value;
            let fFile = (document.getElementById('file_'+i) as HTMLInputElement).files;
            
            console.log('fFile ----------')
            console.log(fFile![0])

            let base64 = fFile![0] != undefined || fFile![0] != null ? await convertBase64(fFile![0]) : '';

            var data = {
                "unit_type": fUnitType,
                "total_units": fTotalUnits,
                // "bathrooms": fBathrooms,
                "price_min": fPriceMin,
                "price_max": fPriceMax,
                "builtup_area_min": fBuiltMin,
                "builtup_area_max": fBuiltMax,
                // "plot_area_min": fPlotMin,
                // "plot_area_max": fPlotMax,
                "local_currency": fLocalCurrency,
                "file": '',
            }
            featureData.push(data);
        }

        setPlanData(featureData);

    };

    const removeProfile = () => {
        console.log(profileView.current?.value);
        // if(profileView.current != null){
        //   setProfileImagePreview(null);
        //   setProfileImage(null);
        //   profileView.current.value = "";
        // }
      }

    const processFile = async (e:any, index:any) => {
        console.log('image dropppeddd');
        var files = e.dataTransfer.files;

        console.log(e.dataTransfer);
        console.log(files);

        // const newVisibilities = [...documentFile];
        // newVisibilities[index] = !newVisibilities[index];
        // setDocumentFile(newVisibilities);
        // console.log(documentFile);

        let fields = files[0]['name'].split(".");

        let fileType =  fields![fields!.length - 1];

        var featureData = [];

        for(let i = 1; i <= documentList.length; i++){
            let fFileSec = (document.getElementById('file_'+i) as HTMLInputElement).value;


            console.log(fFileSec);

            if(index == i){
                fFileSec = files;
            }

            let fImgSec = (document.getElementById('file_img_sec_'+i) as HTMLDivElement);
            let fImg = (document.getElementById('file_img_'+i) as HTMLImageElement);
            let fUnitType = (document.getElementById('unit_type_'+i) as HTMLInputElement).value;
            let fTotalUnits = (document.getElementById('total_units_'+i) as HTMLInputElement).value;
            // let fBathrooms = (document.getElementById('bathrooms_'+i) as HTMLInputElement).value;
            let fPriceMin = (document.getElementById('price_min_'+i) as HTMLInputElement).value;
            let fPriceMax = (document.getElementById('price_max_'+i) as HTMLInputElement).value;
            let fBuiltMin = (document.getElementById('builtup_area_min_'+i) as HTMLInputElement).value;
            let fBuiltMax = (document.getElementById('builtup_area_max_'+i) as HTMLInputElement).value;
            // let fPlotMin = (document.getElementById('plot_area_min_'+i) as HTMLInputElement).value;
            // let fPlotMax = (document.getElementById('plot_area_max_'+i) as HTMLInputElement).value;
            let fLocalCurrency = (document.getElementById('local_currency_'+i) as HTMLInputElement).value;
            let fFile = (document.getElementById('file_'+i) as HTMLInputElement).files;

            console.log('fFile ----------')
            console.log(fFile![0])
            
            // let base64 = '';

            // if(fFile![0] != undefined || fFile![0] != null){
                let base64 = fFile![0] != undefined || fFile![0] != null ? await convertBase64(fFile![0]) : '';
            // }

            if(index == i){
                if(fileType == 'jpg' || fileType == 'jpeg'){
                    fImgSec.classList.remove("d-none");
                    fImg.setAttribute("src", URL.createObjectURL(files[0]));
                } else if (fileType == 'pdf'){
                    fImgSec.classList.remove("d-none");
                    fImg.setAttribute("src", toAbsoluteUrl("/media/svg/files/pdf.svg"));
                }
            }

            var data = {
                "unit_type": fUnitType,
                "total_units": fTotalUnits,
                // "bathrooms": fBathrooms,
                "price_min": fPriceMin,
                "price_max": fPriceMax,
                "builtup_area_min": fBuiltMin,
                "builtup_area_max": fBuiltMax,
                // "plot_area_min": fPlotMin,
                // "plot_area_max": fPlotMax,
                "local_currency": fLocalCurrency,
                "file": '',
            }

            featureData.push(data);
        }

        setPlanData(featureData);
    
        let image_as_base64:any = URL.createObjectURL(e.target.files[0])
        let image_as_files:any = e.target.files[0];

        if(currentFeature == index){
            console.log('ffffffffffff');
            console.log(image_as_base64);
        }

    }

    const assingToChange = (event: SelectChangeEvent<typeof assignToName>) => {
        const {
          target: { value },
        } = event;
    
        console.log('assign event');
        console.log(value);
    
        var name = [];
        var id = [];
    
        for(let i = 0; i < value.length; i++){
          var fields = value[i].split('-');
    
          var n = fields[0];
          var d = fields[1];
    
          name.push(n);
          id.push(d);
        }
    
        console.log(name);
        console.log(id);
    
        setAssignToId(id);
    
        setAssignToName(
          typeof value === 'string' ? value.split(',') : value,
        );
      };

    const allowDrop = (e:any, index:any) => {
        console.log('image droppp');
        e.preventDefault();
        console.log(e.target.files);
    }

    // const getBase64 = (file:any) => {
    //     let document:any;
       
    //     let reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onload = function () {
    //         document = reader.result?.toString();
    //         console.log('configures1', document)
    //         console.log('configures123', typeof document)
    //         setBase64Image(document)
    //     };
    //     reader.onerror = function (error) {
    //         console.log('Error: ', error);
    //     };
                   
    //     return document;
    // }

    const convertBase64 = (file:any) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
    
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
    
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
      };
    

    const handleFilePreview = async (e:any, index:any) => {
        
    
        console.log('image_as_base64');
        console.log(index);
        setCurrentFeature(index);
        console.log(e.target.files);
        console.log(e.target.files[0]);
        console.log(currentFeature);
        
        const newVisibilities = [...documentFile];
        newVisibilities[index] = !newVisibilities[index];
        setDocumentFile(newVisibilities);
        console.log(documentFile);

        let fields = profileView.current?.value.split(".");

        let fileType =  fields![fields!.length - 1];

        var featureData: React.SetStateAction<any[]> = [];
        console.log('featureData')
        console.log(featureData)

        for(let i = 1; i <= documentList.length; i++){

            let fImgSec = (document.getElementById('file_img_sec_'+i) as HTMLDivElement);
            let fImg = (document.getElementById('file_img_'+i) as HTMLImageElement);
            let fUnitType = (document.getElementById('unit_type_'+i) as HTMLInputElement).value;
            let fTotalUnits = (document.getElementById('total_units_'+i) as HTMLInputElement).value;
            // let fBathrooms = (document.getElementById('bathrooms_'+i) as HTMLInputElement).value;
            let fPriceMin = (document.getElementById('price_min_'+i) as HTMLInputElement).value;
            let fPriceMax = (document.getElementById('price_max_'+i) as HTMLInputElement).value;
            let fBuiltMin = (document.getElementById('builtup_area_min_'+i) as HTMLInputElement).value;
            let fBuiltMax = (document.getElementById('builtup_area_max_'+i) as HTMLInputElement).value;
            // let fPlotMin = (document.getElementById('plot_area_min_'+i) as HTMLInputElement).value;
            // let fPlotMax = (document.getElementById('plot_area_max_'+i) as HTMLInputElement).value;
            let fLocalCurrency = (document.getElementById('local_currency_'+i) as HTMLInputElement).value;
            let fFile = (document.getElementById('file_'+i) as HTMLInputElement).files;
           
            console.log('fFile ----------')
            console.log(fFile![0])

            // let base64 = fFile![0] != undefined || fFile![0] != null ? await convertBase64(fFile![0]) : '';

            
            // let configures: string | undefined; 
            // let reader = new FileReader();

            // reader.readAsDataURL(fFile![0]);

            // reader.onload = () => {
            // configures = reader.result?.toString();
            // formik.setFieldValue('myfiles'+index, configures ?? '')
            // console.log('configuresin', configures)
            // return configures;
            // }

            // console.log('configuresthen', configures)

            if(index == i){
                if(fileType == 'jpg' || fileType == 'jpeg'){
                    fImgSec.classList.remove("d-none");
                    fImg.setAttribute("src", URL.createObjectURL(e.target.files[0]));
                } else if (fileType == 'pdf'){
                    fImgSec.classList.remove("d-none");
                    fImg.setAttribute("src", toAbsoluteUrl("/media/svg/files/pdf.svg"));
                }
            }

            var data = {
                "unit_type": fUnitType,
                "total_units": fTotalUnits,
                // "bathrooms": fBathrooms,
                "price_min": fPriceMin,
                "price_max": fPriceMax,
                "builtup_area_min": fBuiltMin,
                "builtup_area_max": fBuiltMax,
                // "plot_area_min": fPlotMin,
                // "plot_area_max": fPlotMax,
                "local_currency": fLocalCurrency,
                "file": '',
            }

            featureData.push(data);
            // setBase64Image('')
        }

        setPlanData(featureData);
    
        let image_as_base64:any = URL.createObjectURL(e.target.files[0])
        let image_as_files:any = e.target.files[0];

        if(currentFeature == index){
            console.log('ffffffffffff');
            console.log(image_as_base64);
        }
      
        // setImagePreview1(image_as_base64);
        // setImageFile1(image_as_files);
    }

    console.log('planData')
    console.log(planData)

    const handleDocumentAdd = async (index:any) => {
        const newVisibilities = [...documentFile];
        newVisibilities[index] = !newVisibilities[index];
        setDocumentFile(newVisibilities);
        setDocumentList([...documentList, { document: "" }]);
        console.log(documentList);
        
        var featureData = [];

        for(let i = 1; i <= documentList.length; i++){

            let fUnitType = (document.getElementById('unit_type_'+i) as HTMLInputElement).value;
            let fTotalUnits = (document.getElementById('total_units_'+i) as HTMLInputElement).value;
            // let fBathrooms = (document.getElementById('bathrooms_'+i) as HTMLInputElement).value;
            let fPriceMin = (document.getElementById('price_min_'+i) as HTMLInputElement).value;
            let fPriceMax = (document.getElementById('price_max_'+i) as HTMLInputElement).value;
            let fBuiltMin = (document.getElementById('builtup_area_min_'+i) as HTMLInputElement).value;
            let fBuiltMax = (document.getElementById('builtup_area_max_'+i) as HTMLInputElement).value;
            // let fPlotMin = (document.getElementById('plot_area_min_'+i) as HTMLInputElement).value;
            // let fPlotMax = (document.getElementById('plot_area_max_'+i) as HTMLInputElement).value;
            let fLocalCurrency = (document.getElementById('local_currency_'+i) as HTMLInputElement).value;
            let fFile = (document.getElementById('file_'+i) as HTMLInputElement).files;

            console.log('fFile ----------')
            console.log(fFile![0])
            
            // let base64 = fFile![0] != undefined || fFile![0] != null ? await convertBase64(fFile![0]) : '';            

            var data = {
                "unit_type": fUnitType,
                "total_units": fTotalUnits,
                // "bathrooms": fBathrooms,
                "price_min": fPriceMin,
                "price_max": fPriceMax,
                "builtup_area_min": fBuiltMin,
                "builtup_area_max": fBuiltMax,
                // "plot_area_min": fPlotMin,
                // "plot_area_max": fPlotMax,
                "local_currency": fLocalCurrency,
                "file": '',
            }
            featureData.push(data);
        }

        setPlanData(featureData);

    };
    const handleDocumentsave = async (index:any) => {
        // const newVisibilities = [...documentFile];
        // newVisibilities[index] = !newVisibilities[index];
        // setDocumentFile(newVisibilities);
        // setDocumentList([...documentList, { document: "" }]);
        // console.log(documentList);
        
        var featureData = [];

        for(let i = 1; i <= documentList.length; i++){

            let fUnitType = (document.getElementById('unit_type_'+i) as HTMLInputElement).value;
            let fTotalUnits = (document.getElementById('total_units_'+i) as HTMLInputElement).value;
            // let fBathrooms = (document.getElementById('bathrooms_'+i) as HTMLInputElement).value;
            let fPriceMin = (document.getElementById('price_min_'+i) as HTMLInputElement).value;
            let fPriceMax = (document.getElementById('price_max_'+i) as HTMLInputElement).value;
            let fBuiltMin = (document.getElementById('builtup_area_min_'+i) as HTMLInputElement).value;
            let fBuiltMax = (document.getElementById('builtup_area_max_'+i) as HTMLInputElement).value;
            // let fPlotMin = (document.getElementById('plot_area_min_'+i) as HTMLInputElement).value;
            // let fPlotMax = (document.getElementById('plot_area_max_'+i) as HTMLInputElement).value;
            let fLocalCurrency = (document.getElementById('local_currency_'+i) as HTMLInputElement).value;
            let fFile = (document.getElementById('file_'+i) as HTMLInputElement).files;

            console.log('fFile ----------')
            console.log(fFile![0])
            
            // let base64 = fFile![0] != undefined || fFile![0] != null ? await convertBase64(fFile![0]) : '';            

            var data = {
                "unit_type": fUnitType,
                "total_units": fTotalUnits,
                // "bathrooms": fBathrooms,
                "price_min": fPriceMin,
                "price_max": fPriceMax,
                "builtup_area_min": fBuiltMin,
                "builtup_area_max": fBuiltMax,
                // "plot_area_min": fPlotMin,
                // "plot_area_max": fPlotMax,
                "local_currency": fLocalCurrency,
                "file": '',
            }
            featureData.push(data);
        }

        setPlanData(featureData);

    };

    const openContactFrom = () => {
        console.log('weeerrr');
        document.getElementById('kt_property_close')?.click();
        document.getElementById('kt_contact_toggle')?.click();
    }

    const contactsList =  async () => {
        var userId = currentUser?.id;
        var roleId = currentUser?.designation;
        const contactsResponse = await getContacts(userId, roleId);
    
        console.log('leads contactsResponse');
        console.log(contactsResponse);
    
        var contactDropList = [];
    
        for(let i = 0; i < contactsResponse.length; i++) {
            var data = { "id": contactsResponse[i].id, "name": contactsResponse[i].first_name + ' '+ contactsResponse[i].last_name }
            contactDropList.push(data);
        }
    
        console.log(contactDropList);
        setContacts(contactDropList);
    }
    
    const availableForList = async () => {
        const lookingResponse = await getAvailableFor();
        setAvailableFor(lookingResponse);
    } 

    const segmentList = async () => {
        const segmentResponse = await getSegment();
        setSegment(segmentResponse);
    } 

    const assignToList =  async () => {   
        var userId = currentUser?.id;
        var roleId = currentUser?.designation; 
        const assignToResponse = await getAssignToList(userId, roleId);
        setAssignTo(assignToResponse);
    }

    const ownershipList =  async () => {   
        const cityResponse = await getOwnershipType();
        setOwnershipType(cityResponse);
    }

    const cityList =  async () => {   
        const cityResponse = await getCity();
        setCities(cityResponse);
    }

    const stateList =  async () => {   
        const stateResponse = await getState();
        setStates(stateResponse);
    }

    const countryList =  async () => {   
        const countryResponse = await getCountries();
        setCountries(countryResponse);
    }

    const projectStageList =  async () => {   
        const response = await getProjectStage();
        setProjectStages(response);
    }

    const ageOfPropList =  async () => {   
        const response = await getAgeOfProperty();
        setAgeOfProp(response);
    }

    // const specsList =  async () => {   
    //     const response = await getSpecification();
    //     setSpecifications(response);
    // }

    const siteVisitList =  async () => {   
        const response = await getSiteVisitPref();
        setSiteVisit(response);
    }

    const propertyTypeList = async () => {
        const response = await getPropertyType();
        setPropertyType(response);
    } 

    const propertyStatusList = async () => {
        const response = await getPropertyStatus();
        setPropertyStatus(response);
    } 

    const propertySourceList = async () => {
        const response = await getPropertySource();
        setPropertySource(response);
    }

    const propertyInDepthList = async () => {
        const response = await getPropertyInDepth();
        setPropertyInDepth(response);
    }

    const propertyFacingList = async () => {
        const response = await getPropertyFacing();
        setPropertyFacing(response);
    } 

    const keyCustodyList = async () => {
        const response = await getKeyCustody();
        setKeyCustody(response);
    } 

    const kitchenTypeList = async () => {
        const response = await getKitchenType();
        setKitchenType(response);
    } 

    const flooringList = async () => {
        const response = await getFlooring();
        setFlooring(response);
    } 

    const vasthuCompList = async () => {
        const response = await getVasthuComp();
        setVasthuComp(response);
    } 

    const amenitiesList =  async () => {   
        const amenityResponse = await getAmenity();
        setAmenities(amenityResponse);
    }

    const unitTypeList =  async () => {
        const unitResponse = await getUnitType();
        setUnitType(unitResponse);
    }

    const furnishStatusList =  async () => {   
        const furnishResponse = await getFurnishStatus();
        setFurnishStatus(furnishResponse);
    }

    const posesStatusList =  async () => {   
        const posessionResponse = await getPosesStatus();
        setPosesStatus(posessionResponse);
    }

    const currencyList =  async () => {   
        const posessionResponse = await getCurrency();
        setCurrency(posessionResponse);
    }

    const LegalApprovalList =  async () => {
        const LegalApprovalResponse = await getLegalApproval()
        setLegalApproval(LegalApprovalResponse);
    }

    const WaterSupplyList =  async () => {
        const WaterSupplyResponse = await getWaterSupply()
        setWaterSupply(WaterSupplyResponse);
    }


    const onPlanChange = async () => {
        var featureData = [];

        for(let i = 1; i <= documentList.length; i++){

            let fUnitType = (document.getElementById('unit_type_'+i) as HTMLInputElement).value;
            let fTotalUnits = (document.getElementById('total_units_'+i) as HTMLInputElement).value;
            // let fBathrooms = (document.getElementById('bathrooms_'+i) as HTMLInputElement).value;
            let fPriceMin = (document.getElementById('price_min_'+i) as HTMLInputElement).value;
            let fPriceMax = (document.getElementById('price_max_'+i) as HTMLInputElement).value;
            let fBuiltMin = (document.getElementById('builtup_area_min_'+i) as HTMLInputElement).value;
            let fBuiltMax = (document.getElementById('builtup_area_max_'+i) as HTMLInputElement).value;
            // let fPlotMin = (document.getElementById('plot_area_min_'+i) as HTMLInputElement).value;
            // let fPlotMax = (document.getElementById('plot_area_max_'+i) as HTMLInputElement).value;
            let fLocalCurrency = (document.getElementById('local_currency_'+i) as HTMLInputElement).value;
            let fFile = (document.getElementById('file_'+i) as HTMLInputElement).files;

            console.log('fFile ----------')
            console.log(fFile![0])
            
            let base64 = fFile![0] != undefined || fFile![0] != null ? await convertBase64(fFile![0]) : '';            

            var data = {
                "unit_type": fUnitType,
                "total_units": fTotalUnits,
                // "bathrooms": fBathrooms,
                "price_min": fPriceMin,
                "price_max": fPriceMax,
                "builtup_area_min": fBuiltMin,
                "builtup_area_max": fBuiltMax,
                // "plot_area_min": fPlotMin,
                // "plot_area_max": fPlotMax,
                "local_currency": fLocalCurrency,
                "file": '',
            }
            featureData.push(data);
        }

        setPlanData(featureData);
    }


    const handleClick = (index:any) => {
        setFiles([
            ...files.slice(0, index),
            ...files.slice(index + 1, files.length)
          ]);
    }
  
    const thumbs = files.map((file:any, index:any) => {
        const pieces = file.path.split('.');
        const last = pieces[pieces.length - 1];
        return(
        <div style={thumb} key={file.name} className="position-relative">
            <div style={thumbInner}>
            { last != 'pdf' ?
                <img
                src={file.preview}
                className='w-100 h-100 img-thumbnail rounded fit_contain'
                onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />: <img
                src={toAbsoluteUrl('/media/svg/files/pdf.svg')}
                className='w-100 h-100 img-thumbnail rounded fit_contain'
                />
                }
                
            </div>
            <a onClick={() => handleClick(index)} className='icon position-absolute top-0 end-0 rounded bg-gray border-0'><span className="svg-icon svg-icon-muted"><svg width="" height="30" viewBox="3 3 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
            <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)" fill="currentColor"/>
            <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)" fill="currentColor"/>
            </svg>
            </span></a>
            {/* <button
                style={thumbButton}
                onClick={() =>
                    editImage(file, (output:any) => {
                        const updatedFiles = [...files];

                        // replace original image with new image
                        updatedFiles[index] = output;

                        // revoke preview URL for old image
                        if (file.preview) URL.revokeObjectURL(file.preview);

                        // set new preview URL
                        Object.assign(output, {
                            preview: URL.createObjectURL(output),
                        });

                        // update view
                        setFiles(updatedFiles);
                    })
                }
            >
                Edit
            </button> */}
        </div>
    )});

    useEffect(() => {
        onPlanChange();
        contactsList();
        availableForList();
        segmentList();
        ownershipList();
        cityList();
        stateList();
        countryList();
        projectStageList();
        ageOfPropList();
        LegalApprovalList();
        siteVisitList();
        propertyTypeList();
        keyCustodyList();
        propertyInDepthList();
        assignToList();
        flooringList();
        vasthuCompList();
        amenitiesList();
        unitTypeList();
        furnishStatusList();
        posesStatusList();
        propertyStatusList();
        propertySourceList();
        propertyFacingList();
        kitchenTypeList();
        currencyList();
        WaterSupplyList();
    }, []);

    const handleOnSearch = (string:any, results:any) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        console.log(string, results)
    }

    const handleOnHover = (result:any) => {
        // the item hovered
        console.log(result)
    }

    const handleOnSelect = (item:any) => {
        // the item selected
        console.log(item)
    }

    const handleOnFocus = () => {
        console.log('Focused')
    }

    const formatResult = (item:any) => {
        return (
            <>
            {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
            <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
            </>
        )
    }

    // const handleChange = (event: SelectChangeEvent<typeof aminityName>) => {
    //     const {
    //         target: { value },
    //     } = event;
    //     setAminityName(
    //         // On autofill we get a stringified value.
    //         typeof value === 'string' ? value.split(',') : value,
    //     );
    // };

    const handleChange = (event: SelectChangeEvent<typeof aminityName>) => {
        const {
          target: { value },
        } = event;
    
        console.log('assign event');
        console.log(value);
    
        var name = [];
        var id = [];
    
        for(let i = 0; i < value.length; i++){
          var fields = value[i].split('-');
    
          var n = fields[0];
          var d = fields[1];
    
          name.push(n);
          id.push(d);
        }
    
        console.log(name);
        console.log(id);
    
        setAminityId(id);
    
        setAminityName(
          typeof value === 'string' ? value.split(',') : value,
        );
      };

    // const specsChange = (event: SelectChangeEvent<typeof specsName>) => {
    //     const {
    //         target: { value },
    //     } = event;
    //     setSpecsName(
    //         // On autofill we get a stringified value.
    //         typeof value === 'string' ? value.split(',') : value,
    //     );
    // };

    const specsChange = (event: SelectChangeEvent<typeof specsName>) => {
        const {
          target: { value },
        } = event;
    
        console.log('assign event');
        console.log(value);
    
        var name = [];
        var id = [];
    
        for(let i = 0; i < value.length; i++){
          var fields = value[i].split('-');
    
          var n = fields[0];
          var d = fields[1];
    
          name.push(n);
          id.push(d);
        }
    
        console.log(name);
        console.log(id);
    
        setSpecsId(id);
    
        setSpecsName(
          typeof value === 'string' ? value.split(',') : value,
        );
      };

    // const siteVisitChange = (event: SelectChangeEvent<typeof siteVisitName>) => {
    //     const {
    //         target: { value },
    //     } = event;
    //     setSiteVisitName(
    //         // On autofill we get a stringified value.
    //         typeof value === 'string' ? value.split(',') : value,
    //     );
    // };

    const siteVisitChange = (event: SelectChangeEvent<typeof siteVisitName>) => {
        const {
          target: { value },
        } = event;
    
        console.log('assign event');
        console.log(value);
    
        var name = [];
        var id = [];
    
        for(let i = 0; i < value.length; i++){
          var fields = value[i].split('-');
    
          var n = fields[0];
          var d = fields[1];
    
          name.push(n);
          id.push(d);
        }
    
        console.log(name);
        console.log(id);
    
        setSiteVisitId(id);
    
        setSiteVisitName(
          typeof value === 'string' ? value.split(',') : value,
        );
      };

    const furnishingChange = (event: SelectChangeEvent<typeof furnishName>) => {
        const {
            target: { value },
        } = event;
        setFurnishName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    // const availableChange = (event: SelectChangeEvent<typeof availableName>) => {
    //     const {
    //         target: { value },
    //     } = event;
    //     setAvailableName(
    //         // On autofill we get a stringified value.
    //         typeof value === 'string' ? value.split(',') : value,
    //     );
        
    // };

    const availableChange = (event: SelectChangeEvent<typeof availableName>) => {
        const {
          target: { value },
        } = event;
    
        console.log('assign event');
        console.log(value);
    
        var name = [];
        var id = [];
    
        for(let i = 0; i < value.length; i++){
          var fields = value[i].split('-');
    
          var n = fields[0];
          var d = fields[1];
    
          name.push(n);
          id.push(d);
        }
    
        console.log(name);
        console.log(id);
    
        setAvailableId(id);
    
        setAvailableName(
          typeof value === 'string' ? value.split(',') : value,
        );
      };

    //form submit
  const registrationSchema = Yup.object().shape({
    contact_id: Yup.string()
      .required('Contact name is required'),
    available_for: Yup.array(),
    // .required('Availble for is required'),
    commission: Yup.string(),
    property_indepth: Yup.string().required('Property In-depth is required'),
    segment: Yup.string(),
    property_type: Yup.string(),
    property_source: Yup.string(),
    property_status: Yup.string(),
    invoice_name: Yup.string(),
    gst: Yup.string(),
    legal_approval: Yup.string(),
    description: Yup.string(),
    project_stage: Yup.string(),
    age_of_property: Yup.string(),
    furnishing: Yup.string(),
    price_min: Yup.string(),
    price_max: Yup.string(),
    builtup_area_min: Yup.string(),
    builtup_area_max: Yup.string(),
    plot_area_min: Yup.string(),
    plot_area_max: Yup.string(),
    // specification: Yup.array(),
    uds: Yup.string(),
    no_of_floors: Yup.string(),
    ownership_type: Yup.string(),
    balcony: Yup.string(),
    property_facing: Yup.string(),
    kitchen_type: Yup.string(),
    flooring: Yup.string(),
    vasthu_compliant: Yup.string(),
    currently_under_loan: Yup.string(),
    available_from: Yup.string(),
    site_visit_preference: Yup.array(),
    key_custody: Yup.string(),
    no_of_car: Yup.string(),
    car_park_type: Yup.string(),
    water_suppply: Yup.string(),
    gated_community: Yup.string(),
    amenities: Yup.array(),
    property_highlight: Yup.string(),
    rera_registered: Yup.string(),
    rera_number: Yup.string(),
    completion_certificate: Yup.string(),
    name_of_building: Yup.string(),
    address_line1: Yup.string(),
    country: Yup.string(),
    state: Yup.string(),
    city: Yup.string(),
    locality: Yup.string(),
    pincode: Yup.string(),
    latitude: Yup.string(),
    longitude: Yup.string(),
    door_number: Yup.string(),
    module_number: Yup.string(),
  })

    const formik = useFormik({
        initialValues,
        validationSchema: registrationSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
          try {
    
            console.log('sddddddddassdsf');
            console.log(planData);

            var bathroomArray = [];
            var featureImagesArray = [];
            var builtupMinArray = [];
            var builtupMaxArray = [];
            var plotMinArray = [];
            var plotMaxArray = [];
            var salePriceMinArray = [];
            var salePriceMaxArray = [];
            var totalUnitsArray = [];
            var localCurrencyArray = [];
            var unitTypeArray = [];

            for(let i = 0; i < planData.length; i++){
                // var ggggg = planData[i]['file']
                // ggggg.push({fieldname: 'feature_images'})
                // if(planData[i]['file'] != null) {
                // planData[i]['file'].indexValue = i
                // }
            //    bathroomArray.push(planData[i]['bathrooms']);
               builtupMinArray.push(planData[i]['builtup_area_min']);
               builtupMaxArray.push(planData[i]['builtup_area_max']);
            //    plotMinArray.push(planData[i]['plot_area_min']);
            //    plotMaxArray.push(planData[i]['plot_area_max']);
               salePriceMinArray.push(planData[i]['price_min']);
               salePriceMaxArray.push(planData[i]['price_max']);
               totalUnitsArray.push(planData[i]['total_units']);
               unitTypeArray.push(planData[i]['unit_type']);
               localCurrencyArray.push(planData[i]['local_currency']);
               featureImagesArray.push(planData[i]['file']);
            }

            console.log(featureImagesArray);

            
            var formData = new FormData();
            
            for(let i = 0; i < files.length; i++){
                formData.append('images', files[i]);
            }
            for(let i = 0; i < planData.length; i++){
                // formData.append('bathrooms', bathroomArray[i]);
                formData.append('builtup_area_min', builtupMinArray[i]);
                formData.append('builtup_area_max', builtupMaxArray[i]);
                // formData.append('plot_area_min', plotMinArray[i]);
                // formData.append('plot_area_max', plotMaxArray[i]);
                formData.append('price_min', salePriceMinArray[i]);
                formData.append('price_max', salePriceMaxArray[i]);
                formData.append('total_units', totalUnitsArray[i]);
                formData.append('unit_type', unitTypeArray[i]);
                formData.append('local_currency', localCurrencyArray[i]);
                formData.append('feature_image', featureImagesArray[i]);
            }

            var userId = currentUser?.id.toString();
            var furnishData = furnishName.join(',');
            var aminityData = aminityName.join(',');
            var availableData = availableId.join(',');
            var specsIdData = specsId.join(',');
            var siteVisitData = siteVisitId.join(',');
            var aminityIdData = aminityId.join(',');
            var assigntoData = assignToId.join(',');

            formData.append('prop_unit_count', planData.length.toString());
            formData.append('contact_id', values.contact_id);
            formData.append('available_for', availableData);
            formData.append('assign_to', assigntoData);
            formData.append('commission', values.commission);
            formData.append('property_indepth', values.property_indepth);
            formData.append('segment', values.segment);
            formData.append('property_type', values.property_type);
            formData.append('property_source', values.property_source);
            formData.append('property_status', values.property_status);
            formData.append('description', values.description);
            formData.append('project_stage', values.project_stage);
            formData.append('age_of_property', values.age_of_property);
            formData.append('furnishing', values.furnishing);
            formData.append('builtup_area_min', values.builtup_area_min);
            formData.append('builtup_area_max', values.builtup_area_max);
            formData.append('plot_area_min', values.plot_area_min);
            formData.append('plot_area_max', values.plot_area_max);
            formData.append('uds', values.uds);
            formData.append('no_of_floors', values.no_of_floors);
            formData.append('tower', values.tower);
            formData.append('ownership_type', values.ownership_type);
            formData.append('balcony', values.balcony);
            formData.append('property_facing', values.property_facing);
            formData.append('kitchen_type', values.kitchen_type);
            formData.append('flooring', values.flooring);
            formData.append('vasthu_compliant', values.vasthu_compliant);
            formData.append('currently_under_loan', values.currently_under_loan);
            formData.append('available_from', values.available_from);
            formData.append('legal_approval', values.legal_approval);
            formData.append('invoice_name', values.invoice_name);
            formData.append('site_visit_preference', siteVisitData);
            formData.append('key_custody', values.key_custody);
            formData.append('gst', values.gst);
            formData.append('no_of_car', values.no_of_car);
            formData.append('car_park_type', values.car_park_type);
            formData.append('water_suppply', values.water_suppply);
            formData.append('gated_community', values.gated_community);
            formData.append('property_highlight', values.property_highlight);
            formData.append('rera_registered', values.rera_registered);
            formData.append('rera_number', values.rera_number);
            formData.append('completion_certificate', values.completion_certificate);
            formData.append('name_of_building', values.name_of_building);
            formData.append('address_line1', values.address_line1);
            formData.append('country', values.country);
            formData.append('state', values.state);
            formData.append('city', values.city);
            formData.append('locality', values.locality);
            formData.append('pincode', values.pincode);
            formData.append('latitude', values.latitude);
            formData.append('longitude', values.longitude);
            formData.append('door_number', values.door_number);
            formData.append('module_number', values.module_number);
            formData.append('amenities', aminityIdData);
            formData.append('created_by', userId!);
    
            const savePropertyData = await saveProperty(formData);
            console.log('formData')
            console.log(formData)
    
            console.log('savePropertyData');
            console.log(savePropertyData);
            document.getElementById('kt_contact')?.classList.remove('drawer-on');
            if(savePropertyData != null){
                setLoading(false);
                document.getElementById('kt_property_close')?.click();
                var toastEl = document.getElementById('myToastAdd');
                const bsToast = new Toast(toastEl!);
                bsToast.show();
                const characterResponse = await getProperties(userId, roleId)
                setProperty(characterResponse)
                resetForm();
                setAminityName([]);
                setAssignToName([]);
                setFurnishName([]);
                setAvailableName([]);
                setFiles([]);
                setPlanData([]);
                setDocumentList([{ document: "" }]);
            }
    
          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
        }})
    
      const theme = useTheme(); 

    return(
    <div className='card shadow-none rounded-0 w-100'>
        <div className='card-header w-100' id='kt_property_header'>
          <h3 className='card-title fw-bolder text-dark'>Add Project</h3>

          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-active-light-primary me-n5'
              id='kt_property_close'
            >
                <img src={toAbsoluteUrl('/media/custom/cancel_black.svg')} className="svg_icon" alt='' />
              {/* <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' /> */}
            </button>
          </div>
        </div>
        <div className='card-body position-relative' id='kt_property_body'>
            <form noValidate onSubmit={formik.handleSubmit}>
                <div className="accordion" id="prop_accordion"> 
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Basic Details
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#prop_accordion">
                            <div className="accordion-body">
                                <div className="row">
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label required">Contact Person</label>
                                        <div className="input-group mb-3">
                                            <div className='autocomplete_field'>
                                                <ReactSearchAutocomplete
                                                    items={contacts}
                                                    {...formik.getFieldProps('contact_id')}
                                                    onSearch={handleOnSearch}
                                                    onHover={handleOnHover}
                                                    // onSelect={handleOnSelect}
                                                    onSelect={(val) => formik.setFieldValue('contact_id', val.id ?? '')}
                                                    onFocus={handleOnFocus}
                                                    placeholder="choose contact"
                                                    styling={mystyle}
                                                    autoFocus
                                                    formatResult={formatResult}
                                                />
                                            </div>
                                            <button className="btn btn-outline-secondary addContact_btn" type="button" onClick={openContactFrom}>
                                                <i className="fa fa-plus-circle text_primary"></i>
                                            </button>
                                        </div>
                                        {formik.touched.contact_id && formik.errors.contact_id && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert' className='text-danger'>{formik.errors.contact_id}</span>
                                            </div>
                                        </div>
                                        )}
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Available for</label>
                                        {/* <div className="input-group mb-3 input_prepend"> */}
                                        <FormControl sx={{ m: 0, width: '100%', mt: 0 }}>
                                            <Select
                                                multiple
                                                displayEmpty
                                                value={availableName}
                                                onChange={availableChange}
                                                input={<OutlinedInput />}
                                                renderValue={(selected) => {
                                                    var name = [];
                                                    var id = [];

                                                    for(let i = 0; i < selected.length; i++){
                                                    var fields = selected[i].split('-');

                                                    var n = fields[0];
                                                    var d = fields[1];

                                                    name.push(n);
                                                    id.push(d);
                                                    }
                                                    if (selected.length === 0) {
                                                    return <p>Available For</p>;
                                                    }

                                                    return name.join(', ');
                                                }}
                                                className='multi_select_field'
                                                MenuProps={MenuProps}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                <MenuItem disabled value="">
                                                    <em>Available For</em>
                                                </MenuItem>
                                                {availableFor.map((available) => (
                                                    <MenuItem
                                                    key={available.id}
                                                    value={available.name+'-'+available.id}
                                                    style={getStyles(available.name, availableName, theme)}
                                                    >
                                                    {available.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Assign to</label>
                                        <FormControl sx={{ m: 0, width: '100%', mt: 0 }}>
                                            <Select
                                                multiple
                                                displayEmpty
                                                value={assignToName}
                                                onChange={assingToChange}
                                                input={<OutlinedInput />}
                                                renderValue={(selected) => {
                                                    var name = [];
                                                    var id = [];

                                                    for(let i = 0; i < selected.length; i++){
                                                    var fields = selected[i].split('-');

                                                    var n = fields[0];
                                                    var d = fields[1];

                                                    name.push(n);
                                                    id.push(d);
                                                    }

                                                    // setAssignToId(id);

                                                    console.log('selected');
                                                    console.log(selected);
                                                    if (selected.length === 0) {
                                                        return <p>Assign To</p>;
                                                    }

                                                    return name.join(', ');
                                                }}
                                                className='multi_select_field'
                                                MenuProps={MenuProps}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                <MenuItem disabled value="">
                                                    <em>Assign To</em>
                                                </MenuItem>
                                                {assignTo.map((assignVal) => (
                                                    <MenuItem
                                                    key={assignVal.user_id}
                                                    value={assignVal.employee_name+'-'+assignVal.user_id}
                                                    style={getStyles(assignVal.employee_name, assignToName, theme)}
                                                    >
                                                    {assignVal.employee_name}
                                                    </MenuItem>
                                                ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label required">Property Indepth</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <select {...formik.getFieldProps('property_indepth')}
                                                className={clsx(
                                                'btn_secondary btn btn-sm w-100',
                                                {
                                                    'is-invalid': formik.touched.property_indepth && formik.errors.property_indepth,
                                                },
                                                {
                                                    'is-valid': formik.touched.property_indepth && !formik.errors.property_indepth,
                                                }
                                                )} name="property_indepth">
                                                    <option value='0'>Select</option>
                                                {propertyInDepth.map((propertyInDepthVal,i) =>{
                                                return (
                                                    <option selected={i == 0 ? true: false} value={propertyInDepthVal.id} key={i}>{propertyInDepthVal.name}</option> 
                                                )})}
                                            </select>
                                        </div> 
                                        {formik.touched.property_indepth && formik.errors.property_indepth && (
                                            <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert' className='text-danger'>{formik.errors.property_indepth}</span>
                                            </div>
                                            </div>
                                        )} 
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Commission</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <input type="text" className="form-control" {...formik.getFieldProps('commission')} placeholder="0"/>
                                            <span className="input-group-text">%</span>
                                        </div>
                                    </div>  
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">GST</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <input type="text" className="form-control" {...formik.getFieldProps('gst')} placeholder="GST"/>
                                            
                                        </div>
                                    </div>  
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Invoicing Name</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <input type="text" className="form-control" {...formik.getFieldProps('invoice_name')} placeholder="Invoicing Name"/>
                                            
                                        </div>
                                    </div>  
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Segment</label>
                                        <div className="input-group mb-3 input_prepend py-1">
                                            <select className="btn btn-sm w-100 text-start form-select" {...formik.getFieldProps('segment')}>
                                            <option value='0'>Select</option>
                                                {segment.map((segmentVal,i) =>{
                                                    return (
                                                        <option value={segmentVal.id} key={i}>{segmentVal.name}</option> 
                                                )})}
                                            </select>
                                        </div> 
                                    </div> 
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Property Type</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('property_type')}>
                                            <option value='0'>Select</option>
                                                {propertyType.map((propertyTypeVal,i) =>{
                                                    return (
                                                        <option selected={i == 0 ? true: false} value={propertyTypeVal.id} key={i}>{propertyTypeVal.name}</option> 
                                                )})}
                                            </select>
                                        </div>  
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Property Status</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('property_status')}>
                                            <option value='0'>Select</option>
                                                {propertyStatus.map((propertyStatusVal,i) =>{
                                                    return (
                                                        <option selected={i == 0 ? true: false} value={propertyStatusVal.id} key={i}>{propertyStatusVal.name}</option> 
                                                )})}
                                            </select>
                                        </div> 
                                    </div> 
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Property Source</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('property_source')}>
                                            <option value='0'>Select</option>
                                                {propertySource.map((propertySourceVal,i) =>{
                                                    return (
                                                        <option selected={i == 0 ? true: false} value={propertySourceVal.id} key={i}>{propertySourceVal.name}</option> 
                                                )})}
                                            </select>
                                        </div> 
                                    </div> 
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Legal Approval</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('legal_approval')}>
                                            <option value='0'>Select</option>
                                                {legalApproval.map((Val,i) =>{
                                                    return (
                                                        <option selected={i == 0 ? true: false} value={Val.id} key={i}>{Val.name}</option> 
                                                )})}
                                            </select>
                                        </div> 
                                    </div> 
                                    {/* <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Property Construction Status</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <input type="text" className="form-control" {...formik.getFieldProps('property_construction_status')} placeholder="0"/>
                                        </div>
                                    </div>   */}
                                    {/* <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Status*</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <select className="btn_secondary btn btn-sm w-100">
                                                <option value="default" disabled>Select</option>
                                                <option>On Market</option>
                                                <option>Reserved</option>
                                                <option>Off Market</option>
                                                <option>Sold</option>
                                            </select>
                                        </div> 
                                    </div>  */}
                                    {/* <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Assign To*</label>
                                        <FormControl sx={{ m: 0, width: 300, mt: 0 }}>
                                            <Select
                                                multiple
                                                displayEmpty
                                                value={assignToName}
                                                onChange={assingToChange}
                                                input={<OutlinedInput />}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                    return <p>Assign To</p>;
                                                    }

                                                    return selected.join(', ');
                                                }}
                                                className='multi_select_field'
                                                MenuProps={MenuProps}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                <MenuItem disabled value="">
                                                    <em>Assign To</em>
                                                </MenuItem>
                                                {assignTo.map((assignVal) => (
                                                    <MenuItem
                                                    key={assignVal.id}
                                                    value={assignVal.id}
                                                    style={getStyles(assignVal.first_name+' '+assignVal.last_name, assignToName, theme)}
                                                    >
                                                    {assignVal.first_name+' '+assignVal.last_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>  */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingtwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#two" aria-expanded="false" aria-controls="two">
                            Project Address
                            </button>
                        </h2>
                        <div id="two" className="accordion-collapse collapse" aria-labelledby="headingtwo" data-bs-parent="#prop_accordion">
                            <div className="accordion-body">
                                <div className="row">
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Name of building</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <input type="text" className="form-control" {...formik.getFieldProps('name_of_building')}  placeholder="Enter building name"/>
                                        </div>
                                    </div> 
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Module Number</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <input type="text" className="form-control" {...formik.getFieldProps('module_number')}  placeholder="Enter Module Number"/>
                                        </div>
                                    </div> 
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Address line 1</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <input type="text" className="form-control" {...formik.getFieldProps('door_number')} placeholder="Enter Door Number"/>
                                        </div>
                                    </div> 
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Address line 2</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <input type="text" className="form-control" {...formik.getFieldProps('address_line1')} placeholder="Enter address line"/>
                                        </div>
                                    </div> 
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Locality</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <input type="text" className="form-control" {...formik.getFieldProps('locality')} placeholder="Enter Area Name"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">City</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <select 
                                            {...formik.getFieldProps('city')} 
                                            className="btn_secondary btn btn-sm w-100">
                                                <option value='0'>Select</option>
                                                {cities.map((cityVal,i) =>{
                                                    return (
                                                        <option selected={i == 0 ? true: false} value={cityVal.id} key={i}>{cityVal.name}</option> 
                                                )})}
                                            </select>
                                        </div>  
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">State</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <select 
                                            {...formik.getFieldProps('state')} 
                                            className="btn_secondary btn btn-sm w-100">
                                                <option value='0'>Select</option>
                                                {states.map((stateVal,i) =>{
                                                    return (
                                                        <option selected={i == 0 ? true: false} value={stateVal.id} key={i}>{stateVal.name}</option> 
                                                )})}
                                            </select>
                                        </div>  
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Country</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <select 
                                            {...formik.getFieldProps('country')} 
                                            className="btn_secondary btn btn-sm w-100">
                                                <option value='0'>Select</option>
                                                {countries.map((countryVal,i) =>{
                                                    return (
                                                        <option selected={i == 0 ? true: false} value={countryVal.id} key={i}>{countryVal.name}</option> 
                                                )})}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Zip Code</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <input type="text" className="form-control" {...formik.getFieldProps('pincode')}  placeholder="Zip Code"/>
                                        </div>
                                    </div> 
                                
                                    <div className="col-md-4 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Latitude</label>
                                        <div className="input-group mb-3 input_prepend mx-1">
                                            <input type="text" className="form-control" {...formik.getFieldProps('latitude')} placeholder="Latitude"/>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Longitude</label>
                                        <div className="input-group mb-3 input_prepend mx-1">
                                            <input type="text" className="form-control" {...formik.getFieldProps('longitude')} placeholder="Longitude"/>
                                        </div>
                                    </div> 
                                    {/* <div className="col-md-6 col-12 mb-3">
                                            <div className="input-group mb-3 input_prepend mx-1">
                                                <input type="text" className="form-control" placeholder="Google pin link"/>
                                            </div> */}
                                        {/* <button className='btn btn_primary btn-block'>Generate Latitude/Longitude</button> */}
                                    {/* </div>  */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingthree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#three" aria-expanded="false" aria-controls="three">
                                Project Features
                            </button>
                        </h2>
                        <div id="three" className="accordion-collapse collapse" aria-labelledby="headingthree" data-bs-parent="#prop_accordion">
                            <div className="accordion-body">
                                <div className="row">
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Project Stage</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <select 
                                            {...formik.getFieldProps('project_stage')} 
                                            className="btn_secondary btn btn-sm w-100">
                                                <option value='0'>Select</option>
                                                {projectStages.map((projectStageVal,i) =>{
                                                    return (
                                                        <option selected={i == 0 ? true: false} value={projectStageVal.id} key={i}>{projectStageVal.name}</option> 
                                                )})}
                                            </select>
                                        </div>
                                    </div> 
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Age of Project</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <select 
                                            {...formik.getFieldProps('age_of_property')} 
                                            className="btn_secondary btn btn-sm w-100">
                                                <option value='0'>Select</option>
                                                {ageOfProp.map((ageOfPropVal,i) =>{
                                                    return (
                                                        <option selected={i == 0 ? true: false} value={ageOfPropVal.id} key={i}>{ageOfPropVal.name}</option> 
                                                )})}
                                            </select>
                                        </div>
                                    </div> 
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Furnishing</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <select 
                                                {...formik.getFieldProps('furnishing')} 
                                                className="btn_secondary btn btn-sm w-100">
                                                    <option value='0'>Select</option>
                                                    {furnishStatus.map((furnishStatusVal,i) =>{
                                                        return (
                                                            <option selected={i == 0 ? true: false} value={furnishStatusVal.id} key={i}>{furnishStatusVal.name}</option> 
                                                    )})}
                                            </select>
                                        </div>
                                        {/* <FormControl sx={{ m: 0, width: 300, mt: 0 }}>
                                            <Select
                                                multiple
                                                displayEmpty
                                                value={furnishName}
                                                onChange={furnishingChange}
                                                input={<OutlinedInput />}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                    return <p>Furnishing</p>;
                                                    }

                                                    return selected.join(', ');
                                                }}
                                                className='multi_select_field'
                                                MenuProps={MenuProps}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                <MenuItem disabled value="">
                                                    <em>Furnishing</em>
                                                </MenuItem>
                                                {furnishStatus.map((furnish) => (
                                                    <MenuItem
                                                    key={furnish.id}
                                                    value={furnish.id}
                                                    style={getStyles(furnish.name, furnishName, theme)}
                                                    >
                                                    {furnish.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl> */}
                                    </div>  
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Project Tower</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <input type="text" className="form-control" {...formik.getFieldProps('tower')} placeholder="Enter project tower"/>
                                        </div>
                                    </div> 
                                    {/* <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Specifications</label>
                                        <FormControl sx={{ m: 0, width: '100%', mt: 0 }}>
                                            <Select
                                                multiple
                                                displayEmpty
                                                value={specsName}
                                                onChange={specsChange}
                                                input={<OutlinedInput />}
                                                renderValue={(selected) => {
                                                    var name = [];
                                                    var id = [];

                                                    for(let i = 0; i < selected.length; i++){
                                                    var fields = selected[i].split('-');

                                                    var n = fields[0];
                                                    var d = fields[1];

                                                    name.push(n);
                                                    id.push(d);
                                                    }
                                                    if (selected.length === 0) {
                                                    return <p>Specifications</p>;
                                                    }

                                                    return name.join(', ');
                                                }}
                                                className='multi_select_field'
                                                MenuProps={MenuProps}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                <MenuItem disabled value="">
                                                    <em>Specifications</em>
                                                </MenuItem>
                                                {specifications.map((specification) => (
                                                    <MenuItem
                                                    key={specification.id}
                                                    value={specification.name+'-'+specification.id}
                                                    style={getStyles(specification.name, specsName, theme)}
                                                    >
                                                    {specification.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>  */}
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">uds</label>
                                        <div className="input-group first mb-3 input_prepend">
                                            <input type="text" className="form-control" {...formik.getFieldProps('uds')} placeholder="Enter uds"/>
                                        </div>
                                    </div> 
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">No. of Floors</label>
                                        <div className="input-group first mb-3 input_prepend">
                                            <input type="text" className="form-control" {...formik.getFieldProps('no_of_floors')} placeholder="Enter no of floors"/>
                                        </div>
                                    </div> 
                                    {/* <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">No of Units</label>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="input-group first mb-3 input_prepend">
                                                    <input type="number" min="0" className="form-control" {...formik.getFieldProps('no_of_units_min')} placeholder="Min"/>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="input-group first mb-3 input_prepend">
                                                    <input type="number" min="0" className="form-control" {...formik.getFieldProps('no_of_units_max')} placeholder="Max"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Units Type</label>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="input-group first mb-3 input_prepend">
                                                    <input type="number" min="0" className="form-control" {...formik.getFieldProps('unit_type_min')} placeholder="Min"/>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="input-group first mb-3 input_prepend">
                                                    <input type="number" min="0" className="form-control" {...formik.getFieldProps('unit_type_max')} placeholder="Max"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>  */}
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Ownership Type</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <select 
                                            {...formik.getFieldProps('ownership_type')} 
                                            className="btn_secondary btn btn-sm w-100">
                                                <option value='0'>Select</option>
                                                {ownershipType.map((ownershipTypeVal,i) =>{
                                                    return (
                                                        <option selected={i == 0 ? true: false} value={ownershipTypeVal.id} key={i}>{ownershipTypeVal.name}</option> 
                                                )})}
                                            </select>
                                        </div>
                                    </div> 
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">No of Balcony</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <input type="number" min="0" className="form-control" {...formik.getFieldProps('balcony')} placeholder=""/>
                                        </div>
                                    </div> 
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Project Facing</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <select 
                                            {...formik.getFieldProps('property_facing')} 
                                            className="btn_secondary btn btn-sm w-100">
                                                <option value='0'>Select</option>
                                                {propertyFacing.map((propertyFacingVal,i) =>{
                                                    return (
                                                        <option selected={i == 0 ? true: false} value={propertyFacingVal.id} key={i}>{propertyFacingVal.name}</option> 
                                                )})}
                                            </select>
                                        </div>
                                    </div> 
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Kitchen Type</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <select 
                                            {...formik.getFieldProps('kitchen_type')} 
                                            className="btn_secondary btn btn-sm w-100">
                                                <option value='0'>Select</option>
                                                {kitchenType.map((kitchenTypeVal,i) =>{
                                                    return (
                                                        <option selected={i == 0 ? true: false} value={kitchenTypeVal.id} key={i}>{kitchenTypeVal.name}</option> 
                                                )})}
                                            </select>
                                        </div>
                                    </div> 
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Flooring</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <select 
                                            {...formik.getFieldProps('flooring')} 
                                            className="btn_secondary btn btn-sm w-100">
                                                <option value='0'>Select</option>
                                                {flooring.map((flooringVal,i) =>{
                                                    return (
                                                        <option selected={i == 0 ? true: false} value={flooringVal.id} key={i}>{flooringVal.name}</option> 
                                                )})}
                                            </select>
                                        </div>
                                    </div> 
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Vasthu Compliant</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <select 
                                            {...formik.getFieldProps('vasthu_compliant')} 
                                            className="btn_secondary btn btn-sm w-100">
                                                <option value='0'>Select</option>
                                                {vasthuComp.map((vasthuCompVal,i) =>{
                                                    return (
                                                        <option selected={i == 0 ? true: false} value={vasthuCompVal.id} key={i}>{vasthuCompVal.name}</option> 
                                                )})}
                                            </select>
                                        </div>
                                    </div> 
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Currenty under Loan</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('currently_under_loan')}>
                                            <option value='0'>Select</option>
                                                <option selected value="1">Yes</option>
                                                <option value="0">No</option>
                                            </select>
                                        </div>
                                    </div> 
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Available From</label>
                                        <div className="input-group mb-3">
                                            <input type="date" className="form-control" {...formik.getFieldProps('available_from')} placeholder="date"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Site Visit Preference</label>
                                        <FormControl sx={{ m: 0, width: '100%', mt: 0 }}>
                                            <Select
                                                multiple
                                                displayEmpty
                                                value={siteVisitName}
                                                onChange={siteVisitChange}
                                                input={<OutlinedInput />}
                                                renderValue={(selected) => {
                                                    var name = [];
                                                    var id = [];

                                                    for(let i = 0; i < selected.length; i++){
                                                    var fields = selected[i].split('-');

                                                    var n = fields[0];
                                                    var d = fields[1];

                                                    name.push(n);
                                                    id.push(d);
                                                    }
                                                    if (selected.length === 0) {
                                                    return <p>Site Visit Preferences</p>;
                                                    }

                                                    return name.join(', ');
                                                }}
                                                className='multi_select_field'
                                                MenuProps={MenuProps}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                <MenuItem disabled value="">
                                                    <em>Site Visit Preferences</em>
                                                </MenuItem>
                                                {siteVisit.map((siteVisitVal) => (
                                                    <MenuItem
                                                    key={siteVisitVal.id}
                                                    value={siteVisitVal.name+'-'+siteVisitVal.id}
                                                    style={getStyles(siteVisitVal.name, siteVisitName, theme)}
                                                    >
                                                    {siteVisitVal.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        {/* <div className="input-group mb-3">
                                            <input type="date" className="form-control" {...formik.getFieldProps('site_visit_preference')} placeholder="date"/> 
                                        </div> */}
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Key Custody</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <select 
                                            {...formik.getFieldProps('key_custody')} 
                                            className="btn_secondary btn btn-sm w-100">
                                                <option value='0'>Select</option>
                                                {keyCustody.map((keyCustodyVal,i) =>{
                                                    return (
                                                        <option selected={i == 0 ? true: false} value={keyCustodyVal.id} key={i}>{keyCustodyVal.name}</option> 
                                                )})}
                                            </select>
                                        </div>
                                    </div> 
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">No.of Car Park</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <input type="text" className="form-control" {...formik.getFieldProps('no_of_car')} placeholder=""/>
                                        </div>
                                    </div> 
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Car Park Type</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('car_park_type')}>
                                            <option value='0'>Select</option>
                                                <option selected value="1">Closed</option>
                                                <option value="0">Openned</option>
                                            </select>
                                        </div>
                                    </div> 
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Water Supply</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('water_supply')}>
                                            <option value='0'>Select</option>
                                            {waterSupply.map((Val,i) =>{
                                                    return (
                                                        <option selected={i == 0 ? true: false} value={Val.id} key={i}>{Val.name}</option> 
                                                )})}
                                            </select>
                                        </div>
                                    </div> 
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Gated Community</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('gated_community')}>
                                            <option value='0'>Select</option>
                                                <option selected value="1">Yes</option>
                                                <option value="0">No</option>
                                            </select>
                                        </div>
                                    </div> 
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Amenities</label>
                                        <FormControl sx={{ m: 0, width: '100%', mt: 0 }}>
                                            <Select
                                                multiple
                                                displayEmpty
                                                value={aminityName}
                                                onChange={handleChange}
                                                input={<OutlinedInput />}
                                                renderValue={(selected) => {
                                                    var name = [];
                                                    var id = [];

                                                    for(let i = 0; i < selected.length; i++){
                                                        var fields = selected[i].split('-');

                                                        var n = fields[0];
                                                        var d = fields[1];

                                                        name.push(n);
                                                        id.push(d);
                                                    }
                                                    if (selected.length === 0) {
                                                    return <p>Amenities</p>;
                                                    }

                                                    return name.join(', ');
                                                }}
                                                MenuProps={MenuProps}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                <MenuItem disabled value="">
                                                    <em>Amenities</em>
                                                </MenuItem>
                                                {amenities.map((amenity) => (
                                                    <MenuItem
                                                    key={amenity.id}
                                                    value={amenity.name+'-'+amenity.id}
                                                    style={getStyles(amenity.name, aminityName, theme)}
                                                    >
                                                    {amenity.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div> 
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Property Highlight</label>
                                        <div className="input-group first mb-3 input_prepend">
                                            <input type="text" className="form-control" {...formik.getFieldProps('property_highlight')} placeholder="Enter Property Highlight"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">RERA Registered</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <select className="btn_secondary btn btn-sm w-100" {...formik.getFieldProps('rera_registered')} onChange={(e) => setrera(e.target.value)} value={rera}>
                                            <option value=''>Select</option>
                                                <option value="1">Yes</option>
                                                <option value="0">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    {rera == 1 && 
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">RERA Number</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <input type="text" className="form-control" {...formik.getFieldProps('rera_number')} placeholder="Enter RERA Number"/>
                                        </div>
                                    </div> 
                                    }
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Completion Certificate</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <input type="text" className="form-control" {...formik.getFieldProps('completion_certificate')} placeholder="Enter Completion Certificate"/>
                                        </div>
                                    </div> 
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Project Plot Area</label>
                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                <div className="input-group first mb-3 input_prepend">
                                                    <input type="number" min="0" {...formik.getFieldProps('plot_area_min')} className="form-control" placeholder="Min"/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="input-group first mb-3 input_prepend">
                                                    <input type="number" min="0" {...formik.getFieldProps('plot_area_max')} className="form-control" placeholder="Max"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Units Type</label>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="input-group first mb-3 input_prepend">
                                                    <input type="number" min="0" className="form-control" {...formik.getFieldProps('unit_type_min')} placeholder="Min"/>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="input-group first mb-3 input_prepend">
                                                    <input type="number" min="0" className="form-control" {...formik.getFieldProps('unit_type_max')} placeholder="Max"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">No.of Units</label>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="input-group first mb-3 input_prepend">
                                                    <input type="number" min="0" {...formik.getFieldProps('no_of_units_min')} className="form-control" placeholder="Min"/>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="input-group first mb-3 input_prepend">
                                                    <input type="number" min="0" {...formik.getFieldProps('no_of_units_max')} className="form-control" placeholder="Max"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="card property_units_card">
                                        <div className="card-body p-3">
                                            {documentList.map((singleService, index) => { 
                                                var i = index + 1;
                                                return (
                                                    <div className="card my-2 bs_1 p-4" key={singleService.id}>
                                                        <div className='row'>
                                                        <div className="col-md-6 col-12 mb-3">
                                                            <label htmlFor="basic-url" className="form-label">Unit Type</label>
                                                            <div className="input-group mb-3 input_prepend">
                                                                <select 
                                                                {...formik.getFieldProps('unit_type'+i)} 
                                                                onChange={onPlanChange}
                                                                id={'unit_type_'+i}
                                                                className="btn_secondary btn btn-sm w-100">
                                                                    <option value='0'>Select</option>
                                                                    {unitType.map((unitTypeVal,j) =>{
                                                                        return (
                                                                            <option selected={j == 0 ? true: false} value={unitTypeVal.id} key={unitTypeVal.id}>{unitTypeVal.name}</option> 
                                                                    )})}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-12 mb-3">
                                                            <label htmlFor="basic-url" className="form-label">Builtup Area</label>
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <div className="input-group first mb-3 input_prepend">
                                                                        <input type="number" min="0" id={'builtup_area_min_'+i} {...formik.getFieldProps('builtup_area_min'+i)} className="form-control" placeholder="Min"/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <div className="input-group first mb-3 input_prepend">
                                                                        <input type="number" min="0" id={'builtup_area_max_'+i} {...formik.getFieldProps('builtup_area_max'+i)} className="form-control" placeholder="Max"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-md-6 col-12 mb-3">
                                                            <label htmlFor="basic-url" className="form-label">Project Plot Area</label>
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <div className="input-group first mb-3 input_prepend">
                                                                        <input type="number" min="0" id={'plot_area_min_'+i} {...formik.getFieldProps('plot_area_min'+i)} className="form-control" placeholder="Min"/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <div className="input-group first mb-3 input_prepend">
                                                                        <input type="number" min="0" id={'plot_area_max_'+i} {...formik.getFieldProps('plot_area_max'+i)} className="form-control" placeholder="Max"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        {/* <div className="col-md-6 col-12 mb-3">
                                                            <label htmlFor="basic-url" className="form-label">Unit Type</label>
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <div className="input-group first mb-3 input_prepend">
                                                                        <input type="number" min="0" onKeyUp={onPlanChange} className="form-control" id={'min_'+i} {...formik.getFieldProps('min'+i)} placeholder="Min"/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <div className="input-group first mb-3 input_prepend">
                                                                        <input type="number" min="0" onKeyUp={onPlanChange} className="form-control" id={'max_'+i} {...formik.getFieldProps('max'+i)} placeholder="Max"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        <div className="col-md-6 col-12 mb-3">
                                                            <div className="row">
                                                                <div className="col-md-6 col-12">
                                                                    <label htmlFor="basic-url" className="form-label">Total No.of Units</label>
                                                                    <div className="input-group mb-3 input_prepend">
                                                                        <input type="text" onKeyUp={onPlanChange} className="form-control" id={'total_units_'+i} {...formik.getFieldProps('total_units'+i)} placeholder="Total Units"/>
                                                                    </div>
                                                                </div> 
                                                                <div className="col-md-6 col-12 mb-3">
                                                                    <label htmlFor="basic-url" className="form-label">Local Currency</label>
                                                                    <div className="input-group mb-3 input_prepend">
                                                                        <select 
                                                                        id={'local_currency_'+i}
                                                                        {...formik.getFieldProps('local_currency'+i)} 
                                                                        onChange={onPlanChange}
                                                                        className="btn_secondary btn btn-sm w-100">
                                                                            {currency.map((currencyVal,j) =>{
                                                                                return (
                                                                                    <option value={currencyVal.id} key={currencyVal.id}>{currencyVal.name}</option> 
                                                                            )})}
                                                                        </select>
                                                                    </div>
                                                                </div> 
                                                                {/* <div className="col-md-6 col-12">
                                                                    <label htmlFor="basic-url" className="form-label">files</label>
                                                                    <div className="input-group mb-3 input_prepend">
                                                                    <input type="text" id={'myfiles_'+i} {...formik.getFieldProps('myfiles'+i)} className="form-control" placeholder="Enter Bathrooms"/>
                                                                    </div>
                                                                </div>  */}
                                                            </div> 
                                                        </div> 
                                                        <div className="col-md-6 col-12 mb-3">
                                                            <label htmlFor="basic-url" className="form-label">Sale Price</label>
                                                            <div className="row">
                                                                <div className="col-md-6 col-12">
                                                                    <div className="input-group first mb-3 input_prepend">
                                                                        {/* <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                                                            <option>₹</option>
                                                                            <option>$</option>
                                                                        </select> */}
                                                                        <input type="text" id={'price_min_'+i} className="form-control" {...formik.getFieldProps('price_min'+i)} placeholder="Min Sale Price"/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 col-12">
                                                                    <div className="input-group first mb-3 input_prepend">
                                                                        {/* <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                                                            <option>₹</option>
                                                                            <option>$</option>
                                                                        </select> */}
                                                                        <input type="text" id={'price_max_'+i} className="form-control" {...formik.getFieldProps('price_max'+i)} placeholder="Max Sale Price" onChange={() => handleDocumentsave(i)}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="d-flex justify-content-center">
                                                                <div id={'file_img_sec_'+i} className='d-none profile_preview position-relative image-input image-input-outline'>
                                                                    <img id={'file_img_'+i} className="image-input-wrapper w-125px h-125px" src="" alt="/" />
                                                                    {/* <div onClick={removeProfile} className="p-1">
                                                                        <KTSVG
                                                                            path='/media/icons/duotune/general/gen040.svg'
                                                                            className='svg-icon-3 cursor_pointer bg-white position-absolute p-0 top-0 m-2 end-0 rounded-circle svg-icon-danger pe-auto ms-2'
                                                                        />
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="d-flex justify-content-center">
                                                                <div id={'file_img_sec_'+ i} className='d-none profile_preview position-relative image-input image-input-outline'>
                                                                    <img id={'file_img_' + i} className="image-input-wrapper w-125px h-125px" src="" alt="/" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 d-flex justify-content-between align-items-center">
                                                            <span onDrop={(e:any) => processFile(e, i)} onDragOver={(e:any) => allowDrop(e, i)} className="btn btn-file d-flex flex-md-row p-2 p-md-4 d-none">
                                                                <i className="fa fa-upload my-2" aria-hidden="true"></i>Upload Floor Plan <input id={'file_'+i} {...formik.getFieldProps('file'+i)} ref={profileView} onChange={(e:any) => handleFilePreview(e, i)} type="file"/>
                                                            </span>
                                                            {documentList.length !== 1 && (
                                                            <button className="btn btn-sm btn_soft_primary ps-5 pe-4" onClick={() => handleDocumentRemove(i)}>
                                                                <i className="fa fa-minus text_primary" aria-hidden="true"></i>
                                                            </button>
                                                            )}
                                                            {documentList.length - 1 === index && (
                                                            <button className="btn btn-sm btn_soft_primary ps-5 pe-4" onClick={()=>handleDocumentAdd(i)}>
                                                                <i className="fa fa-plus text_primary" aria-hidden="true"></i>
                                                            </button>
                                                            )}
                                                        </div>
                                                        <small className='text_primary pt-1 d-none'>* Note: jpg, jpeg, pdf only acceptable</small>
                                                    </div>
                                                    </div>
                                                )
                                            })}
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="accordion-item">
                        <h2 className="accordion-header" id="headingfour">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#four" aria-expanded="false" aria-controls="four">
                                Unit, Price & Area
                            </button>
                        </h2>
                        <div id="four" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#prop_accordion">
                            <div className="accordion-body">
                                <div className="row">
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Sale Price*</label>
                                        <div className="input-group first mb-3 input_prepend">
                                            <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                                <option>₹</option>
                                                <option>$</option>
                                            </select>
                                            <input type="text" className="form-control" {...formik.getFieldProps('sale_price')} placeholder="Enter Sale Price"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Project Builtup Area</label>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="input-group first mb-3 input_prepend">
                                                    <input type="number" min="0" {...formik.getFieldProps('builtup_area_min')} className="form-control" placeholder="Min"/>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="input-group first mb-3 input_prepend">
                                                    <input type="number" min="0" {...formik.getFieldProps('builtup_area_max')} className="form-control" placeholder="Max"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Project Plot Area</label>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="input-group first mb-3 input_prepend">
                                                    <input type="number" min="0" {...formik.getFieldProps('plot_area_min')} className="form-control" placeholder="Min"/>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="input-group first mb-3 input_prepend">
                                                    <input type="number" min="0" {...formik.getFieldProps('plot_area_max')} className="form-control" placeholder="Max"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="accordion-item">
                        <h2 className="accordion-header" id="headingfour">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#four" aria-expanded="false" aria-controls="four">
                            Unit,Price & Area
                            </button>
                        </h2>
                        <div id="four" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#prop_accordion">
                            <div className="accordion-body">
                                <div className="row">
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Sale Price*</label>
                                        <div className="input-group first mb-3 input_prepend">
                                            <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                                <option>₹</option>
                                                <option>$</option>
                                            </select>
                                            <input type="text" className="form-control" placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Plot Area*</label>
                                        <div className="input-group first mb-3 input_prepend">
                                            <input type="text" className="form-control" placeholder="Parkings"/>
                                            <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                                <option>Sqft</option>
                                                <option>ft</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Built Area*</label>
                                        <div className="input-group first mb-3 input_prepend">
                                            <input type="text" className="form-control" placeholder="Energy Rating(Consumption)"/>
                                            <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                                <option>Sqft</option>
                                                <option>ft</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingfive">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#five" aria-expanded="false" aria-controls="five">
                                Description
                            </button>
                        </h2>
                        <div id="five" className="accordion-collapse collapse" aria-labelledby="headingfive" data-bs-parent="#prop_accordion">
                            <div className="accordion-body">
                                <div className="row">
                                    <div className="col-md-12 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Description</label>
                                        <textarea
                                            className='form-control border-0 p-2 resize-none min-h-25px br_10'
                                            data-kt-autosize='true'
                                            {...formik.getFieldProps('description')} 
                                            rows={7}
                                            placeholder='Description'
                                        ></textarea>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingsix">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#six" aria-expanded="false" aria-controls="six">
                        Project Images,Floor Plan & Documents
                            </button>
                        </h2>
                        <div id="six" className="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#prop_accordion">
                            <div className="accordion-body">
                            <div {...getRootProps({className: 'dropzone'})}>
                                            <div className='myDIV'>
                                                {/* <div className='d-flex align-items-center justify-content-center h-100 vh-25'>
                                                    <input {...getInputProps()} />
                                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                                </div> */}
                                                <div className="d-flex align-items-center justify-content-center w-100 h-100 vh-25">
                                                    <span className="btn btn-file w-100 h-100">
                                                        {/* <i className="fa fa-upload my-2" aria-hidden="true"></i> */}
                                                        <KTSVG
                                                        path='/media/icons/duotune/files/fil022.svg'
                                                        className='svg-icon-1 text_primary ms-2'
                                                        />
                                                        <p className='text_primary'>Upload Files Here</p>
                                                        <small className='text-dark'>* Note: jpg, jpeg, pdf only acceptable</small>
                                                        <input {...getInputProps()}/>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <aside style={thumbsContainer}>
                                            {thumbs}
                                        </aside>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-footer py-5 text-center' id='kt_contact_footer'>
                    <button
                    type='submit'
                    id='kt_sign_up_submit'
                    className='btn btn_primary text-primary'
                    disabled={formik.isSubmitting}
                    >
                    {!loading && <span className='indicator-label'>Submit
                    <KTSVG
                    path='/media/custom/save_white.svg'
                    className='svg-icon-3 svg-icon-primary ms-2'
                    />
                    </span>}
                    {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                    </button>
                    
                </div>
            </form>
        </div>
    </div>
    )
}


export {ProjectForm}