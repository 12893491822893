import React,{FC, useEffect, useState} from 'react';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { DashboardPieChart } from '../dashboard/pieChart';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import { right } from '@popperjs/core';
import { getCountCitywiseProperty, getCountProperty, getCountPropertyType, getCountSegmentProperty, getCountStatusProperty } from './core/_request';

export const options2 = {
    plugins: {
        legend: {
            display: true,
            position: right,
            labels: {
                color: '#000',
                fontSize:'50',
            },
        }
    }
};

export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
      //   text: 'Chart.js Bar Chart',
      },
    },
    
  };

const ProjectLead: FC = () => {

    const [countProperty, setCountProperty] = useState<any[]>([]);
    const [countStatusProperty, setCountStatusProperty] = useState<any[]>([]);
    const [countCitywiseProperty, setCountCitywiseProperty] = useState<any[]>([]);
    const [countSegmentProperty, setCountSegmentProperty] = useState<any[]>([]);
    const [countPropertyType, setCountPropertyType] = useState<any[]>([]);
        
    const CountPropertyList =  async () => {    
        const CountPropertyResponse = await getCountProperty()
        setCountProperty(CountPropertyResponse);   
    }

    const CountStatusPropertyList =  async () => {    
        const CountStatusPropertyResponse = await getCountStatusProperty()
        setCountStatusProperty(CountStatusPropertyResponse);   
    }

    const CountCitywisePropertyList =  async () => {    
        const CountCitywisePropertyResponse = await getCountCitywiseProperty()
        setCountCitywiseProperty(CountCitywisePropertyResponse);   
    }

    const CountSegmentPropertyList =  async () => {    
        const CountSegmentPropertyResponse = await getCountSegmentProperty()
        setCountSegmentProperty(CountSegmentPropertyResponse);   
    }
    
    const CountPropertyTypeList =  async () => {    
        const CountPropertyTypeResponse = await getCountPropertyType()
        setCountPropertyType(CountPropertyTypeResponse);   
    }
    
    const countPropertyLabels = countProperty.map((obj) => Object.values(obj)[0]);
    const countPropertybar = countProperty.map((obj) => Object.values(obj)[1]);
    
    const databarcountProperty = {
    labels: countPropertyLabels,
    datasets: [{
        label: 'Citywise Lead',
        backgroundColor: '#3bb6f1',
        borderColor: 'rgb(255, 99, 132)',
        data: countPropertybar,
    }]
    };
    // const databarcountProperty = {
    //     labels: countPropertyLabels,
    //     datasets: [
    //       {
    //         label: '# of Votes',
    //         data: countPropertybar,
    //         backgroundColor: [
    //             '#e27e0c',
    //             '#ff6700',
    //             '#208ae6',
    //             '#03a128',
    //             '#f37485',
    //             '#0c4688',
    //         ],
    //         borderColor: [
    //             '#fff',
    //             '#fff',
    //             '#fff',
    //             '#fff',
    //             '#fff',
    //             '#fff',
    //         ],
    //         borderWidth: 6,
    //       },
    //     ],
    //   };

    const countStatusPropertyLabels = countStatusProperty.map((obj) => Object.values(obj)[1]);
    const countStatusPropertybar = countStatusProperty.map((obj) => Object.values(obj)[0]);
    
    // const databarcountStatusProperty = {
    // labels: countStatusPropertyLabels,
    // datasets: [{
    //     label: 'Count Property Type',
    //     backgroundColor: '#3bb6f1',
    //     borderColor: 'rgb(255, 99, 132)',
    //     data: countStatusPropertybar,
    // }]
    // };
    const databarcountStatusProperty = {
        labels: countStatusPropertyLabels,
        datasets: [
          {
            label: '# of Votes',
            data: countStatusPropertybar,
            backgroundColor: [
                '#e27e0c',
                '#ff6700',
                '#208ae6',
                '#03a128',
                '#f37485',
                '#0c4688',
            ],
            borderColor: [
                '#fff',
                '#fff',
                '#fff',
                '#fff',
                '#fff',
                '#fff',
            ],
            borderWidth: 6,
          },
        ],
      };

    const countCitywisePropertyLabels = countCitywiseProperty.map((obj) => Object.values(obj)[0]);
    const countCitywisePropertybar = countCitywiseProperty.map((obj) => Object.values(obj)[1]);
   
    // const databarcountCitywiseProperty = {
    // labels: countCitywisePropertyLabels,
    // datasets: [{
    //     label: 'Count Property Type',
    //     backgroundColor: '#3bb6f1',
    //     borderColor: 'rgb(255, 99, 132)',
    //     data: countCitywisePropertybar,
    // }]
    // };
    const databarcountCitywiseProperty = {
        labels: countCitywisePropertyLabels,
        datasets: [
          {
            label: '# of Votes',
            data: countCitywisePropertybar,
            backgroundColor: [
                '#e27e0c',
                '#ff6700',
                '#208ae6',
                '#03a128',
                '#f37485',
                '#0c4688',
            ],
            borderColor: [
                '#fff',
                '#fff',
                '#fff',
                '#fff',
                '#fff',
                '#fff',
            ],
            borderWidth: 6,
          },
        ],
      };

    const countSegmentPropertyLabels = countSegmentProperty.map((obj) => Object.values(obj)[1]);
    const countSegmentPropertybar = countSegmentProperty.map((obj) => Object.values(obj)[0]);
    
    // const databarcountSegmentProperty = {
    // labels: countSegmentPropertyLabels,
    // datasets: [{
    //     label: 'Count Property Type',
    //     backgroundColor: '#3bb6f1',
    //     borderColor: 'rgb(255, 99, 132)',
    //     data: countSegmentPropertybar,
    // }]
    // };
    const databarcountSegmentProperty = {
        labels: countSegmentPropertyLabels,
        datasets: [
          {
            label: '# of Votes',
            data: countSegmentPropertybar,
            backgroundColor: [
                '#e27e0c',
                '#ff6700',
                '#208ae6',
                '#03a128',
                '#f37485',
                '#0c4688',
            ],
            borderColor: [
                '#fff',
                '#fff',
                '#fff',
                '#fff',
                '#fff',
                '#fff',
            ],
            borderWidth: 6,
          },
        ],
      };

    const countPropertyTypeLabels = countPropertyType.map((obj) => Object.values(obj)[1]);
    const countPropertyTypebar = countPropertyType.map((obj) => Object.values(obj)[0]);
    
    // const databarcountPropertyType = {
    // labels: countPropertyTypeLabels,
    // datasets: [{
    //     label: 'Count Property Type',
    //     backgroundColor: '#3bb6f1',
    //     borderColor: 'rgb(255, 99, 132)',
    //     data: countPropertyTypebar,
    // }]
    // };
    const databarcountPropertyType = {
        labels: countPropertyTypeLabels,
        datasets: [
          {
            label: '# of Votes',
            data: countPropertyTypebar,
            backgroundColor: [
                '#e27e0c',
                '#ff6700',
                '#208ae6',
                '#03a128',
                '#f37485',
                '#0c4688',
            ],
            borderColor: [
                '#fff',
                '#fff',
                '#fff',
                '#fff',
                '#fff',
                '#fff',
            ],
            borderWidth: 6,
          },
        ],
      };

    useEffect(() => {
        CountPropertyList();
        CountStatusPropertyList();
        CountCitywisePropertyList();
        CountSegmentPropertyList();
        CountPropertyTypeList();
    }, []);

    return(
        <section className='report_lead'>
            <div className="row">
                <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-4 pb-5">
                    <div className="card h-100 bg-light type_card">
                        <div className="card-header">
                            <div className="input-group form_search me-3">
                                <input type="text" className="form-control" placeholder="Search"/>
                                <div className="input-group-append">
                                    <button className="btn btn-secondary" type="button">
                                    <img src={toAbsoluteUrl('/media/custom/header-icons/search.svg')} className="svg_icon" alt='' />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body px-4">
                            <ul className="nav nav-pills masters_tab d-block px-3 border-0" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className='text-dark nav-link active' id="siteinspection-tab" data-bs-toggle="pill" data-bs-target="#siteinspection" type="button" role="tab" aria-controls="siteinspection" aria-selected="true">
                                    
                                    Site Inspection Report
                                    </a>                                     
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className='text-dark nav-link' id="leadprojectwise-tab" data-bs-toggle="pill" data-bs-target="#leadprojectwise" type="button" role="tab" aria-controls="leadprojectwise" aria-selected="true">
                                    
                                    Project Wise Report
                                    </a>                                     
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className='text-dark nav-link' id="citywiseproject-tab" data-bs-toggle="pill" data-bs-target="#citywiseproject" type="button" role="tab" aria-controls="citywiseproject" aria-selected="true">
                                    
                                    City wise Project Report
                                    </a>                                     
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className='text-dark nav-link' id="builderwise-tab" data-bs-toggle="pill" data-bs-target="#builderwise" type="button" role="tab" aria-controls="builderwise" aria-selected="true">
                                    
                                    Builder Wise Report
                                    </a>                                     
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className='text-dark nav-link' id="configurationwie-tab" data-bs-toggle="pill" data-bs-target="#configurationwise" type="button" role="tab" aria-controls="configurationwise" aria-selected="true">
                                    
                                    Configuration Wise Report
                                    </a>                                     
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className='text-dark nav-link' id="propertytypewise-tab" data-bs-toggle="pill" data-bs-target="#propertytypewise" type="button" role="tab" aria-controls="propertytypewise" aria-selected="true">
                                    
                                    Property Type Wise Report
                                    </a>                                     
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className='text-dark nav-link' id="projectstatus-tab" data-bs-toggle="pill" data-bs-target="#projectstatus" type="button" role="tab" aria-controls="projectstatus" aria-selected="true">
                                    
                                    Project Status Report
                                    </a>                                     
                                </li>                                
                                <li className="nav-item" role="presentation">
                                    <a className='text-dark nav-link' id="projectsegment-tab" data-bs-toggle="pill" data-bs-target="#projectsegment" type="button" role="tab" aria-controls="projectsegment" aria-selected="true">
                                    
                                    Project Segment
                                    </a>                                     
                                </li>                                
                            </ul>                            
                        </div>
                    </div>
                </div>
                <div className="col-xxl-9 col-xl-8 col-lg-8 col-md-8 col-sm-8">                    
                    <div className="card-group">
                    <div className="tab-content w-100" id="pills-tabContent">
                        <div  className="tab-pane fade show active" id="siteinspection" role="tabpanel" aria-labelledby="siteinspection-tab">
                            <div className="row">
                                <div className="col-xxl-4 col-xl-6 col-lg-8 col-md-10 col-sm-12 mb-4">                                
                                    <div className="card h-100 bs_1 mx-3">
                                        <div className="card-header">
                                            <h4>Site Inspection Report</h4>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <div className="d-flex me-3">
                                                    <i className="fa fa-star me-2 text-warning" aria-hidden="true"></i>
                                                    <i className="fa fa-download me-2" aria-hidden="true"></i>
                                                </div>
                                                <select className="form-select dash_btn me-2 mb-1">
                                                    <option selected>Teams</option>
                                                    <option value="1">Brigade Sales Team</option>
                                                    <option value="1">Godrej Sales Team</option>
                                                    <option value="1">Shriram Sales Team</option>
                                                </select>                                                
                                            </div>
                                        </div>
                                        <div className="card-body p-3">
                                            <DashboardPieChart/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                                                   
                        <div  className="tab-pane fade" id="leadprojectwise" role="tabpanel" aria-labelledby="leadprojectwise-tab">
                            <div className="row">                          
                                <div className="col-xxl-4 col-xl-6 col-lg-8 col-md-10 col-sm-12 mb-4">
                                    <div className="card h-100 bs_1 mx-3">
                                        <div className="card-header">
                                            <h4>Project Wise Report</h4>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <div className="d-flex me-3">
                                                    <i className="fa fa-star me-2 text-warning" aria-hidden="true"></i>
                                                    <i className="fa fa-download me-2" aria-hidden="true"></i>
                                                </div>
                                                <select className="form-select dash_btn me-2 mb-1">
                                                    <option selected>Teams</option>
                                                    <option value="1">Brigade Sales Team</option>
                                                    <option value="1">Godrej Sales Team</option>
                                                    <option value="1">Shriram Sales Team</option>
                                                </select>                                                
                                            </div>
                                        </div>
                                        <div className="card-body p-3">
                                        <Bar options={options} data={databarcountProperty} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                        <div  className="tab-pane fade" id="citywiseproject" role="tabpanel" aria-labelledby="citywiseproject-tab">
                            <div className="row">                          
                                <div className="col-xxl-4 col-xl-6 col-lg-8 col-md-10 col-sm-12 mb-4">
                                    <div className="card h-100 bs_1 mx-3">
                                        <div className="card-header">
                                            <h4>City Wise Project Report</h4>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <div className="d-flex me-3">
                                                    <i className="fa fa-star me-2 text-warning" aria-hidden="true"></i>
                                                    <i className="fa fa-download me-2" aria-hidden="true"></i>
                                                </div>
                                                <select className="form-select dash_btn me-2 mb-1">
                                                    <option selected>Teams</option>
                                                    <option value="1">Brigade Sales Team</option>
                                                    <option value="1">Godrej Sales Team</option>
                                                    <option value="1">Shriram Sales Team</option>
                                                </select>                                                
                                            </div>
                                        </div>
                                        <div className="card-body p-3">
                                        <Doughnut options={options2} data={databarcountCitywiseProperty} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                        <div  className="tab-pane fade" id="builderwise" role="tabpanel" aria-labelledby="builderwise-tab">
                            <div className="row">                          
                                <div className="col-xxl-4 col-xl-6 col-lg-8 col-md-10 col-sm-12 mb-4">
                                    <div className="card h-100 bs_1 mx-3">
                                        <div className="card-header">
                                            <h4>Builder Wise Report</h4>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <div className="d-flex me-3">
                                                    <i className="fa fa-star me-2 text-warning" aria-hidden="true"></i>
                                                    <i className="fa fa-download me-2" aria-hidden="true"></i>
                                                </div>
                                                <select className="form-select dash_btn me-2 mb-1">
                                                    <option selected>Teams</option>
                                                    <option value="1">Brigade Sales Team</option>
                                                    <option value="1">Godrej Sales Team</option>
                                                    <option value="1">Shriram Sales Team</option>
                                                </select>                                                
                                            </div>
                                        </div>
                                        <div className="card-body p-3">
                                            <DashboardPieChart/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                        <div  className="tab-pane fade" id="configurationwise" role="tabpanel" aria-labelledby="configurationwise-tab">
                            <div className="row">                          
                                <div className="col-xxl-4 col-xl-6 col-lg-8 col-md-10 col-sm-12 mb-4">
                                    <div className="card h-100 bs_1 mx-3">
                                        <div className="card-header">
                                            <h4>Configuration Wise Report</h4>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <div className="d-flex me-3">
                                                    <i className="fa fa-star me-2 text-warning" aria-hidden="true"></i>
                                                    <i className="fa fa-download me-2" aria-hidden="true"></i>
                                                </div>
                                                <select className="form-select dash_btn me-2 mb-1">
                                                    <option selected>Teams</option>
                                                    <option value="1">Brigade Sales Team</option>
                                                    <option value="1">Godrej Sales Team</option>
                                                    <option value="1">Shriram Sales Team</option>
                                                </select>                                                
                                            </div>
                                        </div>
                                        <div className="card-body p-3">
                                            <DashboardPieChart/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                        <div  className="tab-pane fade" id="propertytypewise" role="tabpanel" aria-labelledby="propertytypewise-tab">
                            <div className="row">                          
                                <div className="col-xxl-4 col-xl-6 col-lg-8 col-md-10 col-sm-12 mb-4">
                                    <div className="card h-100 bs_1 mx-3">
                                        <div className="card-header">
                                            <h4>Property Type Wise Report</h4>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <div className="d-flex me-3">
                                                    <i className="fa fa-star me-2 text-warning" aria-hidden="true"></i>
                                                    <i className="fa fa-download me-2" aria-hidden="true"></i>
                                                </div>
                                                <select className="form-select dash_btn me-2 mb-1">
                                                    <option selected>Teams</option>
                                                    <option value="1">Brigade Sales Team</option>
                                                    <option value="1">Godrej Sales Team</option>
                                                    <option value="1">Shriram Sales Team</option>
                                                </select>                                                
                                            </div>
                                        </div>
                                        <div className="card-body p-3">
                                        <Doughnut options={options2} data={databarcountPropertyType} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                        <div  className="tab-pane fade" id="projectstatus" role="tabpanel" aria-labelledby="projectstatus-tab">
                            <div className="row">                          
                                <div className="col-xxl-4 col-xl-6 col-lg-8 col-md-10 col-sm-12 mb-4">
                                    <div className="card h-100 bs_1 mx-3">
                                        <div className="card-header">
                                            <h4>Project Status Report</h4>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <div className="d-flex me-3">
                                                    <i className="fa fa-star me-2 text-warning" aria-hidden="true"></i>
                                                    <i className="fa fa-download me-2" aria-hidden="true"></i>
                                                </div>
                                                <select className="form-select dash_btn me-2 mb-1">
                                                    <option selected>Teams</option>
                                                    <option value="1">Brigade Sales Team</option>
                                                    <option value="1">Godrej Sales Team</option>
                                                    <option value="1">Shriram Sales Team</option>
                                                </select>                                                
                                            </div>
                                        </div>
                                        <div className="card-body p-3">
                                        <Doughnut options={options2} data={databarcountStatusProperty} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                        <div  className="tab-pane fade" id="projectsegment" role="tabpanel" aria-labelledby="projectsegment-tab">
                            <div className="row">                          
                                <div className="col-xxl-4 col-xl-6 col-lg-8 col-md-10 col-sm-12 mb-4">
                                    <div className="card h-100 bs_1 mx-3">
                                        <div className="card-header">
                                            <h4>Project Segment</h4>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <div className="d-flex me-3">
                                                    <i className="fa fa-star me-2 text-warning" aria-hidden="true"></i>
                                                    <i className="fa fa-download me-2" aria-hidden="true"></i>
                                                </div>
                                                <select className="form-select dash_btn me-2 mb-1">
                                                    <option selected>Teams</option>
                                                    <option value="1">Brigade Sales Team</option>
                                                    <option value="1">Godrej Sales Team</option>
                                                    <option value="1">Shriram Sales Team</option>
                                                </select>                                                
                                            </div>
                                        </div>
                                        <div className="card-body p-3">
                                        <Doughnut options={options2} data={databarcountSegmentProperty} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                    </div>                        
                    </div>
                </div>
            </div>
        </section>
    )
}
export{ProjectLead}