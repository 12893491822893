import React,{FC, useState, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import { LeadForm } from '../lead/leadform';
import { TaskForm } from '../task/taskform';
import { ContactFilter } from './contactFilter';

import { ContactForm } from './contactform';
import { ContactImportForm } from './contactImport';

type Props = {
  setContactList?: any,
  clickContactName?: any,
  leadClick?: any,
  taskClick?: any,
  setToggle?: any,
}

const ContactDrawer: FC<Props> = (props) => {
  const {
   setContactList, clickContactName, leadClick, taskClick, setToggle
  } = props
 
  const location = useLocation();
  
  return(
  <div>

    {/* Add Contact Drawer */}
    <div
        id='kt_contact'
        className='bg-white'
        data-kt-drawer='true'
        data-kt-drawer-name='contact'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'100%', 'md': '700px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#kt_contact_toggle'
        data-kt-drawer-close='#kt_contact_close'
      >
        <ContactForm setContacts={setContactList}/>
    </div>
    {/* Import Contact Drawer */}
    <div
        id='kt_contact_import'
        className='bg-white side_drawer'
        data-kt-drawer='true'
        data-kt-drawer-name='contact_import'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'100%', 'md': '700px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#kt_contact_import_toggle'
        data-kt-drawer-close='#kt_contact_import_close'
      >
        <ContactImportForm setContacts={setContactList}/>
    </div>
    {/* Filter Contact Drawer */}
    <div
        id='kt_filter'
        className='bg-white filter_area'
        data-kt-drawer='true'
        data-kt-drawer-name='filter'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'100%', 'md': '700px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#kt_filter_toggle'
        data-kt-drawer-close='#kt_filter_close'
      >
        <ContactFilter setContacts={setContactList} setFiltered={setToggle}/>
    </div>
    {/* Add Lead Drawer */}
    <div
        id='kt_lead'
        className='bg-white'
        data-kt-drawer='true'
        data-kt-drawer-name='lead'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'100%', 'md': '700px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#kt_lead_toggle'
        data-kt-drawer-close='#kt_lead_close'
    >
        <LeadForm contactName={clickContactName} checkleadClick={leadClick}/>
    </div>
    <div
        id='kt_task'
        className='bg-white'
        data-kt-drawer='true'
        data-kt-drawer-name='contact'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'100%', 'md': '700px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#kt_task_toggle'
        data-kt-drawer-close='#kt_task_close'
    >
        <TaskForm contactName={clickContactName} checkTaskClick={taskClick}/>
    </div>

  </div>
  )
}


export {ContactDrawer}
// export default AddContact