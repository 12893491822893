import React,{FC, useState,useEffect, useRef} from 'react'
import {getContactType,getContactCategory, getContactsDropList,getContacts, getContactStatus,getState,getLocality,getSource,getDoNotDisturb,getMaritalStatus,getGender,getNationality,getLanguage,getPetOwner,getIdDocument,saveContact, getAssignToList, getContactGroup, getContactByRole, getCity} from './core/_requests'
import { Theme, useTheme } from '@mui/material/styles';
import {Link} from 'react-router-dom'
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Toast } from 'bootstrap';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import {useAuth} from '../../../app/modules/auth'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {getProjects,getProperties} from '../property/core/_requests';
import { getSegment } from '../settings/masters/core/_requests';
import { getUsersByRole } from '../settings/userManagement/core/_requests';

const initialValues = {
  salutation: '',
  first_name: '',
  last_name: '',
  code: '+91',
  phone_number_type: 'mobile',
  mobilePhone: '',
  email: '',
  source: '1',
  contact_group: '',
  contact_type: '1',
  contact_category: '1',
  status: '1',
  is_secondary_contact: '',
  secondary_contact_id: '0',
  company_name: '',
  developer_name: '',
  property_id: '',
  designation:'',
  assign_to: [],
  address_1: '',
  address_2: '',
  locality: '',
  city: '1',
  state: '1',
  zip_code: '',
  country_code: '',
  national_id: '',
  do_not_disturb: '1',
  marital_status: '1',
  gender: '1',
  number_of_children: '',
  wedding_anniversary: '',
  nationality: '1',
  language: '1',
  pet_owner: '1',
  dob: '',
  facebook:'',
  instagram:'',
  linkedin:'',
  twitter:'',
  remarks: '',
  invoice_name: '',
  gst_number: '',
  documents_count: '0',
  id_document1: '',
  id_document2: '',
  id_document3: '',
  id_document4: '',
  id_document5: '',
  document1: '',
  document2: '',
  document3: '',
  document4: '',
  document5: '',
  id_number1: '',
  id_number2: '',
  id_number3: '',
  id_number4: '',
  id_number5: '',
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, aminityName: string[], theme: Theme) {
  return {
      fontWeight:
      aminityName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
  };
}

const mystyle = {
  padding: "0px",
  height: "34px",
  maxHeight: "200px",
  overflowY: "scroll",
  // display: "block"
};

type Props = {
  setContacts?: any,
  sortByOnChange?: any
}

const ContactForm:  FC<Props> = (props) => {

  const {
    setContacts, sortByOnChange
  } = props
  
  //form submit
  const registrationSchema = Yup.object().shape({
    first_name: Yup.string(),
    last_name: Yup.string(),
    email: Yup.string(),
    mobilePhone: Yup.string().required('Phone number is required'),
    city: Yup.string(),
    state: Yup.string(),
    salutation: Yup.string(),
    code: Yup.string(),
    contact_type: Yup.string(),
    source: Yup.string(),
    property_id: Yup.string(),
    phone_number_type: Yup.string(),
    contact_group: Yup.string(),
    contact_category: Yup.string(),
    status: Yup.string(),
    is_secondary_contact: Yup.string(),
    secondary_contact_id: Yup.string(),
    company_name: Yup.string(),
    developer_name: Yup.string(),
    designation: Yup.string(),
    assign_to: Yup.array(),
    address_1: Yup.string(),
    address_2: Yup.string(),
    locality: Yup.string(),
    zip_code: Yup.string(),
    country_code: Yup.string(),
    national_id: Yup.string(),
    do_not_disturb: Yup.string(),
    marital_status: Yup.string(),
    gender: Yup.string(),
    number_of_children: Yup.string(),
    wedding_anniversary: Yup.string(),
    nationality: Yup.string(),
    language: Yup.string(),
    pet_owner: Yup.string(),
    dob: Yup.string(),
    facebook: Yup.string(),
    instagram: Yup.string(),
    linkedin: Yup.string(),
    twitter: Yup.string(),
    remarks: Yup.string(),
    invoice_name: Yup.string(),
    gst_number: Yup.string(),
    documents_count: Yup.string(),
    id_document1: Yup.string(),
    id_document2: Yup.string(),
    id_document3: Yup.string(),
    id_document4: Yup.string(),
    id_document5: Yup.string(),
    document1: Yup.string(),
    document2: Yup.string(),
    document3: Yup.string(),
    document4: Yup.string(),
    document5: Yup.string(),
    profile_image: Yup.string(),
    id_number1: Yup.string(),
    id_number2: Yup.string(),
    id_number3: Yup.string(),
    id_number4: Yup.string(),
    id_number5: Yup.string(),
  })
  
  const [loading, setLoading] = useState(false)
  const [scrolling, setScrolling] = useState(false)

  const {currentUser, logout} = useAuth();

  const [imagePreview1, setImagePreview1] = useState(null);
  const [imagePreview2, setImagePreview2] = useState(null);
  const [imagePreview3, setImagePreview3] = useState(null);
  const [imagePreview4, setImagePreview4] = useState(null);
  const [imagePreview5, setImagePreview5] = useState(null);
  const [profileImagePreview, setProfileImagePreview] = useState(null);
  const [imageFile1, setImageFile1] = useState(null);
  const [imageFile2, setImageFile2] = useState(null);
  const [imageFile3, setImageFile3] = useState(null);
  const [imageFile4, setImageFile4] = useState(null);
  const [imageFile5, setImageFile5] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [assignToName, setAssignToName] = useState<string[]>([]);
  const [assignToId, setAssignToId] = useState<string[]>([]);
  const [city, setCity] = useState<any[]>([]);
  const [assignToItem, setAssignToItem] = useState<any>('');
  var userId = currentUser?.id;
  var roleId = currentUser?.designation;
  
  const theme = useTheme();

  const cityList =  async () => {   
    const cityResponse = await getCity()
    setCity(cityResponse);
}

  const assingToChange = (event: SelectChangeEvent<typeof assignToName>) => {
    const {
      target: { value },
    } = event;

    console.log('assign event');
    console.log(value);

    var name = [];
    var id = [];

    for(let i = 0; i < value.length; i++){
      var fields = value[i].split('-');

      var n = fields[0];
      var d = fields[1];

      name.push(n);
      id.push(d);
    }

    console.log(name);
    console.log(id);

    setAssignToId(id);

    setAssignToName(
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      try {
        console.log('assignToId')
        console.log(assignToId)
        var formData = new FormData();

        var userId = currentUser?.id;
        var roleId = currentUser?.designation;

        var assto:any = [];
        if (assignToId.length > 0) {
          // setAssignToItem(assignToId.join(','));
          assto = assignToId; 
        } else {
          // setAssignToItem([userId]);
          assto = [userId];
        }
        
        formData.append('salutation', values.salutation);
        formData.append('first_name', values.first_name != '' ? values.first_name : 'Customer' );
        formData.append('last_name', values.last_name);
        formData.append('email', values.email);
        formData.append('mobile', values.mobilePhone);
        formData.append('created_by', userId!.toString());
        formData.append('state', values.state);
        formData.append('city', values.city);
        formData.append('contact_type', values.contact_type);
        formData.append('company_name', values.company_name);
        formData.append('developer_name', values.developer_name);
        formData.append('designation', values.designation);
        formData.append('assign_to', assto);
        formData.append('source', values.source);
        formData.append('property_id', values.property_id);
        formData.append('code', values.code);
        formData.append('phone_number_type', values.phone_number_type);
        formData.append('contact_group', values.contact_group);
        formData.append('contact_category', values.contact_category);
        formData.append('status', values.status);
        formData.append('is_secondary_contact', values.is_secondary_contact);
        formData.append('secondary_contact_id', values.secondary_contact_id);
        formData.append('address_1', values.address_1);
        formData.append('address_2', values.address_2);
        formData.append('zip_code', values.zip_code);
        formData.append('country_code', values.country_code);
        formData.append('national_id', values.national_id);
        formData.append('do_not_disturb', values.do_not_disturb);
        formData.append('marital_status', values.marital_status);
        formData.append('gender', values.gender);
        formData.append('number_of_children', values.number_of_children);
        formData.append('wedding_anniversary', values.wedding_anniversary);
        formData.append('nationality', values.nationality);
        formData.append('language', values.language);
        formData.append('pet_owner', values.pet_owner);
        formData.append('dob', values.dob);
        formData.append('locality', values.locality);
        formData.append('facebook', values.facebook);
        formData.append('instagram', values.instagram);
        formData.append('linkedin', values.linkedin);
        formData.append('twitter', values.twitter);
        formData.append('remarks', values.remarks);
        formData.append('invoice_name', values.invoice_name);
        formData.append('gst_number', values.gst_number);
        formData.append('documents_count', documentList.length.toString());
        formData.append('id_document1', values.id_document1);
        formData.append('id_document2', values.id_document2);
        formData.append('id_document3', values.id_document3);
        formData.append('id_document4', values.id_document4);
        formData.append('id_document5', values.id_document5);
        formData.append('document1', imageFile1!);
        formData.append('document2', imageFile2!);
        formData.append('document3', imageFile3!);
        formData.append('document4', imageFile4!);
        formData.append('document5', imageFile5!);
        formData.append('profile_image', profileImage!);
        formData.append('id_number1', values.id_number1);
        formData.append('id_number2', values.id_number2);
        formData.append('id_number3', values.id_number3);
        formData.append('id_number4', values.id_number4);
        formData.append('id_number5', values.id_number5);
        
        const headers = {
          headers: {
              "Content-type": "multipart/form-data",
          },                    
        }
        console.log("ContactSaved", formData)

        const saveContactData = await saveContact(formData, headers)

        // console.log('saveContactData');
        // console.log(saveContactData);
        // document.getElementById('kt_contact')?.classList.remove('drawer-on');
        if(saveContactData != null){

          setLoading(false);
          setScrolling(false);
          document.getElementById('kt_contact_close')?.click();
          var toastEl = document.getElementById('myToastAdd');
          const bsToast = new Toast(toastEl!);
          bsToast.show();
          const characterResponse = await getContactByRole(userId!, roleId);
          setContacts(characterResponse)
          resetForm();
          setProfileImage(null);
          setAssignTo([]);
          setAssignToId([]);
          setAssignToName([]);
          setImagePreview1(null);
          setImagePreview2(null);
          setImagePreview3(null);
          setImagePreview4(null);
          setImagePreview5(null);
        } else {
          setScrolling(true);
        }

      } catch (error) {
        console.error(error)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const [contactType, setContactType] = useState<any[]>([]);
  const [contactCategory, setContactCategory] = useState<any[]>([]);
  const [contactStatus, setContactStatus] = useState<any[]>([]);
  const [contactDrop, setContactDrop] = useState<any[]>([]);
  const [state, setState] = useState<any[]>([]);
  const [locality, setLocality] = useState<any[]>([]);
  const [source, setSource] = useState<any[]>([]);
  const [contactGroup, setContactGroup] = useState<any[]>([]);
  const [assignTo, setAssignTo] = useState<any[]>([]);
  const [doNotDisturb, setDoNotDisturb] = useState<any[]>([]);
  const [maritalStatus, setMaritalStatus] = useState<any[]>([]);
  const [gender, setGender] = useState<any[]>([]);
  const [nationality, setNationality] = useState<any[]>([]);
  const [property, setProperty] = useState<any[]>([]);
  const [language, setLanguage] = useState<any[]>([]);
  const [petOwner, setPetOwner] = useState<any[]>([]);
  const [idDocument, setIdDocument] = useState<any[]>([]);
  const [secSelected, setSecSelected] = useState(true)
  const profileView = useRef<HTMLInputElement>(null);


  const contactTypeList =  async () => {   
      const contactTypeResponse = await getContactType()
      setContactType(contactTypeResponse);
  }
  const contactCategoryList =  async () => {   
      const contactCateResponse = await getSegment()
      setContactCategory(contactCateResponse);
  }
  const contactStatusList =  async () => {   
      const contactStatusResponse = await getContactStatus()
      setContactStatus(contactStatusResponse);
  } 
  const contactDropList =  async () => {   
      const contactDropResponse = await getContactsDropList(userId, roleId)
      setContactDrop(contactDropResponse);
  } 
  const stateList =  async () => {   
      const stateResponse = await getState()
      setState(stateResponse);
  } 
  const localityList =  async () => {   
    const localityResponse = await getLocality()
    setLocality(localityResponse);
  } 
  const sourceList =  async () => {   
    const sourceResponse = await getSource()
    setSource(sourceResponse);
  } 
  const propertyList =  async () => {   
    const Response = await getProperties(userId, roleId)
    setProperty(Response);
    // console.log('Responsedfwewe');
    // console.log(Response);
  } 
  const contactGroupList =  async () => {   
    const groupResponse = await getContactGroup()
    setContactGroup(groupResponse);
  } 
  const assignToList =  async () => {   
    var usersId = currentUser?.id;
    var roleId = currentUser?.designation;
    const assignToResponse = await getAssignToList(usersId, roleId)
    setAssignTo(assignToResponse);
  } 
  const doNotDisturbList =  async () => {   
    const doNotDisturbResponse = await getDoNotDisturb()
    setDoNotDisturb(doNotDisturbResponse);
  } 
  const maritalStatusList =  async () => {   
    const maritalStatusResponse = await getMaritalStatus()
    setMaritalStatus(maritalStatusResponse);
  } 
  const genderList =  async () => {   
    const genderResponse = await getGender()
    setGender(genderResponse);
  } 
  const nationalityList =  async () => {   
    const nationalityResponse = await getNationality()
    setNationality(nationalityResponse);
  } 
  const languageList =  async () => {   
    const languageResponse = await getLanguage()
    setLanguage(languageResponse);
  } 
  const petOwnerList =  async () => {   
    const petOwnerResponse = await getPetOwner()
    setPetOwner(petOwnerResponse);
  } 
  const idDocumentList =  async () => {   
    const idDocumentResponse = await getIdDocument()
    setIdDocument(idDocumentResponse);
  } 
  useEffect(() => {
    contactTypeList();
    contactCategoryList();
    contactStatusList();
    stateList();
    assignToList();
    localityList();
    sourceList();
    contactDropList();
    contactGroupList();
    doNotDisturbList();
    maritalStatusList();
    genderList();
    nationalityList();
    languageList();
    petOwnerList();
    idDocumentList();
    propertyList();
    cityList();
  }, [profileView]);

    // const Input = () => {
    //     return <input className="form-control" placeholder="Your input here"/>;
    //   };
    
    //   const [inputList, setInputList] = useState<JSX.Element>();
    //   const onAddBtnClick = () => {
    //     setInputList(<Input/>);
    //   };

  const [documentList, setDocumentList] = useState<any[]>([{ document: "" }]);
  
  // const handleServiceChange = (e:any, index:any) => {
  //   const { name, value } = e.target;
  //   const list = [...documentList];
  //   list[index][name] = value;
  //   setDocumentList(list);
  // };

  const handleDocumentRemove = (index:any) => {
    const list = [...documentList];
    list.splice(index, 1);
    setDocumentList(list);
  };

  const handleDocumentAdd = () => {
    setDocumentList([...documentList, { document: "" }]);
  };

  const secOnChange = (val:any) => {
    console.log(val)
    formik.setFieldValue('is_secondary_contact', val ?? '');
    if(val == 1){
      setSecSelected(true);
    }
    else {
      setSecSelected(false);
    }
  }

  const handleImagePreview1 = (e:any) => {
    
    console.log('image_as_base64');
    console.log(e.target.files);
    console.log(e.target.files[0]);

    let image_as_base64:any = URL.createObjectURL(e.target.files[0])
    let image_as_files:any = e.target.files[0];
  
    setImagePreview1(image_as_base64);
    setImageFile1(image_as_files);

    // for(let i = 0; i < documentList.length ; i++){

    //   console.log('image_as_base64 i')
    //   console.log(i)
    //   if(documentList.length > 0){
    //     if(i == 0){
    //       let image_as_base64:any = URL.createObjectURL(e.target.files[0])
    //       let image_as_files:any = e.target.files[0];
        
    //       setImagePreview1(image_as_base64);
    //       setImageFile1(image_as_files);
    //     } 
    //   } else if(documentList.length > 1){
    //     if(i == 1) {
    //       let image_as_base64:any = URL.createObjectURL(e.target.files[0])
    //       let image_as_files:any = e.target.files[0];
        
    //       setImagePreview2(image_as_base64);
    //       setImageFile2(image_as_files);
    //     }
    //   } else if(documentList.length > 2){
    //     if(i == 2) {
    //       let image_as_base64:any = URL.createObjectURL(e.target.files[0])
    //       let image_as_files:any = e.target.files[0];
        
    //       setImagePreview3(image_as_base64);
    //       setImageFile3(image_as_files);
    //     }
    //   } else if(documentList.length > 3){
    //     if(i == 3) {
    //     let image_as_base64:any = URL.createObjectURL(e.target.files[0])
    //     let image_as_files:any = e.target.files[0];
      
    //     setImagePreview4(image_as_base64);
    //     setImageFile4(image_as_files);
    //   }
    // } else {
    //   if(i == 4) {
    //     let image_as_base64:any = URL.createObjectURL(e.target.files[0])
    //     let image_as_files:any = e.target.files[0];
      
    //     setImagePreview5(image_as_base64);
    //     setImageFile5(image_as_files);
    //   }
    // }
    // }

        // this.setState({
        //     image_preview: image_as_base64,
        //     image_file: image_as_files,
        // })
  }

  const handleImagePreview2 = (e:any) => {
    let image_as_base64:any = URL.createObjectURL(e.target.files[0])
    let image_as_files:any = e.target.files[0];
  
    setImagePreview2(image_as_base64);
    setImageFile2(image_as_files);
  }
  const handleProfileImagePreview = (e:any) => {
    let image_as_base64:any = URL.createObjectURL(e.target.files[0])
    let image_as_files:any = e.target.files[0];
 
    let fields = profileView.current?.value.split(".");

    let fileType =  fields![fields!.length - 1];

    if(fileType == 'jpg' || fileType == 'jpeg'){
      setProfileImagePreview(image_as_base64);
      setProfileImage(image_as_files);
    } else {
      setProfileImagePreview(null);
      setProfileImage(null);
      if(profileView.current != null){
        profileView.current.value = "";
      }
    }
    console.log(profileView.current?.value);
    console.log(image_as_files);
    console.log(fileType);
  }


  const removeProfile = () => {
    console.log(profileView.current?.value);
    if(profileView.current != null){
      setProfileImagePreview(null);
      setProfileImage(null);
      profileView.current.value = "";
    }
  }

  const handleImagePreview3 = (e:any) => {
    let image_as_base64:any = URL.createObjectURL(e.target.files[0])
    let image_as_files:any = e.target.files[0];
  
    setImagePreview3(image_as_base64);
    setImageFile3(image_as_files);
  }
  const handleImagePreview4 = (e:any) => {
    let image_as_base64:any = URL.createObjectURL(e.target.files[0])
    let image_as_files:any = e.target.files[0];
  
    setImagePreview4(image_as_base64);
    setImageFile4(image_as_files);
  }
  const handleImagePreview5 = (e:any) => {
    let image_as_base64:any = URL.createObjectURL(e.target.files[0])
    let image_as_files:any = e.target.files[0];
  
    setImagePreview5(image_as_base64);
    setImageFile5(image_as_files);
  }

      
    return(
        
        <div className='card shadow-none rounded-0 w-100'>
          <div className='card-header w-100 d-flex align-items-center justify-content-between' id='kt_contact_header'>
            <h3 className='card-title fw-bolder text-dark'>Add Contact</h3>
            
            <div className='card-toolbar'>
              <div>
                {/* <div className="input-group form_search me-3">
                  <input type="text" className="form-control" placeholder="Search"/>
                  <div className="input-group-append">
                    <button className="btn btn-secondary" type="button">
                      <img src={toAbsoluteUrl('/media/custom/header-icons/search.svg')} className="svg_icon" alt='' />
                    </button>
                  </div>
                </div> */}
              </div>
              <button
                type='button'
                className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                id='kt_contact_close'
              >
                  <img src={toAbsoluteUrl('/media/custom/cancel_black.svg')} className="svg_icon" alt='' />
              </button>
            </div>
          </div>
          <div aria-atomic="true" aria-live="assertive" className="toast bg-success text-white position-absolute end-0 bottom-0 m-3" id="myToast">
            <div className="toast-header">
              <strong className="me-auto">Success</strong>
              <button aria-label="Close" className="btn-close" 
                data-bs-dismiss="toast" type="button">
              </button>
            </div>
            <div className="toast-body">
                Contact Added Successfully!
            </div>
          </div>
          <div className='card-body position-relative' id='kt_contact_body'>
          
          <div className="accordion" id="accordionExample"> 

          <form noValidate onSubmit={formik.handleSubmit} className='contact_form'>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Basic Details
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                      <div className="row">
                      <div className="col-md-12">
                        <div className="d-flex justify-content-center">
                         {profileImagePreview != null && (
                          <div className='profile_preview position-relative image-input image-input-outline'>
                            <img src={profileImagePreview} alt="image preview" className='image-input-wrapper w-125px h-125px' height={100} width={100}/>
                            <div onClick={removeProfile} className="p-1">
                              <KTSVG
                                path='/media/icons/duotune/general/gen040.svg'
                                className='svg-icon-3 cursor_pointer bg-white position-absolute p-0 top-0 m-2 end-0 rounded-circle svg-icon-danger pe-auto ms-2'
                              />
                            </div>
                          </div>
                         )}
                        </div>
                          <div className="d-flex justify-content-center">
                              <span className="btn btn-file">
                                {/* <i className="fa fa-upload my-2" aria-hidden="true"></i> */}
                                <KTSVG
                                path='/media/icons/duotune/files/fil022.svg'
                                className='svg-icon-1 text_primary ms-2'
                                />
                                <p className='text_primary'>Upload Profile Image</p>
                                <small className='text-dark'>* Note: jpg, jpeg only acceptable</small>
                                <input type="file" className='form-control' name="profile_image" ref={profileView} onChange={handleProfileImagePreview}/>
                              </span>
                          </div>
                      </div>
                        <div className="col-md-6 col-12 mb-3">
                          
                          <label htmlFor="basic-url" className="form-label">FirstName</label>
                            <div className="input-group first mb-3 input_prepend">
                              <select {...formik.getFieldProps('salutation')} className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                  <option selected value="Mr">Mr</option>
                                  <option value="Ms">Ms</option>
                                  <option value="Mrs">Mrs</option>
                              </select>
                              <input type="text" 
                              {...formik.getFieldProps('first_name')}
                                className={clsx(
                                  'form-control form-control-lg form-control-solid',
                                  {
                                    'is-invalid': formik.touched.first_name && formik.errors.first_name,
                                  },
                                  {
                                    'is-valid': formik.touched.first_name && !formik.errors.first_name,
                                  }
                                )} placeholder="Firstname" name="first_name" autoComplete='off'/>
                             
                            </div>
                            {formik.touched.first_name && formik.errors.first_name && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span role='alert' className='text-danger'>{formik.errors.first_name}</span>
                                    </div>
                                  </div>
                                )}
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                        <label htmlFor="basic-url" className="form-label">LastName</label>
                          <div className="input-group mb-3">
                              <input type="text" 
                              {...formik.getFieldProps('last_name')}
                              className={clsx(
                                'form-control form-control-lg form-control-solid',
                                {
                                  'is-invalid': formik.touched.last_name && formik.errors.last_name,
                                },
                                {
                                  'is-valid': formik.touched.last_name && !formik.errors.last_name,
                                }
                              )}
                              name="last_name" placeholder="Lastname"/>
                          </div>
                          {formik.touched.last_name && formik.errors.last_name && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>{formik.errors.last_name}</span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                          <label htmlFor="basic-url" className="form-label">Phone Number Type</label>
                          <div className="input-group mb-3">
                            <select className="form-select btn btn-sm w-100"
                              {...formik.getFieldProps('phone_number_type')}
                            >
                                <option >Select Number Type</option>
                                <option selected value='mobile'>Mobile</option>
                                <option value='office'>Office</option>
                            </select>      
                          </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                          <label htmlFor="basic-url" className="form-label required">Phone Number</label>
                            <div className="input-group mb-3 input_prepend">
                              <select
                              {...formik.getFieldProps('code')}
                              className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                  <option selected value="+91">+91</option>
                                  <option value="+044">+044</option>
                                  <option value="+1 (214)">+1 (214)</option>
                              </select>
                              <input type="number" min="0" 
                              {...formik.getFieldProps('mobilePhone')}
                              className={clsx(
                                'form-control form-control-lg form-control-solid',
                                {
                                  'is-invalid': formik.touched.mobilePhone && formik.errors.mobilePhone,
                                },
                                {
                                  'is-valid': formik.touched.mobilePhone && !formik.errors.mobilePhone,
                                }
                              )}
                               placeholder="Enter your Phone Number"/>
                            </div>
                            {formik.touched.mobilePhone && formik.errors.mobilePhone && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert' className='text-danger'>{formik.errors.mobilePhone}</span>
                                </div>
                              </div>
                            )}
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                          <label htmlFor="basic-url" className="form-label">Email Address</label>
                            <div className="input-group mb-3">
                              <input type="text" 
                              {...formik.getFieldProps('email')}
                              className={clsx(
                                'form-control form-control-lg form-control-solid',
                                {
                                  'is-invalid': formik.touched.email && formik.errors.email,
                                },
                                {
                                  'is-valid': formik.touched.email && !formik.errors.email,
                                }
                              )}
                              
                              placeholder="Enter your email"/>
                            </div>
                            {formik.touched.email && formik.errors.email && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span role='alert' className='text-danger'>{formik.errors.email}</span>
                                    </div>
                                  </div>
                                )}
                        </div>
                        
                          <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Source</label>
                            <div className="input-group mb-3">
                            <select className="form-select btn btn-sm w-100" 
                            {...formik.getFieldProps('source')}
                            >
                            <option  >Select</option>
                                {source.map((sourceValue,i)=> {
                                  return (
                                    <option selected={i == 0 ? true: false} value={sourceValue.id} key={i}>{sourceValue.name}</option>
                                  )
                                })}   
                            </select>
                            </div>
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Project Name</label>
                            <div className="input-group mb-3">
                            <select className="form-select btn btn-sm w-100" 
                            {...formik.getFieldProps('property_id')}
                            >
                            <option  >Select</option>
                                {property.map((value,i)=> {
                                  return (
                                    <option selected={i == 0 ? true: false} value={value.id} key={i}>{value.name_of_building}</option>
                                  )
                                })}   
                            </select>
                            </div>
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Contact Group</label>
                            <div className="input-group mb-3">
                            <select className="form-select btn btn-sm w-100" 
                            {...formik.getFieldProps('contact_group')}
                            >
                            <option value="">Select</option>
                                {contactGroup.map((contactGroupVal,i)=> {
                                  return (
                                    <option selected={i == 0 ? true: false} value={contactGroupVal.id} key={i}>{contactGroupVal.name}</option>
                                  )
                                })}   
                            </select>            
                            </div>
                          </div>
                        <div className="col-md-6 col-12 mb-3">
                          <label htmlFor="basic-url" className="form-label">Contact Type</label>
                            <div className="input-group mb-3">
                              <select 
                              {...formik.getFieldProps('contact_type')} 
                              name="contact_type" className='form-select btn btn-sm w-100'>
                                <option  >Select</option>
                                {contactType.map((contactTypeValue,i)=> {
                                  return (
                                    <option selected={i == 0 ? true: false} value={contactTypeValue.id} key={i}>{contactTypeValue.name}</option>
                                  )
                                })} 
                              </select>      
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                          <label htmlFor="basic-url" className="form-label">Contact Category</label>
                            <div className="input-group mb-3">
                              <select 
                              {...formik.getFieldProps('contact_category')} 
                              className="form-select btn btn-sm w-100">
                              <option  >Select</option>
                                {contactCategory.map((contactCategoryValue,i)=> {
                                  return (
                                    <option selected={i == 0 ? true: false} value={contactCategoryValue.id} key={i}>{contactCategoryValue.name}</option>
                                  ) 
                                })} 
                              </select>      
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                          <label htmlFor="basic-url" className="form-label">Status</label>
                            <div className="input-group mb-3">
                              <select 
                              {...formik.getFieldProps('status')} 
                              className="form-select btn btn-sm w-100">
                              <option  >Select</option>
                                {contactStatus.map((contactStatusValue,i)=> {
                                  return (
                                    <option selected={i == 0 ? true: false} value={contactStatusValue.id} key={i}>{contactStatusValue.name}</option>
                                  )
                                })} 
                              </select>      
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                          <label htmlFor="basic-url" className="form-label">Developer Name </label>
                          <div className="input-group">
                              <input type="text" {...formik.getFieldProps('company_name')} name="company_name" className="form-control" placeholder="Enter developer Name"/>
                          </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                          <label htmlFor="basic-url" className="form-label">Company Name </label>
                          <div className="input-group">
                              <input type="text" {...formik.getFieldProps('developer_name')} name="developer_name" className="form-control" placeholder="Enter company Name"/>
                          </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                          <label htmlFor="basic-url" className="form-label">Designation</label>
                          <div className="input-group">
                              <input type="text" {...formik.getFieldProps('designation')} name="designation" className="form-control" placeholder="Enter Company Name"/>
                          </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                          <label htmlFor="basic-url" className="form-label">Assign to</label>
                          <FormControl sx={{ m: 0, width: '100%', mt: 0 }}>
                              <Select
                                  multiple
                                  displayEmpty
                                  value={assignToName}
                                  onChange={assingToChange}
                                  input={<OutlinedInput />}
                                  renderValue={(selected) => {
                                    var name = [];
                                    var id = [];

                                    for(let i = 0; i < selected.length; i++){
                                      var fields = selected[i].split('-');

                                      var n = fields[0];
                                      var d = fields[1];

                                      name.push(n);
                                      id.push(d);
                                    }

                                    // setAssignToId(id);

                                    console.log('selected');
                                    console.log(selected);
                                      if (selected.length === 0) {
                                        return <p>Assign To</p>;
                                      }

                                      return name.join(', ');
                                  }}
                                  className='multi_select_field'
                                  MenuProps={MenuProps}
                                  inputProps={{ 'aria-label': 'Without label' }}
                                  >
                                  <MenuItem disabled value="">
                                      <em>Assign To</em>
                                  </MenuItem>
                                  {assignTo.map((assignVal) => (
                                    <MenuItem
                                      key={assignVal.user_id}
                                      value={assignVal.employee_name+'-'+assignVal.user_id}
                                      style={getStyles(assignVal.employee_name, assignToName, theme)}
                                      >
                                      {assignVal.employee_name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                          <label htmlFor="basic-url" className="form-label">Is Secondary Contact</label>
                          <div className="input-group mb-3 input_prepend">
                              <select className="btn_secondary btn btn-sm w-100" onChange={(e) => secOnChange(e.target.value)}>
                                  <option selected value="1">Yes</option>
                                  <option value="0">No</option>
                              </select>
                          </div>
                        </div>
                        {/* secondary_contact_id */}
                        {secSelected &&
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Secondary Contact</label>
                            <div className="input-group mb-3">
                            <select className="form-select btn btn-sm w-100" 
                            {...formik.getFieldProps('secondary_contact_id')}
                            >
                              <option value=''>select secondary contact</option>
                              {contactDrop.map((contactDropVal,i)=> {
                                return (
                                  <option selected={i == 0 ? true: false} value={contactDropVal.id} key={i}>{contactDropVal.first_name+ ' '+contactDropVal.last_name}</option>
                                )
                              })}
                            </select>
                            </div>
                          </div>
                          }
                      </div>
                    
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Address Details
                  </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                      <div className="row">
                          <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Address line 1</label>
                            <div className="input-group">
                                <input type="text" {...formik.getFieldProps('address_1')} name="address_1" className="form-control" placeholder="Enter Address"/>
                            </div>
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Address line 2</label>
                            <div className="input-group">
                                <input type="text" {...formik.getFieldProps('address_2')} name="address_2" className="form-control" placeholder="Enter Address"/>
                            </div>
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Locality</label>
                            <div className="input-group mb-3">
                            <input type="text" {...formik.getFieldProps('locality')} name="locality" className="form-control" placeholder="Enter Locality"/>
                              {/* <select className="btn_secondary btn btn-sm w-100">
                              <option selected disabled >Select</option>
                                {locality.map((localityValue,i)=> {
                                  return (
                                    <option value={localityValue.id}>{localityValue.name}</option>
                                  )
                                })} 
                              </select>       */}
                            {/* <div className="input-group"> */}
                                
                            {/* </div> */}
                            </div>
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">City</label>
                            <div className="input-group mb-3">
                              <select 
                              {...formik.getFieldProps('city')}
                              className={clsx(
                                'form-select btn btn-sm w-100',
                                {
                                  'is-invalid': formik.touched.city && formik.errors.city,
                                },
                                {
                                  'is-valid': formik.touched.city && !formik.errors.city,
                                }
                              )}
                                name="city"
                              
                              >
                                  <option selected value=''>Select City</option>
                                  {city.map((cityValue,i) =>{
                                      return (
                                          <option value={cityValue.id} selected={i == 0 ? true : false} key={i}>{cityValue.name}</option>
                                  )})}
                              </select>      
                            </div>
                            {formik.touched.city && formik.errors.city && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span role='alert' className='text-danger'>{formik.errors.city}</span>
                                    </div>
                                  </div>
                                )}
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">State</label>
                            <div className="input-group mb-3">
                            <select 
                            {...formik.getFieldProps('state')}
                            className={clsx(
                              'form-select btn btn-sm w-100',
                              {
                                'is-invalid': formik.touched.state && formik.errors.state,
                              },
                              {
                                'is-valid': formik.touched.state && !formik.errors.state,
                              }
                            )}
                              name="state"
                            >
                                 <option >Select</option>
                                  {state.map((stateValue,i)=> {
                                    return (
                                      <option selected={i == 0 ? true: false} value={stateValue.id} key={i}>{stateValue.name}</option>
                                    )
                                  })}
                              </select>   
                                
                            </div>
                            {formik.touched.state && formik.errors.state && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span role='alert' className='text-danger'>{formik.errors.state}</span>
                                    </div>
                                  </div>
                                )}
                          </div>
                          
                          <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Zip code</label>
                            <div className="input-group">
                                <input type="text" {...formik.getFieldProps('zip_code')} name="zip_code" className="form-control" placeholder="Enter Zip Code"/>
                            </div>
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Country code</label>
                            <div className="input-group">
                                <input type="text" {...formik.getFieldProps('country_code')} name="country_code" className="form-control" placeholder="Enter Zip Code"/>
                            </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  More Details
                  </button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                      <div className="row">
                          <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Invoice Name</label>
                            <div className="input-group mb-3">
                                <input type="text" {...formik.getFieldProps('invoice_name')} name="invoice_name" className="form-control" placeholder="Enter invoice name"/>
                            </div>
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">GST Number</label>
                            <div className="input-group mb-3">
                                <input type="text" {...formik.getFieldProps('gst_number')} name="gst_number" className="form-control" placeholder="Enter GST No."/>
                            </div>
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">National ID</label>
                            <div className="input-group">
                                <input type="text" {...formik.getFieldProps('national_id')} name="national_id" className="form-control" placeholder="Enter National ID"/>
                            </div>
                          </div>
                         
                          <div className="col-md-6 col-12 mb-3">
                            <div className="row">
                              <div className="col-md-6">
                                  <label htmlFor="basic-url" className="form-label">Do not Disturb</label>
                                  <div className="input-group mb-3">
                                    <select 
                                    {...formik.getFieldProps('do_not_disturb')}
                                    className="form-select btn btn-sm w-100">
                                    <option  >Select</option>
                                    {doNotDisturb.map((doNotDisturbValue,i)=> {
                                      return (
                                        <option selected={i == 0 ? true: false} value={doNotDisturbValue.id} key={i}>{doNotDisturbValue.name}</option>
                                      )
                                    })} 
                                    </select>      
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <label htmlFor="basic-url" className="form-label">Marital Status</label>
                                  <div className="input-group mb-3">
                                    <select 
                                    {...formik.getFieldProps('marital_status')}
                                    className="form-select btn btn-sm w-100">
                                    <option  >Select</option>
                                    {maritalStatus.map((maritalStatusValue,i)=> {
                                      return (
                                        <option selected={i == 0 ? true: false} value={maritalStatusValue.id} key={i}>{maritalStatusValue.name}</option>
                                      )
                                    })} 
                                    </select>
                                  </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <div className="row">
                              <div className="col-md-6">
                                  <label htmlFor="basic-url" className="form-label">Gender</label>
                                  <div className="input-group mb-3">
                                    <select 
                                     {...formik.getFieldProps('gender')}
                                    className="form-select btn btn-sm w-100">
                                    <option  >Select</option>
                                    {gender.map((genderValue,i)=> {
                                      return (
                                        <option selected={i == 0 ? true: false} value={genderValue.id} key={i}>{genderValue.name}</option>
                                      )
                                    })} 
                                    </select>
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <label htmlFor="basic-url" className="form-label">No of Children</label>
                                  <div className="input-group">
                                      <input type="number" min="0" {...formik.getFieldProps('number_of_children')} name="number_of_children" className="form-control" placeholder="Enter"/>
                                  </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Wedding Anniversary</label>
                            <div className="input-group mb-3">
                                <input type="date" {...formik.getFieldProps('wedding_anniversary')} name="wedding_anniversary" className="form-control" placeholder="date"/> 
                            </div>
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <div className="row">
                              <div className="col-md-6">
                                  <label htmlFor="basic-url" className="form-label">Nationality</label>
                                  <div className="input-group mb-3">
                                    <select 
                                    {...formik.getFieldProps('nationality')}
                                    className="form-select btn btn-sm w-100">
                                    <option  >Select</option>
                                    {nationality.map((nationalityValue,i)=> {
                                      return (
                                        <option selected={i == 0 ? true: false} value={nationalityValue.id} key={i}>{nationalityValue.name}</option>
                                      )
                                    })} 
                                    </select>
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <label htmlFor="basic-url" className="form-label">Language</label>
                                  <div className="input-group mb-3">
                                    <select 
                                    {...formik.getFieldProps('language')}
                                    className="form-select btn btn-sm w-100">
                                    <option  >Select</option>
                                    {language.map((languageValue,i)=> {
                                      return (
                                        <option selected={i == 0 ? true: false} value={languageValue.id} key={i}>{languageValue.name}</option>
                                      )
                                    })} 
                                    </select>
                                  </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <div className="row">
                              <div className="col-md-6">
                                  <label htmlFor="basic-url" className="form-label">Pet owner</label>
                                  <div className="input-group mb-3">
                                    <select 
                                    {...formik.getFieldProps('pet_owner')}
                                    className="form-select btn btn-sm w-100">
                                    <option  >Select</option>
                                    {petOwner.map((petOwnerValue,i)=> {
                                      return (
                                        <option selected={i == 0 ? true: false} value={petOwnerValue.id} key={i}>{petOwnerValue.name}</option>
                                      )
                                    })} 
                                    </select>
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <label htmlFor="basic-url" className="form-label">D.O.B</label>
                                  <div className="input-group mb-3">
                                      <input type="date" {...formik.getFieldProps('dob')} name="dob" className="form-control" placeholder="dob"/> 
                                  </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 col-12 mb-3 px-md-0">
                            {documentList.map((singleService, index) => { 
                              var i = index + 1;
                              return (
                            <div className="bg_white br_15 p-4 upload_part position-relative" key={index}>
                              {documentList.length !== 1 && (
                                <button className="btn btn-transparent upload_remove" onClick={() => handleDocumentRemove(index)}>
                                  <i className="fa fa-minus-circle text_primary" aria-hidden="true"></i>
                                </button>
                              )}
                              {documentList.length - 1 === index && documentList.length < 5 && (
                                <button className="btn btn-transparent upload_add" onClick={handleDocumentAdd}>
                                  <i className="fa fa-plus-circle text_primary" aria-hidden="true"></i>
                                </button>
                              )}
                              <div key={index} className="row">
                                  <div className="col-md-6">
                                    <label htmlFor="basic-url" className="form-label">Person ID Documents</label>
                                    <div className="input-group mb-3">
                                      <select 
                                      {...formik.getFieldProps('id_document'+i)}
                                      className="form-select btn btn-sm w-100">
                                        <option  >Select</option>
                                        {idDocument.map((idDocumentValue,i)=> {
                                          return (
                                          <option selected={i == 0 ? true: false} value={idDocumentValue.id} key={i}>{idDocumentValue.name}</option>
                                          )
                                        })} 
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <label htmlFor="basic-url" className="form-label">ID number</label>
                                    <div className="input-group mb-3">
                                      <input type="text" {...formik.getFieldProps('id_number'+i)} name={'id_number'+i} className="form-control" placeholder={"ID Number "+i}/>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                      {/* image preview */}
                                      <div className="d-flex justify-content-center">
                                        {i == 1 ? imagePreview1 != null && (<img src={imagePreview1} alt="image preview" height={100} width={100}/>): null}
                                        {i == 2 ? imagePreview2 != null && (<img src={imagePreview2} alt="image preview" height={100} width={100}/>): null}
                                        {i == 3 ? imagePreview3 != null && (<img src={imagePreview3} alt="image preview" height={100} width={100}/>): null}
                                        {i == 4 ? imagePreview4 != null && (<img src={imagePreview4} alt="image preview" height={100} width={100}/>): null}
                                        {i == 5 ? imagePreview5 != null && (<img src={imagePreview5} alt="image preview" height={100} width={100}/>): null}
                                      </div>
                                      <div className="d-flex justify-content-center">
                                        <span className="btn btn-file">
                                          <i className="fa fa-upload my-2" aria-hidden="true"></i>Upload 
                                          <input type="file" 
                                          //  {...formik.getFieldProps('document'+i)}
                                          onChange={i == 1 ? handleImagePreview1: i == 2 ? handleImagePreview2: i == 3 ? handleImagePreview3 : i == 4 ? handleImagePreview4: handleImagePreview5}
                                           name={'document'+i}/>
                                        </span>
                                      </div>
                                      
                                  </div>
                                </div>
                            </div>
                            )})}
                          </div>
                          <div className="col-12 mb-3">
                            <div className="row">
                              <div className="col-md-12 mb-2">
                                  <label htmlFor="basic-url" className="form-label">Social Links</label>
                                  <div className="row mx-0">
                                    <div className="col-md-6">
                                      <label htmlFor="basic-url" className="form-label">Facebook</label>
                                      <div className="input-group mb-3">
                                        <input type="text" {...formik.getFieldProps('facebook')} name='facebook' className="form-control" placeholder=""/>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <label htmlFor="basic-url" className="form-label">Instagram</label>
                                      <div className="input-group mb-3">
                                        <input type="text" {...formik.getFieldProps('instagram')} name='instagram' className="form-control" placeholder=""/>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <label htmlFor="basic-url" className="form-label">LinkedIn</label>
                                      <div className="input-group mb-3">
                                        <input type="text" {...formik.getFieldProps('linkedin')} name='linkedin' className="form-control" placeholder=""/>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <label htmlFor="basic-url" className="form-label">Twitter</label>
                                      <div className="input-group mb-3">
                                        <input type="text" {...formik.getFieldProps('twitter')} name='twitter' className="form-control" placeholder=""/>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                              <div className="col-md-12">
                                  <label htmlFor="basic-url" className="form-label">Remarks</label>
                                  <div className="input-group mb-3">
                                      <input type="text" {...formik.getFieldProps('remarks')} name="remarks" className="form-control" placeholder=""/>
                                  </div>
                              </div>
                            </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
              <div className='card-footer py-5 text-center' id='kt_contact_footer'>
                  <button
                  type='submit'
                  id='kt_sign_up_submit'
                  className='btn btn_primary text-primary'
                  disabled={formik.isSubmitting}
                >
                  {!loading && <span className='indicator-label'>Submit
                  <KTSVG
                  path='/media/custom/save_white.svg'
                  className='svg-icon-3 svg-icon-primary ms-2'
                />
                  </span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
                  
              </div>
          </form>
        </div>

          </div>
          {/* <div className='card-footer py-5 text-center' id='kt_contact_footer'>
            <Link to='/dashboard' className='btn btn_primary text-primary'>
            Save
              <KTSVG
                path='/media/custom/save_white.svg'
                className='svg-icon-3 svg-icon-primary ms-2'
              />
            </Link>
          </div> */}
         
        </div>
    )
}


export {ContactForm}