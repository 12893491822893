import React,{FC, useEffect, useState} from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { useAuth } from '../../../modules/auth';
import { getCountTransaction } from '../core/requests';
import { getTeamsList, getUsersByRole } from '../../settings/userManagement/core/_requests';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import Moment from 'moment';

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
      },
    },
  };

  const initialValues = {
    start_date: "",
    end_date: "",
}

const TransactionBar: FC = () => {
 
    const {currentUser, logout} = useAuth();
    const userId = currentUser?.id;
    const roleId = currentUser?.designation;
    const usersName = currentUser?.first_name;
    const [search, setSearch] = useState("");
    const [transactionBar, setTransactionBar] = useState(1);
    const [teamId, setTeamId] = useState(0);
    const [list, setList] = useState(1);
    const [userName, setUserName] = useState<any>(usersName);
    const [month, setMonth] = useState('');
    const [reqId, setReqId] = useState(userId);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [loading, setLoading] = useState(false);
    const [CountTransaction, setCountTransaction] = useState<any[]>([]);
    const [filtered, setFiltered] = useState<any[]>([]); 
    const [labels, setLabels] = useState<any[]>(['Today']);
    const [bar, setBar] = useState<any[]>([]); 
    const [users, setUsers] = useState<any[]>([]);
    const [teams, setTeams] = useState<any[]>([]);
    const [filteredTeam, setFilteredTeam] = useState<any[]>([]);
    
 
    const CountTransactionList =  async () => {  
      var body = {
        'start_date': fromDate,
        'end_date': toDate,
        'filter': 1,
      }  
        const CountTransactionResponse = await getCountTransaction(userId, roleId, userId, body, teamId)
        const leadbar3 = CountTransactionResponse.map((obj:any) => Object.values(obj)[0]);
        setBar(leadbar3);   
        console.log('CountTransactionResponse', CountTransactionResponse)
    }

    const usersList =  async () => {
      const Response = await getUsersByRole(userId, roleId)
      setUsers(Response);
    }

    const rangeSelect = async (val:any) => {
      setTransactionBar(val);
      setFromDate('');
      setToDate('');
      if(val == 5) {
      document.getElementById('transactionBarCustomDateRange')?.click();
      } else { 
      var body = {
        'start_date': fromDate,
        'end_date': toDate,
        'filter': val,
      } 
      const CountTransactionResponse = await getCountTransaction(userId, roleId, reqId, body, teamId)
      setCountTransaction(CountTransactionResponse);  
      if(val == 2) {
        setMonth('');
        setLabels(['Yesterday'])
        const leadbar2 = CountTransactionResponse.map((obj:any) => Object.values(obj)[0]);
        setBar(leadbar2);
      } else if (val == 3) {
        setMonth('');
        const transactionbarLables = CountTransactionResponse.map((obj:any) => Object.values(obj)[1]); 
        setLabels(transactionbarLables)
        const leadbar3 = CountTransactionResponse.map((obj:any) => Object.values(obj)[0]);
        setBar(leadbar3);
        console.log('bardata3', transactionbarLables)
      } else if (val == 4) {
        const leadbarLables2 = CountTransactionResponse.map((obj:any) => Object.values(obj)[1]);
        var customLables = [];
        for(var i=0;i<leadbarLables2.length;i++) {
         var item = Moment(leadbarLables2[i]).format('DD');
         var itemMonth = Moment(leadbarLables2[i]).format('MMMM-YYYY');
         setMonth(itemMonth);
         customLables.push(item);
        }
        setLabels(customLables);
        const leadbar4 = CountTransactionResponse.map((obj:any) => Object.values(obj)[0]);
        setBar(leadbar4);
      } else if (val == 6) {
        const leadbarLables2 = CountTransactionResponse.map((obj:any) => Object.values(obj)[1]);
        var customLables = [];
        for(var i=0;i<leadbarLables2.length;i++) {
         var item = Moment(leadbarLables2[i]).format('DD');
         var itemMonth = Moment(leadbarLables2[i]).format('MMMM-YYYY');
         setMonth(itemMonth);
         customLables.push(item);
        }
        setLabels(customLables);
        const leadbar4 = CountTransactionResponse.map((obj:any) => Object.values(obj)[0]);
        setBar(leadbar4);
      } else if (val == 7) {
        const leadbarLables2 = CountTransactionResponse.map((obj:any) => Object.values(obj)[1]);
        var customLables = [];
        for(var i=0;i<leadbarLables2.length;i++) {
         var item = Moment(leadbarLables2[i]).format('MMMM');
         var itemMonth = Moment(leadbarLables2[i]).format('YYYY');
         setMonth(itemMonth);
         customLables.push(item);
        }
        setLabels(customLables);
        const leadbar4 = CountTransactionResponse.map((obj:any) => Object.values(obj)[0]);
        setBar(leadbar4);
      } else if (val == 5) {
        const leadbarLables3 = CountTransactionResponse.map((obj:any) => Object.values(obj)[1]);
        var customLables = [];
        for(var i=0;i<leadbarLables3.length;i++) {
         var item = Moment(leadbarLables3[i]).format('DD');
         var itemMonth = Moment(leadbarLables3[i]).format('MMMM-YYYY');
         setMonth(itemMonth);
         customLables.push(item);
        }
        setLabels(customLables);
        const leadbar5 = CountTransactionResponse.map((obj:any) => Object.values(obj)[0]);
        setBar(leadbar5);
      } else {
        setMonth('');
        setLabels(['Today'])
        const leadbar = CountTransactionResponse.map((obj:any) => Object.values(obj)[0]);
        setBar(leadbar);
      }
    }
      // const transactionbarLables = CountTransaction.map((obj) => Object.values(obj)[1]);  
    }

    const userFilter = async (name:any, id:any) => {
      setReqId(id);
      setUserName(name);
      var body = {
        'start_date': fromDate,
        'end_date': toDate,
        'filter': transactionBar,
      } 
      const CountTransactionResponse = await getCountTransaction(userId, roleId, id, body, teamId)
      setCountTransaction(CountTransactionResponse);
      if(transactionBar == 2) {
        setMonth('');
        setLabels(['Yesterday']);
        const leadbar2 = CountTransactionResponse.map((obj:any) => Object.values(obj)[0]);
        setBar(leadbar2);
      } else if (transactionBar == 3) {
        setMonth('');
        const leadbarLables = CountTransactionResponse.map((obj:any) => Object.values(obj)[1]); 
        setLabels(leadbarLables);
        const leadbar3 = CountTransactionResponse.map((obj:any) => Object.values(obj)[0]);
        setBar(leadbar3);
        // console.log('bardata3', leadbarLables)
      } else if (transactionBar == 4) {
        const leadbarLables2 = CountTransactionResponse.map((obj:any) => Object.values(obj)[1]);
        var customLables = [];
        for(var i=0;i<leadbarLables2.length;i++) {
         var item = Moment(leadbarLables2[i]).format('DD');
         var itemMonth = Moment(leadbarLables2[i]).format('MMMM-YYYY');
         setMonth(itemMonth);
         customLables.push(item);
        }
        setLabels(customLables);
        const leadbar4 = CountTransactionResponse.map((obj:any) => Object.values(obj)[0]);
        setBar(leadbar4);
        // console.log('bardata4', leadbarLables2)
      } else if (transactionBar == 6) {
        const leadbarLables2 = CountTransactionResponse.map((obj:any) => Object.values(obj)[1]);
        var customLables = [];
        for(var i=0;i<leadbarLables2.length;i++) {
         var item = Moment(leadbarLables2[i]).format('DD');
         var itemMonth = Moment(leadbarLables2[i]).format('MMMM-YYYY');
         setMonth(itemMonth);
         customLables.push(item);
        }
        setLabels(customLables);
        const leadbar4 = CountTransactionResponse.map((obj:any) => Object.values(obj)[0]);
        setBar(leadbar4);
      } else if (transactionBar == 7) {
        const leadbarLables2 = CountTransactionResponse.map((obj:any) => Object.values(obj)[1]);
        var customLables = [];
        for(var i=0;i<leadbarLables2.length;i++) {
         var item = Moment(leadbarLables2[i]).format('MMMM');
         var itemMonth = Moment(leadbarLables2[i]).format('YYYY');
         setMonth(itemMonth);
         customLables.push(item);
        }
        setLabels(customLables);
        const leadbar4 = CountTransactionResponse.map((obj:any) => Object.values(obj)[0]);
        setBar(leadbar4);
      } else if (transactionBar == 5) {
        const leadbarLables3 = CountTransactionResponse.map((obj:any) => Object.values(obj)[1]);
        var customLables = [];
        for(var i=0;i<leadbarLables3.length;i++) {
         var item = Moment(leadbarLables3[i]).format('DD');
         var itemMonth = Moment(leadbarLables3[i]).format('MMMM-YYYY');
         setMonth(itemMonth);
         customLables.push(item);
        }
        setLabels(customLables);
        const leadbar5 = CountTransactionResponse.map((obj:any) => Object.values(obj)[0]);
        setBar(leadbar5);
        // console.log('bardata5', leadbarLables3)
      } else {
        setMonth('');
        setLabels(['Today']);
        const leadbar = CountTransactionResponse.map((obj:any) => Object.values(obj)[0]);
        setBar(leadbar);
      }
    }

    const databartransaction = {
        labels,
        datasets: [{
          label: 'Transaction',
          backgroundColor: '#3bb6f1',
          borderColor: 'rgb(255, 99, 132)',
          data: bar,
        }]
      };

      const taskSaveSchema = Yup.object().shape({
        start_date: Yup.string(),
        end_date: Yup.string(),
    })

      const formik = useFormik({
        initialValues,
        validationSchema: taskSaveSchema ,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
          try {
    
            setFromDate(values.start_date)
            setToDate(values.end_date)

            var body = {
              'start_date': values.start_date,
              'end_date': values.end_date,
              'filter': transactionBar,
            }  

            const CountTransactionResponse = await getCountTransaction(userId, roleId, reqId, body, teamId)            

            if(CountTransactionResponse != null){
              setCountTransaction(CountTransactionResponse);
              const leadbarLables5 = CountTransactionResponse.map((obj:any) => Object.values(obj)[1]); 
              var customLables = [];
              for(var i=0;i<leadbarLables5.length;i++) {
               var item = Moment(leadbarLables5[i]).format('DD');
               var itemMonth = Moment(leadbarLables5[i]).format('MMMM-YYYY');
              setMonth(itemMonth);
               customLables.push(item);
              }
              setLabels(customLables);
              const leadbar5 = CountTransactionResponse.map((obj:any) => Object.values(obj)[0]);
              setBar(leadbar5);
              resetForm();
              setLoading(false)
              document.getElementById('transactionBarModelClose')?.click();
            }

          } catch (error) {
            console.error(error)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
          }
    }})

      useEffect(() => {
        CountTransactionList();        
        usersList();        
      }, []);

      function filterItem(item:any, search:any) {
        if (search.startsWith("@")) {
          const bucket = search.toLowerCase().substring(1).split(":");
          const searchBy = bucket[0];
          const searchFor = bucket[1];
          const searchByType = getType(item[searchBy]);
      
          if (!searchFor) return false;
          console.log("**** searchBy: ", searchBy);
          console.log("**** searchFor: ", searchFor);
          console.log("**** item: ", item);
          if (searchByType == "string") {
            if (searchFor.startsWith("!")) {
              const bucket = searchFor.split("!");
              const searchForVal = bucket[1];
              return !item[searchBy].includes(searchForVal);
            }
            return item[searchBy].includes(searchFor);
          }
      
          if (searchByType == "array") {
            if (searchFor.startsWith("!")) {
              const bucket = searchFor.split("!");
              const searchForVal = bucket[1];
              return item[searchBy].find((val:any) => !val.includes(searchForVal));
            }
            return item[searchBy].find((val:any) => val.includes(searchFor));
          }
      
          if (searchByType == "object") {
            if (searchFor.startsWith("!")) {
              const bucket = searchFor.split("!");
              const val = bucket[1] || "";
              return !item[searchBy][val];
            }
            if (searchFor.includes("!=")) {
              const bucket2 = searchFor.split("!=");
              const key = bucket2[0];
              const val = bucket2[1] || "";
              return item[searchBy][key] && !item[searchBy][key].includes(val);
            }
            const bucket2 = searchFor.split("=");
            const key = bucket2[0];
            const val = bucket2[1] || "";
            return item[searchBy][key] && item[searchBy][key].includes(val);
          }
        } else {
          if (search.startsWith("!")) {
            const bucket = search.split("!");
            const searchFor = bucket[1];
            if(list == 1) {
            return !item.team_name_lower.includes(searchFor);
            } else {
            return !item.employee_name_lower.includes(searchFor);
          }
          }
          if(list == 1) {
          return item.team_name_lower.includes(search);
           } else {
          return item.employee_name_lower.includes(search);
           }
        }
      }
      
      const getType = (value:any) => {
        if (Array.isArray(value)) {
          return "array";
        } else if (typeof value == "string") {
          return "string";
        } else if (value == null) {
          return "null";
        } else if (typeof value == "boolean") {
          return "boolean";
        } else if (Number(value) == value) {
          return "number";
        } else if (typeof value == "object") {
          return "object";
        }
        return "string";
      }; 
    
      useEffect(() => {
        teamsList();
        usersList();
    
        let filteredData:any = [];
        if (search.length) {
          if(list == 1) {
            teams.forEach((item) => {
              if (filterItem(item, search.toLowerCase())) {
                filteredData.push({ ...item });
              }
            });
          } else {
            users.forEach((item) => {
              if (filterItem(item, search.toLowerCase())) {
                filteredData.push({ ...item });
              }
            });
          }
          
        }
        if(list == 1) {
        setFilteredTeam(filteredData);
        } else {
        setFiltered(filteredData);
        }
    }, [search]);

      const ListChange = (e:any) => {
        setList(e);
        setTeamId(0);
      }

      const teamsList =  async () => {
        var usersId = currentUser?.id;
        var roleId = currentUser?.designation;
        const Response = await getTeamsList(usersId, roleId)
        setTeams(Response);
        console.log('Response', Response)
    }

    function sumArray(array:any){
      let sum = 0
      for (let i = 0; i < 
      array.length; i += 1) {
      sum += array[i]
      }
      return sum
    }

    const handleTeam = async (id:any, name:any) => {
      setTeamId(id);
      setReqId(id);
      setUserName(name);
      var body = {
        'start_date': fromDate,
        'end_date': toDate,
        'filter': transactionBar,
      }  
        const CountTransactionResponse = await getCountTransaction(userId, roleId, id, body, id)
        const leadbar3 = CountTransactionResponse.map((obj:any) => Object.values(obj)[0]);
        setBar(leadbar3);   
        console.log('CountTransactionResponse', CountTransactionResponse)
    }      
    
    return(
        <>
        <div className="card mx-sm-1 mx-xl-2 bs_1 h-100 br_15 bar_chart">
            <div className="card-heade border-0 d-flex align-items-center justify-content-between px-5 pt-5">
                <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Transactions</span>
                </h3>
                <div className='d-flex justify-content-end align-items-center flex-wrap'>
                {bar &&
                  <div className="border border-gray-300 border-dashed rounded p-1 m-1">
                      <div className="fs-8 fw-bold text-gray-800 shrink-0">Total</div>
                      <div className="fs-7 text-gray-800 fw-bolder shrink-0">#{sumArray(bar)}</div>
                  </div>}
                  {roleId != 3 && <>
                <input className="form-select dash_btn m-2 fs-9" type="button" value={userName} id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false"/>
                <ul className="dropdown-menu db_dw_menu" aria-labelledby="defaultDropdown">
                  <select className="form-select btn btn-sm btn_secondary" onChange={(e) => ListChange(e.target.value)}>
                    <option value="1">Teams</option>
                    <option value="2">Users</option>
                  </select>
                    <div className="input-group form_search my-3 dropdown-item">
                      <input type="text" className="form-control" name="search" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)}/>
                      <div className="input-group-append">
                        <button className="btn btn-secondary" type="button">
                        <img src={toAbsoluteUrl('/media/custom/header-icons/search.svg')} className="svg_icon" alt='' />
                        </button>
                      </div>
                    </div>
              {list == 1 ? <>
                  {!search
                      ? teams.map((userData, i) => {
                        return(                            
                        <li className="list-group p-4" key={i}>
                          <div className="">
                            <a type="button" onClick={(e) => handleTeam(userData.team_leader, userData.team_leader_name)}>
                                <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                                {userData.team_leader_name}
                            </a>
                            </div>                                  
                        </li>
                        )}) 
                      : filteredTeam.length ? filteredTeam.map((item) => (<li  className="list-group p-4">
                      <div className="">
                              <a type="button" onClick={(e) => handleTeam(item.team_leader, item.team_leader_name)}>
                                <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                                {item.team_leader_name}
                              </a>
                            </div>
                  </li>)) : <p>No Records!!!</p> }
              </> : <>
                {!search
                    ? users.map((item, i) => (
                    <li className="dropdown-item" key={i} onClick={() => userFilter(item.employee_name, item.user_id)}>{item.employee_name}</li>
                    ))
                    : filtered.map((item, j) => (
                      <li className="dropdown-item" key={j} onClick={() => userFilter(item.employee_name, item.user_id)}>{item.employee_name}</li>
                      ))}</>}
                </ul></>}
                <select className="form-select dash_btn m-2 fs-9" onChange={(e) => rangeSelect(e.target.value)}>
                    <option selected value="1">Today</option>
                    <option value="2">Yesterday</option>
                    <option value="3">Last Week</option>
                    <option value="4">Last Month</option>
                    <option value="6">This Month</option>
                    <option value="7">This Year</option>
                    <option value="5">Custom Date</option>
                </select>
                <button type='button' data-bs-toggle='modal' data-bs-target='#transactionBarCustomSelect' className='d-none' id='transactionBarCustomDateRange'>Custom Date</button>
                <div className='modal fade' id='transactionBarCustomSelect' aria-hidden='true'>
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className='modal-content'>
                          <form noValidate onSubmit={formik.handleSubmit}>
                            <div className='modal-header py-2'>
                                <h3>Select Custom Range</h3>
                                <div id='transactionBarModelClose' className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                </div>
                            </div>
                            <div className='modal-body py-3 px-lg-10 pb-2'>
                              <div className='row'>                                
                                <div className="col-6 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Start Date</label>
                                    <div className="input-group mb-3">
                                      <input type="date" className="form-control" {...formik.getFieldProps('start_date')}/> 
                                    </div>
                                </div>
                                <div className="col-6 mb-3">
                                    <label htmlFor="basic-url" className="form-label">End Date</label>
                                    <div className="input-group mb-3">
                                      <input type="date" className="form-control" {...formik.getFieldProps('end_date')}/> 
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className='model-footer'>
                              <div className='d-flex align-items-center justify-content-end pb-3 pe-6 pt-0'>
                              <button
                                type='submit'
                                id='kt_sign_up_submit'
                                className='btn btn-sm btn_primary text-primary'
                                disabled={formik.isSubmitting}
                              >
                                {!loading && <span className='indicator-label'>Filter
                                
                                </span>}
                                {loading && (
                                  <span className='indicator-progress' style={{display: 'block'}}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                  </span>
                                )}
                              </button>
                              </div>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="card-body pt-0 px-1">
            <div>
                <Bar options={options} data={databartransaction} />
            </div>
            {month != '' &&
            <div className='text-center'>
              {month}
            </div>}
            </div>
        </div>
        </>
    )
}
export {TransactionBar}